import { useState, useContext, useEffect } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { HiOutlineUserRemove } from "react-icons/hi";
import { GrEdit } from "react-icons/gr";
import { BiShow, BiUserPlus } from "react-icons/bi";
import { MdOutlineDone } from "react-icons/md";
import { toast } from "react-toastify";

import { AppContext } from "../../App";

import useHttpGet from "../../core/hooks/useHttpGet";
import { APIRoutes } from "../../core/http";
import { Private } from "../../core/models";
import PrivateService from "../../core/services/private.service";

import defaultAvatar from "../../assets/photos/profile/default-avatar.svg";
import sortingArrow from "../../assets/photos/all/sorting-arrow.svg";
import search from "../../assets/photos/profile/search.svg";
import { useAppDispatch } from "../../core/store";
import {
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../core/services/enums";
import {
  setChoosenData,
  setSelectedId,
} from "../../core/store/reducers/app/appDataSlice";
import { setCerts } from "../../core/store/reducers/documents/documentsSlice";
import { useTranslation } from "react-i18next";
import ButtonExcel from "../ButtonExcel/ButtonExcel";

const AdminUsers = () => {
  const [filterType, setFilterType] = useState("filter[search]");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState<any>({});
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    per_page: 10,
  });
  const [sent, setSent] = useState<any>({
    isSent: false,
    ids: [],
  });

  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);
  const { t } = useTranslation();

  const { reloadChecker } = appContext;

  const { fetchedData } = useHttpGet<any>(APIRoutes.ADMIN_USERS, {
    dependencies: [filter, sorter, pagination, reloadChecker],

    query: { ...sorter, ...filter, ...pagination },
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (sent) {
      timeout = setTimeout(
        () =>
          setSent((prev: any) => {
            return {
              ...prev,
              isSent: false,
              ids: [],
            };
          }),
        20_000
      );
    }

    return () => clearTimeout(timeout);
  }, [sent]);

  // const handleScroll = () => {
  //   const scrollableDiv: any = document.querySelector(".users-container");
  //   if (
  //     scrollableDiv?.scrollTop + scrollableDiv?.clientHeight + 1 >=
  //       scrollableDiv?.scrollHeight &&
  //     fetchedData?.payload?.cursor?.has_more_pages
  //   ) {
  //     setPaginationPage();
  //   }
  // };

  // document
  //   .querySelector(".users-container")
  //   ?.addEventListener("scroll", handleScroll);

  const handleVerificationEmail = async (id: number) => {
    try {
      const response = await PrivateService.sendVerificationEmail(id);
      toast.success(response?.data?.message);

      setSent((prev: any) => {
        return {
          isSent: true,
          ids: [...prev.ids, id],
        };
      });
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
  };

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};

    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ [propName]: value || undefined });
  };

  const setPaginationPage = () => {
    setPagination(()=>{
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 }
      }else{
        return {...pagination, per_page: 50}
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const deleteUser = (user: Private.User) => {
    dispatch(setModalType(AuthSelectionType.DeleteUser));
    dispatch(setModal(true));
    dispatch(setChoosenData(user));
  };

  const addUser = () => {
    dispatch(setModalType(AuthSelectionType.AddUser));
    dispatch(setModal(true));
  };

  const updateUser = (user: Private.User) => {
    dispatch(setSelectedId(user.id));
    dispatch(setChoosenData(user));
    dispatch(setModalType(AuthSelectionType.UpdateUser));
    dispatch(setModal(true));
  };
  const showUser = (user: Private.User) => {
    dispatch(setSelectedId(user.id));
    dispatch(setChoosenData(user));
    dispatch(setModalType(AuthSelectionType.showUser));
    dispatch(setModal(true));
  };

  const addQuestionnaire = (user: any) => {
    dispatch(setCerts([]));
    dispatch(setChoosenData(user));
    dispatch(setModalType(AuthSelectionType.AddQuestionnaire));
    dispatch(setModal(true));
  };

  return (
    <div className="admin">
      <h2 className="admin-table__name">{t<string>("USERS_TABLE.USERS")}</h2>
      <ButtonExcel url='admin/users_export'/>
      <div className="admin-container">
        <div className="profile-table">
          <div className="admin-search">
            <input
              className="admin-search__inp"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
              onKeyPress={(e: any) => {
                if (e.key === "Enter" && filterType) {
                  setFilterProperty(filterType, filterValue);
                }
              }}
            />
            <select
              onChange={(e) => {
                e.target.value === "search"
                  ? setFilterType(`filter[${e.target.value}]`)
                  : setFilterType(`filter[${e.target.value}][like]`);
              }}
              className="admin-search__dropdown"
            >
              <option value="search">{t<string>("USERS_TABLE.ALL")}</option>
              <option value="first_name">
                {t<string>("USERS_TABLE.FIRST_NAME")}
              </option>
              <option value="last_name">
                {t<string>("USERS_TABLE.LAST_NAME")}
              </option>
              <option value="email">{t<string>("USERS_TABLE.EMAIL")}</option>
              <option value="phone">{t<string>("USERS_TABLE.PHONE")}</option>
              <option value="personal_code">
                {t<string>("USERS_TABLE.PERSONAL_CODE")}
              </option>
            </select>
            <button className="admin-search__btn">
              <img
                src={search}
                alt="search"
                onClick={() => {
                  if (filterType) {
                    setFilterProperty(filterType, filterValue);
                  }
                }}
              />
            </button>
            <button
              title={t<string>("USERS_TABLE.ADD_USER")}
              onClick={addUser}
              className="add-user__btn"
            >
              <BiUserPlus />
            </button>
          </div>
          <div className="users-container">
            <table className="users-table" cellSpacing="0" cellPadding="0">
              <thead className="users-table-head">
                <tr className="users-table-head-row">
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.USER_ID")}
                  </td>
                  <td className="users-table-head__item"></td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.FIRST_NAME")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[first_name]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    className="users-table-head__item"
                  >
                    {t<string>("USERS_TABLE.LAST_NAME")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[last_name]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    className="users-table-head__item"
                    id="status"
                  >
                    {t<string>("USERS_TABLE.STATUS")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[status]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.VERIFICATION")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[verified]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.ROLE")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[role]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.EMAIL")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.PHONE")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[phone]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.GENDER")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[gender]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.DATE_OF_BIRTH")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[date_of_birth]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.CITIZENSHIP")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[citizenship]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.PERSONAL_CODE")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.PASSPORT")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.CREATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[created_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_TABLE.UPDATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[updated_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item"></td>
                  <td className="users-table-head__item"></td>
                </tr>
              </thead>
              <tbody className="users-table-content">
                {fetchedData?.payload?.collection.map((user: Private.User) => (
                  <tr className="users-table-row" key={user.id}>
                    <td className="users-table-row__item">{user.id}</td>
                    <td className="users-table-row__item">
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                        src={user?.avatar ? user?.avatar : defaultAvatar}
                        alt={user.last_name}
                      />
                    </td>
                    <td className="users-table-row__item">{user.first_name}</td>
                    <td className="users-table-row__item">{user.last_name}</td>
                    <td className="users-table-row__item" id="status">
                      {user.status}
                    </td>
                    <td className="users-table-row__item">
                      {user.verified === 1 ? (
                        "Yes"
                      ) : sent.isSent && sent.ids.includes(user.id) ? (
                        <span className="users-table-row__item-send">
                          {t<string>("USERS_TABLE.SENT")} <MdOutlineDone />
                        </span>
                      ) : (
                        <button
                          className="users-table-row__item-verify"
                          onClick={() => handleVerificationEmail(user.id)}
                        >
                          {t<string>("USERS_TABLE.VERIFY")}
                        </button>
                      )}
                    </td>
                    <td className="users-table-row__item">{user.role}</td>
                    <td className="users-table-row__item">{user.email}</td>
                    <td className="users-table-row__item">{user.phone}</td>
                    <td className="users-table-row__item">
                      {(user.gender === "0" && t<string>("USERS_TABLE.MALE")) ||
                        (user.gender === "1" &&
                          t<string>("USERS_TABLE.FEMALE"))}
                    </td>
                    <td className="users-table-row__item">
                      {user.date_of_birth}
                    </td>
                    <td className="users-table-row__item">
                      {(user.citizenship === "0" &&
                        t<string>("USERS_TABLE.BULGARIA")) ||
                        (user.citizenship === "1" &&
                          t<string>("USERS_TABLE.OTHERS"))}
                    </td>
                    <td className="users-table-row__item">
                      {user.personal_code}
                    </td>
                    <td className="users-table-row__item">{user.passport}</td>
                    <td className="users-table-row__item">
                      <span>{`${new Date(
                        user?.created_at
                      ).toLocaleTimeString()}`}</span>
                      <br></br>
                      {`${user?.created_at.slice(
                        8,
                        10
                      )}.${user?.created_at.slice(
                        5,
                        7
                      )}.${user?.created_at.slice(0, 4)}`}
                    </td>
                    <td className="users-table-row__item">
                      <span>{`${new Date(
                        user?.updated_at
                      ).toLocaleTimeString()}`}</span>
                      <br></br>
                      {`${user?.updated_at.slice(
                        8,
                        10
                      )}.${user?.updated_at.slice(
                        5,
                        7
                      )}.${user?.updated_at.slice(0, 4)}`}
                    </td>
                    <td className="users-table-row__item">
                      <button
                        onClick={() => addQuestionnaire(user)}
                        className="users-table-action__btn"
                        title={t<string>("USERS_TABLE.ADD_APP")}
                      >
                        <IoMdAddCircle />
                      </button>
                      <button
                        onClick={() => showUser(user)}
                        className="users-table-action__btn"
                        title={t<string>("USERS_TABLE.SHOW_USER")}
                      >
                        <BiShow />
                      </button>
                      <button
                        onClick={() => {
                          updateUser(user);
                        }}
                        className="users-table-action__btn"
                        title={t<string>("USERS_TABLE.UPDATE_USER")}
                      >
                        <GrEdit />
                      </button>
                      <button
                        onClick={() => deleteUser(user)}
                        className="users-table-action__btn"
                        title={t<string>("USERS_TABLE.DELETE_USER")}
                      >
                        <HiOutlineUserRemove />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {fetchedData?.payload?.cursor.has_more_pages && (
            <button
              className="profile-table__btn"
              onClick={() => {
                setPaginationPage();
              }}
            >
              Show more
            </button>
          )}
          {!fetchedData?.payload?.cursor.has_more_pages &&
            fetchedData?.payload?.collection.length > 10 && (
              <button
                onClick={setDefaultPagination}
                className="profile-table__btn"
              >
                Hide all
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
