import CarLeasingBanner from "./CarLeasingBanner";
import CarLeasingInfo from "./CarLeasingInfo";

const CreditsCarLeasing = () => {
  return (
    <div id="Car leasing" className="credits-card-leasing">
      <CarLeasingBanner />
      <div className="credits-container-main-content-consumer">
        <CarLeasingInfo />
      </div>
    </div>
  );
};

export default CreditsCarLeasing;
