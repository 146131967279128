import { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

import { AppContext } from "../../../App";
import useAuth from "../../../core/hooks/useAuth";
import { currencyFormat } from "../../../core/functions/funtions";
import { style } from "../../../core/constants/style";
import { useAppDispatch } from "../../../core/store";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../core/services/enums";
import { setCalculator } from "../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const CreditCardsForm = () => {
  const resultCalc = JSON.parse(localStorage.getItem("calcResult") || "{}");

  useEffect(() => {
    if (resultCalc) {
      formikForm.setFieldValue("amount_1", +resultCalc.amount_1);
      formikForm.setFieldValue("term_1", +resultCalc.term_1);
    }
  }, []);

  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  const { setCalculationExample } = appContext;
  const valueFormValidationSchema = Yup.object().shape({
    amount_1: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .matches(/[^0]+/, "Sum can't be equal 0")
      .max(30, "Max 30 symbols"),
  });

  const formikForm = useFormik<{
    amount_1: number;
    type: string;
    term_1: number;
  }>({
    initialValues: {
      type: "2",
      amount_1: 10000,
      term_1: 12,
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = (values: any) => {
    dispatch(setCalculator(values));
    localStorage.setItem(
      "calcResult",
      JSON.stringify({
        ...values,
      })
    );
  };

  const addAmount = () => {
    if (formikForm.values.amount_1 < 25000) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 + 100);
    }
    if (25000 - formikForm.values.amount_1 < 200) {
      formikForm.setFieldValue("amount_1", 25000);
    }
  };

  const removeAmount = () => {
    if (formikForm.values.amount_1 > 200) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 - 100);
    }
    if (formikForm.values.amount_1 - 200 < 200) {
      formikForm.setFieldValue("amount_1", 200);
    }
  };

  const coercion = (e: any, maxValue: number) => {
    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue("amount_1", maxValue);
    } else {
      formikForm.setFieldValue("amount_1", valueNum);
    }
  };

  const handleExample = () => {
    setCalculationExample(1);
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.calcExample));
  };

  const perMonth = () => {
    let rate = 7 / 100 / 12;
    // let nper = formikForm.values.term_1;
    let pv = -formikForm.values.amount_1;

    // return PMT(rate, nper, pv, 0, 0).toFixed(2);
    return rate.toFixed(2);
  };

  return (
    <div className="calc">
    <form
      className="calc__form"
      onSubmit={formikForm.handleSubmit}
    >
      <div className="search-block-main">
        <div
          className="search-block-inputs__input-item  input-item"
          onBlur={() => {
            if (formikForm.values.amount_1 < 200) {
              formikForm.setFieldValue("amount_1", 200);
            }
          }}
        >
          <div className="input-item-main">
            <label className="input-item-main__label">{t<string>("CALCULATOR.CREDIT_LIMIT")}</label>
            <label className="ranges__controls-field">
              <input
                className={classNames({
                  "ranges__controls-field-put": true,
                  "ranges__controls-field-put__l": true,
                })}
                type="text"
                min={200}
                max={25000}
                step={100}
                value={currencyFormat(formikForm.values.amount_1)}
                placeholder="180"
                onChange={(event) => {
                  coercion(event, 25_000);
                }}
              />
              {t<string>("PROFILE.BGN")}
            </label>
            <div className="range-wrapper">
              <button
                onClick={removeAmount}
                type="button"
                className="range-wrapper__step-add"
              >
                <AiOutlineMinusCircle style={style} size={32} />
              </button>
              <input
                type="range"
                step={100}
                min={200}
                max={25000}
                className="range__controls-input range-wrapper__input"
                {...formikForm.getFieldProps("amount_1")}
              />
              <button
                onClick={addAmount}
                type="button"
                className="range-wrapper__step-minus"
              >
                <AiOutlinePlusCircle style={style} size={32} />
              </button>
            </div>
          </div>
          <div className="input-item__details">
            <p>200 {t<string>("PROFILE.BGN")}</p>
            <p>25 000 {t<string>("PROFILE.BGN")}</p>
          </div>
        </div>
      </div>
      <button className="search-block-show" type="submit">
        {t<string>("CALCULATOR.BUTTON_LOGGED")}
      </button>
    </form>
    </div>
  );
};

export default CreditCardsForm;
