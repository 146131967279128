import { useState, useEffect } from "react";

import Banners from "./Banners";

import arrow_left from "../../../../assets/photos/credits/arrow_left_credits.svg";
import arrow_right from "../../../../assets/photos/credits/arrow_right_credits.svg";

import { credits_carousel } from "../../../../core/constants/credits-carousel";
import { useTranslation } from "react-i18next";

const CreditsBanner = () => {
  const [loansInfo, setLoansInfo] = useState<any>({});
  const [currentIndex, setCurrentIndex] = useState(1);
  const [switcher, setSwitcher] = useState(true);

  const { t } = useTranslation();
  const updateIndex = () => {
    currentIndex === 3 ? setCurrentIndex(1) : setCurrentIndex(currentIndex + 1);
  };

  useEffect(() => {
    setLoansInfo(credits_carousel[0]);
  }, []);

  useEffect(() => {
    if (switcher) {
      const slide = setTimeout(() => {
        currentIndex === 1
          ? setLoansInfo(credits_carousel[1])
          : currentIndex === 2
          ? setLoansInfo(credits_carousel[2])
          : setLoansInfo(credits_carousel[3]);
        updateIndex();
      }, 4000);

      return () => {
        clearTimeout(slide);
      };
    }
  }, [loansInfo]);

  const buttonDown = () => {
    switch (currentIndex) {
      case 0:
        setCurrentIndex(2);
        setSwitcher(false);
        setLoansInfo(credits_carousel[3]);
        break;

      case 1:
        setCurrentIndex(3);
        setSwitcher(false);
        setLoansInfo(credits_carousel[2]);
        break;

      case 2:
        setCurrentIndex(1);
        setSwitcher(false);
        setLoansInfo(credits_carousel[1]);
        break;

      case 3:
        setCurrentIndex(2);
        setSwitcher(false);
        setLoansInfo(credits_carousel[3]);
        break;

      default:
        return;
    }
  };

  const buttonUp = () => {
    switch (currentIndex) {
      case 0:
        setCurrentIndex(1);
        setSwitcher(false);
        setLoansInfo(credits_carousel[0]);
        break;

      case 1:
        setCurrentIndex(2);
        setSwitcher(false);
        setLoansInfo(credits_carousel[1]);
        break;

      case 2:
        setCurrentIndex(3);
        setSwitcher(false);
        setLoansInfo(credits_carousel[2]);
        break;

      case 3:
        setCurrentIndex(1);
        setSwitcher(false);
        setLoansInfo(credits_carousel[0]);
        break;

      default:
        return;
    }
  };

  const { title, subtitle, icon, bankTitle } = loansInfo;

  return (
    <>
      <header className="credits-container-header">
        <p className="credits-container-header__subtitle">
          {t<string>("FINANCE.TITLE")}
        </p>
        <h1 className="credits-container-header__title">
          {t<string>("FINANCE.SUBTITLE")}
        </h1>
      </header>
      <div className="credits-container__switch-banner">
        <button onClick={buttonDown} className="banner-section__arrow-btn">
          <img src={arrow_left} alt="prev" />
        </button>
        <section className="_switch-banner__banner-section banner-section">
          <Banners
            bankTitle={bankTitle}
            icon={icon}
            title={title}
            subtitle={subtitle}
          />
        </section>
        <button
          onClick={buttonUp}
          type="button"
          className="banner-section__arrow-btn"
        >
          <img src={arrow_right} alt="next" />
        </button>
      </div>

      <div className="banner-section-switch">
        <button
          onClick={() => {
            setLoansInfo(credits_carousel[1]);
            setCurrentIndex(1);
            setSwitcher(false);
          }}
          className={
            currentIndex === 0 || currentIndex === 1
              ? "banner-section-switch-choosen-btn"
              : "banner-section-switch-btn"
          }
        ></button>
        <button
          onClick={() => {
            setLoansInfo(credits_carousel[2]);
            setCurrentIndex(2);
            setSwitcher(false);
          }}
          className={
            currentIndex === 2
              ? "banner-section-switch-choosen-btn"
              : "banner-section-switch-btn"
          }
        ></button>
        <button
          onClick={() => {
            setLoansInfo(credits_carousel[3]);
            setCurrentIndex(3);
            setSwitcher(false);
          }}
          className={
            currentIndex === 3
              ? "banner-section-switch-choosen-btn"
              : "banner-section-switch-btn"
          }
        ></button>
      </div>
    </>
  );
};

export default CreditsBanner;
