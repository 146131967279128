import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { BiShow } from "react-icons/bi";
import { GrAddCircle, GrEdit } from "react-icons/gr";
import { CiCircleRemove } from "react-icons/ci";

import { AppContext } from "../../App";

import { APIRoutes } from "../../core/http";
import useHttpGet from "../../core/hooks/useHttpGet";
import { Private } from "../../core/models";
import PrivateService from "../../core/services/private.service";

import sortingArrow from "../../assets/photos/all/sorting-arrow.svg";
import search from "../../assets/photos/profile/search.svg";
import { useAppDispatch, useAppSelector } from "../../core/store";
import {
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType, CalculatorTypes } from "../../core/services/enums";
import {
  setChoosenData,
  setSelectedId,
} from "../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";
import ButtonExcel from "../ButtonExcel/ButtonExcel";

const AdminCreditOffers = () => {
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterType, setFilterType] = useState("filter[search]");
  const [filter, setFilter] = useState<any>({});
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });

  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);
  const { t } = useTranslation();

  const { setReloadChecker, reloadChecker } = appContext;

  const { fetchedData: offers } = useHttpGet<any>(
    `${APIRoutes.ADMIN_ENTITIES_CREDIT_OFFERS}`,

    {
      dependencies: [filter, sorter, pagination, reloadChecker],
      query: { ...sorter, ...filter, ...pagination },
    }
  );

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ [propName]: value || undefined });
  };

  const addNewOffer = () => {
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.addNewCreditOffer));
  };

  const showContent = (id: number) => {
    dispatch(setChoosenData(""));
    dispatch(setSelectedId(id));
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.showCreditOfferContent));
  };

  const showName = (id: number) => {
    dispatch(setChoosenData("name"));
    dispatch(setSelectedId(id));
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.showCreditOfferContent));
  };

  const updateCreditOffer = (id: number) => {
    dispatch(setSelectedId(id));
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.updateCreditOffer));
  };

  const deleteCreditOffer = async (id: number) => {
    try {
      const response = await PrivateService.deleteCreditOffer(id);

      toast.success(response?.data?.message);
      setReloadChecker(!reloadChecker);
    } catch (errors: any) {
      setLoading(false);
      toast.error(errors?.response?.data?.message);
    }
  };

  const setPaginationPage = () => {
    setPagination(()=>{
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 }
      }else{
        return {...pagination, per_page: 50}
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};

    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  return (
    <>
      <div className="admin">
        <h2 className="admin-table__name">
          {t<string>("CREDIT_OFFERS.TITLE")}
        </h2>
        <ButtonExcel url='admin/credit_offers_export'/>
        <div className="admin-container">
          <div className="profile-table">
            <div className="admin-search">
              <input
                className="admin-search__inp"
                value={filterValue}
                onChange={(e) => {
                  setFilterValue(e.target.value);
                }}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter" && filterType) {
                    setFilterProperty(filterType, filterValue);
                  }
                }}
              />
              <select
                onChange={(e) => {
                  e.target.value === "search"
                    ? setFilterType(`filter[${e.target.value}]`)
                    : setFilterType(`filter[${e.target.value}][like]`);
                }}
                className="admin-search__dropdown"
              >
                <option value="search">
                  {t<string>("CREDIT_OFFERS.SEARCH_ALL")}
                </option>
                <option value="name">{t<string>("CREDIT_OFFERS.NAME")}</option>
                <option value="description">
                  {t<string>("CREDIT_OFFERS.DESCRIPTION")}
                </option>
                <option value="rating">
                  {t<string>("CREDIT_OFFERS.RATING")}
                </option>
                <option value="offer_type">
                  {t<string>("CREDIT_OFFERS.OFFER_TYPE")}
                </option>
                <option value="loan_amount_from">
                  {t<string>("CREDIT_OFFERS.AMOUNT")}
                </option>
                <option value="credit_term_from">
                  {t<string>("CREDIT_OFFERS.TERM")}
                </option>
              </select>
              <button className="admin-search__btn">
                <img
                  src={search}
                  alt="search"
                  onClick={() => {
                    if (filterType) {
                      setFilterProperty(filterType, filterValue);
                    }
                  }}
                />
              </button>
              <button
                onClick={addNewOffer}
                className="add-user__btn"
                title={t<string>("CREDIT_OFFERS.NEW_OFFER")}
              >
                <GrAddCircle />
              </button>
            </div>
            <div className="users-container">
              <table className="users-table" cellSpacing="0" cellPadding="0">
                <thead className="users-table-head">
                  <tr className="users-table-head-row">
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.OFFER_ID")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[id]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.NAME")}
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.DESCRIPTION")}
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.OFFER_TYPE")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[offer_type]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.LOAN_AMOUNT_FROM")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[loan_amount_from]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.LOAN_AMOUNT_TO")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[loan_amount_to]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.CREDIT_TERM_FROM")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[credit_term_from]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.CREDIT_TERM_TO")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[credit_term_to]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.INTEREST_RATE_FROM")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[interest_rate_from]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.INTEREST_RATE_TO")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[interest_rate_to]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.STATUS")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[status]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.SHOW_ON_PUBLIC")}
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.CREATED")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[created_at]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("CREDIT_OFFERS.UPDATED")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[updated_at]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item"></td>
                  </tr>
                </thead>
                <tbody className="users-table-content">
                  {offers?.payload?.collection.map(
                    (offer: Private.AdminCreditOffer) => (
                      <tr className="users-table-row" key={offer.id}>
                        <td className="users-table-row__item">{offer.id}</td>
                        <td className="users-table-row__item">
                          <button
                            onClick={() => showName(offer.id)}
                            className="users-table-action__btn"
                            title={t<string>("CREDIT_OFFERS.SHOW_NAME")}
                          >
                            <BiShow />
                          </button>
                        </td>
                        <td className="users-table-row__item">
                          <button
                            onClick={() => showContent(offer.id)}
                            title={t<string>("CREDIT_OFFERS.SHOW_DESCRIPTION")}
                            className="users-table-action__btn"
                          >
                            <BiShow />
                          </button>
                        </td>

                        <td className="users-table-row__item">
                          {offer?.bankOfferTypes?.collection[0].id ===
                            CalculatorTypes.ConsumerLoan && (
                            <span>{t<string>("LOANS.CONSUMER_LOANS")}</span>
                          )}
                          {offer?.bankOfferTypes?.collection[0].id ===
                            CalculatorTypes.CreditCards && (
                            <span>{t<string>("LOANS.CREDIT_CARDS")}</span>
                          )}
                          {offer?.bankOfferTypes?.collection[0].id ===
                            CalculatorTypes.Quick && (
                            <span>{t<string>("LOANS.QUICK_LOAN")}</span>
                          )}
                          {offer?.bankOfferTypes?.collection[0].id ===
                            CalculatorTypes.Mortgage && (
                            <span>{t<string>("LOANS.MORTGAGE_LOANS")}</span>
                          )}
                          {offer?.bankOfferTypes?.collection[0].id ===
                            CalculatorTypes.CarLeasing && (
                            <span>{t<string>("LOANS.CAR_LEASING")}</span>
                          )}
                          {offer?.bankOfferTypes?.collection[0].id ===
                            CalculatorTypes.Refinancing && (
                            <span>{t<string>("LOANS.REFINANCING")}</span>
                          )}
                          {offer?.bankOfferTypes?.collection[0].id ===
                            CalculatorTypes.Merging && (
                            <span>{t<string>("LOANS.MERGING_LOANS")}</span>
                          )}
                        </td>
                        <td className="users-table-row__item">
                          {offer.loan_amount_from}
                        </td>
                        <td className="users-table-row__item">
                          {offer.loan_amount_to}
                        </td>
                        <td className="users-table-row__item">
                          {offer.credit_term_from}
                        </td>
                        <td className="users-table-row__item">
                          {offer.credit_term_to}
                        </td>
                        <td className="users-table-row__item">
                          {offer.interest_rate_from}
                        </td>
                        <td className="users-table-row__item">
                          {offer.interest_rate_to}
                        </td>
                        <td className="users-table-row__item">
                          {offer.status}
                        </td>
                        <td className="users-table-row__item">
                          {(offer.show_on_public === 1 && "yes") ||
                            (offer.show_on_public === 0 && "no")}
                        </td>
                        <td className="users-table-row__item">
                          <span>{`${new Date(
                            offer?.created_at
                          ).toLocaleTimeString()}`}</span>
                          <br></br>
                          {`${offer?.created_at.slice(
                            8,
                            10
                          )}.${offer?.created_at.slice(
                            5,
                            7
                          )}.${offer?.created_at.slice(0, 4)}`}
                        </td>
                        <td className="users-table-row__item">
                          <span>{`${new Date(
                            offer?.updated_at
                          ).toLocaleTimeString()}`}</span>
                          <br></br>
                          {`${offer?.updated_at.slice(
                            8,
                            10
                          )}.${offer?.updated_at.slice(
                            5,
                            7
                          )}.${offer?.updated_at.slice(0, 4)}`}
                        </td>
                        <td className="users-table-row__item">
                          <button
                            onClick={() => updateCreditOffer(offer.id)}
                            className="users-table-action__btn"
                            title={t<string>("CREDIT_OFFERS.UPDATE_OFFER")}
                          >
                            <GrEdit />
                          </button>
                          <button
                            onClick={() => deleteCreditOffer(offer.id)}
                            className="users-table-action__btn"
                            title={t<string>("CREDIT_OFFERS.DELETE_TITLE")}
                          >
                            <CiCircleRemove />
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            {offers?.payload?.cursor.has_more_pages && (
              <button
                className="profile-table__btn"
                onClick={() => {
                  setPaginationPage();
                }}
              >
                {t<string>("CREDIT_OFFERS.SHOW_MORE")}
              </button>
            )}
            {!offers?.payload?.cursor.has_more_pages &&
              offers?.payload?.collection.length > 10 && (
                <button
                  onClick={setDefaultPagination}
                  className="profile-table__btn"
                >
                  {t<string>("CREDIT_OFFERS.HIDE_ALL")}
                </button>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreditOffers;
