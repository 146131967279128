import { useContext, useState } from "react";
import { BiShow } from "react-icons/bi";
import { CiCircleRemove } from "react-icons/ci";
import { GrAddCircle, GrEdit } from "react-icons/gr";
import { toast } from "react-toastify";

import { AppContext } from "../../../../App";

import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import PrivateService from "../../../../core/services/private.service";
import { Private } from "../../../../core/models";

import search from "../../../../assets/photos/profile/search.svg";
import sortingArrow from "../../../../assets/photos/all/sorting-arrow.svg";
import { useAppDispatch } from "../../../../core/store";
import {
  setModal,
  setModalType,
} from "../../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../../core/services/enums";
import {
  setChoosenData,
  setSelectedId,
} from "../../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";

const NewsCategories = () => {
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterType, setFilterType] = useState("filter[search]");
  const [filter, setFilter] = useState<any>({});
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const appContext = useContext(AppContext);

  const { reloadChecker, setReloadChecker } = appContext;

  const { fetchedData: categories } = useHttpGet<any>(
    `${APIRoutes.ADMIN_PUBLIC}/news/categories`,

    {
      dependencies: [reloadChecker, filter, pagination, sorter],
      query: { ...sorter, ...filter, ...pagination },
    }
  );

  const showContent = (id: number) => {
    dispatch(setChoosenData("news"));
    dispatch(setSelectedId(id));
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.showBlogCategoryContent));
  };

  const addCategory = () => {
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.NewNewsCategory));
  };

  const updateCategory = (id: number) => {
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.updateNewsCategory));
    dispatch(setSelectedId(id));
  };

  const deleteNewsCategory = async (id: number) => {
    try {
      const response = await PrivateService.deleteNewsCategory(id);

      toast.success(response?.data?.message);
      setReloadChecker(!reloadChecker);
    } catch (errors: any) {
      setLoading(false);
      toast.error(errors?.response?.data?.message);
    }
  };

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ [propName]: value || undefined });
  };

  const setPaginationPage = () => {
    setPagination(()=>{
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 }
      }else{
        return {...pagination, per_page: 50}
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};

    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  return (
    <div className="profile-table blogs-table">
      <div className="admin-search">
        <input
          className="admin-search__inp"
          value={filterValue}
          onChange={(e) => {
            setFilterValue(e.target.value);
          }}
          onKeyPress={(e: any) => {
            if (e.key === "Enter" && filterType) {
              setFilterProperty(filterType, filterValue);
            }
          }}
        />
        <select
          onChange={(e) => {
            e.target.value === "search"
              ? setFilterType(`filter[${e.target.value}]`)
              : setFilterType(`filter[${e.target.value}][like]`);
          }}
          className="admin-search__dropdown"
        >
          <option value="search">{t<string>("NEWS_TABLE.ALL")}</option>
          <option value="name">{t<string>("NEWS_TABLE.NAME")}</option>
          <option value="description">
            {t<string>("NEWS_TABLE.DESCRIPTION")}
          </option>
          <option value="meta_title">{t<string>("NEWS_TABLE.TITLE")}</option>
          <option value="meta_keywords">
            {t<string>("NEWS_TABLE.KEYWORDS")}
          </option>
          <option value="content">{t<string>("NEWS_TABLE.CONTENT")}</option>
          <option value="id">{t<string>("NEWS_TABLE.POST_ID")}</option>
        </select>
        <button className="admin-search__btn">
          <img
            src={search}
            alt="search"
            onClick={() => {
              if (filterType) {
                setFilterProperty(filterType, filterValue);
              }
            }}
          />
        </button>
        <button
          onClick={addCategory}
          className="add-user__btn"
          title={t<string>("NEWS_TABLE.ADD_CATEGORY_TITLE")}
        >
          <GrAddCircle />
        </button>
      </div>

      <div className="users-container">
        <table className="users-table" cellSpacing="0" cellPadding="0">
          <thead className="users-table-head">
            <tr className="users-table-head-row">
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.CATEGORY_ID")}
                <button
                  onClick={() => {
                    setSorterProperty("order[id]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item"></td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.NAME_EN")}
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.DESCRIPTION_EN")}
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.TITLE_EN")}
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.META_KEYWORDS_EN")}
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.CONTENT")}
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.NAME_BG")}
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.DESCRIPTION_BG")}
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.TITLE_BG")}
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.META_KEYWORDS_BG")}
              </td>
              <td className="users-table-head__item">
                Status{" "}
                <button
                  onClick={() => {
                    setSorterProperty("order[status]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.SHOW_ON_PUBLIC")}
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.CREATED")}
                <button
                  onClick={() => {
                    setSorterProperty("order[created_at]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item">
                {t<string>("NEWS_TABLE.UPDATED")}
                <button
                  onClick={() => {
                    setSorterProperty("order[updated_at]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item"></td>
            </tr>
          </thead>
          <tbody className="users-table-content">
            {categories?.payload?.collection.length > 0 &&
              categories?.payload?.collection.map(
                (category: Private.BlogCategory) => (
                  <tr className="users-table-row" key={category.id}>
                    <td className="users-table-row__item">{category.id}</td>
                    <td className="users-table-row__item">
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                        src={category.image_path}
                        alt={category.image_name}
                      />
                    </td>
                    <td className="users-table-row__item">
                      {category.translations.en.name}
                    </td>
                    <td className="users-table-row__item">
                      {category.translations.en.meta_description}
                    </td>
                    <td className="users-table-row__item">
                      {category.translations.en.meta_title}
                    </td>
                    <td className="users-table-row__item">
                      {category.translations.en.meta_keywords}
                    </td>
                    <td className="users-table-row__item">
                      <button
                        onClick={() => showContent(category.id)}
                        className="users-table-action__btn"
                        title={t<string>("NEWS_TABLE.SHOW_CONTENT")}
                      >
                        <BiShow />
                      </button>
                    </td>
                    <td className="users-table-row__item">
                      {category.translations.bg.name}
                    </td>
                    <td className="users-table-row__item">
                      {category.translations.bg.meta_description}
                    </td>
                    <td className="users-table-row__item">
                      {category.translations.bg.meta_title}
                    </td>
                    <td className="users-table-row__item">
                      {category.translations.bg.meta_keywords}
                    </td>
                    <td className="users-table-row__item">{category.status}</td>
                    <td className="users-table-row__item">
                      {(!category.show_on_public && "yes") ||
                        (category.show_on_public && "no")}
                    </td>
                    <td className="users-table-row__item">
                      <span>{`${new Date(
                        category?.created_at
                      ).toLocaleTimeString()}`}</span>
                      <br></br>
                      {`${category?.created_at.slice(
                        8,
                        10
                      )}.${category?.created_at.slice(
                        5,
                        7
                      )}.${category?.created_at.slice(0, 4)}`}
                    </td>
                    <td className="users-table-row__item">
                      <span>{`${new Date(
                        category?.updated_at
                      ).toLocaleTimeString()}`}</span>
                      <br></br>
                      {`${category?.updated_at.slice(
                        8,
                        10
                      )}.${category?.updated_at.slice(
                        5,
                        7
                      )}.${category?.updated_at.slice(0, 4)}`}
                    </td>
                    <td className="users-table-row__item">
                      <button
                        onClick={() => updateCategory(category.id)}
                        className="users-table-action__btn"
                        title={t<string>("NEWS_TABLE.UPDATE_CATEGORY_TITLE")}
                      >
                        <GrEdit />
                      </button>
                      <button
                        onClick={() => deleteNewsCategory(category.id)}
                        className="users-table-action__btn"
                        title={t<string>("NEWS_TABLE.DELETE_CATEGORY_TITLE")}
                      >
                        <CiCircleRemove />
                      </button>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
      {categories?.payload?.cursor.has_more_pages && (
        <button
          className="profile-table__btn"
          onClick={() => {
            setPaginationPage();
          }}
        >
          Show more
        </button>
      )}
      {!categories?.payload?.cursor.has_more_pages &&
        categories?.payload?.collection.length > 10 && (
          <button onClick={setDefaultPagination} className="profile-table__btn">
            Hide all
          </button>
        )}
    </div>
  );
};

export default NewsCategories;
