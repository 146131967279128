import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../../App";
import credits_quick_loans_main from "../../../../../assets/photos/credits/credits-quick_loans_main.svg";
import credits_quick_loans_bg from "../../../../../assets/photos/credits/credits_quick_loans_bg.svg";
import { useTranslation } from "react-i18next";
import { setTypeForResult } from "../../../../../core/functions/funtions";

const CreditsQuickLoansBanner = () => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();

  const { setSelectedLoan } = appContext;

  return (
    <section className="credits-block__consumer-credits consumer-credits">
      <div className="consumer-credits-banner credits-quick-banner">
        <div className="consumer-credits-banner__main-content main-content">
          <div className="main-content-info">
            <h1 className="main-content-info-title">
              {t<string>("LOANS.QUICK_LOAN")}
            </h1>
            <p className="main-content-info-subtitle">
              {t<string>("FINANCE.QUICK_TEXT_1")} <br />
              <br /> {t<string>("FINANCE.QUICK_TEXT_2")}
            </p>
            <img
              className="credits_mortgage_bg"
              src={credits_quick_loans_bg}
              alt="credits_quick_loans_bg"
            />
            <img
              className="credits_quick_loans_main"
              src={credits_quick_loans_main}
              alt="credits_quick_loans_main"
            />
            <Link
              to={`/result-list`}
              onClick={() => {
                setTypeForResult(3)
              }}
              className="main-content-btn"
            >
              {t<string>("FINANCE.QUICK_BUTTON")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditsQuickLoansBanner;
