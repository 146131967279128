import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";

import { AppContext } from "../../../../App";

import { useAppDispatch, useAppSelector } from "../../../../core/store";
import PrivateService from "../../../../core/services/private.service";
import { setChoosenData } from "../../../../core/store/reducers/app/appDataSlice";
import {
  setModal,
  setModalType,
} from "../../../../core/store/reducers/modal/modalSlice";
import { credit_types } from "../../../../core/constants/creditTypes";
import {
  AuthSelectionType,
  SubmittedApplicationStatus,
} from "../../../../core/services/enums";

import CommonService from "../../../../core/services/common.service";
import { chatOpener } from "../../../../core/functions/funtions";
import { Public } from "../../../../core/models";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import useRole from "../../../../core/hooks/useRole";

enum userStatuses {
  accepted = "accepted_by_client",
  declined = "rejected_by_client",
}

const ShowSubmittedApplication = () => {
  const [application, setApplication] = useState<any>();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const appContext = useContext(AppContext);
  const { isPartner, isUser } = useRole();

  const { setReloadChecker, reloadChecker } = appContext;

  const { questionnaire, selectedId, choosenData, appLanguage } =
    useAppSelector((state) => state.app);

  useHttpGet<any>(`${APIRoutes.USER_SUBMITTED_APPLICATIONS}/${selectedId}`, {
    dependencies: [selectedId, reloadChecker, window.location.pathname],
    condition: window.location.pathname.split("/").includes("results-list"),
    resolve: (response) => {
      setApplication(response?.payload);
    },
  });

  useHttpGet<any>(
    `${APIRoutes.ADMIN_USERS_SUBMITTED_APPLICATIONS}/${selectedId}`,
    {
      dependencies: [selectedId, reloadChecker, window.location.pathname],
      condition: window.location.pathname
        .split("/")
        .includes("submitted-applications"),
      resolve: (response) => {
        setApplication(response?.payload);
        // console.log("admin");
      },
    }
  );

  const adminChecker = () => {
    return window.location.pathname
      .split("/")
      .includes("submitted-applications");
  };

  const statusChecker = () => {
    return application?.status;
  };

  const dataChecker: any = () => {
    return application?.bank_offer;
  };

  const submittedApplicationDecision = async (status: string) => {
    try {
      const response = await PrivateService.submittedApplicationDecision(
        status,
        selectedId
      );
      dispatch(setModal(false));
      setReloadChecker(!reloadChecker);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
      CommonService.showErrors(errors?.response?.data?.payload);
    }
  };

  return (
    <div className="modal__container modal-show-offer">
      {window.location.pathname.split("/").includes("questionnaires") && (
        <button
          className="modal-show-offer__back"
          onClick={() => {
            dispatch(setChoosenData({ questionnaire }));
            dispatch(setModalType(AuthSelectionType.applyApplication));
          }}>
          {t<string>("SUBMITTED_APPLICATIONS.BACK_BUTTON")}
        </button>
      )}
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("SUBMITTED_APPLICATIONS.SHOW_APPLICATION_TITLE")}
        </p>
        <div className="about about-applications">
          <div className="about__form about-applications__block">
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.BANK_NAME")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>
                    {appLanguage === "bg"
                      ? dataChecker()?.bank?.translations?.bg.name ||
                        dataChecker()?.bank?.translations?.bg.name
                      : appLanguage === "en"
                      ? dataChecker()?.bank?.translations?.en.name
                      : "Bank"}
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.TYPE")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>
                    {
                      credit_types[
                        dataChecker()?.bankOfferTypes?.collection[0].id
                      ]
                    }
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.CREDIT_TERM_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{dataChecker()?.credit_term_from}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.CREDIT_TERM_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{dataChecker()?.credit_term_to}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.INTEREST_RATE_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{dataChecker()?.interest_rate_from}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.INTEREST_RATE_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{dataChecker()?.interest_rate_to}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{dataChecker()?.loan_amount_from}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{dataChecker()?.loan_amount_to}</span>
                </div>
              </label>
            </div>
          </div>
          <div className="about-applications__status">
            <div className="about-applications__status-info">
              {t<string>("SUBMITTED_APPLICATIONS.CURRENT_STATUS")}
              {statusChecker() === SubmittedApplicationStatus.open && (
                <span className="about-applications__status-info-wait">
                  {adminChecker()
                    ? t<string>("STATUSES.OPEN")
                    : t<string>("STATUSES.IN_PROGRESS")}
                </span>
              )}
              {statusChecker() === SubmittedApplicationStatus.inProgress && (
                <span className="about-applications__status-info-wait">
                  {t<string>("STATUSES.IN_PROGRESS")}
                </span>
              )}
              {statusChecker() === SubmittedApplicationStatus.sendToPartner && (
                <span className="about-applications__status-info-wait">
                  {adminChecker()
                    ? t<string>("STATUSES.SEND_TO_PARTNER")
                    : t<string>("STATUSES.IN_PROGRESS")}
                </span>
              )}
              {statusChecker() === SubmittedApplicationStatus.approved && (
                <span className="about-applications__status-info-approved">
                  {adminChecker()
                    ? t<string>("STATUSES.PARTNER_RESPONSE_APPROVED")
                    : t<string>("STATUSES.APPROVED")}
                </span>
              )}
              {statusChecker() === SubmittedApplicationStatus.declined && (
                <span className="about-applications__status-info-declined">
                  {adminChecker()
                    ? t<string>("STATUSES.PARTNER_RESPONSE_APPROVED")
                    : t<string>("STATUSES.DECLINED")}
                </span>
              )}
              {statusChecker() === SubmittedApplicationStatus.counterOffer && (
                <span className="about-applications__status-info-proposal">
                  {adminChecker()
                    ? t<string>("STATUSES.PARTNER_RESPONSE_COUNTER-OFFER")
                    : t<string>("STATUSES.PROPOSAL")}
                </span>
              )}
              {statusChecker() ===
                SubmittedApplicationStatus.acceptedByClient && (
                <span className="about-applications__status-info-approved">
                  {adminChecker()
                    ? t<string>("STATUSES.ACCEPTED_BY_CLIENT")
                    : t<string>("STATUSES.ACCEPTED")}
                </span>
              )}
              {statusChecker() ===
                SubmittedApplicationStatus.rejectedByClient && (
                <span className="about-applications__status-info-declined">
                  {adminChecker()
                    ? t<string>("STATUSES.REJECTED_BY_CLIENT")
                    : t<string>("STATUSES.REJECTED")}
                </span>
              )}
              {adminChecker() && (
                <div>
                  {statusChecker() ===
                    SubmittedApplicationStatus.noResponseFromClient && (
                    <span className="about-applications__status-info-declined">
                      {t<string>("STATUSES.NO_RESPONSE_FROM_CLIENT")}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          {window.location.pathname
            .split("/")
            .includes("submitted-applications") &&
            application?.comments?.collection.length > 0 && (
              <div className="about-applications__status-info">
                Comments:
                <div className="admin-comments-list">
                  {application?.comments?.collection.map(
                    (comment: Public.SubmittedApplicationComment) => (
                      <div
                        className="admin-comments-list__item"
                        key={comment.id}>
                        <span className="admin-comments-list__item-text">{`${comment.comment} (${comment.comment_for})`}</span>
                        <span className="admin-comments-list__item-date">{`${comment.created_at.slice(
                          8,
                          10
                        )}.${comment.created_at.slice(
                          5,
                          7
                        )}.${comment.created_at.slice(0, 4)}`}</span>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          {application?.comment?.comment && (
            <div className="users-buttons__admin-res admin-res">
              <p className="admin-res__pre">
                {t<string>("SUBMITTED_APPLICATIONS.ADMIN_RESPONSE")}
                <span className="admin-res__main">
                  {application?.comment?.comment}
                </span>
              </p>
            </div>
          )}
          {window.location.pathname.split("/").includes("results-list") && (
            <>
              {(statusChecker() === SubmittedApplicationStatus.approved ||
                statusChecker() === SubmittedApplicationStatus.counterOffer ||
                statusChecker() ===
                  SubmittedApplicationStatus.acceptedByClient ||
                statusChecker() ===
                  SubmittedApplicationStatus.rejectedByClient) && (
                <div>
                  <div className="about-applications__users-buttons users-buttons">
                    <div className="users-buttons__box">
                      <button
                        onClick={() =>
                          submittedApplicationDecision(userStatuses.accepted)
                        }
                        className="users-buttons__box-accept">
                        {t<string>("SUBMITTED_APPLICATIONS.ACCEPT_APPLICATION")}
                      </button>
                      <button
                        onClick={() =>
                          submittedApplicationDecision(userStatuses.declined)
                        }
                        className="users-buttons__box-decline">
                        {t<string>(
                          "SUBMITTED_APPLICATIONS.DECLINE_APPLICATION"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <button
                className="users-buttons__box-chat"
                onClick={() => chatOpener()}>
                {t<string>("SUBMITTED_APPLICATIONS.LIVE_CHAT")}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowSubmittedApplication;
