import { useEffect, useState, Fragment } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import FirstStep from "../FirstStep";
import FourthStep from "../FourthStep";
import SecondStep from "../SecondStep";
import ThirdStep from "../ThirdStep";

import back from "../../../assets/photos/all/questionnaire-back.svg";

import { steps } from "../../../core/constants/steps";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../core/store";

const QuestionnaireSteps = () => {
  const [questionsStep, setQuestionsStep] = useState(1);
  const { t } = useTranslation();
  const [type, setType] = useState<string>("");
  const navigate = useNavigate();
  const appLanguage = useAppSelector((state) => state.app.appLanguage);

  useEffect(() => {
    if (sessionStorage.getItem("currentStep")) {
      setQuestionsStep(JSON.parse(sessionStorage.getItem("currentStep") || ""));
    }
  }, []);

  const goBack = () => {
    switch (questionsStep) {
      case 1:
        navigate(-1);
        break;
      case 2:
        setQuestionsStep(1);
        break;
      case 3:
        setQuestionsStep(2);
        break;
      case 4:
        setQuestionsStep(3);
        break;
      default:
        setQuestionsStep(1);
    }
  };

  return (
    <div className="questionnaire">
      <div
        className={classNames({
          questionnaire__container: true,
          "questionnaire__container-first": questionsStep === 1 && 2,
          "questionnaire__container-long":
            (type === "4" && questionsStep === 1) ||
            (type === "5" && questionsStep === 1) ||
            (type === "6" && questionsStep === 1),
          "questionnaire__container-merg": type === "7" && questionsStep === 1,
        })}
      >
        <h1 className="questionnaire__container-title">
          {t<string>("PROFILE.PROFILE_TITLE")}
        </h1>
        <div className="questionnaire__container-steps questionnaire-steps">
          {steps.map((step, index) => (
            <Fragment key={step.id}>
              <div className="questionnaire-steps__box steps-box">
                <div className="steps-box__number">
                  <div
                    className={classNames({
                      "steps-box__number-num": true,
                      "steps-box__number-num-active": questionsStep === step.id,
                      "steps-box__number-num-passed": questionsStep > step.id,
                    })}
                    onClick={() => {
                      if (
                        (JSON.parse(sessionStorage.getItem("stepper") || "")
                          .firstStepConfirm &&
                          questionsStep === 1) ||
                        (JSON.parse(sessionStorage.getItem("stepper") || "")
                          .secondStepConfirm &&
                          questionsStep === 2) ||
                        (JSON.parse(sessionStorage.getItem("stepper") || "")
                          .thirdStepConfirm &&
                          questionsStep === 3) ||
                        step.id < questionsStep
                      ) {
                        setQuestionsStep(step.id);
                        sessionStorage.setItem(
                          "currentStep",
                          JSON.stringify(step.id)
                        );
                      } else {
                        toast.error(t<string>("PROFILE.ALL_FIELDS"));
                      }
                    }}
                  >
                    {step.id}
                  </div>
                </div>
                <span
                  className={classNames({
                    "steps-box__desc": true,
                    "steps-box__desc-active": questionsStep >= step.id,
                    "steps-box__desc_margin_en":
                      index === 1 && appLanguage === "en",
                    "steps-box__desc_margin_bg":
                      index === 1 && appLanguage !== "en",
                  })}
                >
                  {t<string>(`PROFILE.${step.text}`)}
                </span>
              </div>
              {index < 3 && (
                <div
                  className={classNames({
                    lineBetweenSteps: true,
                    "lineBetweenSteps-active": questionsStep >= step.id,
                  })}
                ></div>
              )}
            </Fragment>
          ))}
          <button
            onClick={goBack}
            className="questionnaire__container-back questionnaire-back"
          >
            <img
              className="questionnaire-back__icon"
              src={back}
              alt="back arrow"
            />
            <span className="questionnaire-back__txt">
              {t<string>("PROFILE.BACK")}
            </span>
          </button>
        </div>
        <div className="questionnaire__container-page">
          {questionsStep === 1 && (
            <FirstStep
              setType={setType}
              questionsStep={questionsStep}
              setQuestionsStep={setQuestionsStep}
            />
          )}
          {questionsStep === 2 && (
            <SecondStep
              questionsStep={questionsStep}
              setQuestionsStep={setQuestionsStep}
            />
          )}
          {questionsStep === 4 && <ThirdStep />}
          {questionsStep === 3 && (
            <FourthStep
              questionsStep={questionsStep}
              setQuestionsStep={setQuestionsStep}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireSteps;
