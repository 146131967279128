import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import useHttpGet from "../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../core/http";
import { UIRoutes } from "../../../core/router";
import { Private } from "../../../core/models";

import star from "../../../assets/photos/profile/application-empty.svg";
import QuestionnaireCard from "../Card/QuestionnaireCard";
import { useTranslation } from "react-i18next";

const QuestionnairesList = () => {
  const [allAplications, setAllApplications] = useState<any>([]);
  const [filteredApplications, setFilteredApplications] = useState<any>([]);
  const [changedId, setChangedId] = useState(0);
  const [updateApplication, setUpdateApplication] = useState(false);
  const [userQuestionnaires, setUserQuestionnries] = useState(0);
  const [selector, setSelector] = useState("All");

  // const [pagination, setPagination] = useState({
  //   cursor: undefined,
  //   per_page: 50,
  // });

  const { t } = useTranslation();

  const { fetchedData: documents } = useHttpGet<any>(
    `${APIRoutes.USER_DOCUMENTS}`
  );

  useHttpGet<any>(APIRoutes.USER_SUBMITTED_APPLICATIONS, {
    resolve: (response) => {
      setAllApplications(response?.payload?.collection);
      setFilteredApplications(response?.payload?.collection);
      setUpdateApplication(false);
    },
    condition: selector === "All",
    dependencies: [changedId, updateApplication === true],
  });

  const approved = allAplications.filter(
    (item: any) =>
      item.status === "partner_response_approved" ||
      item.status === "accepted_by_client"
  );

  const declined = allAplications.filter(
    (item: any) =>
      item.status === "partner_response_rejected" ||
      item.status === "rejected_by_client"
  );

  const inProgress = allAplications.filter(
    (item: any) => item.status === "in_progress"
  );

  const handleCategory = (category: any) => {
    switch (category) {
      case "Approved":
        setFilteredApplications(approved);
        setSelector("Approved");
        break;

      case "Declined":
        setFilteredApplications(declined);
        setSelector("Declined");
        break;

      case "In progress":
        setFilteredApplications(inProgress);
        setSelector("Pending");
        break;

      default:
        setFilteredApplications(allAplications);
        setSelector("All");
    }
  };

  return (
    <>
      <div className="applications">
        <div className="application-list__switchers">
          <button
            onClick={() => {
              handleCategory("All");
            }}
            className={classNames({
              "application-list__switchers-btn": true,
              "application-list__switchers-btn-active": selector === "All",
            })}
          >
            {t<string>("PROFILE.ALL")}
          </button>
          <button
            onClick={() => handleCategory("Approved")}
            className={classNames({
              "application-list__switchers-btn": true,
              "application-list__switchers-btn-active": selector === "Approved",
            })}
          >
            {t<string>("PROFILE.APPROVED")} ({approved.length})
          </button>
          <button
            onClick={() => handleCategory("Declined")}
            className={classNames({
              "application-list__switchers-btn": true,
              "application-list__switchers-btn-active": selector === "Declined",
            })}
          >
            {t<string>("PROFILE.DECLINED")} ({declined.length})
          </button>
          <button
            onClick={() => handleCategory("In progress")}
            className={classNames({
              "application-list__switchers-btn": true,
              "application-list__switchers-btn-active": selector === "Pending",
            })}
          >
            {t<string>("PROFILE.IN_PROGRESS")} ({inProgress.length})
          </button>
        </div>
        {allAplications.length === 0 ? (
          <div className="review-roster__empty">
            <img className="review-roster__empty-image" src={star} alt="star" />
            <p className="review-roster__empty-txt">
              {t<string>("PROFILE.NO_APPLICATIONS")}
            </p>
            <Link
              to={`/${UIRoutes.RESULTS_LIST}`}
              className="review-roster__empty-btn"
            >
              {t<string>("PROFILE.GO_TO_CALC")}
            </Link>
          </div>
        ) : (
          <div className="applications__application-list application-list">
            <div className="application-list__content">
              <ul className="application-list__content-notitia">
                {filteredApplications.length !== 0
                  ? filteredApplications.map(
                      (application: Private.Application) => (
                        <li key={application.id}>
                          <QuestionnaireCard
                            application={application}
                            setChangedId={setChangedId}
                            documents={documents}
                            setUpdateApplication={setUpdateApplication}
                          />
                        </li>
                      )
                    )
                  : allAplications.map((application: Private.Application) => (
                      <li key={application.id}>
                        <QuestionnaireCard
                          application={application}
                          setChangedId={setChangedId}
                          documents={documents}
                          setUpdateApplication={setUpdateApplication}
                        />
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionnairesList;
