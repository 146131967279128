import { FC, useContext } from "react";
import { AppContext } from "../../../App";

import ResultsCalculator from "../../ResultsCalculator";
import BenefitsContainer from "../../Benefits";
import ConsumerBanner from "../ConsumerBanner";
import CarLeasingBanner from "../CarLeasingBanner/index";
import RefinancingBanner from "../RefinancingBanner";
import MergingLoansBanner from "../MergingLoansBanner/index";
import MortgageLoansBanner from "../MortgageLoansBanner/index";
import QuickLoansBanner from "../QuickLoansBanner/index";
import Offers from "../../Offers";
import Articles from "../../Articles";
import Questions from "../../Questions";
import Guide from "../../Guide";
import CreditCardBanner from "../CreditCardBanner/CreditCardBanner";
import Banner from "../../Banner/Banner";

const ResultsForm: FC = () => {
  const appContext = useContext(AppContext);

  const { selectedLoan } = appContext;

  return (
    <div className="results-container">
      {selectedLoan === 1 && <ConsumerBanner />}
      {selectedLoan === 2 && <CreditCardBanner />}
      {selectedLoan === 3 && <QuickLoansBanner />}
      {selectedLoan === 4 && <MortgageLoansBanner />}
      {selectedLoan === 5 && <CarLeasingBanner />}
      {selectedLoan === 6 && <RefinancingBanner />}
      {selectedLoan === 7 && <MergingLoansBanner />}

      <ResultsCalculator />
      <div className="guide-wrapper">
        <Guide />
      </div>
      <Offers />
      <BenefitsContainer />
      {/* <Articles /> */}
      <Questions />
      <Banner />
    </div>
  );
};

export default ResultsForm;
