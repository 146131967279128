export const steps = [
  {
    id: 1,
    text: "PERSONAL_INFO",
  },
  {
    id: 2,
    text: "FAMILY_INFO",
  },
  {
    id: 3,
    text: "LOANS_INFO",
  },
  {
    id: 4,
    text: "OCCUPATION",
  },
];
