import { useState, useContext } from "react";

import { AppContext } from "../../../../App";
import CreditsMerging from "../CreditsMerging";
import CreditsMortgage from "../CreditsMortgage";
import CreditsQuickLoans from "../CreditsQuickLoans";
import CreditsRefinancing from "../CreditsRefinancing";
import CreditsCarLeasing from "../CreditsCarLeasing";
import CreditsConsumer from "../CreditsConsumer";

import { credits_list } from "../../../../core/constants/credits";

import credits_arrow_down from "../../../../assets/photos/credits/credits_arrow_down.svg";
import credits_arrow_up from "../../../../assets/photos/credits/credits_arrow_up.svg";
import consumer_icon from "../../../../assets/photos/all/consumer_icon.svg";
import leasing_icon from "../../../../assets/photos/all/leasing_icon.svg";
import merging_icon from "../../../../assets/photos/all/merging_icon.svg";
import mortgage_icon from "../../../../assets/photos/all/mortgage_icon.svg";
import quick_icon from "../../../../assets/photos/all/quick_icon.svg";
import refinancing_icon from "../../../../assets/photos/all/refinancing_icon.svg";
import { useAppSelector } from "../../../../core/store";
import { useTranslation } from "react-i18next";

const CreditsMainList = () => {
  const [opener, setOpener] = useState(false);
  const { t } = useTranslation();
  const credits_list = [
    {
      id: 1,
      title: {
        en: t<string>("LOANS.CONSUMER_LOANS"),
        bg: t<string>("LOANS.CONSUMER_LOANS"),
      },
      icon: consumer_icon,
      href: "Consumer loans",
    },
    {
      id: 3,
      title: {
        en: t<string>("LOANS.QUICK_LOAN"),
        bg: t<string>("LOANS.QUICK_LOAN"),
      },
      icon: quick_icon,
      href: "Quick loans",
    },
    {
      id: 4,
      title: {
        en: t<string>("LOANS.MORTGAGE_LOANS"),
        bg: t<string>("LOANS.MORTGAGE_LOANS"),
      },
      icon: mortgage_icon,
      href: "Mortgage loans",
    },
    {
      id: 5,
      title: {
        en: t<string>("LOANS.CAR_LEASING"),
        bg: t<string>("LOANS.CAR_LEASING")
      },
      icon: leasing_icon,
      href: "Car leasing",
    },
    {
      id: 6,
      title: {
        en: t<string>("LOANS.REFINANCING"),
        bg: t<string>("LOANS.REFINANCING"),
      },
      icon: refinancing_icon,
      href: "Refinancing",
    },
    {
      id: 7,
      title: {
        en: t<string>("LOANS.MERGING_LOANS"),
        bg: t<string>("LOANS.MERGING_LOANS"),
      },
      icon: merging_icon,
      href: "Merging loans",
    },
    
  ];
  const appLanguage = useAppSelector((state) => state.app.appLanguage);
  const appContext = useContext(AppContext);

  const { selectedLoan, setSelectedLoan } = appContext;

  const selector = (tab: string) => {
    const elementIndex = credits_list
      .map((item: any) => item.title.bg)
      .indexOf(tab);

    // setSelectedLoan(elementIndex + 1);
    setOpener(false);
    const section = document.getElementById(credits_list[elementIndex]?.href);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const current_tab = credits_list
    .filter((tab: any, i: number) => tab.id === selectedLoan)
    .map((item: { id: number; title: any; icon: File }) => item.title.en)
    .join("");

  return (
    <main className="credits-container-main">
      <ul className="credits-container-main__credits-navigation-list credits-navigation-list">
        {credits_list.map((credit: any, index: number) => {
          const { id, title, icon, href } = credit;
          return (
            <div className="credits-navigation-list-card" key={id}>
              <hr className={`horizontal-line-${id}`} />
              <li className="credits-navigation-list-item">
                <img
                  src={icon}
                  alt={appLanguage === "en" ? title.en : title.bg}
                />
                <a
                  href={`#${href}`}
                  onClick={(event) => {
                    event.preventDefault();
                    setSelectedLoan(index + 1);
                    const section = document.getElementById(href);
                    if (section) {
                      section.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                  className={
                    selectedLoan === index + 1
                      ? "credits-navigation-list-item__link-active"
                      : "credits-navigation-list-item__link"
                  }>
                  {appLanguage === "en" ? title.en : title.bg}
                </a>
              </li>
            </div>
          );
        })}
      </ul>
      <div className="credits-tabs-mobile">
        <div className="credits-mobile__box">
          <div
            className="credits-mobile__box-selected"
            onClick={() => setOpener(!opener)}>
            <div className="credits-mobile__box-selected-head">
              {selectedLoan === 1 && (
                <img src={consumer_icon} alt="consumer_icon" />
              )}
              {selectedLoan === 2 && <img src={quick_icon} alt="quick_icon" />}
              {selectedLoan === 3 && (
                <img src={mortgage_icon} alt="mortgage_icon" />
              )}
              {selectedLoan === 4 && (
                <img src={leasing_icon} alt="leasing_icon" />
              )}
              {selectedLoan === 5 && (
                <img src={refinancing_icon} alt="refinancing_icon" />
              )}
              {selectedLoan === 6 && (
                <img src={merging_icon} alt="merging_icon" />
              )}
              <p>{current_tab}</p>
            </div>
            <hr />
            {opener ? (
              <img src={credits_arrow_up} alt="credits_arrow_down" />
            ) : (
              <img src={credits_arrow_down} alt="credits_arrow_down" />
            )}
          </div>
          {opener && (
            <ul className="credits-mobile__box-selector">
              {credits_list.map((tab: any, index: number) => (
                <div key={index}>
                  <li
                    className="credits-mobile__box-selector-item"
                    onClick={(event) => {
                      event.preventDefault();
                      selector(
                        appLanguage === "en" ? tab.title.en : tab.title.bg
                      );
                    }}>
                    <img src={tab.icon} alt="icon" />
                    <a
                      className={
                        selectedLoan === index + 1
                          ? "credits-mobile__box-selector-item-choosen"
                          : "credits-mobile__box-selector-item-count"
                      }
                      // href={`#${credits_list[index + 1]?.href}`}
                      >
                      {appLanguage === "en" ? tab.title.en : tab.title.bg}
                    </a>
                  </li>
                  <hr />
                </div>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="credits-container-main-content">
        <CreditsConsumer />
        <CreditsQuickLoans />
        <CreditsMortgage />
        <CreditsCarLeasing />
        <CreditsRefinancing />
        <CreditsMerging />
      </div>
    </main>
  );
};

export default CreditsMainList;
