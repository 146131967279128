import { FC, useState } from "react";
import classNames from "classnames";

import { Partner } from "../../../core/models";
import { clientsData } from "../../../core/constants/clientsTableData";

import approved from "../../../assets/photos/partner/approved-icon.svg";
import declined from "../../../assets/photos/partner/declined-icon.svg";
import client_declined from "../../../assets/photos/partner/client-declined.svg";
import confirm from "../../../assets/photos/partner/confirm-icon.svg";
import counter_offer from "../../../assets/photos/partner/counter_offer-icon.svg";
import default_signature from "../../../assets/photos/partner/default-signature.svg";
import new_status from "../../../assets/photos/partner/new-icon.svg";
import message from "../../../assets/photos/partner/message-icon.svg";
import more from "../../../assets/photos/partner/more-icon.svg";
import question_mark from "../../../assets/photos/partner/question_mark.svg";
import bookmark_active from "../../../assets/photos/partner/bookmark_active.svg";
import bookmark_default from "../../../assets/photos/partner/bookmark_default.svg";
import consumer from "../../../assets/photos/partner/consumer.svg";
import merging from "../../../assets/photos/partner/merging.svg";
import credit from "../../../assets/photos/partner/credit.svg";
import car_leasing from "../../../assets/photos/partner/car-leasing.svg";
import quick_loans from "../../../assets/photos/partner/quick-loans.svg";
import refinancing from "../../../assets/photos/partner/refinancing.svg";
import mortgage from "../../../assets/photos/partner/mortgage.svg";
import next from "../../../assets/photos/partner/next-circle.svg";
import insurance from "../../../assets/photos/partner/insurance-icon.svg";
import pin_icon from "../../../assets/photos/partner/pin-icon.svg";
import prev from "../../../assets/photos/partner/prev-circle.svg";
import edit from "../../../assets/photos/partner/edit-icon.svg";
import download from "../../../assets/photos/partner/download-icon.svg";
import archive from "../../../assets/photos/partner/pin-icon.svg";
import sort_icon from "../../../assets/photos/partner/sort-icon.svg";
import { useTranslation } from "react-i18next";

interface ClientsTableProps {
  status: string;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
}

const ClientsTable: FC<ClientsTableProps> = ({
  status,
  currentPage,
  setCurrentPage,
}: ClientsTableProps) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showLoans, setShowLoans] = useState<boolean>(false);
  const [showLoansCheck, setShowLoansCheck] = useState<boolean>(false);
  const [isMarked, setIsMarked] = useState<boolean>(false);
  const [steper, setSteper] = useState<number>(0);
  const [secondSteper, setSecondSteper] = useState<number>(0);
  const [showMark, setShowMark] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showSort, setShowSort] = useState<boolean>(false);
  const [showProductSort, setShowProductSort] = useState<boolean>(false);
  const [productType, setProductType] = useState<boolean>(false);
  const [showSortType, setShowSortType] = useState<boolean>(false);
  const [clientsPerPage] = useState<number>(15);

  const { t } = useTranslation();

  const clientsList = clientsData.filter((person: Partner.Client) => {
    if (status === "New") {
      return person.status === "New";
    }
    if (status === "In progress") {
      return (
        person.status === "Confirmation" || person.status === "Counter offer"
      );
    }
    if (status === "Approved") {
      return person.status === "Signed loan";
    }
    if (status === "Declined") {
      return (
        person.status === "Declined" || person.status === "Declined by client"
      );
    }
  });

  const countLastClient = currentPage * clientsPerPage;
  const countFirstClient = countLastClient - clientsPerPage;
  const currentClients = clientsList.slice(countFirstClient, countLastClient);
  const numOfPages = Math.ceil(clientsList.length / clientsPerPage);
  const pageNumbers = Array.from(Array(numOfPages + 1).keys()).slice(1);

  const nextPage = () => {
    if (currentPage !== numOfPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="clients-table-container">
      <table className="clients-table">
        <thead>
          <tr className="clients-table-head__row">
            <td
              className={classNames({
                "clients-table-head__cell": true,
              })}
            >
              <input className="clients-table-head__cell-inp" type="checkbox" />
            </td>

            <td className="clients-table-head__cell">ID</td>
            <td className="clients-table-head__cell">Client</td>
            <td className="clients-table-head__cell">
              Credit score
              <button
                onClick={() => {
                  setShowOptions(!showOptions);
                }}
              >
                <img
                  src={question_mark}
                  alt="question_mark"
                  className="clients-table-head__cell-mark"
                />
              </button>
              {showOptions && (
                <div
                  className="credit-score-dropdown"
                  onMouseLeave={() => setShowOptions(false)}
                >
                  <div className="credit-score-dropdown-row">
                    <span className="credit-score-dropdown-row__item-bad" />

                    <span className="credit-score-dropdown-row__txt">
                      Bad 0-200
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <span className="credit-score-dropdown-row__item-normal" />
                    <span className="credit-score-dropdown-row__txt">
                      Normal 200-400
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <span className="credit-score-dropdown-row__item-good" />
                    <span className="credit-score-dropdown-row__txt">
                      Good 400-600
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <span className="credit-score-dropdown-row__item-very-good" />
                    <span className="credit-score-dropdown-row__txt">
                      Very good 600-800
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <span className="credit-score-dropdown-row__item-excellent" />
                    <span className="credit-score-dropdown-row__txt">
                      Excellent 800-1000
                    </span>
                  </div>
                </div>
              )}
            </td>
            <td className="clients-table-head__cell">Product ID</td>

            <td
              onClick={() => setShowLoansCheck(!showLoansCheck)}
              className="clients-table-head__cell"
            >
              <img
                onClick={(e) => {
                  setShowLoans(!showLoans);
                  e.stopPropagation();
                }}
                src={question_mark}
                alt="question_mark"
                className="clients-table-head__cell-loan-mark"
              />
              Type of loan
              {showLoansCheck && (
                <div
                  onMouseLeave={() => setShowLoans(false)}
                  className="credit-score-loans-dropdown-checked"
                >
                  <div className="credit-score-dropdown-row">
                    <input type="checkbox" name="" id="" />
                    <img src={consumer} alt="consumer" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Consumer loans
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <input type="checkbox" />
                    <img src={merging} alt="merging" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Merging loans
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <input type="checkbox" />
                    <img src={credit} alt="credit" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Credit card
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <input type="checkbox" />
                    <img src={car_leasing} alt="car_leasing" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Car leasing
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <input type="checkbox" />
                    <img src={mortgage} alt="mortgage" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Mortgage
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <input type="checkbox" />
                    <img src={refinancing} alt="refinancing" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Refinancing
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <input type="checkbox" />
                    <img src={quick_loans} alt="quick_loans" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Quick loans
                    </span>
                  </div>
                </div>
              )}
              {showLoans && (
                <div
                  onMouseLeave={() => setShowLoans(false)}
                  className="credit-score-loans-dropdown"
                >
                  <div className="credit-score-dropdown-row">
                    <img src={consumer} alt="consumer" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Consumer loans
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <img src={merging} alt="merging" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Merging loans
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <img src={credit} alt="credit" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Credit card
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <img src={car_leasing} alt="car_leasing" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Car leasing
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <img src={mortgage} alt="mortgage" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Mortgage
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <img src={refinancing} alt="refinancing" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Refinancing
                    </span>
                  </div>
                  <div className="credit-score-dropdown-row">
                    <img src={quick_loans} alt="quick_loans" />
                    <span className="credit-score-dropdown-row__loan-txt">
                      -
                    </span>
                    <span className="credit-score-dropdown-row__loan-txt">
                      Quick loans
                    </span>
                  </div>
                </div>
              )}
            </td>
            <td className="clients-table-head__cell">Sum</td>
            <td className="clients-table-head__cell">Term</td>
            <td className="clients-table-head__cell">Rate</td>
            <td className="clients-table-head__cell">Status</td>
            <td className="clients-table-head__cell">Signature</td>
            <td className="clients-table-head__cell">Date</td>
            <td className="clients-table-head__cell"></td>
          </tr>
        </thead>
        <tbody>
          {currentClients.map((client: Partner.Client) => (
            <tr
              key={client.id}
              onMouseEnter={() => {
                setSteper(client.id);
                setShowMark(true);
              }}
              onMouseLeave={() => setShowMark(false)}
              className="clients-table-body__row"
            >
              <td className="clients-table-body__cell">
                <input type="checkbox" name="" id="" />
                {showMark && client.id === steper && (
                  <img
                    onClick={() => {
                      setIsMarked(!isMarked);
                    }}
                    className="clients-table-body__cell-bookmark"
                    src={isMarked ? bookmark_active : bookmark_default}
                    alt={`${isMarked ? bookmark_active : bookmark_default}`}
                  />
                )}
              </td>
              <td className="clients-table-body__cell">{client.id}</td>
              <td
                onMouseEnter={() => {
                  setSteper(client.id);
                  setShowSort(true);
                }}
                onMouseLeave={() => setShowSort(false)}
                className="clients-table-body__cell"
              >
                {showSort && client.id === steper && (
                  <img
                    onClick={() => {
                      setSteper(client.id);
                      setShowSortType(!showSortType);
                    }}
                    src={sort_icon}
                    alt="sort"
                    className="clients-table-body__cell-sort-btn"
                  />
                )}
                {showSortType && client.id === steper && (
                  <div
                    onMouseLeave={() => setShowSortType(false)}
                    className="clients-table-body__cell-sort-txt"
                  >
                    Group by client ID
                  </div>
                )}
                <span className="clients-table-body__cell-client-name">
                  {client.client}
                  <img
                    className="clients-table-body__cell-message-box"
                    src={message}
                    alt="message-box"
                  />
                </span>
              </td>
              <td className="clients-table-body__cell">
                <span
                  className={classNames({
                    "credit-score-dropdown-row__item-bad-asigned":
                      client.credit_score >= 0 && client.credit_score <= 200,
                    "credit-score-dropdown-row__item-normal-asigned":
                      client.credit_score > 200 && client.credit_score <= 400,
                    "credit-score-dropdown-row__item-good-asigned":
                      client.credit_score > 400 && client.credit_score <= 600,
                    "credit-score-dropdown-row__item-very-good-asigned":
                      client.credit_score > 600 && client.credit_score <= 800,
                    "credit-score-dropdown-row__item-excellent-asigned":
                      client.credit_score > 800 && client.credit_score <= 1000,
                  })}
                />
                <span className="clients-table-body__cell-score-txt">
                  {client.credit_score}
                </span>
              </td>
              <td
                onMouseEnter={() => {
                  setSecondSteper(client.id);
                  setShowProductSort(true);
                }}
                onMouseLeave={() => setShowProductSort(false)}
                className="clients-table-body__cell"
              >
                {showProductSort && client.id === secondSteper && (
                  <img
                    onClick={() => {
                      setSecondSteper(client.id);
                      setProductType(!productType);
                    }}
                    src={sort_icon}
                    alt="sort"
                    className="clients-table-body__cell-product-sort"
                  />
                )}
                {productType && client.id === secondSteper && (
                  <div
                    onMouseLeave={() => setProductType(false)}
                    className="clients-table-body__cell-sort-txt"
                  >
                    Group by product ID
                  </div>
                )}
                <span className="clients-table-body__cell-client-name">
                  {client.product_id}
                </span>
              </td>
              <td className="clients-table-body__cell">
                {client.loan_type === "Green loan" && (
                  <img
                    src={consumer}
                    alt="consumer"
                    className="table-cell-loan"
                  />
                )}

                {client.loan_type === "Household Mortgage" && (
                  <img
                    src={mortgage}
                    alt="mortgage"
                    className="table-cell-loan"
                  />
                )}

                {client.loan_type === "Red Credit Card" && (
                  <img src={credit} alt="credit" className="table-cell-loan" />
                )}
                {client.loan_type === "Insurance" && (
                  <img
                    src={insurance}
                    alt="insurance"
                    className="table-cell-loan"
                  />
                )}

                {client.loan_type}
              </td>
              <td className="clients-table-body__cell">
                {`${client.sum
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} BGN`}
              </td>
              <td className="clients-table-body__cell">
                {" "}
                {client.term} {t<string>("CALCULATOR.MONTHS")}
              </td>
              <td className="clients-table-body__cell"> {client.rate}%</td>
              <td className="clients-table-body__cell">
                {client.status === "New" && (
                  <img
                    className="clients-table-body__cell-status-img"
                    src={new_status}
                    alt={`${new_status}`}
                  />
                )}
                {client.status === "Counter offer" && (
                  <img
                    className="clients-table-body__cell-status-img"
                    src={counter_offer}
                    alt={`${counter_offer}`}
                  />
                )}
                {client.status === "Confirmation" && (
                  <img
                    className="clients-table-body__cell-status-img"
                    src={confirm}
                    alt={`${confirm}`}
                  />
                )}
                {client.status === "Signed loan" && (
                  <img
                    className="clients-table-body__cell-status-img"
                    src={approved}
                    alt={`${approved}`}
                  />
                )}
                {client.status === "Declined" && (
                  <img
                    className="clients-table-body__cell-status-img"
                    src={declined}
                    alt={`${declined}`}
                  />
                )}
                {client.status === "Declined by client" && (
                  <img
                    className="clients-table-body__cell-status-img"
                    src={client_declined}
                    alt={`${client_declined}`}
                  />
                )}
                <span
                  className={classNames({
                    "clients-table-body__cell-status-new":
                      client.status === "New",
                    "clients-table-body__cell-status-confirm":
                      client.status === "Confirmation",
                    "clients-table-body__cell-status-counter-offer":
                      client.status === "Counter offer",
                    "clients-table-body__cell-status-approved":
                      client.status === "Signed loan",
                    "clients-table-body__cell-status-declined":
                      client.status === "Declined",
                    "clients-table-body__cell-status-client-declined":
                      client.status === "Declined by client",
                  })}
                >
                  {client.status}
                </span>
              </td>
              <td className="clients-table-body__cell">
                {client.signature === "Yes" && (
                  <img
                    className="clients-table-body__cell-signature"
                    src={approved}
                    alt="yes"
                  />
                )}
                {client.signature === "No" && (
                  <img
                    className="clients-table-body__cell-signature"
                    src={declined}
                    alt="no"
                  />
                )}
                {client.signature === "-" ? (
                  <img
                    className="clients-table-body__cell-default-signature"
                    src={default_signature}
                    alt="default_signature"
                  />
                ) : (
                  <span
                    className={classNames({
                      "clients-table-body__cell-status-signed":
                        client.signature === "Yes",
                      "clients-table-body__cell-status-declined":
                        client.signature === "No",
                    })}
                  >
                    {client.signature}
                  </span>
                )}
              </td>
              <td className="clients-table-body__cell"> {client.date}</td>
              <td className="clients-table-body__cell">
                <img
                  onClick={() => {
                    setSteper(client.id);
                    setShowEdit(!showEdit);
                  }}
                  src={more}
                  alt="more"
                  className="clients-table-body__cell-more-btn"
                />
                {steper === client.id && showEdit && (
                  <div className="credit-score-edit-dropdown">
                    <div className="credit-score-dropdown-row">
                      <img src={pin_icon} alt="pin_icon" />
                      <span className="credit-score-dropdown-row__loan-txt">
                        Pin
                      </span>
                    </div>
                    <div className="credit-score-dropdown-row">
                      <img src={edit} alt="edit" />
                      <span className="credit-score-dropdown-row__loan-txt">
                        Edit
                      </span>
                    </div>
                    <div className="credit-score-dropdown-row">
                      <img src={download} alt="download" />
                      <span className="credit-score-dropdown-row__loan-txt">
                        Download pdf
                      </span>
                    </div>
                    <div className="credit-score-dropdown-row">
                      <img src={archive} alt="archive" />
                      <span className="credit-score-dropdown-row__loan-txt">
                        Archive
                      </span>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="clients-table-pagination">
        <button type="button" onClick={prevPage}>
          <img src={prev} alt="prev" />
        </button>
        {pageNumbers.map((pageNum, index) => (
          <button
            type="button"
            onClick={() => setCurrentPage(pageNum)}
            key={index}
            className={classNames({
              "clients-table-pagination__item": true,
              "clients-table-pagination__item-active": currentPage === pageNum,
            })}
          >
            {pageNum}
          </button>
        ))}
        <button type="button" onClick={nextPage}>
          <img src={next} alt="next" />
        </button>
      </div>
    </div>
  );
};

export default ClientsTable;
