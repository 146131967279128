import { Private, Public } from "../../core/models";
import { useAppDispatch, useAppSelector } from "../../core/store";
import PrivateService from "../../core/services/private.service";
import {
  setModalType,
  setShowNotification,
} from "../../core/store/reducers/modal/modalSlice";
import { AppContext } from "../../App";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setDeleteNotification, setNotifications, } from "../../core/store/reducers/app/appDataSlice";
import NotificationCard from "./NotificationCard";
import deletIcon from "../../assets/photos/all/delete.svg"
import  { useNavigate }  from "react-router-dom"

const NotificationPopup = () => {
  const { notifications, appLanguage } = useAppSelector((state) => state.app);
  const [localNotif, setLocalNotif] = useState([...notifications])
  const [filteredNotif, setFilteredNotif] = useState<Public.Notification[]>([])
  const navigate = useNavigate()
  const dispatch = useAppDispatch();


  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const { setReloadChecker } = appContext;

  useEffect(() => {
    const filtered:any = notifications.filter(item => item.showed === 0 )
    setFilteredNotif([...filtered])
  },[notifications, localNotif])



  const handleShow = async (id: number, index: number) => {
    // const updatedNotifications = notifications.map((item, i) =>
    //   i === index ? { ...item, showed: 0 } : item );
    try {
      const response = await PrivateService.updateNotifications({
        [`ids[${index}]`]: id
      })
      const filtered:any = notifications.filter(item => item.showed === 0 && id !== item.id )
      if(response.data.success){
        dispatch(setNotifications(filtered))
      }
      // dispatch(setShowNotification(false));
      // dispatch(setModalType(""));
      setReloadChecker(!false);
      setLocalNotif([...notifications])
    } catch (e) {}
  };
  
  const arrWithID: any[] = [];

  notifications.forEach((notific: Public.Notification) => {
    if (notific.showed === 0) {
      arrWithID.push(...[(notific.id)]);
    }
  });

  
  const handleDelete = async (arrWithID: Public.Notification[]) => {
    try {
      const response = await PrivateService.updateAllNotifications({
        ids:arrWithID,
      });
      dispatch(setShowNotification(false));
      dispatch(setModalType(""));
      setReloadChecker(!false);
    } catch (e) {
      
    }
  };

  
  
  function findStatus(itemSearchStatus: Public.Notification, appLanguage: string) {
    if (itemSearchStatus.translations && itemSearchStatus.translations.en.text !== null && itemSearchStatus.translations.bg.text !== null) {
      const text = appLanguage === "en"  
      ? itemSearchStatus.translations.en.text 
      : itemSearchStatus.translations.bg.text;
      switch (true) {
        case text.includes(t<string>("STATUSES.APPROVED")):
          return t<string>("STATUSES.APPROVED");
        case text.includes(t<string>("STATUSES.IN_PROGRESS")):
          return t<string>("STATUSES.IN_PROGRESS");
        case text.includes(t<string>("STATUSES.DECLINED")):
          return t<string>("STATUSES.DECLINED");
            default:
              return '';
      }
    }
    return '';
  }
  

  const showAll = () => {
    navigate(`/account/profile`);
};

  return (
    <div
      className="notifications"
      onMouseEnter={() => {
        dispatch(setShowNotification(true));
        dispatch(setModalType("notifications"));
      }}
      onMouseLeave={() => {
        dispatch(setShowNotification(false));
        dispatch(setModalType(""));
      }}
      
    >
      {filteredNotif.length > 0 ? (
        <ul className="notifications-list">
          {filteredNotif
            .map((item: Public.Notification, index: number) => 
              <NotificationCard key={item.id} item={item} index={index} funcHandleShow={handleShow} funcSearchStatus={findStatus} funcFiltered={filteredNotif}/>
            )}
            <div className="notifications_container">
              <button className= "notifications_container__btn" onClick={showAll}>
                {t<string>("COMMON.SHOW_ALL")}
              </button>
              <button className= "notifications_container__btn" 
              onClick={()=>{handleDelete(arrWithID)}}
              >
                <img src={deletIcon} alt="delete" width={18}/>{t<string>("COMMON.DELETE_ALL_NOTIFICATIONS")}
              </button>
            </div>

            
        </ul>
      ) : (
        <div className="notifications-warning">
          <span className="notifications-warning__text">
            {t<string>("COMMON.NOTIFICATIONS_EMPTY")}
          </span>
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;
