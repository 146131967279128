import React, { FC } from "react";
import RegisterForm from "./RegisterForm/RegisterForm";
import AuthBase from "../AuthBase";
import { AuthSelectionType } from "../../core/services/enums";

type Props = {
  setSelectionType: (str: AuthSelectionType) => void;
  setCurrentEmail: (str: string) => void;
};

const RegisterContainer: FC<Props> = ({
  setSelectionType,
  setCurrentEmail,
}) => {
  return (
    <AuthBase>
      <RegisterForm
        setSelectionType={setSelectionType}
        setCurrentEmail={setCurrentEmail}
      />
    </AuthBase>
  );
};

export default RegisterContainer;
