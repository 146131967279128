import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

import { useAppDispatch, useAppSelector } from "../../../core/store";
import { currencyFormat } from "../../../core/functions/funtions";
import { setCalculator } from "../../../core/store/reducers/app/appDataSlice";
import { style } from "../../../core/constants/style";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const RefinancingForm = () => {
  const dispatch = useAppDispatch();

  const resultCalc = JSON.parse(localStorage.getItem("calcResult") || "{}");
  const { t } = useTranslation();

  useEffect(() => {
    if (resultCalc) {
      formikForm.setFieldValue("amount_1", +resultCalc.amount_1);
      formikForm.setFieldValue("term_1", +resultCalc.term_1);
      if (+resultCalc.current_amount) {
        formikForm.setFieldValue("current_amount", +resultCalc.current_amount);
      } else {
        formikForm.setFieldValue("current_amount", 1000);
      }
    }
  }, []);

  const valueFormValidationSchema = Yup.object().shape({
    repayment_amount: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .matches(/[^0]+/, "Sum can't be equal 0")
      .max(30, "Max 30 symbols"),
    current_amount: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .matches(/[^0]+/, "Sum can't be equal 0")
      .max(30, "Max 30 symbols"),
    term_1: Yup.string().matches(/[^0]+/, "Term can't be equal 0"),
  });

  const formikForm = useFormik<{
    amount_1: number;
    current_amount: number;
    term_1: number;
    type: string;
  }>({
    initialValues: {
      amount_1: 1000,
      current_amount: 1000,
      term_1: 3,
      type: "6",
    },
    // validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = (values: any) => {
    dispatch(setCalculator(values));
    localStorage.setItem(
      "calcResult",
      JSON.stringify({
        ...values,
      })
    );
  };

  const addAmount = () => {
    if (formikForm.values.amount_1 < 50000) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 + 100);
    }
    if (50000 - formikForm.values.amount_1 < 200) {
      formikForm.setFieldValue("amount_1", 50000);
    }
  };

  const removeAmount = () => {
    if (formikForm.values.amount_1 > 500) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 - 100);
    }
    if (formikForm.values.amount_1 - 200 < 500) {
      formikForm.setFieldValue("amount_1", 500);
    }
  };

  const addCurrent = () => {
    if (formikForm.values.current_amount < 50000) {
      formikForm.setFieldValue(
        "current_amount",
        formikForm.values.current_amount + 100
      );
    }
    if (50000 - formikForm.values.current_amount < 200) {
      formikForm.setFieldValue("current_amount", 50000);
    }
  };

  const removeCurrent = () => {
    if (formikForm.values.current_amount > 1000) {
      formikForm.setFieldValue(
        "current_amount",
        formikForm.values.current_amount - 100
      );
    }
    if (formikForm.values.current_amount - 200 < 1000) {
      formikForm.setFieldValue("current", 1000);
    }
  };

  const addTerm = () => {
    if (formikForm.values.term_1 < 120) {
      formikForm.setFieldValue("term_1", +formikForm.values.term_1 + 1);
    }
  };
  const removeTerm = () => {
    if (formikForm.values.term_1 > 3) {
      formikForm.setFieldValue("term_1", +formikForm.values.term_1 - 1);
    }
  };

  const coercion = (e: any, maxValue: number, field: string) => {
    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue(field, maxValue);
    } else {
      formikForm.setFieldValue(field, valueNum);
    }
  };

  return (
    <div className="calc">
    <form
      className="calc__form"
      onSubmit={formikForm.handleSubmit}
    >
      <div className="search-block-main">
        <div className="search-block-inputs">
          <div
            className="search-block-inputs__input-item  input-item"
            onBlur={() => {
              if (formikForm.values.amount_1 < 500) {
                formikForm.setFieldValue("amount_1", 500);
              }
            }}
          >
            <div className="input-item-main">
              <label className="input-item-main__label">
                {t<string>("CALCULATOR.LOAN_AMOUNT")}
              </label>
              <label className="ranges__controls-field">
                <input
                  type="text"
                  className={classNames({
                    "ranges__controls-field-put": true,
                    "ranges__controls-field-put__l": true,
                  })}
                  value={currencyFormat(formikForm.values.amount_1)}
                  onChange={(event) => {
                    coercion(event, 50000, "amount_1");
                  }}
                />
                {t<string>("CALCULATOR.BGN")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeAmount}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  type="range"
                  min={500}
                  max={50000}
                  step={100}
                  className="range__controls-input range-wrapper__input"
                  {...formikForm.getFieldProps("amount_1")}
                />
                <button
                  onClick={addAmount}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="input-item__details">
              <p>500 {t<string>("CALCULATOR.BGN")}</p>
              <p>50 000 {t<string>("CALCULATOR.BGN")}</p>
            </div>
          </div>
          <div
            className="search-block-inputs__input-item  input-item"
            onBlur={() => {
              if (formikForm.values.current_amount < 1000) {
                formikForm.setFieldValue("current_amount", 1000);
              }
            }}
          >
            <div className="input-item-main">
              <label className="input-item-main__label">{t<string>("CALCULATOR.CURRENT_AMOUNT")}</label>
              <label className="ranges__controls-field">
                <input
                  type="text"
                  className="ranges__controls-field-put"
                  value={currencyFormat(formikForm.values.current_amount)}
                  onChange={(event) => {
                    coercion(event, 50000, "current_amount");
                  }}
                />
                {t<string>("CALCULATOR.BGN")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeCurrent}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  type="range"
                  min={1000}
                  max={50000}
                  step={100}
                  className="range__controls-input range-wrapper__input"
                  {...formikForm.getFieldProps("current_amount")}
                />
                <button
                  onClick={addCurrent}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="input-item__details">
              <p>1 000 {t<string>("CALCULATOR.BGN")}</p>
              <p>50 000 {t<string>("CALCULATOR.BGN")}</p>
            </div>
          </div>
          <div
            className="search-block-inputs__input-item input-item"
            onBlur={() => {
              if (formikForm.values.term_1 < 3) {
                formikForm.setFieldValue("term_1", 3);
              }
            }}
          >
            <div className="input-item-main">
              <label className="input-item-main__label">
                {t<string>("CALCULATOR.LOAN_TERM")}
              </label>
              <label className="ranges__controls-field">
                <input
                  className="ranges__controls-field-put ranges__controls-field-term"
                  type="number"
                  value={formikForm.values.term_1}
                  onChange={(e) => {
                    let value = Number(e.target.value);
                    value > 120 || value < 0 || e.target.value === "0"
                      ? formikForm.setFieldValue("term_1", "120")
                      : formikForm.setFieldValue("term_1", e.target.value);
                    e.target.value === "" &&
                      formikForm.setFieldValue("term_1", "3");
                  }}
                />
                {t<string>("CALCULATOR.MONTHS")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeTerm}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  type="range"
                  min={3}
                  max={120}
                  step={1}
                  className="range__controls-input range-wrapper__input"
                  {...formikForm.getFieldProps("term_1")}
                />
                <button
                  onClick={addTerm}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="input-item__details">
              <p>3 {t<string>("CALCULATOR.MONTHS")}</p>
              <p>120 {t<string>("CALCULATOR.MONTHS")}</p>
            </div>
          </div>
        </div>
      </div>
      <button className="search-block-show" type="submit">
        {t<string>("CALCULATOR.BUTTON_LOGGED")}
      </button>
    </form>
    </div>
  );
};

export default RefinancingForm;
