import { useState, useContext } from "react";

import { AppContext } from "../../App";

import useHttpGet from "../../core/hooks/useHttpGet";
import { APIRoutes } from "../../core/http";
import { Private } from "../../core/models";

import defaultAvatar from "../../assets/photos/profile/default-avatar.svg";
import sortingArrow from "../../assets/photos/all/sorting-arrow.svg";
import search from "../../assets/photos/profile/search.svg";
import { toast } from "react-toastify";
import PrivateService from "../../core/services/private.service";
import { useTranslation } from "react-i18next";
import { MdOutlineDone } from "react-icons/md";
import ButtonExcel from "../ButtonExcel/ButtonExcel";

const AdminUsersWithoutQuestionnaire = () => {
  const [generalFilter, setGeneralFilter] = useState({
    "filter[relation][doesntHave]": "questionnaires",
  });
  const [filterType, setFilterType] = useState("filter[search]");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState<any>({
    "filter[relation][doesntHave]": "questionnaires",
  });
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });
  const [sent, setSent] = useState({
    isSent: false,
    id: 0,
  });

  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const { reloadChecker } = appContext;

  const { fetchedData } = useHttpGet<any>(APIRoutes.ADMIN_USERS, {
    dependencies: [filter, generalFilter, sorter, pagination, reloadChecker],

    query: { ...sorter, ...generalFilter, ...filter, ...pagination },
  });

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};

    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ [propName]: value || undefined });
  };

  const setPaginationPage = () => {
    setPagination(()=>{
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 }
      }else{
        return {...pagination, per_page: 50}
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const handleVerificationEmail = async (id: number) => {
    try {
      const response = await PrivateService.sendVerificationEmail(id);
      toast.success(response?.data?.message);
      setSent(() => {
        return {
          isSent: true,
          id,
        };
      });
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
  };

  return (
    <div className="admin">
      <h2 className="admin-table__name">
        {t<string>("USERS_W_TABLE.USERS_W_QUEST")}
      </h2>
      {/* <ButtonExcel url=''/> */}
      <div className="admin-container">
        <div className="profile-table">
          <div className="admin-search">
            <input
              className="admin-search__inp"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
              onKeyPress={(e: any) => {
                if (e.key === "Enter" && filterType) {
                  setFilterProperty(filterType, filterValue);
                }
              }}
            />
            <select
              onChange={(e) => {
                e.target.value === "search"
                  ? setFilterType(`filter[${e.target.value}]`)
                  : setFilterType(`filter[${e.target.value}][like]`);
              }}
              className="admin-search__dropdown"
            >
              <option value="search">{t<string>("USERS_W_TABLE.ALL")}</option>
              <option value="id">{t<string>("USERS_W_TABLE.ID")}</option>
              <option value="first_name">
                {t<string>("USERS_W_TABLE.FIRST_NAME")}
              </option>
              <option value="last_name">
                {t<string>("USERS_W_TABLE.LAST_NAME")}
              </option>
              <option value="email">{t<string>("USERS_W_TABLE.EMAIL")}</option>
              <option value="phone">{t<string>("USERS_W_TABLE.PHONE")}</option>
            </select>
            <button className="admin-search__btn">
              <img
                src={search}
                alt="search"
                onClick={() => {
                  if (filterType) {
                    setFilterProperty(filterType, filterValue);
                  }
                }}
              />
            </button>
          </div>
          <div className="users-container">
            <table className="users-table" cellSpacing="0" cellPadding="0">
              <thead className="users-table-head">
                <tr className="users-table-head-row">
                  <td className="users-table-head__item">
                    {t<string>("USERS_W_TABLE.USER_ID")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[id]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item"></td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_W_TABLE.FIRST_NAME")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[first_name]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                    className="users-table-head__item"
                  >
                    {t<string>("USERS_W_TABLE.LAST_NAME")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[last_name]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_W_TABLE.EMAIL")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[email]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_W_TABLE.PHONE")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[phone]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_W_TABLE.VERIFICATION")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[verified]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_W_TABLE.CREATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[created_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("USERS_W_TABLE.UPDATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[updated_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item"></td>
                </tr>
              </thead>
              <tbody className="users-table-content">
                {fetchedData?.payload?.collection.map((user: Private.User) => (
                  <tr className="users-table-row" key={user.id}>
                    <td className="users-table-row__item">{user.id}</td>
                    <td className="users-table-row__item">
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                        src={user?.avatar ? user?.avatar : defaultAvatar}
                        alt={user.last_name}
                      />
                    </td>
                    <td className="users-table-row__item">{user.first_name}</td>
                    <td className="users-table-row__item">{user.last_name}</td>
                    <td className="users-table-row__item">{user.email}</td>
                    <td className="users-table-row__item">{user.phone}</td>
                    <td className="users-table-row__item">
                      {user.verified === 1 ? (
                        "Yes"
                      ) : sent.isSent && sent.id === user.id ? (
                        <span className="users-table-row__item-send">
                          {t<string>("USERS_TABLE.SENT")} <MdOutlineDone />
                        </span>
                      ) : (
                        <button
                          className="users-table-row__item-verify"
                          onClick={() => handleVerificationEmail(user.id)}
                        >
                          {t<string>("USERS_TABLE.VERIFY")}
                        </button>
                      )}
                    </td>
                    <td className="users-table-row__item">
                      <span>{`${new Date(
                        user?.created_at
                      ).toLocaleTimeString()}`}</span>
                      <br></br>
                      {`${user?.created_at.slice(
                        8,
                        10
                      )}.${user?.created_at.slice(
                        5,
                        7
                      )}.${user?.created_at.slice(0, 4)}`}
                    </td>
                    <td className="users-table-row__item">
                      <span>{`${new Date(
                        user?.updated_at
                      ).toLocaleTimeString()}`}</span>
                      <br></br>
                      {`${user?.updated_at.slice(
                        8,
                        10
                      )}.${user?.updated_at.slice(
                        5,
                        7
                      )}.${user?.updated_at.slice(0, 4)}`}
                    </td>
                    <td className="users-table-row__item"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {fetchedData?.payload?.cursor.has_more_pages && (
            <button
              className="profile-table__btn"
              onClick={() => {
                setPaginationPage();
              }}
            >
              {t<string>("USERS_W_TABLE.SHOW_MORE")}
            </button>
          )}
          {!fetchedData?.payload?.cursor.has_more_pages &&
            fetchedData?.payload?.collection.length > 10 && (
              <button
                onClick={setDefaultPagination}
                className="profile-table__btn"
              >
                {t<string>("USERS_W_TABLE.HIDE_ALL")}
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default AdminUsersWithoutQuestionnaire;
