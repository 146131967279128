import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "./authTypes";

const state: AuthState = {
  isAuth: false,
  currentUser: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: state,
  reducers: {
    setIsAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    setCurrentUser(state, action: PayloadAction<any>) {
      state.currentUser = action.payload;
    },
  },
});

export default authSlice.reducer;
export const { setIsAuth, setCurrentUser } = authSlice.actions;
