import React, { FC, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { IRoute, PartnerUIRoutes, PrivateUIRoutes, UIRoutes } from "../router";
import { VerificationTypes } from "../services/enums";
import { ScrollTop } from "../../ScrollToTop";

import AuthGuard from "./AuthGuard";
import AppLayout from "../../components/Layout/AppLayout";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import HomePage from "../../areas/public/Home/HomePage";
import ContactUsPage from "../../areas/public/ContactUs/ContactUsPage";
import DashboardPage from "../../areas/private/Dashboard/DashboardPage";
import ForgotPasswordPage from "../../areas/auth/ForgotPassword";
import RestorePasswordPage from "../../areas/auth/RestorePassword";
import VerificationPage from "../../areas/auth/Verification";
import CreditsEditPage from "../../areas/private/Credits/CreditsEdit/CreditsEditPage";
import CreditsListPage from "../../areas/private/Credits/CreditsList/CreditsListPage";
import BanksListPage from "../../areas/private/Banks/BanksList";
import BanksEditPage from "../../areas/private/Banks/BanksEdit";
import AdminGuard from "./AdminGuard";
import QuestionnairePage from "../../areas/private/Questionnaire/QuestionnairePage";
import SetPasswordPage from "../../areas/auth/SetPassword";
import NewsPage from "../../areas/public/News/NewsPage/NewsPage";
import SelectedNewsPage from "../../areas/public/News/SelectedNewsPage/SelectedNewsPage";
import RatingBanksListPage from "../../areas/public/Rating/RatingBanksPage/RatingBanksList";
import RatingFeedbackPage from "../../areas/public/Rating/RatingFeedbackPage/ReviewFeedback/RatingFeedback";
import ProfileGeneralPage from "../../areas/private/Profile/ProfileGeneralPage/ProfileGeneralPage";
import ProfileFormPage from "../../areas/private/Profile/ProfileFormPage/ProfileFormPage";
import AboutUsPage from "../../areas/public/AboutUs/AboutusPage";
import ResultsPage from "../../areas/private/Results/ResultsPage";
import SelectedBlogPage from "../../areas/public/Blog/SelectedBlogPage";
import BlogPage from "../../areas/public/Blog/BlogPage";
import FAQPage from "../../areas/public/FAQ/FAQPage";
import FinancePage from "../../areas/public/Finance/FinancePage";
import ResetPasswordPage from "../../areas/auth/ResetPassword/ResetPasswordPage";
import ResultsListPage from "../../areas/private/ResultsList/ResultsListPage";
import AdminUsers from "../../components/AdminUsers";
import AdminOffers from "../../components/AdminSubmittedApplications";
import AdminReviews from "../../components/AdminReviews";
import AdminBlogs from "../../components/Blog/AdminBlogs";
import AdminNews from "../../components/News/AdminNews";
import AdminBanks from "../../components/AdminBanks";
import AdminCreditOffers from "../../components/AdminCreditOffers";
import CreatePost from "../../areas/private/CreatePost/CreatePost";
import UpdatePost from "../../areas/private/UpdatePost/UpdatePost";
import LearningAnnounce from "../../components/PreAuthModalWindow/LearningAnnounce";
import ServicesAnnounce from "../../components/PreAuthModalWindow/ServicesAnnounce/ServicesAnnounce";
import PartnerLayout from "../../components/Layout/PartnerLayout";
import RequestsPage from "../../areas/partner/Requests/RequestsPage";
import PartnerGuard from "./PartnerGuard";
import AuthModal from "../../components/PreAuthModalWindow";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import TermsConditions from "../../components/TermsConditions";
import Cookies from "../../components/Cookies";
import AdminBanners from "../../components/AdminBanners";
import AdminQuestionnaires from "../../components/AdminQuestionnaires";
import AdminUsersWithoutQuestionnaire from "../../components/AdminUsersWithoutQuestionnaire";
import AdminLeads from "../../components/AdminLeads";
import TimerPage from "../../components/VerifyEmailSuccess";
import VerifyEmailSuccess from "../../components/VerifyEmailSuccess";
import UnderConstructions from "../../components/UnderConstructions";

export const publicRoutes: IRoute[] = [
  {
    path: UIRoutes.SET_PASSWORD,
    element: <SetPasswordPage />,
  },
  {
    path: UIRoutes.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
  },
  {
    path: UIRoutes.RESTORE_PASSWORD,
    element: <RestorePasswordPage />,
  },
  {
    path: UIRoutes.RESET_PASSWORD,
    element: <ResetPasswordPage />,
  },
  {
    path: UIRoutes.VERIFY_EMAIL,
    element: <VerificationPage type={VerificationTypes.Email} />,
  },
  {
    path: `${UIRoutes.VERIFY_PHONE}/:id`,
    element: <VerificationPage type={VerificationTypes.Phone} />,
  },
];

export const appRoutes: IRoute[] = [
  {
    path: UIRoutes.HOME,
    element: <HomePage />,
  },
  {
    path: UIRoutes.UNDER_CONSTRUCTION,
    element: <UnderConstructions />
  },
  // {
  //   path: UIRoutes.VERIFY_EMAIL_SUCCESS,
  //   element: <VerifyEmailSuccess />
  // },
  {
    path: UIRoutes.CONTACT_US,
    element: <ContactUsPage />,
  },
  {
    path: UIRoutes.ABOUT_US,
    element: <AboutUsPage />,
  },
  {
    path: UIRoutes.FAQ,
    element: <FAQPage />,
  },
  {
    path: UIRoutes.FINANCE,
    element: <FinancePage />,
  },
  {
    path: UIRoutes.BANKS_RATING,
    element: <RatingBanksListPage />,
  },
  {
    path: `${UIRoutes.BANKS_RATING}/:id`,
    element: <RatingFeedbackPage />,
  },
  // {
  //   path: UIRoutes.VERIFY_EMAIL_SUCCESS,
  //   element: <VerifyEmailSuccess />,
  // },
  // {
  //   path: `${UIRoutes.NEWS}`,
  //   element: <NewsPage />,
  // },
  {
    path: `${UIRoutes.CALCULATOR}`,
    element: <ResultsPage />,
  },
  // {
  //   path: `${UIRoutes.NEWS}/:id`,
  //   element: <SelectedNewsPage />,
  // },
  // {
  //   path: `${UIRoutes.BLOG}`,
  //   element: <BlogPage />,
  // },
  // {
  //   path: `${UIRoutes.BLOG}/:id`,
  //   element: <SelectedBlogPage />,
  // },
  {
    path: `${UIRoutes.PRIVACY_POLICY}`,
    element: <PrivacyPolicy />,
  },
  {
    path: `${UIRoutes.TERMS_CONDITIONS}`,
    element: <TermsConditions />,
  },
  {
    path: `${UIRoutes.COOKIE_POLICY}`,
    element: <Cookies />,
  },
  {
    path: `${UIRoutes.RESULTS_LIST}`,
    element: <ResultsListPage />,
  },
];

export const privateRoutes: IRoute[] = [
  {
    path: PrivateUIRoutes.DASHBOARD,
    element: <DashboardPage />,
  },
  {
    path: UIRoutes.PROFILE,
    element: <ProfileGeneralPage />,
  },
  {
    path: `${UIRoutes.PROFILE}/edit`,
    element: <ProfileFormPage />,
  },
  {
    path: `${PrivateUIRoutes.CREDITS}`,
    element: <CreditsListPage />,
  },
  {
    path: `${PrivateUIRoutes.CREDITS}/create`,
    element: <CreditsEditPage />,
  },
  {
    path: `${PrivateUIRoutes.CREDITS}/:id`,
    element: <CreditsEditPage />,
  },
  {
    path: `${PrivateUIRoutes.RESULTS}`,
    element: <ResultsPage />,
  },
  {
    path: `${PrivateUIRoutes.RESULTS_LIST}/:id/:type`,
    element: <ResultsListPage />,
  },
  {
    path: `${PrivateUIRoutes.QUESTIONNAIRE}`,
    element: <QuestionnairePage />,
  },
];

export const adminRoutes: IRoute[] = [
  {
    path: `${PrivateUIRoutes.BANKS}`,
    element: <BanksListPage />,
  },
  {
    path: `${PrivateUIRoutes.BANKS}/create`,
    element: <BanksEditPage />,
  },
  {
    path: `${PrivateUIRoutes.BANKS}/:id`,
    element: <BanksEditPage />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/users`,
    element: <AdminUsers />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/users-without-questionnaire`,
    element: <AdminUsersWithoutQuestionnaire />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/questionnaires`,
    element: <AdminQuestionnaires />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/submitted-applications`,
    element: <AdminOffers />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/reviews`,
    element: <AdminReviews />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/create_post`,
    element: <CreatePost />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/update_post`,
    element: <UpdatePost />,
  },
  // {
  //   path: `${PrivateUIRoutes.ADMIN_PANEL}/blogs`,
  //   element: <AdminBlogs />,
  // },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/news`,
    element: <AdminNews />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/fi`,
    element: <AdminBanks />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/credit-offers`,
    element: <AdminCreditOffers />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/banners`,
    element: <AdminBanners />,
  },
  {
    path: `${PrivateUIRoutes.ADMIN_PANEL}/leads-from-rating`,
    element: <AdminLeads />,
  },
];

export const partnerRoutes: IRoute[] = [
  {
    path: `${PartnerUIRoutes.REQUESTS}`,
    element: <RequestsPage />,
  },
];


const AppRouter: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    sessionStorage.setItem("lng", "bg");
    location.pathname.includes("/verify-email") && navigate("/verify-success")
  },[])
  // sT@g1ng4@sistent
  return (
    <ScrollTop>
      <Routes>
        {/* {
          location.pathname.includes("/auth/verify-email") ? (
            <Route path={UIRoutes.VERIFY_EMAIL_SUCCESS} element={<Navigate to={"/verify-success"} replace={true} />} />
          ) : (
            !location.pathname.includes("/verify-success") ? (
              <Route path="/" element={<UnderConstructions />} />
            ) : true)
        } */}
        {
          location.pathname.includes("/auth/verify-email") ? (
            <Route path={UIRoutes.VERIFY_EMAIL_SUCCESS} element={<Navigate to={"/verify-success"} replace={true} />} />
          ) : (
            !location.pathname.includes("/verify-success") ? (
              <Route path="/" element={<Navigate to={`/${UIRoutes.HOME}`} />} />
            ) : true)
        }
        <Route path={UIRoutes.VERIFY_EMAIL_SUCCESS} element={<VerifyEmailSuccess />} />
        <Route path={`/${UIRoutes.AUTH}/*`} element={<AppLayout />}>
          {publicRoutes.map((route, index) => (
            <Route key={`${route.path}${index}`} {...route} />
            ))}
          <Route path="*" element={<Navigate to={"/"} replace />} />
        </Route>
        
        <Route path="/*" element={<AppLayout />}>
          {appRoutes.map((route, index) => (
            <Route key={`${route.path}${index}`} {...route} />
          ))}
          <Route
            path="*"
            element={<Navigate to={`/${UIRoutes.HOME}`} replace/>}
          />
        </Route>
        <Route path={`${UIRoutes.FEEDBACK_ANNOUNCE}`} element={<AuthModal />} />
        <Route
          path={`${UIRoutes.LEARNING_ANNOUNCE}`}
          element={<LearningAnnounce />}
        />
        <Route
          path={`${UIRoutes.SERVICES_ANNOUNCE}`}
          element={<ServicesAnnounce />}
        />
        <Route element={<AuthGuard />}>
          <Route path={`/${UIRoutes.ACCOUNT}/*`} element={<PrivateLayout />}>
            {privateRoutes.map((route, index) => (
              <Route key={`${route.path}${index}`} {...route} />
            ))}
            <Route element={<AdminGuard />}>
              {adminRoutes.map((route, index) => (
                <Route key={`${route.path}${index}`} {...route} />
              ))}
            </Route>
            <Route
              path="*"
              element={
                <Navigate to={`/${UIRoutes.ACCOUNT}/${UIRoutes.PROFILE}`} />
              }
            />
          </Route>
          <Route path={`/${UIRoutes.ADMIN}/*`} element={<PrivateLayout />}>
            {privateRoutes.map((route, index) => (
              <Route key={`${route.path}${index}`} {...route} />
            ))}
            <Route element={<AdminGuard />}>
              {adminRoutes.map((route, index) => (
                <Route key={`${route.path}${index}`} {...route} />
              ))}
            </Route>
            <Route
              path="*"
              element={
                <Navigate
                  to={`/${UIRoutes.ADMIN}/${PrivateUIRoutes.ADMIN_PANEL}/users`}
                />
              }
            />
          </Route>
          <Route path={`/${UIRoutes.PARTNER}/*`} element={<PartnerLayout />}>
            <Route element={<PartnerGuard />}>
              {partnerRoutes.map((route, index) => (
                <Route key={`${route.path}${index}`} {...route} />
              ))}
            </Route>
            <Route
              path="*"
              element={<Navigate to={`/${PartnerUIRoutes.REQUESTS}`} />}
            />
          </Route>
        </Route>
      </Routes>
    </ScrollTop>
  );
};

export default AppRouter;
