import { useContext } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../../../../App";

import credits_car_leasing_main from "../../../../../assets/photos/credits/credits_car_leasing_main.svg";
import credits_car_leasing_bg from "../../../../../assets/photos/credits/credits_car_leasing_bg.svg";
import { useTranslation } from "react-i18next";

import { setTypeForResult } from "../../../../../core/functions/funtions";

const CarLeasingBanner = () => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();

  const { setSelectedLoan } = appContext;

  return (
    <section className="credits-block__consumer-credits consumer-credits ">
      <div className="consumer-credits-banner car-leasing-banner">
        <div className="consumer-credits-banner__main-content main-content">
          <div className="main-content-info">
            <h1 className="main-content-info-title">
              {t<string>("LOANS.CAR_LEASING")}
            </h1>
            <p className="main-content-info-subtitle">
              {t<string>("FINANCE.CAR_LEASING_TEXT_1")}
              <br /> <br />
              {t<string>("FINANCE.CAR_LEASING_TEXT_2")}
            </p>
            <img
              className="credits-car_leasing-banner__background"
              src={credits_car_leasing_bg}
              alt="credits_car_leasing_bg"
            />
            <img
              className="credits-car_leasing-banner__background-main"
              src={credits_car_leasing_main}
              alt="credits_car_leasing_main"
            />
            <Link
              to={`/result-list`}
              onClick={() => {
                setTypeForResult(5)
              }}
              className="main-content-btn"
            >
              {t<string>("FINANCE.CAR_LEASING_BUTTON")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarLeasingBanner;
