import { useTranslation } from "react-i18next";
import { chatOpener } from "../../core/functions/funtions";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="banner">
      <div className="banner__container">
        <span className="banner__container-small">
          {t<string>("COMMON.BANNER_QUESTION")}
        </span>
        <h1 className="banner__container-title">
          {t<string>("COMMON.BANNER_TITLE_FIRST")}{" "}
          <span>{t<string>("COMMON.BANNER_TITLE_SECOND")}</span>
        </h1>
        <button
          className="banner__container-button"
          onClick={() => chatOpener()}
        >
          {t<string>("COMMON.BANNER_BUTTON")}
        </button>
      </div>
    </div>
  );
};

export default Banner;
