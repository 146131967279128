import { useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../../App";

import PrivateService from "../../../core/services/private.service";
import { APIRoutes } from "../../../core/http";
import useHttpGet from "../../../core/hooks/useHttpGet";
import { Private } from "../../../core/models/private";
import { useAppDispatch } from "../../../core/store";
import { setModal } from "../../../core/store/reducers/modal/modalSlice";

const NewCreditOffer = () => {
  const [loading, setLoading] = useState(false);
  const [bankId, setBankId] = useState<number | string>("");

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const appContext = useContext(AppContext);
  const { setReloadChecker, reloadChecker } = appContext;

  const { fetchedData: banks } = useHttpGet<any>(
    `${APIRoutes.ADMIN_ENTITIES_BANK}`
  );

  const valueFormValidationSchema = Yup.object().shape({
    status: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    "description:en": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(160, "Too many characters"),
    "name:en": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(120, "Too many characters"),
    "description:bg": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(160, "Too many characters"),
    "name:bg": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(120, "Too many characters"),
    loan_amount_to: Yup.number()
      .min(1, t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_MIN"))
      .max(1000000, t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_MAX")),
      // .required(`${t<string>("COMMON.IS_REQUIRED")}`),
    loan_amount_from: Yup.number()
      .min(1, t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_MIN"))
      .max(1000000, t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_MAX")),
      // .required(`${t<string>("COMMON.IS_REQUIRED")}`),
    credit_term_from: Yup.number()
      .min(3, t<string>("SUBMITTED_APPLICATIONS.LOAN_TERM_MIN"))
      .max(240, t<string>("SUBMITTED_APPLICATIONS.LOAN_TERM_MAX")),
      // .required(`${t<string>("COMMON.IS_REQUIRED")}`),
    credit_term_to: Yup.number()
      .min(3, t<string>("SUBMITTED_APPLICATIONS.LOAN_TERM_MIN"))
      .max(240, t<string>("SUBMITTED_APPLICATIONS.LOAN_TERM_MAX")),
      // .required(`${t<string>("COMMON.IS_REQUIRED")}`),
    interest_rate_from: Yup.number()
      .min(1, t<string>("SUBMITTED_APPLICATIONS.LOAN_RATE_MIN")),
      // .required(`${t<string>("COMMON.IS_REQUIRED")}`),
    interest_rate_to: Yup.number()
      .min(1, t<string>("SUBMITTED_APPLICATIONS.LOAN_RATE_MIN")),
      // .required(`${t<string>("COMMON.IS_REQUIRED")}`),
    bank_offer_type_ids: Yup.number()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .min(0)
      .max(7),
    show_on_public: Yup.number().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    bank_id: Yup.number().required(`${t<string>("COMMON.IS_REQUIRED")}`),
  });

  const formikForm = useFormik<{
    status: string;
    "description:en": string;
    "name:en": string;
    "description:bg": string;
    "name:bg": string;
    show_on_public: string | number;
    loan_amount_to: string | number;
    loan_amount_from: string | number;
    credit_term_from: string | number;
    credit_term_to: string | number;
    interest_rate_from: string | number;
    interest_rate_to: string | number;
    bank_offer_type_ids: string;
    bank_id: string | number;
  }>({
    initialValues: {
      loan_amount_to: "",
      loan_amount_from: "",
      credit_term_from: "",
      credit_term_to: "",
      interest_rate_from: "",
      interest_rate_to: "",
      show_on_public: "",
      status: "",
      "description:en": "",
      "name:en": "",
      "description:bg": "",
      "name:bg": "",
      bank_offer_type_ids: "",
      bank_id: "",
    },

    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    setLoading(true);
    delete values.bank_id;
    try {
      const response = await PrivateService.createCreditOffer(
        {
          ...values,
        },
        bankId
      );

      setReloadChecker(!reloadChecker);
      setLoading(false);
      toast.success(response?.data?.message);
      dispatch(setModal(false));
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("CREDIT_OFFERS.NEW_OFFER_TITLE")}
        </p>
        <form className="about" onSubmit={formikForm.handleSubmit}>
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["name:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["name:en"] &&
                      formikForm.errors["name:en"],
                  })}>
                  {t<string>("CREDIT_OFFERS.NAME_EN")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["name:en"] &&
                      formikForm.errors["name:en"],
                    "about-box__field-inp-filled": formikForm.values["name:en"],
                  })}
                  placeholder={t<string>("CREDIT_OFFERS.NAME_EN")}
                  {...formikForm.getFieldProps(`${["name:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["name:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["name:bg"] &&
                      formikForm.errors["name:bg"],
                  })}>
                  {t<string>("CREDIT_OFFERS.NAME_BG")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["name:bg"] &&
                      formikForm.errors["name:bg"],
                    "about-box__field-inp-filled": formikForm.values["name:bg"],
                  })}
                  placeholder={t<string>("CREDIT_OFFERS.NAME_BG")}
                  {...formikForm.getFieldProps(`${["name:bg"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["description:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["description:en"] &&
                      formikForm.errors["description:en"],
                  })}>
                  {t<string>("CREDIT_OFFERS.DESCRIPTION_EN")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["description:en"] &&
                      formikForm.errors["description:en"],
                    "about-box__field-inp-filled":
                      formikForm.values["description:en"],
                  })}
                  placeholder={t<string>("CREDIT_OFFERS.DESCRIPTION_EN")}
                  {...formikForm.getFieldProps(`${["description:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["description:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["description:bg"] &&
                      formikForm.errors["description:bg"],
                  })}>
                  {t<string>("CREDIT_OFFERS.DESCRIPTION_BG")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["description:bg"] &&
                      formikForm.errors["description:bg"],
                    "about-box__field-inp-filled":
                      formikForm.values["description:bg"],
                  })}
                  placeholder={t<string>("CREDIT_OFFERS.DESCRIPTION_BG")}
                  {...formikForm.getFieldProps(`${["description:bg"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.loan_amount_from,
                    "about-box__field-inp-error":
                      formikForm.touched.loan_amount_from &&
                      formikForm.errors.loan_amount_from,
                  })}>
                  {t<string>("CREDIT_OFFERS.LOAN_AMOUNT_FROM")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched.loan_amount_from &&
                      formikForm.errors.loan_amount_from,
                    "about-box__field-inp-filled":
                      formikForm.values.loan_amount_from,
                  })}
                  placeholder={t<string>("CREDIT_OFFERS.AMOUNT")}
                  type="number"
                  {...formikForm.getFieldProps("loan_amount_from")}
                />
              </label>
            </div>
            {formikForm.touched.loan_amount_from &&
              formikForm.errors.loan_amount_from && (
                <div className="form-control-error">
                  {formikForm.errors.loan_amount_from}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.loan_amount_to,
                    "about-box__field-inp-error":
                      formikForm.touched.loan_amount_to &&
                      formikForm.errors.loan_amount_to,
                  })}>
                  {t<string>("CREDIT_OFFERS.LOAN_AMOUNT_TO")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched.loan_amount_to &&
                      formikForm.errors.loan_amount_to,
                    "about-box__field-inp-filled":
                      formikForm.values.loan_amount_to,
                  })}
                  type="number"
                  placeholder={t<string>("CREDIT_OFFERS.AMOUNT")}
                  {...formikForm.getFieldProps("loan_amount_to")}
                />
              </label>
            </div>
          </div>
          {formikForm.touched.loan_amount_to &&
            formikForm.errors.loan_amount_to && (
              <div className="form-control-error">
                {formikForm.errors.loan_amount_to}
              </div>
            )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.credit_term_from,
                  "about-box__field-inp-error":
                    formikForm.touched.credit_term_from &&
                    formikForm.errors.credit_term_from,
                })}>
                {t<string>("CREDIT_OFFERS.CREDIT_TERM_FROM")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.credit_term_from &&
                    formikForm.errors.credit_term_from,
                  "about-box__field-inp-filled":
                    formikForm.values.credit_term_from,
                })}
                type="number"
                placeholder={t<string>("CREDIT_OFFERS.TERM")}
                {...formikForm.getFieldProps("credit_term_from")}
              />
            </label>
          </div>
          {formikForm.touched.credit_term_from &&
            formikForm.errors.credit_term_from && (
              <div className="form-control-error">
                {formikForm.errors.credit_term_from}
              </div>
            )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.credit_term_to,
                  "about-box__field-inp-error":
                    formikForm.touched.credit_term_to &&
                    formikForm.errors.credit_term_to,
                })}>
                {t<string>("CREDIT_OFFERS.CREDIT_TERM_TO")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.credit_term_to &&
                    formikForm.errors.credit_term_to,
                  "about-box__field-inp-filled":
                    formikForm.values.credit_term_to,
                })}
                type="number"
                placeholder={t<string>("CREDIT_OFFERS.TERM")}
                {...formikForm.getFieldProps("credit_term_to")}
              />
            </label>
          </div>
          {formikForm.touched.credit_term_to &&
            formikForm.errors.credit_term_to && (
              <div className="form-control-error">
                {formikForm.errors.credit_term_to}
              </div>
            )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.interest_rate_from,
                  "about-box__field-inp-error":
                    formikForm.touched.interest_rate_from &&
                    formikForm.errors.interest_rate_from,
                })}>
                {t<string>("CREDIT_OFFERS.INTEREST_RATE_FROM")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.interest_rate_from &&
                    formikForm.errors.interest_rate_from,
                  "about-box__field-inp-filled":
                    formikForm.values.interest_rate_from,
                })}
                type="number"
                placeholder={t<string>("CREDIT_OFFERS.RATE")}
                {...formikForm.getFieldProps("interest_rate_from")}
              />
            </label>
          </div>
          {formikForm.touched.interest_rate_from &&
            formikForm.errors.interest_rate_from && (
              <div className="form-control-error">
                {formikForm.errors.interest_rate_from}
              </div>
            )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.interest_rate_to,
                  "about-box__field-inp-error":
                    formikForm.touched.interest_rate_to &&
                    formikForm.errors.interest_rate_to,
                })}>
                {t<string>("CREDIT_OFFERS.INTEREST_RATE_TO")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.interest_rate_to &&
                    formikForm.errors.interest_rate_to,
                  "about-box__field-inp-filled":
                    formikForm.values.interest_rate_to,
                })}
                type="number"
                placeholder={t<string>("CREDIT_OFFERS.RATE")}
                {...formikForm.getFieldProps("interest_rate_to")}
              />
            </label>
          </div>
          {formikForm.touched.interest_rate_to &&
            formikForm.errors.interest_rate_to && (
              <div className="form-control-error">
                {formikForm.errors.interest_rate_to}
              </div>
            )}

          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.status && formikForm.errors.status,
                })}>
                {t<string>("CREDIT_OFFERS.CHOOSE_STATUS")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.status,
                  "about-box__field-inp-touched":
                    formikForm.touched.status && formikForm.errors.status,
                })}
                value={formikForm.values.status}
                onChange={(e) =>
                  formikForm.setFieldValue("status", e.target.value)
                }>
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="enabled">
                  {t<string>("CREDIT_OFFERS.STATUS_ENABLED")}
                </option>
                <option value="disabled">
                  {t<string>("CREDIT_OFFERS.STATUS_DISABLED")}
                </option>
              </select>
            </label>
          </div>

          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.bank_offer_type_ids,
                  "about-box__field-inp-error":
                    formikForm.touched.bank_offer_type_ids &&
                    formikForm.errors.bank_offer_type_ids,
                })}>
                {t<string>("CREDIT_OFFERS.OFFER_TYPE")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled":
                    formikForm.values.bank_offer_type_ids,
                  "about-box__field-inp-touched":
                    formikForm.touched.bank_offer_type_ids &&
                    formikForm.errors.bank_offer_type_ids,
                })}
                {...formikForm.getFieldProps("bank_offer_type_ids")}>
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="1">{t<string>("LOANS.CONSUMER_LOANS")}</option>
                <option value="2">{t<string>("LOANS.CREDIT_CARDS")}</option>
                <option value="3">{t<string>("LOANS.QUICK_LOAN")}</option>
                <option value="4">{t<string>("LOANS.MORTGAGE_LOANS")}</option>
                <option value="5">{t<string>("LOANS.CAR_LEASING")}</option>
                <option value="6">{t<string>("LOANS.REFINANCING")}</option>
                <option value="7">{t<string>("LOANS.MERGING_LOANS")}</option>
              </select>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.show_on_public,
                  "about-box__field-inp-error":
                    formikForm.touched.show_on_public &&
                    formikForm.errors.show_on_public,
                })}>
                {t<string>("CREDIT_OFFERS.SHOW_ON_PUBLIC")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled":
                    formikForm.values.show_on_public,
                  "about-box__field-inp-touched":
                    formikForm.touched.show_on_public &&
                    formikForm.errors.show_on_public,
                })}
                {...formikForm.getFieldProps("show_on_public")}>
                <option value="" disabled hidden>
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value={1}>{t<string>("CREDIT_OFFERS.YES")}</option>
                <option value={0}>{t<string>("CREDIT_OFFERS.NO")}</option>
              </select>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": Number(bankId) > 0,
                  "about-box__field-inp-error":
                    Number(bankId) < 0 ||
                    (formikForm.touched.bank_id && formikForm.errors.bank_id),
                })}>
                {t<string>("CREDIT_OFFERS.CHOOSE_BANK")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": Number(bankId) > 0,
                  "about-box__field-inp-touched":
                    Number(bankId) < 0 ||
                    (formikForm.touched.bank_id && formikForm.errors.bank_id),
                })}
                value={bankId}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setBankId(+e.target.value);
                  formikForm.setFieldValue("bank_id", +e.target.value);
                }}>
                <option value="" disabled hidden>
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                {!banks?.payload?.collection && (
                  <option disabled hidden>
                    {t<string>("CREDIT_OFFERS.NO_BANKS")}
                  </option>
                )}
                {banks?.payload?.collection.map((bank: Private.AdminBank) => (
                  <option key={bank.id} value={bank.id}>
                    {bank.translations.en.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <button
            className={classNames({
              about__submit: true,
              "about__submit-active": formikForm.isValid,
            })}
            type="submit">
            {t<string>("CREDIT_OFFERS.ADD_BUTTON")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewCreditOffer;
