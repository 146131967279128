export const credit_types = [
  "",
  "Consumer loans",
  "Car leasing",
  "Refinancing",
  "Merging loans",
  "Mortgage loans",
  "Quick loans",
  "Credit cards",
];
