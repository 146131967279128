import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../App";
import { useAppDispatch, useAppSelector } from "../../../../core/store";
import { filterPhoneNum, getPreviousDay } from "../../../../core/functions/funtions";
import { usePrevious } from "../../../../core/hooks/usePrevious";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { APIRoutes } from "../../../../core/http";

import * as Yup from "yup";
import classNames from "classnames";
// import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import PrivateService from "../../../../core/services/private.service";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import CommonService from "../../../../core/services/common.service";
import CalendarIcon from "../../../../assets/photos/Calendar";
import DatePicker from "react-date-picker";

import fileIcon from "../../../../assets/photos/all/add-file.svg";
import addedFile from "../../../../assets/photos/all/file-added.svg";
import { useTranslation } from "react-i18next";
import {
  setModal,
  setModalType,
} from "../../../../core/store/reducers/modal/modalSlice";
import {
  setCerts,
  setCertsIds,
} from "../../../../core/store/reducers/documents/documentsSlice";
import { AuthSelectionType } from "../../../../core/services/enums";
import {
  setChoosenData,
  setSelectedId,
} from "../../../../core/store/reducers/app/appDataSlice";
import { City, Country } from "country-state-city";
import { Countries } from "../../../../core/constants/countries";

const UpdateQuestionnaireModal = () => {
  const [loading, setLoading] = useState(false);
  const [questionnaire, setQuestionnaire] = useState<any>({});
  const [cities, setCities] = useState<any>([]);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { appLanguage, selectedId } = useAppSelector((state) => state.app);
  const { certs, certsIds } = useAppSelector((state) => state.document);

  const appContext = useContext(AppContext);
  const { setReloadChecker, reloadChecker } = appContext;

  useHttpGet<any>(`${APIRoutes.ADMIN_USERS}/questionnaires/${selectedId}`, {
    dependencies: [reloadChecker, selectedId],

    resolve: (response: any) => {
      if (response.payload) {
        setQuestionnaire(response.payload);
        if (certs.length === 0) {
          dispatch(setCerts(response?.payload?.certificates?.collection));
        }
      }
    },
  });

  useEffect(() => {
    formikForm.resetForm();

    if (questionnaire) {
      for (let prop in questionnaire) {
        questionnaire[prop] !== null
          ? formikForm.setFieldValue(prop, String(questionnaire[prop]))
          : formikForm.setFieldValue(prop, "");
      }

      if (questionnaire["personal_code"]) {
        formikForm.setFieldValue(
          "personal_code",
          questionnaire["personal_code"]
        );
        formikForm.setFieldValue("egn", false);
      } else {
        formikForm.setFieldValue("egn", "checked");
        formikForm.setFieldValue("personal_code", "");
      }
    }
  }, [questionnaire, selectedId]);

  const valueFormValidationSchema = Yup.object().shape({
    first_name: Yup.string().max(
      121,
      t<string>("UPDATE_QUESTIONNAIRE.TOO_MANY_CHARS")
    ),
    last_name: Yup.string().max(
      121,
      t<string>("UPDATE_QUESTIONNAIRE.TOO_MANY_CHARS")
    ),
    phone: Yup.string(),
    current_amount: Yup.string().max(
      15,
      t<string>("UPDATE_QUESTIONNAIRE.TOO_MANY_CHARS")
    ),
    politic: Yup.number(),
    type: Yup.string(),
    passport: Yup.string().length(
      9,
      t<string>("UPDATE_QUESTIONNAIRE.PASSPORT_RULE")
    ),
    loan_sum: Yup.number().when("debts", {
      is: (val: string) => val && val === "1",
      then: Yup.number()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .min(250, t<string>("PROFILE.MIN_LOAN_SUM")),
    }),
    insolvency: Yup.string(),
    city_of_residence: Yup.string().max(
      55,
      t<string>("UPDATE_QUESTIONNAIRE.MAX_55_CHARS")
    ),
    housing_type: Yup.string(),
    renting: Yup.string().when("housing_type", {
      is: (val: string) => val && val === "1",
      then: Yup.string().required(t<string>("COMMON.IS_REQUIRED")),
    }),
    hypothec: Yup.string().when("housing_type", {
      is: (val: string) => val && val === "3",
      then: Yup.string().required(t<string>("COMMON.IS_REQUIRED")),
    }),
    street_house: Yup.string().max(
      55,
      t<string>("UPDATE_QUESTIONNAIRE.MAX_55_CHARS")
    ),
    agree: Yup.number(),
    job: Yup.number(),
    bank_1: Yup.string(),
    term_1: Yup.string(),
    monthly_income: Yup.string().when("job", {
      is: (val: string) => val,
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    education: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    position: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    income_from: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(36, t<string>("PROFILE.MAX_36_CHARS")),
    }),
    job_status: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    working_place: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(55, t<string>("PROFILE.MAX_55_CHARS")),
    }),
    experience: Yup.number().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.number().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
  });

  const formikForm = useFormik<{
    id: number;
    phone: string;
    first_name: string;
    user_id: number;
    last_name: string;
    gender: string;
    date_of_birth: string;
    citizenship: string;
    personal_code: string;
    passport: string;
    marital_status: string;
    children: string;
    country_of_residence: string;
    city_of_residence: string;
    housing_type: string | number;
    street_house: string;
    postal_code: string;
    house_number: string;
    apartment_number: string;
    job: string;
    education: string;
    working_place: string;
    position: number;
    experience: number;
    job_status: number;
    income_from: number;
    monthly_income: number;
    update: number;
    status: string | number;
    type: string | number;
    current_amount: string;
    amount: number;
    term: number;
    term_1: string | number;
    term_2: string | number;
    term_3: string | number;
    amount_1: string | number;
    amount_2: string | number;
    amount_3: string | number;
    bank_1: string | number;
    bank_2: string | number;
    bank_3: string | number;
    certificates_1: any;
    certificates_2: any;
    certificates_3: any;
    certificates_4: any;
    certificates_5: any;
    politic: string;
    debts: string | number;
    loan_sum: number | string;
    insolvency: string | number;
    egn: boolean | string;
    renting: string;
    hypothec: string;
  }>({
    initialValues: {
      id: 0,
      user_id: 0,
      phone: "",
      first_name: "",
      last_name: "",
      gender: "",
      date_of_birth: "",
      citizenship: "",
      personal_code: "",
      passport: "",
      marital_status: "",
      children: "",
      country_of_residence: "",
      city_of_residence: "",
      housing_type: "",
      street_house: "",
      postal_code: "",
      house_number: "",
      apartment_number: "",
      job: "1", // TODO: check for 1
      working_place: "",
      position: 0,
      experience: 0,
      job_status: 0,
      income_from: 0,
      monthly_income: 0,
      education: "",
      politic: "",
      debts: "",
      loan_sum: "",
      insolvency: "",
      term: 0,
      term_1: "",
      term_2: "",
      term_3: "",
      amount_1: "",
      amount_2: "",
      amount_3: "",
      bank_1: "",
      bank_2: "",
      bank_3: "",
      certificates_1: "",
      certificates_2: "",
      certificates_3: "",
      certificates_4: "",
      certificates_5: "",
      update: 1,
      status: "",
      type: "",
      current_amount: "",
      amount: 1000,
      egn: false,
      renting: "",
      hypothec: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      const result = certificateFilter();

      handleSubmitForm({ ...values, ...result, certificates: "" });
    },
  });

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    if (
      !personalCodeValidation() ||
      (formikForm.values.personal_code.length === 0 && !formikForm.values.egn)
    ) {
      toast.error(`${t<string>("PROFILE.CHECK_FIELDS")}`);
      return;
    }

    delete values.egn;
    try {
      const response = await PrivateService.updateQuestionnaire(
        {
          ...values,
          agree: 1,
          informed: 1,
        },
        selectedId
      );
      setReloadChecker(!reloadChecker);
      setLoading(false);
      toast.success(response?.data?.message);
      dispatch(setSelectedId(0));
      dispatch(setModal(false));
      dispatch(setCerts([]));
    } catch (errors: any) {
      toast.error(errors.response.data.message);
      setLoading(false);
      dispatch(setModal(true));
    }
  };

  const prevEgn = usePrevious(formikForm.values.personal_code);
  const prevPassport = usePrevious(formikForm.values.passport);
  const prevIncome = usePrevious(formikForm.values.income_from);
  const deleteCert = (index: number, doc: any) => {
    const newCertificates = certs.filter(
      (cert: any) => certs.indexOf(cert) !== index
    );
    const uniqueCert = certsIds.filter((certId: number) => certId !== doc.id);

    dispatch(setCertsIds(uniqueCert));

    dispatch(setCerts(newCertificates));
  };

  // const updateCertificates = () => {
  //   for (let i = 0; i < 5; i++) {
  //     if (certs[i]) {
  //       formikForm.setFieldValue(`certificates_${i + 1}`, certs[i]);
  //     } else {
  //       formikForm.setFieldValue(`certificates_${i + 1}`, "");
  //     }
  //   }
  // };

  const personalCodeValidation = () => {
    if (
      formikForm.values.personal_code.length === 0 ||
      formikForm.values.personal_code.length === 10
    ) {
      return true;
    }

    return false;
  };

  const jobInfoClear = (value: string) => {
    formikForm.setFieldValue("working_place", "");
    formikForm.setFieldValue("position", "");
    formikForm.setFieldValue("experience", "");
    formikForm.setFieldValue("monthly_income", "");
    formikForm.setFieldValue("income_from", "");
    formikForm.setFieldValue("job_status", "");
    formikForm.setFieldValue("job", value);
    formikForm.validateForm();
  };

  const certificateFilter = () => {
    const files = certs
      .filter((cert: any) => cert.id === undefined)
      .reduce(
        (a: any, v: any, index: number) => ({
          ...a,
          [`certificates_${index + 1}`]: v,
        }),
        {}
      );

    const existed = certs
      .filter((cert: any) => cert.id !== undefined)
      .reduce(
        (a: any, v: any, index: number) => ({
          ...a,
          [`certificates_ids[${index}]`]: v.id,
        }),
        {}
      );

    return {
      ...files,
      ...existed,
    };
  };

  useEffect(() => {
    Country.getAllCountries().filter((country) => {
      if (country.name === formikForm.values.country_of_residence) {
        setCities(City.getCitiesOfCountry(country.isoCode));
      }
    });
  }, [formikForm.values.country_of_residence]);

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("UPDATE_QUESTIONNAIRE.UPDATE_QUEST")}
        </p>
        <form className="about" onSubmit={formikForm.handleSubmit}>
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.first_name,
                    "about-box__field-inp-error":
                      formikForm.touched.first_name &&
                      formikForm.errors.first_name,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.FIRST_NAME")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched.first_name &&
                      formikForm.errors.first_name,
                    "about-box__field-inp-filled": formikForm.values.first_name,
                  })}
                  placeholder={t<string>(
                    "UPDATE_QUESTIONNAIRE.FIRST_NAME_PLACEHOLDER"
                  )}
                  {...formikForm.getFieldProps("first_name")}
                />
              </label>
            </div>
            {formikForm.touched.first_name && formikForm.errors.first_name && (
              <div className="form-control-error">
                {formikForm.errors.first_name}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.last_name &&
                      formikForm.errors.last_name,
                    "about-box__field-name-filled": formikForm.values.last_name,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.LAST_NAME")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched.last_name &&
                      formikForm.errors.last_name,
                    "about-box__field-inp-filled": formikForm.values.last_name,
                  })}
                  placeholder={t<string>(
                    "UPDATE_QUESTIONNAIRE.LAST_NAME_PLACEHOLDER"
                  )}
                  {...formikForm.getFieldProps("last_name")}
                />
              </label>
            </div>
            {formikForm.touched.last_name && formikForm.errors.last_name && (
              <div className="form-control-error">
                {formikForm.errors.last_name}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.phone,
                    "about-box__field-inp-error":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.PHONE_NUMBER")}
                </span>
                <InputMask
                  name="phone"
                  className={classNames("data-input__input-field",{
                    "form-control": true,
                    "form-control-touched":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}
                  maskChar=" "
                  mask="+35\9 (999) 999-9999"
                  placeholder="+359 (___) ___-___ "
                  value={formikForm.values.phone}
                  onChange={(event) => {
                    formikForm.setFieldValue(
                      "phone",
                      filterPhoneNum(event.target.value)
                    );
                  }}
                />
                {/* <PhoneInput
                  dropdownClass="phone-dropdown"
                  countryCodeEditable={false}
                  enableSearch={true}
                  inputClass={classNames({
                    "form-control": true,
                    "form-control-touched":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}
                  country={"bg"}
                  value={formikForm.values.phone}
                  onChange={(phone) => {
                    formikForm.setFieldValue("phone", phone);
                  }}
                /> */}
              </label>
            </div>
            {formikForm.touched.phone && formikForm.errors.phone && (
              <div className="form-control-error">
                {formikForm.errors.phone}
              </div>
            )}

            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.gender,
                    "about-box__field-inp-error":
                      formikForm.touched.gender && formikForm.errors.gender,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.GENDER")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.gender,
                    "about-box__field-inp-touched":
                      formikForm.touched.gender && formikForm.errors.gender,
                  })}
                  {...formikForm.getFieldProps("gender")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("UPDATE_QUESTIONNAIRE.MALE")}
                  </option>
                  <option value="1">
                    {t<string>("UPDATE_QUESTIONNAIRE.FEMALE")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.gender && formikForm.errors.gender && (
              <div className="form-control-error">
                {formikForm.errors.gender}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.date_of_birth,
                    "about-box__field-inp-error":
                      formikForm.touched.date_of_birth &&
                      formikForm.errors.date_of_birth,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.DATE_OF_BIRTH")}
                </span>
                <DatePicker
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.date_of_birth,
                    "about-box__field-inp-touched":
                      formikForm.touched.date_of_birth &&
                      formikForm.errors.date_of_birth,
                  })}
                  format="dd/MM/y"
                  dayPlaceholder={t<string>("COMMON.CALENDAR_DAY")}
                  monthPlaceholder={t<string>("COMMON.CALENDAR_MONTH")}
                  yearPlaceholder={t<string>("COMMON.CALENDAR_YEAR")}
                  locale={appLanguage}
                  maxDate={getPreviousDay()}
                  minDate={new Date(new Date("1900-01-01").getTime())}
                  onChange={(e: any) => {
                    if (e) {
                      formikForm.setFieldValue(
                        "date_of_birth",
                        `${e.getFullYear()}-${e.getMonth() + 1}-${e.getDate()}`
                      );
                    }
                  }}
                  value={
                    formikForm.values.date_of_birth
                      ? new Date(
                          new Date(formikForm.values.date_of_birth).getTime()
                        )
                      : undefined
                  }
                  calendarIcon={<CalendarIcon />}
                />
                {formikForm.touched.date_of_birth &&
                  formikForm.errors.date_of_birth && (
                    <div className="form-control-error">
                      {formikForm.errors.date_of_birth}
                    </div>
                  )}
              </label>
            </div>
          </div>
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("UPDATE_QUESTIONNAIRE.PASSPORT_INFO")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.citizenship &&
                      formikForm.errors.citizenship,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.CITIZENSHIP")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.citizenship,
                    "about-box__field-inp-touched":
                      formikForm.touched.citizenship &&
                      formikForm.errors.citizenship,
                  })}
                  value={formikForm.values.citizenship}
                  onChange={(e) =>
                    formikForm.setFieldValue("citizenship", e.target.value)
                  }>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("UPDATE_QUESTIONNAIRE.BULGARIA")}
                  </option>
                  <option value="1">
                    {t<string>("UPDATE_QUESTIONNAIRE.OTHER")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.citizenship &&
              formikForm.errors.citizenship && (
                <div className="form-control-error">
                  {formikForm.errors.citizenship}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      !personalCodeValidation() ||
                      (formikForm.values.personal_code.length === 0 &&
                        !formikForm.values.egn),
                  })}>
                  {t<string>("UPDATE_USER.PERSONAL_IDENTIFICATION_CODE")}
                </span>
                <input
                  disabled={formikForm.values.egn === "checked"}
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.personal_code,
                    "about-box__field-inp-touched":
                      !personalCodeValidation() ||
                      (formikForm.values.personal_code.length === 0 &&
                        !formikForm.values.egn),
                  })}
                  placeholder="1002498384"
                  value={formikForm.values.personal_code}
                  type="text"
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("personal_code", "");
                    } else {
                      if (event.target.value.length > 10) {
                        formikForm.setFieldValue("personal_code", prevEgn);
                      } else {
                        formikForm.setFieldValue(
                          "personal_code",
                          event.target.value
                        );
                        event.target.value.length > 0
                          ? formikForm.setFieldValue("egn", true)
                          : formikForm.setFieldValue("egn", false);
                      }
                    }
                    personalCodeValidation();
                  }}
                />
              </label>
              <label className="about-box__egn">
                <input
                  checked={formikForm.values.egn === "checked"}
                  type="checkbox"
                  onChange={() => {
                    if (formikForm.values.egn !== "checked") {
                      formikForm.setFieldValue("egn", "checked");
                      formikForm.setFieldValue("personal_code", "");
                    } else {
                      formikForm.setFieldValue("egn", false);
                    }
                  }}
                />
                {t<string>("UPDATE_USER.NO_EGN")}
              </label>
            </div>
            {!personalCodeValidation() && (
              <div className="form-control-error">
                {t<string>("UPDATE_QUESTIONNAIRE.EGN_RULE")}
              </div>
            )}
            {!formikForm.values.egn &&
              formikForm.values.personal_code.length !== 10 && (
                <div className="form-control-error">
                  {t<string>("PROFILE.ADD_EGN")}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.passport && formikForm.errors.passport,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.PASSPORT_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.passport,
                    "about-box__field-inp-touched":
                      formikForm.touched.passport && formikForm.errors.passport,
                  })}
                  placeholder="1002498384"
                  type="text"
                  value={formikForm.values.passport}
                  onChange={(event) => {
                    if (
                      +event.target.value < 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("passport", "");
                    } else {
                      if (event.target.value.length > 9) {
                        formikForm.setFieldValue("passport", prevPassport);
                      } else {
                        formikForm.setFieldValue(
                          "passport",
                          event.target.value
                        );
                      }
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.passport && formikForm.errors.passport && (
              <div className="form-control-error">
                {formikForm.errors.passport}
              </div>
            )}
          </div>
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("UPDATE_QUESTIONNAIRE.FAMILY_STATUS")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.marital_status,
                    "about-box__field-inp-error":
                      formikForm.touched.marital_status &&
                      formikForm.errors.marital_status,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.MARITAL_STATUS")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.marital_status,
                    "about-box__field-inp-touched":
                      formikForm.touched.marital_status &&
                      formikForm.errors.marital_status,
                  })}
                  {...formikForm.getFieldProps("marital_status")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("PROFILE.MARRIED")}</option>
                  <option value="1">{t<string>("PROFILE.SINGLE")}</option>
                  <option value="2">
                    {t<string>("PROFILE.CIVIL_MARRIAGE")}
                  </option>
                  <option value="3">{t<string>("PROFILE.DIVORCED")}</option>
                  <option value="4">{t<string>("PROFILE.WIDOWED")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched.marital_status &&
              formikForm.errors.marital_status && (
                <div className="form-control-error">
                  {formikForm.errors.marital_status}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.children,
                    "about-box__field-inp-error":
                      formikForm.touched.children && formikForm.errors.children,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.UNDER_18")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.children,
                    "about-box__field-inp-touched":
                      formikForm.touched.children && formikForm.errors.children,
                  })}
                  {...formikForm.getFieldProps("children")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">
                    {t<string>("UPDATE_QUESTIONNAIRE.MORE_THAN_3")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.children && formikForm.errors.children && (
              <div className="form-control-error">
                {formikForm.errors.children}
              </div>
            )}
          </div>
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("UPDATE_QUESTIONNAIRE.RESIDENCE_ADDRESS")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.country_of_residence,
                    "about-box__field-inp-error":
                      formikForm.touched.country_of_residence &&
                      formikForm.errors.country_of_residence,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.COUNTRY_OF_RESIDENCE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.country_of_residence,
                    "about-box__field-inp-touched":
                      formikForm.touched.country_of_residence &&
                      formikForm.errors.country_of_residence,
                  })}
                  {...formikForm.getFieldProps("country_of_residence")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  {[
                    ...Country.getAllCountries().filter(
                      (country) => country.name === "Bulgaria"
                    ),
                    ...Country.getAllCountries().filter(
                      (country) => country.name !== "Bulgaria"
                    ),
                  ].map((country: any, index: number) => (
                    <option
                      className={classNames({
                        "bul-selected":
                          country.name === "Bulgaria" ||
                          country.name === "България",
                      })}
                      value={country.name}
                      key={index}>
                      {t<string>(`COUNTRY.${country.name.toUpperCase()}`)
                        ?.split(" ")
                        .map(
                          (word) =>
                            word[0]?.toUpperCase() + word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            {formikForm.touched.country_of_residence &&
              formikForm.errors.country_of_residence && (
                <div className="form-control-error">
                  {formikForm.errors.country_of_residence}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.city_of_residence,
                    "about-box__field-inp-error":
                      formikForm.touched.city_of_residence &&
                      formikForm.errors.city_of_residence,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.CITY_OF_RESIDENCE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.city_of_residence,
                    "about-box__field-inp-touched":
                      formikForm.touched.city_of_residence &&
                      formikForm.errors.city_of_residence,
                  })}
                  {...formikForm.getFieldProps("city_of_residence")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  {formikForm.values.country_of_residence === "Bulgaria"
                    ? cities.map((city: any, index: number) => (
                        <option value={city.name} key={index}>
                          {t<string>(`CITIES.${city.name.toUpperCase()}`)}
                        </option>
                      ))
                    : cities.map((city: any, index: number) => (
                        <option value={city.name} key={index}>
                          {city.name}
                        </option>
                      ))}
                </select>
              </label>
            </div>
            {formikForm.touched.city_of_residence &&
              formikForm.errors.city_of_residence && (
                <div className="form-control-error">
                  {formikForm.errors.city_of_residence}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.housing_type,
                    "about-box__field-inp-error":
                      formikForm.touched.housing_type &&
                      formikForm.errors.housing_type,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.TYPE_OF_HOUSING")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.housing_type,
                    "about-box__field-inp-touched":
                      formikForm.touched.housing_type &&
                      formikForm.errors.housing_type,
                  })}
                  value={formikForm.values.housing_type}
                  onChange={(event: any) => {
                    formikForm.setFieldValue(
                      "housing_type",
                      event.target.value
                    );
                    formikForm.setFieldValue("renting", "");
                    formikForm.setFieldValue("hypothec", "");
                  }}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("UPDATE_QUESTIONNAIRE.OWN_HOME")}
                  </option>
                  <option value="1">
                    {t<string>("UPDATE_QUESTIONNAIRE.RENTING")}
                  </option>
                  <option value="2">
                    {t<string>("UPDATE_QUESTIONNAIRE.PARENTAL")}
                  </option>
                  <option value="3">
                    {t<string>("UPDATE_QUESTIONNAIRE.OWN_WITH_MORTGAGE")}
                  </option>
                  <option value="4">
                    {t<string>("UPDATE_QUESTIONNAIRE.OTHER")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.housing_type &&
              formikForm.errors.housing_type && (
                <div className="form-control-error">
                  {formikForm.errors.housing_type}
                </div>
              )}
            {formikForm.values.housing_type === "1" && (
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": formikForm.values.renting,
                      "about-box__field-inp-error":
                        formikForm.touched.renting && formikForm.errors.renting,
                    })}>
                    {t<string>("PROFILE.RENT_EXPENSE")}
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.renting,
                      "about-box__field-inp-touched":
                        formikForm.touched.renting && formikForm.errors.renting,
                    })}
                    type="text"
                    value={formikForm.values.renting}
                    onChange={(event: any) => {
                      if (
                        +event.target.value <= 0 ||
                        Number.isNaN(+event.target.value)
                      ) {
                        formikForm.setFieldValue("renting", "");
                      } else {
                        formikForm.setFieldValue("renting", event.target.value);
                      }
                    }}
                  />
                </label>
                {formikForm.touched.renting && formikForm.errors.renting && (
                  <div className="form-control-error">
                    {formikForm.errors.renting}
                  </div>
                )}
              </div>
            )}
            {formikForm.values.housing_type === "3" && (
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.hypothec,
                      "about-box__field-inp-error":
                        formikForm.touched.hypothec &&
                        formikForm.errors.hypothec,
                    })}>
                    {t<string>("PROFILE.OWN_EXPENSE_MORTGAGE")}
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.hypothec,
                      "about-box__field-inp-touched":
                        formikForm.touched.hypothec &&
                        formikForm.errors.hypothec,
                    })}
                    type="text"
                    value={formikForm.values.hypothec}
                    onChange={(event: any) => {
                      if (
                        +event.target.value <= 0 ||
                        Number.isNaN(+event.target.value)
                      ) {
                        formikForm.setFieldValue("hypothec", "");
                      } else {
                        formikForm.setFieldValue(
                          "hypothec",
                          event.target.value
                        );
                      }
                    }}
                  />
                </label>
                {formikForm.touched.hypothec && formikForm.errors.hypothec && (
                  <div className="form-control-error">
                    {formikForm.errors.hypothec}
                  </div>
                )}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.street_house,
                    "about-box__field-inp-error":
                      formikForm.touched.street_house &&
                      formikForm.errors.street_house,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.STREET_HOUSE")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.street_house,
                    "about-box__field-inp-touched":
                      formikForm.touched.street_house &&
                      formikForm.errors.street_house,
                  })}
                  placeholder={t<string>(
                    "UPDATE_QUESTIONNAIRE.STREET_HOUSE_PLACEHOLDER"
                  )}
                  {...formikForm.getFieldProps("street_house")}
                />
              </label>
            </div>
            {formikForm.touched.street_house &&
              formikForm.errors.street_house && (
                <div className="form-control-error">
                  {formikForm.errors.street_house}
                </div>
              )}

            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.house_number,
                    "about-box__field-inp-error":
                      formikForm.touched.house_number &&
                      formikForm.errors.house_number,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.HOUSE_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.house_number,
                    "about-box__field-inp-touched":
                      formikForm.touched.house_number &&
                      formikForm.errors.house_number,
                  })}
                  placeholder="7"
                  value={formikForm.values.house_number}
                  type="text"
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("house_number", "");
                    } else {
                      formikForm.setFieldValue(
                        "house_number",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.house_number &&
              formikForm.errors.house_number && (
                <div className="form-control-error">
                  {formikForm.errors.house_number}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.apartment_number,
                    "about-box__field-inp-error":
                      formikForm.touched.apartment_number &&
                      formikForm.errors.apartment_number,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.APARTMENT_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.apartment_number,
                    "about-box__field-inp-touched":
                      formikForm.touched.apartment_number &&
                      formikForm.errors.apartment_number,
                  })}
                  placeholder="24"
                  type="text"
                  value={formikForm.values.apartment_number}
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("apartment_number", "");
                    } else {
                      formikForm.setFieldValue(
                        "apartment_number",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.apartment_number &&
              formikForm.errors.country_of_residence && (
                <div className="form-control-error">
                  {formikForm.errors.apartment_number}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.postal_code,
                    "about-box__field-inp-error":
                      formikForm.touched.postal_code &&
                      formikForm.errors.postal_code,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.POSTAL_CODE")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.postal_code,
                    "about-box__field-inp-touched":
                      formikForm.touched.postal_code &&
                      formikForm.errors.postal_code,
                  })}
                  placeholder="203-320"
                  {...formikForm.getFieldProps("postal_code")}
                />
              </label>
            </div>
            {formikForm.touched.postal_code &&
              formikForm.errors.postal_code && (
                <div className="form-control-error">
                  {formikForm.errors.postal_code}
                </div>
              )}
          </div>
          <div className="about__form">
            <h4 className="about-ask__question">
              {t<string>("UPDATE_QUESTIONNAIRE.ANY_LOANS")}
            </h4>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.debts,
                    "about-box__field-inp-touched":
                      formikForm.touched.debts && formikForm.errors.debts,
                  })}
                  value={formikForm.values.debts}
                  onChange={(e) => {
                    if (e.target.value === "0") {
                      formikForm.setFieldValue("loan_sum", "");
                    }
                    formikForm.setFieldValue("debts", e.target.value);
                  }}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("UPDATE_QUESTIONNAIRE.NO")}
                  </option>
                  <option value="1">
                    {t<string>("UPDATE_QUESTIONNAIRE.YES")}
                  </option>
                </select>
              </label>
            </div>

            {formikForm.touched.debts && formikForm.errors.debts && (
              <div className="form-control-error">
                {formikForm.errors.debts}
              </div>
            )}

            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.type,
                    "about-box__field-inp-error":
                      formikForm.touched.type && formikForm.errors.type,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.LOAN_TYPE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.type,
                    "about-box__field-inp-touched":
                      formikForm.touched.type && formikForm.errors.type,
                  })}
                  {...formikForm.getFieldProps("type")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="1">
                    {t<string>("UPDATE_QUESTIONNAIRE.CONSUMER_LOANS")}
                  </option>
                  <option value="7">
                    {t<string>("UPDATE_QUESTIONNAIRE.CREDIT_CARD")}
                  </option>
                  <option value="2">
                    {t<string>("UPDATE_QUESTIONNAIRE.CAR_LEASING")}
                  </option>
                  <option value="3">
                    {t<string>("UPDATE_QUESTIONNAIRE.REFINANCING")}
                  </option>
                  <option value="4">
                    {t<string>("UPDATE_QUESTIONNAIRE.MERGING_LOANS")}
                  </option>
                  <option value="5">
                    {t<string>("UPDATE_QUESTIONNAIRE.MORTGAGE_LOANS")}
                  </option>
                  <option value="6">
                    {t<string>("UPDATE_QUESTIONNAIRE.QUICK_LOAN")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.type && formikForm.errors.type && (
              <div className="form-control-error">{formikForm.errors.type}</div>
            )}

            {formikForm.values.debts !== "0" && formikForm.values.debts !== "" && (
              <div className="about__form">
                <label
                  className="about__form-range about-range"
                  htmlFor="family-no">
                  <span
                    className={classNames({
                      "about-range__name": true,
                      "about-box__field-inp-error":
                        formikForm.touched.loan_sum &&
                        formikForm.errors.loan_sum,
                    })}>
                    {t<string>("UPDATE_QUESTIONNAIRE.ALL_LOANS")}
                  </span>
                  <div className="about-range__gen">
                    <label className="about-range__gen-field">
                      <input
                        type="text"
                        className="about-range__gen-field-put"
                        placeholder="1000"
                        value={formikForm.values.loan_sum}
                        onChange={(e) => {
                          let value = Number(e.target.value);
                          value > 1000000 ||
                          value < 0 ||
                          e.target.value === "0000" ||
                          e.target.value === "0"
                            ? formikForm.setFieldValue("loan_sum", "100000")
                            : formikForm.setFieldValue(
                                "loan_sum",
                                e.target.value
                              );
                        }}
                      />
                      {t<string>("UPDATE_QUESTIONNAIRE.BGN")}
                    </label>
                    <input
                      className="about-range__gen-inp"
                      type="range"
                      step={10000}
                      min={0}
                      max={1000000}
                      {...formikForm.getFieldProps("loan_sum")}
                    />
                  </div>
                </label>
              </div>
            )}
          </div>

          <div className="about__form">
            <div className="about__form-box about-ask">
              <h4 className="about-ask__question">
                {t<string>("UPDATE_QUESTIONNAIRE.INSOLVENCY")}
              </h4>
              <div className="about-ask__variants">
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.insolvency === "1",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("insolvency", "1")}>
                  <div className="var-block__click">
                    {t<string>("UPDATE_QUESTIONNAIRE.YES")}
                  </div>
                </button>
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.insolvency === "0",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("insolvency", "0")}>
                  <div className="var-block__click">
                    {t<string>("UPDATE_QUESTIONNAIRE.NO")}
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="about__form">
            <div className="about__form-box about-ask">
              <h4 className="about-ask__question">
                {t<string>("UPDATE_QUESTIONNAIRE.JOB_EXIST")}
              </h4>
              <div className="about-ask__variants">
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.job === "1",
                  })}
                  type="button"
                  onClick={() => jobInfoClear("1")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.YES")}
                </button>
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.job === "0",
                  })}
                  type="button"
                  onClick={() => jobInfoClear("0")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.NO")}
                </button>
              </div>
            </div>
          </div>
          {/* {formikForm.values.job === "0" && ( */}
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("UPDATE_QUESTIONNAIRE.LIFESTYLE")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.income_from,
                    "about-box__field-inp-error":
                      formikForm.touched.income_from &&
                      formikForm.errors.income_from,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.INCOME_WAY")}
                </span>
                <input
                  type="text"
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.income_from,
                    "about-box__field-inp-touched":
                      formikForm.touched?.income_from &&
                      formikForm.errors?.income_from,
                  })}
                  value={formikForm.values.income_from}
                  onChange={(event) => {
                    if (event.target.value.length > 36) {
                      formikForm.setFieldValue("income_from", prevIncome);
                    } else {
                      formikForm.setFieldValue(
                        "income_from",
                        event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched?.income_from &&
              formikForm.errors?.income_from && (
                <div className="form-control-error">
                  {formikForm.errors?.income_from}
                </div>
              )}
          </div>
          {/* )}
          {formikForm.values.job === "1" && ( */}
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.working_place,
                    "about-box__field-inp-error":
                      formikForm.touched?.working_place &&
                      formikForm.errors?.working_place,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.WORK_PLACE")}
                </span>
                <input
                  type="text"
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.working_place,
                    "about-box__field-inp-touched":
                      formikForm.touched?.working_place &&
                      formikForm.errors?.working_place,
                  })}
                  {...formikForm.getFieldProps("working_place")}
                />
              </label>
            </div>
            {formikForm.touched?.working_place &&
              formikForm.errors?.working_place && (
                <div className="form-control-error">
                  {t<string>(formikForm.errors?.working_place)}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.position,
                    "about-box__field-inp-error":
                      formikForm.touched?.position &&
                      formikForm.errors?.position,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.POSITION")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.position,
                    "about-box__field-inp-touched":
                      formikForm.touched?.position &&
                      formikForm.errors?.position,
                  })}
                  {...formikForm.getFieldProps("position")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("UPDATE_QUESTIONNAIRE.EMPLOYED")}
                  </option>
                  <option value="1">
                    {t<string>("UPDATE_QUESTIONNAIRE.CONTRACT")}
                  </option>
                  <option value="2">
                    {t<string>("UPDATE_QUESTIONNAIRE.SELF_EMPLOYED")}
                  </option>
                  <option value="3">
                    {t<string>("UPDATE_QUESTIONNAIRE.FREELANCE")}
                  </option>
                  <option value="4">
                    {t<string>("UPDATE_QUESTIONNAIRE.CIVIL")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched?.position && formikForm.errors?.position && (
              <div className="form-control-error">
                {t<string>(formikForm.errors?.position)}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.experience,
                    "about-box__field-inp-error":
                      formikForm.touched?.experience &&
                      formikForm.errors?.experience,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.WORK_EXPERIENCE")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.experience,
                    "about-box__field-inp-touched":
                      formikForm.touched?.experience &&
                      formikForm.errors?.experience,
                  })}
                  type="text"
                  value={formikForm.values.experience}
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("experience", "");
                    } else {
                      formikForm.setFieldValue(
                        "experience",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched?.experience &&
              formikForm.errors?.experience && (
                <div className="form-control-error">
                  {formikForm.errors?.experience}
                </div>
              )}
          </div>
          {/* )} */}
          {formikForm.values.job && (
            <div>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.monthly_income,
                      "about-box__field-inp-error":
                        formikForm.touched.monthly_income &&
                        formikForm.errors.monthly_income,
                    })}>
                    {t<string>("UPDATE_QUESTIONNAIRE.INCOME")}
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled":
                        formikForm.values.monthly_income,
                      "about-box__field-inp-touched":
                        formikForm.touched.monthly_income &&
                        formikForm.errors.monthly_income,
                    })}
                    placeholder="1000"
                    type="text"
                    value={formikForm.values.monthly_income}
                    onChange={(event) => {
                      if (
                        +event.target.value <= 0 ||
                        Number.isNaN(+event.target.value)
                      ) {
                        formikForm.setFieldValue("monthly_income", "");
                      } else {
                        formikForm.setFieldValue(
                          "monthly_income",
                          +event.target.value
                        );
                      }
                    }}
                  />
                </label>
              </div>
              {formikForm.touched.monthly_income &&
                formikForm.errors.monthly_income && (
                  <div className="form-control-error">
                    {formikForm.errors.monthly_income}
                  </div>
                )}
              {certs.length > 0 && certs.length < 5 && (
                <div
                  className="about__form-files"
                  onClick={() => {
                    dispatch(setChoosenData(questionnaire));
                    dispatch(
                      setModalType(
                        AuthSelectionType.adminSelectUserCertificates
                      )
                    );
                  }}>
                  {t<string>("UPDATE_QUESTIONNAIRE.SELECT_FROM_DOCUMENTS")}
                </div>
              )}
              <div className="about__form-box about-box">
                {certs.map((cert: any, index: number) => (
                  <div
                    className="about-box__file-block file-block file-block-item"
                    key={cert.name}>
                    <div className="file-block-select__box">
                      <img
                        className="file-block__icon"
                        src={addedFile}
                        alt="file"
                      />
                      <span className="file-block__txt">
                        <span
                          className="file-block__txt-underline"
                          onClick={async () => {
                            PrivateService.downloadDocument(cert.id, cert.name);
                          }}>
                          {cert.name}
                        </span>{" "}
                        {t<string>("PROFILE.ADDED")}
                      </span>
                    </div>
                    <button
                      className="file-block__txt"
                      type="button"
                      onClick={() => {
                        deleteCert(index, cert);
                      }}>
                      X
                    </button>
                  </div>
                ))}

                {certs.length < 5 && (
                  <label className="about-box__file">
                    <div className="about-box__file-block file-block">
                      <img
                        className="file-block__icon"
                        src={fileIcon}
                        alt="file"
                      />
                      <span className="file-block__txt">
                        {t<string>("UPDATE_QUESTIONNAIRE.ADD_CERT")}
                      </span>
                    </div>

                    <input
                      type="file"
                      accept=".doc, .docx, .pdf, .xlx, .csv, .zip, .png, .jpg, .jpeg, .gif, .svg"
                      className="file-block__inp"
                      onChange={(event: any) => {
                        const same = certs.some(
                          (cert) => cert.name === event.target.files[0].name
                        );
                        if (certs.length < 5 && event.target.files && !same) {
                          dispatch(setCerts([...certs, event.target.files[0]]));
                        } else {
                          toast.error("File already added");
                        }
                      }}
                    />
                  </label>
                )}
              </div>
              {certs.length === 0 && (
                <div className="form-control-error">
                  {t<string>("UPDATE_QUESTIONNAIRE.LEAST_CERT")}
                </div>
              )}
            </div>
          )}
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("UPDATE_QUESTIONNAIRE.EDUCATION")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.education,
                    "about-box__field-inp-error":
                      formikForm.touched.education &&
                      formikForm.errors.education,
                  })}>
                  {t<string>("UPDATE_QUESTIONNAIRE.DEGREE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.education,
                    "about-box__field-inp-touched":
                      formikForm.touched.education &&
                      formikForm.errors.education,
                  })}
                  {...formikForm.getFieldProps("education")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("UPDATE_QUESTIONNAIRE.UNIVERSITY")}
                  </option>
                  <option value="1">
                    {t<string>("UPDATE_QUESTIONNAIRE.HIGH_SCHOOL")}
                  </option>
                  <option value="2">
                    {t<string>("UPDATE_QUESTIONNAIRE.SECONDARY")}
                  </option>
                  <option value="3">
                    {t<string>("UPDATE_QUESTIONNAIRE.PRIMARY")}
                  </option>
                  <option value="4">
                    {t<string>("UPDATE_QUESTIONNAIRE.NO_COMPLETED")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.education && formikForm.errors.education && (
              <div className="form-control-error">
                {formikForm.errors.education}
              </div>
            )}
            <div className="about__form-box about-ask about-ask-bottom">
              <h4 className="about-ask__question">
                {t<string>("UPDATE_QUESTIONNAIRE.SIGNIFICANT_PERSON")}
              </h4>
              <div className="about-ask__variants">
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.politic === "1",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("politic", "1")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.YES")}
                </button>
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.politic === "0",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("politic", "0")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.NO")}
                </button>
              </div>
            </div>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.amount_1,
                  "about-box__field-inp-error":
                    formikForm.touched.amount_1 && formikForm.errors.amount_1,
                })}>
                {t<string>("UPDATE_QUESTIONNAIRE.AMOUNT")} 1
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.amount_1,
                  "about-box__field-inp-touched":
                    formikForm.touched.amount_1 && formikForm.errors.amount_1,
                })}
                placeholder={`${t<string>("UPDATE_QUESTIONNAIRE.AMOUNT")} 1`}
                {...formikForm.getFieldProps("amount_1")}
              />
            </label>
          </div>
          {formikForm.values.type === "4" && (
            <>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.amount_2,
                      "about-box__field-inp-error":
                        formikForm.touched.amount_2 &&
                        formikForm.errors.amount_2,
                    })}>
                    {t<string>("UPDATE_QUESTIONNAIRE.AMOUNT")} 2
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.amount_2,
                      "about-box__field-inp-touched":
                        formikForm.touched.amount_2 &&
                        formikForm.errors.amount_2,
                    })}
                    placeholder={`${t<string>(
                      "UPDATE_QUESTIONNAIRE.AMOUNT"
                    )} 2`}
                    {...formikForm.getFieldProps("amount_2")}
                  />
                </label>
              </div>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.amount_3,
                      "about-box__field-inp-error":
                        formikForm.touched.amount_3 &&
                        formikForm.errors.amount_3,
                    })}>
                    {t<string>("UPDATE_QUESTIONNAIRE.AMOUNT")} 3
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.amount_3,
                      "about-box__field-inp-touched":
                        formikForm.touched.amount_3 &&
                        formikForm.errors.amount_3,
                    })}
                    placeholder={`${t<string>(
                      "UPDATE_QUESTIONNAIRE.AMOUNT"
                    )} 3`}
                    {...formikForm.getFieldProps("amount_3")}
                  />
                </label>
              </div>
            </>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.term_1,
                  "about-box__field-inp-error":
                    formikForm.touched.term_1 && formikForm.errors.term_1,
                })}>
                {t<string>("UPDATE_QUESTIONNAIRE.TERM")} 1
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.term_1,
                  "about-box__field-inp-touched":
                    formikForm.touched.term_1 && formikForm.errors.term_1,
                })}
                placeholder={`${t<string>("UPDATE_QUESTIONNAIRE.TERM")} 1`}
                {...formikForm.getFieldProps("term_1")}
              />
            </label>
          </div>
          {formikForm.values.type === "4" && (
            <>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": formikForm.values.term_1,
                      "about-box__field-inp-error":
                        formikForm.touched.term_2 && formikForm.errors.term_2,
                    })}>
                    {t<string>("UPDATE_QUESTIONNAIRE.TERM")} 3
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.term_2,
                      "about-box__field-inp-touched":
                        formikForm.touched.term_2 && formikForm.errors.term_2,
                    })}
                    placeholder={`${t<string>("UPDATE_QUESTIONNAIRE.TERM")} 2`}
                    {...formikForm.getFieldProps("term_2")}
                  />
                </label>
              </div>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": formikForm.values.term_3,
                      "about-box__field-inp-error":
                        formikForm.touched.term_3 && formikForm.errors.term_3,
                    })}>
                    {t<string>("UPDATE_QUESTIONNAIRE.TERM")} 3
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.term_3,
                      "about-box__field-inp-touched":
                        formikForm.touched.term_3 && formikForm.errors.term_3,
                    })}
                    placeholder={`${t<string>("UPDATE_QUESTIONNAIRE.TERM")} 3`}
                    {...formikForm.getFieldProps("term_3")}
                  />
                </label>
              </div>
            </>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.bank_1,
                  "about-box__field-inp-error":
                    formikForm.touched.bank_1 && formikForm.errors.bank_1,
                })}>
                {t<string>("UPDATE_QUESTIONNAIRE.BANK")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.bank_1,
                  "about-box__field-inp-touched":
                    formikForm.touched.bank_1 && formikForm.errors.bank_1,
                })}
                placeholder={`${t<string>("UPDATE_QUESTIONNAIRE.BANK")} 1`}
                {...formikForm.getFieldProps("bank_1")}
              />
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.bank_2,
                  "about-box__field-inp-error":
                    formikForm.touched.bank_2 && formikForm.errors.bank_2,
                })}>
                {t<string>("UPDATE_QUESTIONNAIRE.BANK")} 2
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.bank_2,
                  "about-box__field-inp-touched":
                    formikForm.touched.bank_2 && formikForm.errors.bank_2,
                })}
                placeholder={`${t<string>("UPDATE_QUESTIONNAIRE.BANK")} 2`}
                {...formikForm.getFieldProps("bank_2")}
              />
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.bank_3,
                  "about-box__field-inp-error":
                    formikForm.touched.bank_3 && formikForm.errors.bank_3,
                })}>
                {t<string>("UPDATE_QUESTIONNAIRE.BANK")} 3
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.bank_3,
                  "about-box__field-inp-touched":
                    formikForm.touched.bank_3 && formikForm.errors.bank_3,
                })}
                placeholder={`${t<string>("UPDATE_QUESTIONNAIRE.BANK")} 3`}
                {...formikForm.getFieldProps("bank_3")}
              />
            </label>
          </div>
          <div className="about__form-box about-ask">
            <h4 className="about-ask__question">
              {t<string>("UPDATE_QUESTIONNAIRE.SELECT_STATUS")}
            </h4>
            <label className="about-box__field about__form-box about-box">
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.status,
                  "about-box__field-inp-touched":
                    formikForm.touched.status && formikForm.errors.status,
                })}
                value={formikForm.values.status}
                onChange={(e) => {
                  formikForm.setFieldValue("status", e.target.value);
                }}>
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="approved">
                  {t<string>("UPDATE_QUESTIONNAIRE.APPROVED")}
                </option>
                <option value="pending">
                  {t<string>("UPDATE_QUESTIONNAIRE.PENDING")}
                </option>
                <option value="declined">
                  {t<string>("UPDATE_QUESTIONNAIRE.DECLINED")}
                </option>
              </select>
            </label>
          </div>
          <button
            className={classNames({
              about__submit: true,
              "about__submit-active": formikForm.isValid,
            })}
            type="submit">
            {t<string>("UPDATE_QUESTIONNAIRE.SAVE_CHANGES")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateQuestionnaireModal;
