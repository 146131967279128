import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";

import Banner from "../../../Banner";
import Offers from "../../../Offers";

import { UIRoutes } from "../../../../core/router";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { Private, Public } from "../../../../core/models";
import { useAppSelector } from "../../../../core/store";

import bankIcon from "../../../../assets/photos/profile/bank-icon.svg";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../core/hooks/useAuth";

const BanksList = () => {
  const [sorterName, setSorterName] = useState("");
  const [sorterType, setSorterType] = useState("asc");
  const [sortBy, setSortBy] = useState("name");
  const [reviewsList, setReviewsList] = useState<any>([]);
  const [reviewsSorter, setReviewsSorter] = useState<any>({
    "order[created_at]": "desc",
  });
  const [reviewsPagination, setReviewsPagination] = useState<any>({
    cursor: undefined,
    per_page: 5,
  });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
    rating: 1,
  });
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isAuth } = useAuth();

  const { appLanguage } = useAppSelector((state) => state.app);

  const { fetchedData } = useHttpGet<any>(`${APIRoutes.PUBLIC_BANKS}`, {
    dependencies: [sorterName, sorterType, pagination, ],
    query: { [`${sorterName}`]: sorterType, ...pagination },
  });

  useHttpGet<any>(`${APIRoutes.PUBLIC_REVIEWS}`, {
    resolve: (response) => {
      setReviewsList(response?.payload.collection);
    },
    dependencies: [reviewsPagination],
    query: { ...reviewsPagination, ...reviewsSorter },
  });

  const setPaginationCursor = (cursor: string) => {
    setPagination({ ...pagination, cursor });
  };

  const setPaginationPage = () => {
    setPagination(() => {
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 };
      } else {
        return { ...pagination, per_page: 50 };
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  return (
    <div className="bank-rating">
      <div className="bank-rating__caption">
        <div className="bank-rating__caption-container">
          <h1 className="bank-rating__caption-title">
            {t<string>("RATING.TITLE")}
          </h1>
          <p className="bank-rating__caption-txt">{t<string>("RATING.DESC")}</p>
          <p className="bank-rating__caption-txt bank-rating__caption-txt-mobile">
            {t<string>("RATING.DESC")}
            <Link to="">More</Link>
          </p>
        </div>
      </div>
      <div className="bank-rating__container">
        <table className="bank-rating__container-table rating-table">
          <thead className="rating-table__headlines">
            <tr className="rating-table__headlines-box headlines-box">
              <th className="headlines-box__name">№</th>
              <th className="headlines-box__name">
                {t<string>("RATING.NAME")}
              </th>
              <th className="headlines-box__name">
                {t<string>("RATING.RATING")}
              </th>
              <th className="headlines-box__name">
                {t<string>("RATING.REVIEWS")}
              </th>
            </tr>
          </thead>
          <tbody className="rating-tables__essential">
            {fetchedData?.payload?.collection
              // .sort(
              //   (reviewPrev: Public.BankReview, review: Public.BankReview) =>
              //     review.rating - reviewPrev.rating
              // )
              .map((bank: Public.Bank, index: number) => (
                <tr
                  key={bank.id}
                  className="rating-tables__essential-box essential-box"
                  onClick={() =>
                    navigate(`/${UIRoutes.BANKS_RATING}/${bank.id}`)
                  }
                >
                  <td className="essential-box__item">{index + 1}</td>
                  <td className="essential-box__item essential-box__name">
                    <div className="essential-box__name-wrapper">
                      {bank.logo_path ? (
                        <img
                          className="essential-box__name-photo"
                          src={bank.logo_path}
                          alt={bank.logo_name}
                        />
                      ) : (
                        <img
                          className="essential-box__name-photo"
                          src={bankIcon}
                          alt={bank.logo_name}
                        />
                      )}
                      <p className="essential-box__name-link">
                        {appLanguage === "en"
                          ? bank.translations.en.name
                          : bank.translations.bg.name}
                      </p>
                    </div>
                  </td>
                  <td className="essential-box__item essential-box__rating">
                    <div className="essential-box__rating-stars">
                      <p>{bank.rating}</p>
                      <Rating
                        initialValue={bank.rating}
                        allowTitleTag={false}
                        readonly={true}
                        size={20}
                      />
                    </div>
                  </td>
                  <td className="essential-box__item">{bank.reviews_count}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <ul className="rating-list">
          {fetchedData?.payload?.collection.map((bank: Public.Bank) => (
            <li key={bank.id} className="rating-list__card">
              <div className="rating-list__card-box rating-card-box">
                <div className="rating-card-box__name">
                  <img
                    className="rating-card-box__name-icon"
                    src={bankIcon}
                    alt="icon"
                  />
                  <Link
                    to={`/${UIRoutes.BANKS_RATING}/${bank.id}`}
                    className="rating-card-box__name-title"
                  >
                    {appLanguage === "en"
                      ? bank.translations.en.name
                      : bank.translations.bg.name}
                  </Link>
                </div>
                <div className="rating-card-box__data card-data">
                  <div className="card-data__block">
                    <span className="card-data__block-text">
                      {t<string>("RATING.RATING")}
                    </span>
                    <div className="card-data__block-ratings data-blog-ratings">
                      <div className="data-blog-ratings__box">
                        <span className="data-blog-ratings__box-avg">
                          {bank.rating}
                        </span>
                        <Rating
                          initialValue={bank.rating}
                          allowTitleTag={false}
                          readonly={true}
                          size={20}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-data__block">
                    <span className="card-data__block-text">
                      {t<string>("RATING.REVIEWS")}
                    </span>
                    <span className="card-data__block-info">
                      {bank.reviews_count}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {fetchedData?.payload?.cursor.has_more_pages && (
          <button
            className="results-list-more"
            onClick={() => {
              setPaginationPage();
            }}
          >
            {t<string>("REVIEWS_TABLE.SHOW_MORE")}
          </button>
        )}

        {!fetchedData?.payload?.cursor.has_more_pages &&
          fetchedData?.payload?.collection.length > 10 && (
            <button
              className="results-list-more"
              onClick={setDefaultPagination}
            >
              {t<string>("USERS_TABLE.HIDE_ALL")}
            </button>
          )}
      </div>
      {reviewsList.length > 0 && (
        <div className="reviews-section">
          <h1 className="reviews-section__title">
            {t<string>("RATING.REVIEWS")}
          </h1>
          <div className="reviews-section-cards">
            {reviewsList.map((review: any, index: number) => (
              <div key={review.id} className="reviews-section-cards__item">
                <div className="reviews-section-cards__item-top">
                  <div className="reviews-section-cards__item-top-start">
                    {fetchedData?.payload?.collection
                      .filter((bank: Public.Bank) => bank.id === review.bank_id)
                      .map((bank: any) =>
                        bank?.logo_path ? (
                          <img
                            key={bank.id}
                            className="essential-box__item-photo"
                            src={bank.logo_path}
                            alt={bank.logo_name}
                          />
                        ) : (
                          <img
                            className="essential-box__item-photo"
                            src={bankIcon}
                            alt={bank.logo_name}
                          />
                        )
                      )}
                    <div className="reviews-section-cards__item-top-txt">
                      {fetchedData?.payload?.collection
                        .filter(
                          (bank: Public.Bank) => bank.id === review.bank_id
                        )
                        .map((bank: any) => (
                          <span
                            key={bank.id}
                            className="reviews-section-cards__item-top-title"
                          >
                            {appLanguage === "en"
                              ? bank?.translations?.en?.name
                              : bank?.translations?.bg?.name}
                          </span>
                        ))}
                      <Rating
                        allowTitleTag={false}
                        readonly={true}
                        size={24}
                        initialValue={review?.rating}
                      />
                    </div>
                  </div>
                  <span className="holder-box__date">
                    <span className="feed-info__date">
                      {`${review.created_at.slice(
                        8,
                        10
                      )}.${review.created_at.slice(
                        5,
                        7
                      )}.${review.created_at.slice(0, 4)}`}
                    </span>
                  </span>
                </div>
                <div className="reviews-section-cards__item-bottom">
                  <p className="reviews-section-cards__item-bottom-comment">
                    {review.comment}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Offers />
      <div className="bank-rating__banner">
        <Banner />
      </div>
    </div>
  );
};

export default BanksList;
