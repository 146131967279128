import React, { useState } from "react";

import arrow_right_gray from "../../../assets/photos/all/arrow_right_gray.svg";
import car_leasing_main from "../../../assets/photos/all/carLeasingMain.svg";
import leasing_bottom from "../../../assets/photos/all/leasing_bottom.svg";
import { useTranslation } from "react-i18next";

const CarLeasingBanner = () => {
  const [showMore, setShowMore] = useState<boolean | undefined>(false);
  const { t } = useTranslation()
  const text =
    "Calculate the car loan on the calculator online to choose the best conditions in 2022! Buy any car at a car dealership or by hand, from Europe or the USA without mileage restrictions";

  return (
    <div className="results-banner leasing-banner">
      <div className="results-banner-content">
        <div className="results-banner-content__nav-top nav-top">
          <span className="nav-top__first">Home</span>
          <img
            className="nav-top__arrow"
            src={arrow_right_gray}
            alt="arrow_right_gray"
            width="12px"
            height="12px"
          />
          <span className="nav-top__second">{t<string>("LOANS.CAR_LEASING")}</span>
        </div>
        <div className="results-banner-content__main-content main-content">
          <h1 className="main-content__header">{t<string>("LOANS.CAR_LEASING")}</h1>
          <p className="main-content__banner-desc banner-desc">
              {t<string>("RESULTS.CAR_LEASING_TITLE.LINE_1")}
            <br /> 
              {t<string>("RESULTS.CAR_LEASING_TITLE.LINE_2")}
            <br />
              {t<string>("RESULTS.CAR_LEASING_TITLE.LINE_3")}
          </p>
          <p className="main-content__banner-desc-hide banner-desc-hide">
            {showMore ? text : `${text.substring(0, 50)}...`}
            <button
              className="banner-desc-hide__btn"
              onClick={() => setShowMore((prev) => !prev)}
            >
              {showMore ? "Less" : "More"}
            </button>
          </p>
        </div>
      </div>
      <div className="results-banner-leasing">
        <img
          className="results-banner-leasing__bottom"
          src={leasing_bottom}
          alt="leasing_bottom"
        />
        <img
          className="results-banner-leasing__main"
          src={car_leasing_main}
          alt="car_leasing_banner"
        />
      </div>
    </div>
  );
};

export default CarLeasingBanner;
