import { FC } from "react";

type Props = {
  title: string;
  subtitle: string;
  photo: string;
};

const BenefitsCard: FC<Props> = ({ title, subtitle, photo }) => {
  return (
    <div className="benefits-container__benefits-card benefits-card">
      <div className="benefits-card-info">
        <h3 className="benefits-card-info-header">{title}</h3>
        <p className="benefits-card-info-subtitle">{subtitle}</p>
      </div>
      <div className="benefits-card__benefits-logo benefits-logo">
        <img className="benefits-logo__main" src={photo} alt={title} />
      </div>
    </div>
  );
};

export default BenefitsCard;
