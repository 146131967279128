import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";
// import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import DatePicker from "react-date-picker";

import { AppContext } from "../../../../App";

import PrivateService from "../../../../core/services/private.service";
import { useAppDispatch, useAppSelector } from "../../../../core/store";
import CommonService from "../../../../core/services/common.service";
import { filterPhoneNum, getPreviousDay } from "../../../../core/functions/funtions";
import { usePrevious } from "../../../../core/hooks/usePrevious";

import fileIcon from "../../../../assets/photos/all/add-file.svg";
import addedFile from "../../../../assets/photos/all/file-added.svg";
import CalendarIcon from "../../../../assets/photos/Calendar";
import { useTranslation } from "react-i18next";
import {
  setModal,
  setModalType,
} from "../../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../../core/services/enums";
import { setCerts } from "../../../../core/store/reducers/documents/documentsSlice";
import { City, Country } from "country-state-city";
import { Countries } from "../../../../core/constants/countries";

const NewQuestionnaireModal = () => {
  const [addLater, setAddLater] = useState(false);
  const [loading, setLoading] = useState(false);
  const [egn, setEgn] = useState(false);
  const [isEducated, setIsEducated] = useState(false);
  const [cities, setCities] = useState<any>([]);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const appContext = useContext(AppContext);
  const { certs } = useAppSelector((state) => state.document);
  const { choosenData, appLanguage } = useAppSelector((state) => state.app);
  const { setReloadChecker, reloadChecker } = appContext;

  useEffect(() => {
    if (choosenData) {
      for (let prop in choosenData) {
        choosenData[prop] !== null
          ? formikForm.setFieldValue(prop, String(choosenData[prop]))
          : formikForm.setFieldValue(prop, "");
      }

      if (!choosenData["personal_code"]) {
        setEgn(true);
      }
    }
  }, [choosenData, reloadChecker]);

  const valueFormValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t<string>("NEW_QUESTIONNAIRE.REQUIRED_PROPERTY"))
      .max(121, t<string>("NEW_QUESTIONNAIRE.MAX_121")),
    last_name: Yup.string()
      .required(t<string>("NEW_QUESTIONNAIRE.REQUIRED_PROPERTY"))
      .max(121, t<string>("NEW_QUESTIONNAIRE.MAX_121")),
    phone: Yup.string(),
    amount_1: Yup.string()
      .required(t<string>("NEW_QUESTIONNAIRE.REQUIRED_PROPERTY"))
      .max(15, t<string>("NEW_QUESTIONNAIRE.MAX_15_CHARS")),
    term_1: Yup.string()
      .required(t<string>("NEW_QUESTIONNAIRE.REQUIRED_PROPERTY"))
      .max(15, t<string>("NEW_QUESTIONNAIRE.MAX_3_CHARS")),
    house_number: Yup.string()
      .required(t<string>("NEW_QUESTIONNAIRE.REQUIRED_PROPERTY"))
      .matches(/^\d+$/, t<string>("NEW_QUESTIONNAIRE.NUMS_ONLY"))
      .max(15, t<string>("NEW_QUESTIONNAIRE.MAX_15_CHARS")),
    apartment_number: Yup.string().max(
      10,
      t<string>("NEW_QUESTIONNAIRE.TOO_MANY_CHARS")
    ),
    passport: Yup.string().length(
      9,
      t<string>("NEW_QUESTIONNAIRE.PASSPORT_RULE")
    ),
    loan_sum: Yup.number().when("debts", {
      is: (val: string) => val && val === "1",
      then: Yup.number()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .min(250, t<string>("PROFILE.MIN_LOAN_SUM")),
    }),
    insolvency: Yup.string().required(),
    update: Yup.string().required(),
    city_of_residence: Yup.string().max(
      55,
      t<string>("NEW_QUESTIONNAIRE.MAX_55_CHARS")
    ),
    housing_type: Yup.string().max(
      55,
      t<string>("NEW_QUESTIONNAIRE.MAX_55_CHARS")
    ),
    street_house: Yup.string().max(
      55,
      t<string>("NEW_QUESTIONNAIRE.MAX_55_CHARS")
    ),
    postal_code: Yup.string()
      .matches(/^\d+$/, t<string>("NEW_QUESTIONNAIRE.NUMS_ONLY"))
      .max(10, t<string>("NEW_QUESTIONNAIRE.MAX_10_CHARS")),
    type: Yup.string().required(
      t<string>("NEW_QUESTIONNAIRE.REQUIRED_PROPERTY")
    ),

    job: Yup.string(),
    education: Yup.string(),
    politic: Yup.string(),
    monthly_income: Yup.string().when("job", {
      is: (val: string) => val,
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    position: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    income_from: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(36, t<string>("PROFILE.MAX_36_CHARS")),
    }),
    job_status: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    working_place: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(55, t<string>("PROFILE.MAX_55_CHARS")),
    }),
    experience: Yup.number().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.number().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
  });

  const formikForm = useFormik<{
    id: number;
    phone: string;
    first_name: string;
    user_id: number;
    last_name: string;
    gender: string;
    date_of_birth: string;
    citizenship: string;
    personal_code: string;
    passport: string;
    marital_status: number;
    children: number;
    country_of_residence: string;
    city_of_residence: string;
    housing_type: string;
    street_house: string;
    postal_code: number;
    house_number: number;
    apartment_number: number;
    job: string;
    education: string;
    working_place: string;
    position: number;
    experience: number;
    job_status: string;
    income_from: number;
    monthly_income: number;
    update: number;
    status: string | number;
    type: string | number;
    current_amount: string;
    amount: number;
    term: number;
    term_1: string | number;
    term_2: string | number;
    term_3: string | number;
    amount_1: string | number;
    amount_2: string | number;
    amount_3: string | number;
    bank_1: string | number;
    bank_2: string | number;
    bank_3: string | number;
    certificates_1: any;
    certificates_2: any;
    certificates_3: any;
    certificates_4: any;
    certificates_5: any;
    politic: string;
    debts: string | number;
    loan_sum: number | string;
    insolvency: string | number;
    egn: boolean | string;
  }>({
    initialValues: {
      id: 0,
      user_id: 0,
      phone: "",
      first_name: "",
      last_name: "",
      gender: "",
      date_of_birth: "",
      citizenship: "",
      personal_code: "",
      passport: "",
      marital_status: 0,
      children: 0,
      country_of_residence: "",
      city_of_residence: "",
      housing_type: "",
      street_house: "",
      postal_code: 0,
      house_number: 0,
      apartment_number: 0,
      job: "1", // TODO: check for 1s
      working_place: "",
      position: 0,
      experience: 0,
      job_status: "",
      income_from: 0,
      monthly_income: 10000,
      education: "",
      politic: "",
      debts: "",
      loan_sum: "",
      insolvency: "",
      term: 0,
      term_1: "",
      term_2: "",
      term_3: "",
      amount_1: "",
      amount_2: "",
      amount_3: "",
      bank_1: "",
      bank_2: "",
      bank_3: "",
      certificates_1: "",
      certificates_2: "",
      certificates_3: "",
      certificates_4: "",
      certificates_5: "",
      update: 1,
      status: "",
      type: "",
      current_amount: "",
      amount: 1000,
      egn: false,
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      const result = certificateFilter();

      handleSubmitForm({ ...values, ...result });
    },
  });

  const handleSubmitForm = async (values: any) => {
    setLoading(true);
    if (
      !personalCodeValidation() ||
      (formikForm.values.personal_code.length === 0 && !formikForm.values.egn)
    ) {
      toast.error(`${t<string>("PROFILE.EGN_INVALID")}`);
      return;
    }

    delete values.egn;

    try {
      const response = await PrivateService.addNewQuestionnaire(
        {
          ...values,
          informed: 1,
          agree: 1,
        },
        choosenData.id
      );

      setReloadChecker(!reloadChecker);
      setLoading(false);
      toast.success(response?.data?.message);
      dispatch(setModal(false));
    } catch (errors: any) {
      dispatch(setModal(true));
      CommonService.showErrors(errors?.response?.data?.payload);
      toast.error(errors?.response?.data?.message);
      setLoading(false);
    }
  };

  const prevEgn = usePrevious(formikForm.values.personal_code);
  const prevPassport = usePrevious(formikForm.values.passport);
  const prevIncome = usePrevious(formikForm.values.income_from);

  const deleteCert = (file: File) => {
    const newCertificates = certs.filter(
      (cert: any) => cert.name !== file.name
    );

    dispatch(setCerts(newCertificates));
    setReloadChecker(!reloadChecker);
  };

  const personalCodeValidation = () => {
    if (
      formikForm.values.personal_code.length === 0 ||
      formikForm.values.personal_code.length === 10
    ) {
      return true;
    }

    return false;
  };

  const certificateFilter = () => {
    const files = certs
      .filter((cert: any) => cert.id === undefined)
      .reduce(
        (a: any, v: any, index: number) => ({
          ...a,
          [`certificates_${index + 1}`]: v,
        }),
        {}
      );

    const existed = certs
      .filter((cert: any) => cert.id !== undefined)
      .reduce(
        (a: any, v: any, index: number) => ({
          ...a,
          [`certificates_ids[${index}]`]: v.id,
        }),
        {}
      );

    return {
      ...files,
      ...existed,
    };
  };

  useEffect(() => {
    Country.getAllCountries().filter((country) => {
      if (country.name === formikForm.values.country_of_residence) {
        setCities(City.getCitiesOfCountry(country.isoCode));
      }
    });
  }, [formikForm.values.country_of_residence]);

  const jobInfoClear = (value: string) => {
    formikForm.setFieldValue("working_place", "");
    formikForm.setFieldValue("position", "");
    formikForm.setFieldValue("experience", "");
    formikForm.setFieldValue("monthly_income", "");
    formikForm.setFieldValue("status", "");
    formikForm.setFieldValue("income_from", "");
    formikForm.setFieldValue("job", value);
    formikForm.validateForm();
  };

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("NEW_QUESTIONNAIRE.ADD_QUEST")}
        </p>
        <form className="about" onSubmit={formikForm.handleSubmit}>
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.first_name,
                    "about-box__field-inp-error":
                      formikForm.touched.first_name &&
                      formikForm.errors.first_name,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.FIRST_NAME")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched.first_name &&
                      formikForm.errors.first_name,
                    "about-box__field-inp-filled": formikForm.values.first_name,
                  })}
                  placeholder={t<string>(
                    "NEW_QUESTIONNAIRE.FIRST_NAME_PLACEHOLDER"
                  )}
                  {...formikForm.getFieldProps("first_name")}
                />
              </label>
            </div>
            {formikForm.touched.first_name && formikForm.errors.first_name && (
              <div className="form-control-error">
                {formikForm.errors.first_name}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.last_name &&
                      formikForm.errors.last_name,
                    "about-box__field-name-filled": formikForm.values.last_name,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.LAST_NAME")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched.last_name &&
                      formikForm.errors.last_name,
                    "about-box__field-inp-filled": formikForm.values.last_name,
                  })}
                  placeholder={t<string>(
                    "NEW_QUESTIONNAIRE.LAST_NAME_PLACEHOLDER"
                  )}
                  {...formikForm.getFieldProps("last_name")}
                />
              </label>
            </div>
            {formikForm.touched.last_name && formikForm.errors.last_name && (
              <div className="form-control-error">
                {formikForm.errors.last_name}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.phone,
                    "about-box__field-inp-error":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.PHONE_NUMBER")}
                </span>
                <InputMask
                  name="phone"
                  className={classNames("data-input__input-field",{
                    "form-control": true,
                    "form-control-touched":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}
                  maskChar=" "
                  mask="+35\9 (999) 999-9999"
                  placeholder="+359 (___) ___-___ "
                  value={formikForm.values.phone}
                  onChange={(event) => {
                    formikForm.setFieldValue(
                      "phone",
                      filterPhoneNum(event.target.value)
                    );
                  }}
                />
                {/* <PhoneInput
                  dropdownClass="phone-dropdown"
                  enableSearch={true}
                  countryCodeEditable={false}
                  inputClass={classNames({
                    "form-control": true,
                    "form-control-touched":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}
                  country={"bg"}
                  value={formikForm.values.phone}
                  onChange={(phone) => {
                    formikForm.setFieldValue("phone", phone);
                  }}
                /> */}
              </label>
            </div>
            {formikForm.touched.phone && formikForm.errors.phone && (
              <div className="form-control-error">
                {formikForm.errors.phone}
              </div>
            )}

            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.gender,
                    "about-box__field-inp-error":
                      formikForm.touched.gender && formikForm.errors.gender,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.GENDER")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.gender,
                    "about-box__field-inp-touched":
                      formikForm.touched.gender && formikForm.errors.gender,
                  })}
                  {...formikForm.getFieldProps("gender")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("NEW_QUESTIONNAIRE.MALE")}
                  </option>
                  <option value="1">
                    {t<string>("NEW_QUESTIONNAIRE.FEMALE")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.gender && formikForm.errors.gender && (
              <div className="form-control-error">
                {formikForm.errors.gender}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.date_of_birth,
                    "about-box__field-inp-error":
                      formikForm.touched.date_of_birth &&
                      formikForm.errors.date_of_birth,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.DATE_OF_BIRTH")}
                </span>
                <DatePicker
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.date_of_birth,
                    "about-box__field-inp-touched":
                      formikForm.touched.date_of_birth &&
                      formikForm.errors.date_of_birth,
                  })}
                  format="dd/MM/y"
                  dayPlaceholder={t<string>("COMMON.CALENDAR_DAY")}
                  monthPlaceholder={t<string>("COMMON.CALENDAR_MONTH")}
                  yearPlaceholder={t<string>("COMMON.CALENDAR_YEAR")}
                  locale={appLanguage}
                  maxDate={getPreviousDay()}
                  minDate={new Date(new Date("1900-01-01").getTime())}
                  onChange={(e: any) => {
                    if (e) {
                      formikForm.setFieldValue(
                        "date_of_birth",
                        `${e.getFullYear()}-${
                          Number(e.getMonth() + 1) < 10
                            ? "0" + `${e.getMonth() + 1}`
                            : e.getMonth() + 1
                        }-${
                          Number(e.getDate()) < 10
                            ? "0" + `${e.getDate()}`
                            : e.getDate()
                        }`
                      );
                    }
                  }}
                  value={
                    formikForm.values.date_of_birth
                      ? new Date(
                          new Date(formikForm.values.date_of_birth).getTime()
                        )
                      : undefined
                  }
                  calendarIcon={<CalendarIcon />}
                />
                {formikForm.touched.date_of_birth &&
                  formikForm.errors.date_of_birth && (
                    <div className="form-control-error">
                      {formikForm.errors.date_of_birth}
                    </div>
                  )}
              </label>
            </div>
          </div>
          {formikForm.touched.date_of_birth &&
            formikForm.errors.date_of_birth && (
              <div className="form-control-error">
                {formikForm.errors.date_of_birth}
              </div>
            )}
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("NEW_QUESTIONNAIRE.PASSPORT_INFO")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.citizenship &&
                      formikForm.errors.citizenship,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.CITIZENSHIP")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.citizenship,
                    "about-box__field-inp-touched":
                      formikForm.touched.citizenship &&
                      formikForm.errors.citizenship,
                  })}
                  value={formikForm.values.citizenship}
                  onChange={(e) =>
                    formikForm.setFieldValue("citizenship", e.target.value)
                  }>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("NEW_QUESTIONNAIRE.BULGARIA")}
                  </option>
                  <option value="1">
                    {t<string>("NEW_QUESTIONNAIRE.OTHER")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.citizenship &&
              formikForm.errors.citizenship && (
                <div className="form-control-error">
                  {formikForm.errors.citizenship}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      (!personalCodeValidation() ||
                        (formikForm.values.personal_code.length === 0 &&
                          !formikForm.values.egn)) &&
                      formikForm.touched.personal_code,
                  })}>
                  {t<string>("UPDATE_USER.PERSONAL_IDENTIFICATION_CODE")}
                </span>
                <input
                  disabled={formikForm.values.egn === "checked"}
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.personal_code,
                    "about-box__field-inp-touched":
                      (!personalCodeValidation() ||
                        (formikForm.values.personal_code.length === 0 &&
                          !formikForm.values.egn)) &&
                      formikForm.touched.personal_code,
                  })}
                  placeholder="1002498384"
                  value={formikForm.values.personal_code}
                  type="text"
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("personal_code", "");
                    } else {
                      if (event.target.value.length > 10) {
                        formikForm.setFieldValue("personal_code", prevEgn);
                      } else {
                        formikForm.setFieldValue(
                          "personal_code",
                          event.target.value
                        );
                        event.target.value.length > 0
                          ? formikForm.setFieldValue("egn", true)
                          : formikForm.setFieldValue("egn", false);
                      }
                    }
                    personalCodeValidation();
                  }}
                />
              </label>
              <label className="about-box__egn">
                <input
                  checked={formikForm.values.egn === "checked"}
                  type="checkbox"
                  onChange={() => {
                    if (formikForm.values.egn !== "checked") {
                      formikForm.setFieldValue("egn", "checked");
                      formikForm.setFieldValue("personal_code", "");
                    } else {
                      formikForm.setFieldValue("egn", false);
                    }
                  }}
                />
                {t<string>("UPDATE_USER.NO_EGN")}
              </label>
            </div>
            {!personalCodeValidation() && (
              <div className="form-control-error">
                {t<string>("UPDATE_QUESTIONNAIRE.EGN_RULE")}
              </div>
            )}
            {!formikForm.values.egn &&
              formikForm.values.personal_code.length !== 10 &&
              formikForm.touched.personal_code && (
                <div className="form-control-error">
                  {t<string>("PROFILE.ADD_EGN")}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.passport && formikForm.errors.passport,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.PASSPORT_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.passport,
                    "about-box__field-inp-touched":
                      formikForm.touched.passport && formikForm.errors.passport,
                  })}
                  placeholder="1002498384"
                  type="text"
                  value={formikForm.values.passport}
                  onChange={(event) => {
                    if (
                      +event.target.value < 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("passport", "");
                    } else {
                      if (event.target.value.length > 9) {
                        formikForm.setFieldValue("passport", prevPassport);
                      } else {
                        formikForm.setFieldValue(
                          "passport",
                          event.target.value
                        );
                      }
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.passport && formikForm.errors.passport && (
              <div className="form-control-error">
                {formikForm.errors.passport}
              </div>
            )}
          </div>

          <div className="about__form">
            <h2 className="about__title">
              {t<string>("NEW_QUESTIONNAIRE.FAMILY_STATUS")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.marital_status,
                    "about-box__field-inp-error":
                      formikForm.touched.marital_status &&
                      formikForm.errors.marital_status,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.MARITAL_STATUS")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.marital_status,
                    "about-box__field-inp-touched":
                      formikForm.touched.marital_status &&
                      formikForm.errors.marital_status,
                  })}
                  {...formikForm.getFieldProps("marital_status")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("NEW_QUESTIONNAIRE.MARRIED")}
                  </option>
                  <option value="1">
                    {t<string>("NEW_QUESTIONNAIRE.SINGLE")}
                  </option>
                  <option value="2">
                    {t<string>("NEW_QUESTIONNAIRE.CIVIL_MARRIAGE")}
                  </option>
                  <option value="3">
                    {t<string>("NEW_QUESTIONNAIRE.DIVORCED")}
                  </option>
                  <option value="4">
                    {t<string>("NEW_QUESTIONNAIRE.WIDOWED")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.marital_status &&
              formikForm.errors.marital_status && (
                <div className="form-control-error">
                  {formikForm.errors.marital_status}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.children,
                    "about-box__field-inp-error":
                      formikForm.touched.children && formikForm.errors.children,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.UNDER_18")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.children,
                    "about-box__field-inp-touched":
                      formikForm.touched.children && formikForm.errors.children,
                  })}
                  {...formikForm.getFieldProps("children")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">
                    {t<string>("NEW_QUESTIONNAIRE.MORE_THAN_3")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.children && formikForm.errors.children && (
              <div className="form-control-error">
                {formikForm.errors.children}
              </div>
            )}
          </div>

          <div className="about__form">
            <h2 className="about__title">
              {t<string>("NEW_QUESTIONNAIRE.RESIDENCE_ADDRESS")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.country_of_residence,
                    "about-box__field-inp-error":
                      formikForm.touched.country_of_residence &&
                      formikForm.errors.country_of_residence,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.COUNTRY_OF_RESIDENCE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.country_of_residence,
                    "about-box__field-inp-touched":
                      formikForm.touched.country_of_residence &&
                      formikForm.errors.country_of_residence,
                  })}
                  {...formikForm.getFieldProps("country_of_residence")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  {[
                    ...Country.getAllCountries().filter(
                      (country) => country.name === "Bulgaria"
                    ),
                    ...Country.getAllCountries().filter(
                      (country) => country.name !== "Bulgaria"
                    ),
                  ].map((country: any, index: number) => (
                    <option
                      className={classNames({
                        "bul-selected":
                          country.name === "Bulgaria" ||
                          country.name === "България",
                      })}
                      value={country.name}
                      key={index}>
                      {t<string>(`COUNTRY.${country.name.toUpperCase()}`)
                        ?.split(" ")
                        .map(
                          (word) =>
                            word[0]?.toUpperCase() + word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            {formikForm.touched.country_of_residence &&
              formikForm.errors.country_of_residence && (
                <div className="form-control-error">
                  {formikForm.errors.country_of_residence}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.city_of_residence,
                    "about-box__field-inp-error":
                      formikForm.touched.city_of_residence &&
                      formikForm.errors.city_of_residence,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.CITY_OF_RESIDENCE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.city_of_residence,
                    "about-box__field-inp-touched":
                      formikForm.touched.city_of_residence &&
                      formikForm.errors.city_of_residence,
                  })}
                  {...formikForm.getFieldProps("city_of_residence")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  {formikForm.values.country_of_residence === "Bulgaria"
                    ? cities.map((city: any, index: number) => (
                        <option value={city.name} key={index}>
                          {t<string>(`CITIES.${city.name.toUpperCase()}`)}
                        </option>
                      ))
                    : cities.map((city: any, index: number) => (
                        <option value={city.name} key={index}>
                          {city.name}
                        </option>
                      ))}
                </select>
              </label>
            </div>
            {formikForm.touched.city_of_residence &&
              formikForm.errors.city_of_residence && (
                <div className="form-control-error">
                  {formikForm.errors.city_of_residence}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.housing_type,
                    "about-box__field-inp-error":
                      formikForm.touched.housing_type &&
                      formikForm.errors.housing_type,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.TYPE_OF_HOUSING")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.housing_type,
                    "about-box__field-inp-touched":
                      formikForm.touched.housing_type &&
                      formikForm.errors.housing_type,
                  })}
                  {...formikForm.getFieldProps("housing_type")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("NEW_QUESTIONNAIRE.OWN_HOME")}
                  </option>
                  <option value="1">
                    {t<string>("NEW_QUESTIONNAIRE.RENTING")}
                  </option>
                  <option value="2">
                    {t<string>("NEW_QUESTIONNAIRE.PARENTAL")}
                  </option>
                  <option value="3">
                    {t<string>("NEW_QUESTIONNAIRE.OWN_WITH_MORTGAGE")}
                  </option>
                  <option value="4">
                    {t<string>("NEW_QUESTIONNAIRE.OTHER")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.housing_type &&
              formikForm.errors.housing_type && (
                <div className="form-control-error">
                  {formikForm.errors.housing_type}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.street_house,
                    "about-box__field-inp-error":
                      formikForm.touched.street_house &&
                      formikForm.errors.street_house,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.STREET_HOUSE")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.street_house,
                    "about-box__field-inp-touched":
                      formikForm.touched.street_house &&
                      formikForm.errors.street_house,
                  })}
                  placeholder={t<string>(
                    "NEW_QUESTIONNAIRE.STREET_HOUSE_PLACEHOLDER"
                  )}
                  {...formikForm.getFieldProps("street_house")}
                />
              </label>
            </div>
            {formikForm.touched.street_house &&
              formikForm.errors.street_house && (
                <div className="form-control-error">
                  {formikForm.errors.street_house}
                </div>
              )}

            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.house_number,
                    "about-box__field-inp-error":
                      formikForm.touched.house_number &&
                      formikForm.errors.house_number,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.HOUSE_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.house_number,
                    "about-box__field-inp-touched":
                      formikForm.touched.house_number &&
                      formikForm.errors.house_number,
                  })}
                  placeholder="7"
                  type="text"
                  value={formikForm.values.house_number}
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("house_number", "");
                    } else {
                      formikForm.setFieldValue(
                        "house_number",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.house_number &&
              formikForm.errors.house_number && (
                <div className="form-control-error">
                  {formikForm.errors.house_number}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.apartment_number,
                    "about-box__field-inp-error":
                      formikForm.touched.apartment_number &&
                      formikForm.errors.apartment_number,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.APARTMENT_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.apartment_number,
                    "about-box__field-inp-touched":
                      formikForm.touched.apartment_number &&
                      formikForm.errors.apartment_number,
                  })}
                  placeholder="24"
                  type="text"
                  value={formikForm.values.apartment_number}
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("apartment_number", "");
                    } else {
                      formikForm.setFieldValue(
                        "apartment_number",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.apartment_number &&
              formikForm.errors.country_of_residence && (
                <div className="form-control-error">
                  {formikForm.errors.apartment_number}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.postal_code,
                    "about-box__field-inp-error":
                      formikForm.touched.postal_code &&
                      formikForm.errors.postal_code,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.POSTAL_CODE")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.postal_code,
                    "about-box__field-inp-touched":
                      formikForm.touched.postal_code &&
                      formikForm.errors.postal_code,
                  })}
                  placeholder="203-320"
                  {...formikForm.getFieldProps("postal_code")}
                />
              </label>
            </div>
            {formikForm.touched.postal_code &&
              formikForm.errors.postal_code && (
                <div className="form-control-error">
                  {formikForm.errors.postal_code}
                </div>
              )}
          </div>
          <h4 className="about-ask__question">
            {t<string>("NEW_QUESTIONNAIRE.ANY_LOANS")}
          </h4>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.debts,
                  "about-box__field-inp-touched":
                    formikForm.touched.debts && formikForm.errors.debts,
                })}
                value={formikForm.values.debts}
                onChange={(e) => {
                  if (e.target.value === "0") {
                    formikForm.setFieldValue("loan_sum", "");
                  }
                  formikForm.setFieldValue("debts", e.target.value);
                }}>
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="0">{t<string>("NEW_QUESTIONNAIRE.NO")}</option>
                <option value="1">{t<string>("NEW_QUESTIONNAIRE.YES")}</option>
              </select>
            </label>
          </div>

          {formikForm.touched.debts && formikForm.errors.debts && (
            <div className="form-control-error">{formikForm.errors.debts}</div>
          )}

          {formikForm.values.debts !== "0" && formikForm.values.debts !== "" && (
            <div className="about__form">
              <label
                className="about__form-range about-range"
                htmlFor="family-no">
                <span
                  className={classNames({
                    "about-range__name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.loan_sum && formikForm.errors.loan_sum,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.ALL_LOANS")}
                </span>
                <div className="about-range__gen about-range__loan-sum">
                  <label className="about-range__gen-field">
                    <input
                      type="text"
                      className="about-range__gen-field-put"
                      placeholder="1000"
                      value={formikForm.values.loan_sum}
                      onChange={(event) => {
                        if (
                          +event.target.value <= 0 ||
                          Number.isNaN(+event.target.value)
                        ) {
                          formikForm.setFieldValue("loan_sum", "");
                        } else {
                          if (+event.target.value > 100000) {
                            formikForm.setFieldValue("loan_sum", 100000);
                          } else {
                            formikForm.setFieldValue(
                              "loan_sum",
                              +event.target.value
                            );
                          }
                        }
                      }}
                    />
                    {t<string>("NEW_QUESTIONNAIRE.BGN")}
                  </label>
                </div>
              </label>
            </div>
          )}
          <div className="about__form">
            <div className="about__form-box about-ask">
              <h4 className="about-ask__question">
                {t<string>("NEW_QUESTIONNAIRE.INSOLVENCY")}
              </h4>
              <div className="about-ask__variants">
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.insolvency === "1",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("insolvency", "1")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.YES")}
                </button>
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.insolvency === "0",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("insolvency", "0")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.NO")}
                </button>
              </div>
            </div>
          </div>
          <div className="about__form">
            <div className="about__form-box about-ask">
              <h4 className="about-ask__question">
                {t<string>("NEW_QUESTIONNAIRE.JOB_EXIST")}
              </h4>
              <div className="about-ask__variants">
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.job === "1",
                  })}
                  type="button"
                  onClick={() => jobInfoClear("1")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.YES")}
                </button>
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.job === "0",
                  })}
                  type="button"
                  onClick={() => jobInfoClear("0")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.NO")}
                </button>
              </div>
            </div>
          </div>
          {formikForm.values.job === "0" && (
            <div className="about__form">
              <h2 className="about__title">
                {t<string>("NEW_QUESTIONNAIRE.LIFESTYLE")}
              </h2>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.job_status,
                      "about-box__field-inp-error":
                        formikForm.touched?.job_status &&
                        formikForm.errors?.job_status,
                    })}>
                    {t<string>("PROFILE.STATUS")}
                  </span>
                  <select
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-select": true,
                      "about-box__field-inp-filled":
                        formikForm.values.job_status,
                      "about-box__field-inp-touched":
                        formikForm.touched?.job_status &&
                        formikForm.errors?.job_status,
                    })}
                    {...formikForm.getFieldProps("job_status")}>
                    <option disabled hidden value="">
                      {t<string>("PROFILE.PLEASE_SELECT")}
                    </option>
                    <option value="0">{t<string>("PROFILE.STUDENT")}</option>
                    <option value="1">{t<string>("PROFILE.UNEMPLOYED")}</option>
                    <option value="2">{t<string>("PROFILE.PENSIONER")}</option>
                  </select>
                </label>
              </div>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.income_from,
                      "about-box__field-inp-error":
                        formikForm.touched.income_from &&
                        formikForm.errors.income_from,
                    })}>
                    {t<string>("NEW_QUESTIONNAIRE.INCOME_WAY")}
                  </span>
                  <input
                    type="text"
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled":
                        formikForm.values.income_from,
                      "about-box__field-inp-touched":
                        formikForm.touched?.income_from &&
                        formikForm.errors?.income_from,
                    })}
                    value={formikForm.values.income_from}
                    onChange={(event) => {
                      if (event.target.value.length > 36) {
                        formikForm.setFieldValue("income_from", prevIncome);
                      } else {
                        formikForm.setFieldValue(
                          "income_from",
                          event.target.value
                        );
                      }
                    }}
                  />
                </label>
              </div>
              {formikForm.touched?.income_from &&
                formikForm.errors?.income_from && (
                  <div className="form-control-error">
                    {formikForm.errors?.income_from}
                  </div>
                )}
            </div>
          )}
          {formikForm.values.job === "1" && (
            <div className="about__form">
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.working_place,
                      "about-box__field-inp-error":
                        formikForm.touched?.working_place &&
                        formikForm.errors?.working_place,
                    })}>
                    {t<string>("NEW_QUESTIONNAIRE.WORK_PLACE")}
                  </span>
                  <input
                    type="text"
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled":
                        formikForm.values.working_place,
                      "about-box__field-inp-touched":
                        formikForm.touched?.working_place &&
                        formikForm.errors?.working_place,
                    })}
                    {...formikForm.getFieldProps("working_place")}
                  />
                </label>
              </div>
              {formikForm.touched?.working_place &&
                formikForm.errors?.working_place && (
                  <div className="form-control-error">
                    {formikForm.errors?.working_place}
                  </div>
                )}
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.position,
                      "about-box__field-inp-error":
                        formikForm.touched?.position &&
                        formikForm.errors?.position,
                    })}>
                    {t<string>("NEW_QUESTIONNAIRE.POSITION")}
                  </span>
                  <select
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-select": true,
                      "about-box__field-inp-filled": formikForm.values.position,
                      "about-box__field-inp-touched":
                        formikForm.touched?.position &&
                        formikForm.errors?.position,
                    })}
                    {...formikForm.getFieldProps("position")}>
                    <option disabled hidden value="">
                      {t<string>("PROFILE.PLEASE_SELECT")}
                    </option>
                    <option value="0">
                      {t<string>("NEW_QUESTIONNAIRE.EMPLOYED")}
                    </option>
                    <option value="1">
                      {t<string>("NEW_QUESTIONNAIRE.CONTRACT")}
                    </option>
                    <option value="2">
                      {t<string>("NEW_QUESTIONNAIRE.SELF_EMPLOYED")}
                    </option>
                    <option value="3">
                      {t<string>("NEW_QUESTIONNAIRE.FREELANCE")}
                    </option>
                    <option value="4">
                      {t<string>("NEW_QUESTIONNAIRE.CIVIL")}
                    </option>
                  </select>
                </label>
              </div>
              {formikForm.touched?.position && formikForm.errors?.position && (
                <div className="form-control-error">
                  {formikForm.errors?.position}
                </div>
              )}
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.experience,
                      "about-box__field-inp-error":
                        formikForm.touched?.experience &&
                        formikForm.errors?.experience,
                    })}>
                    {t<string>("NEW_QUESTIONNAIRE.WORK_EXPERIENCE")}
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled":
                        formikForm.values.experience,
                      "about-box__field-inp-touched":
                        formikForm.touched?.experience &&
                        formikForm.errors?.experience,
                    })}
                    type="text"
                    value={formikForm.values.experience}
                    onChange={(event) => {
                      if (
                        +event.target.value <= 0 ||
                        Number.isNaN(+event.target.value)
                      ) {
                        formikForm.setFieldValue("experience", "");
                      } else {
                        formikForm.setFieldValue(
                          "experience",
                          +event.target.value
                        );
                      }
                    }}
                  />
                </label>
              </div>
              {formikForm.touched?.experience &&
                formikForm.errors?.experience && (
                  <div className="form-control-error">
                    {formikForm.errors?.experience}
                  </div>
                )}
            </div>
          )}
          {formikForm.values.job && (
            <div>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.monthly_income,
                      "about-box__field-inp-error":
                        formikForm.touched.monthly_income &&
                        formikForm.errors.monthly_income,
                    })}>
                    {t<string>("NEW_QUESTIONNAIRE.INCOME")}
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled":
                        formikForm.values.monthly_income,
                      "about-box__field-inp-touched":
                        formikForm.touched.monthly_income &&
                        formikForm.errors.monthly_income,
                    })}
                    placeholder="1000"
                    type="text"
                    value={formikForm.values.monthly_income}
                    onChange={(event) => {
                      if (
                        +event.target.value <= 0 ||
                        Number.isNaN(+event.target.value)
                      ) {
                        formikForm.setFieldValue("monthly_income", "");
                      } else {
                        formikForm.setFieldValue(
                          "monthly_income",
                          +event.target.value
                        );
                      }
                    }}
                  />
                </label>
              </div>
              {formikForm.touched.monthly_income &&
                formikForm.errors.monthly_income && (
                  <div className="form-control-error">
                    {formikForm.errors.monthly_income}
                  </div>
                )}
              {choosenData?.documents?.collection.length > 0 &&
                certs.length < 5 && (
                  <div
                    className="about__form-files"
                    onClick={() => {
                      dispatch(
                        setModalType(
                          AuthSelectionType.adminSelectUserCertificates
                        )
                      );
                    }}>
                    {t<string>("NEW_QUESTIONNAIRE.SELECT_FROM_DOCUMENTS")}
                  </div>
                )}
              <div className="about__form-box about-box">
                {certs.map((cert: any, index: number) => (
                  <div
                    className="about-box__file-block file-block  file-block-item"
                    key={cert.name}>
                    <div className="file-block-select__box">
                      <img
                        className="file-block__icon"
                        src={addedFile}
                        alt="file"
                      />
                      <span className="file-block__txt">
                        {cert.name} {t<string>("NEW_QUESTIONNAIRE.ADDED")}
                      </span>
                    </div>
                    <button
                      className="file-block__txt"
                      type="button"
                      onClick={() => {
                        deleteCert(cert);
                      }}>
                      X
                    </button>
                  </div>
                ))}

                {certs.length < 5 && (
                  <label className="about-box__file">
                    <div className="about-box__file-block file-block">
                      <img
                        className="file-block__icon"
                        src={fileIcon}
                        alt="file"
                      />
                      <span className="file-block__txt">
                        {t<string>("NEW_QUESTIONNAIRE.ADD_CERT")}
                      </span>
                    </div>

                    <input
                      type="file"
                      accept=".doc, .docx, .pdf, .xlx, .csv, .zip, .png, .jpg, .jpeg, .gif, .svg"
                      className="file-block__inp"
                      onChange={(event: any) => {
                        const same = certs.some(
                          (cert) => cert.name === event.target.files[0].name
                        );
                        if (event.target.files && !same) {
                          dispatch(setCerts([...certs, event.target.files[0]]));
                        } else {
                          toast.error("File already added");
                        }
                      }}
                    />
                  </label>
                )}
              </div>

              {certs.length === 0 && !addLater && (
                <div className="form-control-error">
                  {t<string>("NEW_QUESTIONNAIRE.LEAST_CERT")}
                </div>
              )}

              {certs.length === 0 && (
                <label className="about-box__egn">
                  <input
                    checked={addLater && certs.length === 0}
                    type="checkbox"
                    onChange={(event) => {
                      setAddLater(event.target.checked);
                    }}
                  />
                  {t<string>("PROFILE.LATER_CERT")}
                </label>
              )}
            </div>
          )}
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("NEW_QUESTIONNAIRE.EDUCATION")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.education,
                    "about-box__field-inp-error":
                      formikForm.touched.education &&
                      formikForm.errors.education,
                  })}>
                  {t<string>("NEW_QUESTIONNAIRE.DEGREE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.education,
                    "about-box__field-inp-touched":
                      formikForm.touched.education &&
                      formikForm.errors.education,
                  })}
                  {...formikForm.getFieldProps("education")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("NEW_QUESTIONNAIRE.UNIVERSITY")}
                  </option>
                  <option value="1">
                    {t<string>("NEW_QUESTIONNAIRE.HIGH_SCHOOL")}
                  </option>
                  <option value="2">
                    {t<string>("NEW_QUESTIONNAIRE.SECONDARY")}
                  </option>
                  <option value="3">
                    {t<string>("NEW_QUESTIONNAIRE.PRIMARY")}
                  </option>
                  <option value="4">
                    {t<string>("NEW_QUESTIONNAIRE.NO_COMPLETED")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.education && formikForm.errors.education && (
              <div className="form-control-error">
                {formikForm.errors.education}
              </div>
            )}
            <div className="about__form-box about-ask about-ask-bottom">
              <h4 className="about-ask__question">
                {t<string>("NEW_QUESTIONNAIRE.SIGNIFICANT_PERSON")}
              </h4>
              <div className="about-ask__variants">
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.politic === "1",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("politic", "1")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.YES")}
                </button>
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.politic === "0",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("politic", "0")}>
                  {t<string>("UPDATE_QUESTIONNAIRE.NO")}
                </button>
              </div>
            </div>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.type,
                  "about-box__field-inp-error":
                    formikForm.touched.type && formikForm.errors.type,
                })}>
                {t<string>("NEW_QUESTIONNAIRE.LOAN_TYPE")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.type,
                  "about-box__field-inp-touched":
                    formikForm.touched.type && formikForm.errors.type,
                })}
                value={formikForm.values.type}
                onChange={(e) => {
                  formikForm.setFieldValue("type", e.target.value);

                  if (e.target.value !== "4") {
                    formikForm.setFieldValue("amount_2", "");
                    formikForm.setFieldValue("amount_3", "");
                    formikForm.setFieldValue("term_2", "");
                    formikForm.setFieldValue("term_3", "");
                  }
                }}>
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="1">
                  {t<string>("NEW_QUESTIONNAIRE.CONSUMER_LOANS")}
                </option>
                <option value="7">
                  {t<string>("NEW_QUESTIONNAIRE.CREDIT_CARD")}
                </option>
                <option value="2">
                  {t<string>("NEW_QUESTIONNAIRE.CAR_LEASING")}
                </option>
                <option value="3">
                  {t<string>("NEW_QUESTIONNAIRE.REFINANCING")}
                </option>
                <option value="4">
                  {t<string>("NEW_QUESTIONNAIRE.MERGING_LOANS")}
                </option>
                <option value="5">
                  {t<string>("NEW_QUESTIONNAIRE.MORTGAGE_LOANS")}
                </option>
                <option value="6">
                  {t<string>("NEW_QUESTIONNAIRE.QUICK_LOAN")}
                </option>
              </select>
            </label>
          </div>
          {formikForm.touched.type && formikForm.errors.type && (
            <div className="form-control-error">{formikForm.errors.type}</div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.amount_1,
                  "about-box__field-inp-error":
                    formikForm.touched.amount_1 && formikForm.errors.amount_1,
                })}>
                {t<string>("NEW_QUESTIONNAIRE.AMOUNT")} 1
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.amount_1,
                  "about-box__field-inp-touched":
                    formikForm.touched.amount_1 && formikForm.errors.amount_1,
                })}
                placeholder={`${t<string>("NEW_QUESTIONNAIRE.AMOUNT")} 1`}
                {...formikForm.getFieldProps("amount_1")}
              />
            </label>
          </div>
          {formikForm.values.type === "4" && (
            <div>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.amount_2,
                      "about-box__field-inp-error":
                        formikForm.touched.amount_2 &&
                        formikForm.errors.amount_2,
                    })}>
                    {t<string>("NEW_QUESTIONNAIRE.AMOUNT")} 2
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.amount_2,
                      "about-box__field-inp-touched":
                        formikForm.touched.amount_2 &&
                        formikForm.errors.amount_2,
                    })}
                    placeholder={`${t<string>("NEW_QUESTIONNAIRE.AMOUNT")} 2`}
                    {...formikForm.getFieldProps("amount_2")}
                  />
                </label>
              </div>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.amount_3,
                      "about-box__field-inp-error":
                        formikForm.touched.amount_3 &&
                        formikForm.errors.amount_3,
                    })}>
                    {t<string>("NEW_QUESTIONNAIRE.AMOUNT")} 3
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.amount_3,
                      "about-box__field-inp-touched":
                        formikForm.touched.amount_3 &&
                        formikForm.errors.amount_3,
                    })}
                    placeholder={`${t<string>("NEW_QUESTIONNAIRE.AMOUNT")} 3`}
                    {...formikForm.getFieldProps("amount_3")}
                  />
                </label>
              </div>
            </div>
          )}

          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.term_1,
                  "about-box__field-inp-error":
                    formikForm.touched.term_1 && formikForm.errors.term_1,
                })}>
                {t<string>("NEW_QUESTIONNAIRE.TERM")} 1
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.term_1,
                  "about-box__field-inp-touched":
                    formikForm.touched.term_1 && formikForm.errors.term_1,
                })}
                placeholder={`${t<string>("NEW_QUESTIONNAIRE.TERM")} 1`}
                {...formikForm.getFieldProps("term_1")}
              />
            </label>
          </div>
          {formikForm.values.type === "4" && (
            <>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": formikForm.values.term_2,
                      "about-box__field-inp-error":
                        formikForm.touched.term_2 && formikForm.errors.term_2,
                    })}>
                    {t<string>("NEW_QUESTIONNAIRE.TERM")} 2
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.term_2,
                      "about-box__field-inp-touched":
                        formikForm.touched.term_2 && formikForm.errors.term_2,
                    })}
                    placeholder={`${t<string>("NEW_QUESTIONNAIRE.TERM")} 2`}
                    {...formikForm.getFieldProps("term_2")}
                  />
                </label>
              </div>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": formikForm.values.term_3,
                      "about-box__field-inp-error":
                        formikForm.touched.term_3 && formikForm.errors.term_3,
                    })}>
                    {t<string>("NEW_QUESTIONNAIRE.TERM")} 3
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.term_3,
                      "about-box__field-inp-touched":
                        formikForm.touched.term_3 && formikForm.errors.term_3,
                    })}
                    placeholder={`${t<string>("NEW_QUESTIONNAIRE.TERM")} 3`}
                    {...formikForm.getFieldProps("term_3")}
                  />
                </label>
              </div>
            </>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.bank_1,
                  "about-box__field-inp-error":
                    formikForm.touched.bank_1 && formikForm.errors.bank_1,
                })}>
                {t<string>("NEW_QUESTIONNAIRE.BANK")} 1
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.bank_1,
                  "about-box__field-inp-touched":
                    formikForm.touched.bank_1 && formikForm.errors.bank_1,
                })}
                placeholder={`${t<string>("NEW_QUESTIONNAIRE.BANK")} 1`}
                {...formikForm.getFieldProps("bank_1")}
              />
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.bank_2,
                  "about-box__field-inp-error":
                    formikForm.touched.bank_2 && formikForm.errors.bank_2,
                })}>
                {t<string>("NEW_QUESTIONNAIRE.AMOUNT")} 2
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.bank_2,
                  "about-box__field-inp-touched":
                    formikForm.touched.bank_2 && formikForm.errors.bank_2,
                })}
                placeholder={`${t<string>("NEW_QUESTIONNAIRE.BANK")} 2`}
                {...formikForm.getFieldProps("bank_2")}
              />
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.bank_3,
                  "about-box__field-inp-error":
                    formikForm.touched.bank_3 && formikForm.errors.bank_3,
                })}>
                {t<string>("NEW_QUESTIONNAIRE.AMOUNT")} 3
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.bank_3,
                  "about-box__field-inp-touched":
                    formikForm.touched.bank_3 && formikForm.errors.bank_3,
                })}
                placeholder={`${t<string>("NEW_QUESTIONNAIRE.BANK")} 3`}
                {...formikForm.getFieldProps("bank_3")}
              />
            </label>
          </div>

          <div className="about__form-box about-ask">
            <h4 className="about-ask__question">
              {t<string>("NEW_QUESTIONNAIRE.SELECT_STATUS")}
            </h4>
            <label className="about-box__field about__form-box about-box">
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.status,
                  "about-box__field-inp-touched":
                    formikForm.touched.status && formikForm.errors.status,
                })}
                value={formikForm.values.status}
                onChange={(e) => {
                  formikForm.setFieldValue("status", e.target.value);
                }}>
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="approved">
                  {t<string>("NEW_QUESTIONNAIRE.APPROVED")}
                </option>
                <option value="pending">
                  {t<string>("NEW_QUESTIONNAIRE.PENDING")}
                </option>
                <option value="declined">
                  {t<string>("NEW_QUESTIONNAIRE.DECLINED")}
                </option>
              </select>
            </label>
          </div>
          <button className="about__submit-active" type="submit">
            {t<string>("NEW_QUESTIONNAIRE.ADD_QUEST_BTN")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewQuestionnaireModal;
