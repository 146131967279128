import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./core/store";
import { banners } from "./core/constants/banners-list";

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);

let aNumber;
const isAuthorized = sessionStorage.getItem("isAuthorized") === "true";
if (banners.includes(window.location.pathname)) {
  aNumber = 4444;
}

if (
  window.location.pathname.split("/").includes("reset_password") ||
  window.location.pathname.split("/").includes("forgot-password")
) {
  localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
  localStorage.removeItem("savedUser");
  sessionStorage.removeItem("questionnaire");
}

if (!banners.includes(window.location.pathname)) {
  if (!isAuthorized) {
    aNumber = Number(window.prompt("Enter password", ""));
  }
  if (aNumber === 4444) {
    sessionStorage.setItem("isAuthorized", JSON.stringify(true));
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (isAuthorized || aNumber === 4444) {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

reportWebVitals();
