import { FC } from "react";

import CalculatorTabs from "./Tabs";

const CalculatorContainer: FC = () => {
  return (
    <div>
      <CalculatorTabs />
    </div>
  );
};

export default CalculatorContainer;
