import { Partner } from '../models'

export const clientsData: Partner.Client[] = [
  {
    id: 28102,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 685,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28103,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 180,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28104,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 210,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28105,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 212,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28202,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 685,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28203,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 180,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28204,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 210,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28205,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 212,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28302,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 685,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28303,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 180,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28304,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 210,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28305,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 212,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28306,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 685,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28307,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 180,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28308,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 210,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28309,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 212,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28310,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 685,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28311,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 180,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28312,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 210,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28313,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 212,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'New',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28314,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 800,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28315,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 680,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28316,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 210,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28317,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 212,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Counter offer',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28318,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 579,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28319,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 784,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28320,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 910,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Counter offer',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28321,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28322,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 790,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28323,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 310,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28324,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 600,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28325,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 790,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28326,
    checked: false,
    client: 'Georgiev Mikhail Ivanovich',
    credit_score: 910,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Counter offer',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28327,
    checked: false,
    client: 'Gorbacheva Marina Meshkova',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28328,
    checked: false,
    client: 'Ushakov Viktor Ivanovich',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Counter offer',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28329,
    checked: false,
    client: 'Shcherbakova Anna Alekseevna',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Confirmation',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28330,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 800,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28331,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 990,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28332,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 800,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28333,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 612,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'No',
    date: '21:39',
  },
  {
    id: 28334,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 879,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28335,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 984,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28336,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 910,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28337,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28338,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 790,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'Signed loan',
    signature: 'No',
    date: '21:39',
  },
  {
    id: 28339,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 810,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'Signed loan',
    signature: 'No',
    date: '21:39',
  },
  {
    id: 28340,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 600,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28341,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 990,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28342,
    checked: false,
    client: 'Georgiev Mikhail Ivanovich',
    credit_score: 949,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28343,
    checked: false,
    client: 'Gorbacheva Marina Meshkova',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28344,
    checked: false,
    client: 'Ushakov Viktor Ivanovich',
    credit_score: 781,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'Yes',
    date: '21:39',
  },
  {
    id: 28345,
    checked: false,
    client: 'Shcherbakova Anna Alekseevna',
    credit_score: 800,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Signed loan',
    signature: 'No',
    date: '21:39',
  },
  {
    id: 28346,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 800,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28347,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 680,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28348,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 210,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28349,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 212,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28350,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 579,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'Declined by client',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28351,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 784,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28352,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 910,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Declined by client',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28353,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28354,
    checked: false,
    client: 'Ivanova Maritsa Radneva',
    credit_score: 790,
    product_id: 395205,
    loan_type: 'Green loan',
    sum: 400000,
    term: 6,
    rate: 4.1,
    status: 'Declined by client',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28355,
    checked: false,
    client: 'Ignatova Anna Ivanovna',
    credit_score: 310,
    product_id: 545934,
    loan_type: 'Household Mortgage',
    sum: 200000,
    term: 12,
    rate: 4.1,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28356,
    checked: false,
    client: 'Kutuzov Ivan Viktorovich',
    credit_score: 600,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Declined by client',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28357,
    checked: false,
    client: 'Anisimov Urban Andreevich',
    credit_score: 790,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28358,
    checked: false,
    client: 'Georgiev Mikhail Ivanovich',
    credit_score: 910,
    product_id: 439343,
    loan_type: 'Red Credit Card',
    sum: 100000000,
    term: 18,
    rate: 6.6,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28359,
    checked: false,
    client: 'Gorbacheva Marina Meshkova',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Declined by client',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28360,
    checked: false,
    client: 'Ushakov Viktor Ivanovich',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Declined',
    signature: '-',
    date: '21:39',
  },
  {
    id: 28361,
    checked: false,
    client: 'Shcherbakova Anna Alekseevna',
    credit_score: 776,
    product_id: 839342,
    loan_type: 'Insurance',
    sum: 1100500,
    term: 18,
    rate: 6.6,
    status: 'Declined by client',
    signature: '-',
    date: '21:39',
  },
]
