import adv_personal from "../../assets/photos/all/01.svg";
import adv_unique from "../../assets/photos/all/02.svg";
import adv_partnership from "../../assets/photos/all/03.svg";
import adv_team from "../../assets/photos/all/04.svg";

export const advantages_list = [
  {
    id: 1,
    title: "PERSONAL_APPROACH",
    content: "PERSONAL_APPROACH_DESC",
    photo: adv_personal,
  },
  {
    id: 2,
    title: "UNIQUE",
    content: "UNIQUE_DESC",
    photo: adv_unique,
  },
  {
    id: 3,
    title: "PARTNERSHIP",
    content: "PARTNERSHIP_DESC",
    photo: adv_partnership,
  },
  {
    id: 4,
    title: "PROFESSIONALS",
    content: "PROFESSIONALS_DESC",
    photo: adv_team,
  },
];
