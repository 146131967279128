import React, { useState } from "react";

import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { useAppSelector } from "../../../../core/store";
import { useTranslation } from "react-i18next";

const ShowCreditOfferContent = () => {
  const [content, setContent] = useState<any>({});

  const { t } = useTranslation();
  const { selectedId, choosenData } = useAppSelector((state) => state.app);

  useHttpGet<any>(`${APIRoutes.ADMIN_ENTITIES_CREDIT_OFFERS}/${selectedId}`, {
    dependencies: [selectedId, choosenData],

    resolve: (response) => {
      if (response.payload) {
        setContent(response.payload);
      }
    },
  });

  return (
    <div className="modal__container  modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {choosenData === "name"
            ? `${t<string>("FINANCIAL_INSTITUTIONS.NAME_BG")}`
            : `${t<string>("FINANCIAL_INSTITUTIONS.DESCRIPTION_BG")}`}
          :
        </p>
        <span className="users-table-row__item">
          {choosenData === "name"
            ? content?.translations?.bg?.name
            : content?.translations?.bg?.description}
        </span>
        <p
          style={{
            marginTop: "30px",
          }}
          className="modal-logout__box-title"
        >
          {choosenData === "name"
            ? `${t<string>("FINANCIAL_INSTITUTIONS.NAME_EN")}`
            : `${t<string>("FINANCIAL_INSTITUTIONS.DESCRIPTION_EN")}`}
          :
        </p>
        <span className="users-table-row__item">
          {choosenData === "name"
            ? content?.translations?.en?.name
            : content?.translations?.en?.description}
        </span>
      </div>
    </div>
  );
};

export default ShowCreditOfferContent;
