import React, { useContext } from "react";
import { AppContext } from "../../../../App";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

const ShowAllCommentsModal = () => {
  const appContext = useContext(AppContext);
  const { allComments } = appContext;
  const { t } = useTranslation();

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box admin-reviews-comment">
        {allComments.length === 0 ? (
          <p>{t<string>("BANK_COMMENTS.NO_COMMENTS")}</p>
        ) : (
          <div className="admin-all-comments">
            {allComments.map((comment: any) => (
              <div className="admin-all-comments__item">
                <h3 className="admin-all-comments__item-title">
                  {t<string>("BANK_COMMENTS.ID")}: {comment.user_id} |{" "}
                  {t<string>("BANK_COMMENTS.USER")}: {comment.user_name}
                </h3>
                <p className="admin-all-comments__item-comment">
                  {comment.comment}
                </p>
                <span className="admin-all-comments__item-status">
                  {t<string>("BANK_COMMENTS.STATUS")}:{" "}
                  <span
                    className={classNames({
                      "admin-all-comments__item-status-approved":
                        comment.status === "approved",
                      "admin-all-comments__item-status-declined":
                        comment.status === "declined",
                      "admin-all-comments__item-status-pending":
                        comment.status === "pending",
                    })}>
                    {t<string>(`BANK_COMMENTS.${comment.status.toUpperCase()}`)}
                  </span>
                </span>
                <div className="admin-all-comments__item-time all-comments__time">
                  <div className="all-comments__time-date">
                    {t<string>("BANK_COMMENTS.CREATED")}
                    <div className="all-comments__time-date-numbers">
                      <span>
                        {`${new Date(comment.created_at).getDate()}-${
                          new Date(comment.created_at).getMonth() + 1
                        }-${new Date(comment.created_at).getFullYear()}`}
                      </span>
                      {t<string>("BANK_COMMENTS.AT")}
                      <span>
                        {`${new Date(comment.created_at).toLocaleTimeString()}`}
                      </span>
                    </div>
                  </div>
                  <div className="all-comments__time-date">
                    {t<string>("BANK_COMMENTS.UPDATED")}
                    <div className="all-comments__time-date-numbers">
                      <span>
                        {`${new Date(comment.updated_at).getDate()}-${
                          new Date(comment.updated_at).getMonth() + 1
                        }-${new Date(comment.updated_at).getFullYear()}`}
                      </span>
                      {t<string>("BANK_COMMENTS.AT")}
                      <span>{`${new Date(
                        comment.updated_at
                      ).toLocaleTimeString()}`}</span>
                    </div>
                  </div>
                </div>
                <div className="chapter-selector__line"></div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowAllCommentsModal;
