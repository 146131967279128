import React from "react";

import ConsumerInfo from "./ConsumerInfo";
import CreditsConsumerBanner from "./CreditsConsumerBanner";

const CreditsConsumer = () => {
  return (
    <div id="Consumer loans" className="credits-consumer">
      <CreditsConsumerBanner />
      <div className="credits-container-main-content-consumer">
        <ConsumerInfo />
      </div>
    </div>
  );
};

export default CreditsConsumer;
