import appDataSlice from "./app/appDataSlice";
import authSlice from "./auth/authSlice";
import documentReducer from "./documents/documentsSlice";
import modalSlice from "./modal/modalSlice";
import adminSlice from './admin/adminDataSlice';

export default Object.assign(
  {},
  {
    auth: authSlice,
    app: appDataSlice,
    document: documentReducer,
    modal: modalSlice,
    admin: adminSlice
  }
);
