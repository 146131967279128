import React, { FC } from "react";

import ResultsForm from "./ResultsForm";

const ResultsContainer: FC = () => {
  return (
    <div className="results-form-container">
      <ResultsForm />
    </div>
  );
};

export default ResultsContainer;
