import { Suspense, useEffect }from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames';
import Image from "../../assets/photos/all/Illustration-dev.svg"
import { useAppDispatch, useAppSelector } from '../../core/store';
import { setAppLanguage } from '../../core/store/reducers/app/appDataSlice';
import divider from "../../assets/photos/all/lang-divider.svg";
import { useTranslation } from 'react-i18next';
const Underconstruction = () => {
  const dispatch = useAppDispatch();
  const { appLanguage, notifications } = useAppSelector((state: { app: any }) => state.app);
  const { t, i18n } = useTranslation();
  const setLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    sessionStorage.setItem("lng", lng);
    dispatch(setAppLanguage(lng));
    
  };

  useEffect(() => {
    let lng = sessionStorage.getItem("lng");
    setLanguage("bg")
    dispatch(setAppLanguage(lng || "bg"));
  }, []);

  return (
    <Suspense fallback={<h5>...Loading</h5>}>
      <div className="construction">
        <header className="construction__header">
          <Link to={`/`}>
            <img
              className="construction__header-logo"
              src={require(`../../${process.env.REACT_APP_LOGO_NAME}`)}
              alt="assistent logo"
            />
          </Link>
          <div className="nav__options-lang lang">
                <button
                  className={classNames({
                    lang__btn: true,
                    "lang__btn-selected": appLanguage === "bg",
                  })}
                  onClick={() => setLanguage("bg")}
                >
                  {t<string>("COMMON.BG")}
                </button>
                <img className="lang__line" src={divider} alt="divider"></img>
                <button
                  className={classNames({
                    lang__btn: true,
                    "lang__btn-selected": appLanguage === "en",
                  })}
                  onClick={() => setLanguage("en")}
                >
                  {t<string>("COMMON.EN")}
                </button>
              </div>
        </header>
        <main className="construction__main">
          <div className="construction__main-img">
            <img src={Image} alt="logo" />
          </div>
          <h3 className="construction__main-title">{t<string>("COMMON.CONSTRUCTION_TITLE")}</h3>
          <p className="construction__main-descr">{t<string>("COMMON.CONSTRUCTION_DESCR")}</p>
        </main>
        <footer className="construction__footer">
          <span>© Asistent 2022</span>
          <span>All rights reserved</span>
        </footer>
      </div>
    </Suspense>
    
  )
}

export default Underconstruction