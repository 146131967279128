import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { AppContext } from "../../../../App";

import PrivateService from "../../../../core/services/private.service";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";

import fileIcon from "../../../../assets/photos/all/add-file.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../core/store";
import { setModal } from "../../../../core/store/reducers/modal/modalSlice";

const UpdateCategory = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<any>({});
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState<any>();
  const [avatarChecker, setAvatarChecker] = useState<string>("");

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedId } = useAppSelector((state) => state.app);

  const appContext = useContext(AppContext);
  const { setReloadChecker, reloadChecker } = appContext;

  useHttpGet<any>(`${APIRoutes.ADMIN_PUBLIC}/blog/categories/${selectedId}`, {
    dependencies: [reloadChecker, selectedId],

    resolve: (response) => {
      setCategory(response.payload);
    },
  });

  useEffect(() => {
    formikForm.resetForm();

    if (category) {
      for (let prop in category) {
        category[prop]
          ? formikForm.setFieldValue(prop, category[prop])
          : formikForm.setFieldValue(prop, "");
      }

      setAvatarChecker(category["image_path"]);

      if (category["translations"] !== undefined) {
        category["translations"].bg.meta_description
          ? formikForm.setFieldValue(
              "meta_description:bg",
              category["translations"].bg.meta_description
            )
          : formikForm.setFieldValue("meta_description:bg", "");

        category["translations"].en.meta_description
          ? formikForm.setFieldValue(
              "meta_description:en",
              category["translations"].en.meta_description
            )
          : formikForm.setFieldValue("meta_description:en", "");

        category["translations"].bg.meta_title
          ? formikForm.setFieldValue(
              "meta_title:bg",
              category["translations"].bg.meta_title
            )
          : formikForm.setFieldValue("meta_title:bg", "");

        category["translations"].en.meta_title
          ? formikForm.setFieldValue(
              "meta_title:en",
              category["translations"].en.meta_title
            )
          : formikForm.setFieldValue("meta_title:en", "");

        category["translations"].bg.meta_keywords
          ? formikForm.setFieldValue(
              "meta_keywords:bg",
              category["translations"].bg.meta_keywords
            )
          : formikForm.setFieldValue("meta_keywords:bg", "");

        category["translations"].en.meta_keywords
          ? formikForm.setFieldValue(
              "meta_keywords:en",
              category["translations"].en.meta_keywords
            )
          : formikForm.setFieldValue("meta_keywords:en", "");

        category["translations"].bg.content
          ? formikForm.setFieldValue(
              "content:bg",
              category["translations"].bg.content
            )
          : formikForm.setFieldValue("content:bg", "");

        category["translations"].en.content
          ? formikForm.setFieldValue(
              "content:en",
              category["translations"].en.content
            )
          : formikForm.setFieldValue("content:en", "");

        category["translations"].bg.name
          ? formikForm.setFieldValue(
              "name:bg",
              category["translations"].bg.name
            )
          : formikForm.setFieldValue("name:bg", "");

        category["translations"].en.name
          ? formikForm.setFieldValue(
              "name:en",
              category["translations"].en.name
            )
          : formikForm.setFieldValue("name:en", "");
      }
    }
  }, [category]);

  const valueFormValidationSchema = Yup.object().shape({
    status: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    "meta_description:en": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(160, "Too many characters"),
    "meta_keywords:en": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(255, "Too many characters"),
    "meta_title:en": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(60, "Too many characters"),
    "content:en": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(360, "Too many characters"),
    "name:en": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(120, "Too many characters"),
    "meta_description:bg": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(160, "Too many characters"),
    "meta_keywords:bg": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(255, "Too many characters"),
    "meta_title:bg": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(60, "Too many characters"),
    "content:bg": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(360, "Too many characters"),
    "name:bg": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(120, "Too many characters"),
  });

  const formikForm = useFormik<{
    id: number;
    image: any;
    show_on_public: string | number;
    status: string;
    image_path: string;
    image_name: string;
    "meta_description:en": string;
    "meta_keywords:en": string;
    "meta_title:en": string;
    "content:en": string;
    "name:en": string;
    "meta_description:bg": string;
    "meta_keywords:bg": string;
    "meta_title:bg": string;
    "content:bg": string;
    "name:bg": string;
  }>({
    initialValues: {
      id: 0,
      image: "",
      show_on_public: "",
      status: "",
      image_name: "",
      image_path: "",
      "meta_description:en": "",
      "meta_keywords:en": "",
      "meta_title:en": "",
      "content:en": "",
      "name:en": "",
      "meta_description:bg": "",
      "meta_keywords:bg": "",
      "meta_title:bg": "",
      "content:bg": "",
      "name:bg": "",
    },

    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const pictureLoader = (e: any) => {
    setCropper(undefined);

    e.preventDefault();

    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result as any);
    };

    reader.readAsDataURL(files[0]);
  };

  const urltoFile = (url: string, filename: string, mimeType: string) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      formikForm.setFieldValue(
        "image_path",
        cropper.getCroppedCanvas().toDataURL()
      );

      setImage(undefined);
    }
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    let image;

    if (values.image_path !== avatarChecker) {
      image = await urltoFile(
        values.image_path,
        `photo${Math.random() * 10000}.png`,
        "image/png"
      );
    } else {
      image = "";
    }

    try {
      const response = await PrivateService.updateBlogCategory(
        {
          ...values,
          image,
        },
        category.id
      );

      setReloadChecker(!reloadChecker);
      setLoading(false);
      toast.success(response?.data?.message);
      dispatch(setModal(false));
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
      setLoading(false);
      dispatch(setModal(true));
    }
  };
  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("BLOG_TABLE.UPDATE_CATEGORY_TITLE")}
        </p>
        <form className="about" onSubmit={formikForm.handleSubmit}>
          <div className="about__form-box about-box">
            {image ? (
              <>
                {image && (
                  <div className="about-box__cropper">
                    <Cropper
                      style={{
                        height: 250,
                        width: 250,
                      }}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={image}
                      viewMode={1}
                      minCropBoxHeight={200}
                      minCropBoxWidth={200}
                      cropBoxResizable={false}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                      guides={true}
                    />
                    <button
                      className="about-box__cropper-btn"
                      onClick={getCropData}
                    >
                      {t<string>("BLOG_TABLE.CROP")}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                {formikForm.values.image || formikForm.values.image_name ? (
                  <div className="about-box__avatar">
                    <div className="about-box__avatar-bank">
                      <img
                        className="about-box__avatar-bank-own"
                        src={
                          formikForm.values.image ||
                          formikForm.values.image_path
                        }
                        alt="avatar"
                      />
                    </div>
                    <label className="about-box__file">
                      <div className="about-box__file-block file-block">
                        <img
                          className="file-block__icon"
                          src={fileIcon}
                          alt="file"
                        />
                        <span className="file-block__txt">
                          {t<string>("BLOG_TABLE.CHANGE_IMAGE")}
                        </span>
                      </div>
                      <input
                        className="file-block__inp"
                        type="file"
                        onChange={(e) => {
                          if (e.target.files) {
                            pictureLoader(e);
                          }
                        }}
                        accept="image/jpg, image/jpeg, image/png, image/gif"
                      />
                    </label>
                  </div>
                ) : (
                  <label className="about-box__file">
                    <div className="about-box__file-block file-block">
                      <img
                        className="file-block__icon"
                        src={fileIcon}
                        alt="file"
                      />
                      <span className="file-block__txt">
                        {t<string>("BLOG_TABLE.ADD_IMAGE")}
                      </span>
                    </div>
                    <input
                      className="file-block__inp"
                      type="file"
                      onChange={(e) => {
                        if (e.target.files) {
                          pictureLoader(e);
                        }
                      }}
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                    />
                  </label>
                )}
              </>
            )}
          </div>
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["name:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["name:en"] &&
                      formikForm.errors["name:en"],
                  })}
                >
                  {t<string>("BLOG_TABLE.NAME_EN")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["name:en"] &&
                      formikForm.errors["name:en"],
                    "about-box__field-inp-filled": formikForm.values["name:en"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.NAME_EN")}
                  {...formikForm.getFieldProps(`${["name:en"]}`)}
                />
              </label>
            </div>

            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["name:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["name:bg"] &&
                      formikForm.errors["name:bg"],
                  })}
                >
                  {t<string>("BLOG_TABLE.NAME_BG")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["name:bg"] &&
                      formikForm.errors["name:en"],
                    "about-box__field-inp-filled": formikForm.values["name:bg"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.NAME_BG")}
                  {...formikForm.getFieldProps(`${["name:bg"]}`)}
                />
              </label>
            </div>

            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["meta_title:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["meta_title:en"] &&
                      formikForm.errors["meta_title:en"],
                  })}
                >
                  {t<string>("BLOG_TABLE.TITLE_EN")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["meta_title:en"] &&
                      formikForm.errors["meta_title:en"],
                    "about-box__field-inp-filled":
                      formikForm.values["meta_title:en"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.TITLE_EN")}
                  {...formikForm.getFieldProps(`${["meta_title:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["meta_title:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["meta_title:bg"] &&
                      formikForm.errors["meta_title:bg"],
                  })}
                >
                  {t<string>("BLOG_TABLE.TITLE_BG")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["meta_title:bg"] &&
                      formikForm.errors["meta_title:bg"],
                    "about-box__field-inp-filled":
                      formikForm.values["meta_title:bg"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.TITLE_BG")}
                  {...formikForm.getFieldProps(`${["meta_title:bg"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["meta_description:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["meta_description:en"] &&
                      formikForm.errors["meta_description:en"],
                  })}
                >
                  {t<string>("BLOG_TABLE.DESCRIPTION_EN")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["meta_description:en"] &&
                      formikForm.errors["meta_description:en"],
                    "about-box__field-inp-filled":
                      formikForm.values["meta_description:en"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.DESCRIPTION_EN")}
                  {...formikForm.getFieldProps(`${["meta_description:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["meta_description:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["meta_description:bg"] &&
                      formikForm.errors["meta_description:bg"],
                  })}
                >
                  {t<string>("BLOG_TABLE.DESCRIPTION_BG")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["meta_description:bg"] &&
                      formikForm.errors["meta_description:bg"],
                    "about-box__field-inp-filled":
                      formikForm.values["meta_description:bg"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.DESCRIPTION_BG")}
                  {...formikForm.getFieldProps(`${["meta_description:bg"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["meta_keywords:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["meta_keywords:en"] &&
                      formikForm.errors["meta_keywords:en"],
                  })}
                >
                  {t<string>("BLOG_TABLE.META_KEYWORDS_EN")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["meta_keywords:en"] &&
                      formikForm.errors["meta_keywords:en"],
                    "about-box__field-inp-filled":
                      formikForm.values["meta_keywords:en"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.META_KEYWORDS_EN")}
                  {...formikForm.getFieldProps(`${["meta_keywords:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["meta_keywords:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["meta_keywords:bg"] &&
                      formikForm.errors["meta_keywords:bg"],
                  })}
                >
                  {t<string>("BLOG_TABLE.META_KEYWORDS_BG")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["meta_keywords:bg"] &&
                      formikForm.errors["meta_keywords:bg"],
                    "about-box__field-inp-filled":
                      formikForm.values["meta_keywords:bg"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.META_KEYWORDS_BG")}
                  {...formikForm.getFieldProps(`${["meta_keywords:bg"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["content:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["content:en"] &&
                      formikForm.errors["content:en"],
                  })}
                >
                  {t<string>("BLOG_TABLE.CONTENT_EN")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["content:en"] &&
                      formikForm.errors["content:en"],
                    "about-box__field-inp-filled":
                      formikForm.values["content:en"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.CONTENT_EN")}
                  {...formikForm.getFieldProps(`${["content:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["content:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["content:bg"] &&
                      formikForm.errors["content:bg"],
                  })}
                >
                  {t<string>("BLOG_TABLE.CONTENT_BG")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["content:bg"] &&
                      formikForm.errors["content:bg"],
                    "about-box__field-inp-filled":
                      formikForm.values["content:bg"],
                  })}
                  placeholder={t<string>("BLOG_TABLE.META_KEYWORDS_BG")}
                  {...formikForm.getFieldProps(`${["content:bg"]}`)}
                />
              </label>
            </div>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.show_on_public &&
                    formikForm.errors.show_on_public,
                })}
              >
                {t<string>("BLOG_TABLE.SHOW_ON_PUBLIC")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled":
                    formikForm.values.show_on_public,
                  "about-box__field-inp-touched":
                    formikForm.touched.show_on_public &&
                    formikForm.errors.show_on_public,
                })}
                {...formikForm.getFieldProps("show_on_public")}
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="1">{t<string>("BLOG_TABLE.YES")}</option>
                <option value="0">{t<string>("BLOG_TABLE.NO")}</option>
              </select>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.status && formikForm.errors.status,
                })}
              >
                {t<string>("BLOG_TABLE.CHOOSE_STATUS")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.status,
                  "about-box__field-inp-touched":
                    formikForm.touched.status && formikForm.errors.status,
                })}
                value={formikForm.values.status}
                onChange={(e) =>
                  formikForm.setFieldValue("status", e.target.value)
                }
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="enabled">
                  {t<string>("BLOG_TABLE.ENABLED")}
                </option>
                <option value="disabled">
                  {t<string>("BLOG_TABLE.DISABLED")}
                </option>
              </select>
            </label>
          </div>
          <button
            className={classNames({
              about__submit: true,
              "about__submit-active": formikForm.isValid,
            })}
            type="submit"
          >
            {t<string>("BLOG_TABLE.SAVE_BUTTON")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateCategory;
