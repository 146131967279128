import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { toast } from "react-toastify";

import { useAppSelector } from "../../../../core/store";
import { useTranslation } from "react-i18next";

import PrivateService from "../../../../core/services/private.service";
import CommonService from "../../../../core/services/common.service";

const OtherInfo = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { questionnaire} = useAppSelector((state) => state.app);
  const savedUser = JSON.parse(localStorage.savedUser)
  

  useEffect(() => {
    if (questionnaire) {
      const newUser = JSON.parse(JSON.stringify(questionnaire));
      console.log(newUser)
      for (var property in newUser) {
        if (!newUser[property] && newUser[property] !== 0) {
          newUser[property] = "";
        } else {
          formikForm.setFieldValue(property, String(newUser[property]));
        }
      }
    }
  }, [questionnaire]);

  const valueFormValidationSchema = Yup.object().shape({
    loan_sum: Yup.number().when("debts", {
      is: (val: string) => val && val === "1",
      then: Yup.number()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .min(250, t<string>("PROFILE.MIN_LOAN_SUM")),
    }),
    insolvency: Yup.string().required(),
  });

  const formikForm = useFormik<{
    debts: string | number;
    loan_sum: string | number;
    insolvency: string;
  }>({
    initialValues: {
      debts: "",
      loan_sum: "",
      insolvency: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    try {
      delete values.avatar;
      delete values.agree;
      delete values.informed;
      const response = await PrivateService.updateUserQuestionnaire({
        ...values,
      }, questionnaire.id);

      setLoading(false);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      setLoading(false);
      toast.error(errors?.response?.data?.message);
      CommonService.showErrors(errors?.response?.data?.payload);
    }
  };

  return (
    <div className="edit-info">
      <form className="about" onSubmit={formikForm.handleSubmit}>
        <div className="about__form">
          <h2 className="about__title about__title-fourth">
            {t<string>("PROFILE.CURRENT_LOANS")}
          </h2>
          <div className="about__form-box about-ask">
            <h4 className="about-ask__question">
              {t<string>("PROFILE.ANY_LOANS")}
            </h4>
            <div className="about__form">
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <select
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-select": true,
                      "about-box__field-inp-filled": formikForm.values.debts,
                      "about-box__field-inp-touched":
                        formikForm.touched.debts && formikForm.errors.debts,
                    })}
                    value={formikForm.values.debts}
                    onChange={(e) => {
                      if (e.target.value === "0") {
                        formikForm.setFieldValue("loan_sum", "");
                      }
                      formikForm.setFieldValue("debts", e.target.value);
                    }}>
                    <option disabled hidden value="">
                      {t<string>("PROFILE.PLEASE_SELECT")}
                    </option>
                    <option value="1">{t<string>("COMMON.YES")}</option>
                    <option value="0">{t<string>("COMMON.NO")}</option>
                  </select>
                </label>
              </div>
              {formikForm.touched.debts && formikForm.errors.debts && (
                <div className="form-control-error">
                  {formikForm.errors.debts}
                </div>
              )}
            </div>
          </div>
        </div>

        {formikForm.values.debts !== "0" && formikForm.values.debts !== "" && (
          <div className="about__form">
            <label
              className="about__form-range about-range"
              htmlFor="family-no">
              <span
                className={classNames({
                  "about-range__name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.loan_sum && formikForm.errors.loan_sum,
                })}>
                {t<string>("PROFILE.LOANS_PER_MONTH")}
              </span>
              <div className="about-range__gen about-range__loan-sum">
                <label className="about-range__gen-field">
                  <input
                    type="text"
                    className="about-range__gen-field-put"
                    placeholder="1000"
                    value={formikForm.values.loan_sum}
                    onChange={(event) => {
                      let min = 500;
                      let max = 999999999;

                      if (
                        +event.target.value <= 0 ||
                        Number.isNaN(+event.target.value)
                      ) {
                        formikForm.setFieldValue("loan_sum", "");
                      } else {
                        if (
                          (event.target.value.length === 3 ||
                            event.target.value.length < 3) &&
                          +event.target.value < min
                        ) {
                          formikForm.setFieldValue("loan_sum", min);
                        } else if (
                          (event.target.value.length === 6 ||
                            event.target.value.length > 6) &&
                          +event.target.value > max
                        ) {
                          formikForm.setFieldValue("loan_sum", max);
                        } else {
                          formikForm.setFieldValue(
                            "loan_sum",
                            +event.target.value
                          );
                        }
                      }
                    }}
                  />
                  {t<string>("PROFILE.BGN")}
                </label>
                {formikForm.touched.loan_sum && formikForm.errors.loan_sum && (
                  <div className="form-control-error">
                    {formikForm.errors.loan_sum}
                  </div>
                )}
              </div>
            </label>
          </div>
        )}

        <div className="about__form">
          <div className="about__form-box about-ask">
            <h4 className="about-ask__question">
              {t<string>("PROFILE.INSOLVENCY")}
            </h4>
            <div className="about-ask__variants">
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.insolvency === "1",
                })}
                type="button"
                onClick={() => formikForm.setFieldValue("insolvency", "1")}>
                <div className="var-block__click">
                  {t<string>("COMMON.YES")}
                </div>
              </button>
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.insolvency === "0",
                })}
                type="button"
                onClick={() => formikForm.setFieldValue("insolvency", "0")}>
                <div className="var-block__click">{t<string>("COMMON.NO")}</div>
              </button>
            </div>
          </div>
        </div>
        <button
          className={classNames({
            about__submit: true,
            "about__submit-active": formikForm.isValid && formikForm.dirty,
          })}
          type="submit">
          {t<string>("PROFILE.SAVE_CHANGES")}
        </button>
      </form>
    </div>
  );
};

export default OtherInfo;
