import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import classNames from "classnames";

import { useAppDispatch, useAppSelector } from "../../../core/store";
import { currencyFormat } from "../../../core/functions/funtions";
import { style } from "../../../core/constants/style";
import { setCalculator } from "../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";


const MortgageLoansForm = () => {
  const dispatch = useAppDispatch();
  const calculator = useAppSelector((state) => state.app.calculator);

  const resultCalc = JSON.parse(localStorage.getItem("calcResult") || "{}");
  const { t } = useTranslation();
  useEffect(() => {
    if (calculator) {
      formikForm.setFieldValue("amount_1", +resultCalc.amount_1);
      formikForm.setFieldValue("term_1", +resultCalc.term_1);

      if (calculator.type === "5") {
        dispatch(setCalculator(resultCalc));
      }
    }
  }, []);
  const valueFormValidationSchema = Yup.object().shape({
    amount_1: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .matches(/[^0]+/, "Sum can't be equal 0")
      .max(30, "Max 30 symbols"),
    term_1: Yup.string().matches(/[^0]+/, "Term can't be equal 0"),
  });

  const formikForm = useFormik<{
    amount_1: number;
    term_1: number;
    type: string;
  }>({
    initialValues: {
      amount_1: 30000,
      term_1: 72,
      type: "4",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = (values: any) => {
    dispatch(setCalculator(values));
    localStorage.setItem(
      "calcResult",
      JSON.stringify({
        ...values,
      })
    );
  };

  const addAmount = () => {
    if (formikForm.values.amount_1 < 400000) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 + 100);
    }
    if (400000 - formikForm.values.amount_1 < 200) {
      formikForm.setFieldValue("amount_1", 400000);
    }
  };

  const removeAmount = () => {
    if (formikForm.values.amount_1 > 6000) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 - 100);
    }
    if (formikForm.values.amount_1 - 200 < 6000) {
      formikForm.setFieldValue("amount_1", 6000);
    }
  };

  const addTerm = () => {
    if (formikForm.values.term_1 < 240) {
      formikForm.setFieldValue("term_1", +formikForm.values.term_1 + 1);
    }
  };
  const removeTerm = () => {
    if (formikForm.values.term_1 > 3) {
      formikForm.setFieldValue("term_1", +formikForm.values.term_1 - 1);
    }
  };

  const coercion = (e: any, maxValue: number) => {
    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue("amount_1", maxValue);
    } else {
      formikForm.setFieldValue("amount_1", valueNum);
    }
  };

  return (
    <div className="calc">
    <form
      className="calc__form"
      onSubmit={formikForm.handleSubmit}
    >
      <div className="search-block-main">
        <div className="search-block-inputs">
          <div
            className="search-block-inputs__input-item  input-item"
            onBlur={() => {
              if (formikForm.values.amount_1 < 6000) {
                formikForm.setFieldValue("amount_1", 6000);
              }
            }}
          >
            <div className="input-item-main">
              <label className="input-item-main__label">
                {t<string>("CALCULATOR.LOAN_AMOUNT")}
              </label>
              <label className="ranges__controls-field">
                <input
                  type="text"
                  className={classNames({
                    "ranges__controls-field-put": true,
                    "ranges__controls-field-put__l": true,
                  })}
                  value={currencyFormat(formikForm.values.amount_1)}
                  onChange={(event) => {
                    coercion(event, 400000);
                  }}
                />
                {t<string>("CALCULATOR.BGN")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeAmount}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  className="range-wrapper__input"
                  type="range"
                  min={6000}
                  max={400000}
                  step={100}
                  {...formikForm.getFieldProps("amount_1")}
                />
                <button
                  onClick={addAmount}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="input-item__details">
              <p>6 000 {t<string>("CALCULATOR.BGN")}</p>
              <p>400 000 {t<string>("CALCULATOR.BGN")}</p>
            </div>
          </div>
          <div
            className="search-block-inputs__input-item input-item"
            onBlur={() => {
              if (formikForm.values.term_1 < 3) {
                formikForm.setFieldValue("term_1", 3);
              }
            }}
          >
            <div className="input-item-main">
              <label className="input-item-main__label">
                {t<string>("CALCULATOR.LOAN_TERM")}
              </label>
              <label className="ranges__controls-field">
                <input
                  className="ranges__controls-field-put ranges__controls-field-term"
                  type="number"
                  value={formikForm.values.term_1}
                  onChange={(e) => {
                    let valueEx = e.target.value.replace(/[^0-9]/g, "");
                    e.target.value = valueEx;

                    let value = Number(e.target.value);

                    if (e.target.value[0] !== "0") {
                      value > 240 || value < 0 || e.target.value === "0"
                        ? formikForm.setFieldValue("term_1", "240")
                        : formikForm.setFieldValue("term_1", e.target.value);
                      e.target.value === "" &&
                        formikForm.setFieldValue("term_1", "3");
                    }
                  }}
                />
                {t<string>("CALCULATOR.MONTHS")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeTerm}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  className="range-wrapper__input"
                  type="range"
                  min={3}
                  max={240}
                  step={1}
                  {...formikForm.getFieldProps("term_1")}
                />
                <button
                  onClick={addTerm}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="input-item__details">
              <p>3 {t<string>("CALCULATOR.MONTHS")}</p>
              <p>240 {t<string>("CALCULATOR.MONTHS")}</p>
            </div>
          </div>
        </div>
      </div>
      <button className="search-block-show" type="submit">
        {t<string>("CALCULATOR.BUTTON_LOGGED")}
      </button>
    </form>
    </div>
  );
};

export default MortgageLoansForm;
