import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Document } from "./documentType";

const state: Document = {
  certs: [],
  certsIds: [],
};

const documentSlice = createSlice({
  name: "document",
  initialState: state,
  reducers: {
    setCerts(state, action: PayloadAction<any[]>) {
      state.certs = action.payload;
    },
    setCertsIds(state, action: PayloadAction<number[]>) {
      state.certsIds = action.payload;
    },
  },
});

export default documentSlice.reducer;
export const { setCerts, setCertsIds } = documentSlice.actions;
