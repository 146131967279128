import { FC } from "react";
import { Link } from "react-router-dom";

import CreditsList from "./CreditsList/CreditsList";

import { PrivateUIRoutes, UIRoutes } from "../../../core/router";

const CreditsListContainer: FC = () => {
  return (
    <div>
      <Link to={`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.CREDITS}/create`}>
        Create
      </Link>
      <CreditsList />
    </div>
  );
};

export default CreditsListContainer;
