import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { adminDataType } from "./adminDataType";
import { Private } from "../../../models";

const state: adminDataType = {
  reviews: {
    name: '',
    email: '',
    rating: '',
    comment: ''
  }
}

const adminSlice = createSlice({
  name: 'admin',
  initialState: state,
  reducers: {
    setReviewingComment(state, action: PayloadAction<Private.Review>){
      state.reviews = action.payload
    }
  }
})

export default adminSlice.reducer
export const {setReviewingComment} = adminSlice.actions