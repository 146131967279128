import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { BiShow } from "react-icons/bi";
import { GrAddCircle, GrEdit } from "react-icons/gr";
import { CiCircleRemove } from "react-icons/ci";

import { AppContext } from "../../App";
import { APIRoutes } from "../../core/http";
import useHttpGet from "../../core/hooks/useHttpGet";

import PrivateService from "../../core/services/private.service";
import search from "../../assets/photos/profile/search.svg";
import sortingArrow from "../../assets/photos/all/sorting-arrow.svg";
import { Private } from "../../core/models";
import { useAppDispatch } from "../../core/store";
import {
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../core/services/enums";
import { setSelectedId } from "../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";
import ButtonExcel from "../ButtonExcel/ButtonExcel";

const AdminBanks = () => {
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterType, setFilterType] = useState("filter[search]");
  const [filter, setFilter] = useState<any>({});
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const appContext = useContext(AppContext);

  const { setReloadChecker, reloadChecker } = appContext;

  const { fetchedData: banks } = useHttpGet<any>(
    `${APIRoutes.ADMIN_ENTITIES_BANK}`,

    {
      dependencies: [reloadChecker, filter, pagination, sorter],
      query: { ...sorter, ...filter, ...pagination },
    }
  );

  const setFilterPerPage = () => {
    if (pagination.per_page <= 50) {
      return { ...pagination, per_page: pagination.per_page + 10 }
    }else{
      return {...pagination, per_page: 50}
    }
  }

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ [propName]: value || undefined });
  };

  const setPaginationPage = () => {
    setPagination(()=>{
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 }
      }else{
        return {...pagination, per_page: 50}
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const showContent = (id: number) => {
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.showBankDescription));
    dispatch(setSelectedId(id));
  };

  const showName = (id: number) => {
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.showBankName));
    dispatch(setSelectedId(id));
  };

  const addNewBank = () => {
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.addNewBank));
  };

  const updateBank = (id: number) => {
    dispatch(setSelectedId(id));
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.updateBank));
  };

  const deleteBank = async (id: number) => {
    try {
      const response = await PrivateService.deleteAdminBank(id);
      toast.success(response?.data?.message);
      setReloadChecker(!reloadChecker);
    } catch (errors: any) {
      setLoading(false);
      toast.error(errors?.response?.data?.message);
    }
  };

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};

    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  return (
    <>
      <div className="admin">
        <h2 className="admin-table__name">
          {t<string>("FINANCIAL_INSTITUTIONS.TITLE")}
        </h2>
        <ButtonExcel url='admin/banks_export'/>
        <div className="admin-container">
          <div className="profile-table">
            <div className="admin-search">
              <input
                className="admin-search__inp"
                value={filterValue}
                onChange={(e) => {
                  setFilterValue(e.target.value);
                }}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter" && filterType) {
                    setFilterProperty(filterType, filterValue);
                  }
                }}
              />
              <select
                onChange={(e) => {
                  e.target.value === "search"
                    ? setFilterType(`filter[${e.target.value}]`)
                    : setFilterType(`filter[${e.target.value}][like]`);
                }}
                className="admin-search__dropdown"
              >
                <option value="search">
                  {t<string>("FINANCIAL_INSTITUTIONS.SEARCH_ALL")}
                </option>
                <option value="name">
                  {t<string>("FINANCIAL_INSTITUTIONS.NAME")}
                </option>
                <option value="description">
                  {t<string>("FINANCIAL_INSTITUTIONS.DESCRIPTION")}
                </option>
                <option value="id">
                  {t<string>("FINANCIAL_INSTITUTIONS.FI_ID")}
                </option>
              </select>
              <button className="admin-search__btn">
                <img
                  src={search}
                  alt="search"
                  onClick={() => {
                    if (filterType) {
                      setFilterProperty(filterType, filterValue);
                    }
                  }}
                />
              </button>
              <button
                onClick={addNewBank}
                className="add-user__btn"
                title={t<string>("FINANCIAL_INSTITUTIONS.ADD_NEW")}
              >
                <GrAddCircle />
              </button>
            </div>
            <div className="users-container">
              <table className="users-table" cellSpacing="0" cellPadding="0">
                <thead className="users-table-head">
                  <tr className="users-table-head-row">
                    <td className="users-table-head__item">
                      {t<string>("FINANCIAL_INSTITUTIONS.FI_ID")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[id]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>{" "}
                    </td>
                    <td className="users-table-head__item"></td>
                    <td className="users-table-head__item">
                      {t<string>("FINANCIAL_INSTITUTIONS.NAME_BG")}
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("FINANCIAL_INSTITUTIONS.NAME_EN")}
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("FINANCIAL_INSTITUTIONS.DESCRIPTION")}
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("FINANCIAL_INSTITUTIONS.REVIEWS")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[reviews_count]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("FINANCIAL_INSTITUTIONS.RATING")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[rating]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("FINANCIAL_INSTITUTIONS.STATUS")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[status]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("FINANCIAL_INSTITUTIONS.CREATED")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[created_at]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item">
                      {t<string>("FINANCIAL_INSTITUTIONS.UPDATED")}
                      <button
                        onClick={() => {
                          setSorterProperty("order[updated_at]");
                        }}
                      >
                        <img src={sortingArrow} alt="sortingArrow" />
                      </button>
                    </td>
                    <td className="users-table-head__item"></td>
                  </tr>
                </thead>
                <tbody className="users-table-content">
                  {banks?.payload?.collection.map((bank: Private.AdminBank) => (
                    <tr className="users-table-row" key={bank.id}>
                      <td className="users-table-row__item">{bank.id}</td>
                      <td className="users-table-row__item">
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "contain",
                          }}
                          src={bank.logo_path}
                          alt={bank.logo_name}
                        />
                      </td>
                      <td className="users-table-row__item">
                        {/* <button
                          onClick={() => showName(bank.id)}
                          className="users-table-action__btn"
                          title="Show Name">
                          <BiShow />
                        </button> */}
                        {bank?.translations?.bg.name}
                      </td>
                      <td className="users-table-row__item">
                        {/* <button
                          onClick={() => showName(bank.id)}
                          className="users-table-action__btn"
                          title="Show Name">
                          <BiShow />
                        </button> */}
                        {bank?.translations?.en.name}
                      </td>
                      <td className="users-table-row__item">
                        <button
                          onClick={() => showContent(bank.id)}
                          className="users-table-action__btn"
                          title={t<string>("FINANCIAL_INSTITUTIONS.SHOW")}
                        >
                          <BiShow />
                        </button>
                      </td>
                      <td className="users-table-row__item">
                        {bank.reviews_count}
                      </td>
                      <td className="users-table-row__item">{bank.rating}</td>
                      <td className="users-table-row__item">{bank.status}</td>
                      <td className="users-table-row__item">
                        <span>{`${new Date(
                          bank?.created_at
                        ).toLocaleTimeString()}`}</span>
                        <br></br>
                        {`${bank?.created_at.slice(
                          8,
                          10
                        )}.${bank?.created_at.slice(
                          5,
                          7
                        )}.${bank?.created_at.slice(0, 4)}`}
                      </td>
                      <td className="users-table-row__item">
                        <span>{`${new Date(
                          bank?.updated_at
                        ).toLocaleTimeString()}`}</span>
                        <br></br>
                        {`${bank?.updated_at.slice(
                          8,
                          10
                        )}.${bank?.updated_at.slice(
                          5,
                          7
                        )}.${bank?.updated_at.slice(0, 4)}`}
                      </td>
                      <td className="users-table-row__item">
                        <button
                          onClick={() => updateBank(bank.id)}
                          className="users-table-action__btn"
                          title={t<string>("FINANCIAL_INSTITUTIONS.UPDATE")}
                        >
                          <GrEdit />
                        </button>
                        <button
                          onClick={() => deleteBank(bank.id)}
                          className="users-table-action__btn"
                          title={t<string>("FINANCIAL_INSTITUTIONS.DELETE")}
                        >
                          <CiCircleRemove />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {banks?.payload?.cursor.has_more_pages && (
              <button
                className="profile-table__btn"
                onClick={() => {
                  setPaginationPage();
                }}
              >
                {t<string>("FINANCIAL_INSTITUTIONS.SHOW_MORE")}
              </button>
            )}
            {!banks?.payload?.cursor.has_more_pages &&
              banks?.payload?.collection.length > 10 && (
                <button
                  onClick={setDefaultPagination}
                  className="profile-table__btn"
                >
                  {t<string>("FINANCIAL_INSTITUTIONS.HIDE_ALL")}
                </button>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminBanks;
