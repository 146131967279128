import axios, { AxiosResponse } from "axios";

import RequestsService from "./requests.service";
import $api, { APIRoutes } from "../http";
import { Private, Public } from "../models";
import FileDownload from "js-file-download";
import { response } from "express";
import { type } from "os";
import AdminLeads from "../../components/AdminLeads/AdminLeads";

export default class PrivateService {
  static async updateProfile(
    model: Private.Profile
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (
        (model.hasOwnProperty(property) && (model as any)[property]) ||
        property === "personal_code"
      ) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(APIRoutes.USER, formData, {
      params: { _method: "PUT" },
    });
  }

  static async deleteProfile(
    model: Private.Profile
  ): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(APIRoutes.USER);
  }

  static async addCredit(model: Private.Credit): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property))
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(APIRoutes.USER_CREDITS, formData);
  }

  static async updateCredit(
    model: Private.Credit
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property])
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.USER_CREDITS}/${model.id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async deleteCredit(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(`${APIRoutes.USER_CREDITS}/${id}`);
  }

  static async addBank(model: Private.Bank): Promise<AxiosResponse<any>> {//TODO: надо просто под запрос нормально написать 
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property))
        formData.append(property, (model as any)[property]);
    }
    console.log(formData, 'formData on send')
    return RequestsService.postMethod<any>(
      APIRoutes.ADMIN_ENTITIES_BANK,
      formData
    );
  }

  static async updateBank(model: Private.Bank): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property])
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_ENTITIES_BANK}/${model.id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async deleteBank(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN_ENTITIES_BANK}/${id}`
    );
  }

  static async leaveFeedback(
    model: Private.Review,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("name", model.name.toString());
    formData.append("email", model.email.toString());
    formData.append("rating", model.rating.toString());
    formData.append("comment", model.comment);

    return RequestsService.postMethod<any>(
      `${APIRoutes.PUBLIC_BANKS}/${id}/reviews`,
      formData
    );
  }

  static async adminAddDocument(
    doc: File[],
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("status", "approved");
    formData.append("document_name", doc[0].name);

    for (let i = 0; i < doc.length; i++) {
      formData.append("documents[]", doc[i]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_USERS}/${id}/documents`,
      formData
    );
  }

  static async adminDeleteDocument(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN_USERS_DOCUMENTS}/${id}`
    );
  }

  static async deleteDocument(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.USER_DOCUMENTS}/${id}`
    );
  }

  static async createQuestionnaire(
    questionnaire: Private.Questionnaire
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in questionnaire) {
      if (
        questionnaire.hasOwnProperty(property) &&
        (questionnaire as any)[property]
      )
        formData.append(property, (questionnaire as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.USER_QUESTIONNAIRES}`,
      formData
    );
  }

  static async addProfileDocument(doc: File): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("documents[]", doc);
    formData.append("document_name", doc.name);
    return RequestsService.postMethod<any>(
      `${APIRoutes.USER_PROFILE_DOCUMENTS}`,
      formData
    );
  }

  static async sendPdfViaEmail(
    model: any,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property])
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_USERS_SUBMITTED_APPLICATIONS}/${id}/pdf_email`,
      formData
    );
  }

  static async downloadPdf(id: number) {
    $api
      .get<any>(`${APIRoutes.ADMIN_USERS_SUBMITTED_APPLICATIONS}/${id}/pdf`, {
        responseType: "blob",
      })
      .then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `application_${id}`);

        document.body.appendChild(link);

        link.click();

        link?.parentNode?.removeChild(link);
      });
  }

  static async downloadDocument(id: number, file_name: string) {
    $api
      .get<any>(`${APIRoutes.USER_DOCUMENTS}/${id}/download_file`, {
        responseType: "blob",
      })
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);

        document.body.appendChild(link);

        link.click();

        link?.parentNode?.removeChild(link);
      });
  }

  static async adminApplyApplication(
    questionnaire_id: number,
    bank_offer_id: number,
    user_id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("questionnaire_id", String(questionnaire_id));
    formData.append("bank_offer_id", String(bank_offer_id));
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_USERS}/${user_id}/submitted_applications`,
      formData
    );
  }

  static async applyApplication(
    id: number,
    offer_id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("questionnaire_id", String(id));
    formData.append("bank_offer_id", String(offer_id));
    return RequestsService.postMethod<any>(
      `${APIRoutes.USER_SUBMITTED_APPLICATIONS}`,
      formData
    );
  }

  static async updateSubmittedApplication(
    model: any,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property])
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_USERS_SUBMITTED_APPLICATIONS}/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }
  static async userUpdateSubmittedApplication(
    model: any,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property])
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.USER_SUBMITTED_APPLICATIONS}/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async submittedApplicationComment(
    model: { comment: string; comment_for: string },
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("comment", model.comment);
    formData.append("comment_for", model.comment_for);
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_USERS_SUBMITTED_APPLICATIONS}/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async deleteUser(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(`${APIRoutes.ADMIN_USERS}/${id}`);
  }

  static async addNewUser(model: Private.NewUser): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property])
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(APIRoutes.ADMIN_USERS, formData);
  }

  static async updateUser(model: Private.NewUser): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (
        (model.hasOwnProperty(property) && (model as any)[property]) ||
        property === "personal_code"
      ) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_USERS}/${model.id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async updateQuestionnaire(
    model: Private.AdminQuestionnaire,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_USERS}/questionnaires/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async updateUserQuestionnaire(
    model: Private.Questionnaire,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.USER_QUESTIONNAIRES}/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async updateReview(
    model: Private.AdminReview,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_ENTITIES_REVIEWS}/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async updateComment(
    model: Private.ReviewUpdate,
    id: string | number
  ): Promise<AxiosResponse<any>> {
    return RequestsService.patchMethod<any>(
      `${APIRoutes.ADMIN_ENTITIES_REVIEWS}/${id}?comment=${model.comment}`
    )
  }

  static async updateLead(
    model: Private.Leads,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_LEADS_FROM_RATING}/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async addComment(
    status: string,
    comment: string,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("status", status);
    formData.append("comment", comment);
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_ENTITIES_BANK_REVIEWS}/${id}/comments`,
      formData
    );
  }

  static async emailVerify(email: string): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("email", email)
    return RequestsService.postMethod<any>(`${APIRoutes.AUTH_VERIFY_EMAIL}`, formData)
  }
  static async changeLocale(locale: string, id: number): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("locale", locale)
    formData.append('_method', 'PATCH')
    return RequestsService.patchMethod<any>(`${APIRoutes.USER_LOCALE}/${id}`, formData, {
      params:{
        'locale': locale,
      }
    })
  }

  static async addNewQuestionnaire(
    model: Private.NewUser,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property])
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_USERS}/${id}/questionnaires`,
      formData
    );
  }

  static async deleteSubmittedApllication(
    id: number
  ): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN_USERS_SUBMITTED_APPLICATIONS}/${id}`
    );
  }
  static async addBlogCategory(
    model: Private.AdminNewCategory
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property))
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/blog/categories`,
      formData
    );
  }

  static async addNewsCategory(
    model: Private.AdminNewCategory
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property))
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/news/categories`,
      formData
    );
  }
  static async addNewsPost(
    model: Private.AdminNewCategory
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property))
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/news/posts`,
      formData
    );
  }

  static async addNewBlogPost(
    model: Private.AdminNewCategory
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property))
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/blog/posts`,
      formData
    );
  }

  static async updateBlogCategory(
    model: Private.AdminNewCategory,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/blog/categories/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }
  static async updateNewsCategory(
    model: Private.AdminNewCategory,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/news/categories/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async updatePost(
    model: Private.AdminNewCategory,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/blog/posts/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async updateNewsPost(
    model: Private.AdminNewCategory,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/news/posts/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async updateAdminBank(
    model: Private.AdminBank,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_ENTITIES_BANK}/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async deleteNewsCategory(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/news/categories/${id}`
    );
  }

  static async deleteNewsPost(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/news/posts/${id}`
    );
  }

  static async deleteBlogCategory(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/blog/categories/${id}`
    );
  }
  static async deletePost(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/blog/posts/${id}`
    );
  }
  static async deleteAdminBank(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN_ENTITIES_BANK}/${id}`
    );
  }
  static async deleteCreditOffer(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN}/entities/credit_offers/${id}`
    );
  }

  static async addAdminNewBank(
    model: Private.AdminNewCategory
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property))
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      APIRoutes.ADMIN_ENTITIES_BANK,
      formData
    );
  }
  static async createCreditOffer(
    model: Private.AdminCreateCreditOffer,
    id: number | string
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property))
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_ENTITIES_BANK}/${id}/credit_offers`,
      formData
    );
  }

  static async updateCreditOffer(
    model: Private.AdminUpdateCreditOffer,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property)) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `admin/entities/credit_offers/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async deleteBannerRequest(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.deleteMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/launch_banners/${id}`
    );
  }

  static async addBannerRequest(model: any): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property])
        formData.append(property, (model as any)[property]);
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/launch_banners`,
      formData
    );
  }

  static async updateBannerRequest(
    model: Private.IAdminBannerRequest,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_PUBLIC}/launch_banners/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async sendVerificationEmail(id: number): Promise<AxiosResponse<any>> {
    return RequestsService.postMethod<any>(
      `${APIRoutes.ADMIN_USERS}/${id}/verify_email`
    );
  }

  static async submittedApplicationDecision(
    status: string,
    id: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("status", status);
    return RequestsService.postMethod<any>(
      `${APIRoutes.USER_SUBMITTED_APPLICATIONS}/${id}`,
      formData,
      {
        params: { _method: "PUT" },
      }
    );
  }

  static async updateNotifications(model: any): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (model as any)[property]) {
        formData.append(property, (model as any)[property]);
      }
    }
    return RequestsService.postMethod<any>(
      `${APIRoutes.USER_NOTIFICATIONS}`,
      formData,
      {
        params: { _method: "PUT" }, 
      }
    );
  }


  static async updateAllNotifications(model: any): Promise<AxiosResponse<any>> {
    try {
      return RequestsService.putMethod<any>(
        `${APIRoutes.USER_NOTIFICATIONS}`,
        model,
        {
          params: { _method: "PUT" }, 
        }
      );
    } catch (error) {
      console.error('Error updating notifications:', error);
      throw error;
    }
  }

  static async userNotifications(filter: any): Promise<AxiosResponse<any>> {
    return RequestsService.getMethod<any>(APIRoutes.USER_NOTIFICATIONS);
  }
}
