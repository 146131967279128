import { FC } from "react";
import BanksForm from "./BanksForm/BanksForm";

const BanksEditContainer: FC = () => {
  return (
    <div>
      <BanksForm />
    </div>
  );
};

export default BanksEditContainer;
