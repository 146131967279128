import React, { useContext } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { AppContext } from "../../App";
import PrivateService from "../../core/services/private.service";
import { useAppDispatch } from "../../core/store";
import { setModal } from "../../core/store/reducers/modal/modalSlice";

const AddNewComment = () => {
  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);

  const { adminCommentId, reloadChecker, setReloadChecker } = appContext;

  const valueFormValidationSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
    comment: Yup.string()
      .required("Comment is required")
      .max(520, "Max 520 characters"),
  });

  const formikForm = useFormik<{
    id: number;
    status: string;
    comment: string;
  }>({
    initialValues: {
      id: adminCommentId,
      status: "approved",
      comment: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values: any) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    try {
      const response = await PrivateService.addComment(
        values.status,
        values.comment,
        values.id
      );
      dispatch(setModal(false));
      formikForm.resetForm();
      toast.success(response?.data?.message);
      setReloadChecker(!reloadChecker);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
  };

  return (
    <div className="modal__container modal-logout">
      <div className="modal-logout__box">
        <form className="admin-add-comment" onSubmit={formikForm.handleSubmit}>
          <textarea
            className="admin-add-comment__txt"
            placeholder="Your comment"
            {...formikForm.getFieldProps("comment")}
          ></textarea>
          <div className="auth__submit">
            <button className="auth__submit-btn" type="submit">
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewComment;
