import React from "react";

export interface IRoute {
  path: string;
  element: React.ReactElement;
}

export enum UIRoutes {
  ABOUT_US = "about-us",
  AUTH = "auth",
  ACCOUNT = "account",
  ADMIN = "admin",
  PARTNER = "partner",
  SET_PASSWORD = "set_password/:id/:code",
  FORGOT_PASSWORD = "forgot-password",
  RESTORE_PASSWORD = "restore-password/:code/:id",
  RESET_PASSWORD = "reset_password/:id/:code",
  CALCULATOR = "calculator",
  FINANCE = "loans",
  HOME = "home",
  FAQ = "faq",
  CONTACT_US = "contact-us",
  PROFILE = "profile",
  VERIFY_EMAIL = "verify-email/:id/:code",
  VERIFY_PHONE = "verify-phone",
  BANKS_RATING = "banks-rating",
  // NEWS = "news",
  // BLOG = "blog",
  PREAUTH = "pre-auth",
  LEARNING_ANNOUNCE = "learning-announce",
  SERVICES_ANNOUNCE = "services-announce",
  FEEDBACK_ANNOUNCE = "feedback-announce",
  PRIVACY_POLICY = "privacy-policy",
  TERMS_CONDITIONS = "terms-conditions",
  COOKIE_POLICY = "cookie-policy",
  RESULTS_LIST = "result-list",
  VERIFY_EMAIL_SUCCESS = "verify-success",
  UNDER_CONSTRUCTION = "under-construction"
}

export enum PrivateUIRoutes {
  ADMIN_PANEL = "admin-panel",
  DASHBOARD = "dashboard",
  CREDITS = "credits",
  BANKS = "banks",
  // BLOG = "blog",
  QUESTIONNAIRE = "questionnaire",
  RESULTS = "results",
  RESULTS_LIST = "results-list",
  FI = "fi",
  LEADS_FROM_RATING = "leads-from-rating",
  /*  USERS = "users",
  USERS_EDIT = "user/edit",
  USERS_CREATE = "user/create", */
}

export enum PartnerUIRoutes {
  REQUESTS = "requests",
}
