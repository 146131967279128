import { useNavigate } from "react-router-dom";

import { PrivateUIRoutes, UIRoutes } from "../../../../core/router";
import { useAppDispatch } from "../../../../core/store";
import { setModal } from "../../../../core/store/reducers/modal/modalSlice";

const ResultsAlertModal = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const createNewQuestionnaire = () => {
    sessionStorage.removeItem("stepper");

    dispatch(setModal(false));

    navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
  };

  return (
    <div className="modal__container modal-addUser results-modal">
      <div className="modal-logout__box results-alert">
        <p className="modal-logout__box-title results-alert__text">
          You must create a new questionnaire to change the credit type!
        </p>
        <button
          className="results-alert__link"
          onClick={createNewQuestionnaire}>
          Create new
        </button>
      </div>
    </div>
  );
};

export default ResultsAlertModal;
