import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ReactGA from "react-ga4";

import { AppContext } from "../../../App";

import { PrivateUIRoutes, UIRoutes } from "../../../core/router";
import { currencyFormat, PMT } from "../../../core/functions/funtions";
import useAuth from "../../../core/hooks/useAuth";

import add from "../../../assets/photos/all/add.svg";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { style } from "../../../core/constants/style";
import { useAppDispatch } from "../../../core/store";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../core/services/enums";
import {
  setCalculator,
  setLoginRoute,
} from "../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const MergingLoans: FC = () => {
  const { isAuth } = useAuth();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const appContext = useContext(AppContext);

  const { setCalculationExample, setSelectionType } = appContext;

  const addCredit = () => {
    if (formikForm.values.ranges.length < 3) {
      formikForm.setFieldValue(
        "ranges",
        [...formikForm.values.ranges].concat([
          [
            {
              title: `${t<string>("CALCULATOR.LOAN_AMOUNT")}`,
              amount: 10000,
              min: 500,
              max: 50000,
              step: 100,
              prefix: "500 BGN",
              sufix: "50 000 BGN",
            },
            {
              title: `${t<string>("CALCULATOR.LOAN_TERM")}`,
              term: 36,
              min: 3,
              max: 120,
              step: 1,
              prefix: `3 ${t<string>("CALCULATOR.MONTHS")}`,
              sufix: `120 ${t<string>("CALCULATOR.MONTHS")}`,
            },
          ],
        ])
      );
    }
  };

  const deleteCredit = (index: number) => {
    const newCredits = formikForm.values.ranges.filter(
      (range) => formikForm.values.ranges.indexOf(range) !== index
    );

    formikForm.setFieldValue("ranges", newCredits);
  };

  const formikForm = useFormik<{
    ranges: [
      {
        title: string;
        amount: number;
        min: number;
        max: number;
        step: number;
        prefix: string;
        sufix: string;
      },
      {
        title: string;
        term: number;
        min: number;
        max: number;
        step: number;
        prefix: string;
        sufix: string;
      }
    ][];
    amount: number;
    term: number;
  }>({
    initialValues: {
      ranges: [
        [
          {
            title: `${t<string>("CALCULATOR.LOAN_AMOUNT")}`,
            amount: 10000,
            min: 500,
            max: 50000,
            step: 100,
            prefix: `500 ${t<string>("CALCULATOR.BGN")}`,
            sufix: `50 000 ${t<string>("CALCULATOR.BGN")}`,
          },
          {
            title: `${t<string>("CALCULATOR.LOAN_TERM")}`,
            term: 36,
            min: 3,
            max: 120,
            step: 1,
            prefix: `3 ${t<string>("CALCULATOR.MONTHS")}`,
            sufix: `120 ${t<string>("CALCULATOR.MONTHS")}`,
          },
        ],
      ],
      amount: 0,
      term: 0,
    },

    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = (values: any) => {
    let amount_1 = "";
    let amount_2 = "";
    let amount_3 = "";
    let term_1 = "";
    let term_2 = "";
    let term_3 = "";

    values.ranges.forEach((range: any) => {
      if (values.ranges.indexOf(range) === 0) {
        amount_1 = range[0].amount;
        term_1 = range[1].term;
      }

      if (values.ranges.indexOf(range) === 1) {
        amount_2 = range[0].amount;
        term_2 = range[1].term;
      }

      if (values.ranges.indexOf(range) === 2) {
        amount_3 = range[0].amount;
        term_3 = range[1].term;
      }
    });

    const checkedValues: any = {
      amount_1:
        Number(amount_1) < 500
          ? 10000
          : Math.ceil(Number(amount_1) / 100) * 100,
      amount_2:
        Number(amount_2) < 500 && amount_2
          ? 10000
          : Math.ceil(Number(amount_2) / 100) * 100,
      amount_3:
        Number(amount_3) < 500 && amount_3
          ? 10000
          : Math.ceil(Number(amount_3) / 100) * 100,
      term_1: term_1,
      term_2: term_2,
      term_3: term_3,
      totalAmount:
        (Number(amount_1) < 500
          ? 10000
          : Math.ceil(Number(amount_1) / 100) * 100) +
        (Number(amount_2) < 500 && amount_2
          ? 10000
          : Math.ceil(Number(amount_2) / 100) * 100) +
        (Number(amount_3) < 500 && amount_3
          ? 10000
          : Math.ceil(Number(amount_3) / 100) * 100),
      type: "7",
    };

    if (!checkedValues.amount_2 && !checkedValues.term_2) {
      delete checkedValues.amount_2;
      delete checkedValues.term_2;
    }
    if (!checkedValues.amount_3 && !checkedValues.term_3) {
      delete checkedValues.amount_3;
      delete checkedValues.term_3;
    }

    localStorage.setItem("calcResult", JSON.stringify(checkedValues));
    dispatch(setCalculator(checkedValues));
    dispatch(setLoginRoute(true));

    ReactGA.event({
      category: "calc_public",
      action:
        process.env.REACT_APP_ANALYTICS_NAME === ""
          ? "calc_public"
          : `calc_public_${process.env.REACT_APP_ANALYTICS_NAME}`,
    });
    navigate(`/${UIRoutes.RESULTS_LIST}`);
    // if (isAuth) {
    //   navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
    // } else {
    //   dispatch(setModalType(AuthSelectionType.Auth));
    //   setSelectionType(AuthSelectionType.Register);
    //   dispatch(setModal(true));
    //   window.scrollTo(0, 0);
    //   document.body.style.overflow = "hidden";
    //   localStorage.setItem(
    //     "prevPage",
    //     `/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`
    //   );
    // }
  };

  const perMonth = () => {
    let rate = 7 / 100 / 12;
    let totalAmount = 0;
    let maxTerm = 0;

    formikForm.values.ranges.forEach((range: any) => {
      totalAmount += range[0].amount;
      if (range[1].term > maxTerm) {
        maxTerm = range[1].term;
      }
    });

    return PMT(rate, maxTerm, -totalAmount, 0, 0).toFixed(2);
  };

  const handleExample = () => {
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.calcExample));
    setCalculationExample(4);
  };

  const addAmount = (index: number) => {
    if (formikForm.values.ranges[index][0].amount < 50000) {
      formikForm.setFieldValue(
        `ranges.${index}[0].amount`,
        formikForm.values.ranges[index][0].amount + 100
      );
    }
    if (50000 - formikForm.values.ranges[index][0].amount < 200) {
      formikForm.setFieldValue(`ranges.${index}[0].amount`, 50000);
    }
  };

  const removeAmount = (index: number) => {
    if (formikForm.values.ranges[index][0].amount > 500) {
      formikForm.setFieldValue(
        `ranges.${index}[0].amount`,
        formikForm.values.ranges[index][0].amount - 100
      );
    }
    if (formikForm.values.ranges[index][0].amount - 200 < 500) {
      formikForm.setFieldValue(`ranges.${index}[0].amount`, 500);
    }
  };

  const addTerm = (index: number) => {
    if (formikForm.values.ranges[index][1].term < 120) {
      formikForm.setFieldValue(
        `ranges.${index}[1].term`,
        formikForm.values.ranges[index][1].term + 1
      );
    }
  };

  const removeTerm = (index: number) => {
    if (formikForm.values.ranges[index][1].term > 3) {
      formikForm.setFieldValue(
        `ranges.${index}[1].term`,
        formikForm.values.ranges[index][1].term - 1
      );
    }
  };

  const coercion = (e: any, maxValue: number, index: number) => {
    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue(`ranges.${index}[0].amount`, maxValue);
    } else {
      formikForm.setFieldValue(`ranges.${index}[0].amount`, valueNum);
    }
  };

  return (
    <div className="calc">
      <form onSubmit={formikForm.handleSubmit} className="calc__form">
        <div className="calc__form-container calc-container">
          {[...formikForm.values.ranges].map((range, index) => (
            <div key={index} className="calc-container__boxes">
              {index !== 0 && (
                <div className="calc-container__add calc-container__remove">
                  <button
                    className="calc-container__add-btn"
                    onClick={() => deleteCredit(index)}
                    type="button"
                  >
                    {t<string>("CALCULATOR.MERGING_REMOVE")}
                  </button>
                </div>
              )}
              <div
                className="calc-container__ranges ranges"
                onBlur={() => {
                  formikForm.values.ranges.map(
                    (range, i) =>
                      range[0].amount < 500 &&
                      formikForm.setFieldValue(`ranges[${i}][0].amount`, 500)
                  );
                }}
              >
                <div className="ranges__controls">
                  <label className="ranges__controls-desc">
                    {range[0].title}
                  </label>
                  <label className="ranges__controls-field">
                    <input
                      type="text"
                      min={500}
                      max={50000}
                      step={100}
                      className={classNames({
                        "ranges__controls-field-put": true,
                        "ranges__controls-field-put__l": true,
                      })}
                      value={currencyFormat(range[0].amount)}
                      onChange={(event) => {
                        coercion(event, 50000, index);
                      }}
                    />
                    {t<string>("CALCULATOR.BGN")}
                  </label>
                  <div className="range-wrapper">
                    <button
                      onClick={() => removeAmount(index)}
                      type="button"
                      className="range-wrapper__step-add"
                    >
                      <AiOutlineMinusCircle style={style} size={32} />
                    </button>
                    <input
                      type="range"
                      min={range[0].min}
                      max={range[0].max}
                      step={range[0].step}
                      className="range__controls-input range-wrapper__input"
                      {...formikForm.getFieldProps(`ranges.${index}[0].amount`)}
                    />
                    <button
                      onClick={() => addAmount(index)}
                      type="button"
                      className="range-wrapper__step-minus"
                    >
                      <AiOutlinePlusCircle style={style} size={32} />
                    </button>
                  </div>
                </div>
                <div className="ranges__inform">
                  <span className="ranges__inform-text">{`${range[0].prefix}`}</span>
                  <span className="ranges__inform-text">{`${range[0].sufix}`}</span>
                </div>
              </div>
              <div
                className="calc-container__ranges ranges"
                onBlur={() => {
                  formikForm.values.ranges.map(
                    (range, i) =>
                      range[1].term < 3 &&
                      formikForm.setFieldValue(`ranges[${i}][1].term`, 3)
                  );
                }}
              >
                <div className="ranges__controls">
                  <label className="ranges__controls-desc">
                    {range[1].title}
                  </label>
                  <label className="ranges__controls-field">
                    <input
                      type="text"
                      min={3}
                      max={120}
                      className="ranges__controls-field-put ranges__controls-field-term"
                      value={range[1].term}
                      onChange={(e) => {
                        let value = Number(e.target.value);

                        let valueEx = e.target.value.replace(/[^0-9]/g, "");

                        e.target.value = valueEx;
                        if (e.target.value[0] !== "0") {
                          value > 120 || value < 0 || e.target.value === "0"
                            ? formikForm.setFieldValue(
                                `ranges.${index}[1].term`,
                                120
                              )
                            : formikForm.setFieldValue(
                                `ranges.${index}[1].term`,
                                Number(e.target.value)
                              );
                          e.target.value === "" &&
                            formikForm.setFieldValue(
                              `ranges.${index}[1].term`,
                              3
                            );
                        }
                      }}
                    />
                    {t<string>("CALCULATOR.MONTHS")}
                  </label>
                  <div className="range-wrapper">
                    <button
                      onClick={() => removeTerm(index)}
                      type="button"
                      className="range-wrapper__step-add"
                    >
                      <AiOutlineMinusCircle style={style} size={32} />
                    </button>
                    <input
                      type="range"
                      min={range[1].min}
                      max={range[1].max}
                      step={range[1].step}
                      className="range__controls-input range-wrapper__input"
                      {...formikForm.getFieldProps(`ranges.${index}[1].term`)}
                    />
                    <button
                      onClick={() => addTerm(index)}
                      type="button"
                      className="range-wrapper__step-minus"
                    >
                      <AiOutlinePlusCircle style={style} size={32} />
                    </button>
                  </div>
                </div>
                <div className="ranges__inform">
                  <span className="ranges__inform-text">{`${range[1].prefix}`}</span>
                  <span className="ranges__inform-text">{`${range[1].sufix}`}</span>
                </div>
              </div>
            </div>
          ))}
          {formikForm.values.ranges.length < 3 && 
            <div className="calc-container__add">
              <button
              className="calc-container__add-btn"
              onClick={addCredit}
              type="button"
            >
              <img src={add} alt="add icon" />
                {t<string>("CALCULATOR.MERGING_ADD")}
              </button>
            </div>
          }
        </div>

        <div className="calc__form-actions info-actions">
          <p className="info-actions__text">
            {t<string>("CALCULATOR.MONTHLY_PAYMANENT")}
            <span className="info-actions__text-star">*</span>:
            <span className="info-actions__text-bolder">
              {perMonth()} <span> {t<string>("CALCULATOR.BGN")}/{t<string>("CALCULATOR.MONTHS")}</span>
            </span>
          </p>
          <span className="info-actions__small">
            {t<string>("CALCULATOR.INFORM")}
            <span onClick={handleExample} className="calc-hint">
              {t<string>("CALCULATOR.INFORM_LINK")}
            </span>
          </span>
          <span className="info-actions__small">
              {t<string>("CALCULATOR.INFORM_TEXT")} 
          </span>

          <button type="submit" className="info-actions__button">
            {/* {isAuth ? (
              <span>{t<string>("CALCULATOR.BUTTON_LOGGED")}</span>
            ) : (
              <span>{t<string>("CALCULATOR.BUTTON")}</span>
            )} */}
              <span>{t<string>("CALCULATOR.BUTTON_LOGGED")}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default MergingLoans;
