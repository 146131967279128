import { FC } from "react";

import {
  partners_main,
  partners_main_mobile,
  // partners_secondary,
  // partners_secondary_mobile,
} from "../../core/constants/partners";
import { useTranslation } from "react-i18next";

const Partners: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="partners">
      <div className="partners__container">
        <p className="partners__container-subtitle">
          {t<string>("HOME.PARTNERS_SUBTITLE")}
        </p>
        <h3 className="partners__container-title">
          {t<string>("HOME.PARTNERS_TITLE")}
        </h3>
        <div className="partners-section__partner-logos partner-logos partners__container-box">
          <div className="partner-logos-row">
            {partners_main.map((item) => {
              const { photo, id } = item;
              return <img key={id} src={photo} alt={photo} />;
            })}
          </div>
          {/* <div className="partner-logos-row">
            {partners_secondary.map((item) => {
              const { photo, id } = item;

              return <img key={id} src={photo} alt={photo} />;
            })}
          </div> */}
        </div>
        <div className=" partner-logos-mobile partners-section__partner-logos-mobile partners__container-box">
          <div className="partner-logos-mobile-row">
            {partners_main_mobile.map((item) => {
              const { photo, id } = item;

              return (
                <img
                  className="partner-logos-mobile-row__item"
                  key={id}
                  src={photo}
                  alt={photo}
                />
              );
            })}
          </div>
          {/* <div className="partner-logos-mobile-row">
            {partners_secondary_mobile.map((item) => {
              const { photo, id } = item;

              return (
                <img
                  className="partner-logos-mobile-row__item"
                  key={id}
                  src={photo}
                  alt={photo}
                />
              );
            })}
          </div> */}
        </div>
        {/* <a href="" className="partners__container-more">
          {t<string>("HOME.PARTNERS_BUTTON")}
        </a> */}
        {/* <a href="" className="partners__container-more-mobile">
          {t<string>("HOME.PARTNERS_LINK")}
        </a> */}
      </div>
    </div>
  );
};

export default Partners;
