import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";
// import * as Yup from "yup";
// import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../../App";
import SendViaEmail from "../../SendViaEmail/SendViaEmail";

import useRole from "../../../core/hooks/useRole";
import useHttpGet from "../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../core/http";
import { useAppDispatch, useAppSelector } from "../../../core/store";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";
import PrivateService from "../../../core/services/private.service";
import {
  AuthSelectionType,
  SubmittedApplicationStatus,
} from "../../../core/services/enums";
import CommonService from "../../../core/services/common.service";
import {
  setChoosenData,
  setSelectedId,
} from "../../../core/store/reducers/app/appDataSlice";
import defaultAvatar from "../../../assets/photos/profile/default-avatar.svg";
import asistentAvatar from "../../../assets/photos/all/asistent-avatar.svg";

const UpdateSubmittedApplication = () => {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [sentViaEmail, setSentViaEmail] = useState(false);
  const [status, setStatus] = useState<string>("");
  const [updateComments, setUpdateComments] = useState(false);
  const [application, setApplication] = useState<any>({});
  const [comment, setComment] = useState<{
    comment: string;
    comment_for: string;
  }>({ comment: "", comment_for: "" });
  const { currentUser } = useAppSelector((state) => state.auth);
  const { selectedId, appLanguage } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);
  const { reloadChecker, setReloadChecker } = appContext;

  const { fetchedData: submittedApplication } = useHttpGet<any>(
    `${APIRoutes.ADMIN_USERS_SUBMITTED_APPLICATIONS}/${selectedId}`,
    {
      dependencies: [selectedId, updateComments === true],
      resolve: (response) => {
        setApplication(response.payload);
        setUpdateComments(false);
        if (response?.payload) {
          setId(response?.payload?.id);
          setStatus(response?.payload?.status);
        }
      },
    }
  );
  // const { collection } = application?.comments;
  const { t } = useTranslation();

  const { isSuperAdmin } = useRole();

  useEffect(() => {
    setSentViaEmail(false);
  }, [selectedId]);

  const handleSubmit = async (status: any) => {
    try {
      const response = await PrivateService.updateSubmittedApplication(
        { status: status },
        Number(selectedId)
      );

      setLoading(false);
      setReloadChecker(!reloadChecker);
      toast.success(response?.data?.message);
      dispatch(setModal(false));
    } catch (errors: any) {
      console.log(errors);
      errors?.response?.data?.payload
        ? CommonService.showErrors(errors?.response?.data?.payload)
        : toast.error(errors?.response?.data?.message);
      setLoading(false);
    }
  };

  const addComment = async (role: string) => {
    const commentData = { ...comment, comment_for: role };

    if (!comment.comment) {
      return;
    }

    try {
      const response = await PrivateService.submittedApplicationComment(
        commentData,
        Number(selectedId)
      );

      setLoading(false);
      setReloadChecker(!reloadChecker);
      setComment({ comment: "", comment_for: "" });
      toast.success(response?.data?.message);
      setUpdateComments(true);
    } catch (errors: any) {
      errors?.response?.data?.payload
        ? CommonService.showErrors(errors?.response?.data?.payload)
        : toast.error(errors?.response?.data?.message);
      setLoading(false);
    }
  };

  const showQuestionnaire = (application: any) => {
    dispatch(setSelectedId(application.questionnaire_id));
    dispatch(setChoosenData({ application_id: application.id }));
    dispatch(setModalType(AuthSelectionType.showQuestionnaire));
    dispatch(setModal(true));
  };

  const createdApplicationDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("SUBMITTED_APPLICATIONS.UPDATE_APPLICATION")}
        </p>
        <div className="update-offer-head">
          {isSuperAdmin && (
            <div className="about__form-box about-box update-offer-head-wrapper">
              <div
                className="about-box__file-block update-offer-head__btn"
                onClick={async () => {
                  await PrivateService.downloadPdf(selectedId);
                }}
              >
                <span>{t<string>("SUBMITTED_APPLICATIONS.SAVE_PDF")}</span>
              </div>
            </div>
          )}
          <div className="about__form-box about-box update-offer-head-wrapper">
            <button
              onClick={() => showQuestionnaire(submittedApplication?.payload)}
              className="about-box__file-block update-offer-head__btn"
            >
              <span>
                {t<string>("SUBMITTED_APPLICATIONS.SHOW_QUESTIONNAIRE")}
              </span>
            </button>
          </div>
          <div className="about__form-box about-box update-offer-head-wrapper">
            {!sentViaEmail ? (
              <button
                onClick={() => setSentViaEmail(true)}
                className="about-box__file-block update-offer-head__btn"
              >
                <span>
                  {t<string>("SUBMITTED_APPLICATIONS.SEND_VIA_EMAIL")}
                </span>
              </button>
            ) : (
              <SendViaEmail setSentViaEmail={setSentViaEmail} />
            )}
          </div>
        </div>
        <div className="about about-applications">
          <div className="about__form about-applications__block">
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("CREDIT_OFFERS.NAME")}
                </span>
                <span
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  {appLanguage === "en"
                    ? submittedApplication?.payload?.bank_offer?.translations
                        ?.en?.name
                    : submittedApplication?.payload?.bank_offer?.translations
                        ?.bg?.name}
                </span>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {
                      submittedApplication?.payload?.bank_offer
                        ?.loan_amount_from
                    }
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {submittedApplication?.payload?.bank_offer?.loan_amount_to}
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.CREDIT_TERM_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {
                      submittedApplication?.payload?.bank_offer
                        ?.credit_term_from
                    }
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.CREDIT_TERM_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {submittedApplication?.payload?.bank_offer?.credit_term_to}
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("CREDIT_OFFERS.INTEREST_RATE_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {
                      submittedApplication?.payload?.bank_offer
                        ?.interest_rate_from
                    }
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("CREDIT_OFFERS.INTEREST_RATE_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {
                      submittedApplication?.payload?.bank_offer
                        ?.interest_rate_to
                    }
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("CREDIT_OFFERS.OFFER_TYPE")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  {submittedApplication?.payload?.bank_offer?.bankOfferTypes
                    ?.collection[0]?.id === 1 && (
                    <span>{t<string>("LOANS.CONSUMER_LOANS")}</span>
                  )}
                  {submittedApplication?.payload?.bank_offer?.bankOfferTypes
                    ?.collection[0]?.id === 2 && (
                    <span>{t<string>("LOANS.CREDIT_CARDS")}</span>
                  )}
                  {submittedApplication?.payload?.bank_offer?.bankOfferTypes
                    ?.collection[0]?.id === 3 && (
                    <span>{t<string>("LOANS.QUICK_LOAN")}</span>
                  )}
                  {submittedApplication?.payload?.bank_offer?.bankOfferTypes
                    ?.collection[0]?.id === 4 && (
                    <span>{t<string>("LOANS.MORTGAGE_LOANS")}</span>
                  )}
                  {submittedApplication?.payload?.bank_offer?.bankOfferTypes
                    ?.collection[0]?.id === 5 && (
                    <span>{t<string>("LOANS.CAR_LEASING")}</span>
                  )}
                  {submittedApplication?.payload?.bank_offer?.bankOfferTypes
                    ?.collection[0]?.id === 6 && (
                    <span>{t<string>("LOANS.REFINANCING")}</span>
                  )}
                  {submittedApplication?.payload?.bank_offer?.bankOfferTypes
                    ?.collection[0]?.id === 7 && (
                    <span>{t<string>("LOANS.MERGING_LOANS")}</span>
                  )}
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("CREDIT_OFFERS.SHOW_ON_PUBLIC")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  {submittedApplication?.payload?.bank_offer?.show_on_public ===
                    1 && <span>{t<string>("CREDIT_OFFERS.YES")}</span>}
                  {submittedApplication?.payload?.bank_offer?.show_on_public ===
                    0 && <span>{t<string>("CREDIT_OFFERS.NO")}</span>}
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("CREDIT_OFFERS.CHOOSE_BANK")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  {appLanguage === "en"
                    ? submittedApplication?.payload?.bank_offer?.bank
                        ?.translations.en.name
                    : submittedApplication?.payload?.bank_offer?.bank
                        ?.translations.bg.name}
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.STATUS")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": true,
                  })}
                  value={status}
                  onChange={(event: any) => setStatus(event.target.value)}
                >
                  <option value={SubmittedApplicationStatus.open}>
                    {t<string>("Open")}
                  </option>
                  <option value={SubmittedApplicationStatus.inProgress}>
                    {t<string>("In progress")}
                  </option>
                  <option value={SubmittedApplicationStatus.sendToPartner}>
                    {t<string>("Send to partner")}
                  </option>
                  <option value={SubmittedApplicationStatus.approved}>
                    {t<string>("Partner response : approved")}
                  </option>
                  <option value={SubmittedApplicationStatus.declined}>
                    {t<string>("Partner response : declined")}
                  </option>
                  <option value={SubmittedApplicationStatus.counterOffer}>
                    {t<string>("Partner response : counter-offer")}
                  </option>
                  <option value={SubmittedApplicationStatus.acceptedByClient}>
                    {t<string>("Accepted by client")}
                  </option>
                  <option value={SubmittedApplicationStatus.rejectedByClient}>
                    {t<string>("Rejected by client")}
                  </option>
                  <option
                    value={SubmittedApplicationStatus.noResponseFromClient}
                  >
                    {t<string>("No response from client")}
                  </option>
                </select>
              </label>
            </div>
            <button
              className={classNames({
                about__submit: true,
                "about__submit-active": true,
              })}
              style={{ maxWidth: "60%", margin: "0 auto" }}
              type="button"
              onClick={() => handleSubmit(status)}
            >
              {t<string>("CREDIT_OFFERS.SAVE_BUTTON")}
            </button>
          </div>
          {application?.comments?.collection.length !== 0 && (
            <div className="application-card__comment">
              <div className="application-card__comment-block">
                {application?.comments?.collection.map((comment: any) => (
                  <div
                    className="application-card__comment-wrap"
                    key={comment.id}
                  >
                    <div
                      className={classNames(
                        "application-card__comment-head",
                        comment.comment_for === "user" && "user"
                      )}
                    >
                      <div className="application-card__comment-head-img">
                        <img
                          src={
                            comment.comment_for === "user"
                              ? currentUser?.avatar
                                ? currentUser?.avatar
                                : defaultAvatar
                              : asistentAvatar
                          }
                          alt="admin image"
                        />
                      </div>
                      <div className="application-card__comment-head-text">
                        <h4 className="application-card__comment-head-title">
                          {comment.comment_for === "user"
                            ? currentUser?.first_name
                              ? currentUser?.first_name
                              : "User"
                            : "Asistent"}
                        </h4>
                        <p>{createdApplicationDate(comment.created_at)}</p>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "application-card__comment-text",
                        comment.comment_for === "user" && "user"
                      )}
                    >
                      <p>{comment.comment}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <section className="offer-status-wrapper">
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                    "about-box__field-inp-error":
                      comment.comment && comment.comment.length < 5,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.COMMENT")}
                </span>
                <textarea
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-textarea": true,
                    "about-box__field-inp-filled": comment.comment,
                    "about-box__field-inp-touched":
                      comment.comment && comment.comment.length < 5,
                  })}
                  placeholder="Comment..."
                  value={comment.comment}
                  onChange={(event: any) => {
                    setComment({ ...comment, comment: event.target.value });
                  }}
                />
              </label>
              {comment.comment && comment.comment.length < 5 && (
                <div className="form-control-error">
                  {t<string>("SUBMITTED_APPLICATIONS.COMMENT_LENGTH")}
                </div>
              )}
              <div className="offer-status-wrapper-control">
                <button
                  className={classNames({
                    about__submit: true,
                    "offer-status-wrapper-control__btn": true,
                    "about__submit-active": comment.comment,
                  })}
                  type="button"
                  onClick={() => addComment("admin")}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.SAVE_FOR_ADMIN")}
                </button>
                <button
                  className={classNames({
                    about__submit: true,
                    "offer-status-wrapper-control__btn": true,
                    "about__submit-active": comment.comment,
                  })}
                  onClick={() => addComment("user")}
                  type="button"
                >
                  {t<string>("SUBMITTED_APPLICATIONS.SAVE_FOR_USER")}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default UpdateSubmittedApplication;
