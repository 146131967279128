import { useState } from "react";
import classNames from "classnames";

import ConsumerLoanForm from "../ConsumerLoanForm";
import Leasing from "../Leasing";
import MergingLoans from "../MergingLoans";
import Mortgage from "../Mortgage";
import Refinancing from "../Refinancing";

import arrow from "../../../assets/photos/all/tabs-arrow.svg";
// import { tabs } from "../../../core/constants/tabs";
import QuickLoans from "../QuickLoans/QuickLoans";
import CreditCards from "../CreditCards/CreditCards";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../core/store";

const CalculatorTabs = () => {
  const [step, setStep] = useState(0);
  const [opener, setOpener] = useState(false);

  const { t } = useTranslation();
  const appLanguage = useAppSelector((state) => state.app.appLanguage);

  const selector = (tab: any) => {
    const elementIndex = tabs.indexOf(appLanguage === "en" ? tab.en : tab.bg);

    setStep(elementIndex);
    setOpener(false);
  };

  const tabs = [
    {
      en: t<string>("LOANS.CONSUMER_LOANS"),
      bg: t<string>("LOANS.CONSUMER_LOANS"),
    },
    {
      en: t<string>("LOANS.CREDIT_CARDS"),
      bg: t<string>("LOANS.CREDIT_CARDS"),
    },
    {
      en: t<string>("LOANS.QUICK_LOAN"),
      bg: t<string>("LOANS.QUICK_LOAN"),
    },
    {
      en: t<string>("LOANS.MORTGAGE_LOANS"),
      bg: t<string>("LOANS.MORTGAGE_LOANS"),
    },
    {
      en: t<string>("LOANS.CAR_LEASING"),
      bg: t<string>("LOANS.CAR_LEASING"),
    },
    {
      en: t<string>("LOANS.REFINANCING"),
      bg: t<string>("LOANS.REFINANCING"),
    },
    {
      en: t<string>("LOANS.MERGING_LOANS"),
      bg: t<string>("LOANS.MERGING_LOANS"),
    },
  ];

  return (
    <div className={classNames("calculator", appLanguage === "en" && "en")}>
      <div className="calculator-ads">
      </div>
      <div className="calculator__text">
        <h1 className="calculator__text-title">
          {t<string>("HOME.MONEY_HERE_TITLE")}
          <span className={appLanguage === "bg" ? "text-bg" : ""}>
            {t<string>("HOME.MONEY_HERE_TITLE_DESC")}
          </span>
        </h1>
      </div>
      <div className="calculator-wrapper">
        <div className="calculator__tabs">
          <ul className="calculator__tabs-list tabs-list">
            {tabs.map((tab, index) => (
              <li
                className={classNames({
                  "tabs-list__item": true,
                  "tabs-list__item-active": step === index,
                  "tabs-list__item-language-BG": appLanguage === "bg",
                })}
                onClick={() => setStep(index)}
                key={index}
              >
                {appLanguage === "en" ? tab.en : tab.bg}
              </li>
            ))}
          </ul>
          <div className="tabs-mobile">
            <div className="tabs-mobile__box">
              <div
                className="tabs-mobile__box-selected"
                onClick={() => setOpener(!opener)}
              >
                <p>{appLanguage === "en" ? tabs[step].en : tabs[step].bg}</p>
                <img src={arrow} alt="arrow" />
              </div>
              {opener && (
                <ul className="tabs-mobile__box-selector">
                  {tabs
                    // .map((tab) => tab !== tabs[step])
                    .map((tab, index) => (
                      <li
                        className="tabs-mobile__box-selector-item"
                        key={index}
                        onClick={() => {
                          selector(appLanguage === "en" ? tab.en : tab.bg);
                          if (step < index) {
                            setStep(index);
                          } else {
                            setStep(index);
                          }
                        }}
                      >
                        {appLanguage === "en" ? tab.en : tab.bg}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
          <div className="calculator__tabs-type">
            {step === 0 && <ConsumerLoanForm />}
            {step === 1 && <CreditCards />}
            {step === 2 && <QuickLoans />}
            {step === 3 && <Mortgage />}
            {step === 4 && <Leasing />}
            {step === 5 && <Refinancing />}
            {step === 6 && <MergingLoans />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorTabs;
