import classNames from "classnames";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import fileIcon from "../../../../assets/photos/all/add-file.svg";
import addedFile from "../../../../assets/photos/all/file-added.svg";

import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { Private, Public } from "../../../../core/models";
import PrivateService from "../../../../core/services/private.service";
import { useAppDispatch, useAppSelector } from "../../../../core/store/index";
import {
  setCerts,
  setCertsIds,
} from "../../../../core/store/reducers/documents/documentsSlice";
import {
  setModal,
  setModalType,
} from "../../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../../core/services/enums";
import { AppContext } from "../../../../App";

const DownloadCertificates = () => {
  const [questionnaire, setQuestionnaire] = useState<Private.Questionnaire>();

  const { selectedId } = useAppSelector((state) => state.app);

  const { certs, certsIds } = useAppSelector((state) => state.document);

  const appContext = useContext(AppContext);

  const { reloadChecker, setReloadChecker } = appContext;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useHttpGet<any>(`${APIRoutes.USER_QUESTIONNAIRES}/${selectedId}`, {
    resolve: (response) => {
      setQuestionnaire(response?.payload);
    },
  });

  const { fetchedData: certificates } = useHttpGet<any>(
    `${APIRoutes.USER_DOCUMENTS}`
  );

  useEffect(() => {
    updateCertificates();
  }, [certs]);

  const formikForm = useFormik<{
    certificates_1: File | Public.Document | string;
    certificates_2: File | Public.Document | string;
    certificates_3: File | Public.Document | string;
    certificates_4: File | Public.Document | string;
    certificates_5: File | Public.Document | string;
  }>({
    initialValues: {
      certificates_1: "",
      certificates_2: "",
      certificates_3: "",
      certificates_4: "",
      certificates_5: "",
    },
    onSubmit: (values: any) => {
      const result: any = certificateFilter({
        certificates_1: formikForm.values.certificates_1,
        certificates_2: formikForm.values.certificates_2,
        certificates_3: formikForm.values.certificates_3,
        certificates_4: formikForm.values.certificates_4,
        certificates_5: formikForm.values.certificates_5,
      });

      const privateCerts = certsIds.reduce(
        (a: any, v: any, index: number) => ({
          ...a,
          [`certificates_ids[${index}]`]: v,
        }),
        {}
      );
      handleAddCertificates({ ...result, ...privateCerts });
    },
  });

  const certificateFilter = (values: any) => {
    for (let value in values) {
      if (values[value].id !== undefined) {
        values[value] = "";
      }
    }

    return Object.values(values)
      .sort()
      .reverse()
      .reduce(
        (a: any, v: any, index: number) => ({
          ...a,
          [`certificates_${index + 1}`]: v,
        }),
        {}
      );
  };

  const updateCertificates = () => {
    for (let i = 0; i < 5; i++) {
      if (certs[i]) {
        formikForm.setFieldValue(`certificates_${i + 1}`, certs[i]);
      } else {
        formikForm.setFieldValue(`certificates_${i + 1}`, "");
      }
    }
  };

  const deleteCert = (index: number, cert: any) => {
    const newCertificates = certs.filter(
      (cert: any) => certs.indexOf(cert) !== index
    );

    const newCertificateIds = certsIds.filter((val: number) => val !== cert.id);

    dispatch(setCertsIds(newCertificateIds));

    dispatch(setCerts(newCertificates));
  };

  const similarNameChecker = (e: any) => {
    return certs.some((cert: File) => cert.name === e.target.files[0].name);
  };

  const handleAddCertificates = async (values: any) => {
    const newData: any = Object.assign({}, questionnaire);

    delete newData.id;
    delete newData.certificates;
    delete newData.created_at;
    delete newData.updated_at;

    try {
      const response = await PrivateService.updateUserQuestionnaire(
        {
          ...newData,
          ...values,
          politic: String(questionnaire?.politic),
          job: String(questionnaire?.job),
          agree: 1,
          informed: 1,
          update: 1,
          insolvency: 1,
        },
        Number(selectedId)
      );
      toast.success(response?.data?.message);
      dispatch(setModal(false));
      setReloadChecker(!reloadChecker);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
  };

  const certName = (str: string) => {
    let sliced = "";

    for (let i = 0; i < str.length; i += 40) {
      sliced += `${str.slice(i, i + 40)}\n`;
    }

    return sliced;
  };

  return (
    <div
      className={classNames({
        modal: true,
        "modal-active": true,
      })}
    >
      <div
        className="modal__bg"
        onClick={() => {
          dispatch(setModal(false));
          document.body.style.overflowY = "scroll";
        }}
      ></div>
      <div className="modal__container modal-details">
        <button
          className="modal-details__back"
          onClick={() =>
            dispatch(setModalType(AuthSelectionType.questionnairesList))
          }
        >
          {t<string>("WIDGET.BACK")}
        </button>
        <form
          className="about__form-box about-box about-box-cert"
          onSubmit={formikForm.handleSubmit}
        >
          {certificates?.payload?.collection.length > 0 && (
            <div
              className="about__form-files"
              onClick={() =>
                dispatch(
                  setModalType(AuthSelectionType.selectFromMyCertificates)
                )
              }
            >
              {t<string>("WIDGET.SELECT")}
            </div>
          )}
          {certs.map((cert: any, index: number) => (
            <div
              className="about-box__file-block file-block file-block-cert"
              key={index}
            >
              <div className="file-block-cert__box">
                <img className="file-block__icon" src={addedFile} alt="file" />
                {cert?.name.length > 40 ? (
                  <span className="file-block__txt">
                    {certName(cert?.name)}
                  </span>
                ) : (
                  <span className="file-block__txt">{cert?.name}</span>
                )}
              </div>
              <button
                className="file-block__txt"
                type="button"
                onClick={() => {
                  deleteCert(index, cert);
                }}
              >
                X
              </button>
            </div>
          ))}

          {certs.length < 5 && (
            <label className="about-box__file">
              <div className="about-box__file-block file-block file-block-cert">
                <div className="file-block-cert__box">
                  <img className="file-block__icon" src={fileIcon} alt="file" />
                  <span className="file-block__txt">
                    {t<string>("WIDGET.ADD_CERT")}
                  </span>
                </div>
              </div>

              <input
                type="file"
                accept=".doc, .docx, .pdf, .xlx, .csv, .zip, .png, .jpg, .jpeg, .gif, .svg"
                className="file-block__inp"
                onChange={(event: any) => {
                  if (
                    certs.length < 5 &&
                    event.target.files &&
                    !similarNameChecker(event)
                  ) {
                    similarNameChecker(event);
                    dispatch(setCerts([...certs, event.target.files[0]]));
                  }
                }}
              />
            </label>
          )}
          <button
            className={classNames({
              about__submit: true,
              "modal-details__btn": true,
              "about__submit-active": certs.length > 0,
            })}
            type="submit"
          >
            {t<string>("WIDGET.ADD")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DownloadCertificates;
