import excelIcon from "../../assets/photos/all/excel-icon.svg";
import $api from "../../core/http";
import './ButtonExcel.scss';
interface ButtonExcelProps {
	url: string
}
const ButtonExcel = ({url}:ButtonExcelProps) => {
	const handleClick = async () => {
		try {
			await $api.get(url, {
				responseType: "blob",
			}).then((response): any => {
				const urlUpload = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = urlUpload;
        link.setAttribute("download", 'admin data');
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
				});
			} catch (error: any) {
				console.error('Error: ', error.message);
			}
	}
	return <button
		className="button"
		onClick={handleClick}>
		Upload to Excel
		<img src={excelIcon} alt="Excel Icon" />
	</button>
}
export default ButtonExcel