import { FC, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import FacebookLogin from "react-facebook-login";
// import { GoogleLogin } from "@react-oauth/google";
import ReactGA from "react-ga4";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import classNames from "classnames";
// import jwtDecode from "jwt-decode";
import * as Yup from "yup";
// import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import AuthService from "../../../core/services/auth.service";
import { PrivateUIRoutes, UIRoutes } from "../../../core/router";
import CommonService from "../../../core/services/common.service";
// import { Auth } from "../../../core/models";
import { authentiticator } from "../../../core/services/firebase-config.service";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

// import passwordEye from "../../../assets/photos/all/password-eye.svg";
// import facebook from "../../../assets/photos/all/login-facebook.svg";
// import google from "../../../assets/photos/all/login-google.svg";
// import secured from "../../../assets/photos/all/secured.svg";
import { useAppDispatch, useAppSelector } from "../../../core/store";
import { setModal } from "../../../core/store/reducers/modal/modalSlice";
import { setIsAuth } from "../../../core/store/reducers/auth/authSlice";
import {
  filterPhoneNum,
  spacesRemover,
} from "../../../core/functions/funtions";
import { useTranslation } from "react-i18next";

const LoginForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [successPhone, setSuccessPhone] = useState(false);
  const [successOTP, setSuccessOTP] = useState(false);
  const [otpButton, setOtpButton] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loginRoute } = useAppSelector((state) => state.app);

  // const location = useLocation();

  const valueFormValidationSchema = Yup.object().shape({
    phone: Yup.string()
      .required("Fill in your phone")
      .min(12, "Min 12 symbols"),
    otp: Yup.string()
      .min(6, "Min 6 symbols")
      .required("Fill code received from SMS"),
    otpSuccess: Yup.string().required("Please, wait answer."),
  });

  const formikForm = useFormik<{
    phone: string;
    otp: string;
    otpSuccess: string;
  }>({
    initialValues: {
      phone: "",
      otp: "",
      otpSuccess: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  // const handleFacebookSignIn = async (values: any) => {
  //   try {
  //     const response = await AuthService.loginFacebook(
  //       values.accessToken,
  //       values.data_access_expiration_time,
  //       values.email,
  //       values.expiresIn,
  //       values.grantedScopes,
  //       values.graphDomain,
  //       values.id,
  //       values.name,
  //       JSON.stringify(values.picture),
  //       values.signedRequest,
  //       values.userID
  //     );

  //     localStorage.setItem(
  //       process.env.REACT_APP_TOKEN_KEY,
  //       response.data.payload.token
  //     );

  //     dispatch(setIsAuth(true));
  //     setLoading(false);
  //     if (!loginRoute) {
  //       response?.data?.payload.role === "admin" ||
  //       response?.data?.payload.role === "super_admin"
  //         ? navigate(`/${UIRoutes.ADMIN}/${PrivateUIRoutes.ADMIN_PANEL}`)
  //         : navigate(`/${UIRoutes.ACCOUNT}/${UIRoutes.PROFILE}`);
  //     } else {
  //       navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
  //     }

  //     ReactGA.event({
  //       category: "login",
  //       action:
  //         process.env.REACT_APP_ANALYTICS_NAME === ""
  //           ? "login"
  //           : `login_${process.env.REACT_APP_ANALYTICS_NAME}`,
  //     });

  //     localStorage.removeItem("prevPage");
  //     formikForm.resetForm();
  //     document.body.style.overflowY = "scroll";
  //     dispatch(setModal(false));
  //   } catch (errors: any) {
  //     setLoading(false);
  //     errors?.response?.data?.payload
  //       ? CommonService.showErrors(errors?.response?.data?.payload)
  //       : toast.error(errors?.response?.data?.message);
  //   }
  // };

  // const handleGoogleSignIn = async (values: any) => {
  //   setLoading(true);
  //   try {
  //     const encoded_values: Auth.GoogleLogin = jwtDecode(values.credential);
  //     const response = await AuthService.loginGoogle(encoded_values);
  //     localStorage.setItem(
  //       process.env.REACT_APP_TOKEN_KEY,
  //       response.data.payload.token
  //     );
  //     dispatch(setIsAuth(true));
  //     setLoading(false);
  //     if (!loginRoute) {
  //       response?.data?.payload.role === "admin" ||
  //       response?.data?.payload.role === "super_admin"
  //         ? navigate(`/${UIRoutes.ADMIN}/${PrivateUIRoutes.ADMIN_PANEL}`)
  //         : navigate(`/${UIRoutes.ACCOUNT}/${UIRoutes.PROFILE}`);
  //     } else {
  //       navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
  //     }

  //     ReactGA.event({
  //       category: "login",
  //       action:
  //         process.env.REACT_APP_ANALYTICS_NAME === ""
  //           ? "login"
  //           : `login_${process.env.REACT_APP_ANALYTICS_NAME}`,
  //     });

  //     localStorage.removeItem("prevPage");
  //     document.body.style.overflowY = "scroll";
  //     formikForm.resetForm();
  //     dispatch(setModal(false));
  //   } catch (errors: any) {
  //     setLoading(false);
  //     CommonService.showErrors(errors?.response?.data?.payload);
  //     toast.error(errors?.response?.data?.message);
  //   }
  // };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    try {
      const response = await AuthService.login(values.phone);

      localStorage.setItem(
        process.env.REACT_APP_TOKEN_KEY,
        response.data.payload.token
      );

      dispatch(setIsAuth(true));
      setLoading(false);
      document.body.style.overflowY = "scroll";
      dispatch(setModal(false));

      if (!loginRoute) {
        response?.data?.payload.role === "admin" ||
        response?.data?.payload.role === "super_admin"
          ? navigate(`/${UIRoutes.ADMIN}/${PrivateUIRoutes.ADMIN_PANEL}`)
          : navigate(`/${UIRoutes.ACCOUNT}/${UIRoutes.PROFILE}`);
      } else {
        navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
      }

      ReactGA.event({
        category: "login",
        action:
          process.env.REACT_APP_ANALYTICS_NAME === ""
            ? "login"
            : `login_${process.env.REACT_APP_ANALYTICS_NAME}`,
      });

      localStorage.removeItem("prevPage");
      formikForm.resetForm();
    } catch (errors: any) {
      setLoading(false);
      CommonService.showErrors(errors?.response?.data?.payload);
      toast.error(errors?.response?.data?.message);
    }
  };

  const dataValidation = (string: string) => {
    let filteredPhone = string.split(",").join().replace(/ /g, ""); //разобраться почему не проверяет правильное количество знаков
    return filteredPhone;
  };
  useEffect(() => {
    dataValidation(formikForm.values.phone).length >= 13
      ? setOtpButton(false)
      : setOtpButton(true);
  }, [formikForm.values.phone]);

  useEffect(() => {
    dataValidation(formikForm.values.otp).length === 6 && verifyOTP();
  }, [formikForm.values.otp]);

  const verifyOTP = () => {
    window.confirmationResult
      .confirm(dataValidation(formikForm.values.otp))
      .then((result: any) => {
        // User signed in successfully.
        const user = result.user;
        formikForm.setFieldValue("otpSuccess", user.accessToken);
        localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, user.accessToken);
        setSuccessOTP(true);
      })
      .catch((error: any) => {
        // User couldn't sign in (bad verification code?)
        // ...
        setSuccessOTP(false);
        toast.error(t<string>(error.message));
      });
  };

  const getRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      authentiticator,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      }
    );
  };

  const requestOTP = () => {
    setLoading(true);
    getRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(authentiticator, formikForm.values.phone, appVerifier)
      .then((confirmationResult) => {
        setLoading(false);
        setSuccessPhone(true);
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        setSuccessPhone(false);
        setLoading(false);
        // Error; SMS not sent
        // ...
        toast.error(t<string>(error.message));
      });
  };

  return (
    <section className="entry">
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          formikForm.handleSubmit(e);
        }}
        className="entry__auth"
      >
        <p className="auth__title">{t<string>("AUTH.LOGIN_TITLE")}</p>
        <div className="auth__fields">
          <div className="auth__fields-input data-input open">
            <label
              className={classNames({
                "data-input__details": true,
                // "data-input__details-touched":
                // formikForm.touched.phone && formikForm.errors.phone,
              })}
            >
              {t<string>("AUTH.PHONE")}
            </label>
            <div
              className={classNames(
                "data-input__number-field",
                successPhone && "success",
                loading && "active"
              )}
            >
              <InputMask
                name="phone"
                className="data-input__input-field"
                maskChar=""
                mask="+35\9 (999) 999-9999"
                placeholder="+359 (___) ___-___ "
                value={formikForm.values.phone}
                onChange={(event) => {
                  formikForm.handleChange(event);
                  formikForm.setFieldValue(
                    "phone",
                    filterPhoneNum(event.target.value)
                  );
                }}
              />
              <button
                type="button"
                className={classNames(
                  "data-input__number-submit",
                  loading && "active"
                )}
                onClick={requestOTP}
                disabled={otpButton}
              >
                <span>{t<string>("COMMON.SEND_CODE")}</span>
                <div className="lds-facebook">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
            </div>
            {/* вызов ошибок */}
            {formikForm.touched.phone && formikForm.errors.phone && (
              <div className="form-control-error-phone">
                {t<string>(formikForm.errors.phone)}
              </div>
            )}
          </div>
          <div className="auth__fields-input data-input">
            <label
              className={classNames({
                "data-input__details": true,
                // "data-input__details-touched":
                // formikForm.touched.phone && formikForm.errors.phone,
              })}
            >
              {t<string>("AUTH.OTP")}
            </label>
            <div
              className={classNames(
                "data-input__number-field",
                successOTP && "success"
              )}
            >
              <InputMask
                className="data-input__input-field"
                name="otp"
                mask="9 9 9 9 9 9"
                maskChar=" "
                placeholder="_ _ _  _ _ _"
                disabled={successPhone ? false : true}
                value={formikForm.values.otp}
                onChange={(event) => {
                  formikForm.handleChange(event);
                  formikForm.setFieldValue(
                    "otp",
                    spacesRemover(event.target.value)
                  );
                }}
              />
            </div>
            {/* вызов ошибок */}
            {formikForm.touched.otp && formikForm.errors.otp && (
              <div className="form-control-error-phone">
                {t<string>(formikForm.errors.otp)}
              </div>
            )}
            {/* {formikForm.touched.otpSuccess && formikForm.errors.otpSuccess && (
              <div className="form-control-error-phone">
                {t<string>(formikForm.errors.otpSuccess)}
              </div>
            )} */}
          </div>
        </div>
        <div className="auth__submit">
          <button className="auth__submit-btn" type="submit">
            {t<string>("AUTH.LOGIN_BUTTON")}
          </button>
        </div>
      </form>
      {/* <div className="entry__social social-login">
        <div className="social-login__decor">
          <div className="social-login__decor-line"></div>
          <span className="social-login__decor-txt">
            {t<string>("AUTH.OR")}
          </span>
          <span className="social-login__decor-txt social-login__decor-txt-mobile">
            {t<string>("AUTH.OR_LOGIN_WITH")}
          </span>
          <div className="social-login__decor-line"></div>
        </div>
        <div className="social-login__networks">
          <label className="social-login__networks-btn networks-btn">
            <div className="networks-btn__visible">
              <img
                className="networks-btn__visible-icon"
                src={facebook}
                alt="facebook"
              />
              <span className="networks-btn__visible-text">
                {t<string>("AUTH.LOGIN_FACEBOOK")}
              </span>
              <span className="networks-btn__visible-text-mobile">
                {t<string>("AUTH.FACEBOOK")}
              </span>
            </div>
            <div className="networks-btn__block">
              <button
                onClick={() => {
                  window.FB.login(
                    (res: any) => {
                      sessionStorage.setItem(
                        "accessToken",
                        JSON.stringify(res?.authResponse?.accessToken)
                      );
                      if (res.status === "connected") {
                        window.FB.api(
                          "/me?fields=id,email,name,picture",
                          function (data: any) {
                            handleFacebookSignIn({
                              ...res.authResponse,
                              ...data,
                              picture: data.picture,
                            });
                          }
                        );
                      }
                    },
                    { scope: "public_profile,email" }
                  );
                }}></button>
            </div>
          </label>
          <label
            className="social-login__networks-btn networks-btn"
            style={{
              position: "relative",
              minHeight: "40px",
            }}>
            <div
              className="networks-btn__visible"
              style={{
                position: "absolute",
                width: "100%",
              }}>
              <img
                className="networks-btn__visible-icon"
                src={google}
                alt="google"
              />
              <span className="networks-btn__visible-text">
                {t<string>("AUTH.LOGIN_GOOGLE")}
              </span>
              <span className="networks-btn__visible-text-mobile">
                {t<string>("AUTH.GOOGLE")}
              </span>
            </div>
            <div
              className="networks-btn__block"
              style={{
                position: "absolute",
                opacity: 0,
                zIndex: 10,
                display: "block",
              }}>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleGoogleSignIn(credentialResponse);
                }}
              />
            </div>
          </label>
        </div>
        <div className="entry__secured">
          <img className="entry__secured-icon" src={secured} alt="secured" />
          <span className="entry__secured-txt">
            {t<string>("AUTH.SAFE_SECURE")}
          </span>
        </div>
      </div> */}
    </section>
  );
};

export default LoginForm;
