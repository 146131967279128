import { useEffect, useState } from "react";
import classNames from "classnames";

import { useAppDispatch, useAppSelector } from "../../../../core/store/index";
import { credit_types } from "../../../../core/constants/creditTypes";
import { setModalType } from "../../../../core/store/reducers/modal/modalSlice";
import {
  AuthSelectionType,
  SubmittedApplicationStatus,
} from "../../../../core/services/enums";
import { setChoosenData } from "../../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";

const ShowApplicationModal = () => {
  const [application, setApplication] = useState<any>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { appLanguage, choosenData, questionnaire } = useAppSelector(
    (state) => state.app
  );

  useEffect(() => {
    if (choosenData) {
      setApplication((prev: any) => {
        return {
          ...prev,
          ...choosenData,
        };
      });
    }
  }, [choosenData]);

  const hideStatus =
    window.location.pathname.split("/").includes("results-list") ||
    window.location.pathname.split("/").includes("questionnaires");

  return (
    <div className="modal__container modal-show-offer">
      {window.location.pathname.split("/").includes("questionnaires") && (
        <button
          className="modal-show-offer__back"
          onClick={() => {
            dispatch(setChoosenData({ questionnaire }));
            dispatch(setModalType(AuthSelectionType.applyApplication));
          }}>
          {t<string>("SUBMITTED_APPLICATIONS.BACK_BUTTON")}
        </button>
      )}
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("SUBMITTED_APPLICATIONS.APPLICATION_INFO_TITLE")}
        </p>
        <div className="about about-applications">
          <div className="about__form about-applications__block">
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.BANK_NAME")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>
                    {appLanguage === "bg"
                      ? application?.bank?.translations?.bg.name
                      : appLanguage === "en"
                      ? application?.bank?.translations?.en.name
                      : "Bank"}
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.TYPE")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>
                    {application?.bankOfferTypes?.collection &&
                      credit_types[
                        application?.bankOfferTypes?.collection[0].id
                      ]}
                  </span>
                </div>
              </label>
            </div>
            {!hideStatus && (
              <div className="about__form-box about-box about-applications__block-item">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": true,
                    })}>
                    {t<string>("SUBMITTED_APPLICATIONS.STATUS")}
                  </span>
                  <div
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": true,
                    })}>
                    <span>{application?.status}</span>
                  </div>
                </label>
              </div>
            )}
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.CREDIT_TERM_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{application?.credit_term_from}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.CREDIT_TERM_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{application?.credit_term_to}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.INTEREST_RATE_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{application?.interest_rate_from}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.INTEREST_RATE_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{application?.interest_rate_to}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{application?.loan_amount_from}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-applications__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SUBMITTED_APPLICATIONS.LOAN_AMOUNT_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{application?.loan_amount_to}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowApplicationModal;
