import guide_first from "../../assets/photos/all/guide_first.svg";
import guide_second from "../../assets/photos/all/guide_second.svg";
import guide_third from "../../assets/photos/all/guide_third.svg";
import guide_hour from "../../assets/photos/all/guide_hour.svg";
import guide_arrow from "../../assets/photos/all/guide_arrow.svg";
import { useTranslation } from "react-i18next";

const Guide = () => {
  const { t } = useTranslation();

  return (
    <div className="guide">
      <div className="guide-top">
        <h1 className="guide-top-title">
          {t<string>("HOME.COMPARISONS_TITLE")}
        </h1>
        <span className="guide-top-subtitle">
          {t<string>("HOME.COMPARISONS_SUBTITLE")}
        </span>
      </div>
      <div className="guide-list">
        <div className="guide-list__card">
          <div className="guide-list__card-item card-item">
            <span className="card-item-count"></span>
            <img
              className="card-item-icon"
              src={guide_first}
              alt="Complete an application on asistent.bg"
            />
            <h4 className="card-item-title">
              {t<string>("HOME.COMPARISONS_1.heading")}
              <span className="card-item-title-lineOne">{t<string>("HOME.COMPARISONS_1.highlighted")}</span>
              {t<string>("HOME.COMPARISONS_1.text")}
            </h4>
          </div>
        </div>
        <div className="guide-arrow">
          <img className="guide-arrow" src={guide_arrow} alt="guide_arrow" />
        </div>

        <div className="guide-list__card">
          <div className="guide-list__card-item card-item">
            <span className="card-item-count"></span>
            <img
              className="card-item-icon"
              src={guide_second}
              alt="Compare and choose best offer on market!"
            />
            <h4 className="card-item-title">
              {t<string>("HOME.COMPARISONS_2")}
            </h4>
          </div>
        </div>
        <div className="guide-arrow">
          <img className="guide-arrow" src={guide_arrow} alt="guide_arrow" />
        </div>
        <div className="guide-list__card">
          <div className="guide-list__card-item card-item">
            <span className="card-item-count"></span>
            <img
              className="card-item-icon"
              src={guide_third}
              alt="Get decision in"
            />
            <div className="guide-list__card-title card-hour">
              <h4 className="card-item-title">
                {t<string>("HOME.COMPARISONS_3.heading")}
                <span className="card-item-title-lineThree">{t<string>("HOME.COMPARISONS_3.highlighted")}</span>
                {t<string>("HOME.COMPARISONS_3.text")}
              </h4>
              {/* <img className="hour-logo" src={guide_hour} alt="guide_hour" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guide;
