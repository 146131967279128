import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useAuth from "../../core/hooks/useAuth";

import card from "../../assets/photos/footer/black/card.svg";
import debts from "../../assets/photos/footer/black/debts.svg";
import refinancing from "../../assets/photos/footer/black/refinancing.svg";
import merging from "../../assets/photos/footer/black/merging.svg";
import car from "../../assets/photos/footer/black/car.svg";
import mortgage from "../../assets/photos/footer/black/mortgage.svg";
import close from "../../assets/photos/footer/nav-close.svg";
import { UIRoutes } from "../../core/router";
import { AppContext } from "../../App";

import { useAppDispatch, useAppSelector } from "../../core/store";
import {
  setMenu,
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../core/services/enums";
import { setLoginRoute } from "../../core/store/reducers/app/appDataSlice";
import PrivateService from "../../core/services/private.service";

const MobileMenu = () => {
  const [currentLng, setCurrentLng] = useState("en");
  const user = JSON.parse(localStorage.getItem("savedUser") || "{}");
  const { menu } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  const appContext = useContext(AppContext);
  const { setSelectedLoan, setSelectionType } = appContext;

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const { isAuth } = useAuth();

  const setLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    sessionStorage.setItem("lng", lng);
    setCurrentLng(lng || "bg");
    user && PrivateService.changeLocale(lng, user.id)
  };

  const authorizeSwitcher = () => {
    dispatch(setMenu(false));
    dispatch(setLoginRoute(false));

    if (!isAuth) {
      dispatch(setModalType(AuthSelectionType.Auth));
      setSelectionType(AuthSelectionType.Register);
      window.scrollTo(0, 0);
      dispatch(setModal(true));
      document.body.style.overflow = "hidden";
    } else {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
      dispatch(setModalType(AuthSelectionType.Logout));
      dispatch(setModal(true));
    }
  };

  useEffect(() => {
    const lng = sessionStorage.getItem("lng");

    setCurrentLng(lng || "bg");
  }, []);

  return (
    <div className="mobile-menu">
      <div className="mobile-menu__container">
        <div className="mobile-menu__container-general general-nav">
          <div className="general-nav__box">
            <h2 className="general-nav__box-title">
              {t<string>("COMMON.FINANCE")}
            </h2>
            <button className="general-nav__box-btn">
              {t<string>("COMMON.CHAT_WITH_ASISTENT")}
            </button>
          </div>
          <ul className="general-nav__links-note links-note">
            <li className="links-note__item">
              <Link
                to={`/${UIRoutes.CALCULATOR}?type=consumerloan`}
                className="links-note__item-link"
                onClick={() => {
                  setSelectedLoan(1);
                  dispatch(setMenu(false));
                }}
              >
                <img src={debts} alt={t<string>("LOANS.CONSUMER_LOANS")} />
                <span>{t<string>("LOANS.CONSUMER_LOANS")}</span>
              </Link>
            </li>
            <li className="links-note__item">
              <Link
                to={`/${UIRoutes.CALCULATOR}?type=carleasing`}
                className="links-note__item-link"
                onClick={() => {
                  setSelectedLoan(2);
                  dispatch(setMenu(false));
                }}
              >
                <img src={car} alt={t<string>("LOANS.CAR_LEASING")} />
                <span>{t<string>("LOANS.CAR_LEASING")}</span>
              </Link>
            </li>
            <li className="links-note__item">
              <Link
                to={`/${UIRoutes.CALCULATOR}?type=refinancing`}
                className="links-note__item-link"
                onClick={() => {
                  setSelectedLoan(3);
                  dispatch(setMenu(false));
                }}
              >
                <img src={refinancing} alt={t<string>("LOANS.REFINANCING")} />
                <span>{t<string>("LOANS.REFINANCING")}</span>
              </Link>
            </li>
            <li className="links-note__item">
              <Link
                to={`/${UIRoutes.CALCULATOR}?type=merging`}
                className="links-note__item-link"
                onClick={() => {
                  setSelectedLoan(4);
                  dispatch(setMenu(false));
                }}
              >
                <img src={merging} alt={t<string>("LOANS.MERGING_LOANS")} />
                <span>{t<string>("LOANS.MERGING_LOANS")}</span>
              </Link>
            </li>
            <li>
              <Link
                className="links-note__item-link"
                to={`/${UIRoutes.CALCULATOR}?type=mortgage`}
                onClick={() => {
                  setSelectedLoan(5);
                  dispatch(setMenu(false));
                }}
              >
                <img src={mortgage} alt={t<string>("LOANS.MORTGAGE_LOANS")} />
                <span>{t<string>("LOANS.MORTGAGE_LOANS")}</span>
              </Link>
            </li>
            <li className="links-note__item">
              <Link
                className="links-note__item-link"
                to={`/${UIRoutes.CALCULATOR}?type=quick`}
                onClick={() => {
                  setSelectedLoan(6);
                  dispatch(setMenu(false));
                }}
              >
                <img src={card} alt={t<string>("LOANS.QUICK_LOAN")} />
                <span>{t<string>("LOANS.QUICK_LOAN")}</span>
              </Link>
            </li>
            <li className="links-note__item">
              <Link
                to={`/${UIRoutes.CALCULATOR}?type=creditcards`}
                className="links-note__item-link"
                onClick={() => {
                  setSelectedLoan(7);
                  dispatch(setMenu(false));
                }}
              >
                <img src={card} alt={t<string>("LOANS.CREDIT_CARDS")} />
                <span>{t<string>("LOANS.CREDIT_CARDS")}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="mobile-menu__container-down-blocks down-blocks">
          <div className="down-blocks__block block-content">
            <div className="block-content__column">
              <h5 className="block-content__column-title">
                {t<string>("COMMON.ASISTENT")}
              </h5>
              <ul className="block-content__column-list column-list">
                <li className="column-list__link">
                  <button
                    className="column-list__link-item"
                    onClick={() => {
                      navigate(`/${UIRoutes.BANKS_RATING}`);
                      dispatch(setMenu(false));
                    }}
                  >
                    {t<string>("COMMON.RATING")}
                  </button>
                </li>
                <li className="column-list__link">
                  <button
                    className="column-list__link-item"
                    onClick={() => {
                      navigate(`/${UIRoutes.ABOUT_US}`);
                      dispatch(setMenu(false));
                    }}
                  >
                    {t<string>("COMMON.ABOUT_US")}
                  </button>
                </li>
                {/* <li className="column-list__link">
                  <button
                    className="column-list__link-item"
                    onClick={() => {
                      navigate(`/${UIRoutes.NEWS}`);
                      dispatch(setMenu(false));
                    }}
                  >
                    {t<string>("COMMON.NEWS")}
                  </button>
                </li> */}
                {/* <li className="column-list__link">
                  <button
                    className="column-list__link-item"
                    onClick={() => {
                      navigate(`/${UIRoutes.BLOG}`);
                      dispatch(setMenu(false));
                    }}
                  >
                    {t<string>("COMMON.BLOG")}
                  </button>
                </li> */}
                <li className="column-list__link">
                  <button
                    className="column-list__link-item"
                    onClick={() => {
                      navigate(`/${UIRoutes.FAQ}`);
                      dispatch(setMenu(false));
                    }}
                  >
                    {t<string>("COMMON.FAQ")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="down-blocks__block">
            <div className="down-blocks__block block-content">
              <div className="block-content__column">
                <h5 className="block-content__column-title">
                  {t<string>("COMMON.SOCIAL_LINKS")}
                </h5>
                <ul className="block-content__column-list column-list">
                  <li className="column-list__link">
                    <Link to="" className="column-list__link-item">
                      {t<string>("COMMON.TWITTER")}
                    </Link>
                  </li>
                  <li className="column-list__link">
                    <Link to="" className="column-list__link-item">
                      {t<string>("COMMON.FACEBOOK")}
                    </Link>
                  </li>
                  <li className="column-list__link">
                    <Link to="" className="column-list__link-item">
                      {t<string>("COMMON.INSTAGRAM")}
                    </Link>
                  </li>
                  <li className="column-list__link">
                    <Link to="" className="column-list__link-item">
                      {t<string>("COMMON.LINKEDIN")}
                    </Link>
                  </li>
                  <li className="column-list__link">
                    <Link to="" className="column-list__link-item">
                      {t<string>("COMMON.WHATSAPP")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-menu__container-functional nav-functional">
          <button className="nav-functional__btn" onClick={authorizeSwitcher}>
            {!isAuth ? (
              <span>{t<string>("COMMON.LOG_IN")}</span>
            ) : (
              <span>{t<string>("COMMON.LOG_OUT")}</span>
            )}
          </button>
          <div className="nav-functional__controls">
            <span className="nav-functional__controls-text">
              {t<string>("COMMON.SWITCH_LANGUAGE")}
            </span>
            <div className="nav-functional__controls-lang lang-selectors">
              <span
                className={classNames({
                  "lang-selectors__thing": true,
                  "lang-selectors__thing-active": currentLng === "bg",
                })}
                onClick={() => {
                  setLanguage("bg");
                }}
              >
                {t<string>("COMMON.BG")}
              </span>
              {t<string>("COMMON.TO")}
              <span
                className={classNames({
                  "lang-selectors__thing": true,
                  "lang-selectors__thing-active": currentLng === "en",
                })}
                onClick={() => {
                  setLanguage("en");
                }}
              >
                {t<string>("COMMON.EN")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-menu__close">
        <div
          className="mobile-menu__close-icon"
          onClick={() => dispatch(setMenu(false))}
        >
          <img src={close} alt="close" />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
