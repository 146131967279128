import { FC, useEffect, useState } from "react";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import useHttpGet from "../../../../core/hooks/useHttpGet";
import useQuery from "../../../../core/hooks/useQuery";
import { APIRoutes } from "../../../../core/http";
import { Private } from "../../../../core/models";
import { PrivateUIRoutes, UIRoutes } from "../../../../core/router";
import CommonService from "../../../../core/services/common.service";
import PrivateService from "../../../../core/services/private.service";

const BanksList: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastUpdatedBankId, setLastUpdatedBankId] = useState(0);
  const [sorter, setSorter] = useState<any>({ "order[name]": "asc" });
  const [filter, setFilter] = useState<any>({});
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });

  const navigate = useNavigate();

  const query = useQuery();

  useEffect(() => {
    setSorter(CommonService.getQueryByPropType("order", query));
    setFilter(CommonService.getQueryByPropType("filter", query));

    setPagination({
      cursor: query?.cursor || undefined,
      has_more_pages: query?.has_more_pages || undefined,
      per_page: query?.per_page || undefined,
    });
  }, []);

  const { fetchedData, loading: banksLoading } = useHttpGet<any>(
    APIRoutes.ADMIN_ENTITIES_BANK,
    {
      dependencies: [lastUpdatedBankId, sorter, filter, pagination],
      query: { ...sorter, ...filter, ...pagination },

      resolve: () => {
        navigate({
          pathname: "",
          search: `?${createSearchParams({
            ...sorter,
            ...filter,
            ...pagination,
          })}`,
        });
      },
    }
  );

  const deleteBank = async (id: number) => {
    try {
      const response = await PrivateService.deleteBank(id);
      setLastUpdatedBankId(id);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      setLoading(false);

      toast.error(errors?.response?.data?.message);
    }
  };

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};

    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ ...filter, [propName]: value || undefined });
  };

  const setPaginationCursor = (cursor: string) => {
    setPagination({ ...pagination, cursor });
  };

  const setPaginationPage = (per_page: string) => {
    setPagination({ ...pagination, per_page });
  };

  return (
    <>
      {(banksLoading || loading) && <span>Loading...</span>}
      <input
        type="text"
        name="name"
        value={filter["filter[name][like]"]}
        placeholder="Shearch by name"
        onChange={(e) => {
          setFilterProperty("filter[name][like]", e.target.value);
        }}
      />
      <input
        type="text"
        name="name"
        value={filter["filter[description][like]"]}
        placeholder="Shearch by description"
        onChange={(e) => {
          setFilterProperty("filter[description][like]", e.target.value);
        }}
      />
      <table>
        <thead>
          <tr>
            <th>Logo</th>
            <th
              onClick={() => {
                setSorterProperty("order[name]");
              }}
            >
              Name ⭥
            </th>
            <th
              onClick={() => {
                setSorterProperty("order[rating]");
              }}
            >
              Rating ⭥
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {fetchedData?.payload?.collection?.map((el: Private.GetBank) => (
            <tr key={el.id}>
              <td>
                <img
                  src={el.logo_path + el.logo_name}
                  alt={el.logo_name}
                  style={{ width: "20px", height: "20px" }}
                />
              </td>
              <td>{el.name}</td>
              <td>{el.rating}</td>
              <td>
                <Link
                  to={`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.BANKS}/${el.id}`}
                >
                  Edit
                </Link>
                <button onClick={() => deleteBank(el.id)}>Delete</button>
              </td>
            </tr>
          ))}
          {!fetchedData?.payload?.collection && (
            <tr>
              <td>No data</td>
            </tr>
          )}
        </tbody>
      </table>
      <div>
        <select
          value={pagination.per_page}
          onChange={(e) => {
            setPaginationPage(e.target.value);
          }}
        >
          <option value={10}>10</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
        <button
          onClick={() => {
            setPaginationCursor("[prev_cursor]");
          }}
        >
          prev
        </button>
        <button
          onClick={() => {
            setPaginationCursor("[next_cursor]");
          }}
        >
          next
        </button>
      </div>
    </>
  );
};

export default BanksList;
