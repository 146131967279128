import { useState, useContext } from "react";
import { BiShow } from "react-icons/bi";
import { GrEdit } from "react-icons/gr";

import { AppContext } from "../../App";

import useHttpGet from "../../core/hooks/useHttpGet";
import { APIRoutes } from "../../core/http";

import search from "../../assets/photos/profile/search.svg";
import sortingArrow from "../../assets/photos/all/sorting-arrow.svg";
import { useAppDispatch } from "../../core/store";
import {
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";
import {
  AuthSelectionType,
  SubmittedApplicationStatus,
} from "../../core/services/enums";
import {
  setChoosenData,
  setSelectedId,
} from "../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";
import ButtonExcel from "../ButtonExcel/ButtonExcel";
import classNames from "classnames";

const AdminSubmittedApplications = () => {
  const [filterValue, setFilterValue] = useState("");
  const [filterType, setFilterType] = useState("filter[search]");
  const [filter, setFilter] = useState<any>({});
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);

  const { reloadChecker } = appContext;

  const { fetchedData } = useHttpGet<any>(
    `${APIRoutes.ADMIN_USERS_SUBMITTED_APPLICATIONS}/index_all`,

    {
      dependencies: [reloadChecker, pagination, sorter, filter],
      query: { ...sorter, ...filter, ...pagination },
    }
  );

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};

    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ [propName]: value || undefined });
  };

  const setPaginationPage = () => {
    setPagination(() => {
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 };
      } else {
        return { ...pagination, per_page: 50 };
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const showBankApplication = (application: any) => {
    dispatch(setSelectedId(application.id));
    dispatch(setChoosenData(application));
    dispatch(setModalType(AuthSelectionType.showSubmittedApplication));
    dispatch(setModal(true));
  };

  const updateUserApplication = (application: any) => {
    dispatch(setSelectedId(application.id));
    dispatch(setModalType(AuthSelectionType.updateSubmittedApplication));
    dispatch(setModal(true));
  };

  const addStatusClassname = (status: string) => {
    switch (status) {
      case "accepted_by_client":
        return "client-approved";

      case "rejected_by_client":
        return "client-rejected";

      case "no_response_from_client":
        return "client-noresponse";

      default:
        return "";
    }
  };

  return (
    <div className="admin">
      <h2 className="admin-table__name">
        {t<string>("SUBMITTED_APPLICATIONS.TITLE")}
      </h2>
      <ButtonExcel url="admin/submitted_applications_export" />
      <div className="admin-container">
        <div className="profile-table">
          <div className="admin-search">
            <input
              disabled={!filterType}
              className="admin-search__inp"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
              onKeyPress={(e: any) => {
                if (e.key === "Enter" && filterType) {
                  setFilterProperty(filterType, filterValue);
                }
              }}
            />
            <select
              onChange={(e) => {
                e.target.value === "search"
                  ? setFilterType(`filter[${e.target.value}]`)
                  : setFilterType(`filter[${e.target.value}][like]`);
              }}
              className="admin-search__dropdown"
            >
              <option value="search">
                {t<string>("SUBMITTED_APPLICATIONS.SEARCH_ALL")}
              </option>
              <option value={"user_id"}>
                {t<string>("SUBMITTED_APPLICATIONS.USER_ID")}
              </option>
              <option value={"questionnaire_id"}>
                {t<string>("SUBMITTED_APPLICATIONS.QUESTIONNAIRE_ID")}
              </option>
            </select>
            <button
              className="admin-search__btn"
              onClick={() => {
                if (filterType) {
                  setFilterProperty(filterType, filterValue);
                }
              }}
            >
              <img src={search} alt="search" />
            </button>
          </div>
          <div className="users-container">
            <table className="users-table" cellSpacing="0" cellPadding="0">
              <thead className="users-table-head">
                <tr className="users-table-head-row">
                  <td className="users-table-head__item">
                    {t<string>("SUBMITTED_APPLICATIONS.USER_ID")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[user_id]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.FIRST_NAME")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.LAST_NAME")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.CODE")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("SUBMITTED_APPLICATIONS.STATUS")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[status]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("SUBMITTED_APPLICATIONS.APPLICATION_TITLE")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("SUBMITTED_APPLICATIONS.QUESTIONNAIRE_ID_TITLE")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[questionnaire_id]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("SUBMITTED_APPLICATIONS.VERIFICATION_TITLE")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("SUBMITTED_APPLICATIONS.UPDATED")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("SUBMITTED_APPLICATIONS.CREATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[created_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("SUBMITTED_APPLICATIONS.UPDATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[updated_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                </tr>
              </thead>
              <tbody className="users-table-content">
                {fetchedData?.payload?.data.map((application: any) => {
                  return (
                    <tr
                      className={classNames(
                        "users-table-row",
                        addStatusClassname(application.status)
                      )}
                      key={application.id}
                    >
                      <td className="users-table-row__item">
                        {application.user_id}
                      </td>
                      <td className="users-table-row__item">{application.user.first_name}</td>
                      <td className="users-table-row__item">{application.user.last_name}</td>
                      {application.code && (<td className="users-table-row__item">{application.code}</td>)}
                      
                      <td className="users-table-row__item">
                        {application.status ===
                          SubmittedApplicationStatus.open && (
                          <span>{t<string>("STATUSES.OPEN")}</span>
                        )}
                        {application.status ===
                          SubmittedApplicationStatus.inProgress && (
                          <span>{t<string>("STATUSES.IN_PROGRESS")}</span>
                        )}
                        {application.status ===
                          SubmittedApplicationStatus.sendToPartner && (
                          <span>{t<string>("STATUSES.SEND_TO_PARTNER")}</span>
                        )}
                        {application.status ===
                          SubmittedApplicationStatus.approved && (
                          <span>
                            {t<string>("STATUSES.PARTNER_RESPONSE_APPROVED")}
                          </span>
                        )}
                        {application.status ===
                          SubmittedApplicationStatus.declined && (
                          <span>
                            {t<string>("STATUSES.PARTNER_RESPONSE_DECLINED")}
                          </span>
                        )}
                        {application.status ===
                          SubmittedApplicationStatus.counterOffer && (
                          <span>
                            {t<string>(
                              "STATUSES.PARTNER_RESPONSE_COUNTER-OFFER"
                            )}
                          </span>
                        )}
                        {application.status ===
                          SubmittedApplicationStatus.acceptedByClient && (
                          <span>
                            {t<string>("STATUSES.ACCEPTED_BY_CLIENT")}
                          </span>
                        )}
                        {application.status ===
                          SubmittedApplicationStatus.rejectedByClient && (
                          <span>
                            {t<string>("STATUSES.REJECTED_BY_CLIENT")}
                          </span>
                        )}
                        {application.status ===
                          SubmittedApplicationStatus.noResponseFromClient && (
                          <span>
                            {t<string>("STATUSES.NO_RESPONSE_FROM_CLIENT")}
                          </span>
                        )}
                      </td>
                      <td className="users-table-row__item">
                        <button
                          onClick={() => showBankApplication(application)}
                          className="users-table-action__btn"
                          title={t<string>("SUBMITTED_APPLICATIONS.SHOW")}
                        >
                          <BiShow />
                        </button>
                      </td>
                      <td className="users-table-row__item">
                        {application.questionnaire_id}
                      </td>
                      <td className="users-table-row__item">
                        {application?.user?.verified === 1
                          ? `${t<string>("SUBMITTED_APPLICATIONS.YES")}`
                          : `${t<string>("SUBMITTED_APPLICATIONS.NO")}`}
                      </td>
                      <td className="users-table-row__item">
                        <GrEdit
                          className="users-table-head__item__edit-offer"
                          onClick={() => updateUserApplication(application)}
                          title={t<string>("SUBMITTED_APPLICATIONS.UPDATE")}
                        />
                      </td>
                      <td className="users-table-row__item">
                        <span>{`${new Date(
                          application?.created_at
                        ).toLocaleTimeString()}`}</span>
                        <br></br>
                        {`${application?.created_at.slice(
                          8,
                          10
                        )}.${application?.created_at.slice(
                          5,
                          7
                        )}.${application?.created_at.slice(0, 4)}`}
                      </td>
                      <td className="users-table-row__item">
                        <span>{`${new Date(
                          application?.updated_at
                        ).toLocaleTimeString()}`}</span>
                        <br></br>
                        {`${application?.updated_at.slice(
                          8,
                          10
                        )}.${application?.updated_at.slice(
                          5,
                          7
                        )}.${application?.updated_at.slice(0, 4)}`}
                      </td>
                      <td className="users-table-row__item"></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {fetchedData?.payload?.data.length >= 10 && (
            <button
            className="profile-table__btn"
            onClick={() => {
              setPaginationPage();
            }}
          >
            {t<string>("SUBMITTED_APPLICATIONS.SHOW_MORE")}
          </button>
          )} 
          {/* {fetchedData?.payload?.cursor.has_more_pages ? (
            <button
              className="profile-table__btn"
              onClick={() => {
                setPaginationPage();
              }}
            >
              {t<string>("SUBMITTED_APPLICATIONS.SHOW_MORE")}
            </button>
          ) : (
            <button
              onClick={setDefaultPagination}
              className="profile-table__btn"
            >
              {t<string>("SUBMITTED_APPLICATIONS.HIDE_ALL")}
            </button>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AdminSubmittedApplications;
