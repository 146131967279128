import { useContext, useState } from "react";
import classNames from "classnames";

import { useAppSelector } from "../../../../core/store";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { useTranslation } from "react-i18next";

const ShowAdminBankOfferModal = () => {
  const [bankOffer, setBankOffer] = useState<any>();
  const { t } = useTranslation();

  const { selectedId, appLanguage } = useAppSelector((state) => state.app);

  useHttpGet<any>(
    `${APIRoutes.ADMIN_USERS_SUBMITTED_APPLICATIONS}/${selectedId}`,
    {
      dependencies: [selectedId],
      resolve: (res) => {
        setBankOffer(res?.payload);
      },
    }
  );

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("SUBMITTED_APPLICATIONS.DETAILS")}
        </p>
        <div className="about about-offers">
          <div className="about__form about-offers__block">
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.BANK_NAME")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {appLanguage === "bg"
                      ? bankOffer?.bank_offer?.bank?.translations?.bg.name
                      : bankOffer?.bank_offer?.bank?.translations?.en.name}
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.OFFER_TYPE")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {appLanguage === "bg"
                      ? bankOffer.bank_offer?.bankOfferTypes?.collection[0]
                          ?.translations?.bg.name
                      : bankOffer.bank_offer?.bankOfferTypes?.collection[0]
                          ?.translations?.en.name}
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.STATUS")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{bankOffer?.status}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.CREDIT_TERM_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{bankOffer?.bank_offer.credit_term_from}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.CREDIT_TERM_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{bankOffer?.bank_offer.credit_term_to}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.INTEREST_RATE_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{bankOffer?.bank_offer.interest_rate_from}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.INTEREST_RATE_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{bankOffer?.bank_offer.interest_rate_to}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("CALCULATOR.LOAN_AMOUNT_FROM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{bankOffer?.bank_offer.loan_amount_from}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("CALCULATOR.LOAN_AMOUNT_TO")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{bankOffer?.bank_offer.loan_amount_to}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box about-offers__block-item">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SUBMITTED_APPLICATIONS.SHOW_ON_PUBLIC")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {bankOffer?.show_on_public === 1
                      ? `${t<string>("SUBMITTED_APPLICATIONS.YES")}`
                      : `${t<string>("SUBMITTED_APPLICATIONS.NO")}`}
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowAdminBankOfferModal;
