import classNames from "classnames";
import { useContext, useState } from "react";

import { AppContext } from "../../../../App";
import { AuthSelectionType } from "../../../../core/services/enums";
import LoginContainer from "../../../Login/LoginContainer";
import RegisterContainer from "../../../Register";
import RegisterConfirmation from "../../../RegisterConfirmation";
import { useTranslation } from "react-i18next";

const UserAuthorizationModal = () => {
  const [currentEmail, setCurrentEmail] = useState("");
  const {t} = useTranslation()
  const appContext = useContext(AppContext);

  const selectType = (name: AuthSelectionType) => {
    setSelectionType(name);
  };
  const { selectionType, setSelectionType } = appContext;

  return (
    <div className="modal__container modal-details">
      <div className="modal-details__theme">
        <div
          className={classNames({
            "modal-details__theme-selector": true,
            "theme-selector": true,
            "theme-selector-active":
              selectionType === AuthSelectionType.Register ||
              selectionType === AuthSelectionType.Confirm,
          })}
          onClick={() => selectType(AuthSelectionType.Register)}
        >
          <p className="theme-selector__text">{t<string>("COMMON.CREATE_ACCOUNT")}</p>
        </div>
        <div
          className={classNames({
            "modal-details__theme-selector": true,
            "theme-selector": true,
            "theme-selector-active": selectionType === AuthSelectionType.Login,
          })}
          onClick={() => selectType(AuthSelectionType.Login)}
        >
          <p className="theme-selector__text">{t<string>("COMMON.LOG_IN")}</p>
        </div>
      </div>
      <div className="modal-details__types">
        {selectionType === "login" && <LoginContainer />}
        {selectionType === "register" && (
          <RegisterContainer
            setSelectionType={setSelectionType}
            setCurrentEmail={setCurrentEmail}
          />
        )}
        {/* {selectionType === "confirm" && (
          <RegisterConfirmation
            setSelectionType={setSelectionType}
            currentEmail={currentEmail}
          />
        )} */}
      </div>
    </div>
  );
};

export default UserAuthorizationModal;
