import { useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";

import fileIcon from "../../../assets/photos/all/add-file.svg";
import { AppContext } from "../../../App";
import PrivateService from "../../../core/services/private.service";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../core/store";
import { setModal } from "../../../core/store/reducers/modal/modalSlice";

const AddNewBank = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState<any>();
  const [avatarChecker, setAvatarChecker] = useState<string>("");

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const appContext = useContext(AppContext);
  const { setReloadChecker, reloadChecker } = appContext;

  const valueFormValidationSchema = Yup.object().shape({
    status: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    "description:en": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(1000, "Too many characters"),
    "name:en": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(120, "Too many characters"),
    "description:bg": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(1000, "Too many characters"),
    "name:bg": Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(120, "Too many characters"),
    phone: Yup.string().required(t<string>("COMMON.IS_REQUIRED")),
    "address:bg": Yup.string()
      .required(t<string>("COMMON.IS_REQUIRED"))
      .max(120, "Too many characters"),
    "address:en": Yup.string()
      .required(t<string>("COMMON.IS_REQUIRED"))
      .max(120, "Too many characters"),
    link: Yup.string()
      .required(t<string>("COMMON.IS_REQUIRED"))
      .max(120, "Too many characters"),
  });

  const formikForm = useFormik<{
    id: number;
    logo: any;
    show_on_public: string | number;
    status: string;
    "description:en": string;
    "name:en": string;
    "description:bg": string;
    "name:bg": string;
    phone: string;
    "address:bg": string;
    "address:en": string;
    link: string;
  }>({
    initialValues: {
      id: 0,
      logo: "",
      show_on_public: 0,
      status: "",
      "description:en": "",
      "name:en": "",
      "description:bg": "",
      "name:bg": "",
      phone: "",
      "address:bg": "",
      "address:en": "",
      link: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const pictureLoader = (e: any) => {
    setCropper(undefined);

    e.preventDefault();

    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result as any);
    };

    reader.readAsDataURL(files[0]);
  };

  const urltoFile = (url: string, filename: string, mimeType: string) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      formikForm.setFieldValue("logo", cropper.getCroppedCanvas().toDataURL());
      setImage(undefined);
    }
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    let logo;

    if (values.logo !== avatarChecker) {
      logo = await urltoFile(
        values.logo,
        `photo${Math.random() * 10000}.png`,
        "image/png"
      );
    } else {
      logo = "";
    }

    try {
      const response = await PrivateService.addAdminNewBank({
        ...values,
        logo,
      });

      setReloadChecker(!reloadChecker);
      setLoading(false);
      toast.success(response?.data?.message);
      dispatch(setModal(false));
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
      setLoading(false);
      dispatch(setModal(false));
    }
  };

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">Add new FI</p>
        <form className="about" onSubmit={formikForm.handleSubmit}>
          <div className="about__form-avatar">
            <div className="about__form-box about-box">
              {image ? (
                <>
                  {image && (
                    <div className="about-box__cropper">
                      <Cropper
                        className="about-box__cropper-item"
                        style={{
                          height: 250,
                          width: 250,
                        }}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={200}
                        minCropBoxWidth={200}
                        cropBoxResizable={false}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />
                      <button
                        className="about-box__cropper-btn"
                        onClick={getCropData}
                      >
                        Crop
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {formikForm.values.logo ? (
                    <div className="about-box__avatar">
                      <div className="about-box__avatar-bank">
                        <img
                          className="about-box__avatar-bank-own"
                          src={formikForm.values.logo}
                          alt="avatar"
                        />
                      </div>
                      <label className="about-box__file">
                        <div className="about-box__file-block file-block">
                          <img
                            className="file-block__icon"
                            src={fileIcon}
                            alt="file"
                          />
                          <span className="file-block__txt">
                            Change FI logo
                          </span>
                        </div>
                        <input
                          className="file-block__inp"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files) {
                              pictureLoader(e);
                            }
                          }}
                          accept="image/jpg, image/jpeg, image/png, image/gif"
                        />
                      </label>
                    </div>
                  ) : (
                    <label className="about-box__file">
                      <div className="about-box__file-block file-block">
                        <img
                          className="file-block__icon"
                          src={fileIcon}
                          alt="file"
                        />
                        <span className="file-block__txt">Add FI logo</span>
                      </div>
                      <input
                        type="file"
                        accept=" .png, .jpg, .jpeg, .gif"
                        className="file-block__inp"
                        onChange={(e) => {
                          if (e.target.files) {
                            pictureLoader(e);
                          }
                        }}
                      />
                    </label>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["name:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["name:en"] &&
                      formikForm.errors["name:en"],
                  })}
                >
                  Name En
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["name:en"] &&
                      formikForm.errors["name:en"],
                    "about-box__field-inp-filled": formikForm.values["name:en"],
                  })}
                  placeholder="Name en"
                  {...formikForm.getFieldProps(`${["name:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["name:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["name:bg"] &&
                      formikForm.errors["name:bg"],
                  })}
                >
                  Name Bg
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["name:bg"] &&
                      formikForm.errors["name:bg"],
                    "about-box__field-inp-filled": formikForm.values["name:bg"],
                  })}
                  placeholder="Name bg"
                  {...formikForm.getFieldProps(`${["name:bg"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["phone"],
                    "about-box__field-inp-error":
                      formikForm.touched["phone"] &&
                      formikForm.errors["phone"],
                  })}
                >
                  Phone Number
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["phone"] &&
                      formikForm.errors["phone"],
                    "about-box__field-inp-filled":
                      formikForm.values["phone"],
                  })}
                  placeholder="+359"
                  {...formikForm.getFieldProps(`${["phone"]}`)}
                />
              </label>
            </div>
            {/* adress:en */}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["address:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["address:en"] &&
                      formikForm.errors["address:en"],
                  })}
                >
                  Address EN
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["address:en"] &&
                      formikForm.errors["address:en"],
                    "about-box__field-inp-filled":
                      formikForm.values["address:en"],
                  })}
                  placeholder="Address"
                  {...formikForm.getFieldProps(`${["address:en"]}`)}
                />
              </label>
            </div>
            {/* adress:bg */}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["address:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["address:bg"] &&
                      formikForm.errors["address:bg"],
                  })}
                >
                  Address BG
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["address:bg"] &&
                      formikForm.errors["address:bg"],
                    "about-box__field-inp-filled":
                      formikForm.values["address:bg"],
                  })}
                  placeholder="Address"
                  {...formikForm.getFieldProps(`${["address:bg"]}`)}
                />
              </label>
            </div>
            {/* website */}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values["link"],
                    "about-box__field-inp-error":
                      formikForm.touched["link"] && formikForm.errors["link"],
                  })}
                >
                  Website
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["link"] && formikForm.errors["link"],
                    "about-box__field-inp-filled": formikForm.values["link"],
                  })}
                  placeholder="Website"
                  {...formikForm.getFieldProps(`${["link"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["description:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["description:en"] &&
                      formikForm.errors["description:en"],
                  })}
                >
                  Description En
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["description:en"] &&
                      formikForm.errors["description:en"],
                    "about-box__field-inp-filled":
                      formikForm.values["description:en"],
                  })}
                  placeholder="description:en"
                  {...formikForm.getFieldProps(`${["description:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["description:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["description:bg"] &&
                      formikForm.errors["description:bg"],
                  })}
                >
                  Description bg
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["description:bg"] &&
                      formikForm.errors["description:bg"],
                    "about-box__field-inp-filled":
                      formikForm.values["description:bg"],
                  })}
                  placeholder="description:bg"
                  {...formikForm.getFieldProps(`${["description:bg"]}`)}
                />
              </label>
            </div>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.status && formikForm.errors.status,
                })}
              >
                Choose Status
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.status,
                  "about-box__field-inp-touched":
                    formikForm.touched.status && formikForm.errors.status,
                })}
                value={formikForm.values.status}
                onChange={(e) =>
                  formikForm.setFieldValue("status", e.target.value)
                }
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="enabled">Enabled</option>
                <option value="disabled">Disabled</option>
              </select>
            </label>
          </div>
          <button
            className={classNames({
              about__submit: true,
              "about__submit-active": formikForm.isValid,
            })}
            type="submit"
          >
            Add FI
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddNewBank;
