import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal } from "./modalType";

const state: Modal = {
  showNotification: false,
  modal: false,
  modalType: "",
  menu: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState: state,
  reducers: {
    setModal(state, action: PayloadAction<boolean>) {
      state.modal = action.payload;
    },
    setModalType(state, action: PayloadAction<string>) {
      state.modalType = action.payload;
    },
    setMenu(state, action: PayloadAction<boolean>) {
      state.menu = action.payload;
    },
    setShowNotification(state, action: PayloadAction<boolean>) {
      state.showNotification = action.payload;
    },
  },
});

export default modalSlice.reducer;
export const { setModal, setModalType, setMenu, setShowNotification } =
  modalSlice.actions;
