import { FC } from "react";
import YourSvg from '../../assets/photos/all/icon-cookie.svg';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CookiePopUpProps {
  setState: Function;
}

const CookiePopUp: FC<CookiePopUpProps> = ({ setState }) => {

  const cookieAccept = () => {
    setState('accept')
    if (!localStorage.getItem('cookies')) {
        localStorage.setItem('cookies', 'accept')
    }
  }
  const {t} = useTranslation()
  return (
    <div className="cookiePopUp">
      <div className="cookiePopUp__info">
        <img src={YourSvg} alt="cookie" />
        <p>
          {t<string>("COMMON.POP_UP")} {t<string>("COOKIE_POLICY.TITLE")}.
        </p>
      </div>
      <div className="cookiePopUp__btn">
        {/* <Link to={'/privacy-policy'} className="cookiePopUp__btn-left">{t<string>("COMMON.READ_MORE")}</Link> */}
        <button className="cookiePopUp__btn-right" onClick={cookieAccept}>{t<string>("COMMON.I_AGREE")}</button>
      </div>
    </div>
  );
};

export default CookiePopUp;
