import { useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ReactGA from "react-ga4";

import auth_logo from "../../../assets/photos/all/auth-modal-logo.svg";
import CommonService from "../../../core/services/common.service";
import PublicService from "../../../core/services/public.service";
import { useTranslation } from "react-i18next";

const iphone_logo = require("../../../assets/photos/all/iphone.png");
const satellite_img = require("../../../assets/photos/all/satellite.png");
const money_logo = require("../../../assets/photos/all/money.png");

const FeedbackAnnounce = () => {
  const [sent, setSent] = useState(true);
  const [ratingValue, setRatingValue] = useState(0);

  const { t } = useTranslation();

  const valueFormValidationSchema = Yup.object().shape({
    full_name: Yup.string()
      .required("Въведете пълно име")
      .max(121, "Максимум 120 знака"),
    email: Yup.string().email("Invalid email").required("Въведете e-mail"),
    rating: Yup.number().notOneOf([0], "Въведете рейтинг"),
    comment: Yup.string()
      .required("Въведете отзив")
      .max(720, "Максимум 720 знака"),
    bank_name: Yup.string().required("Bank must be selected"),
  });

  const formikForm = useFormik<{
    type: string;
    full_name: string;
    email: string;
    rating: number;
    comment: string;
    bank_name: string;
  }>({
    initialValues: {
      type: "review",
      full_name: "",
      email: "",
      rating: 0,
      comment: "",
      bank_name: "",
    },

    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleRating = (val: number) => {
    setRatingValue(val);

    switch (val) {
      case 1:
        formikForm.setFieldValue("rating", 1);
        break;

      case 2:
        formikForm.setFieldValue("rating", 2);
        break;

      case 3:
        formikForm.setFieldValue("rating", 3);
        break;

      case 4:
        formikForm.setFieldValue("rating", 4);
        break;

      case 5:
        formikForm.setFieldValue("rating", 5);
        break;

      default:
        throw new Error("error!");
    }
  };

  const handleSubmitForm = async (values: any) => {
    try {
      const response = await PublicService.launchBanners(values);

      formikForm.resetForm();

      setRatingValue(0);
      toast.success(response?.data?.message);
      ReactGA.event({
        category: `feedback_banner ${process.env.REACT_APP_ANALYTICS_NAME}`,
        action: "feedback_banner_submit",
      });
    } catch (errors: any) {
      CommonService.showErrors(errors?.response?.data?.payload);
      toast.error(errors?.response?.data?.message);
    }
    setSent(!sent);
  };

  return (
    <div className="slide-step">
      <div className="slide-step-main">
        <img
          className="slide-step-main__logo"
          src={auth_logo}
          alt="modal logo"
        />
        <h1 className="slide-step-main__title">
          {t<string>("PREAUTH_COMMENT.TITLE")}
        </h1>
        <div className="slide-step-main-subtitle">
          <img
            className="slide-step-main-subtitle__logo"
            src={satellite_img}
            alt="satellite"
          />
          <p className="slide-step-main-subtitle__txt">
            {t<string>("PREAUTH_COMMENT.DESCRIPTION")}
          </p>
        </div>
        <p className="slide-step-main__copyrights">
          {t<string>("PREAUTH_COMMENT.DETAILS")}
        </p>
      </div>

      <div className="slide-step-aside">
        <img
          src={money_logo}
          alt="money-logo"
          className="slide-step-aside__secondary"
        />
        <img
          className="slide-step-aside__logo"
          src={iphone_logo}
          alt="iphone"
        />
      </div>
      <form
        className="slide-step-form-third"
        onSubmit={formikForm.handleSubmit}
      >
        <div
          className={classNames({
            "about__form-box about-box": true,
            "feedback-inp": true,
          })}
        >
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-preauth-name": true,
                "about-box__field-inp-error":
                  formikForm.touched.full_name && formikForm.errors.full_name,
                "about-box__field-name-filled": formikForm.values.full_name,
              })}
            >
              {t<string>("PREAUTH_COMMENT.FULL_NAME")}
            </span>
            <input
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-preauth": true,
                "about-box__field-inp-touched":
                  formikForm.touched.full_name && formikForm.errors.full_name,
                "about-box__field-inp-filled": formikForm.values.full_name,
              })}
              placeholder={t<string>("PREAUTH_COMMENT.FULL_NAME")}
              {...formikForm.getFieldProps("full_name")}
            />
          </label>
          {formikForm.touched.full_name && formikForm.errors.full_name && (
            <div className="form-control-error">
              {formikForm.errors.full_name}
            </div>
          )}
        </div>
        <div
          className={classNames({
            "about__form-box about-box": true,
            "feedback-inp": true,
          })}
        >
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-preauth-name": true,
                "about-box__field-inp-error":
                  formikForm.touched.email && formikForm.errors.email,
                "about-box__field-name-filled": formikForm.values.email,
              })}
            >
              {t<string>("PREAUTH_COMMENT.EMAIL")}
            </span>
            <input
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-preauth": true,
                "about-box__field-inp-touched":
                  formikForm.touched.email && formikForm.errors.email,
                "about-box__field-inp-filled": formikForm.values.email,
              })}
              placeholder={t<string>("PREAUTH_COMMENT.EMAIL")}
              {...formikForm.getFieldProps("email")}
            />
          </label>
          {formikForm.touched.email && formikForm.errors.email && (
            <div className="form-control-error">{formikForm.errors.email}</div>
          )}
        </div>
        <div
          className={classNames({
            "about__form-box about-box": true,
            "feedback-inp": true,
          })}
        >
          <label className="about-box__field about__form-box about-box">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-preauth-name": true,
                "about-box__field-inp-error":
                  formikForm.touched.bank_name && formikForm.errors.bank_name,
                "about-box__field-name-filled": formikForm.values.bank_name,
              })}
            >
              {t<string>("PREAUTH_COMMENT.SELECT_BANK")}
            </span>
            <select
              className={classNames({
                "about-box__field-inp": true,
                "slide-step-form__bank-select": true,
                "about-box__field-preauth": true,
                "about-box__field-select": true,
                "about-box__field-inp-filled": formikForm.values.bank_name,
                "about-box__field-inp-touched":
                  formikForm.touched.bank_name && formikForm.errors.bank_name,
              })}
              value={formikForm.values.bank_name}
              onChange={(e) => {
                formikForm.setFieldValue("bank_name", e.target.value);
              }}
            >
              <option disabled hidden value="">
                {t<string>("PREAUTH_COMMENT.SELECT_BANK")}
              </option>
              <option value="DSK Bank">ДСК Банк</option>
              <option value="UniCredit Bulbank">УниКредит Булбанк</option>
              <option value="Postbank">Пощенска банка</option>
              <option value="UBB">Обединена българска банка</option>
              <option value="KBC Bank">KBC Банк</option>
              <option value="Fibank">Fibank</option>
              <option value="Investbank">Инвестбанк</option>
              <option value="Central Cooperative Bank">
                Централна кооперативна банка
              </option>
              <option value="Allianz Bank Bulgaria ">
                Алианц Банк България
              </option>
              <option value="Bulgarian-American Credit Bank">
                Българо-американска кредитна банка
              </option>
              <option value="tbi bank">tbi bank</option>
              <option value="ProCredit Bank">ПроКредит Банк</option>
              <option value="International Asset Bank">
                Интернешънъл Асет Банк
              </option>
              <option value="D Commerce Bank">Търговска Банка Д</option>
              <option value="Bulgarian Development Bank">
                Българската банка за развитие
              </option>
              <option value="Tokuda Bank">Токуда Банк</option>
              <option value="Texim Bank">Тексим Банк</option>
              <option value="Municipal Bank">Общинска банка</option>
            </select>
          </label>
          {formikForm.touched.bank_name && formikForm.errors.bank_name && (
            <div className="form-control-error">
              {formikForm.errors.bank_name}
            </div>
          )}
        </div>
        <div
          className={classNames({
            "about__form-box about-box": true,
            "feedback-inp": true,
          })}
        >
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-preauth-name": true,
                "about-box__field-inp-error":
                  formikForm.touched.rating && formikForm.errors.rating,
                "about-box__field-name-filled": formikForm.values.rating,
              })}
            >
              {t<string>("PREAUTH_COMMENT.RATING")}
            </span>
            <Rating
              onClick={handleRating}
              size={40}
              transition
              allowTitleTag={false}
              initialValue={ratingValue}
            />
          </label>
        </div>
        <div
          className={classNames({
            "about__form-box about-box": true,
            "feedback-inp": true,
          })}
        >
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-preauth-name": true,
                "about-box__field-inp-error":
                  formikForm.touched.comment && formikForm.errors.comment,
                "about-box__field-name-filled": formikForm.values.comment,
              })}
            >
              {t<string>("PREAUTH_COMMENT.BANK_REVIEW")}
            </span>
            <textarea
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-preauth-comment": true,
                "about-box__field-inp-touched":
                  formikForm.touched.comment && formikForm.errors.comment,
                "about-box__field-inp-filled": formikForm.values.comment,
              })}
              placeholder="Отзив за банката"
              {...formikForm.getFieldProps("comment")}
            />
            {formikForm.touched.comment && formikForm.errors.comment && (
              <div className="form-control-error">
                {formikForm.errors.comment}
              </div>
            )}
          </label>
        </div>
        <button
          className={classNames({
            "about-box__field-preauth": true,
            "about__preauth-submit": true,
            "about__preauth-submit-active": true,
          })}
        >
          {t<string>("PREAUTH_COMMENT.SUBMIT")}
        </button>
      </form>
    </div>
  );
};

export default FeedbackAnnounce;
