export const tables = [
  "Users",
  "Users w/questionnaire",
  "Questionnaires",
  "Submitted Applications",
  "FI",
  "Credit Offers",
  "Reviews",
  "Leads from rating",
  "Blogs",
  "News",
  "Banners",
];
