import { useEffect, useState } from "react";

import AdminApplyApplication from "../AdminApplyApplication/AdminApplyApplication";

import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { Private } from "../../../../core/models";
import { useAppSelector } from "../../../../core/store";
import { useTranslation } from "react-i18next";

const ApplyBankOffer = () => {
  const [applications, setApplications] = useState<any>([]);
  const [submitted, setSubmitted] = useState(false);
  const [sorter] = useState({ "order[created_at]": "desc" });
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState<string>("");

  const { t } = useTranslation();

  const { questionnaire } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (questionnaire) {
      setFilter({
        "filter[bank_offer_type_id][in]": questionnaire.type,
        "filter[questionnaire_id][<>]": questionnaire.id,
      });
      setSearch("");
    }
  }, [questionnaire]);

  useEffect(() => {
    setSubmitted(!submitted);
  }, []);

  useHttpGet<any>(APIRoutes.PUBLIC_BANKS_OFFERS, {
    resolve: (response: any) => {
      setApplications(response?.payload?.collection);
    },
    condition: Object.keys(filter).length !== 0,
    query: { ...sorter, ...filter },
    dependencies: [submitted, sorter, filter],
  });

  return (
    <div className="modal__offers-container modal-addUser modal-apply-application">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("APPLY_APP.APPLY")}
        </p>
        <div className="results-cards">
          <input
            className="results-search"
            type="search"
            value={search}
            placeholder={t<string>("APPLY_APP.SEARCH")}
            onChange={(event) => {
              // setFilter((prev: any) => {
              //   return {
              //     ...prev,
              //     "filter[name][like]": event.target.value,
              //   };
              // });
              // setSearch(event.target.value);

              if (event.target.value) {
                // setFilter({ "filter[name][like]": event.target.value });
                // setSearch(event.target.value);
                setSearch(event.target.value);
                setFilter((prev: any) => {
                  return {
                    ...prev,
                    "filter[name][like]": event.target.value,
                  };
                });
              } else {
                setSearch(event.target.value);
                setFilter((prev: any) => {
                  return {
                    ...prev,
                    "filter[name][like]": undefined,
                  };
                });
              }
            }}
          />
          {applications.length !== 0 ? (
            <>
              {applications.map((application: Private.BankOffer) => {
                return (
                  <AdminApplyApplication
                    key={application.id}
                    application={application}
                    questionnaire={questionnaire}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                  />
                );
              })}
            </>
          ) : (
            <div className="modal-logout__box">
              <h3 className="modal-logout__box-inform">
                {t<string>("APPLY_APP.NO_APPS")}
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplyBankOffer;
