import { useContext, useEffect } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import "cropperjs/dist/cropper.css";

import { AppContext } from "../../../../App";
import CommonService from "../../../../core/services/common.service";
import PrivateService from "../../../../core/services/private.service";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../core/store";
import { setModal } from "../../../../core/store/reducers/modal/modalSlice";

const UpdateBannerStudy = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { choosenData, selectedId } = useAppSelector((state) => state.app);

  const appContext = useContext(AppContext);
  const { setReloadChecker, reloadChecker } = appContext;

  useEffect(() => {
    formikForm.setValues({
      ...formikForm.values,
      ...choosenData,
    });
  }, [choosenData]);

  const valueFormValidationSchema = Yup.object().shape({
    full_name: Yup.string().max(120, "Max length 120 characters"),
    email: Yup.string().email("Email is invalid"),
    topic: Yup.string(),
  });

  const formikForm = useFormik<{
    type: string;
    full_name: string;
    email: string;
    topic: string;
  }>({
    initialValues: {
      type: "study",
      full_name: "",
      email: "",
      topic: "",
    },

    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    try {
      const response = await PrivateService.updateBannerRequest(
        values,
        selectedId
      );

      formikForm.resetForm();
      toast.success(response?.data?.message);
      dispatch(setModal(false));
      setReloadChecker(!reloadChecker);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
      CommonService.showErrors(errors?.response?.data?.payload);
    }
  };

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("BANNERS.UPDATE_TITLE")}
        </p>
        <form className="about" onSubmit={formikForm.handleSubmit}>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.full_name,
                  "about-box__field-inp-error":
                    formikForm.touched.full_name && formikForm.errors.full_name,
                })}
              >
                {t<string>("BANNERS.FULL_NAME")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.full_name,
                  "about-box__field-inp-touched":
                    formikForm.touched.full_name && formikForm.errors.full_name,
                })}
                placeholder={t<string>("BANNERS.FULL_NAME")}
                {...formikForm.getFieldProps("full_name")}
              />
            </label>
          </div>
          {formikForm.touched.full_name && formikForm.errors.full_name && (
            <div className="form-control-error">
              {formikForm.errors.full_name}
            </div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.email,
                  "about-box__field-inp-error":
                    formikForm.touched.email && formikForm.errors.email,
                })}
              >
                {t<string>("BANNERS.EMAIL")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.email,
                  "about-box__field-inp-touched":
                    formikForm.touched.email && formikForm.errors.email,
                })}
                placeholder={t<string>("BANNERS.EMAIL")}
                {...formikForm.getFieldProps("email")}
              />
            </label>
          </div>
          {formikForm.touched.email && formikForm.errors.email && (
            <div className="form-control-error">{formikForm.errors.email}</div>
          )}
          <div
            className={classNames({
              "about__form-box about-box": true,
              "feedback-inp": true,
            })}
          >
            <label className="about-box__field about__form-box about-box">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.topic && formikForm.errors.topic,
                  "about-box__field-name-filled": formikForm.values.topic,
                })}
              >
                {t<string>("BANNERS.TOPIC")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "slide-step-form__bank-select": true,
                  "about-box__field-preauth": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.topic,
                  "about-box__field-inp-touched":
                    formikForm.touched.topic && formikForm.errors.topic,
                })}
                value={formikForm.values.topic}
                onChange={(e) => {
                  formikForm.setFieldValue("topic", e.target.value);
                }}
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="topic">Topic 1</option>
                <option value="topic2">Topic 2</option>
                <option value="topic3">Topic 3</option>
                <option value="topic4">Topic 4</option>
              </select>
            </label>
            {formikForm.touched.topic && formikForm.errors.topic && (
              <div className="form-control-error">
                {formikForm.errors.topic}
              </div>
            )}
          </div>
          <button
            type="submit"
            className={classNames({
              "about-box__field-preauth": true,
              "about__preauth-submit": true,
              "about__preauth-submit-active": true,
            })}
          >
            {t<string>("BANNERS.SAVE_BUTTON")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateBannerStudy;
