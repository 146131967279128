import { JSXElementConstructor, useContext } from "react";

import { AppContext } from "../../App";
import { useAppDispatch } from "../../core/store";
import { setModal } from "../../core/store/reducers/modal/modalSlice";
import { useTranslation } from "react-i18next";

const ExampleCalculator = () => {
  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);
  const { t } = useTranslation();

  const { calculationExample } = appContext;

  // function exampleText(): JSX.Element {
  //   const elements: JSX.Element[] = [];
  
  //   for (let i = 1; i <= 7; i++) {
  //     elements.push(
  //       <div className="calc-example-content" key={i}>
  //         <span>{t<string>("CALCULATOR.CALC_EXAMPLE.P_1")}</span>
  //         <br /><br />
  //         <span>{t<string>("CALCULATOR.CALC_EXAMPLE.P_2")}</span>
  //         <br /><br />
  //         <span>{t<string>("CALCULATOR.CALC_EXAMPLE.P_3")}</span>
  //         <br /> <br /> 
  //       </div>
  //     );
  //   }
  
  //   return (
  //     <div>
  //       {elements}
  //     </div>
  //   );
  // }
  return (
    <div className="modal__container modal-logout calc-example-wrapper">
      <div className=" calc-modal-box">
        <nav className="calc-example-header">
          <button
            className="calc-example-header__btn"
            onClick={() => dispatch(setModal(false))}>
            x
          </button>
        </nav>
          <div className="calc-example-content">
            <span>{t<string>("CALCULATOR.CALC_EXAMPLE.P_1")}</span>
            <br /><br />
            <span>{t<string>("CALCULATOR.CALC_EXAMPLE.P_2")}</span>
            <br /><br />
            <span>{t<string>("CALCULATOR.CALC_EXAMPLE.P_3")}</span>
            <br /> <br /> 
          </div>
        {/* // {exampleText()} */}
        <button
          onClick={() => dispatch(setModal(false))}
          className="calc-example-close__btn">
          {t<string>("CALCULATOR.CLOSE")}
        </button>
      </div>
    </div>
  );
};

export default ExampleCalculator;
