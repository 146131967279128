import React from 'react'
import AuthBase from '../AuthBase'
import ResetPasswordForm from './ResetPasswordForm'

const ResetPasswordContainer = () => {
  return (
    <AuthBase>
      <ResetPasswordForm />
    </AuthBase>
  )
}

export default ResetPasswordContainer
