import React, { useContext } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";
import ReactGA from "react-ga4";
import { AppContext } from "../../../App";
import PrivateService from "../../../core/services/private.service";
import { useAppDispatch, useAppSelector } from "../../../core/store";
import { Private } from "../../../core/models";
import { currencyFormat } from "../../../core/functions/funtions";

import star from "../../../assets/photos/all/star.svg";
import photo from "../../../assets/photos/profile/bank-icon.svg";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../core/services/enums";
import CommonService from "../../../core/services/common.service";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../core/hooks/useAuth";
import { PrivateUIRoutes, UIRoutes } from "../../../core/router";

interface Props {
  application: Private.BankOffer;
  id: number;
  sortedApplication: any;
  passport?: string | null;
}

const ResultsCard: React.FC<Props> = ({
  application,
  id,
  sortedApplication,
  passport
}) => {
  const { isAuth } = useAuth();
  const appContext = useContext(AppContext);
  const { setSelectionType } = appContext;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { appLanguage } = useAppSelector((state) => state.app);
  // const currTab = JSON.parse(sessionStorage.getItem("calcResult") || "{}");
  // const throwAlertWindow = (typeId: number | null) => {
  //   if (typeId !== +currTab.type) {
  //     dispatch(setModalType(AuthSelectionType.resultsAlert));
  //     dispatch(setModal(true));
  //   }
  // };
  // const setApplyOffer = () => {
  //   if (isAuth) {
  //     // navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
  //     throwAlertWindow(null);
  //   } else {
  //     dispatch(setModalType(AuthSelectionType.Auth));
  //     dispatch(setModal(true));
  //     window.scrollTo(0, 0);
  //     document.body.style.overflow = "hidden";
  //     localStorage.setItem("prevPage", `/${UIRoutes.RESULTS_LIST}`);
  //   }
  // };

  const applyOffer = async (id: number) => {
    try {
      const response: any = await PrivateService.applyApplication(
        id,
        application.id
      );
      sortedApplication(application.id);
      toast.success(response?.data?.message);

      ReactGA.event({
        category: "offer_apply",
        action:
          process.env.REACT_APP_ANALYTICS_NAME === ""
            ? "offer_apply"
            : `offer_apply_${process.env.REACT_APP_ANALYTICS_NAME}`,
      });
    } catch (errors: any) {
      // toast.error(errors?.response?.data?.message);
      CommonService.showErrors(errors?.response?.data?.payload);
    }
  };
  const setApplyOffer = () => {
    if (isAuth) {
      if (id !== 0 && passport !== '' && passport !== null) {
        applyOffer(id);
      } else {
        navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
        localStorage.setItem("prevPage", `/${UIRoutes.RESULTS_LIST}`);
        sessionStorage.setItem("bank_offer_id", `${application.id}`);
      }
    } else {
      dispatch(setModalType(AuthSelectionType.Auth));
      setSelectionType(AuthSelectionType.Register);
      dispatch(setModal(true));
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
      localStorage.setItem("prevPage", `/${UIRoutes.RESULTS_LIST}`);
      sessionStorage.setItem("bank_offer_id", `${application.id}`);
    }
  };

  // const showApplicationDetails = (application: any) => {
  //   dispatch(setModalType(AuthSelectionType.showApplication));
  //   dispatch(setModal(true));
  //   dispatch(setChoosenData({ ...application }));
  // };

  return (
    <div
      className="results-card"
      // onClick={() => {
      //   showApplicationDetails(application);
      // }}
    >
      <div className="results-card__search-item search-item__container">
        <div className="results-card__search-item search-item">
          <div className="search-item__main-info main-info">
            <div className="main-info__headers headers">
              <img
                className="headers__photo"
                src={
                  application.bank.logo_path
                    ? application.bank.logo_path
                    : photo
                }
                alt={application.bank.name}
              />
              <div className="headers__info info">
                <span
                  className={
                    appLanguage === "en" ? "info__title_en" : "info__title_bg"
                  }
                >
                  {appLanguage === "bg"
                    ? application.translations.bg.name
                    : appLanguage === "en"
                    ? application.translations.en.name
                    : "FI Name"}
                </span>
                <div className="main-info__feedback feedback">
                  <div className="feedback__rating rating">
                    <div className="rating__inner">
                      <span>
                        {t<string>("COMMON.RATING")} {application.bank.rating}
                      </span>
                      <img src={star} width="18px" height="18px" alt="rating" />
                    </div>
                  </div>
                  <div className="feedback__reviews reviews">
                    <span className="reviews__reviews-data">
                      {application.bank.reviews_count}
                    </span>
                    <span className="reviews__reviews-title">
                      {t<string>("CALCULATOR.REVIEWS")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-item__details details">
            <div className="details__sum sum">
              <h5 className="sum__header">{t<string>("CALCULATOR.SUM")}</h5>
              <div className="sum__data">
                <span>{currencyFormat(application.loan_amount_from)}</span>
                <span className="sum__data__currency">
                  {t<string>("PROFILE.BGN")}
                </span>
                <span> - </span>
                <span>{currencyFormat(application.loan_amount_to)}</span>
                <span className="sum__data__currency">
                  {t<string>("PROFILE.BGN")}
                </span>
              </div>
            </div>
            <div className="details__item-term item-term">
              <h5 className="item-term__header">
                {t<string>("CALCULATOR.LOAN_TERM")}
              </h5>
              <div className="item-term__data">
                <span>{application.credit_term_from}</span>
                <span>{t<string>("CALCULATOR.MONTHS")}</span>
                <span> - </span>
                <span>{application.credit_term_to}</span>
                <span>{t<string>("CALCULATOR.MONTHS")}</span>
              </div>
            </div>
            <div className="details__item-rate item-rate">
              <h5 className="item-rate__header">
                {t<string>("CALCULATOR.RATE")}
              </h5>
              <div className="item-rate__data">
                <span>{`${t<string>("CALCULATOR.FROM")}  ${
                  application.interest_rate_from
                }%`}</span>
              </div>
            </div>
          </div>
        </div>
        <button
          className={classNames({
            "search-item__apply-btn": true,
          })}
          onClick={(e) => {
            e.stopPropagation();
            setApplyOffer();
          }}
        >
          {t<string>("APPLY_APP.APPLY_BTN")}
        </button>
      </div>
    </div>
  );
};

export default ResultsCard;
