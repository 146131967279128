import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import cities_list_BG from "../../../core/store/citiesBG.json";
import { City, Country } from "country-state-city";
import { useAppSelector } from "../../../core/store";


type Props = {
  questionsStep: number;
  setQuestionsStep: (num: number) => void;
};

const SecondStep: React.FC<Props> = ({ questionsStep, setQuestionsStep }) => {
  const [cities, setCities] = useState<any>([]);
  const { appLanguage } = useAppSelector((state) => state.app);
  const { t } = useTranslation();

  useEffect(() => {
    if (sessionStorage.getItem("questionnaire")) {
      const questionnaire = JSON.parse(
        sessionStorage.getItem("questionnaire") || ""
      );
      formikForm.setValues({
        ...formikForm.values,
        ...questionnaire,
      });
    }
  }, []);

  const valueFormValidationSchema = Yup.object().shape({
    marital_status: Yup.string()
    .required(t<string>("COMMON.IS_REQUIRED")),
    children: Yup.string()
    .required(t<string>("COMMON.IS_REQUIRED")),
    country_of_residence: Yup.string()
      .required(t<string>("COMMON.IS_REQUIRED"))
      .max(55),
    city_of_residence: Yup.string()
      .required(t<string>("COMMON.IS_REQUIRED"))
      .max(55),
    housing_type: Yup.string().required(t<string>('COMMON.IS_REQUIRED')).max(55),
    renting: Yup.string().when("housing_type", {
      is: (val: string) => val && val === "0",
      then: Yup.string().required(t<string>("COMMON.IS_REQUIRED")),
    }),
    hypothec: Yup.string().when("housing_type", {
      is: (val: string) => val && val === "3",
      then: Yup.string().required(t<string>("COMMON.IS_REQUIRED")),
    }),
    street_house: Yup.string().required(t<string>("COMMON.IS_REQUIRED")).max(55),
    postal_code: Yup.string().required(t<string>("COMMON.IS_REQUIRED")),
    house_number: Yup.string().required(t<string>("COMMON.IS_REQUIRED")).max(10),
    apartment_number: Yup.string().when(["housing_type"], {
      is: "0",
      then: Yup.string().required(t<string>("COMMON.IS_REQUIRED")),
      otherwise: Yup.string(),
    }),
  });

  const formikForm = useFormik<{
    marital_status: string;
    children: string;
    country_of_residence: string;
    city_of_residence: string;
    housing_type: string;
    accomodation_type: string;
    street_house: string;
    postal_code: number | string;
    house_number: number | string;
    apartment_number: number | string;
    renting: number | string;
    hypothec: number | string;
  }>({
    initialValues: {
      marital_status: "",
      children: "0",
      country_of_residence: "",
      city_of_residence: "",
      housing_type: "",
      accomodation_type: "",
      street_house: "",
      postal_code: "",
      house_number: "",
      apartment_number: "",
      renting: "",
      hypothec: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = (values: any) => {
    const memory = JSON.parse(sessionStorage.getItem("questionnaire") || "");
    sessionStorage.setItem(
      "questionnaire",
      JSON.stringify({ ...memory, ...values })
    );
    setQuestionsStep(questionsStep + 1);
    sessionStorage.setItem("currentStep", JSON.stringify(questionsStep + 1));
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    sessionStorage.setItem(
      "stepper",
      JSON.stringify({
        firstStepConfirm: true,
        secondStepConfirm: formikForm.isValid,
        thirdStepConfirm: formikForm.isValid,
      })
    );
  }, [formikForm.isValid]);

  useEffect(() => {
    Country.getAllCountries().filter((country) => {
      if (country.name === formikForm.values.country_of_residence) {
        if (country.name === "Bulgaria") {
          setCities(cities_list_BG)
        } else{
          setCities(City.getCitiesOfCountry(country.isoCode));
        }
      }
    });
  }, [formikForm.values.country_of_residence]);

  return (
    <form className="about" onSubmit={formikForm.handleSubmit}>
      <div className="about__form">
        <h2 className="about__title">{t<string>("PROFILE.FAMILY_STATUS")}</h2>
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled":
                  formikForm.values.marital_status,
                "about-box__field-inp-error":
                  formikForm.touched.marital_status &&
                  formikForm.errors.marital_status,
              })}
            >
              {t<string>("PROFILE.MARITAL_STATUS")}
            </span>
            <select
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-select": true,
                "about-box__field-inp-filled": formikForm.values.marital_status,
                "about-box__field-inp-touched":
                  formikForm.touched.marital_status &&
                  formikForm.errors.marital_status,
              })}
              {...formikForm.getFieldProps("marital_status")}
            >
              <option disabled hidden value="">
                {t<string>("PROFILE.PLEASE_SELECT")}
              </option>
              <option value="0">{t<string>("PROFILE.MARRIED")}</option>
              <option value="1">{t<string>("PROFILE.SINGLE")}</option>
              <option value="2">{t<string>("PROFILE.CIVIL_MARRIAGE")}</option>
              <option value="3">{t<string>("PROFILE.DIVORCED")}</option>
              <option value="4">{t<string>("PROFILE.WIDOWED")}</option>
            </select>
          </label>
        </div>
        {formikForm.touched.marital_status &&
          formikForm.errors.marital_status && (
            <div className="form-control-error">
              {t<string>(formikForm.errors.marital_status)}
            </div>
          )}
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.children,
                "about-box__field-inp-error":
                  formikForm.touched.children && formikForm.errors.children,
              })}
            >
              {t<string>("PROFILE.UNDER_18")}
            </span>
            <select
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-select": true,
                "about-box__field-inp-filled": formikForm.values.children,
                "about-box__field-inp-touched":
                  formikForm.touched.children && formikForm.errors.children,
              })}
              {...formikForm.getFieldProps("children")}
            >
              <option disabled hidden value="">
                {t<string>("PROFILE.PLEASE_SELECT")}
              </option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">{t<string>("PROFILE.MORE_THAN_3")}</option>
            </select>
          </label>
        </div>
        {formikForm.touched.children && formikForm.errors.children && (
          <div className="form-control-error">{t<string>(formikForm.errors.children)}</div>
        )}
      </div>
      <div className="about__form">
        <h2 className="about__title">
          {t<string>("PROFILE.RESIDENCE_ADDRESS")}
        </h2>
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled":
                  formikForm.values.country_of_residence,
                "about-box__field-inp-error":
                  formikForm.touched.country_of_residence &&
                  formikForm.errors.country_of_residence,
              })}
            >
              {t<string>("PROFILE.COUNTRY_OF_RESIDENCE")}
            </span>
            <select
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-select": true,
                "about-box__field-inp-filled":
                  formikForm.values.country_of_residence,
                "about-box__field-inp-touched":
                  formikForm.touched.country_of_residence &&
                  formikForm.errors.country_of_residence,
              })}
              {...formikForm.getFieldProps("country_of_residence")}
            >
              <option disabled hidden value="">
                {t<string>("PROFILE.PLEASE_SELECT")}
              </option>
              {[
                ...Country.getAllCountries().filter(
                  (country) => country.name === "Bulgaria"
                ),
                ...Country.getAllCountries().filter(
                  (country) => country.name !== "Bulgaria"
                ),
              ].map((country: any, index: number) => (
                <option
                  className={classNames({
                    "bul-selected":
                      country.name === "Bulgaria" ||
                      country.name === "България",
                  })}
                  value={country.name}
                  key={index}
                >
                  {t<string>(`COUNTRY.${country.name.toUpperCase()}`)
                    ?.split(" ")
                    .map(
                      (word) =>
                        word[0]?.toUpperCase() + word.slice(1).toLowerCase()
                    )
                    .join(" ")}
                </option>
              ))}
            </select>
          </label>
        </div>
        {formikForm.touched.country_of_residence &&
          formikForm.errors.country_of_residence && (
            <div className="form-control-error">
              {t<string>(formikForm.errors.country_of_residence)}
            </div>
          )}
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled":
                  formikForm.values.city_of_residence,
                "about-box__field-inp-error":
                  formikForm.touched.city_of_residence &&
                  formikForm.errors.city_of_residence,
              })}
            >
              {t<string>("PROFILE.CITY_OF_RESIDENCE")}
            </span>
            <select
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-select": true,
                "about-box__field-inp-filled":
                  formikForm.values.city_of_residence,
                "about-box__field-inp-touched":
                  formikForm.touched.city_of_residence &&
                  formikForm.errors.city_of_residence,
              })}
              {...formikForm.getFieldProps("city_of_residence")}
            >
              <option disabled hidden value="">
                {t<string>("PROFILE.PLEASE_SELECT")}
              </option>
              {formikForm.values.country_of_residence === "Bulgaria"
                ? cities.map((city: any, index: number) => (
                    <option value={city.name} key={city.id}>
                      {/* {t<string>(`CITIES.${city.name.toUpperCase()}`)} */}
                      {
                      appLanguage === "en" 
                      ? city.title_en
                      : city.title_bg
                      }
                    </option>
                  ))
                : cities.map((city: any, index: number) => (
                    <option value={city.name} key={index}>
                      {city.name}
                    </option>
                  ))}
            </select>
          </label>
        </div>
        {formikForm.touched.city_of_residence &&
          formikForm.errors.city_of_residence && (
            <div className="form-control-error">
              {t<string>(formikForm.errors.city_of_residence)}
            </div>
          )}
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.housing_type,
                "about-box__field-inp-error":
                  formikForm.touched.housing_type && formikForm.errors.housing_type,
              })}
            >
              {t<string>("PROFILE.OWNERSHIP_TYPE")}
            </span>
            <select
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-select": true,
                "about-box__field-inp-filled": formikForm.values.housing_type,
                "about-box__field-inp-touched":
                  formikForm.touched.housing_type && formikForm.errors.housing_type,
              })}
              value={formikForm.values.housing_type}
              onChange={(event: any) => {
                formikForm.setFieldValue("housing_type", event.target.value);
              }}
            >
              <option disabled hidden value="">
                {t<string>("PROFILE.PLEASE_SELECT")}
              </option>
              <option value="0">{t<string>("PROFILE.RENTING")}</option>
              <option value="1">{t<string>("PROFILE.PARENTAL")}</option>
              <option value="2">{t<string>("PROFILE.OWN_HOME")}</option>
              <option value="3">
                {t<string>("PROFILE.OWN_WITH_MORTGAGE")}
              </option>
              <option value="4">{t<string>("PROFILE.PUBLIC_HOUSING")}</option>
              <option value="5">{t<string>("PROFILE.OTHER")}</option>
            </select>
          </label>
        </div>
        {formikForm.touched.housing_type && formikForm.errors.housing_type && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.housing_type)}
          </div>
        )}
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.accomodation_type,
                "about-box__field-inp-error":
                  formikForm.touched.accomodation_type &&
                  formikForm.errors.accomodation_type,
              })}
            >
              {t<string>("PROFILE.TYPE_OF_HOUSING")}
            </span>
            <select
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-select": true,
                "about-box__field-inp-filled": formikForm.values.accomodation_type,
                "about-box__field-inp-touched":
                  formikForm.touched.accomodation_type &&
                  formikForm.errors.accomodation_type,
              })}
              value={formikForm.values.accomodation_type}
              onChange={(event: any) => {
                formikForm.setFieldValue("accomodation_type", event.target.value);
              }}
            >
              <option disabled hidden value="">
                {t<string>("PROFILE.PLEASE_SELECT")}
              </option>
              <option value="0">{t<string>("PROFILE.FLAT")}</option>
              <option value="1">{t<string>("PROFILE.HOUSE")}</option>
            </select>
          </label>
        </div>
        {formikForm.touched.accomodation_type && formikForm.errors.accomodation_type && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.accomodation_type)}
          </div>
        )}
        {formikForm.values.housing_type === "0" && (
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.renting,
                  "about-box__field-inp-error":
                    formikForm.touched.renting && formikForm.errors.renting,
                })}
              >
                {t<string>("PROFILE.RENT_EXPENSE")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.renting,
                  "about-box__field-inp-touched":
                    formikForm.touched.renting && formikForm.errors.renting,
                })}
                type="text"
                value={formikForm.values.renting}
                onChange={(event: any) => {
                  if (
                    +event.target.value <= 0 ||
                    Number.isNaN(+event.target.value)
                  ) {
                    formikForm.setFieldValue("renting", "");
                  } else {
                    formikForm.setFieldValue("renting", event.target.value);
                  }
                }}
              />
            </label>
            {formikForm.touched.renting && formikForm.errors.renting && (
              <div className="form-control-error">
                {t<string>(formikForm.errors.renting)}
              </div>
            )}
          </div>
        )}
        {formikForm.values.housing_type === "3" && (
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.hypothec,
                  "about-box__field-inp-error":
                    formikForm.touched.hypothec && formikForm.errors.hypothec,
                })}
              >
                {t<string>("PROFILE.OWN_EXPENSE_MORTGAGE")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.hypothec,
                  "about-box__field-inp-touched":
                    formikForm.touched.hypothec && formikForm.errors.hypothec,
                })}
                type="text"
                value={formikForm.values.hypothec}
                onChange={(event: any) => {
                  if (
                    +event.target.value <= 0 ||
                    Number.isNaN(+event.target.value)
                  ) {
                    formikForm.setFieldValue("hypothec", "");
                  } else {
                    formikForm.setFieldValue("hypothec", event.target.value);
                  }
                }}
              />
            </label>
            {formikForm.touched.hypothec && formikForm.errors.hypothec && (
              <div className="form-control-error">
                {t<string>(formikForm.errors.hypothec)}
              </div>
            )}
          </div>
        )}

        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.street_house,
                "about-box__field-inp-error":
                  formikForm.touched.street_house &&
                  formikForm.errors.street_house,
              })}
            >
              {t<string>("PROFILE.STREET")}
            </span>
            <input
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-inp-filled": formikForm.values.street_house,
                "about-box__field-inp-touched":
                  formikForm.touched.street_house &&
                  formikForm.errors.street_house,
              })}
              placeholder={t<string>("PROFILE.STREET_HOUSE_PLACEHOLDER")}
              {...formikForm.getFieldProps("street_house")}
            />
          </label>
        </div>
        {formikForm.touched.street_house && formikForm.errors.street_house && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.street_house)}
          </div>
        )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.house_number,
                  "about-box__field-inp-error":
                    formikForm.touched.house_number &&
                    formikForm.errors.house_number,
                })}
              >
                {t<string>("PROFILE.HOUSE_NUMBER")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.house_number,
                  "about-box__field-inp-touched":
                    formikForm.touched.house_number &&
                    formikForm.errors.house_number,
                })}
                placeholder="7"
                type="text"
                value={formikForm.values.house_number}
                onChange={(event) => {
                  if (
                    +event.target.value <= 0 ||
                    Number.isNaN(+event.target.value)
                  ) {
                    formikForm.setFieldValue("house_number", "");
                  } else {
                    formikForm.setFieldValue(
                      "house_number",
                      +event.target.value
                    );
                  }
                }}
              />
            </label>
          </div>
        {formikForm.touched.house_number && formikForm.errors.house_number && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.house_number)}
          </div>
        )}
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled":
                  formikForm.values.apartment_number,
                "about-box__field-inp-error":
                  formikForm.values.housing_type === '0' &&
                  formikForm.touched.apartment_number &&
                  formikForm.errors.apartment_number,
              })}
            >
              {t<string>("PROFILE.APARTMENT_NUMBER")}
            </span>
            <input
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-inp-filled":
                  formikForm.values.apartment_number,
                "about-box__field-inp-touched":
                formikForm.values.housing_type === '0' &&
                  formikForm.touched.apartment_number &&
                  formikForm.errors.apartment_number,
              })}
              placeholder="24"
              type="text"
              value={formikForm.values.apartment_number}
              onChange={(event) => {
                if (
                  +event.target.value <= 0 ||
                  Number.isNaN(+event.target.value)
                ) {
                  formikForm.setFieldValue("apartment_number", "");
                } else {
                  formikForm.setFieldValue(
                    "apartment_number",
                    +event.target.value
                  );
                }
              }}
            />
          </label>
        </div>
        {formikForm.values.housing_type === '0' &&
          formikForm.touched.apartment_number &&
          formikForm.errors.apartment_number && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.apartment_number)}
          </div>
        )}
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.postal_code,
                "about-box__field-inp-error":
                  formikForm.touched.postal_code &&
                  formikForm.errors.postal_code,
              })}
            >
              {t<string>("PROFILE.POSTAL_CODE")}
            </span>
            <input
              maxLength={4}
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-inp-filled": formikForm.values.postal_code,
                "about-box__field-inp-touched":
                  formikForm.touched.postal_code &&
                  formikForm.errors.postal_code,
              })}
              placeholder="203-320"
              {...formikForm.getFieldProps("postal_code")}
            />
          </label>
        </div>
        {formikForm.touched.postal_code && formikForm.errors.postal_code && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.postal_code)}
          </div>
        )}
      </div>
      <button
        className={classNames({
          about__submit: true,
          "about__submit-active": formikForm.isValid
        })}
        type="submit"
      >
        {t<string>("COMMON.NEXT")}
      </button>
      <p className="about__safe">{t<string>("PROFILE.SAFETY")}</p>
    </form>
  );
};

export default SecondStep;
