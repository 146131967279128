import { FC } from "react";

import { benefits } from "../../core/constants/benefits";
import BenefitsCard from "./BenefitsCard/BenefitsCard";
import { useTranslation } from "react-i18next";

const BenefitsContainer: FC = () => {
  const {t} = useTranslation()

  const benefitsText = [
    {
      title: t<string>("BENEFITS.BENEFITS_1_TITLE"),
      subtitle: t<string>("BENEFITS.BENEFITS_1_SUBTITLE")
    },
    {
      title: t<string>("BENEFITS.BENEFITS_2_TITLE"),
      subtitle: t<string>("BENEFITS.BENEFITS_2_SUBTITLE")
    },
    {
      title: t<string>("BENEFITS.BENEFITS_3_TITLE"),
      subtitle: t<string>("BENEFITS.BENEFITS_3_SUBTITLE")
    },
    {
      title: t<string>("BENEFITS.BENEFITS_4_TITLE"),
      subtitle: t<string>("BENEFITS.BENEFITS_4_SUBTITLE")
    },
  ]
  return (
    <div className="benefits-container">
      {benefits.map((benefit: any, i) => {
        const { title, subtitle, id, photo } = benefit;

        return (
          <BenefitsCard
            key={id}
            title={benefitsText[i].title}
            subtitle={benefitsText[i].subtitle}
            photo={photo}
          />
        );
      })}
    </div>
  );
};

export default BenefitsContainer;
