import React, { FC } from "react";
import VerificationContainer from "../../../components/Verification";
import { VerificationTypes } from "../../../core/services/enums";

interface IProps {
  type: VerificationTypes;
}

const VerificationPage: FC<IProps> = ({ type }) => {
  return <VerificationContainer type={type} />;
};

export default VerificationPage;
