import React, { FC } from "react";
import BanksListContainer from "../../../../components/Banks/List";

const BanksListPage: FC = () => {
  return (
    <>
      <BanksListContainer />
    </>
  );
};

export default BanksListPage;
