import icon from "../../assets/photos/profile/bank-icon.svg";

export const profileReviews = [
  {
    id: 1,
    name: "Alfabank",
    photo: icon,
    rating: 4.5,
    desc: "Thanks to the bank for such speed and customer orientation, we were able to issue urgent documents and their service and other stuff, that hepled me to do a lot",
    date: "24.10.21",
  },
  {
    id: 2,
    name: "Alfabank",
    photo: icon,
    rating: 4.5,
    desc: "Thanks to the bank for such speed and customer orientation, we were able to issue urgent documents and their service and other stuff, that hepled me to do a lot",
    date: "24.10.21",
  },
  {
    id: 3,
    name: "Alfabank",
    photo: icon,
    rating: 4.5,
    desc: "Thanks to the bank for such speed and customer orientation, we were able to issue urgent documents and their service and other stuff, that hepled me to do a lot",
    date: "24.10.21",
  },
  {
    id: 4,
    name: "Alfabank",
    photo: icon,
    rating: 4.5,
    desc: "Thanks to the bank for such speed and customer orientation, we were able to issue urgent documents and their service and other stuff, that hepled me to do a lot",
    date: "24.10.21",
  },
  {
    id: 5,
    name: "Alfabank",
    photo: icon,
    rating: 4.5,
    desc: "Thanks to the bank for such speed and customer orientation, we were ",
    date: "24.10.21",
  },
  {
    id: 6,
    name: "Alfabank",
    photo: icon,
    rating: 4.5,
    desc: "Thanks to the bank for such speed and customer orientation, we were able to issue urgent documents and their service and other stuff, that hepled me to do a lot",
    date: "24.10.21",
  },
];
