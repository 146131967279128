import React, { FC } from "react";
import AuthBase from "../AuthBase";
import SetPasswordForm from "./SetPasswordForm";

const SetPasswordContainer: FC = () => {
  return (
    <AuthBase>
      <SetPasswordForm />
    </AuthBase>
  );
};

export default SetPasswordContainer;
