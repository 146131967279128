import React from "react";
import { Rating } from "react-simple-star-rating";
import { Private } from "../../../core/models";

import defaultBankLogo from "../../../assets/photos/profile/bank-logo.svg";
import { Link } from "react-router-dom";

type Props = {
  review: Private.ProfileReview;
};

const ReviewCard: React.FC<Props> = ({ review }) => {
  return (
    <Link to={`/banks-rating/${review.bank.id}`}>
      <div className="review-holder">
        <div className="review-holder__box holder-box">
          <div className="holder-box__bank">
            <img
              className="holder-box__bank-photo"
              src={
                review.bank.logo_path ? review.bank.logo_path : defaultBankLogo
              }
              alt={review.bank.name}
            />
            <div className="holder-box__bank-content">
              <p className="holder-box__bank-content-name">
                {review.bank.name}
              </p>
              <div className="holder-box__bank-content-rate">
                <Rating
                  initialValue={review.rating}
                  readonly={true}
                  size={18}
                  allowTitleTag={false}
                />
                <span>{review.rating}</span>
              </div>
            </div>
          </div>
          <span className="holder-box__date">
            {`${review.created_at.slice(8, 10)}.${review.created_at.slice(
              5,
              7
            )}.${review.created_at.slice(0, 4)}`}
          </span>
        </div>
        <p className="review-holder__desc">{review.comment}</p>
        <span className="holder-box__date holder-box__date-mobile">
          {`${review.created_at.slice(8, 10)}.${review.created_at.slice(
            5,
            7
          )}.${review.created_at.slice(0, 4)}`}
        </span>
      </div>
    </Link>
  );
};

export default ReviewCard;
