import { useState } from "react";
import classNames from "classnames";

import NewsCategories from "./NewsCategories";
import NewsPosts from "./NewsPosts";
import { useTranslation } from "react-i18next";
import ButtonExcel from "../../ButtonExcel/ButtonExcel";

const AdminNews = () => {
  const [selectBlock, setSelectBlock] = useState<number>(0);

  const { t } = useTranslation();

  return (
    <div className="admin">
      <h2 className="admin-table__name">
        {t<string>("NEWS_TABLE.GENERAL_TITLE")}
      </h2>
      <ButtonExcel url='admin/news_export'/>
      <div className="admin-container">
        <div className="profile-table">
          <div className="admin-blog">
            <div className="admin-blog-container">
              <button
                onClick={() => setSelectBlock(0)}
                className={classNames({
                  "admin-blog-container__btn": true,
                  "admin-blog-container__btn-selected": selectBlock === 0,
                  "admin-blog-container__btn-disable": selectBlock !== 0,
                })}
              >
                {t<string>("NEWS_TABLE.CATEGORIES")}
              </button>
              <button
                onClick={() => setSelectBlock(1)}
                className={classNames({
                  "admin-blog-container__btn": true,
                  "admin-blog-container__btn-selected": selectBlock === 1,
                  "admin-blog-container__btn-disable": selectBlock !== 1,
                })}
              >
                {t<string>("NEWS_TABLE.POSTS")}
              </button>
            </div>
            {selectBlock === 0 && <NewsCategories />}
            {selectBlock === 1 && <NewsPosts />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNews;
