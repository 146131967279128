import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../core/store";
import { setModal } from "../../../../core/store/reducers/modal/modalSlice";
import { clearCache } from "../../../../core/functions/funtions";

const LogoutModal = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const accessToken = JSON.parse(sessionStorage.getItem("accessToken") || "{}");

  const logout = () => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
    localStorage.removeItem("savedUser");
    localStorage.removeItem("cookies");
    sessionStorage.removeItem("questionnaire");
    localStorage.removeItem("calcResult");
    sessionStorage.removeItem("currentStep");
    sessionStorage.removeItem("stepper");
    navigate(`/`);
    window.location.reload();
    clearCache();
    window.FB.logout(() => {}, { access_token: accessToken });
  };

  return (
    <div className="modal__container modal-logout">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("AUTH.LOG_OUT_QUESTION")}
        </p>
        <div className="modal-logout__box-buttons">
          <button
            className="modal-logout__box-buttons-btn modal-logout__box-buttons-yes"
            onClick={() => {
              logout();
              dispatch(setModal(false));
              document.body.style.overflowY = "scroll";
            }}>
            {t<string>("AUTH.YES")}
          </button>
          <button
            className="modal-logout__box-buttons-btn modal-logout__box-buttons-no"
            onClick={() => {
              dispatch(setModal(false));
              document.body.style.overflowY = "scroll";
            }}>
            {t<string>("AUTH.NO")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
