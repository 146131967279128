import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { AppContext } from "../../../../App";
import PrivateService from "../../../../core/services/private.service";
import { useAppDispatch, useAppSelector } from "../../../../core/store";
import { setModal } from "../../../../core/store/reducers/modal/modalSlice";

const DeleteBankOfferModal = () => {
  const [tempOffer, setTempOffer] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const appContext = useContext(AppContext);
  const { choosenData } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const { setReloadChecker, reloadChecker } = appContext;

  useEffect(() => {
    if (choosenData) {
      setTempOffer((prev: any) => {
        return {
          ...prev,
          ...choosenData,
        };
      });
    }
  }, [choosenData]);

  const deleteOffer = async (id: number) => {
    try {
      const response = await PrivateService.deleteSubmittedApllication(id);

      toast.success(response?.data?.message);
      setReloadChecker(!reloadChecker);
      dispatch(setModal(false));
    } catch (errors: any) {
      setLoading(false);
      dispatch(setModal(false));
      toast.error(errors?.response?.data?.message);
    }
  };

  return (
    <div className="modal__container modal-logout">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          Delete offer with id of {tempOffer?.bank_offer_id} ?
        </p>
        <div className="modal-logout__box-buttons">
          <button
            className="modal-logout__box-buttons-btn modal-logout__box-buttons-yes"
            onClick={() => {
              dispatch(setModal(false));
              deleteOffer(tempOffer.bank_offer_id);
              document.body.style.overflowY = "scroll";
            }}
          >
            Yes
          </button>
          <button
            className="modal-logout__box-buttons-btn modal-logout__box-buttons-no"
            onClick={() => {
              dispatch(setModal(false));
              document.body.style.overflowY = "scroll";
            }}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBankOfferModal;
