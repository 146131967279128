import { AxiosResponse } from "axios";

import RequestsService from "./requests.service";
import { APIRoutes } from "../http";
import { Public } from "../models";

export default class PublicService {
  static async contactUs(model: Public.ContactUs): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("full_name", model.full_name);
    formData.append("email", model.email);
    formData.append("subject", model.subject);
    formData.append("message", model.message);
    return RequestsService.postMethod<any>(
      APIRoutes.PUBLIC_CONTACT_US,
      formData
    );
  }

  static async launchBanners(model: any): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    for (var property in model) {
      if (model.hasOwnProperty(property) && (<any>model)[property])
        formData.append(property, (<any>model)[property]);
    }
    return RequestsService.postMethod<any>(
      APIRoutes.PUBLIC_LAUNCH_BANNERS,
      formData
    );
  }
}
