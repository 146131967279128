import React, { useContext, useState, useEffect } from "react";
import { APIRoutes } from "../../../../core/http";

import classNames from "classnames";
import useHttpGet from "../../../../core/hooks/useHttpGet";

import addedFile from "../../../../assets/photos/all/file-added.svg";
import { useAppSelector } from "../../../../core/store";
import { useTranslation } from "react-i18next";
import PrivateService from "../../../../core/services/private.service";

const ShowUserModal = () => {
  const [tempUser, setTempUser] = useState<any>({});

  const { choosenData, selectedId } = useAppSelector((state) => state.app);
  const { t } = useTranslation();

  const { fetchedData: documents } = useHttpGet<any>(
    `${APIRoutes.ADMIN_USERS}/${selectedId}/documents`,
    {
      dependencies: [selectedId],
    }
  );

  useEffect(() => {
    if (choosenData) {
      setTempUser((prev: any) => {
        return {
          ...prev,
          ...choosenData,
        };
      });
    }
  }, [choosenData]);

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {tempUser.first_name} {tempUser.last_name}
        </p>
        <div className="about">
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SHOW_USER.PHONE_NUMBER")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  {tempUser.phone}
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SHOW_USER.EMAIL")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  {tempUser.email}
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SHOW_USER.GENDER")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {tempUser.gender === "0" && t<string>("SHOW_USER.MALE")}
                  </span>
                  <span>
                    {tempUser.gender === "1" && t<string>("SHOW_USER.FEMALE")}
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SHOW_USER.DATE_OF_BIRTH")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{tempUser.date_of_birth}</span>
                </div>
              </label>
            </div>
          </div>

          <div className="about__form">
            <h2 className="about__title">
              {t<string>("SHOW_USER.PASSPORT_INFO")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                  })}
                >
                  {t<string>("SHOW_USER.CITIZENSHIP")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>
                    {tempUser.citizenship === "1" &&
                      t<string>("SHOW_USER.BULGARIA")}
                  </span>
                  <span>
                    {tempUser.citizenship === "2" &&
                      t<string>("SHOW_USER.OTHER_COUNTRY")}
                  </span>
                </div>
              </label>
            </div>

            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                  })}
                >
                  {t<string>("SHOW_USER.PERSONAL_IDENTIFICATION_CODE")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{tempUser.personal_code}</span>
                </div>
              </label>
            </div>

            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                  })}
                >
                  {t<string>("SHOW_USER.PASSPORT_NUMBER")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{tempUser.passport}</span>
                </div>
              </label>
            </div>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.STATUS")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>{tempUser.status}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.JOB_EXIST")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>{tempUser.job === 1 && t<string>("SHOW_USER.YES")}</span>
                <span>{tempUser.job === 0 && t<string>("SHOW_USER.NO")}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.WORK_PLACE")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>{tempUser.working_place}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.VERIFIED")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>
                  {tempUser.verified === 1 && t<string>("SHOW_USER.YES")}
                </span>
                <span>
                  {tempUser.verified === 0 && t<string>("SHOW_USER.NO")}
                </span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.ROLE")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>{tempUser.role}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.CITY_OF_RESIDENCE")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>{tempUser.city_of_residence}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.STREET_HOUSE")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>{tempUser.street_house}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.APARTMENT_NUMBER")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>{tempUser.apartment_number}</span>
              </div>
            </label>
          </div>

          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.COUNTRY_OF_RESIDENCE")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>{tempUser.country_of_residence}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.INSOLVENCY")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              >
                <span>
                  {tempUser.insolvency === 0 && t<string>("SHOW_USER.YES")}
                </span>
                <span>
                  {tempUser.insolvency === 1 && t<string>("SHOW_USER.NO")}
                </span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}
              >
                {t<string>("SHOW_USER.BANK")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}
              ></div>
            </label>
          </div>
          {tempUser.loan_sum !== null && (
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}
                >
                  {t<string>("SHOW_USER.LOAN_SUM")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}
                >
                  <span>{tempUser.loan_sum}</span>
                </div>
              </label>
            </div>
          )}
          <div className="about__form-box about-box">
            <h2 className="about__title about__title-adm">
              {t<string>("SHOW_USER.DOCUMENTS")}
            </h2>
            {documents?.payload?.collection.length > 0 ? (
              <div className="about-box__adm-docs">
                {documents?.payload?.collection.map((document: any) => (
                  <div
                    className="about-box__file-block file-block"
                    key={document.id}
                  >
                    <img
                      className="file-block__icon"
                      src={addedFile}
                      alt="file"
                    />
                    <span className="file-block__txt">
                      <span
                        className="file-block__txt-underline"
                        onClick={async () => {
                          PrivateService.downloadDocument(
                            document.id,
                            document.name
                          );
                        }}
                      >
                        {document.name}
                      </span>{" "}
                      {t<string>("PROFILE.ADDED")}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="about-box__file-block file-block">
                <span className="file-block__txt">
                  {t<string>("SHOW_USER.NO_DOCUMENTS")}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowUserModal;
