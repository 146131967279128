import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Auth } from "../../../core/models";
import { UIRoutes } from "../../../core/router";

import * as Yup from "yup";
import classNames from "classnames";
import AuthService from "../../../core/services/auth.service";

import passwordEye from "../../../assets/photos/all/password-eye.svg";
import successIcon from "../../../assets/photos/all/success.svg";
import { spacesRemover } from "../../../core/functions/funtions";
import { useTranslation } from "react-i18next";

const SetPasswordForm = () => {
  const [sentSuccess, setSentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const { t } = useTranslation();
  const { id, code } = useParams();
  const navigate = useNavigate();

  const valueFormValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[#?!@$%^&*-])(?!.* ).{8,}$/,
        "8 characters, at least one digit, one lowercased and one uppercased letter, one special symbol (#?!@$%^&*-)"
      ),
    password_confirmation: Yup.string()
      .required("Field is required")
      .oneOf([Yup.ref("password"), null], "Doesn't match password"),
  });

  const formikForm = useFormik<{
    password: string;
    password_confirmation: string;
  }>({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: (values: Auth.SetPassword) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    try {
      const response = await AuthService.setPassword(
        values,
        Number(id),
        Number(code)
      );
      setLoading(false);
      setSentSuccess(true);
      formikForm.resetForm();
      navigate(`/${UIRoutes.ACCOUNT}`);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      setLoading(false);
      toast.error(errors?.response?.data?.message);
    }
  };

  return (
    <div className="set-password">
      <div className="set-password__container">
        {sentSuccess ? (
          <div className="set-password__container-success success-pop">
            <img
              className="success-pop__icon"
              src={successIcon}
              alt="success"
            />
            <p className="success-pop__info">
              {t<string>("AUTH.PASSWORD_CREATED")}
            </p>
          </div>
        ) : (
          <form
            onSubmit={formikForm.handleSubmit}
            className="set-password__container-form"
          >
            <div className="auth__fields-input data-input">
              <label
                className={classNames({
                  "data-input__details": true,
                  "data-input__details-touched":
                    formikForm.touched.password && formikForm.errors.password,
                })}
              >
                {t<string>("AUTH.PASSWORD")}
              </label>
              <input
                type={password ? "text" : "password"}
                className={classNames({
                  "data-input__field": true,
                  "data-input__field-touched":
                    formikForm.touched.password && formikForm.errors.password,
                  "data-input__field-filled": formikForm.values.password,
                })}
                placeholder={t<string>("AUTH.PASSWORD_PLACEHOLDER")}
                autoComplete="on"
                value={formikForm.values.password}
                onChange={(event) => {
                  formikForm.setFieldValue(
                    "password",
                    spacesRemover(event.target.value)
                  );
                }}
              />
              {formikForm.touched.password && formikForm.errors.password && (
                <div className="form-control-error">
                  {formikForm.errors.password}
                </div>
              )}
              <button
                className="data-input__eye"
                type="button"
                onClick={() => setPassword(!password)}
              >
                <img src={passwordEye} alt="close eye" />
              </button>
            </div>
            <div className="auth__fields-input data-input">
              <label
                className={classNames({
                  "data-input__details": true,
                  "data-input__details-touched":
                    formikForm.touched.password_confirmation &&
                    formikForm.errors.password_confirmation,
                })}
              >
                {t<string>("AUTH.CONFIRM_PASSWORD")}
              </label>
              <input
                type={confirm ? "text" : "password"}
                className={classNames({
                  "data-input__field": true,
                  "data-input__field-touched":
                    formikForm.touched.password_confirmation &&
                    formikForm.errors.password_confirmation,
                  "data-input__field-filled":
                    formikForm.values.password_confirmation,
                })}
                placeholder={t<string>("AUTH.CONFIRM_PASSWORD")}
                autoComplete="on"
                value={formikForm.values.password_confirmation}
                onChange={(event) => {
                  formikForm.setFieldValue(
                    "password_confirmation",
                    spacesRemover(event.target.value)
                  );
                }}
              />
              {formikForm.touched.password_confirmation &&
                formikForm.errors.password_confirmation && (
                  <div className="form-control-error">
                    {formikForm.errors.password_confirmation}
                  </div>
                )}
              <button
                className="data-input__eye"
                type="button"
                onClick={() => setConfirm(!confirm)}
              >
                <img src={passwordEye} alt="close eye" />
              </button>
            </div>
            <button className="about__submit-active" type="submit">
              {t<string>("AUTH.SAVE_BUTTON")}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SetPasswordForm;
