import React, { FC } from "react";

import why_us_first from "../../assets/photos/all/why_us_first.svg";
import why_us_second from "../../assets/photos/all/why_us_second.svg";
import why_us_third from "../../assets/photos/all/why_us_third.svg";
import why_us_fourth from "../../assets/photos/all/why_us_fourth.svg";
import { useTranslation } from "react-i18next";

const WhyUs: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="benefits">
      <div className="benefits__container">
        <h1 className="benefits__container-title">
          {t<string>("HOME.WHY_US_TITLE")}
        </h1>
        <div className="benefits__container-property property">
          <div className="property__box">
            <img
              className="property__box-icon"
              src={why_us_first}
              alt="planet"
            />
            <h2 className="property__box-title">
              {t<string>("HOME.WHY_US_FIRST_TITLE")}
            </h2>
            <span className="property__box-txt">
              {t<string>("HOME.WHY_US_FIRST_SUBTITLE")}
            </span>
          </div>
          <div className="property__box">
            <img
              className="property__box-icon"
              src={why_us_second}
              alt="heart"
            />
            <h2 className="property__box-title">
              {t<string>("HOME.WHY_US_SECOND_TITLE")}
            </h2>
            <span className="property__box-txt">
              {t<string>("HOME.WHY_US_SECOND_SUBTITLE")}
            </span>
          </div>
          <div className="property__box">
            <img
              className="property__box-icon"
              src={why_us_third}
              alt="clock"
            />
            <h2 className="property__box-low-title">
              {t<string>("HOME.WHY_US_THIRD_TITLE")}
            </h2>
            <span className="property__box-txt">
              {t<string>("HOME.WHY_US_THIRD_SUBTITLE")}
            </span>
          </div>
          <div className="property__box">
            <img
              className="property__box-icon"
              src={why_us_fourth}
              alt="like"
            />
            <h2 className="property__box-low-title">
              {t<string>("HOME.WHY_US_FOURTH_TITLE")}
            </h2>
            <span className="property__box-txt">
              {t<string>("HOME.WHY_US_FOURTH_SUBTITLE")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
