import React, { FC } from "react";
import CreditsListContainer from "../../../../components/Credits/List";

const CreditsListPage: FC = () => {
  return (
    <CreditsListContainer />
  );
};

export default CreditsListPage;
