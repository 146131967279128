import { useEffect, useState } from "react";

// import { generalQuestions } from "../../core/constants/questions";
import { Public } from "../../core/models";

import minus from "../../assets/photos/home/minus.svg";
import plus from "../../assets/photos/home/plus.svg";
import { useAppSelector } from "../../core/store";
import { useTranslation } from "react-i18next";
import Banner from "../Banner/Banner";
import { Link } from "react-router-dom";


const FAQContainer = () => {
  const [questions, setQuestions] = useState<Public.Question[]>([]);

  const { t } = useTranslation();
  const appLanguage = useAppSelector((state) => state.app.appLanguage);

  useEffect(() => {
    setQuestions(generalQuestions);
  }, [appLanguage]);

  const opener = (index: number) => {
    const newQuestions = [...questions];

    newQuestions[index].opened = !newQuestions[index].opened;

    setQuestions(newQuestions);
  };

  const generalQuestions = [
    {
      id: 1,
      title: {
        en: t<string>("FAQ.WHAT_ASSISTANT_IS_Q"),
        bg: t<string>("FAQ.WHAT_ASSISTANT_IS_Q"),
      },
      desc: {
        en: t<string>("FAQ.WHAT_ASSISTANT_IS_A"),
        bg: t<string>("FAQ.WHAT_ASSISTANT_IS_A"),
      },
      opened: false,
    },
    {
      id: 2,
      title: {
        en: t<string>("FAQ.APPLY_Q"),
        bg: t<string>("FAQ.APPLY_Q"),
      },
      desc: {
        en: {
          en1: t<string>("FAQ.APPLY_A.APPLY_A_1"),
          en2: t<string>("FAQ.APPLY_A.APPLY_A_2"),
          en3: t<string>("FAQ.APPLY_A.APPLY_A_3"),
        },
        bg: {
          bg1: t<string>("FAQ.APPLY_A.APPLY_A_1"),
          bg2: t<string>("FAQ.APPLY_A.APPLY_A_2"),
          bg3: t<string>("FAQ.APPLY_A.APPLY_A_3"),
        },
      },
      opened: false,
    },
    {
      id: 3,
      title: {
        en: t<string>("FAQ.HOW_LONG_Q"),
        bg: t<string>("FAQ.HOW_LONG_Q"),
      },
      desc: {
        en: {
          en1: t<string>("FAQ.HOW_LONG_A.STEP_1"),
          en2: t<string>("FAQ.HOW_LONG_A.STEP_2"),
          en3: t<string>("FAQ.HOW_LONG_A.STEP_3"),
          en4: t<string>("FAQ.HOW_LONG_A.STEP_4"),
          en5: t<string>("FAQ.HOW_LONG_A.STEP_5"),
        },
        bg: {
          bg1: t<string>("FAQ.HOW_LONG_A.STEP_1"),
          bg2: t<string>("FAQ.HOW_LONG_A.STEP_2"),
          bg3: t<string>("FAQ.HOW_LONG_A.STEP_3"),
          bg4: t<string>("FAQ.HOW_LONG_A.STEP_4"),
          bg5: t<string>("FAQ.HOW_LONG_A.STEP_5"),
        },
      },
      opened: false,
    },
    {
      id: 4,
      title: {
        en: t<string>("FAQ.FREE_OF_CHARGE_Q"),
        bg: t<string>("FAQ.FREE_OF_CHARGE_Q"),
      },
      desc: {
        en: t<string>("FAQ.FREE_OF_CHARGE_A"),
        bg: t<string>("FAQ.FREE_OF_CHARGE_A"),
      },
      opened: false,
    },
    {
      id: 5,
      title: {
        en: t<string>("FAQ.BENEFITS_Q"),
        bg: t<string>("FAQ.BENEFITS_Q"),
      },
      desc: {
        en: {
          en1: t<string>("FAQ.BENEFITS_A.BENEFITS_A_1"),
          en2: t<string>("FAQ.BENEFITS_A.BENEFITS_A_2"),
          en3: t<string>("FAQ.BENEFITS_A.BENEFITS_A_3"),
        },
        bg: {
          bg1: t<string>("FAQ.BENEFITS_A.BENEFITS_A_1"),
          bg2: t<string>("FAQ.BENEFITS_A.BENEFITS_A_2"),
          bg3: t<string>("FAQ.BENEFITS_A.BENEFITS_A_3"),
        },
      },
      opened: false,
    },
    {
      id: 6,
      title: {
        en: t<string>("FAQ.OBLIGATED_TO_Q"),
        bg: t<string>("FAQ.OBLIGATED_TO_Q"),
      },
      desc: {
        en: t<string>("FAQ.OBLIGATED_TO_A"),
        bg: t<string>("FAQ.OBLIGATED_TO_A"),
      },
      opened: false,
    },
    {
      id: 7,
      title: {
        en: t<string>("FAQ.BAD_CREDIT_Q"),
        bg: t<string>("FAQ.BAD_CREDIT_Q"),
      },
      desc: {
        en: t<string>("FAQ.BAD_CREDIT_A"),
        bg: t<string>("FAQ.BAD_CREDIT_A"),
      },
      opened: false,
    },
    {
      id: 8,
      title: {
        en: t<string>("FAQ.CREDIT_HISTORY_Q"),
        bg: t<string>("FAQ.CREDIT_HISTORY_Q"),
      },
      desc: {
        en: t<string>("FAQ.CREDIT_HISTORY_A"),
        bg: t<string>("FAQ.CREDIT_HISTORY_A"),
      },
      opened: false,
    },
    {
      id: 9,
      title: {
        en: t<string>("FAQ.REQUIREMENTS_Q"),
        bg: t<string>("FAQ.REQUIREMENTS_Q"),
      },
      desc: {
        en: {
          en1: t<string>("FAQ.REQUIREMENTS_A.REQUIREMENTS_A_1"),
          en2: t<string>("FAQ.REQUIREMENTS_A.REQUIREMENTS_A_2"),
          en3: t<string>("FAQ.REQUIREMENTS_A.REQUIREMENTS_A_3"),
          en4: t<string>("FAQ.REQUIREMENTS_A.REQUIREMENTS_A_4"),
        },
        bg: {
          bg1: t<string>("FAQ.REQUIREMENTS_A.REQUIREMENTS_A_1"),
          bg2: t<string>("FAQ.REQUIREMENTS_A.REQUIREMENTS_A_2"),
          bg3: t<string>("FAQ.REQUIREMENTS_A.REQUIREMENTS_A_3"),
          bg4: t<string>("FAQ.REQUIREMENTS_A.REQUIREMENTS_A_4"),
        },
      },
      opened: false,
    },
    {
      id: 10,
      title: {
        en: t<string>("FAQ.PERSONAL_DATA_Q"),
        bg: t<string>("FAQ.PERSONAL_DATA_Q"),
      },
      desc: {
        en: {
            title: t<string>("FAQ.PERSONAL_DATA_A"),
            link: <Link to="/privacy-policy">{t<string>("COMMON.PRIVACY_POLICY")}</Link>
          },
          bg: {
            title: t<string>("FAQ.PERSONAL_DATA_A"),
            link: <Link to="/privacy-policy">{t<string>("COMMON.PRIVACY_POLICY")}</Link>
          },
      },
      opened: false,
    },
    {
      id: 11,
      title: {
        en: t<string>("FAQ.TYPES_OF_CRED_Q"),
        bg: t<string>("FAQ.TYPES_OF_CRED_Q"),
      },
      desc: {
        en: t<string>("FAQ.TYPES_OF_CRED_A"),
        bg: t<string>("FAQ.TYPES_OF_CRED_A"),
      },
      opened: false,
    },
    {
      id: 12,
      title: {
        en: t<string>("FAQ.OPTIONS_Q"),
        bg: t<string>("FAQ.OPTIONS_Q"),
      },
      desc: {
        en: t<string>("FAQ.OPTIONS_A"),
        bg: t<string>("FAQ.OPTIONS_A"),
      },
      opened: false,
    },
    {
      id: 13,
      title: {
        en: t<string>("FAQ.REMOTELY_Q"),
        bg: t<string>("FAQ.REMOTELY_Q"),
      },
      desc: {
        en: t<string>("FAQ.REMOTELY_A"),
        bg: t<string>("FAQ.REMOTELY_A"),
      },
      opened: false,
    },
  ];
  

  const descRender = (question: Public.Question) => {
    const desc = appLanguage === 'en' ? question.desc.en : question.desc.bg;
    if (typeof desc === 'string') {
      return <p className="holder__description-text">{desc}</p>;
    } else if (typeof desc === 'object') {
      return (
        <div>
          {Object.keys(desc).map((key) => (
            <p key={key} className="holder__description-text">
              {desc[key]}
            </p>
          ))}
        </div>
      );
      
    } 
    return null; 
  };
  
  return (
    <div className="faq-container">
      <div className="faq-container-header">
        <span className="faq-container-header-pretitle">
          {t<string>("FAQ.SUB_TITLE")}
        </span>
        <h1 className="faq-container-header-title">{t<string>("FAQ.TITLE")}</h1>
      </div>
      <div className="faq-container__questions-main">
        <div className="questions__collapse">
          {questions.map((question: Public.Question, index: number) => (
            <div
              onClick={() => opener(index)}
              className="questions__collapse-dropdown dropdown"
              key={question.id}
            >
              <div className="dropdown__holder holder">
                <div className="holder__title">
                  <p className="holder__title-text">
                    {appLanguage === "en"
                      ? question.title.en
                      : question.title.bg}
                  </p>
                  <button
                    className="holder__title-controller"
                    onClick={(e) => {
                      e.stopPropagation();
                      opener(index);
                    }}
                  >
                    {!question.opened ? (
                      <img src={plus} alt="plus" />
                    ) : (
                      <img src={minus} alt="minus" />
                    )}
                  </button>
                </div>
                {question.opened && (
                  <div className="holder__description">
                    <div className="holder__description-line"></div>
                    <div className="holder__description-text">
                      {descRender(question)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="faq-container-bottom">
        <Banner />
      </div>
    </div>
  );
};

export default FAQContainer;
