import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactGA from "react-ga4";

import { AppContext } from "../../../App";

import useAuth from "../../../core/hooks/useAuth";
import { PrivateUIRoutes, UIRoutes } from "../../../core/router";
import { currencyFormat, PMT } from "../../../core/functions/funtions";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { style } from "../../../core/constants/style";
import { useAppDispatch } from "../../../core/store";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../core/services/enums";
import {
  setCalculator,
  setLoginRoute,
} from "../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const QuickLoans: FC = () => {
  const { isAuth } = useAuth();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const appContext = useContext(AppContext);

  const { setCalculationExample, setSelectionType } = appContext;

  const valueFormValidationSchema = Yup.object().shape({
    amount_1: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .matches(/[^0]+/, "Sum can't be equal 0")
      .max(30, "Max 30 symbols"),
    term_1: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
  });

  const formikForm = useFormik<{
    type: string;
    amount_1: number;
    term_1: number;
  }>({
    initialValues: {
      type: "3",
      amount_1: 2000,
      term_1: 3,
    },

    validationSchema: valueFormValidationSchema,
    onSubmit: async (values: any) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = (values: any) => {
    const checkedValues = {
      ...values,
      amount_1:
        formikForm.values.amount_1 < 250
          ? 250
          : Math.ceil(formikForm.values.amount_1 / 100) * 100,
    };
    localStorage.setItem("calcResult", JSON.stringify(checkedValues));
    dispatch(setCalculator(checkedValues));
    dispatch(setLoginRoute(true));

    ReactGA.event({
      category: "calc_public",
      action:
        process.env.REACT_APP_ANALYTICS_NAME === ""
          ? "calc_public"
          : `calc_public_${process.env.REACT_APP_ANALYTICS_NAME}`,
    });
    navigate(`/${UIRoutes.RESULTS_LIST}`);
    // if (isAuth) {
    //   navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
    // } else {
    //   dispatch(setModalType(AuthSelectionType.Auth));
    //   setSelectionType(AuthSelectionType.Register);
    //   dispatch(setModal(true));
    //   window.scrollTo(0, 0);
    //   document.body.style.overflow = "hidden";
    //   localStorage.setItem(
    //     "prevPage",
    //     `/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`
    //   );
    // }
  };

  const perMonth = () => {
    let rate = 30 / 100 / 12;
    let nper = formikForm.values.term_1;
    let pv = -formikForm.values.amount_1;

    return PMT(rate, nper, pv, 0, 0).toFixed(2);
  };

  const handleExample = () => {
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.calcExample));
    setCalculationExample(6);
  };

  const addAmount = () => {
    if (formikForm.values.amount_1 < 10000) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 + 50);
    }
    if (10000 - formikForm.values.amount_1 < 200) {
      formikForm.setFieldValue("amount_1", 10000);
    }
  };

  const removeAmount = () => {
    if (formikForm.values.amount_1 > 250) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 - 50);
    }
    if (formikForm.values.amount_1 - 200 < 250) {
      formikForm.setFieldValue("amount_1", 250);
    }
  };

  const addTerm = () => {
    if (formikForm.values.term_1 < 12) {
      formikForm.setFieldValue("term_1", +formikForm.values.term_1 + 1);
    }
  };
  const removeTerm = () => {
    if (formikForm.values.term_1 > 3) {
      formikForm.setFieldValue("term_1", +formikForm.values.term_1 - 1);
    }
  };

  const coercion = (e: any, maxValue: number) => {
    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue("amount_1", maxValue);
    } else {
      formikForm.setFieldValue("amount_1", valueNum);
    }
  };

  return (
    <div className="calc">
      <form onSubmit={formikForm.handleSubmit} className="calc__form">
        <div className="calc__form-container calc-container">
          <div
            className="calc-container__ranges ranges"
            onBlur={() => {
              if (formikForm.values.amount_1 < 250) {
                formikForm.setFieldValue("amount_1", 250);
              }
            }}
          >
            <div className="ranges__controls">
              <label className="ranges__controls-desc">
                {t<string>("CALCULATOR.LOAN_AMOUNT")}
              </label>
              <label className="ranges__controls-field">
                <input
                  type="text"
                  className={classNames({
                    "ranges__controls-field-put": true,
                    "ranges__controls-field-put__l": true,
                  })}
                  value={currencyFormat(formikForm.values.amount_1)}
                  onChange={(event) => {
                    coercion(event, 10000);
                  }}
                />
                {t<string>("CALCULATOR.BGN")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeAmount}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  type="range"
                  min={250}
                  max={10000}
                  step={100}
                  className="range__controls-input range-wrapper__input"
                  {...formikForm.getFieldProps("amount_1")}
                />
                <button
                  onClick={addAmount}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="ranges__inform">
              <span className="ranges__inform-text">250 {t<string>("CALCULATOR.BGN")}</span>
              <span className="ranges__inform-text">10 000 {t<string>("CALCULATOR.BGN")}</span>
            </div>
          </div>

          <div
            className="calc__form-ranges ranges"
            onBlur={() => {
              if (formikForm.values.term_1 < 3) {
                formikForm.setFieldValue("term_1", 3);
              }
            }}
          >
            <div className="ranges__controls">
              <label className="ranges__controls-desc">
                {t<string>("CALCULATOR.LOAN_TERM")}
              </label>
              <label className="ranges__controls-field">
                <input
                  className="ranges__controls-field-put ranges__controls-field-term"
                  type="text"
                  value={formikForm.values.term_1}
                  onChange={(e) => {
                    let valueEx = e.target.value.replace(/[^0-9]/g, "");

                    e.target.value = valueEx;

                    let value = Number(e.target.value);

                    if (e.target.value[0] !== "0") {
                      value > 12 || value < 0 || e.target.value === "0"
                        ? formikForm.setFieldValue("term_1", "12")
                        : formikForm.setFieldValue("term_1", e.target.value);
                      e.target.value === "" &&
                        formikForm.setFieldValue("term_1", "3");
                    }
                  }}
                />
                {t<string>("CALCULATOR.MONTHS")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeTerm}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  type="range"
                  min={3}
                  max={12}
                  step={1}
                  className="range__controls-input range-wrapper__input"
                  {...formikForm.getFieldProps("term_1")}
                />
                <button
                  onClick={addTerm}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="ranges__inform">
              <span className="ranges__inform-text">
                3 {t<string>("CALCULATOR.MONTHS")}
              </span>
              <span className="ranges__inform-text">
                12 {t<string>("CALCULATOR.MONTHS")}
              </span>
            </div>
          </div>
        </div>

        <div className="calc__form-actions info-actions">
          <p className="info-actions__text">
            {t<string>("CALCULATOR.MONTHLY_PAYMANENT")}
            <span className="info-actions__text-star">*</span>:
            <span className="info-actions__text-bolder">
              {perMonth()} <span> {t<string>("CALCULATOR.BGN")}/{t<string>("CALCULATOR.MONTHS")}</span>
            </span>
          </p>
          <span className="info-actions__small">
            {t<string>("CALCULATOR.INFORM")}
            <span onClick={handleExample} className="calc-hint">
              {t<string>("CALCULATOR.INFORM_LINK")}
            </span>
          </span>
          <span className="info-actions__small">
            {t<string>("CALCULATOR.INFORM_TEXT")}
          </span>
          <button type="submit" className="info-actions__button">
            {/* {isAuth ? (
              <span>{t<string>("CALCULATOR.BUTTON_LOGGED")}</span>
            ) : (
              <span>{t<string>("CALCULATOR.BUTTON")}</span>
            )} */}
            <span>{t<string>("CALCULATOR.BUTTON_LOGGED")}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default QuickLoans;
