import React, { FC } from "react";
import { Link } from "react-router-dom";

interface IProps {
  children: React.ReactNode;
}

const AuthBase: FC<IProps> = ({ children }) => {
  return <div className="auth-layout-template">{children}</div>;
};

export default AuthBase;
