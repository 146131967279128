import { Private } from "../models";

export const PMT = (
  rate: number,
  nper: number,
  pv: number,
  fv: number,
  type: number
) => {
  let pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (rate === 0) return -(pv + fv) / nper;

  pvif = Math.pow(1 + rate, nper);
  pmt = (-rate * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + rate;
  return pmt;
};

export function getPreviousDay(date = new Date()) {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  return previous;
}

export function currencyFormat(num: number) {
  return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

export function spacesRemover(query: string) {
  return query
    .split("")
    .filter((el) => el !== " ")
    .join("");
}

export const filterPhoneNum = (phoneNum: string) =>
"+" + phoneNum.replace(/\D/g, "");


export const compareDates = (
  a: Private.BlogCategory,
  b: Private.BlogCategory
) => {
  let newA = new Date(a.created_at);
  let newB = new Date(b.created_at);

  if (newA < newB) return 1;
  if (newA > newB) return -1;

  return 0;
};

export const chatOpener = () => {
  const iframe: any = document.getElementById("chat-widget-minimized");
  if (iframe) {
    iframe.contentWindow.document.getElementsByTagName("button")[0].click();
  }
};

export function initializeFacebookSdk() {
  if (window.FB === undefined) {
    window.fbAsyncInit = function () {
      initialize();
    };
  } else {
    initialize();
  }

  function initialize() {
    window.FB.init({
      appId: "530718005569982",
      cookie: true,
      xfbml: true,
      version: "v16.0",
    });
  }
}

export const clearCache = () => {
  if ("caches" in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
};


const setInitialValues = (type: number) => {
  switch (type) {
    case 1:
      return {
        type: "1",
        amount_1: 10000,
        term_1: 36,
      };
    case 7:
      return {
        type: "7",
        amount_1: 10000,
        term_1: 12,
      };
    case 2:
      return {
        type: "2",
        amount_1: 10000,
        term_1: 36,
      };
    case 3:
      return {
        type: "3",
        amount_1: 10000,
        current_amount: 10000,
        term_1: 36,
      };
    case 4:
      return {
        amount_1: 10000,
        term_1: 36,
        totalAmount: 10000,
        type: "4",
      };
    case 5:
      return {
        type: "5",
        amount_1: 30000,
        term_1: 72,
      };
    case 6:
      return {
        type: "6",
        amount_1: 2000,
        term_1: 3,
      };
  }
};

export const setTypeForResult = (type: number) => {
  if (localStorage.calcResult) {
    localStorage.removeItem("calcResult");
    const calcResult = setInitialValues(type);
    localStorage.setItem("calcResult", JSON.stringify(calcResult));
  } else {
    const calcResult = setInitialValues(type);
    localStorage.setItem("calcResult", JSON.stringify(calcResult));
  }
};