import React, { FC } from "react";
import BanksEditContainer from "../../../../components/Banks/Edit";

const BanksEditPage: FC = () => {
  return (
    <>
      <BanksEditContainer />
    </>
  );
};

export default BanksEditPage;
