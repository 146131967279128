import { FC } from "react";
import { Link } from "react-router-dom";

import BanksList from "./BanksList/BanksList";

import { PrivateUIRoutes, UIRoutes } from "../../../core/router";

const BanksListContainer: FC = () => {
  return (
    <div>
      <Link to={`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.BANKS}/create`}>
        Create
      </Link>
      <BanksList />
    </div>
  );
};

export default BanksListContainer;
