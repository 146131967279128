import React from "react";

import CreditsBanner from "./Main/CreditsBanner";
import CreditsMainList from "./Main/CreditsMainList";
import Banner from "../Banner/Banner";

const FinanceContainer = () => {
  return (
    <div className="credits-container">
      <CreditsBanner />
      <CreditsMainList />
      <div className="credits-container__banner-bottom">
        <Banner />
      </div>
    </div>
  );
};

export default FinanceContainer;
