import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import { useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";

import Banner from "../../../Banner";
import FeedbackCard from "../FeedbackCard";

import { Private, Public } from "../../../../core/models";
import { useAppDispatch, useAppSelector } from "../../../../core/store";
import { APIRoutes } from "../../../../core/http";
import { UIRoutes } from "../../../../core/router";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import PrivateService from "../../../../core/services/private.service";

import routeArrow from "../../../../assets/photos/ratings/route-arrow.svg";
import bankIcon from "../../../../assets/photos/profile/bank-icon.svg";
import sortingArrow from "../../../../assets/photos/all/sorting-arrow.svg";
import success from "../../../../assets/photos/ratings/success.svg";
import close from "../../../../assets/photos/ratings/close.svg";
import CommonService from "../../../../core/services/common.service";

const BankFeedBackForm: FC = () => {
  const [reviewField, setReviewField] = useState(false);
  const [modalPopUp, setModalPopUp] = useState(false);
  const [feedbackmodal, setModalFeedback] = useState(false);
  const [sent, setSent] = useState(false);
  const [bank, setBank] = useState<Public.Bank>();
  const [isResponseOpen, setIsResponseOpen] = useState(true);
  const [ratingValue, setRatingValue] = useState(0);
  const [filter, setFilter] = useState({ "filter[status][=]": "approved" });
  const [sorter, setSorter] = useState<any>({ "order[rating]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });

  const { t } = useTranslation();

  const appLanguage = useAppSelector((state) => state.app.appLanguage);
  const { id } = useParams();

  useHttpGet<any>(`${APIRoutes.PUBLIC_BANKS}/${id}`, {
    resolve: (response: any) => {
      setBank(response?.payload);
    },
  });

  const { fetchedData: reviews } = useHttpGet<any>(
    `${APIRoutes.PUBLIC_BANKS}/${id}/reviews`,
    {
      dependencies: [pagination, sorter, filter],
      query: { ...pagination, ...sorter, ...filter },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("savedUser") || "{}");

    if (user.hasOwnProperty("first_name") || user.hasOwnProperty("email")) {
      formikForm.setFieldValue("name", user.first_name);
      formikForm.setFieldValue("email", user.email);
    }
  }, []);

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};
    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  const setPaginationPage = () => {
    setPagination(() => {
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 };
      } else {
        return { ...pagination, per_page: 50 };
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const handleResponse = () => {
    setIsResponseOpen((prev) => !prev);
  };

  const handleRating = (val: number) => {
    setRatingValue(val);

    switch (val) {
      case 1:
        formikForm.setFieldValue("rating", 1);
        break;
      case 2:
        formikForm.setFieldValue("rating", 2);
        break;
      case 3:
        formikForm.setFieldValue("rating", 3);
        break;
      case 4:
        formikForm.setFieldValue("rating", 4);
        break;
      case 5:
        formikForm.setFieldValue("rating", 5);
        break;
      default:
        throw new Error("error!");
    }
  };

  const valueFormValidationSchema = Yup.object().shape({
    name: Yup.string().required(`${t<string>("RATING.REQUIRED_FIELD")}`),
    email: Yup.string().required(`${t<string>("RATING.REQUIRED_FIELD")}`),
    rating: Yup.number()
      .required(`${t<string>("RATING.REQUIRED_FIELD")}`)
      .notOneOf([0], `${t<string>("RATING.REQUIRED_FIELD")}`),
    comment: Yup.string()
      .required(`${t<string>("RATING.COMMENT_REQUIRED")}`)
      .max(720, "Max 720 symbols"),
  });

  const formikForm = useFormik<{
    name: string;
    email: string;
    rating: string | number;
    comment: string;
  }>({
    initialValues: {
      name: "",
      email: "",
      rating: "",
      comment: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: Private.Review) => {
    try {
      const response = await PrivateService.leaveFeedback(values, Number(id));
      toast.success(`${t<string>("RATING.REVIEW_SUCCESS")}`);
      setRatingValue(0);

      formikForm.resetForm();
      setReviewField(false);
      setSent(true);
      ReactGA.event({
        category: "feedback",
        action:
          process.env.REACT_APP_ANALYTICS_NAME === ""
            ? "feedback"
            : `feedback_${process.env.REACT_APP_ANALYTICS_NAME}`,
      });
      window.scrollTo(0, 0);
    } catch (errors: any) {
      CommonService.showErrors(errors?.response?.data?.payload);
    }
  };

  return (
    <div className="bank-feedback">
      <div
        className={classNames({
          "bank-feedback__modal": true,
          "bank-feedback__modal-open": modalPopUp,
        })}
      >
        <div
          className="bank-feedback__modal-bg"
          onClick={() => {
            setModalPopUp(false);
            document.body.style.overflowY = "scroll";
          }}
        ></div>
        <div className="bank-feedback__modal-content">
          <div className="feedback-modal">
            <img
              className="feedback-modal__image"
              src={success}
              alt="success"
            />
            <h2 className="feedback-modal__bolder">
              {t<string>("RATING.FEEDBACK")}
            </h2>
            <span className="feedback-modal__lighter">
              {t<string>("RATING.THANK_YOU")}
            </span>
          </div>
        </div>
      </div>
      <div
        className={classNames({
          "bank-feedback__modal": true,
          "bank-feedback__modal-open": feedbackmodal,
        })}
      >
        <div
          className="bank-feedback__modal-bg"
          onClick={() => {
            setModalFeedback(false);
            setSent(false);
            document.body.style.overflowY = "scroll";
          }}
        ></div>
        {sent ? (
          <div className="bank-feedback__modal-content">
            <div className="feedback-modal">
              <img
                className="feedback-modal__image"
                src={success}
                alt="success"
              />
              <h2 className="feedback-modal__bolder">
                {t<string>("RATING.FEEDBACK")}
              </h2>
              <span className="feedback-modal__lighter">
                {t<string>("RATING.THANK_YOU")}
              </span>
            </div>
          </div>
        ) : (
          <form
            onSubmit={formikForm.handleSubmit}
            className="bank-feedback__container-form reviews-form"
          >
            <div className="reviews-form__desc">
              <p className="reviews-form__desc-title">
                {t<string>("RATING.WORK")}
              </p>
              <span className="reviews-form__desc-text">
                {t<string>("RATING.SCALE")}
              </span>
              {formikForm.touched.rating && formikForm.errors.rating && (
                <div className="form-control-error">
                  {t<string>(formikForm.errors.rating)}
                </div>
              )}
              <Rating
                className="reviews-form__desc-stars"
                initialValue={0}
                readonly={false}
                size={32}
                allowTitleTag={false}
                emptyClassName="hovered-star"
                onClick={handleRating}
              />
            </div>
            <div className="reviews-form__line"></div>
            <div className="reviews-form__info">
              <label className="reviews-form__info-field reviews-user-info">
                <span className="reviews-user-info__title">
                  {t<string>("PROFILE.FIRST_NAME")}
                </span>
                <input
                  className={classNames({
                    "reviews-user-info__txt": true,
                    "reviews-user-info__txt-error":
                      formikForm.touched.name && formikForm.errors.name,
                  })}
                  placeholder="Your name"
                  {...formikForm.getFieldProps("name")}
                />
                {formikForm.touched.name && formikForm.errors.name && (
                  <div className="form-control-error">
                    {t<string>(formikForm.errors.name)}
                  </div>
                )}
              </label>
              <label className="reviews-form__info-field reviews-user-info">
                <span className="reviews-user-info__title">
                  {t<string>("PROFILE.EMAIL")}
                </span>
                <input
                  className={classNames({
                    "reviews-user-info__txt": true,
                    "reviews-user-info__txt-error":
                      formikForm.touched.email && formikForm.errors.email,
                  })}
                  placeholder="Your email"
                  {...formikForm.getFieldProps("email")}
                />
                {formikForm.touched.email && formikForm.errors.email && (
                  <div className="form-control-error">
                    {t<string>(formikForm.errors.email)}
                  </div>
                )}
              </label>
            </div>
            <div className="reviews-form__own">
              <span className="reviews-form__own-about">
                {t<string>("RATING.TELL_US")}
              </span>
              <textarea
                className={classNames({
                  "reviews-form__own-inp": true,
                  "reviews-form__own-error":
                    formikForm.touched.comment && formikForm.errors.comment,
                })}
                placeholder={t<string>("RATING.REVIEW_TXT")}
                {...formikForm.getFieldProps("comment")}
              />
              {formikForm.touched.comment && formikForm.errors.comment && (
                <div className="form-control-error">
                  {t<string>(formikForm.errors.comment)}
                </div>
              )}
              <button className="reviews-form__own-btn">
                {t<string>("RETING.SEND")}
              </button>
              
            </div>
            <button
              className="reviews-form__close"
              onClick={() => {
                setModalFeedback(false);
                document.body.style.overflowY = "scroll";
              }}
            >
              <img src={close} alt="close" />
            </button>
          </form>
        )}
      </div>
      <div className="bank-feedback__container">
        <div className="bank-feedback__container-route feedback-route">
          <div className="feedback-route__box">
            <Link to={`/banks-rating`} className="feedback-route__box-back">
              {t<string>("RATING.RATING")}
            </Link>
            <img src={routeArrow} alt="arrow" />
            <Link
              to={`${UIRoutes.BANKS_RATING}/${id}`}
              className="feedback-route__box-current"
            >
              {appLanguage === "en"
                ? bank?.translations.en.name
                : bank?.translations.bg.name}
            </Link>
          </div>
        </div>
        <div className="bank-feedback__container-about feedback-about">
          <div className="feedback-about__bank">
            <div className="feedback-about__bank-box about-bank-box">
              <div className="about-bank-box__info">
                <img
                  src={bank?.logo_name ? bank.logo_path : bankIcon}
                  alt="logo"
                  // style={{
                  // width: "100px",
                  // height: "100px",
                  // }}
                />
                {/* <p className="about-bank-box__info-txt">
                  {appLanguage === "en"
                    ? bank?.translations.en.name
                    : bank?.translations.bg.name}
                </p> */}
              </div>
              {/* <h2 className="about-bank-box__abt">
                {t<string>("RATING.ABOUT")}{" "}
                {appLanguage === "en"
                  ? bank?.translations.en.name
                  : bank?.translations.bg.name}
              </h2> */}
              <div className="about-bank-box__desc">
                <p className="about-bank-box__desc-txt">
                  {appLanguage === "en"
                    ? bank?.translations.en.description
                    : bank?.translations.bg.description}
                </p>
              </div>
            </div>
          </div>
          <div className="feedback-about__contacts">
            <div className="feedback-about__contacts-box feedback-contacts">
              <h2 className="feedback-contacts__title">
                {t<string>("RATING.CONTACTS")}
              </h2>
              <div className="feedback-contacts__item">
                <p className="feedback-contacts__item-bolder">
                  {t<string>("PROFILE.PHONE_NUMBER")}
                </p>
                <span className="feedback-contacts__item-default">
                  {bank?.phone}
                </span>
              </div>
              <div className="feedback-contacts__item">
                <p className="feedback-contacts__item-bolder">
                  {t<string>("RATING.OFFICE")}
                </p>
                <span className="feedback-contacts__item-default">
                  {appLanguage === "en"
                    ? bank?.translations.en.address
                    : bank?.translations.bg.address}
                </span>
              </div>
              <div className="feedback-contacts__item">
                <p className="feedback-contacts__item-bolder">
                  {t<string>("RATING.OFFICIAL")}
                </p>
                <a
                  href={bank?.link}
                  target="_blank"
                  className="feedback-contacts__item-link" rel="noreferrer"
                >
                  {bank?.link}
                </a>
              </div>
            </div>
          </div>
        </div>
        <button
          className="bank-feedback__container-send"
          onClick={() => {
            setModalFeedback(true);
          }}
        >
          {t<string>("RATING.WRITE")}
        </button>
        <div className="bank-feedback__container-reviews bank-reviews">
          <div className="bank-reviews__rate feedback-rate">
            <div className="feedback-rate__box">
              <p className="feedback-rate__box-title">
                {t<string>("RATING.RATING_REVIEWS")}
              </p>
              <div className="feedback-rate__box-ratings feedback-box-ratings">
                <div className="feedback-box-ratings__info">
                  <div className="feedback-box-ratings__info-block rate-info-block">
                    <span className="rate-info-block__txt">{bank?.rating}</span>
                    <Rating
                      initialValue={bank?.rating}
                      readonly={true}
                      size={32}
                      allowTitleTag={false}
                      className="rate-info-block__stars st"
                    />
                  </div>
                  <span className="feedback-box-ratings__info-additional">
                    {t<string>("RATING.AVERAGE")}
                  </span>
                </div>
                <div className="feedback-box-ratings__info">
                  <span className="feedback-box-ratings__info-txt">
                    {Number(bank?.reviews_count) < 2
                      ? `${bank?.reviews_count} ${t<string>("RATING.REVIEW")}`
                      : `${bank?.reviews_count} ${t<string>("RATING.REVIEWS")}`}
                  </span>
                  <p className="feedback-box-ratings__info-additional">
                    {t<string>("RATING.DURING")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bank-reviews__leave-reviews leave-reviews">
            <div className="leave-reviews__block">
              <h2 className="leave-reviews__block-title">
                {reviews?.payload?.collection !== 0 ? (
                  <span>{t<string>("RATING.LEAVE")}</span>
                ) : (
                  <span>{t<string>("RATING.FIRST")}</span>
                )}
              </h2>
              <p className="leave-reviews__block-small">
                {t<string>("RATING.EXPERIENCE")}
              </p>
              <button
                className="leave-reviews__block-btn"
                onClick={() => {
                  // if (isAuth) {
                  setReviewField(true);
                  // } else {
                  // setModalType(AuthSelectionType.Auth);
                  // setSelectionType(AuthSelectionType.Login);
                  // window.scrollTo(0, 0);
                  // setModal(true);
                  // document.body.style.overflow = "hidden";
                  // }
                }}
              >
                {t<string>("RATING.WRITE")}
              </button>
            </div>
          </div>
        </div>
        {reviewField && (
          <form
            onSubmit={formikForm.handleSubmit}
            className="bank-feedback__container-form reviews-form"
          >
            <div className="reviews-form__desc">
              <p className="reviews-form__desc-title">
                {t<string>("RATING.WORK")}
              </p>
              <span className="reviews-form__desc-text">
                {t<string>("RATING.SCALE")}
              </span>
              {formikForm.touched.rating && formikForm.errors.rating && (
                <div className="form-control-error">
                  {formikForm.errors.rating}
                </div>
              )}
              <Rating
                className="reviews-form__desc-stars"
                readonly={false}
                size={32}
                allowTitleTag={false}
                onClick={handleRating}
              />
            </div>
            <div className="reviews-form__line"></div>
            <div className="reviews-form__info">
              <label className="reviews-form__info-field reviews-user-info">
                <span className="reviews-user-info__title">
                  {t<string>("RATING.NAME")}
                </span>
                <input
                  className={classNames({
                    "reviews-user-info__txt": true,
                    "reviews-user-info__txt-error":
                      formikForm.touched.name && formikForm.errors.name,
                  })}
                  placeholder={t<string>("RATING.NAME")}
                  {...formikForm.getFieldProps("name")}
                />
                {formikForm.touched.name && formikForm.errors.name && (
                  <div className="form-control-error">
                    {formikForm.errors.name}
                  </div>
                )}
              </label>
              <label className="reviews-form__info-field reviews-user-info">
                <span className="reviews-user-info__title">
                  {t<string>("PROFILE.EMAIL")}
                </span>
                <input
                  className={classNames({
                    "reviews-user-info__txt": true,
                    "reviews-user-info__txt-error":
                      formikForm.touched.email && formikForm.errors.email,
                  })}
                  placeholder={t<string>("RATING.EMAIL")}
                  {...formikForm.getFieldProps("email")}
                />
                {formikForm.touched.email && formikForm.errors.email && (
                  <div className="form-control-error">
                    {formikForm.errors.email}
                  </div>
                )}
              </label>
            </div>
            <div className="reviews-form__own">
              <span className="reviews-form__own-about">
                {t<string>("RATING.TELL_US")}
              </span>
              <textarea
                className={classNames({
                  "reviews-form__own-inp": true,
                  "reviews-form__own-error":
                    formikForm.touched.comment && formikForm.errors.comment,
                })}
                placeholder={t<string>("RATING.REVIEW_TXT")}
                {...formikForm.getFieldProps("comment")}
              />
              {formikForm.touched.comment && formikForm.errors.comment && (
                <div className="form-control-error">
                  {formikForm.errors.comment}
                </div>
              )}
              <div className="reviews-form__buttons">
                <button type="submit" className="reviews-form__own-btn">
                  {t<string>("RATING.SEND")}
                </button>
                <p className="reviews-form__desc-text reviews-form__send">{t<string>("RATING.SEND_DESC")}</p>
              </div>
            </div>
          </form>
        )}
        {bank && reviews?.payload?.collection.length > 0 && (
          <div className="reviews-list">
            <div className="reviews-list__controls">
              <p className="reviews-list__controls-title">
                {t<string>("RATING.REVIEWS_ABOUT")}{" "}
                {appLanguage === "en"
                  ? bank?.translations.en.name
                  : bank?.translations.bg.name}
              </p>
              <div className="reviews-list__controls-sort-by sort-by">
                <div className="sort-by__block">
                  <button
                    onClick={() => {
                      setSorterProperty("order[rating]");
                    }}
                  >
                    <img
                      className="sort-by__block-image"
                      src={sortingArrow}
                      alt="sorting arrows"
                    />
                  </button>
                  <span className="sort-by__block-name">
                    {t<string>("RATING.SORT_BY_RATING")}
                  </span>
                </div>
              </div>
            </div>
            <ul className="reviews-list__content">
              {reviews?.payload?.collection.map((review: Public.BankReview) => (
                <FeedbackCard
                  key={review.id}
                  bank={bank}
                  review={review}
                  handleResponse={handleResponse}
                  isResponseOpen={isResponseOpen}
                />
              ))}
            </ul>
            {reviews?.payload?.cursor.has_more_pages && (
              <button
                className="results-list-more"
                onClick={() => {
                  setPaginationPage();
                }}
              >
                {t<string>("REVIEWS_TABLE.SHOW_MORE")}{" "}
              </button>
            )}
            {!reviews?.payload?.cursor.has_more_pages &&
              reviews?.payload?.collection.length > 10 && (
                <button
                  onClick={setDefaultPagination}
                  className="results-list-more"
                >
                  {t<string>("REVIEWS_TABLE.HIDE_ALL")}
                </button>
              )}
          </div>
        )}
      </div>
      <div className="bank-feedback__banner">
        <Banner />
      </div>
    </div>
  );
};

export default BankFeedBackForm;
