import React, { FC } from "react";
import CreditsEditContainer from "../../../../components/Credits/Edit";

const CreditsEditPage: FC = () => {
  return (
    <>
      <CreditsEditContainer />
    </>
  );
};

export default CreditsEditPage;
