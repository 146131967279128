import { useContext } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../../../../App";

import { UIRoutes } from "../../../../../core/router";

import credits_consumer_bg from "../../../../../assets/photos/credits/credits_consumer_bg.svg";
import credits_consumer_main from "../../../../../assets/photos/all/consumer_main.svg";
import credits_consumer_secondary from "../../../../../assets/photos/all/consumer_secondary.svg";
import credits_consumer_main_mobile from "../../../../../assets/photos/credits/credits_consumer_main_mobile.svg";
import credits_consumer_secondary_mobile from "../../../../../assets/photos/credits/credits_consumer_secondary_mobile.svg";
import { useTranslation } from "react-i18next";
import { setTypeForResult } from "../../../../../core/functions/funtions";

const CreditsConsumerBanner = () => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();

  const { setSelectedLoan } = appContext;

  return (
    <section className="credits-block__consumer-credits consumer-credits">
      <div className="consumer-credits-banner">
        <div className="consumer-credits-banner__main-content main-content">
          <div className="main-content-info">
            <img
              className="consumer-credits-banner__bg-main-mobile"
              src={credits_consumer_main_mobile}
              alt="credits_consumer_main"
            />
            <img
              className="consumer-credits-banner__bg-secondary-mobile"
              src={credits_consumer_secondary_mobile}
              alt="credits_consumer_secondary"
            />
            <h1 className="main-content-info-title">
              {t<string>("LOANS.CONSUMER_LOANS")}
            </h1>
            <p className="main-content-info-subtitle">
              {t<string>("FINANCE.CONSUMER_TEXT_1")}
              <br /> <br />
              {t<string>("FINANCE.CONSUMER_TEXT_2")}
            </p>
            <img
              className="consumer-credits-banner__background"
              src={credits_consumer_bg}
              alt="credits_consumer_bg"
            />
            <img
              className="consumer-credits-banner__background-main"
              src={credits_consumer_main}
              alt="credits_consumer_main"
            />
            <img
              className="consumer-credits-banner__background-secondary"
              src={credits_consumer_secondary}
              alt="credits_consumer_secondary"
            />
            <Link
              to="/result-list"
              onClick={() =>  setTypeForResult(1)}
              className="main-content-btn"
            >
              {t<string>("FINANCE.CONSUMER_BUTTON")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditsConsumerBanner;
