import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { Private } from "../../../../core/models";
import {
  AuthSelectionType,
  CalculatorTypes,
} from "../../../../core/services/enums";

import { currencyFormat } from "../../../../core/functions/funtions";
import { useAppDispatch } from "../../../../core/store";
import {
  setModal,
  setModalType,
} from "../../../../core/store/reducers/modal/modalSlice";

import addedFile from "../../../../assets/photos/all/file-added.svg";
import { setSelectedId } from "../../../../core/store/reducers/app/appDataSlice";

const MyQuestionnairesModal = () => {
  const [id, setId] = useState<null | number>(null);
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { fetchedData: questionnaires } = useHttpGet<any>(
    `${APIRoutes.USER_QUESTIONNAIRES}`,
    {
      dependencies: [sorter],
      query: { ...sorter },
    }
  );

  const totalAmount = (questionnaire: Private.AdminQuestionnaire) => {
    return currencyFormat(
      Number(questionnaire?.amount_1) +
        Number(questionnaire.amount_2) +
        Number(questionnaire.amount_3)
    );
  };

  return (
    <div
      className={classNames({
        modal: true,
        "modal-active": true,
      })}
    >
      <div
        className="modal__bg"
        onClick={() => {
          dispatch(setModal(false));
          document.body.style.overflowY = "scroll";
        }}
      ></div>
      <div className="modal__container modal-details modal-cert">
        <h2 className="modal-details__top">
          {t<string>("WIDGET.YOUR_QUESTIONNAIRES")}
        </h2>
        <div className="modal-logout__files">
          {questionnaires?.payload?.collection
            .filter(
              (questionnaire: Private.AdminQuestionnaire) =>
                questionnaire.certificates.collection.length === 0
            )
            .map((questionnaire: Private.AdminQuestionnaire) => (
              <div
                className={classNames({
                  "about-box__file-block": true,
                  "file-block-select": true,
                  "file-block-select-active": id === questionnaire.id,
                })}
                key={questionnaire.id}
              >
                <div className="file-block-select__box">
                  {id === questionnaire.id ? (
                    <img
                      className="file-block__icon"
                      src={addedFile}
                      alt="file"
                      onClick={() => {
                        dispatch(setSelectedId(null));
                        setId(null);
                      }}
                    />
                  ) : (
                    <div
                      className="file-block-select__box-window"
                      onClick={() => {
                        dispatch(setSelectedId(questionnaire.id));
                        setId(questionnaire.id);
                      }}
                    ></div>
                  )}
                  <div className="file-block__types">
                    {Number(questionnaire.type) ===
                      CalculatorTypes.ConsumerLoan && (
                      <span className="file-block__types-txt">
                        Consumer loan
                      </span>
                    )}
                    {Number(questionnaire.type) ===
                      CalculatorTypes.CarLeasing && (
                      <span className="file-block__types-txt">Car Leasing</span>
                    )}
                    {Number(questionnaire.type) ===
                      CalculatorTypes.Refinancing && (
                      <span className="file-block__types-txt">Refinancing</span>
                    )}
                    {Number(questionnaire.type) === CalculatorTypes.Merging && (
                      <span className="file-block__types-txt">
                        Merging loans
                      </span>
                    )}
                    {Number(questionnaire.type) ===
                      CalculatorTypes.Mortgage && (
                      <span className="file-block__types-txt">Mortgage</span>
                    )}
                    {Number(questionnaire.type) === CalculatorTypes.Quick && (
                      <span className="file-block__types-txt">Quick loan</span>
                    )}
                    {Number(questionnaire.type) ===
                      CalculatorTypes.CreditCards && (
                      <span className="file-block__types-txt">
                        Credit cards
                      </span>
                    )}
                    <span>
                      {questionnaire.type !== 4
                        ? questionnaire.amount_1
                        : totalAmount(questionnaire)}{" "}
                      BGN
                    </span>
                  </div>
                </div>
                {id === questionnaire.id && (
                  <button
                    className="file-block-select__btn"
                    onClick={() => setId(null)}
                  >
                    <img
                      className="file-block-select__btn-icon"
                      src={require("../../../../assets/photos/all/multiply.png")}
                      alt="multiply"
                    />
                  </button>
                )}
              </div>
            ))}
          <button
            className={classNames({
              about__submit: true,
              "modal-details__btn": true,
              "about__submit-active": id !== null,
            })}
            type="button"
            onClick={() => {
              dispatch(setModalType(AuthSelectionType.downloadCertificates));
            }}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyQuestionnairesModal;
