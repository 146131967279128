import React, { FC } from "react";
import BanksList from "./BanksList";

const BanksListContainer: FC = () => {
  return (
    <div>
      <BanksList />
    </div>
  );
};

export default BanksListContainer;
