import { useEffect, useState } from "react";
import classNames from "classnames";

import addedFile from "../../../../assets/photos/all/file-added.svg";

import { useAppDispatch, useAppSelector } from "../../../../core/store/index";

import {
  setCerts,
  setCertsIds,
} from "../../../../core/store/reducers/documents/documentsSlice";
import {
  setModal,
  setModalType,
} from "../../../../core/store/reducers/modal/modalSlice";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { Private, Public } from "../../../../core/models";
import { AuthSelectionType } from "../../../../core/services/enums";
import PrivateService from "../../../../core/services/private.service";
import { useTranslation } from "react-i18next";

const SelectFromMyCertificates = () => {
  const [selected, setSelected] = useState<any[]>([]);
  const [documents, setDocuments] = useState<Private.Document[]>([]);

  const { certs, certsIds } = useAppSelector((state) => state.document);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useHttpGet<any>(`${APIRoutes.USER_DOCUMENTS}`, {
    resolve: (response: any) => {
      if (response.payload) {
        setDocuments(
          response.payload?.collection.filter(
            (
              docum: Private.Document,
              currIndex: number,
              arr: Private.Document[]
            ) =>
              currIndex ===
              arr.findIndex(
                (doc: Private.Document, index: number) =>
                  doc.name === docum.name
              )
          )
        );
      }
    },
  });

  const fileExist = (doc: File) => {
    return Boolean(
      selected.find((cert: File) => cert.name === doc.name) ||
        certs.find((cert: File) => cert.name === doc.name)
    );
  };

  const addFile = (doc: Public.Document) => {
    setSelected([...selected, { id: doc.id, name: doc.name }]);
  };

  const deleteFile = (doc: any) => {
    setSelected(
      selected.filter((cert: Public.Document) => cert.name !== doc.name)
    );
    dispatch(
      setCertsIds(certsIds.filter((certId: number) => certId !== doc.id))
    );

    if (certs.find((cert: File) => cert.name === doc.name)) {
      dispatch(setCerts(certs.filter((cert: File) => cert.name !== doc.name)));
    }
  };

  const filesHandleSubmit = () => {
    dispatch(setCerts([...certs, ...selected]));

    const newsIds: number[] = [];
    selected.map((file: any) => newsIds.push(file.id));
    dispatch(setCertsIds([...certsIds, ...newsIds]));

    pathChecker()
      ? dispatch(setModalType(AuthSelectionType.downloadCertificates))
      : dispatch(setModal(false));

    setSelected([]);
  };

  const pathChecker = () => {
    return !window.location.pathname.split("/").includes("questionnaire");
  };

  return (
    <div
      className={classNames({
        modal: true,
        "modal-active": true,
      })}
    >
      <div
        className="modal__bg"
        onClick={() => {
          dispatch(setModal(false));
          document.body.style.overflowY = "scroll";
        }}
      ></div>
      <div className="modal__container modal-details modal-docs">
        {pathChecker() && (
          <button
            className="modal-details__back"
            onClick={() =>
              dispatch(setModalType(AuthSelectionType.downloadCertificates))
            }
          >
            Back
          </button>
        )}
        <div className="modal-logout__files">
          {documents.map((document: any, index: number) => (
            <div
              className={classNames({
                "about-box__file-block": true,
                "file-block-select": true,
                "file-block-select-active": fileExist(document),
              })}
              key={index}
            >
              <div className="file-block-select__box">
                {fileExist(document) ? (
                  <img
                    className="file-block__icon"
                    src={addedFile}
                    alt="file"
                  />
                ) : (
                  <div
                    className="file-block-select__box-window"
                    onClick={() => addFile(document)}
                  ></div>
                )}
                <span className="file-block__txt">
                  <span
                    className="file-block__txt-underline"
                    onClick={async () => {
                      PrivateService.downloadDocument(
                        document.id,
                        document.name
                      );
                    }}
                  >
                    {document.name.length > 30
                      ? `${document.name.slice(0, 30)}...`
                      : `${document.name}`}
                  </span>{" "}
                  {t<string>("PROFILE.ADDED")}
                </span>
              </div>
              {fileExist(document) && (
                <button
                  className="file-block-select__btn"
                  onClick={() => deleteFile(document)}
                >
                  <img
                    className="file-block-select__btn-icon"
                    src={require("../../../../assets/photos/all/multiply.png")}
                    alt="multiply"
                  />
                </button>
              )}
            </div>
          ))}
          {selected.length + certs.length > 5 && (
            <span className="modal-details__error">
              {`Selected ${selected.length + certs.length}/5 files`}
            </span>
          )}
          <button
            className={classNames({
              about__submit: true,
              "modal-details__btn": true,
              "about__submit-active":
                true &&
                selected.length + certs.length <= 5 &&
                selected.length > 0,
            })}
            type="button"
            onClick={filesHandleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectFromMyCertificates;
