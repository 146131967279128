import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "../../../../Cards";

import useHttpGet from "../../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../../core/http";
import { Private } from "../../../../../core/models";
import useAuth from "../../../../../core/hooks/useAuth";
import { useAppSelector } from "../../../../../core/store";

const RefinancingInfo = () => {
  const [filter, setFilter] = useState({});
  const [applications, setApplications] = useState<any>([]);
  const [applicationsLength, setApplicationsLength] = useState(1);
  const [userQuestionnaires, setUserQuestionnries] = useState(0);
  const appLanguage = useAppSelector((state) => state.app.appLanguage);
  const {questionnaire} = useAppSelector((state) => state.app);
  const { isAuth } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    setFilter({
      "filter[bank_offer_type_id][=]": "6",
    });
  }, []);

  useHttpGet<any>(APIRoutes.USER_QUESTIONNAIRE, {
    resolve: (response: any) => {
      setUserQuestionnries(response?.payload?.id);
    },
    condition: isAuth === true,
  });

  useHttpGet<any>(APIRoutes.PUBLIC_BANKS_OFFERS, {
    condition: Object.keys(filter).length !== 0,
    dependencies: [filter],
    query: { ...filter },
    resolve: (response: any) => {
      setApplications(response?.payload?.collection);
    },
  });

  const sortedSubmitedApplication = (id: number) => {
    setApplications(
      applications.filter((applications: any) => applications.id !== id)
    );
    setApplicationsLength(() => applications.length - 1);
  };

  return (
    <div className="credits-container-main-content-consumer__info-consumer info-consumer">
      <h1 className="info-consumer-title">
        {t<string>("FINANCE.REFINANCING_DESC_TITLE")}
      </h1>
      <div className="info-consumer-content">
        <span className="refinancing-main-content">
          {t<string>("FINANCE.REFINANCING_DESC_1")}
          <br /> <br />
          {t<string>("FINANCE.REFINANCING_DESC_2")}
        </span>
      </div>

      <div className="credits-deals">
        <div className="credits-deals-top">
          <h1 className="credits-deals-top__title">
            {t<string>("FINANCE.REFINANCING_DESC")}
          </h1>
          {/* <button className="credits-deals-top__btn">
            {t<string>("FINANCE.SEE_ALL_OFFERS")}
          </button>
          <button className="credits-deals-top__mobile">
            {t<string>("FINANCE.SEE_ALL")}
          </button> */}
        </div>
        {applications.length !== 0 ? (
          <div className="credits-deals-list">
            {applications
              ?.sort(
                (offer1: Private.BankOffer, offer2: Private.BankOffer) =>
                  offer2.bank.rating - offer1.bank.rating
              )
              .map((offer: any) => (
                <Card
                  offer={offer}
                  questionnaire={userQuestionnaires}
                  sortedApplication={sortedSubmitedApplication}
                  type="bank"
                  passport={questionnaire?.passport}
                  key={offer.id}
                />
              ))}
          </div>
        ) : (
          <div className="essential__list-empty">
            <img
              src={require(`../../../../../assets/photos/all/best_deals_not_result_${
                appLanguage === "en" ? "EN" : "BG"
              }.png`)}
              alt="best deals empty banner"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RefinancingInfo;
