import allianzBank from "../../assets/photos/all/banks-monochrome/Allianz-min.svg";
import bulgarianAmericanBank from "../../assets/photos/all/banks-monochrome/BulgarianAmericanBank-min.svg";
// import bnrPariba from "../../assets/photos/all/banks-monochrome/BNRPariba-min.svg";
import ccbBank from "../../assets/photos/all/banks-monochrome/CCBBANK-min.svg";
import dBank from "../../assets/photos/all/banks-monochrome/Dbank-min.svg";
import fiBank from "../../assets/photos/all/banks-monochrome/Fibank-min.svg";
import iBank from "../../assets/photos/all/banks-monochrome/Ibank-min.svg";
import internationalAssetBank from "../../assets/photos/all/banks-monochrome/internationalAssetBank-min.svg";
import postBank from "../../assets/photos/all/banks-monochrome/Postbank-min.svg";
import teximBank from "../../assets/photos/all/banks-monochrome/TeximBank-min.svg";
import uniCredit from "../../assets/photos/all/banks-monochrome/UniCredit-min.svg";
import obb from "../../assets/photos/all/banks-monochrome/OBB-min.svg";
import tokudaBank from "../../assets/photos/all/banks-monochrome/TokudaBank-min.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import 'swiper/css';
import classNames from "classnames";
const banksLogos = [
  {
    id: 1,
    name: "alianzBank",
    src: allianzBank,
  },
  {
    id: 2,
    name: "Balgarian American Bank",
    src: bulgarianAmericanBank,
  },
  {
    id: 3,
    name: "CCB Bank",
    src: ccbBank,
  },
  {
    id: 4,
    name: "D Bank",
    src: dBank,
  },
  {
    id: 5,
    name: "Fi Bank",
    src: fiBank,
  },
  {
    id: 6,
    name: "I bank",
    src: iBank,
  },
  {
    id: 7,
    name: "International Asset Bank",
    src: internationalAssetBank,
  },
  {
    id: 8,
    name: "Post Bank",
    src: postBank,
  },
  {
    id: 9,
    name: "Texim Bank",
    src: teximBank,
  },
  {
    id: 10,
    name: "Unicredit Bank",
    src: uniCredit,
  },
  {
    id: 12,
    name: "OBB",
    src: obb,
  },
  {
    id: 13,
    name: "Tokuda Bank",
    src: tokudaBank,
  },
  {
    id: 14,
    name: "alianzBank",
    src: allianzBank,
  },
  {
    id: 15,
    name: "Balgarian American Bank",
    src: bulgarianAmericanBank,
  },
  {
    id: 16,
    name: "CCB Bank",
    src: ccbBank,
  },
  {
    id: 17,
    name: "D Bank",
    src: dBank,
  },
  {
    id: 18,
    name: "Fi Bank",
    src: fiBank,
  },
  {
    id: 19,
    name: "I bank",
    src: iBank,
  },
  {
    id: 20,
    name: "International Asset Bank",
    src: internationalAssetBank,
  },
  {
    id: 21,
    name: "Post Bank",
    src: postBank,
  },
  {
    id: 22,
    name: "Texim Bank",
    src: teximBank,
  },
  {
    id: 23,
    name: "Unicredit Bank",
    src: uniCredit,
  },
  {
    id: 25,
    name: "OBB",
    src: obb,
  },
  {
    id: 26,
    name: "Tokuda Bank",
    src: tokudaBank,
  },
];

const BanksLogosList = () => {
  return (
    <div className="banks-list">
      <Swiper
        className="banks-list__swiper"
        slidesPerView={11}
        spaceBetween={20}
        breakpoints={{
          1440: {
            slidesPerView: 11,
          },
          1366: {
            slidesPerView: 8,
          },
          1280: {
            slidesPerView: 8,
          },
          1024: {
            slidesPerView: 6,
          },
          768: {
            slidesPerView: 4,
          },
          550: {
            spaceBetween: 20,
            slidesPerView: 5,
          },
          375: {
            spaceBetween: 20,
            slidesPerView: 3,
          },
          320: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
        }}
        navigation={{
          prevEl: ".swiper-button__prev",
          nextEl: ".swiper-button__next",
        }}
        // navigation
        loop={true}
        modules={[Navigation]}
      >
        {banksLogos.map((bank) => (
          <SwiperSlide key={bank.id} className="banks-list__swiper-slide">
            <img
              src={bank.src}
              alt={bank.name}
              className="banks-list__bank-logo"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={classNames("swiper-button", "swiper-button__prev")}></div>
      <div className={classNames("swiper-button", "swiper-button__next")}></div>
    </div>
  );
};

export default BanksLogosList;
