import { useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Articles from "../../Articles";
import BanksLogosList from "../../BanksLogosList";
import Banner from "../../Banner";
import Guide from "../../Guide";
import Partners from "../../Partners";
import Questions from "../../Questions";
import WhyUs from "../../WhyUs";
import ReactGA from "react-ga4";

import CommonService from "../../../core/services/common.service";
import PublicService from "../../../core/services/public.service";

import CalculatorPage from "../../../areas/public/Calculator/CalculatorPage";
import auth_logo from "../../../assets/photos/all/auth-modal-logo.svg";
import { useTranslation } from "react-i18next";

const iphone_logo = require("../../../assets/photos/all/iphone.png");
const satellite_img = require("../../../assets/photos/all/satellite.png");
const money_logo = require("../../../assets/photos/all/money.png");

const ServicesAnnounce = () => {
  const [isAuth] = useState<boolean>(false);

  const { t } = useTranslation();

  const valueFormValidationSchema = Yup.object().shape({
    full_name: Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(121, "Too many characters"),
    email: Yup.string()
      .email("Invalid email")
      .required(`${t<string>("COMMON.IS_REQUIRED")}`),
    service: Yup.string().required("Service type is required"),
  });

  const formikForm = useFormik<{
    type: string;
    full_name: string;
    email: string;
    service: string;
  }>({
    initialValues: {
      type: "service",
      full_name: "",
      email: "",
      service: "",
    },

    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    try {
      const response = await PublicService.launchBanners(values);

      formikForm.resetForm();

      toast.success(response?.data?.message);

      ReactGA.event({
        category: `services_banner ${process.env.REACT_APP_ANALYTICS_NAME}`,
        action: "services_banner_submit",
      });
    } catch (errors: any) {
      CommonService.showErrors(errors?.response?.data?.payload);
      toast.error(errors?.response?.data?.message);
    }
  };

  return (
    <>
      {!isAuth && (
        <div
          className={classNames({
            modal: true,
            "modal-active": true,
            "modal__auth-wrapper": isAuth,
          })}
        >
          <BanksLogosList />
          <CalculatorPage />
          <Guide />
          <WhyUs />
          <Partners />
          {/* <Articles /> */}
          <Questions />
          <Banner />
          <div
            className="modal__auth-bg"
            onClick={() => {
              document.body.style.overflowY = "scroll";
            }}
          >
            <div className="modal__auth-container modal-slide">
              <div className="slide-step">
                <div className="slide-step-main">
                  <img
                    className="slide-step-main__logo"
                    src={auth_logo}
                    alt="modal logo"
                  />
                  <h1 className="slide-step-main__title">
                    {t<string>("PREAUTH_SERVICE.TITLE")}
                  </h1>
                  <div className="slide-step-main-subtitle">
                    <img
                      className="slide-step-main-subtitle__logo"
                      src={satellite_img}
                      alt="satellite"
                    />
                    <p className="slide-step-main-subtitle__txt">
                      {t<string>("PREAUTH_SERVICE.DESCRIPTION")}
                    </p>
                  </div>
                  <p className="slide-step-main__copyrights">
                    {t<string>("PREAUTH_SERVICE.DETAILS")}
                  </p>
                </div>
                <div className="slide-step-aside">
                  <img
                    src={money_logo}
                    alt="money-logo"
                    className="slide-step-aside__second"
                  />
                  <img
                    className="slide-step-aside__logo"
                    src={iphone_logo}
                    alt="iphone"
                  />
                </div>
                <form
                  className="slide-step-form"
                  onSubmit={formikForm.handleSubmit}
                >
                  <div
                    className={classNames({
                      "about__form-box about-box": true,
                      "feedback-inp": true,
                    })}
                  >
                    <label className="about-box__field">
                      <span
                        className={classNames({
                          "about-box__field-name": true,
                          "about-box__field-preauth-name": true,
                          "about-box__field-inp-error":
                            formikForm.touched.full_name &&
                            formikForm.errors.full_name,
                          "about-box__field-name-filled":
                            formikForm.values.full_name,
                        })}
                      >
                        {t<string>("PREAUTH_SERVICE.FULL_NAME")}
                      </span>
                      <input
                        className={classNames({
                          "about-box__field-inp": true,
                          "about-box__field-preauth": true,
                          "about-box__field-inp-touched":
                            formikForm.touched.full_name &&
                            formikForm.errors.full_name,
                          "about-box__field-inp-filled":
                            formikForm.values.full_name,
                        })}
                        placeholder={t<string>("PREAUTH_SERVICE.FULL_NAME")}
                        {...formikForm.getFieldProps("full_name")}
                      />
                    </label>
                    {formikForm.touched.full_name &&
                      formikForm.errors.full_name && (
                        <div className="form-control-error">
                          {formikForm.errors.full_name}
                        </div>
                      )}
                  </div>
                  <div
                    className={classNames({
                      "about__form-box about-box": true,
                      "feedback-inp": true,
                    })}
                  >
                    <label className="about-box__field">
                      <span
                        className={classNames({
                          "about-box__field-name": true,
                          "about-box__field-preauth-name": true,
                          "about-box__field-inp-error":
                            formikForm.touched.email && formikForm.errors.email,
                          "about-box__field-name-filled":
                            formikForm.values.email,
                        })}
                      >
                        {t<string>("PREAUTH_SERVICE.EMAIL")}
                      </span>
                      <input
                        className={classNames({
                          "about-box__field-inp": true,
                          "about-box__field-preauth": true,
                          "about-box__field-inp-touched":
                            formikForm.touched.email && formikForm.errors.email,
                          "about-box__field-inp-filled":
                            formikForm.values.email,
                        })}
                        placeholder={t<string>("PREAUTH_SERVICE.EMAIL")}
                        {...formikForm.getFieldProps("email")}
                      />
                    </label>
                    {formikForm.touched.email && formikForm.errors.email && (
                      <div className="form-control-error">
                        {formikForm.errors.email}
                      </div>
                    )}
                  </div>
                  <div
                    className={classNames({
                      "about__form-box about-box": true,
                      "feedback-inp": true,
                    })}
                  >
                    <label className="about-box__field about__form-box about-box">
                      <span
                        className={classNames({
                          "about-box__field-preauth-name": true,
                          "about-box__field-name": true,
                          "about-box__field-inp-error":
                            formikForm.touched.service &&
                            formikForm.errors.service,
                          "about-box__field-name-filled":
                            formikForm.values.service,
                        })}
                      >
                        {t<string>("PREAUTH_SERVICE.SECTION")}
                      </span>
                      <select
                        className={classNames({
                          "about-box__field-inp": true,
                          "slide-step-form__bank-select": true,
                          "about-box__field-preauth": true,
                          "about-box__field-select": true,
                          "about-box__field-inp-filled":
                            formikForm.values.service,
                          "about-box__field-inp-touched":
                            formikForm.touched.service &&
                            formikForm.errors.service,
                        })}
                        value={formikForm.values.service}
                        onChange={(e) => {
                          formikForm.setFieldValue("service", e.target.value);
                        }}
                      >
                        <option disabled hidden value="">
                          {t<string>("PREAUTH_SERVICE.SECTION_PLACEHOLDER")}
                        </option>
                        <option value="service">Service 1</option>
                        <option value="service">Service 2</option>
                        <option value="service">Service 3</option>
                        <option value="service">Service 4</option>
                      </select>
                    </label>
                    {formikForm.touched.service &&
                      formikForm.errors.service && (
                        <div className="form-control-error">
                          {formikForm.errors.service}
                        </div>
                      )}
                  </div>
                  <button
                    className={classNames({
                      "about-box__field-preauth": true,
                      "about__preauth-submit": true,
                      "about__preauth-submit-active": true,
                    })}
                  >
                    {t<string>("PREAUTH_SERVICE.SUBMIT")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServicesAnnounce;
