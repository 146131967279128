import React, { useContext, useEffect } from "react";

import classNames from "classnames";

import { AppContext } from "../../App";
import UpdateUser from "./Modal/UpdateUserModal";
import AddNewUserModal from "./Modal/AddNewUserModal";
import DeleteModal from "./Modal/DeleteModal";
import ShowUser from "./Modal/ShowUserModal/ShowUserModal";
import DeleteBankOffer from "./Modal/DeleteBankOfferModal";
import ShowComment from "./Modal/ShowCommentModal";
import AddNewComment from "../AddNewComment";
import ShowAllComments from "./Modal/ShowAllCommentsModal";
import NewNewsCategory from "../News/AdminNews/NewNewsCategory";
import AddNewBank from "../AdminBanks/AddNewBank";
import UpdateBank from "../AdminBanks/UpdateBank";
import NewCreditOffer from "../AdminCreditOffers/NewCreditOffer";
import UpdateCreditOffer from "../AdminCreditOffers/UpdateCreditOffer";
import ShowCategoryContent from "../Blog/AdminBlogs/ShowCategoryContent";
import ShowPostContent from "../Blog/AdminBlogs/ShowPostContent";
import ShowBankName from "../AdminBanks/ShowBankName";
import ShowBankDescription from "./Modal/ShowBankDescriptionModal";

import UpdateNewsCategory from "../News/AdminNews/UpdateNewsCategory/UpdateNewsCategory";
import UpdateCategory from "../Blog/AdminBlogs/UpdateCategory/UpdateCategory";
import NewCategory from "../Blog/AdminBlogs/NewCategory/NewCategory";
import NewApplication from "./Modal/NewQuestionnaireModal/NewQuestionnaireModal";
import ExampleCalculator from "../ExampleCalculator";
import UpdateBannerReviews from "../AdminBanners/UpdateBannerRequest/UpdateBannerReviews/UpdateBannerReviews";
import UpdateBannerServices from "../AdminBanners/UpdateBannerRequest/UpdateBannerServices";
import UpdateBannerStudy from "../AdminBanners/UpdateBannerRequest/UpdateBannerStudy";
import NewStudyRequest from "../AdminBanners/CreateNewRequest/NewStudyRequest";
import NewReviewRequest from "../AdminBanners/CreateNewRequest/NewReviewRequest";
import NewServiceRequest from "../AdminBanners/CreateNewRequest/NewServiceRequest";
import UpdateQuestionnaire from "./Modal/UpdateQuestionnaireModal/UpdateQuestionnaireModal";
import ApplyBankOffer from "./Modal/ApplyBankOffer";
import LogoutModal from "./Modal/LogoutModal";
import UserAuthorizationModal from "./Modal/UserAuthorizationModal";

import ResultsAlertModal from "./Modal/ResultsAlertModal/ResultsAlertModal";
import MyQuestionnairesModal from "./Modal/MyQuestionnairesModal/MyQuestionnairesModal";
import { useAppDispatch, useAppSelector } from "../../core/store";
import { setModal } from "../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../core/services/enums";
import SelectFromMyCertificates from "./Modal/SelectFromMyCertificates";
import DownloadCertificates from "./Modal/DownloadCertificates";
import AdminSelectUserCertificates from "./Modal/AdminSelectUserCertificates/AdminSelectUserCertificates";
import ShowApplicationModal from "./Modal/ShowApplicationModal/ShowApplicationModal";
import UpdateSubmittedApplication from "../AdminSubmittedApplications/UpdateSubmittedApplication/UpdateSubmittedApplication";
import ShowQuestionnaireModal from "./Modal/ShowQuestionnaireModal/ShowQuestionnaireModal";
import ShowSubmittedApplication from "./Modal/ShowSubmittedApplication/ShowSubmittedApplication";
import ShowAdminBankOfferModal from "./Modal/ShowAdminBankOfferModal/ShowAdminBankOfferModal";
import ShowCreditOfferContent from "./Modal/ShowCreditOfferContent/ShowCreditOfferContent";

const ModalWindow: React.FC = () => {
  const appContext = useContext(AppContext);
  const { modal, modalType } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        dispatch(setModal(false));
        document.body.style.overflowY = "scroll";
      }
    };

    window.addEventListener("keydown", close);

    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <div
      className={classNames({
        modal: true,
        "modal-active": modal,
      })}>
      <div
        className="modal__bg"
        onClick={() => {
          dispatch(setModal(false));
          document.body.style.overflowY = "scroll";
        }}></div>
      {modalType === AuthSelectionType.Auth && <UserAuthorizationModal />}
      {modalType === AuthSelectionType.Logout && <LogoutModal />}
      {modalType === AuthSelectionType.DeleteUser && <DeleteModal />}
      {modalType === AuthSelectionType.AddUser && <AddNewUserModal />}
      {modalType === AuthSelectionType.UpdateUser && <UpdateUser />}
      {modalType === AuthSelectionType.AddQuestionnaire && <NewApplication />}
      {modalType === AuthSelectionType.UpdateQuestionnaire && (
        <UpdateQuestionnaire />
      )}
      {modalType === AuthSelectionType.showUser && <ShowUser />}
      {modalType === AuthSelectionType.showQuestionnaire && (
        <ShowQuestionnaireModal />
      )}
      {modalType === AuthSelectionType.showApplication && (
        <ShowApplicationModal />
      )}
      {modalType === AuthSelectionType.showAdminBankOffer && (
        <ShowAdminBankOfferModal />
      )}
      {modalType === AuthSelectionType.updateSubmittedApplication && (
        <UpdateSubmittedApplication />
      )}
      {modalType === AuthSelectionType.showSubmittedApplication && (
        <ShowSubmittedApplication />
      )}
      {modalType === AuthSelectionType.applyApplication && <ApplyBankOffer />}
      {modalType === AuthSelectionType.deleteOffer && <DeleteBankOffer />}
      {modalType === AuthSelectionType.showComment && <ShowComment reviewId={appContext.reviewId} />}
      {modalType === AuthSelectionType.addComment && <AddNewComment />}
      {modalType === AuthSelectionType.showBankComments && <ShowAllComments />}
      {modalType === AuthSelectionType.newBlogCategory && <NewCategory />}
      {modalType === AuthSelectionType.updateBlogCategory && <UpdateCategory />}
      {modalType === AuthSelectionType.NewNewsCategory && <NewNewsCategory />}
      {modalType === AuthSelectionType.updateNewsCategory && (
        <UpdateNewsCategory />
      )}
      {modalType === AuthSelectionType.addNewBank && <AddNewBank />}
      {modalType === AuthSelectionType.showBlogCategoryContent && (
        <ShowCategoryContent />
      )}
      {modalType === AuthSelectionType.showBlogPostContent && (
        <ShowPostContent />
      )}
      {modalType === AuthSelectionType.showBankDescription && (
        <ShowBankDescription />
      )}
      {modalType === AuthSelectionType.showCreditOfferContent && (
        <ShowCreditOfferContent />
      )}
      {modalType === AuthSelectionType.showBankName && <ShowBankName />}
      {modalType === AuthSelectionType.updateBank && <UpdateBank />}
      {modalType === AuthSelectionType.addNewCreditOffer && <NewCreditOffer />}
      {modalType === AuthSelectionType.updateCreditOffer && (
        <UpdateCreditOffer />
      )}
      {modalType === AuthSelectionType.calcExample && <ExampleCalculator />}
      {modalType === AuthSelectionType.updateReview && <UpdateBannerReviews />}
      {modalType === AuthSelectionType.updateService && (
        <UpdateBannerServices />
      )}
      {modalType === AuthSelectionType.updateStudy && <UpdateBannerStudy />}
      {modalType === AuthSelectionType.createReview && <NewReviewRequest />}
      {modalType === AuthSelectionType.createService && <NewServiceRequest />}
      {modalType === AuthSelectionType.createStudy && <NewStudyRequest />}
      {modalType === AuthSelectionType.resultsAlert && <ResultsAlertModal />}
      {modalType === AuthSelectionType.questionnairesList && (
        <MyQuestionnairesModal />
      )}
      {modalType === AuthSelectionType.selectFromMyCertificates && (
        <SelectFromMyCertificates />
      )}
      {modalType === AuthSelectionType.downloadCertificates && (
        <DownloadCertificates />
      )}
      {modalType === AuthSelectionType.adminSelectUserCertificates && (
        <AdminSelectUserCertificates />
      )}
    </div>
  );
};

export default ModalWindow;
