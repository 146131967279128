import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { spacesRemover } from "../../core/functions/funtions";
import PrivateService from "../../core/services/private.service";
import { useAppSelector } from "../../core/store";
import { toast } from "react-toastify";

type Props = {
  setSentViaEmail: (bol: boolean) => void;
};

const SendViaEmail: React.FC<Props> = ({ setSentViaEmail }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const { selectedId } = useAppSelector((state) => state.app);

  const valueFormValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email")
      .max(50, "Max 50 characters"),
  });

  const formikForm = useFormik<{
    email: string;
  }>({
    initialValues: {
      email: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = (values: any) => {
    setEmails([...emails, values.email]);
    formikForm.resetForm();
  };

  const deleteEmail = (enteredEmail: string) => {
    const newEmails = emails.filter((email: string) => email !== enteredEmail);
    setEmails(newEmails);
  };

  const SendPdfViaEmail = async () => {
    const emailsData = emails.reduce(
      (a: any, v: any, index: number) => ({
        ...a,
        [`emails[${index}]`]: v,
      }),
      {}
    );

    try {
      const response = await PrivateService.sendPdfViaEmail(
        emailsData,
        selectedId
      );
      toast.success(response?.data?.message);
      setEmails([]);
      setSentViaEmail(false);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
  };

  return (
    <div className="email-pdf">
      <div className="email-pdf__list">
        {emails.map((email: string) => (
          <div className="email-pdf__box">
            <span className="email-pdf__box-email">{email}</span>
            <button
              className="email-pdf__box-delete"
              onClick={() => deleteEmail(email)}
            >
              x
            </button>
          </div>
        ))}
        {emails.length > 0 && (
          <button
            onClick={SendPdfViaEmail}
            className="about-box__file-block update-offer-head__btn email-pdf__box-send"
          >
            Send
          </button>
        )}
      </div>
      <form onSubmit={formikForm.handleSubmit}>
        <input
          className="email-pdf__inp"
          placeholder="E-mail"
          type="email"
          value={formikForm.values.email}
          onChange={(event) => {
            if (event.target.value !== " ") {
              formikForm.setFieldValue(
                "email",
                spacesRemover(event.target.value)
              );
            }
          }}
        />
        {formikForm.touched.email && formikForm.errors.email && (
          <div className="form-control-error">{formikForm.errors.email}</div>
        )}
        <input
          type="submit"
          style={{ position: "absolute", left: "-9999px" }}
        />
      </form>
    </div>
  );
};

export default SendViaEmail;
