import { FC, useState, useRef, useEffect } from "react";

import Cards from "../Cards/index";

import useHttpGet from "../../core/hooks/useHttpGet";
import { APIRoutes } from "../../core/http";
import { Private } from "../../core/models";
import { useTranslation } from "react-i18next";
// import CardOffers from "../../core/store/creditCard.json";
import useAuth from "../../core/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../core/store";
import { setQuestionnaire } from "../../core/store/reducers/app/appDataSlice";

const Offers: FC = () => {
  const scrollableRef = useRef<any>(null);
  const [isDown, setIsDown] = useState<any>(false);
  const [startX, setStartX] = useState<any>(null);
  const [scrollLeft, setScrollLeft] = useState<any>(null);
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [filter, setFilter] = useState<any>({});
  const [applications, setApplications] = useState<any>([]);
  const [creditCards, setCreditCards] = useState<any>([]);
  const [applicationsLength, setApplicationsLength] = useState(1);
  const [creditCardsLength, setCreditCardsLength] = useState<any>([]);
  const [userQuestionnaires, setUserQuestionnries] = useState(0);

  const [isSelected, setIsSelected] = useState<{
    credit: boolean;
    creditCard: boolean;
  }>({
    credit: true,
    creditCard: false,
  });

  const { isAuth } = useAuth();
  const { t } = useTranslation();
  const appLanguage = useAppSelector((state) => state.app.appLanguage);
  const {questionnaire} = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFilter({ "filter[show_on_public][=]": "1" });
  }, []);

  useHttpGet<any>(APIRoutes.USER_QUESTIONNAIRE, {
    resolve: (response: any) => {
      setUserQuestionnries(response?.payload?.id);
      dispatch(setQuestionnaire(response?.payload))
    },
    condition: isAuth === true,
  });

  useHttpGet<any>(APIRoutes.PUBLIC_BANKS_OFFERS, {
    query: { ...filter, ...sorter },
    dependencies: [filter],
    condition: Object.keys(filter).length !== 0,
    resolve: (response: any) => {
      setApplications(
        response?.payload?.collection.filter(
          (item: any) => item.annual_fee === null
        )
      );
    },
  });

  useHttpGet<any>(APIRoutes.PUBLIC_CREDIT_CARDS, {
    query: { ...filter, ...sorter },
    dependencies: [filter],
    condition: Object.keys(filter).length !== 0,
    resolve: (response: any) => {
      setCreditCards(response?.payload?.collection);
    },
  });

  const sortedSubmitedApplication = (id: number) => {
    setApplications(
      applications.filter((applications: any) => applications.id !== id)
    );
    setApplicationsLength(() => applications.length - 1);
  };

  const sortedSubmitedCreditCards = (id: number) => {
    setCreditCards(
      creditCards.filter((creditCards: any) => creditCards.id !== id)
    );
    setCreditCardsLength(() => creditCards.length - 1);
  };

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ ...filter, [propName]: value || undefined });
  };

  const handleMouseDown = (e: any) => {
    setIsDown(true);
    setStartX(e.pageX - scrollableRef.current.offsetLeft);
    setScrollLeft(scrollableRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e: any) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollableRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    scrollableRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleSelect = () => {
    if (isSelected.credit) {
      setIsSelected((prev) => {
        return {
          ...prev,
          creditCard: true,
          credit: false,
        };
      });
    } else {
      setIsSelected((prev) => {
        return {
          ...prev,
          credit: true,
          creditCard: false,
        };
      });
    }
  };

  return (
    <div className="offers">
      <div className="offers__container">
        <span className="offers__container-small">
          {t<string>("HOME.BEST_DEALS_SUBTITLE")}
        </span>
        <h1 className="offers__container-title">
          {t<string>("HOME.BEST_DEALS_TITLE")}
          <span className="offers__container-title-word">
            &nbsp;
            <span>{t<string>("HOME.BEST_DEALS_ADVERTISING")}</span>
          </span>
        </h1>
        <div className="offers__container-essential essential">
          <div className="essential__control">
            <div className="essential__control-buttons">
              <button
                onClick={() => {
                  handleSelect();
                }}
                className={
                  isSelected.credit ? "active-offer" : "inactive-offer"
                }
              >
                {t<string>("COMMON.CREDIT")}
              </button>
              <button
                onClick={() => {
                  handleSelect();
                }}
                className={
                  isSelected.creditCard ? "active-offer" : "inactive-offer"
                }
              >
                {t<string>("COMMON.CREDIT_CARD")}
              </button>
            </div>
          </div>
          <div
            className="essential__list"
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            ref={scrollableRef}
          >
            {isSelected.credit &&
              (applications.length ? (
                applications
                  .filter(
                    (offer: any) => offer.bankOfferTypes.collection[0].id !== 7
                  )
                  ?.sort(
                    (offer1: Private.BankOffer, offer2: Private.BankOffer) =>
                      offer2.bank.rating - offer1.bank.rating
                  )
                  ?.map((offer: Private.BankOffer) => (
                    <Cards
                      offer={offer}
                      questionnaire={userQuestionnaires}
                      sortedApplication={sortedSubmitedApplication}
                      type="bank"
                      passport={questionnaire?.passport}
                      key={offer.id}
                    />
                  ))
              ) : (
                <div className="essential__list-empty essential__list-empty--home">
                  <img
                    src={require(`../../assets/photos/all/best_deals_not_result_${
                      appLanguage === "en" ? "EN" : "BG"
                    }.png`)}
                    alt="best deals empty banner"
                  />
                </div>
              ))}

            {isSelected.creditCard &&
              (creditCards.length ? (
                creditCards
                  ?.sort(
                    (offer1: any, offer2: any) =>
                      offer2.bank.rating - offer1.bank.rating
                  )
                  ?.map((offer: any) => (
                    <Cards
                      offer={offer}
                      questionnaire={userQuestionnaires}
                      sortedApplication={sortedSubmitedCreditCards}
                      type="credit"
                      passport={questionnaire?.passport}
                      key={offer.id}
                    />
                  ))
              ) : (
                <div className="essential__list-empty essential__list-empty--home">
                  <img
                    src={require(`../../assets/photos/all/best_deals_not_result_${
                      appLanguage === "en" ? "EN" : "BG"
                    }.png`)}
                    alt="best deals empty banner"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
