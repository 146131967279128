import React, { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UIRoutes } from ".";
import useRole from "../hooks/useRole";

interface IProtectedRouteProps {
  path?: string;
  element?: React.ReactElement;
}

const AdminGuard: FC<IProtectedRouteProps> = (props) => {
  const location = useLocation();

  const { isUser, isPartner } = useRole();

  if (isUser || isPartner) {
    return (
      <Navigate to={`/${UIRoutes.HOME}`} replace state={{ from: location }} />
    );
  }
  return <Outlet />;
};
export default AdminGuard;
