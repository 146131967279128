import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../../../../App";

import credits_refinancing_bg from "../../../../../assets/photos/credits/credits_refinancing_bg.svg";
import credits_refinancing_bg_main from "../../../../../assets/photos/credits/credits_refinancing_bg-main.svg";
import { useTranslation } from "react-i18next";
import { setTypeForResult } from "../../../../../core/functions/funtions";

const RefinancingBanner = () => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();

  const { setSelectedLoan } = appContext;

  return (
    <section className="credits-block__consumer-credits consumer-credits">
      <div className="consumer-credits-banner refinancing-banner">
        <div className="consumer-credits-banner__main-content main-content">
          <div className="main-content-info">
            <h1 className="main-content-info-title">
              {t<string>("LOANS.REFINANCING")}
            </h1>
            <p className="main-content-info-subtitle">
              {t<string>("FINANCE.REFINANCING_TEXT_1")}
              <br /> <br />
              {t<string>("FINANCE.REFINANCING_TEXT_2")}
            </p>
            <img
              className="credits-refinancing-bg"
              src={credits_refinancing_bg}
              alt="credits_refinancing_bg"
            />
            <img
              className="credits-refinancing-bg-main"
              src={credits_refinancing_bg_main}
              alt="credits_refinancing_bg-main"
            />
            <Link
              to={`/result-list`}
              onClick={() => {
                setTypeForResult(6)
              }}
              className="main-content-btn"
            >
              {t<string>("FINANCE.REFINANCING_BUTTON")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RefinancingBanner;
