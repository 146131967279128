import React, { useEffect, useState} from 'react';
import classNames from 'classnames';
import { Public } from '../../core/models';
import { useAppSelector } from '../../core/store';
import { useTranslation } from 'react-i18next';
import status_inProgress  from "../../assets/photos/all/status_inProgress.svg";
import status_approved  from "../../assets/photos/all/status_approved.svg";
import status_decline  from "../../assets/photos/all/status_decline.svg";

interface NotificationCardProps {
    item: Public.Notification;
    index: number    
    funcHandleShow: Function;
    funcSearchStatus: Function;
    funcFiltered: any
    
}

const NotificationCard: React.FC<NotificationCardProps> = ({ item, index, funcHandleShow, funcSearchStatus}) => {
    const { t } = useTranslation()
    const {appLanguage } = useAppSelector((state) => state.app);

    const { id, showed, created_at, translations } = item;

    const [checked, setChecked] = useState("notifications-list__item")
    const progStatus = funcSearchStatus(item, appLanguage)


    const setNotifCheked = (id:number, index:number) => {
        // setChecked("notifications-list__item__checked")
        funcHandleShow(id, index)
    }
    // useEffect(()=>{
    //     item.showed === 1 ? setChecked("notifications-list__item--checked") : setChecked("notifications-list__item")
    // },[checked])

    

    return (
    <li className={checked} key={id} onClick={() => setNotifCheked(id, index)} >
        <div className="notifications-list__item__card-content">
        <span
            className={classNames({
            'notifications-list__item-content__txt': showed,
            'notifications-list__item-content__new': !showed,
            })}
            dangerouslySetInnerHTML={
                appLanguage === "en"
                ? {
                    __html: translations?.en?.text,
                }
                : {
                    __html: translations?.bg?.text,
                }
            }
        />
        <br/>
        <span className="notifications-list__item-content__date">{`${created_at.slice(
            8,
            10
        )}.${created_at.slice(5, 7)}.${created_at.slice(0, 4)}`}</span>
        </div>
            {progStatus ===  t<string>("STATUSES.APPROVED") ? <img src={status_approved} width={8}/> : ''}
            {progStatus ===  t<string>("STATUSES.IN_PROGRESS") ? <img src={status_inProgress} width={8}/> : ''}
            {progStatus ===  t<string>("STATUSES.DECLINED") ? <img src={status_decline} width={8}/> : ''}
    </li>
);
};

export default NotificationCard 