import { useState, useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { GrEdit } from "react-icons/gr";
import { BiShow } from "react-icons/bi";
import { BsFillPiggyBankFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../App";

import sortingArrow from "../../assets/photos/all/sorting-arrow.svg";
import search from "../../assets/photos/profile/search.svg";

import useHttpGet from "../../core/hooks/useHttpGet";
import { APIRoutes } from "../../core/http";
import PrivateService from "../../core/services/private.service";
import { Private } from "../../core/models";
import { credit_types } from "../../core/constants/creditTypes";
import { statusArr } from "../../core/constants/statusArr";
import { useAppDispatch } from "../../core/store";
import {
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../core/services/enums";
import {
  setChoosenData,
  setQuestionnaire,
  setSelectedId,
} from "../../core/store/reducers/app/appDataSlice";
import { setCerts } from "../../core/store/reducers/documents/documentsSlice";
import { MdOutlineDone } from "react-icons/md";
import ButtonExcel from "../ButtonExcel/ButtonExcel";

const AdminQuestionnaires = () => {
  const [filterValue, setFilterValue] = useState("");
  const [statusReload, setStatusReload] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [filterType, setFilterType] = useState("filter[search]");
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });
  const [sent, setSent] = useState<any>({
    isSent: false,
    ids: [],
  });

  const containerRef: any = useRef(null);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);

  const { reloadChecker } = appContext;

  const { fetchedData } = useHttpGet<any>(
    `${APIRoutes.ADMIN_USERS_QUESTIONNAIRES}/index_all`,
    {
      dependencies: [statusReload, reloadChecker, pagination, sorter, filter],
      query: { ...sorter, ...filter, ...pagination },

      resolve: (response: any) => {},
    }
  );

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};
    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ [propName]: value || undefined });
  };

  const setPaginationPage = () => {
    setPagination(()=>{
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 }
      }else{
        return {...pagination, per_page: 50}
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const approve = async (id: number) => {
    const tempUser = fetchedData.payload?.collection.find(
      (user: Private.AdminQuestionnaire) => user.id === id
    );

    try {
      const response = await PrivateService.updateQuestionnaire(
        {
          ...tempUser,
          status: "approved",
          insolvency: "1",
          update: "1",
        },
        id
      );
      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
    setStatusReload(!statusReload);
  };

  const decline = async (id: number) => {
    const tempUser = fetchedData.payload?.collection.find(
      (user: Private.AdminQuestionnaire) => user.id === id
    );

    try {
      const response = await PrivateService.updateQuestionnaire(
        {
          ...tempUser,
          status: "declined",
          insolvency: "1",
          update: "1",
        },
        id
      );

      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors.response.data.message);
    }
    setStatusReload(!statusReload);
  };

  const onPending = async (id: number) => {
    const tempUser = fetchedData.payload?.collection.find(
      (user: Private.AdminQuestionnaire) => user.id === id
    );

    try {
      const response = await PrivateService.updateQuestionnaire(
        {
          ...tempUser,
          status: "pending",
          insolvency: "1",
          update: "1",
        },
        id
      );
      toast.success(response.data.message);
    } catch (errors: any) {
      toast.error(errors.response.data.message);
    }
    setStatusReload(!statusReload);
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container?.scrollTop + container?.clientHeight >=
      container?.scrollHeight
    ) {
      setPaginationPage();
    }
  };

  const handleVerificationEmail = async (id: number) => {
    try {
      const response = await PrivateService.sendVerificationEmail(id);
      setSent((prev: any) => {
        return {
          isSent: true,
          ids: [...prev.ids, id],
        };
      });
      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
  };

  const updateQuestionnaire = (questionnaire: any) => {
    dispatch(setSelectedId(questionnaire.id));
    dispatch(setModalType(AuthSelectionType.UpdateQuestionnaire));
    dispatch(setModal(true));
    dispatch(setCerts([]));
  };

  const showQuestionnaire = (questionnaire: any) => {
    dispatch(setSelectedId(questionnaire.id));
    dispatch(setModalType(AuthSelectionType.showQuestionnaire));
    dispatch(setModal(true));
  };

  const applyBankOffer = (questionnaire: any) => {
    dispatch(setModalType(AuthSelectionType.applyApplication));
    dispatch(setModal(true));
    dispatch(setChoosenData(questionnaire));
    dispatch(setQuestionnaire(questionnaire));
  };
 

  return (
    <div className="admin">
      <h2 className="admin-table__name">
        {t<string>("QUESTIONNAIRES_TABLE.QUESTIONNAIRES")}
      </h2>
      <ButtonExcel url='admin/questionnaires_export'/>
      <div className="admin-container">
        <div className="profile-table">
          <div className="admin-search">
            <input
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
              onKeyPress={(e: any) => {
                if (e.key === "Enter" && filterType) {
                  setFilterProperty(filterType, filterValue);
                }
              }}
              className="admin-search__inp"
            />
            <select
              onChange={(e) => {
                e.target.value === "search"
                  ? setFilterType(`filter[${e.target.value}]`)
                  : setFilterType(`filter[${e.target.value}][like]`);
              }}
              className="admin-search__dropdown"
            >
              <option value="search">
                {t<string>("QUESTIONNAIRES_TABLE.ALL")}
              </option>
              <option value={"first_name"}>
                {t<string>("QUESTIONNAIRES_TABLE.FIRST_NAME")}
              </option>
              <option value={"last_name"}>
                {t<string>("QUESTIONNAIRES_TABLE.LAST_NAME")}
              </option>
              <option value={"phone"}>
                {t<string>("QUESTIONNAIRES_TABLE.PHONE")}
              </option>
              <option value={"personal_code"}>
                {t<string>("QUESTIONNAIRES_TABLE.PERSONAL_CODE")}
              </option>
              <option value={"date_of_birth"}>
                {t<string>("QUESTIONNAIRES_TABLE.DATE_OF_BIRTH")}
              </option>
            </select>
            <button
              className="admin-search__btn"
              onClick={() => {
                if (filterType) {
                  setFilterProperty(filterType, filterValue);
                }
              }}
            >
              <img src={search} alt="search" />
            </button>
          </div>
          <div className="users-container" id="questionnaire-table">
            <table className="users-table" cellSpacing="0" cellPadding="0">
              <thead className="users-table-head">
                <tr className="users-table-head-row">
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.USER_ID")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.QUESTIONNAIRE_ID")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.FIRST_NAME")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[first_name]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.LAST_NAME")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[last_name]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td id="status" className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.STATUS")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[status]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.TYPE")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.LOAN_SUM")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.PHONE")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.GENDER")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.DATE_OF_BIRTH")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.CITIZENSHIP")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.PERSONAL_CODE")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.PASSPORT")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.IP")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.CREATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[created_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.UPDATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[updated_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("QUESTIONNAIRES_TABLE.STATUS")}
                  </td>
                  <td className="users-table-head__item"></td>
                </tr>
              </thead>
              <tbody className="users-table-content">
                {fetchedData?.payload?.collection.map(
                  (questionnaire: Private.AdminQuestionnaire) => (
                    <tr className="users-table-row" key={questionnaire.id}>
                      <td className="users-table-row__item">
                        {questionnaire.user_id}
                      </td>
                      <td className="users-table-row__item">
                        {questionnaire.id}
                      </td>
                      <td className="users-table-row__item">
                        {questionnaire.first_name}
                      </td>
                      <td className="users-table-row__item">
                        {questionnaire.last_name}
                      </td>
                      <td id="status" className="users-table-row__item">
                        {questionnaire.status}
                      </td>
                      <td className="users-table-row__item">
                        {credit_types[questionnaire.type]}
                      </td>
                      <td className="users-table-row__item">
                        {String(questionnaire.type) !== "4"
                          ? questionnaire.amount_1
                          : Number(questionnaire.amount_1) +
                            Number(questionnaire.amount_2) +
                            Number(questionnaire.amount_3)}
                      </td>
                      <td className="users-table-row__item">
                        {questionnaire.phone}
                      </td>
                      <td className="users-table-row__item">
                        {(questionnaire.gender === "0" &&
                          t<string>("QUESTIONNAIRES_TABLE.MALE")) ||
                          (questionnaire.gender === "1" &&
                            t<string>("QUESTIONNAIRES_TABLE.FEMALE"))}
                      </td>
                      <td className="users-table-row__item">
                        {questionnaire.date_of_birth}
                      </td>
                      <td className="users-table-row__item">
                        {(questionnaire.citizenship === "1" &&
                          t<string>("QUESTIONNAIRES_TABLE.OTHERS")) ||
                          (questionnaire.citizenship === "0" &&
                            t<string>("QUESTIONNAIRES_TABLE.BULGARIA"))}
                      </td>
                      <td className="users-table-row__item">
                        {questionnaire.personal_code}
                      </td>
                      <td className="users-table-row__item">
                        {questionnaire.passport}
                      </td>
                      <td className="users-table-row__item">
                        {questionnaire.ip}
                      </td>
                      <td className="users-table-row__item">
                        <span>{`${new Date(
                          questionnaire?.created_at
                        ).toLocaleTimeString()}`}</span>
                        <br></br>
                        {`${questionnaire?.created_at.slice(
                          8,
                          10
                        )}.${questionnaire?.created_at.slice(
                          5,
                          7
                        )}.${questionnaire?.created_at.slice(0, 4)}`}
                      </td>
                      <td className="users-table-row__item">
                        <span>{`${new Date(
                          questionnaire?.updated_at
                        ).toLocaleTimeString()}`}</span>
                        <br></br>
                        {`${questionnaire?.updated_at.slice(
                          8,
                          10
                        )}.${questionnaire?.updated_at.slice(
                          5,
                          7
                        )}.${questionnaire?.updated_at.slice(0, 4)}`}
                      </td>
                      <td className="users-table-row__item">
                        <select
                          onChange={(e) => {
                            if (e.target.value === "approved") {
                              approve(questionnaire.id);
                            }
                            if (e.target.value === "declined") {
                              decline(questionnaire.id);
                            }
                            if (e.target.value === "pending") {
                              onPending(questionnaire.id);
                            }
                          }}
                          className="admin-search__dropdown-status"
                        >
                          <option value={questionnaire.status}>
                            {t<string>(
                              `QUESTIONNAIRES_TABLE.${questionnaire.status.toUpperCase()}`
                            )}
                          </option>
                          {statusArr
                            .filter(
                              (status: string) =>
                                questionnaire.status !== status
                            )
                            .map((status: string, index: number) => (
                              <option key={index} value={status}>
                                {t<string>(
                                  `QUESTIONNAIRES_TABLE.${status.toUpperCase()}`
                                )}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td className="users-table-row__item">
                        <button
                          onClick={() => showQuestionnaire(questionnaire)}
                          className="users-table-action__btn"
                          title={t<string>("QUESTIONNAIRES_TABLE.SHOW_QUEST")}
                        >
                          <BiShow />
                        </button>
                        <button
                          onClick={() => updateQuestionnaire(questionnaire)}
                          className="users-table-action__btn"
                          title={t<string>("QUESTIONNAIRES_TABLE.UPDATE_QUEST")}
                        >
                          <GrEdit />
                        </button>
                        <button
                          onClick={() => applyBankOffer(questionnaire)}
                          className="users-table-action__btn"
                          title={t<string>(
                            "QUESTIONNAIRES_TABLE.APPLY_BANK_OFFER"
                          )}
                        >
                          <BsFillPiggyBankFill />
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          {fetchedData?.payload?.cursor.has_more_pages && (
            <button
              className="profile-table__btn"
              onClick={() => {
                setPaginationPage();
              }}
            >
              {t<string>("QUESTIONNAIRES_TABLE.SHOW_MORE")}
            </button>
          )}
          {!fetchedData?.payload?.cursor.has_more_pages &&
            fetchedData?.payload?.collection.length > 10 && (
              <button
                onClick={setDefaultPagination}
                className="profile-table__btn"
              >
                {t<string>("QUESTIONNAIRES_TABLE.HIDE_ALL")}
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default AdminQuestionnaires;
