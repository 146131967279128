import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Props = {
  questionsStep: number;
  setQuestionsStep: (num: number) => void;
};

const FourthStep: React.FC<Props> = ({ questionsStep, setQuestionsStep }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (sessionStorage.getItem("questionnaire")) {
      const questionnaire = JSON.parse(
        sessionStorage.getItem("questionnaire") || ""
      );
      formikForm.setValues({ ...formikForm.values, ...questionnaire });
    }
  }, []);

  const valueFormValidationSchema = Yup.object().shape({
    debts: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    loan_sum: Yup.number().when("debts", {
      is: (val: string) => val && val === "1",
      then: Yup.number()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .min(250, t<string>("PROFILE.MIN_LOAN_SUM")),
    }),
    insolvency: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
  });

  const formikForm = useFormik<{
    debts: string;
    loan_sum: string | number;
    insolvency: string;
    update: string;
  }>({
    initialValues: {
      debts: "0",
      loan_sum: "",
      insolvency: "",
      update: "1",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    const memory = JSON.parse(sessionStorage.getItem("questionnaire") || "");

    sessionStorage.setItem(
      "questionnaire",
      JSON.stringify(Object.assign(memory, values))
    );
    setQuestionsStep(questionsStep + 1);
    sessionStorage.setItem("currentStep", JSON.stringify(questionsStep + 1));
    sessionStorage.removeItem("stepper");
    window.scrollTo(0, 0);
  };

  return (
    <form className="about" onSubmit={formikForm.handleSubmit}>
      <div className="about__form">
        <h2 className="about__title about__title-fourth">
          {t<string>("PROFILE.CURRENT_LOANS")}
        </h2>
        <div className="about__form-box about-ask">
          <h4 className="about-ask__question">
            {t<string>("PROFILE.ANY_LOANS")}
          </h4>
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.debts,
                    "about-box__field-inp-touched":
                      formikForm.touched.debts && formikForm.errors.debts,
                  })}
                  value={formikForm.values.debts}
                  onChange={(e) => {
                    if (e.target.value === "0") {
                      formikForm.setFieldValue("loan_sum", "");
                    }
                    formikForm.setFieldValue("debts", e.target.value);
                  }}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("COMMON.NO")}</option>
                  <option value="1">{t<string>("COMMON.YES")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched.debts && formikForm.errors.debts && (
              <div className="form-control-error">
                {t<string>(formikForm.errors.debts)}
              </div>
            )}
          </div>
        </div>
        {formikForm.values.debts !== "0" && formikForm.values.debts !== "" && (
          <label
            className="about__form-range about__form-loan about-range about-box__field"
            htmlFor="family-no"
          >
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.loan_sum,
                "about-box__field-inp-error":
                  formikForm.touched.loan_sum && formikForm.errors.loan_sum,
              })}
            >
              {t<string>("PROFILE.LOANS_PER_MONTH")}
            </span>
            <div className="about-range__gen about-range__loan-sum">
              <label className="about-range__gen-field">
                <input
                  type="text"
                  className="about-range__gen-field-put"
                  placeholder="1000"
                  value={formikForm.values.loan_sum}
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("loan_sum", "");
                    } else {
                      if (+event.target.value > 999999999) {
                        formikForm.setFieldValue("loan_sum", 999999999);
                      } else {
                        formikForm.setFieldValue(
                          "loan_sum",
                          +event.target.value
                        );
                      }
                    }
                  }}
                />
                {t<string>("PROFILE.BGN")}
              </label>
            </div>
          </label>
        )}
        {formikForm.touched.loan_sum && formikForm.errors.loan_sum && (
          <div className="form-control-error">{t<string>(formikForm.errors.loan_sum)}</div>
        )}
      </div>

      {formikForm.values.debts && (
        <div className="about__form">
          <div className="about__form-box about-ask">
            <h4 className="about-ask__question">
              {t<string>("PROFILE.INSOLVENCY")}
            </h4>
            <div className="about-ask__variants">
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.insolvency === "1",
                })}
                type="button"
                onClick={() => formikForm.setFieldValue("insolvency", "1")}
              >
                {t<string>("UPDATE_QUESTIONNAIRE.YES")}
              </button>
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.insolvency === "0",
                })}
                type="button"
                onClick={() => formikForm.setFieldValue("insolvency", "0")}
              >
                {t<string>("UPDATE_QUESTIONNAIRE.NO")}
              </button>
            </div>
          </div>
        </div>
      )}

      <button
        className={classNames({
          about__submit: true,
          "about__submit-active": formikForm.isValid,
        })}
        type="submit"
      >
        {t<string>("COMMON.NEXT")}
      </button>
    </form>
  );
};

export default FourthStep;
