import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import fileIcon from "../../../assets/photos/all/add-file.svg";
import addedFile from "../../../assets/photos/all/file-added.svg";

import { UIRoutes } from "../../../core/router";
import PrivateService from "../../../core/services/private.service";

import CommonService from "../../../core/services/common.service";
import { useAppDispatch, useAppSelector } from "../../../core/store";
import useHttpGet from "../../../core/hooks/useHttpGet";
import $api, { APIRoutes } from "../../../core/http";
import {
  setCerts,
  setCertsIds,
} from "../../../core/store/reducers/documents/documentsSlice";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../core/services/enums";
import { usePrevious } from "../../../core/hooks/usePrevious";
import Checkbox from "../../CustomCheckbox/CustomCheckbox";

const ThirdStep: React.FC = () => {
  const [addLater, setAddLater] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { certs, certsIds } = useAppSelector((state) => state.document);
  const dispatch = useAppDispatch();

  const { fetchedData: certificates } = useHttpGet<any>(
    `${APIRoutes.USER_DOCUMENTS}`
  );
  // const { fetchedData: user } = useHttpGet<any>(APIRoutes.USER);
  useEffect(() => {
    if (sessionStorage.getItem("questionnaire")) {
      const questionnaire = JSON.parse(
        sessionStorage.getItem("questionnaire") || ""
      );

      formikForm.setValues({ ...formikForm.values, ...questionnaire });
    }
    dispatch(setCerts([]));
    dispatch(setCertsIds([]));
  }, []);

  useEffect(() => {
    updateCertificates();
  }, [certs]);

  const valueFormValidationSchema = Yup.object().shape({
    job: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    monthly_income: Yup.number().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    education: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    politic: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    position: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    income_from: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(36, t<string>("PROFILE.MAX_36_CHARS")),
    }),
    job_status: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    working_place: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(55, t<string>("PROFILE.MAX_55_CHARS")),
    }),
    experience: Yup.number().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.number().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
  });

  const formikForm = useFormik<{
    job: string | number;
    working_place: string;
    position: string;
    experience: string;
    job_status: string;
    income_from: string;
    monthly_income: string;
    certificates_1: any;
    certificates_2: any;
    certificates_3: any;
    certificates_4: any;
    certificates_5: any;
    education: string;
    politic: string;
  }>({
    initialValues: {
      job: "1",
      working_place: "",
      position: "",
      experience: "",
      job_status: "",
      income_from: "",
      monthly_income: "",
      certificates_1: "",
      certificates_2: "",
      certificates_3: "",
      certificates_4: "",
      certificates_5: "",
      education: "",
      politic: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: (values) => {
      const result: any = certificateFilter({
        certificates_1: formikForm.values.certificates_1,
        certificates_2: formikForm.values.certificates_2,
        certificates_3: formikForm.values.certificates_3,
        certificates_4: formikForm.values.certificates_4,
        certificates_5: formikForm.values.certificates_5,
      });

      const privateCerts = certsIds.reduce(
        (a: any, v: any, index: number) => ({
          ...a,
          [`certificates_ids[${index}]`]: v,
        }),
        {}
      );

      handleSubmitForm({
        ...values,
        ...result,
        ...privateCerts,
      });
    },
  });

  const deleteCert = (index: number, doc: any) => {
    const newCertificates = certs.filter(
      (cert: any) => certs.indexOf(cert) !== index
    );
    const uniqueCert = certsIds.filter((certId: number) => certId !== doc.id);

    dispatch(setCertsIds(uniqueCert));

    dispatch(setCerts(newCertificates));
  };

  const handleSubmitForm = async (values: any) => {
    // const varified = user?.payload?.verified;
    const questionnaire = JSON.parse(
      sessionStorage.getItem("questionnaire") || ""
    );
    const bankOfferId = JSON.parse(
      sessionStorage.getItem("bank_offer_id") || ""
    );

    if (!validateSingleInputs()) {
      toast.error(`${t<string>("PROFILE.CHECK_FIELDS")}`);
      return;
    }

    $api
      .get(APIRoutes.USER)
      .then((response) =>
        localStorage.setItem("userVerify", response.data.payload.verified)
      )
      .then(async () => {
        const userVerify = Number(localStorage.getItem("userVerify"));
        if (userVerify && userVerify === 1) {
          try {
            delete values.agree;
            delete values.informed;

            const response = await PrivateService.createQuestionnaire({
              ...questionnaire,
              ...values,
              bank_offer_id: bankOfferId,
              update: "1",
            });

            toast.success(response?.data?.message);
            navigate(`/${UIRoutes.RESULTS_LIST}`);

            localStorage.setItem(
              "calcResult",
              JSON.stringify({
                amount_1: values.amount_1,
                term_1: values.term_1,
                amount_2: values.amount_2,
                term_2: values.term_2,
                amount_3: values.amount_3,
                term_3: values.term_3,
                current_amount: values.current_amount,
                type: values.type,
                totalAmount:
                  +values.amount_1 + +values.amount_2 + +values.amount_3,
              })
            );

            ReactGA.event({
              category: "questionnaire",
              action:
                process.env.REACT_APP_ANALYTICS_NAME === ""
                  ? "questionnaire_submit"
                  : `questionnaire_submit_${process.env.REACT_APP_ANALYTICS_NAME}`,
            });

            sessionStorage.removeItem("questionnaire");
            sessionStorage.removeItem("stepper");
            sessionStorage.removeItem("currentStep");
            formikForm.resetForm();
          } catch (errors: any) {
            CommonService.showErrors(errors?.response?.data?.payload);
          }
        } else {
          toast.error(t<string>("PROFILE.VERIFY_EMAIL"));
        }
      });
  };

  const jobInfoClear = (value: string) => {
    formikForm.setFieldValue("working_place", "");
    formikForm.setFieldValue("position", "");
    formikForm.setFieldValue("experience", "");
    formikForm.setFieldValue("monthly_income", "");
    formikForm.setFieldValue("income_from", "");
    formikForm.setFieldValue("job_status", "");
    formikForm.setFieldValue("job", value);
    formikForm.validateForm();
  };

  const certificateFilter = (values: any) => {
    for (let value in values) {
      if (values[value].id !== undefined) {
        values[value] = "";
      }
    }

    return Object.values(values)
      .sort()
      .reverse()
      .reduce(
        (a: any, v: any, index: number) => ({
          ...a,
          [`certificates_${index + 1}`]: v,
        }),
        {}
      );
  };
  const prevIncome = usePrevious(formikForm.values.income_from);
  const updateCertificates = () => {
    for (let i = 0; i < 5; i++) {
      if (certs[i]) {
        formikForm.setFieldValue(`certificates_${i + 1}`, certs[i]);
      } else {
        formikForm.setFieldValue(`certificates_${i + 1}`, "");
      }
    }
  };

  const validateSingleInputs = () => {
    return (
      formikForm.values.monthly_income &&
      formikForm.values.education &&
      formikForm.values.politic &&
      (formikForm.values.certificates_1 || addLater)
    );
  };

  useEffect(() => {
    sessionStorage.setItem(
      "stepper",
      JSON.stringify({
        firstStepConfirm: true,
        secondStepConfirm: true,
        thirdStepConfirm: formikForm.isValid,
      })
    );
  }, [formikForm.isValid]);
  const appLanguage = useAppSelector((state) => state.app.appLanguage);

  useEffect(() => {
    jobInfoClear("1");
  }, []);

  return (
    <>
      <form className="about" onSubmit={formikForm.handleSubmit}>
        <div className="about__form">
          <div className="about__form-box about-ask">
            <div className="about__form">
              <h2 className="about__title">{t<string>("PROFILE.EDUCATION")}</h2>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.education,
                      "about-box__field-inp-error":
                        formikForm.touched.education &&
                        formikForm.errors.education,
                    })}
                  >
                    {t<string>("PROFILE.DEGREE")}
                  </span>
                  <select
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-select": true,
                      "about-box__field-inp-filled":
                        formikForm.values.education,
                      "about-box__field-inp-touched":
                        formikForm.touched.education &&
                        formikForm.errors.education,
                    })}
                    {...formikForm.getFieldProps("education")}
                  >
                    <option disabled hidden value="">
                      {t<string>("PROFILE.PLEASE_SELECT")}
                    </option>
                    <option value="0">{t<string>("PROFILE.UNIVERSITY")}</option>
                    <option value="1">
                      {t<string>("PROFILE.HIGH_SCHOOL")}
                    </option>
                    <option value="2">{t<string>("PROFILE.SECONDARY")}</option>
                    <option value="3">{t<string>("PROFILE.PRIMARY")}</option>
                    <option value="4">
                      {t<string>("PROFILE.NO_COMPLETED")}
                    </option>
                  </select>
                </label>
              </div>
              {formikForm.touched.education && formikForm.errors.education && (
                <div className="form-control-error">
                  {t<string>(formikForm.errors.education)}
                </div>
              )}
              <div className="about__form-box about-ask about-ask-bottom">
                <h4 className="about-ask__question">
                  {t<string>("PROFILE.SIGNIFICANT_PERSON")}
                </h4>
                <div className="about-ask__variants">
                  <label
                    className={classNames({
                      "about-ask__variants-block": true,
                      "var-block": true,
                      "var-block-selected": formikForm.values.politic === "1",
                    })}
                    htmlFor="politic-yes"
                  >
                    {t<string>("COMMON.YES")}
                    <input
                      className="var-block__inp"
                      type="radio"
                      name="politic"
                      id="politic-yes"
                      value="1"
                      onChange={(e) =>
                        formikForm.setFieldValue("politic", e.target.value)
                      }
                    />
                  </label>
                  <label
                    className={classNames({
                      "about-ask__variants-block": true,
                      "var-block": true,
                      "var-block-selected": formikForm.values.politic === "0",
                    })}
                    htmlFor="politic-no"
                  >
                    {t<string>("COMMON.NO")}
                    <input
                      className="var-block__inp"
                      type="radio"
                      name="politic"
                      id="politic-no"
                      value="0"
                      onChange={(e) =>
                        formikForm.setFieldValue("politic", e.target.value)
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
            <h4 className="about-ask__question">
              {t<string>("PROFILE.JOB_EXIST")}
            </h4>
            <div className="about-ask__variants">
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.job === "1",
                })}
                type="button"
                onClick={() => {
                  jobInfoClear("1");
                }}
              >
                {t<string>("COMMON.YES")}
              </button>
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.job === "0",
                })}
                type="button"
                onClick={() => {
                  jobInfoClear("0");
                }}
              >
                {t<string>("COMMON.NO")}
              </button>
            </div>
          </div>
        </div>
        {formikForm.values.job === "0" && (
          <div className="about__form">
            <h2 className="about__title">{t<string>("PROFILE.LIFESTYLE")}</h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.job_status,
                    "about-box__field-inp-error":
                      formikForm.touched?.job_status &&
                      formikForm.errors?.job_status,
                  })}
                >
                  {t<string>("PROFILE.STATUS")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.job_status,
                    "about-box__field-inp-touched":
                      formikForm.touched?.job_status &&
                      formikForm.errors?.job_status,
                  })}
                  {...formikForm.getFieldProps("job_status")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("PROFILE.STUDENT")}</option>
                  <option value="1"> {t<string>("PROFILE.UNEMPLOYED")}</option>
                  <option value="2">{t<string>("PROFILE.PENSIONER")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched?.job_status &&
              formikForm.errors?.job_status && (
                <div className="form-control-error">
                  {formikForm.errors?.job_status ||
                    `${t<string>("COMMON.IS_REQUIRED")}`}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.income_from,
                    "about-box__field-inp-error":
                      formikForm.touched.income_from &&
                      formikForm.errors.income_from,
                  })}
                >
                  {t<string>("PROFILE.INCOME_WAY")}
                </span>
                <input
                  type="text"
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.income_from,
                    "about-box__field-inp-touched":
                      formikForm.touched.income_from &&
                      formikForm.errors.income_from,
                  })}
                  value={formikForm.values.income_from}
                  onChange={(event) => {
                    if (event.target.value.length > 36) {
                      formikForm.setFieldValue("income_from", prevIncome);
                    } else {
                      formikForm.setFieldValue(
                        "income_from",
                        event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.income_from &&
              formikForm.errors.income_from && (
                <div className="form-control-error">
                  {t<string>(formikForm.errors?.income_from)}
                </div>
              )}
          </div>
        )}

        {formikForm.values.job === "1" && (
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.working_place,
                    "about-box__field-inp-error":
                      formikForm.touched.working_place &&
                      formikForm.errors.working_place,
                  })}
                >
                  {t<string>("PROFILE.WORK_PLACE")}
                </span>
                <input
                  placeholder={t<string>("PROFILE.ORGANIZATION")}
                  type="text"
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.working_place,
                    "about-box__field-inp-touched":
                      formikForm.touched?.working_place &&
                      formikForm.errors?.working_place,
                  })}
                  {...formikForm.getFieldProps("working_place")}
                />
              </label>
            </div>
            {formikForm.touched?.working_place &&
              formikForm.errors?.working_place && (
                <div className="form-control-error">
                  {formikForm.errors?.working_place ||
                    `${t<string>("COMMON.IS_REQUIRED")}`}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.position,
                    "about-box__field-inp-error":
                      formikForm.touched?.position &&
                      formikForm.errors?.position,
                  })}
                >
                  {t<string>("PROFILE.POSITION")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.position,
                    "about-box__field-inp-touched":
                      formikForm.touched?.position &&
                      formikForm.errors?.position,
                  })}
                  {...formikForm.getFieldProps("position")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("PROFILE.EMPLOYED")}</option>
                  <option value="1">{t<string>("PROFILE.CONTRACT")}</option>
                  <option value="2">
                    {t<string>("PROFILE.SELF_EMPLOYED")}
                  </option>
                  <option value="3">{t<string>("PROFILE.FREELANCE")}</option>
                  <option value="4">{t<string>("PROFILE.CIVIL")}</option>
                  <option value="5">{t<string>("PROFILE.OTHER")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched?.position && formikForm.errors?.position && (
              <div className="form-control-error">
                {t<string>(formikForm.errors?.position)}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.experience,
                    "about-box__field-inp-error":
                      formikForm.touched?.experience &&
                      formikForm.errors?.experience,
                  })}
                >
                  {t<string>("PROFILE.WORK_EXPERIENCE")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.experience,
                    "about-box__field-inp-touched":
                      formikForm.touched?.experience &&
                      formikForm.errors?.experience,
                  })}
                  type="text"
                  value={formikForm.values.experience}
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("experience", "");
                    } else {
                      formikForm.setFieldValue(
                        "experience",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched?.experience &&
              formikForm.errors?.experience && (
                <div className="form-control-error">
                  {t<string>(formikForm.errors?.experience)}
                </div>
              )}
          </div>
        )}

        {formikForm.values.job && (
          <div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.monthly_income,
                    "about-box__field-inp-error":
                      formikForm.touched.monthly_income &&
                      formikForm.errors.monthly_income,
                  })}
                >
                  {t<string>("PROFILE.INCOME")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.monthly_income,
                    "about-box__field-inp-touched":
                      formikForm.touched.monthly_income &&
                      formikForm.errors.monthly_income,
                  })}
                  placeholder="1000"
                  type="text"
                  value={formikForm.values.monthly_income}
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("monthly_income", "");
                    } else {
                      formikForm.setFieldValue(
                        "monthly_income",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.monthly_income &&
              formikForm.errors.monthly_income && (
                <div className="form-control-error">
                  {t<string>(formikForm.errors.monthly_income)}
                </div>
              )}
          </div>
        )}
        {certificates?.payload?.collection.length > 0 && (
          <div
            className="about__form-files"
            onClick={() => {
              dispatch(
                setModalType(AuthSelectionType.selectFromMyCertificates)
              );
              dispatch(setModal(true));
            }}
          >
            {t<string>("PROFILE.SELECT_FROM_DOCUMENTS")}
          </div>
        )}
        <div className="about__form-box about-box">
          {certs.map((cert: any, index: number) => (
            <div
              className="about-box__file-block file-block file-block-item"
              key={index}
            >
              <div className="file-block-select__box">
                <img className="file-block__icon" src={addedFile} alt="file" />
                <span className="file-block__txt">
                  {cert.name} {t<string>("PROFILE.ADDED")}
                </span>
              </div>
              <button
                className="file-block__txt"
                type="button"
                onClick={() => {
                  deleteCert(index, cert);
                }}
              >
                X
              </button>
            </div>
          ))}

          {certs.length < 5 && (
            <label className="about-box__file">
              <div className="about-box__file-block file-block">
                <img className="file-block__icon" src={fileIcon} alt="file" />
                <p
                  className={
                    appLanguage === "en"
                      ? "file-block__txt-en"
                      : "file-block__txt-bg"
                  }
                >
                  <b>{t<string>("PROFILE.ADD_CERT.LINE_1")}</b>
                  <br />
                  {t<string>("PROFILE.ADD_CERT.LINE_2")}
                  <br />
                  {t<string>("PROFILE.ADD_CERT.LINE_3")}
                </p>
              </div>
              <input
                type="file"
                accept=".doc, .docx, .pdf, .xlx, .csv, .zip, .png, .jpg, .jpeg, .gif, .svg"
                className="file-block__inp"
                onChange={(event: any) => {
                  setAddLater(false);
                  const same = certs.some(
                    (cert) => cert.name === event.target.files[0].name
                  );
                  if (certs.length < 5 && event.target.files && !same) {
                    dispatch(setCerts([...certs, event.target.files[0]]));
                    formikForm.validateForm();
                  }
                }}
              />
            </label>
          )}
        </div>
        {btnClicked && certs.length === 0 && !addLater && (
          <div className="form-control-error">
            {t<string>("PROFILE.LEAST_CERT")}
          </div>
        )}
        {certs.length === 0 && (
          <div className="about-box__egn_s3">
            <div className="about-box__egn_s3-checkbox-wrapper">
              <Checkbox
                checked={addLater && certs.length === 0}
                onChange={(e: any) => {
                  setAddLater(e.target.checked);
                  formikForm.validateForm();
                }}
                id="checkboxLabel"
              />
              <label htmlFor="checkboxLabel">
                <p className="line1">
                  {t<string>("PROFILE.LATER_CERT.MAIN_TEXT")}
                </p>
              </label>
            </div>
            <span className="line2">
              *{t<string>("PROFILE.LATER_CERT.ADDITIONAL_TEXT")}
            </span>
          </div>
        )}
        <button
          className={classNames({
            about__submit: true,
            "about__submit-active":
              formikForm.isValid && validateSingleInputs(),
          })}
          type="submit"
          onClick={() => setBtnClicked(true)}
        >
          {t<string>("PROFILE.SUBMIT_QUEST")}
        </button>
      </form>
    </>
  );
};

export default ThirdStep;
