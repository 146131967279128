import { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import InputMask from "react-input-mask";
// import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { AppContext } from "../../../../App";

import { useAppSelector } from "../../../../core/store";
import PrivateService from "../../../../core/services/private.service";
import CommonService from "../../../../core/services/common.service";
import { filterPhoneNum, getPreviousDay } from "../../../../core/functions/funtions";

import defaultAvatar from "../../../../assets/photos/profile/default-avatar.svg";
import fileIcon from "../../../../assets/photos/all/add-file.svg";
import { useTranslation } from "react-i18next";
import CalendarIcon from "../../../../assets/photos/Calendar";
import DatePicker from "react-date-picker";
import { usePrevious } from "../../../../core/hooks/usePrevious";

const PersonalInfo = () => {
  const [loading, setLoading] = useState(false);
  const [touchedPassport, setTouchedPassport] = useState(false)
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState<any>();
  const [avatarChecker, setAvatarChecker] = useState<string>("");

  const { t } = useTranslation();

  const { currentUser } = useAppSelector((state) => state.auth);
  const { questionnaire} = useAppSelector((state) => state.app);
  const { appLanguage } = useAppSelector((state) => state.app);
  const savedUser = JSON.parse(localStorage.savedUser)
  const appContext = useContext(AppContext);
  
  const { reloadChecker, setReloadChecker } = appContext;
  useEffect(() => {
    currentUser && setAvatarChecker(currentUser["avatar"]);
  }, [currentUser])
  useEffect(() => {
    formikForm.resetForm();
    if (questionnaire) {
      const newUser = JSON.parse(JSON.stringify(questionnaire));
      for (var property in newUser) {
        if (!newUser[property] && newUser[property] !== 0) {
          newUser[property] = "";
        }
      }

      formikForm.setValues({ ...formikForm.values, ...newUser });
      if (questionnaire["personal_code"]) {
        formikForm.setFieldValue("personal_code", questionnaire["personal_code"]);
        formikForm.setFieldValue("egn", false);
      } else {
        formikForm.setFieldValue("egn", "");
        formikForm.setFieldValue("personal_code", "");
      }
    }
  }, [questionnaire]);

  const valueFormValidationSchema = Yup.object().shape({
    first_name: Yup.string().max(55),
    last_name: Yup.string().max(55),
    phone: Yup.string().max(55),
    gender: Yup.string(),
    date_of_birth: Yup.date()
      .max(
        moment().subtract(1, "days").toDate(),
        t<string>("PROFILE.INVALID_DATE")
      )
      .min(
        moment().subtract(122, "years").toDate(),
        t<string>("PROFILE.INVALID_DATE")
      ),
    citizenship: Yup.string(),

    personal_code: Yup.string().when("egnCheckbox", {
      is: false,
      then: Yup.string()
        .required(t<string>("NEW_USER.ADD_EGN"))
        .matches(/^\d{10}$/, t<string>("NEW_USER.EGN_RULE")),
      otherwise: Yup.string(),
    }),
    egnCheckbox: Yup.boolean(),

    passport: Yup.string().length(9, t<string>("PROFILE.PASSPORT_RULE")),
    authority: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    email: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    date_of_issue: Yup.date()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(moment().subtract(1, "days").toDate(), "Invalid date")
      .min(moment().subtract(122, "years").toDate(), "Invalid date"),
  });

  const formikForm = useFormik<{
    avatar: string;
    first_name: string;
    last_name: string;
    phone: string;
    gender: string;
    date_of_birth: string;
    citizenship: string;
    personal_code: string;
    passport: string;
    egn: boolean;
    authority: string;
    date_of_issue: string;
    email: string;
    checked: boolean
  }>({
    initialValues: {
      avatar: "",
      first_name: "",
      last_name: "",
      phone: "",
      gender: "",
      date_of_birth: "",
      citizenship: "",
      personal_code: "",
      passport: "",
      egn: false,
      authority: '',
      date_of_issue: "",
      email: "",
      checked: false,
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const egnValidator = () => {
    if (
      formikForm.values.personal_code.length === 0 &&
      formikForm.values.egn === true
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const egnLengthValidator = () => {
    if (
      formikForm.values.personal_code.length === 0 ||
      formikForm.values.personal_code.length === 10
    ) {
      return true;
    }

    return false;
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);
    let avatar;

    if (values.avatar !== avatarChecker && values.avatar) {
      avatar = await urltoFile(
        values.avatar,
        `photo${Math.random() * 10000}.png`,
        "image/png"
      );
    } else {
      avatar = "";
    }
    egnValidator()
    delete values.egn;
    try {
      const response = await PrivateService.updateUserQuestionnaire({
        ...values,
      }, questionnaire.id);

      setLoading(false);
      setReloadChecker(!reloadChecker);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      CommonService.showErrors(errors?.response?.data?.payload);
      toast.error(errors?.response?.data?.message);
      setLoading(false);
    }
  };

  const prevEgn = usePrevious(formikForm.values.personal_code);
  const prevPassport = usePrevious(formikForm.values.passport);


  const emailVerification = async (email: string) => {
    try {
      const response = await PrivateService.emailVerify(email);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.payload.email[0]);
    }
  };

  const pictureLoader = (e: any) => {
    setCropper(undefined);

    e.preventDefault();

    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result as any);
    };

    reader.readAsDataURL(files[0]);
  };

  const urltoFile = (url: string, filename: string, mimeType: string) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      formikForm.setFieldValue(
        "avatar",
        cropper.getCroppedCanvas().toDataURL()
      );
      setImage(undefined);
    }
  };

  return (
    <div className="edit-info">
      <form className="about" onSubmit={formikForm.handleSubmit}>
        <div className="about__form">
          <div className="about__form-avatar">
            <div className="about__form-box about-box avatar-centered">
              {image ? (
                <>
                  {image && (
                    <div className="about-box__cropper">
                      <Cropper
                        style={{
                          height: 250,
                          width: 250,
                        }}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={200}
                        minCropBoxWidth={200}
                        cropBoxResizable={false}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />
                      <button
                        className="about-box__cropper-btn"
                        onClick={getCropData}>
                        Crop
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {formikForm.values.avatar ? (
                    <div className="about-box__avatar">
                      <div className="about-box__avatar-image">
                        <img
                          className="about-box__avatar-image-own"
                          src={formikForm.values.avatar}
                          alt="avatar"
                        />
                      </div>
                      <label className="about-box__file">
                        <div className="about-box__file-block file-block">
                          <img
                            className="file-block__icon"
                            src={fileIcon}
                            alt="file"
                          />
                          <span className="file-block__txt">
                            {t<string>("PROFILE.CHANGE_AVATAR")}
                          </span>
                        </div>
                        <input
                          className="file-block__inp"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files) {
                              pictureLoader(e);
                            }
                          }}
                          accept="image/jpg, image/jpeg, image/png, image/gif"
                        />
                      </label>
                    </div>
                  ) : (
                    <label className="about-box__file">
                      <div className="about-box__avatar-image">
                        <img
                          className="about-box__avatar-image-own"
                          src={defaultAvatar}
                          alt="avatar"
                        />
                      </div>
                      <div className="about-box__file-block file-block">
                        <img
                          className="file-block__icon"
                          src={fileIcon}
                          alt="file"
                        />
                        <span className="file-block__txt">
                          {t<string>("PROFILE.CHANGE_AVATAR")}
                        </span>
                      </div>
                      <input
                        type="file"
                        accept=" .png, .jpg, .jpeg, .gif"
                        className="file-block__inp"
                        onChange={(e) => {
                          if (e.target.files) {
                            pictureLoader(e);
                          }
                        }}
                      />
                    </label>
                  )}
                </>
              )}
            </div>
          </div>
          <h2 className="about__title">{t<string>("PROFILE.PERSONAL_INFO")}</h2>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.first_name,
                  "about-box__field-inp-error":
                    formikForm.touched.first_name &&
                    formikForm.errors.first_name,
                })}>
                {t<string>("PROFILE.FIRST_NAME")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.first_name &&
                    formikForm.errors.first_name,
                  "about-box__field-inp-filled": formikForm.values.first_name,
                })}
                placeholder={t<string>("PROFILE.FIRST_NAME_PLACEHOLDER")}
                {...formikForm.getFieldProps("first_name")}
              />
            </label>
          </div>
          {formikForm.touched.first_name && formikForm.errors.first_name && (
            <div className="form-control-error">
              {formikForm.errors.first_name}
            </div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.last_name && formikForm.errors.last_name,
                  "about-box__field-name-filled": formikForm.values.last_name,
                })}>
                {t<string>("PROFILE.LAST_NAME")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.last_name && formikForm.errors.last_name,
                  "about-box__field-inp-filled": formikForm.values.last_name,
                })}
                placeholder={t<string>("PROFILE.LAST_NAME_PLACEHOLDER")}
                {...formikForm.getFieldProps("last_name")}
              />
            </label>
          </div>
          {formikForm.touched.last_name && formikForm.errors.last_name && (
            <div className="form-control-error">
              {formikForm.errors.last_name}
            </div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.phone,
                  "about-box__field-inp-error":
                    formikForm.touched.phone && formikForm.errors.phone,
                })}>
                {t<string>("PROFILE.PHONE_NUMBER")}
              </span>
              <InputMask
                  name="phone"
                  className={classNames("data-input__input-field",{
                    "form-control": true,
                    "form-control-touched":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}
                  maskChar=" "
                  mask="+35\9 (999) 999-9999"
                  placeholder="+359 (___) ___-____ "
                  value={formikForm.values.phone}
                  onChange={(event) => {
                    formikForm.setFieldValue(
                      "phone",
                      filterPhoneNum(event.target.value)
                    );
                  }}
                />
            </label>
          </div>
          {formikForm.touched.phone && formikForm.errors.phone && (
            <div className="form-control-error">{formikForm.errors.phone}</div>
          )}
                  <div className="about__form-box about-box">
          <label className="about-box__field about-box__field-email">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.email,
                "about-box__field-inp-error":
                  formikForm.touched.email && formikForm.errors.email,
              })}
            >
              {t<string>("PROFILE.EMAIL")}
            </span>
            <input
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-inp-filled": formikForm.values.email,
                "about-box__field-inp-touched":
                  formikForm.touched.email && formikForm.errors.email,
              })}
              placeholder={t<string>("PROFILE.EMAIL")}
              {...formikForm.getFieldProps("email")}
            />
          </label>
        </div>
        {!savedUser?.verified && (
          <div className="about__form-bottom">
            <p className="about__form-bottom-text">
              {appLanguage === "en"
                ? "Check your email for a confirmation email"
                : "Проверете пощата си за имейл за потвърждение"}
            </p>
            <span
              className="about__form-bottom-link"
              onClick={() => emailVerification(formikForm.values.email)}
            >
              {appLanguage === "en"
                ? "Send confirmation again"
                : "Изпратете потвърждение отново"}
            </span>
          </div>
        )}

          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.gender,
                  "about-box__field-inp-error":
                    formikForm.touched.gender && formikForm.errors.gender,
                })}>
                {t<string>("PROFILE.GENDER")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.gender,
                  "about-box__field-inp-touched":
                    formikForm.touched.gender && formikForm.errors.gender,
                })}
                {...formikForm.getFieldProps("gender")}>
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="0">{t<string>("PROFILE.MALE")}</option>
                <option value="1">{t<string>("PROFILE.FEMALE")}</option>
              </select>
            </label>
          </div>
          {formikForm.touched.gender && formikForm.errors.gender && (
            <div className="form-control-error">{formikForm.errors.gender}</div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.date_of_birth,
                  "about-box__field-inp-error":
                    formikForm.touched.date_of_birth &&
                    formikForm.errors.date_of_birth,
                })}>
                {t<string>("PROFILE.DATE_OF_BIRTH")}
              </span>
              <DatePicker
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled":
                    formikForm.values.date_of_birth,
                  "about-box__field-inp-touched":
                    formikForm.touched.date_of_birth &&
                    formikForm.errors.date_of_birth,
                })}
                format="dd/MM/y"
                dayPlaceholder={t<string>("COMMON.CALENDAR_DAY")}
                monthPlaceholder={t<string>("COMMON.CALENDAR_MONTH")}
                yearPlaceholder={t<string>("COMMON.CALENDAR_YEAR")}
                locale={appLanguage}
                maxDate={getPreviousDay()}
                minDate={new Date(new Date("1900-01-01").getTime())}
                onChange={(e: any) => {
                  if (e) {
                    formikForm.setFieldValue(
                      "date_of_birth",
                      `${e.getFullYear()}-${
                        Number(e.getMonth() + 1) < 10
                          ? "0" + `${e.getMonth() + 1}`
                          : e.getMonth() + 1
                      }-${
                        Number(e.getDate()) < 10
                          ? "0" + `${e.getDate()}`
                          : e.getDate()
                      }`
                    );
                  }
                }}
                value={
                  formikForm.values.date_of_birth
                    ? new Date(
                        new Date(formikForm.values.date_of_birth).getTime()
                      )
                    : undefined
                }
                calendarIcon={<CalendarIcon />}
              />
              {formikForm.touched.date_of_birth &&
                formikForm.errors.date_of_birth && (
                  <div className="form-control-error">
                    {formikForm.errors.date_of_birth}
                  </div>
                )}
            </label>
          </div>
        </div>
        {formikForm.touched.date_of_birth &&
          formikForm.errors.date_of_birth && (
            <div className="form-control-error">
              {formikForm.errors.date_of_birth}
            </div>
          )}
        <div className="about__form">
          <h2 className="about__title">{t<string>("PROFILE.PASSPORT_INFO")}</h2>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.citizenship &&
                    formikForm.errors.citizenship,
                })}>
                {t<string>("PROFILE.CITIZENSHIP")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.citizenship,
                  "about-box__field-inp-touched":
                    formikForm.touched.citizenship &&
                    formikForm.errors.citizenship,
                })}
                value={formikForm.values.citizenship}
                onChange={(e) =>
                  formikForm.setFieldValue("citizenship", e.target.value)
                }>
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="0">
                  {t<string>("COUNTRY.BULGARIA")[0] +
                    t<string>("COUNTRY.BULGARIA").slice(1).toLowerCase()}
                </option>
                <option value="1">{t<string>("PROFILE.OTHER")}</option>
              </select>
            </label>
          </div>
          {formikForm.touched.citizenship && formikForm.errors.citizenship && (
            <div className="form-control-error">
              {formikForm.errors.citizenship}
            </div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                  !egnLengthValidator() ||
                      (formikForm.values.personal_code.length === 0 &&
                        formikForm.values.egn && formikForm.touched.personal_code),
                })}>
                {t<string>("UPDATE_USER.PERSONAL_IDENTIFICATION_CODE")}
              </span>
              <input
                disabled={formikForm.values.egn}
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled":
                    formikForm.values.personal_code,
                  "about-box__field-inp-touched":
                  (formikForm.values.personal_code.length === 0 &&
                    !formikForm.values.egn && formikForm.touched.personal_code && formikForm.errors.personal_code) || 
                    !egnLengthValidator(),
                })}
                placeholder="1002498384"
                type="text"
                value={formikForm.values.personal_code}
                onChange={(event) => {
                  if (
                    +event.target.value <= 0 ||
                    Number.isNaN(+event.target.value)
                  ) {
                    formikForm.setFieldValue("personal_code", "");
                  } else {
                    if (event.target.value.length > 10) {
                      formikForm.setFieldValue("personal_code", prevEgn);
                    } else {
                      formikForm.setFieldValue(
                        "personal_code",
                        event.target.value
                      );
                      egnLengthValidator()
                    }
                  }
                  egnLengthValidator();
                }}
              />
            </label>
            <label className="about-box__egn">
              <input
                checked={formikForm.values.egn}
                type="checkbox"
                onChange={() => {
                  if (formikForm.values.egn !== true) {
                    formikForm.setFieldValue("egn", true);
                    formikForm.setFieldValue("personal_code", "");
                  } else {
                    formikForm.setFieldValue("egn", false);
                  }
                }}
              />
              {t<string>("UPDATE_USER.NO_EGN")}
            </label>
          </div>
          {!egnLengthValidator() && (
              <div className="form-control-error">
                {t<string>("PROFILE.ADD_EGN")} 
                {/* Поменял местами текста ошибок EGN_RULE и ADD_ENG. EGN_RULE пока не отрабатывает*/}
              </div>
            )}
            {!formikForm.values.egn &&
              formikForm.touched.egn &&
              formikForm.touched.personal_code &&
              formikForm.errors.personal_code && (
                <div className="form-control-error">
                  {t<string>("UPDATE_QUESTIONNAIRE.EGN_RULE")}
                </div>
            )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                  touchedPassport && formikForm.values.passport.length <=8,
                })}>
                {t<string>("PROFILE.PASSPORT_NUMBER")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.passport,
                  "about-box__field-inp-touched":
                  touchedPassport && formikForm.values.passport.length <=8,
                })}
                placeholder="1002498384"
                type="text"
                value={formikForm.values.passport}
                onChange={(event) => {
                  if (
                    +event.target.value < 0 ||
                    Number.isNaN(+event.target.value)
                  ) {
                    formikForm.setFieldValue("passport", "");
                  } else {
                    if (event.target.value.length > 9) {
                      formikForm.setFieldValue("passport", prevPassport);
                      setTouchedPassport(false)
                    } else {
                      setTouchedPassport(true)
                      formikForm.setFieldValue("passport", event.target.value);
                    }
                  }
                }}
              />
            </label>
          </div>
          {touchedPassport && formikForm.values.passport.length <=8 && (
            <div className="form-control-error">
              {t<string>(String(formikForm.errors.passport))}
            </div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.authority,
                  "about-box__field-inp-error":
                  formikForm.touched.authority &&
                    formikForm.errors.authority,
                })}
              >
                  {t<string>("PROFILE.AUTHORITY")}
                </span>
                <input
                  className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.authority,
                  "about-box__field-inp-touched":
                    formikForm.touched.authority &&
                    formikForm.errors.authority,
                })}
                placeholder={t<string>("PROFILE.AUTHORITY_PLACEHOLDER")}
                {...formikForm.getFieldProps("authority")}
                />
              </label>
            </div>
            {formikForm.touched.authority && formikForm.errors.authority && (
            <div className="form-control-error">
              {formikForm.errors.authority}
            </div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.date_of_issue,
                  "about-box__field-inp-error":
                    formikForm.touched.date_of_issue &&
                    formikForm.errors.date_of_issue,
                })}
              >
                {t<string>("PROFILE.DATE_OF_ISSUE")}
              </span>
              <DatePicker
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled":
                    formikForm.values.date_of_issue,
                  "about-box__field-inp-touched":
                    formikForm.touched.date_of_issue &&
                    formikForm.errors.date_of_issue,
                })}
                format="dd/MM/y"
                dayPlaceholder={t<string>("COMMON.CALENDAR_DAY")}
                monthPlaceholder={t<string>("COMMON.CALENDAR_MONTH")}
                yearPlaceholder={t<string>("COMMON.CALENDAR_YEAR")}
                locale={appLanguage}
                maxDate={getPreviousDay()}
                minDate={new Date(new Date("1900-01-01").getTime())}
                onChange={(e: any) => {
                  if (e) {
                    formikForm.setFieldValue(
                      "date_of_issue",
                      `${e.getFullYear()}-${
                        Number(e.getMonth() + 1) < 10
                          ? "0" + `${e.getMonth() + 1}`
                          : e.getMonth() + 1
                      }-${
                        Number(e.getDate()) < 10
                          ? "0" + `${e.getDate()}`
                          : e.getDate()
                      }`
                    );
                  }
                }}
                value={
                  formikForm.values.date_of_issue
                    ? new Date(
                        new Date(formikForm.values.date_of_issue).getTime()
                      )
                    : undefined
                }
                calendarIcon={<CalendarIcon />}
              />
              {formikForm.touched.date_of_issue &&
                formikForm.errors.date_of_issue && (
                  <div className="form-control-error">
                    {formikForm.errors.date_of_issue}
                  </div>
                )}
            </label>
          </div>
        </div>

        <button
          className={classNames({
            about__submit: true,
            "about__submit-active":
              formikForm.isValid &&
              formikForm.touched,
          })}
          type="submit">
          {t<string>("PROFILE.SAVE_CHANGES")}
        </button>
      </form>
    </div>
  );
};

export default PersonalInfo;
