import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCJ9CPJ_UvZ1Xj2XYYihDdWvrUt4_wUpd8",
  authDomain: "asistant-front.firebaseapp.com",
  projectId: "asistant-front",
  storageBucket: "asistant-front.appspot.com",
  messagingSenderId: "712469833945",
  appId: "1:712469833945:web:b75ba0e03f3ae57ad376c8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentiticator = getAuth(app);