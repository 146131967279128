import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { GrAddCircle, GrEdit } from "react-icons/gr";
import { CiCircleRemove } from "react-icons/ci";
import { BiCommentDetail } from "react-icons/bi";

import { AppContext } from "../../../App";

import useHttpGet from "../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../core/http";
import PrivateService from "../../../core/services/private.service";
import CommonService from "../../../core/services/common.service";
import { Private } from "../../../core/models";

import search from "../../../assets/photos/profile/search.svg";
import sortingArrow from "../../../assets/photos/all/sorting-arrow.svg";
import { useAppDispatch } from "../../../core/store";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../core/services/enums";
import {
  setChoosenData,
  setSelectedId,
} from "../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";

const AdminBannerReview = () => {
  const [filterValue, setFilterValue] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filter, setFilter] = useState<any>({
    "filter[type][like]": "review",
  });
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);

  const { reloadChecker, setReloadChecker, setComments, setReviewId } = appContext;

  const { fetchedData: reviews } = useHttpGet<any>(
    `${APIRoutes.ADMIN_PUBLIC}/launch_banners`,

    {
      dependencies: [filter, sorter, pagination, reloadChecker],
      query: { ...filter, ...sorter, ...pagination },
    }
  );

  const deleteRequest = async (id: number) => {
    try {
      const response = await PrivateService.deleteBannerRequest(id);

      toast.success(response?.data?.message);
      setReloadChecker(!reloadChecker);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
      CommonService.showErrors(errors?.response?.data?.payload);
    }
  };

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({
      "filter[type][like]": "review",

      [propName]: value || undefined,
    });
  };
  

  const setPaginationPage = () => {
    setPagination(()=>{
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 }
      }else{
        return {...pagination, per_page: 50}
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};
    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  const showComment = (comment: string, id: string | number) => {
    setComments(comment);
    setReviewId(String(id))
    dispatch(setModalType(AuthSelectionType.showComment));
    dispatch(setModal(true));
  };

  const updateReview = (review: Private.IAdminBannerRequest) => {
    dispatch(setSelectedId(review.id));
    dispatch(setChoosenData(review));
    dispatch(setModalType(AuthSelectionType.updateReview));
    dispatch(setModal(true));
  };

  const newReview = () => {
    dispatch(setModalType(AuthSelectionType.createReview));
    dispatch(setModal(true));
  };

  return (
    <div className="profile-table blogs-table">
      <div className="admin-search">
        <input
          className="admin-search__inp"
          value={filterValue}
          onChange={(e) => {
            setFilterValue(e.target.value);
          }}
          onKeyPress={(e: any) => {
            if (e.key === "Enter" && filterType) {
              setFilterProperty(filterType, filterValue);
            }
          }}
        />
        <select
          onChange={(e) => {
            e.target.value === "search"
              ? setFilterType(`filter[${e.target.value}]`)
              : setFilterType(`filter[${e.target.value}][like]`);
          }}
          className="admin-search__dropdown"
        >
          <option value="search">{t<string>("BANNERS.SEARCH_ALL")}</option>
          <option value="id">{t<string>("BANNERS.ID")}</option>
          <option value="bank_name">{t<string>("BANNERS.BANK_NAME")}</option>
          <option value="rating">{t<string>("BANNERS.RATING")}</option>
          <option value="full_name">{t<string>("BANNERS.FULL_NAME")}</option>
          <option value="email">{t<string>("BANNERS.EMAIL")}</option>
          <option value="comment">{t<string>("BANNERS.COMMENT")}</option>
        </select>
        <button className="admin-search__btn">
          <img
            src={search}
            alt="search"
            onClick={() => {
              if (filterType) {
                setFilterProperty(filterType, filterValue);
              }
            }}
          />
        </button>
        <button
          className="add-user__btn"
          title={t<string>("BANNERS.ADD_NEW")}
          onClick={() => newReview()}
        >
          <GrAddCircle />
        </button>
      </div>

      <div className="users-container">
        <table className="users-table" cellSpacing="0" cellPadding="0">
          <thead className="users-table-head">
            <tr className="users-table-head-row">
              <td className="users-table-head__item">
                {t<string>("BANNERS.ID")}
                <button
                  onClick={() => {
                    setSorterProperty("order[id]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item">
                {t<string>("BANNERS.TYPE")}
              </td>
              <td className="users-table-head__item">
                {t<string>("BANNERS.BANK")}
                <button
                  onClick={() => {
                    setSorterProperty("order[bank_name]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item">
                {t<string>("BANNERS.RATING")}
                <button
                  onClick={() => {
                    setSorterProperty("order[rating]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item">
                {t<string>("BANNERS.FULL_NAME")}
                <button
                  onClick={() => {
                    setSorterProperty("order[full_name]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item">
                {t<string>("BANNERS.EMAIL")}
                <button
                  onClick={() => {
                    setSorterProperty("order[email]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item">
                {t<string>("BANNERS.COMMENT")}
                <button
                  onClick={() => {
                    setSorterProperty("order[comment]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item">
                {t<string>("BANNERS.CREATED")}
                <button
                  onClick={() => {
                    setSorterProperty("order[created_at]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item">
                {t<string>("BANNERS.UPDATED")}
                <button
                  onClick={() => {
                    setSorterProperty("order[updated_at]");
                  }}
                >
                  <img src={sortingArrow} alt="sortingArrow" />
                </button>
              </td>
              <td className="users-table-head__item"></td>
            </tr>
          </thead>
          <tbody className="users-table-content">
            {reviews?.payload?.collection.length > 0 &&
              reviews?.payload?.collection.map(
                (review: Private.IAdminBannerRequest) => (
                  <tr className="users-table-row" key={review.id}>
                    <td className="users-table-row__item">{review.id}</td>
                    <td className="users-table-row__item">{review.type}</td>
                    <td className="users-table-row__item">
                      {review.bank_name}
                    </td>
                    <td className="users-table-row__item">{review.rating}</td>
                    <td className="users-table-row__item">
                      {review.full_name}
                    </td>
                    <td className="users-table-row__item">{review.email}</td>
                    <td className="users-table-row__item">
                      <button
                        onClick={() => showComment(review.comment, review.id)}
                        className="users-table-action__btn"
                        title={t<string>("BANNERS.SHOW_COMMENT")}
                      >
                        <BiCommentDetail />
                      </button>
                    </td>
                    <td className="users-table-row__item">
                      <span>{`${new Date(
                        review?.created_at
                      ).toLocaleTimeString()}`}</span>
                      <br></br>
                      {`${review?.created_at.slice(
                        8,
                        10
                      )}.${review?.created_at.slice(
                        5,
                        7
                      )}.${review?.created_at.slice(0, 4)}`}
                    </td>
                    <td className="users-table-row__item">
                      <span>{`${new Date(
                        review?.updated_at
                      ).toLocaleTimeString()}`}</span>
                      <br></br>
                      {`${review?.updated_at.slice(
                        8,
                        10
                      )}.${review?.updated_at.slice(
                        5,
                        7
                      )}.${review?.updated_at.slice(0, 4)}`}
                    </td>
                    <td className="users-table-row__item">
                      <button
                        onClick={() => updateReview(review)}
                        className="users-table-action__btn"
                        title={t<string>("BANNERS.UPDATE_TITLE")}
                      >
                        <GrEdit />
                      </button>
                      <button
                        onClick={() => deleteRequest(review.id)}
                        className="users-table-action__btn"
                        title={t<string>("BANNERS.DELETE_TITLE")}
                      >
                        <CiCircleRemove />
                      </button>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
      {reviews?.payload?.cursor.has_more_pages && (
        <button
          className="profile-table__btn"
          onClick={() => {
            setPaginationPage();
          }}
        >
          {t<string>("BANNERS.SHOW_MORE")}
        </button>
      )}
      {!reviews?.payload?.cursor.has_more_pages &&
        reviews?.payload?.collection.length > 10 && (
          <button onClick={setDefaultPagination} className="profile-table__btn">
            {t<string>("BANNERS.HIDE_ALL")}
          </button>
        )}
    </div>
  );
};

export default AdminBannerReview;
