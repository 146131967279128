import { useContext } from "react";
import { AppContext } from "../../../../App";

import { toast } from "react-toastify";
import { currencyFormat } from "../../../../core/functions/funtions";
import { Private } from "../../../../core/models";
import PrivateService from "../../../../core/services/private.service";
import ReactGA from "react-ga4";

import star from "../../../../assets/photos/all/star.svg";
import photo from "../../../../assets/photos/profile/bank-icon.svg";
import classNames from "classnames";
import { useAppDispatch } from "../../../../core/store";
import {
  setModal,
  setModalType,
} from "../../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../../core/services/enums";
import {
  setChoosenData,
  setSelectedId,
} from "../../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";

interface Props {
  submitted: boolean;
  setSubmitted: (bol: boolean) => void;
  application: Private.BankOffer;
  questionnaire: Private.AdminQuestionnaire;
}

const AdminApplyApplication: React.FC<Props> = ({
  application,
  questionnaire,
  submitted,
  setSubmitted,
}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const applyOffer = async (id: number) => {
    try {
      const response: any = await PrivateService.adminApplyApplication(
        id,
        application.id,
        questionnaire.user_id
      );
      setSubmitted(!submitted);
      toast.success(response?.data?.message);
      ReactGA.event({
        category: "offer_apply",
        action:
          process.env.REACT_APP_ANALYTICS_NAME === ""
            ? "offer_apply"
            : `offer_apply_${process.env.REACT_APP_ANALYTICS_NAME}`,
      });
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
  };

  const totalAmount = () => {
    return currencyFormat(
      Number(questionnaire?.amount_1) +
        Number(questionnaire.amount_2) +
        Number(questionnaire.amount_3)
    );
  };

  const maxTerm = () => {
    return Math.max(
      Number(questionnaire.term_1),
      Number(questionnaire.term_2),
      Number(questionnaire.term_3)
    );
  };

  const showApplicationDetails = (application: any) => {
    dispatch(setModalType(AuthSelectionType.showApplication));
    dispatch(setModal(true));
    dispatch(setChoosenData(application));
  };

  return (
    <div
      className="results-card"
      onClick={() => {
        showApplicationDetails(application);
        dispatch(setSelectedId(questionnaire.id));
      }}>
      <div className="results-card__search-item search-item">
        <div className="search-item__main-info main-info">
          <div className="main-info__headers headers">
            <img
              className="headers__photo"
              src={
                application.bank.logo_path ? application.bank.logo_path : photo
              }
              alt={application.bank.name}
            />
            <div className="headers__info info">
              <span className="info__title">
                {application.bank.translations.bg.name}
              </span>
              <div className="main-info__feedback feedback">
                <div className="feedback__rating rating">
                  <div className="rating__inner">
                    <span>{application.bank.rating}</span>
                    <img src={star} width="18px" height="18px" alt="rating" />
                  </div>
                </div>
                <div className="feedback__reviews reviews">
                  <span className="reviews__reviews-data">
                    {application.bank.reviews_count}
                  </span>
                  <span className="reviews__reviews-title">reviews</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-item__details details">
          <div className="details__sum sum">
            <h5 className="sum__header">Sum</h5>
            <div className="sum__data">
              {/* {questionnaire.type !== "4"
                ? currencyFormat(Number(questionnaire?.amount_1))
                : totalAmount()} */}
              <span>{application.loan_amount_from}</span>
              <span className="sum__data__currency">
                {t<string>("UPDATE_QUESTIONNAIRE.BGN")}
              </span>
              <span> - </span>
              <span>{` ${application?.loan_amount_to}`}</span>
              <span className="sum__data__currency">
                {t<string>("UPDATE_QUESTIONNAIRE.BGN")}
              </span>
            </div>
          </div>
          <div className="details__item-term item-term">
            <h5 className="item-term__header">Term</h5>
            <div className="item-term__data">
              <span>
                {/* {questionnaire.type !== "4" ? questionnaire.term_1 : maxTerm()} */}
                {application?.credit_term_from}
              </span>
              <span>{t<string>("CALCULATOR.MONTHS")}</span>
              <span> - </span>
              <span>{application?.credit_term_to}</span>
              <span>{t<string>("CALCULATOR.MONTHS")}</span>
            </div>
          </div>
          <div className="details__item-rate item-rate">
            <h5 className="item-rate__header">
              {t<string>("CREDIT_OFFERS.RATE")}
            </h5>
            <div className="item-rate__data">
              <span>from</span>
              <span>{application.interest_rate_from}</span>
              <span> %</span>
              <span> - </span>
              <span>{application?.interest_rate_to}</span>
              <span> %</span>
            </div>
          </div>
        </div>
        <button
          className={classNames({
            "search-item__apply-btn": true,
          })}
          onClick={(e) => {
            e.stopPropagation();
            applyOffer(questionnaire.id);
          }}>
          {t<string>("APPLY_APP.APPLY_BTN")}
        </button>
      </div>
    </div>
  );
};

export default AdminApplyApplication;
