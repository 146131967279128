import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CertificateWidget from "../CertificateWidget";
import PrivateResultsCalculator from "../PrivateResultsCalculator";
import BenefitsContainer from "../Benefits";
import Questions from "../Questions";
import ResultsCard from "../Results/ResultsCard";
import ResultsBankCard from "../Results/ResultsBankCard";
// import ResultsCardApplied from "../Results/ResultsCardApplied";

import { APIRoutes } from "../../core/http";
import { Private } from "../../core/models";
import { useAppDispatch, useAppSelector } from "../../core/store";
import useHttpGet from "../../core/hooks/useHttpGet";
import useQuery from "../../core/hooks/useQuery";
import CommonService from "../../core/services/common.service";
import Banner from "../Banner/Banner";
import { CalculatorTab } from "../PrivateResultsCalculator/PrivateResultsCalculator";
// import { results_banks_card } from "../../core/constants/results-banks-card";
import { useTranslation } from "react-i18next";
import useAuth from "../../core/hooks/useAuth";
import { setQuestionnaire } from "../../core/store/reducers/app/appDataSlice";

const ResultsList = () => {
  // const [submittedApplications, setSubmittedApplications] = useState<any>([]);
  const [userQuestionnaires, setUserQuestionnries] = useState(0);
  const [applications, setApplications] = useState<any>([]);
  const [submitted, setSubmitted] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [applicationsLength, setApplicationsLength] = useState(1);
  const [submittedApplicationsFilter, setSubmittedApplicationsFilter] =
    useState<any>({});
  const [creditCards, setCreditCards] = useState<any>([]);
  const [creditCardsLength, setCreditCardsLength] = useState([]);

  const query = useQuery();
  const { id } = useParams();
  const dispatch = useAppDispatch()
  const { appLanguage } = useAppSelector((state) => state.app);
  const { t } = useTranslation();
  const { isAuth } = useAuth();

  const { calculator } = useAppSelector((state) => state.app);
  const { questionnaire } = useAppSelector((state) => state.app);

  const resultCalc = JSON.parse(localStorage.getItem("calcResult") || "{}");
  // const { fetchedData: questionnaire } = useHttpGet<any>(
  //   `${APIRoutes.USER_QUESTIONNAIRES}/${id}`
  // );

  // useHttpGet<any>(APIRoutes.PUBLIC_BANKS_OFFERS, {
  //   resolve: (response: any) => {
  //     setSubmittedApplications(response?.payload?.collection);
  //   },
  //   condition: Object.keys(submittedApplicationsFilter).length !== 0,
  //   query: { ...submittedApplicationsFilter },
  //   dependencies: [submitted, reloadChecker, submittedApplicationsFilter],
  // });
  useHttpGet<any>(APIRoutes.USER_QUESTIONNAIRE, {
    resolve: (response: any) => {
      setUserQuestionnries(response?.payload?.id);
      dispatch(setQuestionnaire(response?.payload))
    },
    condition: isAuth === true,
  });

  useHttpGet<any>(APIRoutes.PUBLIC_USER_BANKS_OFFERS, {
    query: { ...filter },
    dependencies: [filter],
    resolve: (response: any) => {
      setApplications(response?.payload?.collection);
    },
    condition: isAuth === true && Object.keys(filter).length !== 0,
  });

  useHttpGet<any>(APIRoutes.PUBLIC_BANKS_OFFERS, {
    query: { ...filter },
    dependencies: [filter],
    condition: isAuth === false && Object.keys(filter).length !== 0,
    resolve: (response) => {
      setApplications(response?.payload?.collection);
    },
  });

  useHttpGet<any>(APIRoutes.PUBLIC_CREDIT_CARDS, {
    // here is credit cards
    query: { ...filter },
    dependencies: [filter],
    condition: Object.keys(filter).length !== 0,
    resolve: (response: any) => {
      setCreditCards(
        response?.payload?.collection.filter(
          (item: any) => item.annual_fee !== null
        )
      ); //  delete later
    },
  });
  const sortedSubmitedApplication = (id: number) => {
    setApplications(
      applications.filter((applications: any) => applications.id !== id)
    );
    // setApplicationsLength(() => applications.length - 1);
  };

  const sortedSubmitedCreditCards = (id: number) => {
    setCreditCards(
      creditCards.filter((creditCards: any) => creditCards.id !== id)
    );
    // setCreditCardsLength(() => applications.length - 1);
  };

  useEffect(() => {
    setFilter(CommonService.getQueryByPropType("filter", query));
  }, []);

  useEffect(() => {
    if (resultCalc && resultCalc.type !== "4") {
      setFilter({
        ...filter,
        "filter[bank_offer_type_id][in]": resultCalc.type,
        "filter[loan_amount_from][<=]": resultCalc.amount_1,
        "filter[loan_amount_to][>=]": resultCalc.amount_1,
        "filter[credit_term_from][<=]": resultCalc.term_1,
        "filter[credit_term_to][>=]": resultCalc.term_1,
        "filter[questionnaire_id][<>]": id,
      });

      setSubmittedApplicationsFilter({
        ...submittedApplicationsFilter,
        "filter[questionnaire_id][=]": id,
        "filter[bank_offer_type_id][in]": resultCalc.type,
      });
    } else if (resultCalc && resultCalc.type === "4") {
      setFilter({
        ...filter,
        "filter[bank_offer_type_id][in]": resultCalc.type,
        "filter[loan_amount_from][<=]": resultCalc.totalAmount,
        "filter[loan_amount_to][>=]": resultCalc.totalAmount,
        "filter[credit_term_from][<=]": resultCalc.maxTerm,
        "filter[credit_term_to][>=]": resultCalc.maxTerm,
        "filter[questionnaire_id][<>]": id,
      });

      setSubmittedApplicationsFilter({
        ...submittedApplicationsFilter,
        "filter[questionnaire_id][=]": id,
        "filter[bank_offer_type_id][in]": resultCalc.type,
      });
    }
  }, [calculator]);

  return (
    <>
      <PrivateResultsCalculator type={calculator?.type} />
      <div className="results-list results-list-top">
        <div className="results-cards">
          <div className="results-cards__row">
            <div className="results-cards__row-widget-mobile">
              <CertificateWidget />
            </div>
            {/* <div className="results-cards__row-applied">
              <h2 className="results-cards__row-applied-txt">Applied</h2>
              {submittedApplications.map(
                (submittedApplication: Private.BankOffer) => {
                  return (
                    <ResultsCardApplied
                      key={submittedApplication.id}
                      submittedApplication={submittedApplication}
                    />
                  );
                }
              )}
              {results_banks_card.length === 0 && (
                <h5 className="results-cards__row-applied-txt">
                  No offers applied
                </h5>
              )}
            </div> */}
            {/* {questionnaire?.payload?.certificates?.collection.length === 0 && (
              <div className="results-cards__row-widget">
                <CertificateWidget />
              </div>
            )} */}
          </div>
          <div className="results-cards__column">
            {/* <h2 className="results-cards__column-txt">{t<string>("COMMON.APPLICATION")}</h2> */}
            {String(resultCalc.type) &&
            String(resultCalc.type) !== String(CalculatorTab.CreditCard) ? (
              <>
                {applications.map((application: Private.BankOffer) => {
                  return (
                    <ResultsCard
                      key={application.id}
                      application={application}
                      id={userQuestionnaires}
                      passport={questionnaire?.passport}
                      sortedApplication={sortedSubmitedApplication}
                    />
                  );
                })}
                {applications.length <= 0 && (
                  <div className="results-card__img">
                    <img
                      src={require(`../../assets/photos/${
                        appLanguage === "en" ? "EN.png" : "BG.png"
                      }`)}
                      alt="banner"
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {creditCards.map((bankCard: any) => {
                  // HERE is map of cards
                  return (
                    <div key={bankCard.id}>
                      <ResultsBankCard
                        key={bankCard.id}
                        application={bankCard}
                        id={userQuestionnaires}
                        
                        sortedSubmitedCreditCards={sortedSubmitedCreditCards}
                        // submitted={submitted}
                        // setSubmitted={setSubmitted}
                      />
                    </div>
                  );
                })}
                {creditCards.length === 0 && ( // if no cards
                  <h5 className="results-cards__column-txt">
                    {t<string>("APPLY_APP.NO_APPS")}
                  </h5>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <BenefitsContainer />
      <Banner />
      <Questions />
    </>
  );
};

export default ResultsList;
