import React, { useState } from "react";
import classNames from "classnames";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../core/store";
// import { PrivateUIRoutes, UIRoutes } from "../../../core/router";
// import { currencyFormat } from "../../../core/functions/funtions";
// import { setQuestionnaire } from "../../../core/store/reducers/app/appDataSlice";
import defaultAvatar from "../../../assets/photos/profile/default-avatar.svg";
import asistentAvatar from "../../../assets/photos/all/asistent-avatar.svg";
import StarRating from "../../../assets/photos/all/star.svg";
import PrivateService from "../../../core/services/private.service";
import { toast } from "react-toastify";
import CommonService from "../../../core/services/common.service";

type Props = {
  application: any;
  setChangedId: any;
  documents: any;
  setUpdateApplication: any;
};

const QuestionnaireCard: React.FC<Props> = ({
  application,
  setChangedId,
  documents,
  setUpdateApplication,
}) => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { appLanguage } = useAppSelector((state) => state.app);
  const { currentUser } = useAppSelector((state) => state.auth);
  const [userComment, setUserComment] = useState("");
  const [docUpdate, setDocUpdate] = useState(false);
  const { t } = useTranslation();
  // const getTitle = () => {
  //   switch (application.type) {
  //     case "1":
  //       return t<string>("LOANS.CONSUMER_LOANS");

  //     case "2":
  //       return t<string>("LOANS.CAR_LEASING");

  //     case "3":
  //       return t<string>("LOANS.REFINANCING");

  //     case "4":
  //       return t<string>("LOANS.MERGING_LOANS");

  //     case "5":
  //       return t<string>("LOANS.MORTGAGE_LOANS");

  //     case "6":
  //       return t<string>("LOANS.QUICK_LOAN");

  //     case "7":
  //       return t<string>("LOANS.CREDIT_CARDS");

  //     default:
  //       return;
  //   }
  // };
  // const [addLater, setAddLater] = useState(false);
  // const { certs, certsIds } = useAppSelector((state) => state.document);
  const { bank } = application.bank_offer;
  const { bank_offer } = application;
  const { collection } = application.comments;

  // const goTo = (application: any) => {
  //   dispatch(setQuestionnaire(application));
  //   localStorage.setItem(
  //     "resultCalc",
  //     JSON.stringify({
  //       type: application.type,
  //       amount_1: +application.amount_1,
  //       term_1: +application.term_1,
  //       amount_2: +application.amount_2,
  //       term_2: application.term_2,
  //       amount_3: +application.amount_3,
  //       term_3: application.term_3,
  //       current_amount: application.current_amount,
  //       totalAmount:
  //         +application.amount_1 + +application.amount_2 + +application.amount_2,
  //     })
  //   );

  //   navigate(
  //     `/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.RESULTS_LIST}/${application.id}/${application.type}`
  //   );
  // };

  const applicationStatus = (status: string) => {
    if (
      status === "partner_response_approved" ||
      status === "accepted_by_client"
    ) {
      return t<string>("PROFILE.APPROVED");
    } else if (
      status === "partner_response_declined" ||
      status === "rejected_by_client"
    ) {
      return t<string>("PROFILE.DECLINED");
    } else if (status === "in_progress") {
      return t<string>("PROFILE.IN_PROGRESS");
    }
  };

  const createdApplicationDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  const changeStatusSubmittedApplication = async (
    status: string,
    id: number
  ) => {
    try {
      const response = await PrivateService.userUpdateSubmittedApplication(
        { status: status },
        Number(id)
      );
      toast.success(response?.data?.message);
      setChangedId(id);
    } catch (errors: any) {
      console.log(errors);
      errors?.response?.data?.payload
        ? CommonService.showErrors(errors?.response?.data?.payload)
        : toast.error(errors?.response?.data?.message);
    }
  };

  const addDocument = async (document: File) => {
    try {
      const response = await PrivateService.addProfileDocument(document);
      setDocUpdate(!docUpdate);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.errorMessages[0] || "");
    }
  };

  const addCommentSubmittedApplication = async (
    comment: string,
    id: number
  ) => {
    try {
      const response = await PrivateService.userUpdateSubmittedApplication(
        { comment: comment, comment_for: "user" },
        Number(id)
      );
      toast.success(`${t<string>("SUBMITTED_APPLICATIONS.MESSAGE_SENDED")}`);
      setChangedId(id);
      setUserComment("");
      setUpdateApplication(true);
    } catch (errors: any) {
      console.log(errors);
      errors?.response?.data?.payload
        ? CommonService.showErrors(errors?.response?.data?.payload)
        : toast.error(errors?.response?.data?.message);
    }
  };

  // function dispatch(arg0: any) {
  //   throw new Error("Function not implemented.");
  // }


  return (
    <div className="application-card">
      <div className="application-card__desktop">
        <div className="application-card__desktop-overline card-overline">
          <div className="card-overline__general">
            <div className="card-overline__general-wrap">
              <div className="card-overline__general-img">
                <img src={bank.logo_path} alt={bank.logo_name} />
              </div>
              <div className="card-overline__general-descr">
                <h3 className="card-overline__general-title">
                  {appLanguage === "en"
                    ? bank_offer.translations.en.name
                    : bank_offer.translations.bg.name}
                </h3>
                <div className="card-overline__general-info">
                  <span className="card-overline__general-detail"></span>
                  <span className="card-overline__general-code">
                    {application.code}
                  </span>
                  <span className="card-overline__general-rating">
                    <img src={StarRating} alt="star.svg" />
                    {bank.rating}
                  </span>
                  <span className="card-overline__general-review">
                    {bank.reviews_count} {t<string>("CALCULATOR.REVIEWS")}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-overline__general-status">
              <div className="card-overline__progress">
                <span
                  className={classNames({
                    "card-overline__progress-status": true,
                    "card-overline__progress-approved":
                      application.status === "partner_response_approved" ||
                      application.status === "accepted_by_client",
                    "card-overline__progress-declined":
                      application.status === "partner_response_declined" ||
                      application.status === "rejected_by_client",
                    "card-overline__progress-in":
                      application.status === "in_progress",
                  })}
                >
                  {applicationStatus(application.status)}
                </span>
              </div>
              <p className="card-overline__general-status-created">
                {createdApplicationDate(application.created_at)}
              </p>
            </div>

            {/* <div className="card-overline__general-reviews general-reviews">
              <div className="general-reviews__box">
                <div
                  onClick={() => goTo(application)}
                  className="general-reviews__name"
                >
                  {getTitle()}
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="application-card__desktop-underline card-underline card-overline">
          <div className="card-underline__numbers">
            <div className="card-underline__numbers-holder numbers-holder">
              <span className="numbers-holder__name">
                {t<string>("CALCULATOR.SUM")}
              </span>
              <p className="numbers-holder__num">
                {application.bank_offer.loan_amount_from}
                {application.bank_offer.loan_amount_from !== null && application.bank_offer.loan_amount_to ? '-' : ''}
                {application.bank_offer.loan_amount_to} {t<string>("CALCULATOR.BGN")}
              </p>
            </div>
            <div className="card-underline__numbers-holder numbers-holder">
              <span className="numbers-holder__name">
                {t<string>("CALCULATOR.TERM")}
              </span>
              <p className="numbers-holder__num">
                {application.bank_offer.credit_term_from}
                {application.bank_offer.credit_term_from !== null && application.bank_offer.credit_term_to !== null ? '-' : ''}
                {application.bank_offer.credit_term_to}
                {t<string>("CALCULATOR.MONTHS")}
              </p>
            </div>
            <div className="card-underline__numbers-holder numbers-holder">
              <span className="numbers-holder__name">
                {t<string>("CALCULATOR.RATE")}
              </span>
              <p className="numbers-holder__num">
                {application.bank_offer.interest_rate_from !== null ? t<string>("CALCULATOR.FROM") : t<string>("CALCULATOR.TO")}: {application.bank_offer.interest_rate_from || application.bank_offer.interest_rate_to }%
              </p>
            </div>
          </div>

          {/* {application.amount_1 && application.term_2 && (
            <div className="card-underline__numbers">
              <div className="card-underline__numbers-holder numbers-holder">
                <span className="numbers-holder__name">Sum 2</span>
                <p className="numbers-holder__num">
                  {currencyFormat(application.amount_2)} {t<string>("CALCULATOR.BGN")}
                </p>
              </div>
              <div className="card-underline__numbers-holder numbers-holder">
                <span className="numbers-holder__name">Term 2</span>
                <p className="numbers-holder__num">
                  {application.term_2} {t<string>("CALCULATOR.MONTHS")}
                </p>
              </div>
            </div>
          )}
          {application.amount_3 && application.term_3 && (
            <div className="card-underline__numbers">
              <div className="card-underline__numbers-holder numbers-holder">
                <span className="numbers-holder__name">Sum 3</span>
                <p className="numbers-holder__num">
                  {currencyFormat(application.amount_3)} {t<string>("CALCULATOR.BGN")}
                </p>
              </div>
              <div className="card-underline__numbers-holder numbers-holder">
                <span className="numbers-holder__name">Term 3</span>
                <p className="numbers-holder__num">
                  {application.term_3} {t<string>("CALCULATOR.MONTHS")}
                </p>
              </div>
            </div>
          )} */}
        </div>
        {collection.length !== 0 && (
          <div className="application-card__comment">
            <div className="application-card__comment-block">
              {collection.map((comment: any) => (
                <div
                  className="application-card__comment-wrap"
                  key={comment.id}
                >
                  <div
                    className={classNames(
                      "application-card__comment-head",
                      comment.comment_for === "user" && "user"
                    )}
                  >
                    <div className="application-card__comment-head-img">
                      <img
                        src={
                          comment.comment_for === "user"
                            ? currentUser?.avatar
                              ? currentUser?.avatar
                              : defaultAvatar
                            : asistentAvatar
                        }
                        alt="admin image"
                      />
                    </div>
                    <div className="application-card__comment-head-text">
                      <h4 className="application-card__comment-head-title">
                        {comment.comment_for === "user"
                          ? currentUser?.first_name
                            ? currentUser?.first_name
                            : "User"
                          : "Asistent"}
                      </h4>
                      <p>{createdApplicationDate(comment.created_at)}</p>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "application-card__comment-text",
                      comment.comment_for === "user" && "user"
                    )}
                  >
                    <p>{comment.comment}</p>
                  </div>
                </div>
              ))}
            </div>

            {application.status === "partner_response_approved" && (
              <form
                className="application-card__comment-form"
                onSubmit={(event: any) => {
                  event.preventDefault();
                  addCommentSubmittedApplication(userComment, application.id);
                }}
              >
                <div className="application-card__comment-form-img">
                  <img
                    src={
                      currentUser?.avatar ? currentUser?.avatar : defaultAvatar
                    }
                    alt="avatar"
                  />
                </div>
                <div className="application-card__comment-form-label">
                  <input
                    type="text"
                    name="user_comment"
                    value={userComment}
                    placeholder={t<string>("PROFILE.PROFILE_COMMENT")}
                    onChange={(event) =>
                      setUserComment(event.currentTarget.value)
                    }
                  />
                  <div className="application-card__comment-form-buttons">
                    <label className="application-card__comment-form-file">
                      <input
                        type="file"
                        accept=".doc, .docx, .pdf, .xlx, .csv, .zip, .png, .jpg, .jpeg, .gif, .svg"
                        className="file-block__inp"
                        onChange={(event: any) => {
                          const sameDoc = documents?.payload?.collection.some(
                            (doc: any) =>
                              doc.name === event.target.files[0].name
                          );

                          if (event.target.files && !sameDoc) {
                            addDocument(event.target.files[0]);
                          } else {
                            toast.error("File already added");
                          }
                        }}
                      />
                    </label>

                    <button
                      type="submit"
                      className="application-card__comment-form-submit"
                    ></button>
                  </div>
                </div>
              </form>
            )}
          </div>
        )}

        {application.status === "partner_response_approved" && (
          <div className="application-card__action-buttons">
            <button
              className="application-card__action-button application-card__action-button--decline"
              onClick={() =>
                changeStatusSubmittedApplication(
                  "rejected_by_client",
                  application.id
                )
              }
            >
              {t<string>("PROFILE.DECLINE_OFFER")}
            </button>
            <button
              className="application-card__action-button application-card__action-button--accept"
              onClick={() =>
                changeStatusSubmittedApplication(
                  "accepted_by_client",
                  application.id
                )
              }
            >
              <div className="application-card__button-info">
                <h5 className="application-card__button-info-title">
                  {/* {t<string>("SUBMITTED_APPLICATIONS.INFO_TITLE")} */}
                </h5>
                <p className="application-card__button-info-text">
                  {t<string>("SUBMITTED_APPLICATIONS.INFO_TEXT")}
                </p>
              </div>
              {t<string>("PROFILE.ACCEPT_OFFER")}
            </button>
          </div>
        )}
      </div>
      <div className="application-card__mobile">
        <div className="application-card__desktop-overline card-overline">
          <div className="card-overline__general">
            <div className="card-overline__general-reviews general-reviews">
              {/* <div className="general-reviews__box">
                <div
                  onClick={() => goTo(application)}
                  className="general-reviews__name"
                >
                  {getTitle()}
                </div>
                <span className="detailed-info__name">AlfaBank Bulgaria</span>
              </div> */}
            </div>
          </div>
          <div className="card-overline__progress">
            <span
              className={classNames({
                "card-overline__progress-status": true,
                "card-overline__progress-approved":
                  application.status === "approved",
                "card-overline__progress-declined":
                  application.status === "declined",
                "card-overline__progress-in": application.status === "pending",
              })}
            >
              {application.status === "approved" && "Approved"}
              {application.status === "declined" && "Declined"}
              {application.status === "pending" && "In progress"}
            </span>
          </div>
        </div>
        <div className="application-card__desktop-underline card-underline">
          <div className="card-underline__numbers">
            <div className="card-underline__numbers-holder numbers-holder">
              <span className="numbers-holder__name">Sum 1</span>
              <p className="numbers-holder__num">{application.amount_1} {t<string>("CALCULATOR.BGN")}</p>
            </div>
            <div className="card-underline__numbers-holder numbers-holder">
              <span className="numbers-holder__name">Term 1</span>
              <p className="numbers-holder__num">
                {application.term_1} {t<string>("CALCULATOR.MONTHS")}
              </p>
            </div>
          </div>
          {application.amount_2 && application.term_2 && (
            <div className="card-underline__numbers">
              <div className="card-underline__numbers-holder numbers-holder">
                <span className="numbers-holder__name">Sum 2</span>
                <p className="numbers-holder__num">{application.amount_2}</p>
              </div>
              <div className="card-underline__numbers-holder numbers-holder">
                <span className="numbers-holder__name">Term 2</span>
                <p className="numbers-holder__num">{application.term_2}</p>
              </div>
            </div>
          )}
          {application.amount_3 && application.term_3 && (
            <div className="card-underline__numbers">
              <div className="card-underline__numbers-holder numbers-holder">
                <span className="numbers-holder__name">Sum 3</span>
                <p className="numbers-holder__num">{application.amount_3}</p>
              </div>
              <div className="card-underline__numbers-holder numbers-holder">
                <span className="numbers-holder__name">Term 3</span>
                <p className="numbers-holder__num">{application.term_3}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireCard;
// function updateSubmittedApplication() {
//   throw new Error("Function not implemented.");
// }
