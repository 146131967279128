import { FC, useEffect, useState } from "react";
// import { generalQuestions } from "../../core/constants/questions";
import { Public } from "../../core/models";

import minus from "../../assets/photos/home/minus.svg";
import plus from "../../assets/photos/home/plus.svg";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../core/store";

const Questions: FC = () => {
  const [questions, setQuestions] = useState<Public.Question[]>([]);

  const { t } = useTranslation();
  const appLanguage = useAppSelector((state) => state.app.appLanguage);
  const generalQuestions = [
    {
      id: 1,
      title: {
        en: t<string>("FAQ.WHAT_ASSISTANT_IS_Q"),
        bg: t<string>("FAQ.WHAT_ASSISTANT_IS_Q"),
      },
      desc: {
        en: t<string>("FAQ.WHAT_ASSISTANT_IS_A"),
        bg: t<string>("FAQ.WHAT_ASSISTANT_IS_A"),
      },
      opened: false,
    },
    {
      id: 2,
      title: {
        en: t<string>("FAQ.APPLY_Q"),
        bg: t<string>("FAQ.APPLY_Q"),
      },
      desc: {
        en: {
          en1: t<string>("FAQ.APPLY_A.APPLY_A_1"),
          en2: t<string>("FAQ.APPLY_A.APPLY_A_2"),
          en3: t<string>("FAQ.APPLY_A.APPLY_A_3"),
        },
        bg: {
          bg1: t<string>("FAQ.APPLY_A.APPLY_A_1"),
          bg2: t<string>("FAQ.APPLY_A.APPLY_A_2"),
          bg3: t<string>("FAQ.APPLY_A.APPLY_A_3"),
        },
      },
      opened: false,
    },
    {
      id: 3,
      title: {
        en: t<string>("FAQ.HOW_LONG_Q"),
        bg: t<string>("FAQ.HOW_LONG_Q"),
      },
      desc: {
        en: {
          en1: t<string>("FAQ.HOW_LONG_A.STEP_1"),
          en2: t<string>("FAQ.HOW_LONG_A.STEP_2"),
          en3: t<string>("FAQ.HOW_LONG_A.STEP_3"),
          en4: t<string>("FAQ.HOW_LONG_A.STEP_4"),
          en5: t<string>("FAQ.HOW_LONG_A.STEP_5"),
        },
        bg: {
          bg1: t<string>("FAQ.HOW_LONG_A.STEP_1"),
          bg2: t<string>("FAQ.HOW_LONG_A.STEP_2"),
          bg3: t<string>("FAQ.HOW_LONG_A.STEP_3"),
          bg4: t<string>("FAQ.HOW_LONG_A.STEP_4"),
          bg5: t<string>("FAQ.HOW_LONG_A.STEP_5"),
        },
      },
      opened: false,
    },
    {
      id: 4,
      title: {
        en: t<string>("FAQ.FREE_OF_CHARGE_Q"),
        bg: t<string>("FAQ.FREE_OF_CHARGE_Q"),
      },
      desc: {
        en: t<string>("FAQ.FREE_OF_CHARGE_A"),
        bg: t<string>("FAQ.FREE_OF_CHARGE_A"),
      },
      opened: false,
    },
    {
      id: 5,
      title: {
        en: t<string>("FAQ.BENEFITS_Q"),
        bg: t<string>("FAQ.BENEFITS_Q"),
      },
      desc: {
        en: {
          en1: t<string>("FAQ.BENEFITS_A.BENEFITS_A_1"),
          en2: t<string>("FAQ.BENEFITS_A.BENEFITS_A_2"),
          en3: t<string>("FAQ.BENEFITS_A.BENEFITS_A_3"),
        },
        bg: {
          bg1: t<string>("FAQ.BENEFITS_A.BENEFITS_A_1"),
          bg2: t<string>("FAQ.BENEFITS_A.BENEFITS_A_2"),
          bg3: t<string>("FAQ.BENEFITS_A.BENEFITS_A_3"),
        },
      },
      opened: false,
    },
  ];

  useEffect(() => {
    setQuestions(generalQuestions);
  }, [appLanguage]);

  const opener = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index].opened = !newQuestions[index].opened;
    setQuestions(newQuestions);
  };

  const descRender = (question: Public.Question) => {
    const desc = appLanguage === 'en' ? question.desc.en : question.desc.bg;
    if (typeof desc === 'string') {
      return <p className="holder__description-text">{desc}</p>;
    } else if (typeof desc === 'object') {
      return (
        <>
          {Object.keys(desc).map((key) => (
            <p key={key} className="holder__description-text">
              {desc[key]}
            </p>
          ))}
        </>
      );
    }
    return null; 
  };

  return (
    <div className="questions">
      <div className="questions__container">
        <h1 className="questions__container-title">
          {t<string>("HOME.GENERAL_QUESTIONS_TITLE")}
        </h1>
        <div className="questions__collapse">
          {questions.map((question: Public.Question, index: number) => (
            <div
              className="questions__collapse-dropdown dropdown"
              key={question.id}
            >
              <div
                className="dropdown__holder holder"
                onClick={() => opener(index)}
              >
                <div className="holder__title">
                  <p className="holder__title-text">
                    {appLanguage === "en"
                      ? question.title.en
                      : question.title.bg}
                  </p>
                  <button className="holder__title-controller">
                    {!question.opened ? (
                      <img src={plus} alt="plus" />
                    ) : (
                      <img src={minus} alt="minus" />
                    )}
                  </button>
                </div>
                {question.opened && (
                  <div className="holder__description">
                    <div className="holder__description-line"></div>
                    <div className="holder__description-text">
                      {descRender(question)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questions;
