import React, { useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";

import { AppContext } from "../../../App";
import PrivateService from "../../../core/services/private.service";
import { APIRoutes } from "../../../core/http";
import useHttpGet from "../../../core/hooks/useHttpGet";
import fileIcon from "../../../assets/photos/all/add-file.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../core/store";
import { setModal } from "../../../core/store/reducers/modal/modalSlice";

const UpdateBank = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<any>({});
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState<any>();
  const [avatarChecker, setAvatarChecker] = useState<string>("");

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedId } = useAppSelector((state) => state.app);

  const appContext = useContext(AppContext);
  const { setReloadChecker, reloadChecker } = appContext;

  useHttpGet<any>(`${APIRoutes.ADMIN_ENTITIES_BANK}/${selectedId}`, {
    dependencies: [reloadChecker, selectedId],

    resolve: (response) => {
      setCategory(response.payload);
    },
  });
  // const setSplitString = (formikName:string ,arrayStrings: any) => {
  //   arrayStrings.split(',')
  //   formikForm.setFieldValue(formikName, )
  // }

  useEffect(() => {
    formikForm.resetForm();

    if (category) {
      for (let prop in category) {
        category[prop]
          ? formikForm.setFieldValue(prop, category[prop])
          : formikForm.setFieldValue(prop, "");
      }

      setAvatarChecker(category["logo_path"]);

      if (category["translations"] !== undefined) {
        category["translations"].bg.description
          ? formikForm.setFieldValue(
              "description:bg",
              category["translations"].bg.description
            )
          : formikForm.setFieldValue("description:bg", "");

        category["translations"].en.description
          ? formikForm.setFieldValue(
              "description:en",
              category["translations"].en.description
            )
          : formikForm.setFieldValue("description:en", "");

        category["translations"].bg.name
          ? formikForm.setFieldValue(
              "name:bg",
              category["translations"].bg.name
            )
          : formikForm.setFieldValue("name:bg", "");

        category["translations"].en.name
          ? formikForm.setFieldValue(
              "name:en",
              category["translations"].en.name
            )
          : formikForm.setFieldValue("name:en", "");

        category["translations"].bg.address
          ? formikForm.setFieldValue(
              "address:bg",
              category["translations"].bg.address
            )
          : formikForm.setFieldValue("address:bg", "");
        category["translations"].en.address
          ? formikForm.setFieldValue(
              "address:en",
              category["translations"].en.address
            )
          : formikForm.setFieldValue("address:en", "");
      }
    }
  }, [category, reloadChecker]);

  const valueFormValidationSchema = Yup.object().shape({
    status: Yup.string(),

    "description:en": Yup.string().max(1000, "Too many characters"),

    "name:en": Yup.string().max(120, "Too many characters"),

    "description:bg": Yup.string().max(1000, "Too many characters"),

    "name:bg": Yup.string().max(120, "Too many characters"),
    "address:en": Yup.string().required(),
    "address:bg": Yup.string().required(),
    phone: Yup.string().required(),
    link: Yup.string().required(),
  });

  const formikForm = useFormik<{
    id: number;
    logo: any;
    logo_name: string;
    logo_path: string;
    show_on_public: string | number;
    status: string;
    "description:en": string;
    "name:en": string;
    "description:bg": string;
    "name:bg": string;
    reviews_count: number;
    rating: number;
    "address:en": string;
    "address:bg": string;
    link: string;
    phone: string;
  }>({
    initialValues: {
      id: 0,
      logo: "",
      logo_name: "",
      logo_path: "",
      show_on_public: 0,
      status: "",
      "description:en": "",
      "name:en": "",
      "description:bg": "",
      "name:bg": "",
      reviews_count: 0,
      rating: 0,
      "address:en": "",
      "address:bg": "",
      link: "",
      phone: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });
  // const addToArray = (array: string[], string: string) => {
  //   return array.push(string);
  // };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);
    let logo;

    if (values.logo_path !== avatarChecker && values.logo_path !== "") {
      logo = await urltoFile(
        values.logo_path,
        `photo${Math.random() * 10000}.png`,
        "image/png"
      );
    } else {
      logo = "";
    }

    // addToArray(addresses, formikForm.values.address);
    // addToArray(links, formikForm.values.link);
    // addToArray(phones, formikForm.values.phone);

    try {
      const response = await PrivateService.updateAdminBank(
        {
          ...values,
          logo,
        },
        category.id
      );

      setReloadChecker(!reloadChecker);
      setLoading(false);
      toast.success(response?.data?.message);
      dispatch(setModal(false));
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
      setLoading(false);
      dispatch(setModal(false));
    }
  };

  const pictureLoader = (e: any) => {
    setCropper(undefined);

    e.preventDefault();

    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };

    reader.readAsDataURL(files[0]);
  };

  const urltoFile = (url: string, filename: string, mimeType: string) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      formikForm.setFieldValue(
        "logo_path",
        cropper.getCroppedCanvas().toDataURL()
      );

      setImage(undefined);
    }
  };

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("FINANCIAL_INSTITUTIONS.UPDATE_TITLE")}
        </p>
        <form className="about" onSubmit={formikForm.handleSubmit}>
          <div className="about__form-avatar">
            <div className="about__form-box about-box">
              {image ? (
                <>
                  {image && (
                    <div className="about-box__cropper">
                      <Cropper
                        className="about-box__cropper-item"
                        style={{
                          height: 250,
                          width: 250,
                        }}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={200}
                        minCropBoxWidth={200}
                        cropBoxResizable={false}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />
                      <button
                        className="about-box__cropper-btn"
                        onClick={getCropData}
                      >
                        {t<string>("FINANCIAL_INSTITUTIONS.CROP")}
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {formikForm.values.logo || formikForm.values.logo_name ? (
                    <div className="about-box__avatar">
                      <div className="about-box__avatar-bank">
                        <img
                          className="about-box__avatar-bank-own"
                          src={
                            formikForm.values.logo ||
                            formikForm.values.logo_path
                          }
                          alt="avatar"
                        />
                      </div>
                      <label className="about-box__file">
                        <div className="about-box__file-block file-block">
                          <img
                            className="file-block__icon"
                            src={fileIcon}
                            alt="file"
                          />
                          <span className="file-block__txt">
                            {t<string>("FINANCIAL_INSTITUTIONS.CHANGE_LOGO")}
                          </span>
                        </div>
                        <input
                          className="file-block__inp"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files) {
                              pictureLoader(e);
                            }
                          }}
                          accept="image/jpg, image/jpeg, image/png, image/gif"
                        />
                      </label>
                    </div>
                  ) : (
                    <label className="about-box__file">
                      <div className="about-box__file-block file-block">
                        <img
                          className="file-block__icon"
                          src={fileIcon}
                          alt="file"
                        />
                        <span className="file-block__txt">
                          {t<string>("FINANCIAL_INSTITUTIONS.UPDATE_LOGO")}
                        </span>
                      </div>
                      <input
                        type="file"
                        accept=" .png, .jpg, .jpeg, .gif"
                        className="file-block__inp"
                        onChange={(e) => {
                          if (e.target.files) {
                            pictureLoader(e);
                          }
                        }}
                      />
                    </label>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["name:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["name:en"] &&
                      formikForm.errors["name:en"],
                  })}
                >
                  {t<string>("FINANCIAL_INSTITUTIONS.NAME_EN")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["name:en"] &&
                      formikForm.errors["name:en"],
                    "about-box__field-inp-filled": formikForm.values["name:en"],
                  })}
                  placeholder="Name en"
                  {...formikForm.getFieldProps(`${["name:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["name:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["name:bg"] &&
                      formikForm.errors["name:bg"],
                  })}
                >
                  {t<string>("FINANCIAL_INSTITUTIONS.NAME_BG")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["name:bg"] &&
                      formikForm.errors["name:bg"],
                    "about-box__field-inp-filled": formikForm.values["name:bg"],
                  })}
                  placeholder="Name bg"
                  {...formikForm.getFieldProps(`${["name:bg"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["description:en"],
                    "about-box__field-inp-error":
                      formikForm.touched["description:en"] &&
                      formikForm.errors["description:en"],
                  })}
                >
                  {t<string>("FINANCIAL_INSTITUTIONS.DESCRIPTION_EN")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["description:en"] &&
                      formikForm.errors["description:en"],
                    "about-box__field-inp-filled":
                      formikForm.values["description:en"],
                  })}
                  placeholder="Description En"
                  {...formikForm.getFieldProps(`${["description:en"]}`)}
                />
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values["description:bg"],
                    "about-box__field-inp-error":
                      formikForm.touched["description:bg"] &&
                      formikForm.errors["description:bg"],
                  })}
                >
                  {t<string>("FINANCIAL_INSTITUTIONS.DESCRIPTION_BG")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched["description:bg"] &&
                      formikForm.errors["description:bg"],
                    "about-box__field-inp-filled":
                      formikForm.values["description:bg"],
                  })}
                  placeholder="Description Bg"
                  {...formikForm.getFieldProps(`${["description:bg"]}`)}
                />
              </label>
            </div>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values["address:en"],
                  "about-box__field-inp-error":
                    formikForm.touched["address:en"] &&
                    formikForm.errors["address:en"],
                })}
              >
                {t<string>("FINANCIAL_INSTITUTIONS.ADDRESS_EN")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched["address:en"] &&
                    formikForm.errors["address:en"],
                  "about-box__field-inp-filled":
                    formikForm.values["address:en"],
                })}
                placeholder="Bank Address EN"
                {...formikForm.getFieldProps(`${["address:en"]}`)}
              />
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values["address:bg"],
                  "about-box__field-inp-error":
                    formikForm.touched["address:bg"] &&
                    formikForm.errors["address:bg"],
                })}
              >
                {t<string>("FINANCIAL_INSTITUTIONS.ADDRESS_BG")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched["address:bg"] &&
                    formikForm.errors["address:bg"],
                  "about-box__field-inp-filled":
                    formikForm.values["address:bg"],
                })}
                placeholder="Bank Address BG"
                {...formikForm.getFieldProps(`${["address:bg"]}`)}
              />
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.link,
                  "about-box__field-inp-error":
                    formikForm.touched.link && formikForm.errors.link,
                })}
              >
                {t<string>("FINANCIAL_INSTITUTIONS.LINK")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.link && formikForm.errors.link,
                  "about-box__field-inp-filled": formikForm.values.link,
                })}
                placeholder="Banks link"
                {...formikForm.getFieldProps(`${["link"]}`)}
              />
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.phone,
                  "about-box__field-inp-error":
                    formikForm.touched.phone && formikForm.errors.phone,
                })}
              >
                {t<string>("FINANCIAL_INSTITUTIONS.PHONE")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.phone && formikForm.errors.phone,
                  "about-box__field-inp-filled": formikForm.values.phone,
                })}
                placeholder="Name en"
                {...formikForm.getFieldProps(`${["phone"]}`)}
              />
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.status && formikForm.errors.status,
                })}
              >
                {t<string>("FINANCIAL_INSTITUTIONS.CHOOSE_STATUS")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.status,
                  "about-box__field-inp-touched":
                    formikForm.touched.status && formikForm.errors.status,
                })}
                value={formikForm.values.status}
                onChange={(e) =>
                  formikForm.setFieldValue("status", e.target.value)
                }
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="enabled">
                  {t<string>("FINANCIAL_INSTITUTIONS.STATUS_ENABLED")}
                </option>
                <option value="disabled">
                  {t<string>("FINANCIAL_INSTITUTIONS.STATUS_DISABLED")}
                </option>
              </select>
            </label>
          </div>
          <button
            className={classNames({
              about__submit: true,
              "about__submit-active": formikForm.isValid && formikForm.dirty,
            })}
            type="submit"
          >
            {t<string>("FINANCIAL_INSTITUTIONS.SAVE_BUTTON")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateBank;
