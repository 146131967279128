import { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import { AppContext } from "../../App";

import useAuth from "../../core/hooks/useAuth";
// import { Private } from "../../core/models";
import { PrivateUIRoutes, UIRoutes } from "../../core/router";

import bankLogo from "../../assets/photos/home/bank-logo.svg";
import star from "../../assets/photos/home/star.svg";
import { useAppDispatch, useAppSelector } from "../../core/store";
import {
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../core/services/enums";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import CommonService from "../../core/services/common.service";
import PrivateService from "../../core/services/private.service";
import { toast } from "react-toastify";

interface IProps {
  offer: any;
  questionnaire: number;
  sortedApplication: any;
  type: string;
  passport?: string | null;
}

const Cards: FC<IProps> = ({
  offer,
  questionnaire,
  sortedApplication,
  type,
  passport
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuth } = useAuth();

  const appContext = useContext(AppContext);
  const { appLanguage } = useAppSelector((state) => state.app);
  const { setSelectionType } = appContext;

  const offerData = (
    from: string | number,
    to: string | number,
    units: string
  ) => {
    if (from === to) {
      return (
        <p>
          <span className="term__items-bolder--light">{`${t<string>(
            "COMMON.FROM"
          )}:`}</span>
          <span className="term__items-bolder--light"> {from} </span>
          {t<string>(`CALCULATOR.${units}`)}
        </p>
      );
    } else if (from && to) {
      return (
        <p>
          <span className="term__items-bolder--light">
            {`${t<string>("COMMON.FROM")}`}:
          </span>
          <span className="term__items-bolder--light"> {from}</span>
          <span className="term__items-bolder--light">
            {` - ${t<string>("COMMON.UP_TO")}`} :
          </span>
          <span className="term__items-bolder--light"> {to}</span>
          {units === "MONTHS" && <span> {t<string>("CALCULATOR.MONTHS")}</span>}
        </p>
      );
    } else if (
      (from && to === "") ||
      (from && to === 0) ||
      (from && to === null)
    ) {
      return (
        <p>
          <span className="term__items-bolder--light">{`${t<string>(
            "COMMON.FROM"
          )}: `}</span>
          <span className="term__items-bolder--light"> {from} </span>
          {t<string>(`CALCULATOR.${units}`)}
        </p>
      );
    } else {
      return (
        <p>
          <span className="term__items-bolder--light">{`${t<string>(
            "COMMON.UP_TO"
          )}: `}</span>
          <span className="term__items-bolder--light">{to} </span>
          {t<string>(`CALCULATOR.${units}`)}
        </p>
      );
    }
  };

  const applyOffer = async (id: number) => {
    try {
      const response: any = await PrivateService.applyApplication(id, offer.id);
      sortedApplication(offer.id);
      toast.success(response?.data?.message);

      ReactGA.event({
        category: "offer_apply",
        action:
          process.env.REACT_APP_ANALYTICS_NAME === ""
            ? "offer_apply"
            : `offer_apply_${process.env.REACT_APP_ANALYTICS_NAME}`,
      });
    } catch (errors: any) {
      // toast.error(errors?.response?.data?.message);
      CommonService.showErrors(errors?.response?.data?.payload);
    }
  };

  const setApplyOffer = () => {
    if (isAuth) {
      if (questionnaire !== 0 && passport !== '' && passport !== null) {
        applyOffer(questionnaire);
      } else {
        navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
        // throwAlertWindow(null);
        // dispatch(setModalType(AuthSelectionType.Auth));
        localStorage.setItem("prevPage", `/${UIRoutes.RESULTS_LIST}`);
        sessionStorage.setItem("bank_offer_id", `${offer.id}`);
      }
    } else {
      dispatch(setModalType(AuthSelectionType.Auth));
      setSelectionType(AuthSelectionType.Register);
      dispatch(setModal(true));
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
      localStorage.setItem("prevPage", `/${UIRoutes.RESULTS_LIST}`);
      sessionStorage.setItem("bank_offer_id", `${offer.id}`);
    }
  };

  return (
    <div
      className={classNames(
        "offer-card",
        type === "card" || "credit" ? "card" : ""
      )}
    >
      {offer.translations.en.promo && (
        <div className="offer-card__promo">
          {appLanguage === "en"
            ? offer.translations.en.promo
            : offer.translations.bg.promo}
        </div>
      )}
      <div className="offer-card__block">
        <div
          className={classNames(
            "offer-card__block-desc desc",
            type === "card" || "credit" ? "card" : ""
          )}
        >
          <div
            className={classNames(
              "desc__box",
              type === "card" || "credit" ? "card" : ""
            )}
          >
            <div className="offer-card__img-wrap">
              {type === "card" && (
                <img
                  src={require(`../../assets/photos/Credit card/${offer.img}`)}
                  alt={offer.title_EN}
                />
              )}
              {type === "bank" && (
                <img
                  className="desc__logo"
                  src={offer?.bank?.logo_path || bankLogo}
                  alt="logo"
                />
              )}
              {type === "credit" && <img src={offer.card_path} alt="logo" />}
            </div>
            <div
              className={classNames(
                "desc__box-wrapper",
                type === "card" || "credit" ? "card" : ""
              )}
            >
              <h3
                className={classNames(
                  "desc__box-title",
                  type === "card" || "credit" ? "card" : ""
                )}
              >
                {type === "card" && appLanguage === "bg"
                  ? offer?.title_BG
                  : offer?.title_EN}
                {type === ("bank" || "credit") && appLanguage === "bg"
                  ? offer?.translations?.bg?.name
                  : offer?.translations?.en?.name}
              </h3>
              <div className="desc__box-rating">
                {type === "bank" ? (
                  <>
                    <span className="desc__box-sub">
                      {t<string>("CREDIT_OFFERS.BANK_RATE")}
                    </span>
                    <img src={star} alt="star" />
                    <span>{offer?.bank?.rating}</span>
                  </>
                ) : (
                  <span></span> // <span>{offer?.rating}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offer-card__block">
        <div
          className={classNames(
            "offer-card__block-term term",
            type === "card" || "credit" ? "card" : ""
          )}
        >
          <div className="term__items">
            <p className="term__items-simple">
              {type === "credit"
                ? t<string>("CALCULATOR.CREDIT_LIMIT")
                : t<string>("CREDIT_OFFERS.TERM")}
            </p>
            <div className="term__items-bolder">
              {type === "bank" ? (
                <div>
                  {offerData(
                    offer.credit_term_from,
                    offer.credit_term_to,
                    "MONTHS"
                  )}
                </div>
              ) : type === "card" ? (
                <div>
                  {offerData(
                    offer.credit_limit_from,
                    offer.credit_limit_to,
                    "MONTHS"
                  )}
                </div>
              ) : (
                <div>
                  {offerData(
                    offer.loan_amount_from,
                    offer.loan_amount_to,
                    "BGN"
                  )}
                </div>
              )}
            </div>
            {type === "credit" && (
              <p className="pricing__small">{`${t<string>(
                "CALCULATOR.INTEREST_RATE"
              )}: ${offer.interest_rate_to || offer.interest_rate_to}%`}</p>
            )}
          </div>
        </div>
        {type === "credit" ? (
          <div className="offer-card__block-pricing pricing">
            <p className="pricing__small">
              {t<string>("CALCULATOR.ANNUAL_FEE")}
            </p>
            <div className="pricing__sum">
              <span>{offer.annual_fee ? offer.annual_fee : 0}</span>
              <span className="term__items-bolder--light">{` ${t<string>(
                "CALCULATOR.BGN"
              )}`}</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="offer-card__block-pricing pricing bottom">
          <p className="pricing__small">
            {type === "credit"
              ? t<string>("CALCULATOR.GRACE_PERIOD")
              : t<string>("CREDIT_OFFERS.LOAN_AMOUNT")}
          </p>
          {type === "bank" ? (
            <div className="term__items-bolder">
              {offerData(offer.loan_amount_from, offer.loan_amount_to, "BGN")}
            </div>
          ) : (
            <p className="term__items-bolder">
              {t<string>("COMMON.UP_TO")}
              {`: ${offer.grace_period} ${t<string>("COMMON.DAYS")}`}
            </p>
          )}
          <div className="pricing__small rating">
            {type === "bank" ? (
              <>
                {t<string>("CREDIT_OFFERS.RATE")}
                {offerData(
                  offer.interest_rate_from,
                  offer.interest_rate_to,
                  "%"
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          <button
            className="pricing__btn"
            onClick={() => {
              ReactGA.event({
                category: `best_deals`,
                action:
                  process.env.REACT_APP_ANALYTICS_NAME === ""
                    ? "best_deals"
                    : `best_deals_${process.env.REACT_APP_ANALYTICS_NAME}`,
              });
              setApplyOffer();
            }}
          >
            {t<string>("HOME.BEST_DEALS_BUTTON")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cards;
