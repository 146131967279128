import { useContext, useState } from "react";

import { AppContext } from "../../../App";
import useHttpGet from "../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../core/http";
import { useAppSelector } from "../../../core/store";

const ShowBankName = () => {
  const [content, setContent] = useState<any>({});

  const { selectedId } = useAppSelector((state) => state.app);

  useHttpGet<any>(`${APIRoutes.ADMIN_ENTITIES_BANK}/${selectedId}`, {
    dependencies: [selectedId],

    resolve: (response) => {
      if (response.payload) {
        setContent(response?.payload);
      }
    },
  });

  return (
    <div className="modal__container  modal-addUser">
      <div className="modal-logout__box">
        <h2 className="modal-logout__box-title">Name</h2>
        <p className="modal-logout__box-title">Name BG:</p>
        <span className="users-table-row__item">
          {content.translations !== undefined && content.translations.bg.name}
        </span>
        <p
          style={{
            marginTop: "30px",
          }}
          className="modal-logout__box-title"
        >
          Name EN:
        </p>
        <span className="users-table-row__item">
          {content.translations !== undefined &&
            content.translations.en.description}
        </span>
      </div>
    </div>
  );
};

export default ShowBankName;
