import React, { useContext } from "react";

import Questions from "../Questions/index";

import mission_banner from "../../assets/photos/all/mission-banner.svg";
import { advantages_list } from "../../core/constants/advantages-list";
import {
  partners_main,
  partners_main_mobile,
  // partners_secondary,
  // partners_secondary_mobile,
} from "../../core/constants/partners";

import { products } from "../../core/constants/products";
import { strategies } from "../../core/constants/strategies";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { UIRoutes } from "../../core/router";
import { useTranslation } from "react-i18next";
import Banner from "../Banner/Banner";

const AboutUsContainer = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const appContext = useContext(AppContext);

  const { setSelectedLoan } = appContext;

  return (
    <>
      <div className="about-us-container">
        <div className="about-us-container__main-content-about main-content-about">
          <h1 className="main-content-about__title">{t<string>('ABOUTUS.TITLE')}</h1>
          <span className="main-content-about__subtitle">
            {t<string>("ABOUTUS.SUB")}
          </span>
          <p className="main-content-about__info">
            {t<string>("ABOUTUS.DESC")}
          </p>
        </div>
        <div className="about-us-container__mission-block mission-block">
          <div className="about-us-container__mission-block-info">
            <h1 className="about-us-container__mission-block-info-title">
              {t<string>("ABOUTUS.MISSION")}
            </h1>
            <p className="mission-block-info-content">
              {t<string>("ABOUTUS.CREATE")}
            </p>
          </div>
          <img
            className="mission-block-info-banner"
            src={mission_banner}
            alt="mission_banner"
          />
        </div>
        <div className="about-us-container__advantages-block advantages-block">
          <div className="advantages-block-info">
            <h4 className="advantages-block-info-title">
              {t<string>("ABOUTUS.ADVANTAGES")}
            </h4>
            <p className="advantages-block-info-content">
              {t<string>("ABOUTUS.BEST_DEAL")}
            </p>
          </div>
          <div className="advantages-block__advantages-list advantages-list">
            {advantages_list.map((item) => {
              const { id, title, content, photo } = item;

              return (
                <div key={id} className="advantages-list__card">
                  <img
                    className="advantages-list__card-photo"
                    src={photo}
                    alt={title}
                  />
                  <h5 className="advantages-list__card-title">
                    {t<string>(`ABOUTUS.${title}`)}
                  </h5>
                  <p className="advantages-list__card-content">
                    {t<string>(`ABOUTUS.${content}`)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="about-us-container__partners-section partners-section">
          <h1 className="partners-section-title">
            {t<string>("ABOUTUS.WORK_WITH")}
          </h1>
          <div className="partners-section__partner-logos partner-logos">
            <div className="partner-logos-row">
              {partners_main.map((item) => {
                const { photo, id } = item;

                return <img key={id} src={photo} alt={photo} />;
              })}
            </div>
          </div>
          <div className="partners-section__partner-logos-mobile partner-logos-mobile">
            <div className="partner-logos-mobile-row">
              {partners_main_mobile.map((item) => {
                const { photo, id } = item;

                return (
                  <img
                    className="partner-logos-mobile-row__item"
                    key={id}
                    src={photo}
                    alt={photo}
                  />
                );
              })}
            </div>
            <div className="partner-logos-mobile-row">
              {partners_main_mobile.map((item) => {
                const { photo, id } = item;

                return (
                  <img
                    className="partner-logos-mobile-row__item"
                    key={id}
                    src={photo}
                    alt={photo}
                  />
                );
              })}
            </div>
          </div>
          {/* <button className="partners-section-more-btn">
            {t<string>("ABOUTUS.SHOW_MORE")}
          </button> */}
        </div>
  
        <div className="about-us-container__products-section products-section">
          <div className="products-section-top">
            <h1 className="products-section-top-title">
              {t<string>(`ABOUTUS.FINANCIAL`).split(" ").slice(0, 2).join(" ")}
              <br />
              {t<string>(`ABOUTUS.FINANCIAL`).split(" ").slice(2).join(" ")}
            </h1>
            <p className="products-section-top-subtitle">
              {t<string>("ABOUTUS.FINANCIAL_DESC")}
            </p>
          </div>
          <div className="products-section__bottom-list bottom-list">
            {products.map((product, index) => {
              const { id, title, info, btn_content, photo } = product;

              return (
                <div className="bottom-list__item" key={id}>
                  <img
                    className="bottom-list__item-icon"
                    src={photo}
                    alt={title}
                  />
                  <div className="bottom-list__item-content">
                    <h5 className="bottom-list__item-content-title">
                      {t<string>(`LOANS.${title}`)}
                    </h5>
                    <p className="bottom-list__item-content-subtitle">
                      {t<string>(`ABOUTUS.${info}`)}
                    </p>
                    <button
                      className="bottom-list__item-content-btn"
                      onClick={() => {
                        navigate(`/${UIRoutes.CALCULATOR}`);
                        setSelectedLoan(index + 1);
                      }}
                    >
                      {t<string>(`ABOUTUS.${btn_content}`)}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Questions />
      <Banner />
    </>
  );
};

export default AboutUsContainer;
