import { FC, useState } from "react";
// import Articles from "../../../components/Articles";
import FeedbackAnnounce from "../../../components/PreAuthModalWindow/FeedbackAnnounce";
import BanksLogosList from "../../../components/BanksLogosList";
import Banner from "../../../components/Banner";
import Banners from "../../../components/Banners";
import Guide from "../../../components/Guide";
import Offers from "../../../components/Offers";
import Partners from "../../../components/Partners";
import Questions from "../../../components/Questions";
import WhyUs from "../../../components/WhyUs";
import CalculatorPage from "../Calculator/CalculatorPage";

const HomePage: FC = () => {
  const isAuthorized = sessionStorage.getItem("isAuthorized") === "true";

  return (
    <div>
      {!isAuthorized && (
        <div>
          {window.location.pathname === "/home" && (
            <>
              <div className="modal__auth-bg"></div>
              <div className="modal__auth-container modal-slide">
                <FeedbackAnnounce />
              </div>
            </>
          )}
        </div>
      )}

      <BanksLogosList />
      <CalculatorPage />
      <Banners></Banners>
      <Guide />
      <Offers/>
      <div className="home-whyus">
        <WhyUs />
      </div>
      <Partners />
      {/* <Articles /> */}
      <Questions />
      <Banner />
    </div>
  );
};

export default HomePage;
