import React from "react";

import CreditsMergingBanner from "./CreditsMergingBanner";
import CreditsMergingInfo from "./CreditsMergingInfo";

const CreditsMerging = () => {
  return (
    <div id="Merging loans" className="credits-merging">
      <CreditsMergingBanner />
      <div className="credits-container-main-content-consumer">
        <CreditsMergingInfo />
      </div>
    </div>
  );
};

export default CreditsMerging;
