import { useEffect, useTransition } from "react";
import { useFormik } from "formik";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

import addIcon from "../../../assets/photos/all/add.svg";
import { currencyFormat } from "../../../core/functions/funtions";
import { style } from "../../../core/constants/style";
import { useAppDispatch } from "../../../core/store";
import { setCalculator } from "../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const MergingLoansForm = () => {
  const dispatch = useAppDispatch();
  const resultCalc = JSON.parse(localStorage.getItem("calcResult") || "{}");
  const { t } = useTranslation();

  useEffect(() => {
    if (resultCalc) {
      addCredit();
    }
  }, []);

  const addDefaultCredit = () => {
    if (formikForm.values.ranges.length < 3) {
      formikForm.setFieldValue(
        "ranges",
        [...formikForm.values.ranges].concat([
          [
            {
              title: `${t<string>("CALCULATOR.LOAN_AMOUNT")}`,
              amount: 10000,
              min: 500,
              max: 50000,
              step: 100,
              prefix: `500 ${t<string>("PROFILE.BGN")}`,
              sufix: `50 000 ${t<string>("PROFILE.BGN")}`,
              type: "7",
            },
            {
              title: `${t<string>("CALCULATOR.LOAN_TERM")}`,
              term: 36,
              min: 3,
              max: 120,
              step: 1,
              prefix: `3 ${t<string>("CALCULATOR.MONTHS")}`,
              sufix: `120 ${t<string>("CALCULATOR.MONTHS")}`,
              type: "7",
            },
          ],
        ])
      );
    }
  };

  const addCredit = () => {
    const resultCalc = JSON.parse(localStorage.getItem("resultCalc") || "{}");

    const newCredit = (amount: number, term: number) => {
      return [
        {
          title: `${t<string>("CALCULATOR.LOAN_AMOUNT")}`,
          amount: amount,
          min: 500,
          max: 50000,
          step: 100,
          prefix: `500 ${t<string>("PROFILE.BGN")}`,
          sufix: `50 000 ${t<string>("PROFILE.BGN")}`,
          type: "7",
        },
        {
          title: `${t<string>("CALCULATOR.LOAN_TERM")}`,
          term: term,
          min: 3,
          max: 120,
          step: 1,
          prefix: `3 ${t<string>("CALCULATOR.MONTHS")}`,
          sufix: `120 ${t<string>("CALCULATOR.MONTHS")}`,
          type: "7",
        },
      ];
    };

    if (resultCalc.amount_1 && resultCalc.term_1) {
      formikForm.setFieldValue("ranges", [
        newCredit(resultCalc.amount_1, resultCalc.term_1),
      ]);
    }

    if (resultCalc.amount_2 && resultCalc.term_2) {
      formikForm.setFieldValue("ranges", [
        newCredit(resultCalc.amount_1, resultCalc.term_1),
        newCredit(resultCalc.amount_2, resultCalc.term_2),
      ]);
    }

    if (resultCalc.amount_3 && resultCalc.term_3) {
      formikForm.setFieldValue("ranges", [
        newCredit(resultCalc.amount_1, resultCalc.term_1),
        newCredit(resultCalc.amount_2, resultCalc.term_2),
        newCredit(resultCalc.amount_3, resultCalc.term_3),
      ]);
    }
  };

  const deleteCredit = (index: number) => {
    const newCredits = formikForm.values.ranges.filter(
      (range) => formikForm.values.ranges.indexOf(range) !== index
    );

    formikForm.setFieldValue("ranges", newCredits);
  };

  const formikForm = useFormik<{
    ranges: [
      {
        title: string;
        amount: number;
        min: number;
        max: number;
        step: number;
        prefix: string;
        sufix: string;
        type: string;
      },
      {
        title: string;
        term: number;
        min: number;
        max: number;
        step: number;
        prefix: string;
        sufix: string;
        type: string;
      }
    ][];
  }>({
    initialValues: {
      ranges: [
        [
          {
            title: `${t<string>("CALCULATOR.LOAN_AMOUNT")}`,
            amount: 10000,
            min: 500,
            max: 50000,
            step: 100,
            prefix: `500 ${t<string>("PROFILE.BGN")}`,
            sufix: `50 000 ${t<string>("PROFILE.BGN")}`,
            type: "7",
          },
          {
            title: `${t<string>("CALCULATOR.LOAN_TERM")}`,
            term: 36,
            min: 3,
            max: 120,
            step: 1,
            prefix: `3 ${t<string>("CALCULATOR.MONTHS")}`,
            sufix: `120 ${t<string>("CALCULATOR.MONTHS")}`,
            type: "7",
          },
        ],
      ],
    },

    onSubmit: async (values) => {
      handleSubmitForm();
    },
  });

  const handleSubmitForm = () => {
    let totalAmount = 0;
    let maxTerm = 0;

    formikForm.values.ranges.forEach((range: any) => {
      totalAmount += +range[0].amount;
      if (range[1].term > maxTerm) {
        maxTerm = +range[1].term;
      }
    });

    dispatch(
      setCalculator({
        type: "7",
        amount_1: totalAmount,
        term_1: formikForm.values.ranges[0][1].term,
      })
    );

    localStorage.setItem(
      "calcResult",
      JSON.stringify({
        type: "7",
        amount_1: formikForm.values.ranges[0][0].amount,
        term_1: formikForm.values.ranges[0][1].term,
        amount_2: formikForm.values.ranges[1]
          ? +formikForm.values.ranges[1][0]?.amount
          : "",
        term_2: formikForm.values.ranges[1]
          ? +formikForm.values.ranges[1][1]?.term
          : "",
        amount_3: formikForm.values.ranges[2]
          ? +formikForm.values.ranges[2][0]?.amount
          : "",
        term_3: formikForm.values.ranges[2]
          ? +formikForm.values.ranges[2][1]?.term
          : "",
        totalAmount: totalAmount,
        maxTerm: maxTerm,
      })
    );
  };

  const addAmount = (index: number) => {
    if (formikForm.values.ranges[index][0].amount < 50000) {
      formikForm.setFieldValue(
        `ranges.${index}[0].amount`,
        formikForm.values.ranges[index][0].amount + 100
      );
    }
    if (50000 - formikForm.values.ranges[index][0].amount < 200) {
      formikForm.setFieldValue(`ranges.${index}[0].amount`, 50000);
    }
  };

  const removeAmount = (index: number) => {
    if (formikForm.values.ranges[index][0].amount > 500) {
      formikForm.setFieldValue(
        `ranges.${index}[0].amount`,
        formikForm.values.ranges[index][0].amount - 100
      );
    }
    if (formikForm.values.ranges[index][0].amount - 200 < 500) {
      formikForm.setFieldValue(`ranges.${index}[0].amount`, 500);
    }
  };

  const addTerm = (index: number) => {
    if (formikForm.values.ranges[index][1].term < 120) {
      formikForm.setFieldValue(
        `ranges.${index}[1].term`,
        formikForm.values.ranges[index][1].term + 1
      );
    }
  };

  const removeTerm = (index: number) => {
    if (formikForm.values.ranges[index][1].term > 3) {
      formikForm.setFieldValue(
        `ranges.${index}[1].term`,
        formikForm.values.ranges[index][1].term - 1
      );
    }
  };

  const coercion = (e: any, maxValue: number, index: number) => {
    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue(`ranges.${index}[0].amount`, +maxValue);
    } else {
      formikForm.setFieldValue(`ranges.${index}[0].amount`, +valueNum);
    }
  };

  return (
    <div className="calc">
    <form
      className="calc__form"
      onSubmit={formikForm.handleSubmit}>
      <div className="search-block-merging-main">
        {formikForm.values.ranges.map((range, index) => {
          return (
            <div key={index} className="search-block-inputs-merging">
              <div
                className="search-block-inputs-merging__input-item  input-item"
                onBlur={() => {
                  formikForm.values.ranges.map(
                    (range, i) =>
                      range[0].amount < 500 &&
                      formikForm.setFieldValue(`ranges[${i}][0].amount`, 500)
                  );
                }}>
                {index !== 0 && (
                  <div className="calc-container__add calc-container__remove">
                    <button
                      className="calc-container__add-btn"
                      onClick={() => deleteCredit(index)}
                      type="button">
                      {t<string>("CALCULATOR.MERGING_REMOVE")}
                    </button>
                  </div>
                )}
                <p className="input-item__count">
                  {index + 1}. {t<string>("CALCULATOR.LOAN")}
                </p>
                <div className="input-item-main">
                  <label className="input-item-main__label">{`${range[0]?.title}`}</label>
                  <label className="ranges__controls-field">
                    <input
                      type="text"
                      min={500}
                      max={50000}
                      className={classNames({
                        "ranges__controls-field-put": true,
                        "ranges__controls-field-put__l": true,
                      })}
                      placeholder="50000"
                      value={currencyFormat(range[0]?.amount)}
                      onChange={(event) => {
                        coercion(event, 50000, index);
                      }}
                    />
                    {t<string>("PROFILE.BGN")}
                  </label>
                  <div className="range-wrapper">
                    <button
                      onClick={() => removeAmount(index)}
                      type="button"
                      className="range-wrapper__step-add">
                      <AiOutlineMinusCircle style={style} size={32} />
                    </button>
                    <input
                      type="range"
                      min={range[0]?.min}
                      max={range[0]?.max}
                      step={range[0]?.step}
                      className="range__controls-input range-wrapper__input"
                      {...formikForm.getFieldProps(`ranges.${index}[0].amount`)}
                    />
                    <button
                      onClick={() => addAmount(index)}
                      type="button"
                      className="range-wrapper__step-minus">
                      <AiOutlinePlusCircle style={style} size={32} />
                    </button>
                  </div>
                </div>
                <div className="input-item__details">
                  <p>{`${range[0]?.prefix}`}</p>
                  <p>{`${range[0]?.sufix}`}</p>
                </div>
              </div>
              <div
                className="search-block-inputs__input-item input-item"
                onBlur={() => {
                  formikForm.values.ranges.map(
                    (range, i) =>
                      range[1].term < 3 &&
                      formikForm.setFieldValue(`ranges[${i}][1].term`, 3)
                  );
                }}>
                <div className="input-item-main">
                  <label className="input-item-main__label">{`${range[1]?.title}`}</label>
                  <label className="ranges__controls-field">
                    <input
                      type="text"
                      min={3}
                      max={120}
                      className="ranges__controls-field-put ranges__controls-field-term"
                      placeholder="120"
                      value={range[1]?.term}
                      onChange={(e) => {
                        let valueEx = e.target.value.replace(/[^0-9]/g, "");
                        e.target.value = valueEx;

                        let value = Number(e.target.value);
                        if (e.target.value[0] !== "0") {
                          value > 120 || value < 0 || e.target.value === "0"
                            ? formikForm.setFieldValue(
                                `ranges.${index}[1].term`,
                                120
                              )
                            : formikForm.setFieldValue(
                                `ranges.${index}[1].term`,
                                Number(e.target.value)
                              );
                          e.target.value === "" &&
                            formikForm.setFieldValue(
                              `ranges.${index}[1].term`,
                              3
                            );
                        }
                      }}
                    />
                    {t<string>("CALCULATOR.MONTHS")}
                  </label>
                  <div className="range-wrapper">
                    <button
                      onClick={() => removeTerm(index)}
                      type="button"
                      className="range-wrapper__step-add">
                      <AiOutlineMinusCircle style={style} size={32} />
                    </button>
                    <input
                      type="range"
                      min={range[1]?.min}
                      max={range[1]?.max}
                      step={range[1]?.step}
                      className="range__controls-input range-wrapper__input"
                      {...formikForm.getFieldProps(`ranges.${index}[1].term`)}
                    />
                    <button
                      onClick={() => addTerm(index)}
                      type="button"
                      className="range-wrapper__step-minus">
                      <AiOutlinePlusCircle style={style} size={32} />
                    </button>
                  </div>
                </div>
                <div className="input-item__details">
                  <p>{`${range[1]?.prefix}`}</p>
                  <p>{`${range[1]?.sufix}`}</p>
                </div>
              </div>
            </div>
          );
        })}
        {
          formikForm.values.ranges.length < 3 &&
          <button
            type="button"
            className="search-block-main__credit-btn"
            onClick={addDefaultCredit}>
              <img src={addIcon} alt="addIcon" />
              <span>{t<string>("CALCULATOR.ADD_MORE")}</span>
          </button>
        }
      </div>
      
        <button
          onMouseEnter={() => {
            formikForm.values.ranges.map(
              (range, i) =>
                range[0].amount < 500 &&
                formikForm.setFieldValue(`ranges[${i}][0].amount`, 500)
            );
          }}
          className="search-block-show"
          type="submit">
          {t<string>("CALCULATOR.BUTTON_LOGGED")}
        </button>

    </form>
    </div>
  );
};

export default MergingLoansForm;
