import { useState } from "react";

import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { useAppSelector } from "../../../../core/store";
import { useTranslation } from "react-i18next";

const ShowCategoryContent = () => {
  const [content, setContent] = useState<any>({});

  const { t } = useTranslation();
  const { selectedId, choosenData } = useAppSelector((state) => state.app);

  useHttpGet<any>(
    `${APIRoutes.ADMIN_PUBLIC}/${choosenData}/categories/${selectedId}`,
    {
      resolve: (response) => {
        if (response.payload) {
          setContent(response.payload);
        }
      },
      dependencies: [selectedId],
    }
  );

  return (
    <div className="modal__container  modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("BLOG_TABLE.CONTENT_BG")}:
        </p>
        <span className="post-content">
          {content.translations !== undefined &&
            content.translations.bg.content}
        </span>
        <p
          style={{
            marginTop: "30px",
          }}
          className="modal-logout__box-title">
          {t<string>("BLOG_TABLE.CONTENT_EN")}:
        </p>
        <span className="post-content">
          {content.translations !== undefined &&
            content.translations.en.content}
        </span>
      </div>
    </div>
  );
};

export default ShowCategoryContent;
