import products_first from "../../assets/photos/all/products_first.svg";
import products_second from "../../assets/photos/all/products_second.svg";
import products_third from "../../assets/photos/all/products_third.svg";
import products_fourth from "../../assets/photos/all/products_fourth.svg";
import products_fifth from "../../assets/photos/all/products_fifth.svg";
import products_sixth from "../../assets/photos/all/products_sixth.svg";

export const products = [
  {
    id: 1,
    title: "CONSUMER_LOANS",
    info: "CONSUMER_DESC",
    btn_content: "GET_CONSUMER",
    photo: products_first,
  },
  {
    id: 2,
    title: "CAR_LEASING",
    info: "LEASING_DESC",
    btn_content: "GET_LEASING",
    photo: products_second,
  },
  {
    id: 3,
    title: "REFINANCING",
    info: "REFINANCING_DESC",
    btn_content: "GET_REFINANCING",
    photo: products_fourth,
  },
  {
    id: 4,
    title: "MERGING_LOANS",
    info: "MERGING_DESC",
    btn_content: "GET_MERGING",
    photo: products_third,
  },
  {
    id: 5,
    title: "MORTGAGE_LOANS",
    info: "MORTGAGE_DESC",
    btn_content: "GET_MORTGAGE",
    photo: products_fifth,
  },
  {
    id: 6,
    title: "QUICK_LOAN",
    info: "QUICK_DESC",
    btn_content: "GET_QUICK",
    photo: products_sixth,
  },
];
