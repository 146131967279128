import React, { useState } from "react";

import CarLeasingForm from "./CarLeasingForm";
import ConsumerLoansForm from "./ConsumerLoansForm";
import MergingLoansForm from "./MergingLoansForm";
import MortgageLoansForm from "./MortgageLoansForm";
import QuickLoansForm from "./QuickLoansForm";
import RefinancingForm from "./RefinancingForm";
import CreditCardsForm from "./CreditCardsForm";

import { results_calc } from "../../core/constants/results-calc";

import { useAppDispatch } from "../../core/store";
import {
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../core/services/enums";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../core/store";

export enum CalculatorTab {
  ConsumerLoan = 1,
  CreditCard = 2,
  QuickLoans = 3,
  MortgageLoans = 4,
  CarLeasing = 5,
  Refinancing = 6,
  MergingLoans = 7,
}

interface IProps {
  type: CalculatorTab;
}



const PrivateResultsCalculator: React.FC<IProps> = ({ type }) => {
  const currTab = JSON.parse(localStorage.getItem("calcResult") || "{}");

  const [step, setStep] = useState(+currTab.type);
  const dispatch = useAppDispatch();
  const {t} = useTranslation()
  const appLanguage = useAppSelector((state) => state.app.appLanguage);
  const throwAlertWindow = (typeId: number | null) => {
    if (typeId !== +currTab.type) {
      dispatch(setModalType(AuthSelectionType.resultsAlert));
      dispatch(setModal(true));
    }
  };
  const results_calc_titles = [
    {
      index: 1,
      name: t<string>("LOANS.CONSUMER_LOANS"),
    },
    {
      index: 2,
      name: t<string>("LOANS.CREDIT_CARDS"),
    },
    {
      index: 3,
      name: t<string>("LOANS.QUICK_LOAN"),
    },
    {
      index: 4,
      name: t<string>("LOANS.MORTGAGE_LOANS"),
    },
    {
      index: 5,
      name: t<string>("LOANS.CAR_LEASING"),
    },
    {
      index: 6,
      name: t<string>("LOANS.REFINANCING"),
    },
    {
      index: 7,
      name: t<string>("LOANS.MERGING_LOANS"),
    },
  
  ]
  return (
    <div className="results-calculator results-calculator-private">
      <div className="results-calculator-type">
        {results_calc.map((type: any, index) => {
          const { id } = type;
          const resultTitles = results_calc_titles[index].name
          return (
            <button
              // onClick={() => throwAlertWindow(id)}
              onClick={() => {
                setStep(id);
              }}
              className={classNames({
                "tabs-list__item": true,
                "tabs-list__item-active": step === id,
                "tabs-list__item-language-BG": appLanguage === "bg",
              })}
              key={id}
            >
              {resultTitles}
            </button>
          );
        })}
      </div>

      <div
        className="results-calculator__tabs-results-mobile tabs-results-mobile"
        onClick={() => throwAlertWindow(null)}
      >
        <select className="tabs-results-mobile__options options" disabled>
          {results_calc
            .filter((tab) => tab.id === +currTab.type)
            .map((type: any) => {
              const { id, name } = type;

              return (
                <option className="options__btn" key={id}>
                  {name}
                </option>
              );
            })}
        </select>
      </div>

      {/* {+currTab.type === CalculatorTab.ConsumerLoan && <ConsumerLoansForm />}
      {+currTab.type === CalculatorTab.CarLeasing && <CarLeasingForm />}
      {+currTab.type === CalculatorTab.Refinancing && <RefinancingForm />}
      {+currTab.type === CalculatorTab.MergingLoans && <MergingLoansForm />}
      {+currTab.type === CalculatorTab.MortgageLoans && <MortgageLoansForm />}
      {+currTab.type === CalculatorTab.QuickLoans && <QuickLoansForm />}
      {+currTab.type === CalculatorTab.CreditCard && <CreditCardsForm />} */}
      {step === CalculatorTab.ConsumerLoan && <ConsumerLoansForm />}
      {step === CalculatorTab.CreditCard && <CreditCardsForm />}
      {step === CalculatorTab.QuickLoans && <QuickLoansForm />}
      {step === CalculatorTab.MortgageLoans && <MortgageLoansForm />}
      {step === CalculatorTab.CarLeasing && <CarLeasingForm />}
      {step === CalculatorTab.Refinancing && <RefinancingForm />}
      {step === CalculatorTab.MergingLoans && <MergingLoansForm />}
    </div>
  );
};

export default PrivateResultsCalculator;
