import { useState } from "react";
import classNames from "classnames";

import Articles from "../Articles";
import Banner from "../Banner";
import BanksLogosList from "../BanksLogosList";
import CalculatorPage from "../../areas/public/Calculator/CalculatorPage";
import FeedbackAnnounce from "./FeedbackAnnounce/FeedbackAnnounce";
import Guide from "../Guide";
import Partners from "../Partners";
import Questions from "../Questions";
import WhyUs from "../WhyUs";
import { setModal } from "../../core/store/reducers/modal/modalSlice";
import { useAppDispatch } from "../../core/store";

const PreAuthModalWindow = () => {
  const [isAuth] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  return (
    <>
      {!isAuth && (
        <div
          className={classNames({
            modal: true,
            "modal-active": true,
            "modal__auth-wrapper": isAuth,
          })}>
          <BanksLogosList />
          <CalculatorPage />
          <Guide />
          <WhyUs />
          <Partners />
          {/* <Articles /> */}
          <Questions />
          <Banner />
          <div
            className="modal__auth-bg"
            onClick={() => {
              dispatch(setModal(false));
              document.body.style.overflowY = "scroll";
            }}></div>
          <div className="modal__auth-container modal-slide">
            <FeedbackAnnounce />
          </div>
        </div>
      )}
    </>
  );
};

export default PreAuthModalWindow;
