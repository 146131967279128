import React, { useEffect, useState } from "react";

import CreateBlogPostForm from "../../../components/CreateBlogPost/CreateBlogPostForm";
import CreateNewsPostForm from "../../../components/CreateNewsPost/CreateNewsPostForm";

const CreatePost = () => {
  const [type, setType] = useState<string | null>("");

  useEffect(() => {
    const tempType = localStorage.getItem("postName");
    setType(tempType);
  }, []);

  if (type === "Blogs") {
    return <CreateBlogPostForm />;
  }
  return <CreateNewsPostForm />;
};

export default CreatePost;
