import { UserRoleTypes } from "../services/enums";
import { useAppSelector } from "../store";

const useRole = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  return {
    isSuperAdmin: currentUser?.role === UserRoleTypes.SuperAdmin,
    isAdmin: currentUser?.role === UserRoleTypes.Admin,
    isPartner: currentUser?.role === UserRoleTypes.Partner,
    isUser: currentUser?.role === UserRoleTypes.User,
  };
};
export default useRole;
