import { AuthSelectionType } from "../../core/services/enums";
import { useAppDispatch } from "../../core/store";
import {
  setCerts,
  setCertsIds,
} from "../../core/store/reducers/documents/documentsSlice";
import {
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";

const CertificateWidget = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="certificate">
      <div className="certificate__details">
        <p className="certificate__details-title">
          Let's make your journey easier
        </p>
        <span className="certificate__details-desc">
          Add income certificate and get more opportunities
        </span>
      </div>
      <button
        className="certificate__btn"
        onClick={() => {
          dispatch(setModalType(AuthSelectionType.questionnairesList));
          dispatch(setModal(true));
          dispatch(setCerts([]));
          dispatch(setCertsIds([]));
        }}>
        Add certificate
      </button>
      <span className="certificate__advertise">Certificate</span>
    </div>
  );
};

export default CertificateWidget;
