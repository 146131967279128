import { AxiosResponse } from "axios";

import RequestsService from "./requests.service";
import { APIRoutes } from "../http";
import { Base64 } from "js-base64";
import { Auth } from "../models";

export default class AuthService {
  static createAuthHash(username: string, password: string) {
    return Base64.encode(`${username}:${password}`);
  }

  static async login(
    phone: string,
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("phone", phone);
    return RequestsService.postMethod<any>(APIRoutes.AUTH_LOGIN, formData);
  }

  // static async loginFacebook(
  //   accessToken: string,
  //   data_access_expiration_time: string,
  //   email: string,
  //   expiresIn: string,
  //   grantedScopes: string,
  //   graphDomain: string,
  //   id: string,
  //   name: string,
  //   picture: string,
  //   signedRequest: string,
  //   userID: string
  // ): Promise<AxiosResponse<any>> {
  //   const formData = new FormData();
  //   formData.append("accessToken", accessToken);
  //   formData.append("data_access_expiration_time", data_access_expiration_time);
  //   formData.append("email", email);
  //   formData.append("expiresIn", String(expiresIn));
  //   formData.append("grantedScopes", grantedScopes);
  //   formData.append("graphDomain", graphDomain);
  //   formData.append("id", id);
  //   formData.append("name", name);
  //   formData.append("picture", picture);
  //   formData.append("signedRequest", signedRequest);
  //   formData.append("userID", userID);
  //   return RequestsService.postMethod<any>(APIRoutes.AUTH_FACEBOOK, formData);
  // }

  // static async loginGoogle(
  //   model: Auth.GoogleLogin
  // ): Promise<AxiosResponse<any>> {
  //   const formData = new FormData();
  //   formData.append("aud", model.aud);
  //   formData.append("azp", model.azp);
  //   formData.append("email", model.email);
  //   formData.append("email_verified", model.email_verified.toString());
  //   formData.append("exp", model.exp.toString());
  //   formData.append("iss", model.iss);
  //   formData.append("jti", model.jti);
  //   formData.append("name", model.name);
  //   formData.append("picture", model.picture);
  //   formData.append("sub", model.sub);
  //   return RequestsService.postMethod<any>(APIRoutes.AUTH_GOOGLE, formData);
  // }

  static async register(model: Auth.Registration): Promise<AxiosResponse<any>> {
    return RequestsService.postMethod<any>(APIRoutes.AUTH_REGISTER, model);
  }

  // static async forgotPassword(
  //   email_phone: string
  // ): Promise<AxiosResponse<any>> {
  //   const formData = new FormData();
  //   formData.append("email_phone", email_phone);
  //   return RequestsService.postMethod<any>(
  //     APIRoutes.AUTH_FORGOT_PASSWORD,
  //     formData
  //   );
  // }

  // static async restorePassword(
  //   model: Auth.RestorePassword
  // ): Promise<AxiosResponse<any>> {
  //   const formData = new FormData();
  //   formData.append("id", model.id.toString());
  //   formData.append("code", model.code.toString());
  //   formData.append("password", model.password);
  //   formData.append("password_confirmation", model.password_confirmation);
  //   return RequestsService.postMethod<any>(
  //     APIRoutes.AUTH_RESTORE_PASSWORD,
  //     formData
  //   );
  // }
  static async getIpGeolocation(): Promise<AxiosResponse<any>> {
    return RequestsService.getMethod<any>(APIRoutes.AUTH_GET_IP)
  }
  static async verifyEmail(): Promise<AxiosResponse<any>> {
    return RequestsService.getMethod<any>(APIRoutes.AUTH_VERIFY_EMAIL);
  }

  static async verificationEmail(
    model: Auth.Verification
  ): Promise<AxiosResponse<any>> {
    return RequestsService.getMethod<any>(
      `${APIRoutes.AUTH_VERIFY_EMAIL}/${model.id}/${model.code}`
    );
  }

  static async verifyEmailResend(): Promise<AxiosResponse<any>> {
    return RequestsService.getMethod<any>(APIRoutes.AUTH_VERIFY_EMAIL_RESEND);
  }

  static async verifyPhone(): Promise<AxiosResponse<any>> {
    return RequestsService.getMethod<any>(APIRoutes.AUTH_VERIFY_PHONE);
  }

  static async verificationPhone(
    model: Auth.Verification
  ): Promise<AxiosResponse<any>> {
    return RequestsService.getMethod<any>(
      `${APIRoutes.AUTH_VERIFY_PHONE}/${model.id}/${model.code}`
    );
  }

  static async resetPassword(
    model: Auth.RestorePassword
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("password", model.password);
    formData.append("password_confirmation", model.password_confirmation);
    formData.append("id", model.id.toString());
    formData.append("code", model.code.toString());
    return RequestsService.postMethod<any>(
      `${APIRoutes.AUTH_RESET_PASSWORD}`,
      formData
    );
  }

  static async setPassword(
    model: Auth.SetPassword,
    id: number,
    code: number
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("password", model.password);
    formData.append("password_confirmation", model.password_confirmation);
    return RequestsService.postMethod<any>(
      `${APIRoutes.AUTH_SET_PASSWORD}/${id}/${code}`,
      formData
    );
  }

  static async verifyPhoneResend(): Promise<AxiosResponse<any>> {
    return RequestsService.getMethod<any>(APIRoutes.AUTH_VERIFY_PHONE_RESEND);
  }
}
