import React, { FC } from "react";
import AuthBase from "../AuthBase";
import RestorePasswordForm from "./RestorePasswordForm";

const RestorePasswordContainer: FC = () => {
  return (
    <AuthBase>
      <RestorePasswordForm />
    </AuthBase>
  );
};

export default RestorePasswordContainer;
