import React, { FC, useState } from "react";
import classNames from "classnames";

import search_icon from "../../../assets/photos/partner/search-icon.svg";
import calendar from "../../../assets/photos/partner/calendar.svg";

interface TableNavigationProps {
  setStatus: (status: string) => void;
  setCurrentPage: (currentPage: number) => void;
  status: string;
}

const TableNavigation: FC<TableNavigationProps> = ({
  status,
  setStatus,
  setCurrentPage,
}: TableNavigationProps) => {
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>("Today (56)");
  const [showDaysOption, setShowDaysOption] = useState<boolean>(false);

  return (
    <div className="table-navigation">
      <div className="table-navigation-list">
        {isSearch ? (
          <>
            <input
              placeholder="Find a customer"
              type="text"
              className="table-navigation-list__search-box"
            />
            <img
              onClick={() => {
                setIsSearch(false);
              }}
              src={search_icon}
              alt="search_icon"
              className="table-navigation-list__search-box-img"
            />
          </>
        ) : (
          <button
            onClick={() => {
              setIsSearch(true);
            }}
            className="table-navigation-list__search"
          >
            <img src={search_icon} alt="search_icon" />
          </button>
        )}

        <button
          onClick={() => {
            setStatus("New");
            setCurrentPage(1);
          }}
          className={classNames({
            "table-navigation-list__btn": true,
            "table-navigation-list__btn-active": status === "New",
          })}
        >
          New (8)
        </button>
        <button
          onClick={() => {
            setStatus("In progress");
            setCurrentPage(1);
          }}
          className={classNames({
            "table-navigation-list__btn": true,
            "table-navigation-list__btn-active": status === "In progress",
          })}
        >
          In progress (16)
        </button>
        <button
          onClick={() => {
            setStatus("Approved");
            setCurrentPage(1);
          }}
          className={classNames({
            "table-navigation-list__btn": true,
            "table-navigation-list__btn-active": status === "Approved",
          })}
        >
          Approved (32)
        </button>
        <button
          onClick={() => {
            setStatus("Declined");
            setCurrentPage(1);
          }}
          className={classNames({
            "table-navigation-list__btn": true,
            "table-navigation-list__btn-active": status === "Declined",
          })}
        >
          Declined (43)
        </button>
        <button
          onClick={() => {
            setStatus("All");
            setCurrentPage(1);
          }}
          className={classNames({
            "table-navigation-list__btn": true,
            "table-navigation-list__btn-active": status === "All",
          })}
        >
          All (248)
        </button>
      </div>
      <div className="table-navigation-end">
        <div
          onClick={() => setShowDaysOption(!showDaysOption)}
          className="table-navigation-end__days"
        >
          <span>{selectedDay}</span>
        </div>
        {showDaysOption && (
          <div className="table-navigation-end__days-list-box days-list-box">
            <span
              onClick={() => {
                setSelectedDay("Today (56)");
                setShowDaysOption(false);
              }}
              className={classNames({
                "days-list-box__item": true,
                "days-list-box__item-current": selectedDay === "Today (56)",
              })}
            >
              Today (56)
            </span>
            <span
              onClick={() => {
                setSelectedDay("This week (24)");
                setShowDaysOption(false);
              }}
              className={classNames({
                "days-list-box__item": true,
                "days-list-box__item-current": selectedDay === "This week (24)",
              })}
            >
              This week (24)
            </span>
            <span
              onClick={() => {
                setSelectedDay("This month (56)");
                setShowDaysOption(false);
              }}
              className={classNames({
                "days-list-box__item": true,
                "days-list-box__item-current":
                  selectedDay === "This month (56)",
              })}
            >
              This month (56)
            </span>
          </div>
        )}

        <button className="table-navigation-end__date">
          <span>Select Date</span>
          <img src={calendar} alt="calendar" />
        </button>
      </div>
    </div>
  );
};

export default TableNavigation;
