import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Public } from "../../../models";
import { AppState } from "./appDataTypes";

const state: AppState = {
  questionnaire: null,
  calculator: null,
  appLanguage: "bg",
  choosenData: null,
  selectedId: 0,
  notifications: [],
  loginRoute: false,
};

const appSlice = createSlice({
  name: "app",
  initialState: state,
  reducers: {
    setQuestionnaire(state, action: PayloadAction<any>) {
      state.questionnaire = action.payload;
    },
    setCalculator(state, action: PayloadAction<any>) {
      state.calculator = action.payload;
    },
    setAppLanguage(state, action: PayloadAction<string>) {
      state.appLanguage = action.payload;
    },
    setChoosenData(state, action: PayloadAction<any>) {
      state.choosenData = action.payload;
    },
    setSelectedId(state, action: PayloadAction<number | null>) {
      state.selectedId = action.payload;
    },
    setNotifications(state, action: PayloadAction<Public.Notification[]>) {
      state.notifications = action.payload;
    },
    setDeleteNotification(state, action: PayloadAction<number>) {
      // state.notifications = action.payload;
      state.notifications = state.notifications.filter((notif) => notif.id !== action.payload);
    },
    setNotificationsSort(state, action: PayloadAction<Public.Notification[]>) {
      state.notifications = action.payload;
    },
    setLoginRoute(state, action: PayloadAction<boolean>) {
      state.loginRoute = action.payload;
    },
  },
});

export default appSlice.reducer;
export const {
  setQuestionnaire,
  setSelectedId,
  setAppLanguage,
  setCalculator,
  setChoosenData,
  setNotifications,
  setDeleteNotification,
  setNotificationsSort,
  setLoginRoute,
} = appSlice.actions;
