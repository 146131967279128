import { useFormik } from "formik";
import DatePicker from "react-date-picker";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
// import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import moment from "moment";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../core/store";
import { usePrevious } from "../../../core/hooks/usePrevious";
import {
  currencyFormat,
  filterPhoneNum,
  getPreviousDay,
} from "../../../core/functions/funtions";

import CalendarIcon from "../../../assets/photos/Calendar";

import { setCalculator } from "../../../core/store/reducers/app/appDataSlice";
import add from "../../../assets/photos/all/add.svg";
import verified from "../../../assets/photos/all/verified.svg";
import nonVerified from "../../../assets/photos/all/not-verified.svg";
import PrivateService from "../../../core/services/private.service";
import { toast } from "react-toastify";

type Props = {
  setType: (state: string) => void;
  questionsStep: number;
  setQuestionsStep: (num: number) => void;
};

const FirstStep: React.FC<Props> = ({
  questionsStep,
  setQuestionsStep,
  setType,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [touchedPassport, setTouchedPassport] = useState(false)
  const { currentUser } = useAppSelector((state) => state.auth);
  const { appLanguage, calculator } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (currentUser) {
      let questionnaire;
      if (sessionStorage.getItem("questionnaire")) {
        questionnaire = JSON.parse(
          sessionStorage.getItem("questionnaire") || "{}"
        );
      } else {
        const calc = JSON.parse(localStorage.getItem("calcResult") || "{}");

        questionnaire = { ...currentUser, ...calc };

        for (const property in questionnaire) {
          questionnaire[property] =
            questionnaire[property] !== null
              ? String(questionnaire[property])
              : "";
        }
        sessionStorage.setItem("questionnaire", JSON.stringify(questionnaire));
      }

      formikForm.setValues({
        ...formikForm.values,
        ...questionnaire,
        ...calculator,
      });

      if (calculator) {
        setType(calculator.type);
      } else {
        setType(formikForm.values.type);
      }

      if (questionnaire["personal_code"]) {
        formikForm.setFieldValue(
          "personal_code",
          questionnaire["personal_code"]
        );
        formikForm.setFieldValue("egn", false);
      } else {
        formikForm.setFieldValue("egn", "");
        formikForm.setFieldValue("personal_code", "");
      }

      // egnLengthValidator();
      // egnValidator();
    }
  }, [currentUser, calculator]);

  const valueFormValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(55),
    last_name: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(55),
    email: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    phone: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(15),
    gender: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    date_of_birth: Yup.date()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(moment().subtract(1, "days").toDate(), t<string>("PROFILE.INVALID_DATE"))
      .min(moment().subtract(122, "years").toDate(), t<string>("PROFILE.INVALID_DATE")),
    age: Yup.number().moreThan(18, t<string>("NEW_USER.UNDER18")).required(),
    citizenship: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),

    personal_code: Yup.string().when("egnCheckbox", {
      is: false,
      then: Yup.string()
        .required(t<string>("NEW_USER.ADD_EGN"))
        .matches(/^\d{10}$/, t<string>("NEW_USER.EGN_RULE")),
      otherwise: Yup.string(),
    }),
    egnCheckbox: Yup.boolean(),

    passport: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .length(9, "Passport number must be 9 characters"),
    amount_1: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    term_1: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    type: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    authority: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    date_of_issue: Yup.date()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(moment().subtract(1, "days").toDate(), "Invalid date")
      .min(moment().subtract(122, "years").toDate(), "Invalid date"),
    link_to_advert: Yup.string().when("checked", {
      is: true,
      then: Yup.string().required(t<string>("CALCULATOR.LINK_IS_REQUIRED")),
      otherwise: Yup.string(),
    }),
    bank_1: Yup.string(),
    bank_2: Yup.string(),
    bank_3: Yup.string(),
    property_details: Yup.string(),
    checked: Yup.boolean(),
    car_year: Yup.string().when("checked", {
      is: true,
      then: Yup.string().required(t<string>("CALCULATOR.YEAR_IS_REQUIRED")),
      otherwise: Yup.string(),
    }),
    car: Yup.string().when("checked", {
      is: true,
      then: Yup.string().required(t<string>("CALCULATOR.CAR_IS_REQUIRED")),
      otherwise: Yup.string(),
    }),
  });

  const formikForm = useFormik<{
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    gender: string;
    date_of_birth: string;
    age: number;
    citizenship: string;
    personal_code: string;
    car: string;
    car_year: number;
    creditor: string;
    credit_amount: number;
    current_amount: string;
    passport: string;
    amount_1: string;
    amount_2: string;
    amount_3: string;
    term_1: string;
    type: string;
    egn: boolean;
    authority: string;
    date_of_issue: string;
    link_to_advert: string;
    bank_1: string;
    bank_2: string;
    bank_3: string;
    property_details: string;
    checked: boolean;
  }>({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      gender: "0",
      age: 0,
      date_of_birth: "",
      citizenship: "0",
      personal_code: "",
      car: "",
      car_year: new Date().getFullYear(),
      current_amount: "",
      creditor: "",
      credit_amount: 0,
      passport: "",
      amount_1: "",
      amount_2: "",
      amount_3: "",
      term_1: "",
      type: "",
      egn: false,
      authority: "",
      date_of_issue: "",
      link_to_advert: "",
      bank_1: "",
      bank_2: "",
      bank_3: "",
      property_details: "",
      checked: false,
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });
  const handleSubmitForm = (values: any) => {
    setQuestionsStep(questionsStep + 1);

    localStorage.removeItem("calcResult");
    if (values.type === "4") {
      dispatch(
        setCalculator({
          ...calculator,
          amount_1: values.amount_1,
          amount_2: values.amount_2,
          amount_3: values.amount_3,
          term_1: values.term_1,
        })
      );
    } else {
      dispatch(
        setCalculator({
          ...calculator,
          amount_1: values.amount_1,
          term_1: values.term_1,
          current_amount: values.current_amount,
        })
      );
    }

    delete values.egn;

    sessionStorage.setItem("questionnaire", JSON.stringify(values));

    sessionStorage.setItem("currentStep", JSON.stringify(questionsStep + 1));

    window.scrollTo(0, 0);
  };
  
  useEffect(() => {
    sessionStorage.setItem(
      "stepper",
      JSON.stringify({
        firstStepConfirm: formikForm.isValid,
        secondStepConfirm: false,
        thirdStepConfirm: false,
      })
    );
  }, [formikForm.isValid]);

  const currAmountValidation = () => {
    return (
      formikForm.values.type === "3" &&
      !formikForm.touched.current_amount &&
      +formikForm.values.current_amount > 999
    );
  };

  const egnLengthValidator = () => {
    if (
      formikForm.values.personal_code.length === 0 ||
      formikForm.values.personal_code.length === 10
    ) {
      return true;
    }

    return false;
  };

  const egnValidator = () => {
    if (
      (formikForm.values.personal_code.length === 0 &&
      formikForm.values.egn === true) || formikForm.values.personal_code.length === 10
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const FormValidator = () => {
    if (!formikForm.values.checked) {
      return (
        formikForm.values.first_name &&
        formikForm.values.last_name &&
        formikForm.values.email &&
        formikForm.values.phone &&
        formikForm.values.gender &&
        formikForm.values.date_of_birth &&
        formikForm.values.date_of_issue &&
        formikForm.values.authority &&
        formikForm.values.citizenship &&
        formikForm.values.passport &&
        formikForm.values.passport.length === 9 &&
        (formikForm.values.personal_code.length === 10 || formikForm.values.egn === true) 
      );
    } else {
      return (
        formikForm.values.car_year &&
        formikForm.values.link_to_advert &&
        formikForm.values.car &&
        formikForm.values.first_name &&
        formikForm.values.last_name &&
        formikForm.values.email &&
        formikForm.values.phone &&
        formikForm.values.gender &&
        formikForm.values.date_of_birth &&
        formikForm.values.date_of_issue &&
        formikForm.values.authority &&
        formikForm.values.citizenship &&
        formikForm.values.passport &&
        formikForm.values.passport.length === 9
      );
    }
    
  };

  const amountTwoValid = () => {
    return formikForm.values.amount_2 && +formikForm.values.amount_2 > 499;
  };

  const amountThreeValid = () => {
    return formikForm.values.amount_3 && +formikForm.values.amount_3 > 499;
  };

  const validLeftover = () => {
    return formikForm.values.credit_amount && +formikForm.values.credit_amount;
  };

  const amountValidation = () => {
    if (
      formikForm.values.type === "1" ||
      formikForm.values.type === "5" ||
      formikForm.values.type === "6" ||
      formikForm.values.type === "7"
    ) {
      return formikForm.values.amount_1 && +formikForm.values.amount_1 > 499;
    }
    if (formikForm.values.type === "2") {
      return formikForm.values.amount_1 && +formikForm.values.amount_1 > 199;
    }
    if (formikForm.values.type === "4") {
      return formikForm.values.amount_1 && +formikForm.values.amount_1 > 5999;
    }
    if (formikForm.values.type === "3") {
      return formikForm.values.amount_1 && +formikForm.values.amount_1 > 249;
    }
  };
  // const termValidationTwo = () => {
  //   return (
  //     formikForm.values.term_2 &&
  //     formikForm.values.term_2 !== "1" &&
  //     formikForm.values.term_2 !== "2"
  //   );
  // };

  // const termValidationThree = () => {
  //   return (
  //     formikForm.values.term_3 &&
  //     formikForm.values.term_3 !== "1" &&
  //     formikForm.values.term_3 !== "2"
  //   );
  // };

  const termValidation = () => {
    return (
      formikForm.values.term_1 &&
      formikForm.values.term_1 !== "1" &&
      formikForm.values.term_1 !== "2"
    );
  };

  const prevEgn = usePrevious(formikForm.values.personal_code);
  const prevPassport = usePrevious(formikForm.values.passport);

  const typeValidation = () => {
    return formikForm.values.type && formikForm.values.type;
  };

  const creditCoercion = (e: any) => {
    let maxValue = 1000000;

    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue("credit_amount", maxValue);
    } else {
      formikForm.setFieldValue("credit_amount", valueNum);
    }
  };

  const coercion = (e: any, type: string, count: number) => {
    let maxValue;

    switch (type) {
      case "1":
        maxValue = 80000;
        break;
      case "2":
        maxValue = 25000;
        break;
        case "3":
        maxValue = 10000;
        break;
      case "4":
        maxValue = 400000;
        break;
      case "5":
        maxValue = 50000;
        break;
      case "6":
        maxValue = 50000;
        break;
      case "7":
        maxValue = 50000;
        break;
      default:
        maxValue = 50000;
    }

    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue(`amount_${count}`, maxValue);
    } else {
      formikForm.setFieldValue(`amount_${count}`, valueNum);
    }
  };

  const currAmountCoercion = (e: any) => {
    let maxValue = 50000;

    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue("current_amount", maxValue);
    } else {
      formikForm.setFieldValue("current_amount", valueNum);
    }
  };

  const termValidityGeneral = (e: any) => {
    let valueEx = e.target.value.replace(/[^0-9]/g, "");

    e.target.value = valueEx;

    let value = Number(e.target.value);

    if (
      (e.target.value[0] !== "0" && formikForm.values.type === "1") ||
      formikForm.values.type === "3" ||
      formikForm.values.type === "4"
    ) {
      value > 120 || value < 0 || e.target.value === "0"
        ? formikForm.setFieldValue("term_1", "")
        : formikForm.setFieldValue("term_1", e.target.value);
      termValidation();
    }

    if (e.target.value[0] !== "0" && formikForm.values.type === "2") {
      value > 96 || value < 0 || e.target.value === "0"
        ? formikForm.setFieldValue("term_1", "")
        : formikForm.setFieldValue("term_1", e.target.value);
      termValidation();
    }

    if (e.target.value[0] !== "0" && formikForm.values.type === "6") {
      value > 12 || value < 0 || e.target.value === "0"
        ? formikForm.setFieldValue("term_1", "")
        : formikForm.setFieldValue("term_1", e.target.value);
      termValidation();
    }

    if (e.target.value[0] !== "0" && formikForm.values.type === "5") {
      value > 240 || value < 0 || e.target.value === "0"
        ? formikForm.setFieldValue("term_1", "")
        : formikForm.setFieldValue("term_1", e.target.value);
      termValidation();
    }
  };

  const amountCleaner = (value: string) => {
    switch (value) {
      case "4":
        formikForm.setValues({
          ...formikForm.values,
          amount_1: "10000",
          term_1: "36",
          type: "4",
        });
        dispatch(setCalculator({ amount_1: "10000", term_1: "36", type: "4" }));
        break;
      case "1":
        formikForm.setValues({
          ...formikForm.values,
          amount_1: "10000",
          term_1: "36",
          type: "1",
        });
        dispatch(setCalculator({ amount_1: "10000", term_1: "36", type: "1" }));
        break;
      case "2":
        formikForm.setValues({
          ...formikForm.values,
          amount_1: "10000",
          term_1: "36",
          type: "2",
        });
        dispatch(setCalculator({ amount_1: "10000", term_1: "36", type: "2" }));
        break;
      case "3":
        formikForm.setValues({
          ...formikForm.values,
          amount_1: "10000",
          term_1: "36",
          type: "3",
          current_amount: "1000",
        });
        dispatch(
          setCalculator({
            amount_1: "10000",
            term_1: "36",
            type: "3",
            current_amount: "1000",
          })
        );
        break;
      case "5":
        formikForm.setValues({
          ...formikForm.values,
          amount_1: "30000",
          term_1: "72",
          type: "5",
        });
        dispatch(setCalculator({ amount_1: "30000", term_1: "72", type: "5" }));
        break;
      case "6":
        formikForm.setValues({
          ...formikForm.values,
          amount_1: "2000",
          term_1: "3",
          type: "6",
        });
        dispatch(setCalculator({ amount_1: "2000", term_1: "3", type: "6" }));
        break;
      case "7":
        formikForm.setValues({
          ...formikForm.values,
          amount_1: "10000",
          term_1: "3",
          type: "7",
        });
        dispatch(setCalculator({ amount_1: "10000", term_1: "3", type: "7" }));
        break;
    }
  };
  const emailVerification = async (email: string) => {
    try {
      const response = await PrivateService.emailVerify(email);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.payload.email[0]);
    }
  };

  const addCredit = () => {
    if (
      calculator.hasOwnProperty("amount_2") &&
      calculator.hasOwnProperty("term_2")
    ) {
      dispatch(
        setCalculator({
          ...calculator,
          amount_3: 10000,
          term_3: 36,
        })
      );
    } else {
      dispatch(
        setCalculator({
          ...calculator,
          term_1: formikForm.values.term_1,
          amount_1: formikForm.values.amount_1,
          amount_2: 10000,
          term_2: 36,
        })
      );
    }
  };

  const removeCredit = () => {
    let newCalc = Object.assign({}, calculator);

    if (
      calculator.hasOwnProperty("amount_3") &&
      calculator.hasOwnProperty("term_3")
    ) {
      delete newCalc.amount_3;
      delete newCalc.term_3;
      dispatch(setCalculator(newCalc));
    } else {
      delete newCalc.amount_2;
      delete newCalc.term_2;
      dispatch(setCalculator(newCalc));
    }
  };

  const ageValidation = (age: any) => {
    const currentYear = new Date().getFullYear();
    formikForm.setFieldValue("age", currentYear - age.getFullYear());
  };

  useEffect(() => {
    ageValidation(new Date(formikForm.values.date_of_birth));
  }, [formikForm.values.date_of_birth]);

  return (
    <form
      className={classNames({
        about: true,
      })}
      onSubmit={formikForm.handleSubmit}
    >
      <div className="miscount">
        <div className="miscount__container">
          <div className="miscount__container-inputs inputs-miscount questionnaires">
            <div className="inputs-miscount-fields">
              <div className="inputs-miscount-fields__row">
                <div
                  className={classNames({
                    "inputs-miscount__box": true,
                    "inputs-miscount__box-loan-amount":
                      formikForm.values.type === "2" && true,
                    "inputs-miscount__box-line": !amountValidation(),
                  })}
                >
                  <span
                    className={classNames({
                      "inputs-miscount__box-name": true,
                      "inputs-miscount__box-error": !amountValidation(),
                    })}
                  >
                    {formikForm.values.type === "2" && true ? t<string>("CALCULATOR.CREDIT_LIMIT") :  t<string>("CALCULATOR.LOAN_AMOUNT")}
                  </span>
                  <label className="inputs-miscount__box-field">
                    <input
                      className={classNames({
                        "inputs-miscount__box-field-inp": true,
                        "inputs-miscount__box-field-amount": true,
                        "inputs-miscount__box-field-amount-l":
                          +formikForm.values.amount_1 > 9999 &&
                          +formikForm.values.amount_1 < 100000,
                        "inputs-miscount__box-field-amount-xl":
                          +formikForm.values.amount_1 > 99999,
                      })}
                      type="text"
                      value={currencyFormat(Number(formikForm.values.amount_1))}
                      placeholder="50000"
                      onChange={(event) => {
                        coercion(event, formikForm.values.type, 1);
                        amountValidation();
                      }}
                    />
                    {t<string>("PROFILE.BGN")}
                  </label>
                </div>
                {formikForm.values.type !== "2" &&
                  formikForm.values.type !== "7" && (
                    <div
                      className={classNames({
                        "inputs-miscount__box": true,
                      })}
                    >
                      <span
                        className={classNames({
                          "inputs-miscount__box-name": true,
                        })}
                      >
                        {t<string>("CALCULATOR.LOAN_TERM")}
                      </span>
                      <label className="inputs-miscount__box-field">
                        <input
                          className={classNames({
                            "inputs-miscount__box-field-inp": true,
                            "inputs-miscount__box-field-term":
                              +formikForm.values.term_1 < 100,
                            "inputs-miscount__box-field-mort":
                              +formikForm.values.term_1 >= 100,
                          })}
                          type="text"
                          value={formikForm.values.term_1}
                          placeholder={
                            formikForm.values.type === "3" ? "3" : "12"
                          }
                          onChange={(event: any) => {
                            termValidityGeneral(event);
                          }}
                        />
                        {t<string>("CALCULATOR.MONTHS")}
                      </label>
                    </div>
                  )}
                {formikForm.values.type === "7" && (
                  <div
                    className={classNames({
                      "inputs-miscount__box": true,
                    })}
                  >
                    <span
                      className={classNames({
                        "inputs-miscount__box-name": true,
                      })}
                    >
                      {t<string>("PROFILE.OBLIGEE")}
                    </span>
                    <label className="inputs-miscount__box-field">
                      <input
                        className={classNames({
                          "inputs-miscount__box-field-leasing-inp ": true,
                          "inputs-miscount__box-field-term":
                            formikForm.errors.bank_1,
                        })}
                        type="text"
                        placeholder={t<string>("PROFILE.CREDITOR_NAME")}
                        {...formikForm.getFieldProps("bank_1")}
                      />
                    </label>
                  </div>
                )}
              </div>
              {formikForm.values.type === "7" &&
                calculator.hasOwnProperty("amount_2") &&
                calculator.hasOwnProperty("term_2") && (
                  <div className="inputs-miscount-fields__row">
                    <div
                      className={classNames({
                        "inputs-miscount__box": true,
                        "inputs-miscount__box-line": !amountTwoValid(),
                      })}
                    >
                      <span
                        className={classNames({
                          "inputs-miscount__box-name": true,
                          "inputs-miscount__box-error": !amountTwoValid(),
                        })}
                      >
                        {t<string>("CALCULATOR.LOAN_AMOUNT")}
                      </span>
                      <label className="inputs-miscount__box-field">
                        <input
                          className={classNames({
                            "inputs-miscount__box-field-inp": true,
                            "inputs-miscount__box-field-amount": true,
                            "inputs-miscount__box-field-amount-l":
                              +formikForm.values.amount_2 > 9999 &&
                              +formikForm.values.amount_2 < 100000,
                            "inputs-miscount__box-field-amount-xl":
                              +formikForm.values.amount_2 > 99999,
                          })}
                          type="text"
                          value={currencyFormat(
                            Number(formikForm.values.amount_2)
                          )}
                          placeholder="50000"
                          onChange={(event) => {
                            coercion(event, formikForm.values.type, 2);
                            amountTwoValid();
                          }}
                        />
                        {t<string>("CALCULATOR.BGN")}
                      </label>
                    </div>
                    <div
                      className={classNames({
                        "inputs-miscount__box": true,
                      })}
                    >
                      <span
                        className={classNames({
                          "inputs-miscount__box-name": true,
                        })}
                      >
                        {t<string>("PROFILE.OBLIGEE")}
                      </span>
                      <label className="inputs-miscount__box-field">
                        <input
                          className={classNames({
                            "inputs-miscount__box-field-leasing-inp ": true,
                            "inputs-miscount__box-field-term":
                              formikForm.errors.bank_2,
                          })}
                          type="text"
                          placeholder={t<string>("PROFILE.CREDITOR_NAME")}
                          {...formikForm.getFieldProps("bank_2")}
                        />
                      </label>
                    </div>
                  </div>
                )}
              {formikForm.values.type === "7" &&
                calculator.hasOwnProperty("amount_3") &&
                calculator.hasOwnProperty("term_3") && (
                  <div className="inputs-miscount-fields__row">
                    <div
                      className={classNames({
                        "inputs-miscount__box": true,
                        "inputs-miscount__box-line": !amountThreeValid(),
                      })}
                    >
                      <span
                        className={classNames({
                          "inputs-miscount__box-name": true,
                          "inputs-miscount__box-error": !amountThreeValid(),
                        })}
                      >
                        {t<string>("CALCULATOR.LOAN_AMOUNT")}
                      </span>
                      <label className="inputs-miscount__box-field">
                        <input
                          className={classNames({
                            "inputs-miscount__box-field-inp": true,
                            "inputs-miscount__box-field-amount": true,
                            "inputs-miscount__box-field-amount-l":
                              +formikForm.values.amount_3 > 9999 &&
                              +formikForm.values.amount_3 < 100000,
                            "inputs-miscount__box-field-amount-xl":
                              +formikForm.values.amount_3 > 99999,
                          })}
                          type="text"
                          value={currencyFormat(
                            Number(formikForm.values.amount_3)
                          )}
                          placeholder="50000"
                          onChange={(event) => {
                            coercion(event, formikForm.values.type, 3);
                            amountThreeValid();
                          }}
                        />
                        {t<string>("CALCULATOR.BGN")}
                      </label>
                    </div>
                    <div
                      className={classNames({
                        "inputs-miscount__box": true,
                      })}
                    >
                      <span
                        className={classNames({
                          "inputs-miscount__box-name": true,
                        })}
                      >
                        {t<string>("PROFILE.OBLIGEE")}
                      </span>
                      <label className="inputs-miscount__box-field">
                        <input
                          className={classNames({
                            "inputs-miscount__box-field-leasing-inp ": true,
                            "inputs-miscount__box-field-term":
                              formikForm.errors.bank_3,
                          })}
                          type="text"
                          placeholder={t<string>("PROFILE.CREDITOR_NAME")}
                          {...formikForm.getFieldProps("bank_3")}
                        />
                      </label>
                    </div>
                  </div>
                )}
              {formikForm.values.type === "7" && (
                <>
                  {!calculator.hasOwnProperty("amount_3") &&
                    !calculator.hasOwnProperty("term_3") &&
                    +formikForm.values.amount_1 >= 500 &&
                    +formikForm.values.term_1 >= 3 && (
                      <div className="inputs-miscount-fields__row">
                        <div className="calc-container__add remove">
                          <button
                            className="calc-container__add-btn "
                            onClick={addCredit}
                            type="button"
                          >
                            <img src={add} alt="add icon" />
                            {t<string>("CALCULATOR.MERGING_ADD")}
                          </button>
                        </div>
                      </div>
                    )}
                  {calculator.hasOwnProperty("amount_2") &&
                    calculator.hasOwnProperty("term_2") && (
                      <div className="calc-container__add calc-container__remove remove">
                        <button
                          className="calc-container__add-btn "
                          onClick={removeCredit}
                          type="button"
                        >
                          {t<string>("CALCULATOR.MERGING_REMOVE")}
                        </button>
                      </div>
                    )}
                </>
              )}
            </div>

            <div
              className={classNames({
                "inputs-miscount__box": true,
                "inputs-miscount__select": true,
                "inputs-miscount__box-line": !typeValidation(),
              })}
            >
              <span
                className={classNames({
                  "inputs-miscount__box-name": true,
                  "inputs-miscount__select-name": true,
                  "inputs-miscount__box-error": !typeValidation(),
                })}
              >
                {t<string>("CALCULATOR.LOAN_TYPE")}
              </span>
              <select
                value={formikForm.values.type}
                onChange={(event: any) => {
                  formikForm.setFieldValue("type", event.target.value);
                  amountCleaner(event.target.value);
                }}
                className="inputs-miscount__select-field"
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="1">{t<string>("LOANS.CONSUMER_LOANS")}</option>
                <option value="2">{t<string>("LOANS.CREDIT_CARDS")}</option>
                <option value="3">{t<string>("LOANS.QUICK_LOAN")}</option>
                <option value="4">{t<string>("LOANS.MORTGAGE_LOANS")}</option>
                <option value="5">{t<string>("LOANS.CAR_LEASING")}</option>
                <option value="6">{t<string>("LOANS.REFINANCING")}</option>
                <option value="7">{t<string>("LOANS.MERGING_LOANS")}</option>
              </select>
            </div>
          </div>
        </div>  
        {formikForm.values.type === "4" && (
          <div className="miscount__container">
            <div className="miscount__container-inputs inputs-miscount">
              <div className="inputs-miscount-fields">
                <div className="inputs-miscount-fields__row">
                  <div
                    className={classNames({
                      "inputs-miscount__box": true,
                    })}
                  >
                    <span
                      className={classNames({
                        "inputs-miscount__box-name": true,
                      })}
                    >
                      {t<string>("PROFILE.PROPERTY_DETAILS")}
                    </span>
                    <label className="inputs-miscount__box-field">
                      <input
                        className={classNames({
                          "inputs-miscount__box-field-leasing-inp": true,
                          "inputs-miscount__box-field-leasing": true,
                        })}
                        type="text"
                        placeholder={t<string>("PROFILE.FULL_ADDRESS")}
                        {...formikForm.getFieldProps("property_details")}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {formikForm.values.type === "6" && (
          <div className="miscount__container">
            <div className="miscount__container-inputs inputs-miscount questionnaires">
              <div className="inputs-miscount-fields">
                <div className="inputs-miscount-fields__row">
                  <div
                    className={classNames({
                      "inputs-miscount__box": true,
                    })}
                  >
                    <span
                      className={classNames({
                        "inputs-miscount__box-name": true,
                      })}
                    >
                      {t<string>("PROFILE.OBLIGEE")}
                    </span>
                    <label className="inputs-miscount__box-field">
                      <input
                        className={classNames({
                          "inputs-miscount__box-field-leasing-inp": true,
                          "inputs-miscount__box-field-leasing": true,
                        })}
                        type="text"
                        placeholder={t<string>("PROFILE.CREDITOR_NAME")}
                        {...formikForm.getFieldProps("creditor")}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {formikForm.values.type === "7" && (
          <div className="miscount__container">
            <div className="miscount__container-inputs inputs-miscount questionnaires">
              <div className="inputs-miscount-fields">
                <div className="inputs-miscount-fields__row">
                  <div
                    className={classNames({
                      "inputs-miscount__box": true,
                    })}
                  >
                    <span
                      className={classNames({
                        "inputs-miscount__box-name": true,
                      })}
                    >
                      {t<string>("PROFILE.REPAYMENT_TERM")}
                    </span>
                    <label className="inputs-miscount__box-field">
                      <input
                        className={classNames({
                          "inputs-miscount__box-field-leasing-inp": true,
                          "inputs-miscount__box-field-leasing": true,
                        })}
                        type="text"
                        placeholder="months"
                        {...formikForm.getFieldProps("term_1")}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {formikForm.values.type === "5" && (
          <div className="miscount__container">
            <div className="miscount__container-inputs inputs-miscount">
              <div className="inputs-miscount-fields">
                <div className="car__loans">
                  <div>
                  <div
                    className={classNames({
                      "inputs-miscount__box": true,
                    })}
                  >
                    <span
                      className={classNames({
                        "inputs-miscount__box-name": true,
                        "about-box__field-name-filled":
                          formikForm.values.car,
                        "about-box__field-inp-error": (formikForm.touched.car && formikForm.errors.car)
                      })}
                    >
                      {t<string>("PROFILE.CAR")}
                    </span>
                    <label className="inputs-miscount__box-field-leasing">
                      <input
                        disabled={formikForm.values.car === "checked"}
                        className={classNames({
                          "inputs-miscount__box-field-leasing-inp": true,
                          "inputs-miscount__box-field-leasing": true,
                        })}
                        type="text"
                        placeholder={t<string>("PROFILE.BRAND")}
                        {...formikForm.getFieldProps("car")}
                      />
                    </label>
                  </div>
                  {
                    formikForm.errors.car && (
                      <div className="form-control-error">
                        {t<string>("CALCULATOR.CAR_IS_REQUIRED")}
                      </div>
                  )}
                </div>
                <div>
                  <div
                    className={classNames({
                      "inputs-miscount__box": true,
                      "inputs-miscount__box-line":
                        formikForm.values.car_year.toString().length < 4 &&
                        formikForm.values.car_year.toString().length !== 0,
                    })}
                  >
                    <span
                      className={classNames({
                        "inputs-miscount__box-name": true,
                        "inputs-miscount__box-errors":
                          (formikForm.values.car_year.toString().length < 4) &&
                          (formikForm.values.car_year.toString().length !== 0),
                        "inputs-miscount__box-error": (formikForm.touched.car && formikForm.errors.car),
                      })}
                    >
                      {t<string>("PROFILE.ISSUED")}
                    </span>
                    <label className="inputs-miscount__box-field-leasing">
                    <input
                      disabled={formikForm.values.car === "checked"}
                      className={classNames({
                        "inputs-miscount__box-field-leasing-inp": true,
                        "inputs-miscount__box-field-leasing": true,
                      })}
                      value={formikForm.values.car_year}
                      type="text"
                      placeholder={t<string>("PROFILE.ISSUED")}
                      onChange={(event) => {
                        let min = 1900;
                        let max = new Date().getFullYear() + 2;

                        if (
                          +event.target.value <= 0 ||
                          Number.isNaN(+event.target.value)
                        ) {
                          formikForm.setFieldValue("car_year", "");
                        } else {
                          if (
                            (event.target.value.length === 4 ||
                              event.target.value.length > 4) &&
                            +event.target.value > max
                          ) {
                            formikForm.setFieldValue("car_year", max);
                          } else if (
                            (event.target.value.length === 4 ||
                              event.target.value.length > 4) &&
                            +event.target.value < min
                          ) {
                            formikForm.setFieldValue("car_year", min);
                          } else {
                            formikForm.setFieldValue(
                              "car_year",
                              +event.target.value
                            );
                          }
                        }
                      }}
                    />
                    </label>
                  </div>
                  {
                    formikForm.errors.car_year && (
                      <div className="form-control-error">
                        {t<string>("CALCULATOR.YEAR_IS_REQUIRED")}
                      </div>
                  )}
                </div>
                <div>
                  <div
                    className={classNames({
                      "inputs-miscount__box": true,
                    })}
                  >
                    <span
                      className={classNames({
                        "inputs-miscount__box-name": true,
                        "about-box__field-name-filled":
                          formikForm.values.link_to_advert,
                        "about-box__field-inp-error":
                          formikForm.errors.link_to_advert,
                      })}
                    >
                      {t<string>("PROFILE.ADVERT_LINK")}
                    </span>
                    <label
                      className={classNames({
                        "inputs-miscount__box-field-leasing": true,
                        "inputs-miscount__box-field-leasing-link":
                          formikForm.values.type === "5" && true,
                      })}
                    >
                      <input
                        className={classNames({
                          "inputs-miscount__box-field-leasing-inp": true,
                          "inputs-miscount__box-field-leasing-link":
                            formikForm.values.type === "5" && true,
                          "inputs-miscount__box-field-leasing": true,
                        })}
                        type="text"
                        placeholder="https://www.car.com"
                        {...formikForm.getFieldProps("link_to_advert")}
                      />
                    </label>
                  </div>
                  {
                      formikForm.errors.link_to_advert && (
                        <div className="form-control-error">
                          {t<string>("CALCULATOR.LINK_IS_REQUIRED")}
                      </div>
                  )}
                  </div>
                  <div className="car__loans-checked">
                    <input
                      onChange={(event) => {
                        formikForm.setFieldValue(
                          "checked",
                          !event.target.checked
                        );
                      }}
                      type="checkbox"
                      name="check_carLoans"
                      id=""
                    />
                    <span className="about-bottom-text">{t<string>("PROFILE.NOT_CHOSEN_YET")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="about__form">
        <h2 className="about__title">{t<string>("PROFILE.INTRODUCTION")}</h2>
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.first_name,
                "about-box__field-inp-error":
                  formikForm.touched.first_name && formikForm.errors.first_name,
              })}
            >
              {t<string>("PROFILE.FIRST_NAME")}
            </span>
            <input
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-inp-filled": formikForm.values.first_name,
                "about-box__field-inp-touched":
                  formikForm.touched.first_name && formikForm.errors.first_name,
              })}
              placeholder={t<string>("PROFILE.FIRST_NAME_PLACEHOLDER")}
              {...formikForm.getFieldProps("first_name")}
            />
          </label>
        </div>
        {formikForm.touched.first_name && formikForm.errors.first_name && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.first_name)}
          </div>
        )}
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.last_name,
                "about-box__field-inp-error":
                  formikForm.touched.last_name && formikForm.errors.last_name,
              })}
            >
              {t<string>("PROFILE.LAST_NAME")}
            </span>
            <input
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-inp-filled": formikForm.values.last_name,
                "about-box__field-inp-touched":
                  formikForm.touched.last_name && formikForm.errors.last_name,
              })}
              placeholder={t<string>("PROFILE.LAST_NAME_PLACEHOLDER")}
              {...formikForm.getFieldProps("last_name")}
            />
          </label>
        </div>
        {formikForm.touched.last_name && formikForm.errors.last_name && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.last_name)}
          </div>
        )}
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.phone,
                "about-box__field-inp-error":
                  formikForm.touched.phone && formikForm.errors.phone,
              })}
            >
              {t<string>("PROFILE.PHONE_NUMBER")}
            </span>
            <InputMask
              name="phone"
              className={classNames(
                "data-input__input-field data-input__input-field--box",
                {
                  "form-control": true,
                  "form-control-touched":
                    formikForm.touched.phone && formikForm.errors.phone,
                }
              )}
              maskChar=" "
              mask="+35\9 (999) 999-9999"
              placeholder="+359 (___) ___-___ "
              value={formikForm.values.phone}
              onChange={(event) => {
                formikForm.setFieldValue(
                  "phone",
                  filterPhoneNum(event.target.value)
                );
              }}
            />
          </label>
        </div>
        {formikForm.touched.phone && formikForm.errors.phone && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.phone)}
          </div>
        )}
        <div className="about__form-box about-box">
          <label className="about-box__field about-box__field-email">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.email,
                "about-box__field-inp-error":
                  formikForm.touched.email && formikForm.errors.email,
              })}
            >
              {t<string>("PROFILE.EMAIL")}
            </span>
            <input
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-inp-filled": formikForm.values.email,
                "about-box__field-inp-touched":
                  formikForm.touched.email && formikForm.errors.email,
              })}
              placeholder={t<string>("PROFILE.EMAIL")}
              {...formikForm.getFieldProps("email")}
            />
            <div className="about-box__email-validation">
              {currentUser?.verified ? (
                <img src={verified} alt="verified" />
              ) : (
                <img src={nonVerified} alt="not verified" />
              )}
              <div className="profile-link__user-status-txt">
                {currentUser?.verified ? (
                  <span className={currentUser?.verified && "verified"}>
                    {t<string>("COMMON.VERIFIED")}
                  </span>
                ) : (
                  <span className="not_verified">
                    {t<string>("COMMON.NOT_VERIFIED")}
                  </span>
                )}
              </div>
            </div>
          </label>
        </div>
        {!currentUser?.verified && (
          <div className="about__form-bottom">
            <p className="about__form-bottom-text">
              {t<string>('UPDATE_USER.CHECK_EMAIL')}
            </p>
            <span
              className="about__form-bottom-link"
              onClick={() => emailVerification(formikForm.values.email)}
            >
              {t<string>('UPDATE_USER.RESENT_EMAIL')}
            </span>
          </div>
        )}

        {formikForm.touched.phone && formikForm.errors.phone && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.phone)}
          </div>
        )}
        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.gender,
                "about-box__field-inp-error":
                  formikForm.touched.gender && formikForm.errors.gender,
              })}
            >
              {t<string>("PROFILE.GENDER")}
            </span>
            <select
              className={classNames({
                "about-box__field-inp": true,
                "about-box__field-select": true,
                "about-box__field-inp-filled": formikForm.values.gender,
                "about-box__field-inp-touched":
                  formikForm.touched.gender && formikForm.errors.gender,
              })}
              {...formikForm.getFieldProps("gender")}
            >
              <option disabled hidden value="">
                {t<string>("PROFILE.PLEASE_SELECT")}
              </option>
              <option value="0">{t<string>("PROFILE.MALE")}</option>
              <option value="1">{t<string>("PROFILE.FEMALE")}</option>
            </select>
          </label>
        </div>
        {formikForm.touched.gender && formikForm.errors.gender && (
          <div className="form-control-error">
            {t<string>(formikForm.errors.gender)}
          </div>
        )}

        <div className="about__form-box about-box">
          <label className="about-box__field">
            <span
              className={classNames({
                "about-box__field-name": true,
                "about-box__field-name-filled": formikForm.values.date_of_birth,
                "about-box__field-inp-error":
                  formikForm.touched.date_of_birth &&
                  formikForm.errors.date_of_birth,
              })}
            >
              {t<string>("PROFILE.DATE_OF_BIRTH")}
            </span>
            <DatePicker
              className={classNames(
                {
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled":
                    formikForm.values.date_of_birth,
                  "about-box__field-inp-touched":
                    formikForm.touched.date_of_birth &&
                    formikForm.errors.date_of_birth,
                },
                {
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.age,
                  "about-box__field-inp-touched":
                    formikForm.touched.age && formikForm.errors.age,
                }
              )}
              format="dd/MM/y"
              dayPlaceholder={t<string>("COMMON.CALENDAR_DAY")}
              monthPlaceholder={t<string>("COMMON.CALENDAR_MONTH")}
              yearPlaceholder={t<string>("COMMON.CALENDAR_YEAR")}
              locale={appLanguage}
              maxDate={getPreviousDay()}
              minDate={new Date(new Date("1900-01-01").getTime())}
              onChange={(e: any) => {
                if (e) {
                  formikForm.setFieldValue(
                    "date_of_birth",
                    `${e.getFullYear()}-${
                      Number(e.getMonth() + 1) < 10
                        ? "0" + `${e.getMonth() + 1}`
                        : e.getMonth() + 1
                    }-${
                      Number(e.getDate()) < 10
                        ? "0" + `${e.getDate()}`
                        : e.getDate()
                    }`
                  );
                }
              }}
              value={
                formikForm.values.date_of_birth
                  ? new Date(
                      new Date(formikForm.values.date_of_birth).getTime()
                    )
                  : undefined
              }
              calendarIcon={<CalendarIcon />}
            />
            {formikForm.touched.date_of_birth &&
              formikForm.errors.date_of_birth && (
                <div className="form-control-error">
                  {t<string>(formikForm.errors.date_of_birth)}
                </div>
              )}
            {formikForm.touched.age && formikForm.errors.age && (
              <div className="form-control-error">
                {t<string>(formikForm.errors.age)}
              </div>
            )}
          </label>
        </div>
      </div>

      {formikForm.values.first_name &&
        formikForm.values.last_name &&
        formikForm.values.phone &&
        formikForm.values.gender &&
        formikForm.values.date_of_birth && (
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("PROFILE.PASSPORT_INFO")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.citizenship &&
                      formikForm.errors.citizenship,
                  })}
                >
                  {t<string>("PROFILE.CITIZENSHIP")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.citizenship,
                    "about-box__field-inp-touched":
                      formikForm.touched.citizenship &&
                      formikForm.errors.citizenship,
                  })}
                  {...formikForm.getFieldProps("citizenship")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">
                    {t<string>("COUNTRY.BULGARIA")[0] +
                      t<string>("COUNTRY.BULGARIA").slice(1).toLowerCase()}
                  </option>
                  <option value="1">{t<string>("PROFILE.OTHER")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched.citizenship &&
              formikForm.errors.citizenship && (
                <div className="form-control-error">
                  {t<string>(formikForm.errors.citizenship)}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      !egnLengthValidator() ||
                      (formikForm.values.personal_code.length === 0 &&
                        formikForm.values.egn && formikForm.touched.personal_code),
                  })}
                >
                  {t<string>("UPDATE_USER.PERSONAL_IDENTIFICATION_CODE")}
                </span>
                <input
                  disabled={formikForm.values.egn} 
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.personal_code,
                    "about-box__field-inp-touched":
                    (formikForm.values.personal_code.length === 0 &&
                      !formikForm.values.egn && formikForm.touched.personal_code && formikForm.errors.personal_code) || 
                      !egnLengthValidator(),
                  })}
                  placeholder="1002498384"
                  type="text"
                  value={formikForm.values.personal_code}
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("personal_code", "");
                    } else {
                      if (event.target.value.length > 10) {
                        formikForm.setFieldValue("personal_code", prevEgn);
                      } else {
                        formikForm.setFieldValue(
                          "personal_code",
                          event.target.value
                        );
                      }
                    }
                    egnLengthValidator();
                  }}
                />
              </label>
              <label className="about-box__egn">
                <input
                  checked={formikForm.values.egn}
                  type="checkbox"
                  onChange={() => {
                    if (formikForm.values.egn !== true) {
                      formikForm.setFieldValue("egn", true);
                      formikForm.setFieldValue("personal_code", "");
                    } else {
                      formikForm.setFieldValue("egn", false);
                    }
                  }}
                />
                {t<string>("UPDATE_USER.NO_EGN")}
              </label>
            </div>
            {!egnLengthValidator() && (
              <div className="form-control-error">
                {t<string>("PROFILE.ADD_EGN")} 
                {/* Поменял местами текста ошибок EGN_RULE и ADD_ENG. EGN_RULE пока не отрабатывает*/}
              </div>
            )}
            {!formikForm.values.egn &&
              formikForm.touched.egn &&
              formikForm.touched.personal_code &&
              formikForm.errors.personal_code && (
                <div className="form-control-error">
                  {t<string>("UPDATE_QUESTIONNAIRE.EGN_RULE")}
                </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                    touchedPassport && formikForm.values.passport.length <= 8
                  })}
                >
                  {t<string>("PROFILE.PASSPORT_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.passport,
                    "about-box__field-inp-touched": touchedPassport && formikForm.values.passport.length <= 8 
                  })}
                  placeholder="102498384"
                  type="text"
                  value={formikForm.values.passport}
                  onChange={(event) => {
                    if (
                      +event.target.value < 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("passport", "");
                    } else {
                      if (event.target.value.length > 9) {
                        formikForm.setFieldValue("passport", prevPassport);
                        setTouchedPassport(false)
                      } else {
                        setTouchedPassport(true)
                        formikForm.setFieldValue(
                          "passport",
                          event.target.value,
                        );
                      }
                    }
                  }}
                />
              </label>
            </div>
            {touchedPassport && formikForm.values.passport.length <= 8 && (
              <div className="form-control-error">
                {t<string>(String(formikForm.errors.passport))}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.authority,
                    "about-box__field-inp-error":
                      formikForm.touched.authority &&
                      formikForm.errors.authority,
                  })}
                >
                  {t<string>("PROFILE.AUTHORITY")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.authority,
                    "about-box__field-inp-touched":
                      formikForm.touched.authority &&
                      formikForm.errors.authority,
                  })}
                  placeholder={t<string>("PROFILE.AUTHORITY_PLACEHOLDER")}
                  {...formikForm.getFieldProps("authority")}
                />
              </label>
            </div>
            {formikForm.touched.authority && formikForm.errors.authority && (
              <div className="form-control-error">
                {t<string>(formikForm.errors.authority)}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.date_of_issue,
                    "about-box__field-inp-error":
                      formikForm.touched.date_of_issue &&
                      formikForm.errors.date_of_issue,
                  })}
                >
                  {t<string>("PROFILE.DATE_OF_ISSUE")}
                </span>
                <DatePicker
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.date_of_issue,
                    "about-box__field-inp-touched":
                      formikForm.touched.date_of_issue &&
                      formikForm.errors.date_of_issue,
                  })}
                  format="dd/MM/y"
                  dayPlaceholder={t<string>("COMMON.CALENDAR_DAY")}
                  monthPlaceholder={t<string>("COMMON.CALENDAR_MONTH")}
                  yearPlaceholder={t<string>("COMMON.CALENDAR_YEAR")}
                  locale={appLanguage}
                  maxDate={getPreviousDay()}
                  minDate={new Date(new Date("1900-01-01").getTime())}
                  onChange={(e: any) => {
                    if (e) {
                      formikForm.setFieldValue(
                        "date_of_issue",
                        `${e.getFullYear()}-${
                          Number(e.getMonth() + 1) < 10
                            ? "0" + `${e.getMonth() + 1}`
                            : e.getMonth() + 1
                        }-${
                          Number(e.getDate()) < 10
                            ? "0" + `${e.getDate()}`
                            : e.getDate()
                        }`
                      );
                    }
                  }}
                  value={
                    formikForm.values.date_of_issue
                      ? new Date(
                          new Date(formikForm.values.date_of_issue).getTime()
                        )
                      : undefined
                  }
                  calendarIcon={<CalendarIcon />}
                />
                {formikForm.touched.date_of_issue &&
                  formikForm.errors.date_of_issue && (
                    <div className="form-control-error">
                      {t<string>(formikForm.errors.date_of_issue)}
                    </div>
                  )}
              </label>
            </div>
          </div>
        )}

      <button
        className={classNames({
          about__submit: true,
          "about__submit-active":
            FormValidator() &&
            amountValidation() &&
            (termValidation() || formikForm.values.type === "7") &&
            typeValidation()
            &&
            egnLengthValidator() &&
            egnValidator(),
        })}
        type={
          FormValidator() &&
          amountValidation() &&
          (termValidation() || formikForm.values.type === "7") &&
          typeValidation()
          &&
          egnLengthValidator() &&
          egnValidator()
          ? "submit"
          : "button"
        }
      >
        {t<string>("COMMON.NEXT")}
      </button>
      <p className="about__safe">{t<string>("PROFILE.SAFETY")}</p>
    </form>
  );
};

export default FirstStep;
