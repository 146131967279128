import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../../App";
import { FaPen } from "react-icons/fa";
import PrivateService from '../../../../core/services/private.service';
import { toast } from "react-toastify";

interface ShowCommentModalProps{
  reviewId: string
}
const ShowCommentModal = ({reviewId}: ShowCommentModalProps) => {
  const appContext = useContext(AppContext);
  const { comments } = appContext;
  const { setComments } = useContext(AppContext)
  const [isEditing, setIsEditing ] = useState<boolean>(false)
  

  const handleSave = async () =>{
    setIsEditing(false)
    try {
      const response = await PrivateService.updateComment(
        {
          comment: comments
        },
        reviewId,
      )
      toast.success(response?.data?.message);
    }
    catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
    setIsEditing(false)
    // updateComment
  }

  useMemo(() => {
    setIsEditing(false)
  }, [reviewId])

  return (
    <>
      {
        isEditing ?
        <div className="modal__container modal-logout modal-logout__edit">
          <textarea value={comments} onChange={(e) => setComments(e.target.value)}/>
          <button onClick={handleSave}>save</button>
        </div>
        :
        <div className="modal__container modal-logout modal-logout__edit">
          <div className="admin-reviews-comment">
            <p>{comments ? comments : "There is no comment"}</p>
            <button onClick={() => setIsEditing(!isEditing)}>
              <FaPen />
            </button> 
          </div>
        </div>
      }
    </>
  );
};

export default ShowCommentModal;
