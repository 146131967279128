import { useTranslation } from "react-i18next";
import credits_container__banner from "../../../../../assets/photos/credits/credits_container__banner.svg";
import credits_container__banner__mobile from "../../../../../assets/photos/credits/credits_container__banner__mobile.svg";
import { useNavigate } from "react-router-dom";

type BannersProps = {
  icon: any;
  title: string;
  subtitle: string;
  bankTitle: string;
};

const Banners = ({ icon, subtitle, title, bankTitle }: BannersProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault(); 
  };
  
  const handleRedirect = () =>{
    localStorage.setItem(
      "calcResult",
      JSON.stringify({
        "amount_1":"10000","term_1":"36","type":"1"
      })
    );
    navigate("/result-list")
  }

  return (
    <div className="banner-section__banner-content banner-content">
      <div className="banner-content-txt">
        <div className="banner-content-txt-icon">
          <img src={icon} alt="fiBank" />
          <span>{bankTitle}</span>
        </div>
        <div className="banner-content-txt-main">
          <h1 className="banner-content-txt-main-title">{t<string>(title)}</h1>
          <button className="banner-content-txt-main-btn"
            onClick={(e)=>{
              handleButtonClick(e)
              handleRedirect()
            }}>
            {t<string>("FINANCE.BUTTON_NAVIGATE")}
          </button>
        </div>
      </div>
      <img
        className="banner-content-bg"
        src={credits_container__banner}
        alt="credits_container__banner"
      />
      <img
        className="banner-content-mobile"
        src={credits_container__banner__mobile}
        alt="credits_container__banner"
      />
    </div>
  );
};

export default Banners;
