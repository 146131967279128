import CreditsMortgageBanner from "./CreditsMortgageBanner";
import CreditsMortgageInfo from "./CreditsMortgageInfo";

const CreditsMortgage = () => {
  return (
    <div id="Mortgage loans" className="credits-mortgage">
      <CreditsMortgageBanner />
      <div className="credits-container-main-content-consumer">
        <CreditsMortgageInfo />
      </div>
    </div>
  );
};

export default CreditsMortgage;
