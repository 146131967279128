import {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import Logo from "../../assets/photos/all/Illustration_redirect.svg"
import i18n from '../../i18nextConf'
import { setAppLanguage } from '../../core/store/reducers/app/appDataSlice'
import { useAppDispatch, useAppSelector } from '../../core/store'
import divider from "../../assets/photos/all/lang-divider.svg";
import { useTranslation } from 'react-i18next'


const TimerPage = () => {
  const [timer, setTimer] = useState(10);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { appLanguage, notifications } = useAppSelector((state: { app: any }) => state.app);

  useEffect(() => {
    let lng = sessionStorage.getItem("lng");

    dispatch(setAppLanguage(lng || "bg"));

  }, []);

  const setLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    sessionStorage.setItem("lng", lng);
    dispatch(setAppLanguage(lng));
  };

  useEffect(() => {
    const interval = setInterval(() => setTimer(prevState => {
    if (prevState > 0){
      return prevState - 1
    } else{
      navigate("/home")
      return prevState
    }
    }), 1000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <div className="verify">
      <header className="verify__header">
        <Link to={`/`}>
          <img
            className="verify__header-logo"
            src={require(`../../${process.env.REACT_APP_LOGO_NAME}`)}
            alt="assistent logo"
          />
        </Link>
        <div className="nav__options-lang lang">
          <button
            className={classNames({
              lang__btn: true,
              "lang__btn-selected": appLanguage === "bg",
            })}
            onClick={() => setLanguage("bg")}
          >
            {t<string>("COMMON.BG")}
          </button>
          <img className="lang__line" src={divider} alt="divider"></img>
          <button
            className={classNames({
              lang__btn: true,
              "lang__btn-selected": appLanguage === "en",
            })}
            onClick={() => setLanguage("en")}
          >
            {t<string>("COMMON.EN")}
          </button>
        </div>
      </header>
      <main className="verify__main">
        <div className="verify__main-img">
          <img src={Logo} alt="logo" />
        </div>
        <h3 className="verify__main-title">{t<string>("COMMON.VERIFY_TITLE")}
           {timer} {t<string>("COMMON.VERIFY_TITLE2")}
        </h3>
        <p className="verify__main-descr">{t<string>("COMMON.VERIFY_DESCR")}</p>
        <Link to="/home" className="verify__main-link">{t<string>("COMMON.VERIFY_BUTTON")}</Link>
      </main>
      <footer className="verify__footer">
        <span>© Asistent 2022</span>
        <span>All rights reserved</span>
      </footer>
    </div>
  )
}

export default TimerPage

function dispatch(arg0: any) {
  throw new Error('Function not implemented.')
}
