import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "./core/router/AppRouter";
import "./i18nextConf";
import { GoogleOAuthProvider } from "@react-oauth/google";
import i18n from "./i18nextConf";

import "./assets/index.scss";

import { AuthSelectionType } from "./core/services/enums";
import { useAppDispatch } from "./core/store";
import { setAppLanguage } from "./core/store/reducers/app/appDataSlice";
import { initializeFacebookSdk } from "./core/functions/funtions";

export enum ProfileListCategory {
  Questionnaires = "questionnaires",
  Reviews = "reviews",
  Users = "users",
  AdminQuestionnaires = "admin-questionnaires",
  AdminOffers = "admin-offers",
  AdminReviews = "admin-reviews",
  AdminBlogs = "admin-blogs",
  AdminNews = "admin-news",
  AdminBanks = "admin-banks",
  AdminCreditOffers = "admin-credit-offers",
  AdminPanel = "admin-panel",
}

export const AppContext = React.createContext({
  calculationExample: 1,
  selectedLoan: 1,
  reloadChecker: false,
  selectionType: AuthSelectionType.Login,
  profileCategory: ProfileListCategory.Questionnaires,
  comments: "",
  allComments: [],
  adminCommentId: 0,
  reviewId: '',
  setReviewId: (id: string) => {},
  setAdminCommentId: (id: number) => {},
  setCalculationExample: (calculationExample: number) => {},
  setSelectedLoan: (selectedLoan: number) => {},
  setReloadChecker: (bool: boolean) => {},
  setSelectionType: (type: AuthSelectionType) => {},
  setProfileCategory: (type: ProfileListCategory) => {},
  setComments: (com: string) => {},
  setAllComments: (com: []) => {},
});

function App() {
  const [calculationExample, setCalculationExample] = useState(1);
  const [selectedLoan, setSelectedLoan] = useState(1);
  const [reloadChecker, setReloadChecker] = useState(false);
  const [selectionType, setSelectionType] = useState(AuthSelectionType.Login);
  const [profileCategory, setProfileCategory] = useState(
    ProfileListCategory.Questionnaires
  );
  const [comments, setComments] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [adminCommentId, setAdminCommentId] = useState(0);
  const [reviewId, setReviewId] = useState('')
  const dispatch = useAppDispatch();

  useEffect(() => {
    initializeFacebookSdk();
  }, []);

  if (!sessionStorage.getItem("lng")) {
    sessionStorage.setItem("lng", "bg");
    i18n.changeLanguage("bg");
    dispatch(setAppLanguage("bg"));
  }

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_KEY}>
        <AppContext.Provider
          value={{
            adminCommentId,
            comments,
            calculationExample,
            allComments,
            reloadChecker,
            profileCategory,
            setAllComments,
            setAdminCommentId,
            reviewId,
            setReviewId,
            setComments,
            selectionType,
            selectedLoan,
            setReloadChecker,
            setSelectionType,
            setSelectedLoan,
            setProfileCategory,
            setCalculationExample,
          }}
        >
          <AppRouter />
        </AppContext.Provider>
      </GoogleOAuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div id="recaptcha-container"></div>
    </>
  );
}

export default App;
