import React, { FC } from "react";
import ContactUsForm from "./ContactUsForm";

const ContactUsContainer: FC = () => {
  return (
    <div className="public">
      <ContactUsForm />
    </div>
  );
};

export default ContactUsContainer;
