import { useState } from "react";
import classNames from "classnames";

import AdminBannerReviews from "./AdminBannerReviews";
import AdminBannerServices from "./AdminBannerServices";
import AdminBannerStudy from "./AdminBannerStudy";
import { useTranslation } from "react-i18next";
import ButtonExcel from "../ButtonExcel/ButtonExcel";

const AdminBanners = () => {
  const [selectBlock, setSelectBlock] = useState<number>(0);

  const { t } = useTranslation();

  return (
    <div className="admin">
      <h2 className="admin-table__name">{t<string>("BANNERS.TITLE")}</h2>
      <ButtonExcel url='admin/banners_export'/>
      <div className="admin-container">
        <div className="profile-table">
          <div className="admin-blog">
            <div className="admin-banners-container">
              <button
                onClick={() => setSelectBlock(0)}
                className={classNames({
                  "admin-blog-container__btn": true,
                  "admin-blog-container__btn-selected": selectBlock === 0,
                  "admin-blog-container__btn-disable": selectBlock !== 0,
                })}
              >
                {t<string>("BANNERS.REVIEWS")}
              </button>
              <button
                onClick={() => setSelectBlock(1)}
                className={classNames({
                  "admin-blog-container__btn": true,
                  "admin-blog-container__btn-selected": selectBlock === 1,
                  "admin-blog-container__btn-disable": selectBlock !== 1,
                })}
              >
                {t<string>("BANNERS.SERVICES")}
              </button>
              <button
                onClick={() => setSelectBlock(2)}
                className={classNames({
                  "admin-blog-container__btn": true,
                  "admin-blog-container__btn-selected": selectBlock === 2,
                  "admin-blog-container__btn-disable": selectBlock !== 2,
                })}
              >
                {t<string>("BANNERS.STUDY")}
              </button>
            </div>
            {selectBlock === 0 && <AdminBannerReviews />}
            {selectBlock === 1 && <AdminBannerServices />}
            {selectBlock === 2 && <AdminBannerStudy />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBanners;
