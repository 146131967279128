import React, { useState } from "react";

import arrow_right_gray from "../../../assets/photos/all/arrow_right_gray.svg";
import merging_main from "../../../assets/photos/all/merging_main.svg";
import merging_secondary from "../../../assets/photos/all/merging_secondary.svg";
import merging_mobile from "../../../assets/photos/all/merging_mobile.svg";
import { useTranslation } from "react-i18next";

const MergingLoansBanner = () => {
  const [showMore, setShowMore] = useState<boolean | undefined>(false);
  const {t} = useTranslation()
  const text = `Pay less on loans. Combine your loans in a bank convenient 
for you at a favorable interest rate. It is profitable and the total savings can reach several thousand euros`;

  return (
    <div className="results-banner merging-banner">
      <div className="results-banner-content-merging ">
        <div className="results-banner-content-merging__nav-top nav-top">
          <span className="nav-top__first">Home</span>
          <img
            className="nav-top__arrow"
            src={arrow_right_gray}
            alt="arrow_right_gray"
            width="12px"
            height="12px"
          />
          <span className="nav-top__second">{t<string>("LOANS.MERGING_LOANS")}</span>
        </div>
        <div className="results-banner-content__main-content main-content">
          <h1 className="main-content__header">{t<string>("LOANS.MERGING_LOANS")}</h1>
          <p className="main-content__banner-desc banner-desc">
            {t<string>("RESULTS.MERGING_TITLE.LINE_1")}
            <br />
            {t<string>("RESULTS.MERGING_TITLE.LINE_2")}{" "}
            <br /> 
            {t<string>("RESULTS.MERGING_TITLE.LINE_3")}
          </p>
          <p className="main-content__banner-desc-hide banner-desc-hide">
            {showMore ? text : `${text.substring(0, 43)}...`}
            <button
              className="banner-desc-hide__btn"
              onClick={() => setShowMore((prev) => !prev)}
            >
              {showMore ? "Less" : "More"}
            </button>
          </p>
        </div>
      </div>
      <div className="results-banner-merging">
        <img
          className="results-banner-merging__mobile"
          src={merging_mobile}
          alt="merging_mobile"
        />

        <img
          className="results-banner-merging__secondary"
          src={merging_secondary}
          alt="merging_secondary"
        />
        <img
          className="results-banner-merging__main"
          src={merging_main}
          alt="merging_main"
        />
      </div>
    </div>
  );
};

export default MergingLoansBanner;
