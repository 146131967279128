import { FC } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { toast } from "react-toastify";

import PublicService from "../../../core/services/public.service";
import { useAppSelector } from "../../../core/store";

import contact_us_first from "../../../assets/photos/all/contact_us_first.svg";
import contact_us_second from "../../../assets/photos/all/contact_us_second.svg";
import contact_us_third from "../../../assets/photos/all/contact_us_third.svg";
import { useTranslation } from "react-i18next";

const ContactUsForm: FC = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  const valueFormValidationSchema = Yup.object().shape({
    full_name: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(121, "Max 121 symbols"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email")
      .max(121, "Max 121 symbols"),
    subject: Yup.string()
      .required("Subject is required")
      .max(121, "Max 121 symbols"),
    message: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(300, "Max 300 symbols"),
  });

  const formikForm = useFormik<{
    full_name: string;
    email: string;
    subject: string;
    message: string;
  }>({
    initialValues: {
      full_name: "",
      email: currentUser ? currentUser.email : "",
      subject: "",
      message: "",
    },

    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    try {
      const response = await PublicService.contactUs(values);

      toast.success(response?.data?.message);
      formikForm.resetForm();
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
  };

  return (
    <div className="contact">
      <div className="contact-us-container">
        <div className="contact-us-container-main">
          <p className="contact-us-container-main__subtitle">
            {t<string>("CONTACT_FORM.TITLE")}
          </p>
          <h1 className="contact-us-container-main__title">
            {t<string>("CONTACT_FORM.BIG")}
          </h1>
        </div>
        <div className="contact-form">
          <form className="about__form" onSubmit={formikForm.handleSubmit}>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.full_name,
                    "about-box__field-inp-error":
                      formikForm.touched.full_name &&
                      formikForm.errors.full_name,
                  })}
                >
                  {t<string>("CONTACT_FORM.FULL_NAME")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.full_name,
                    "about-box__field-inp-touched":
                      formikForm.touched.full_name &&
                      formikForm.errors.full_name,
                  })}
                  placeholder={t<string>("CONTACT_FORM.FULL_NAME")}
                  {...formikForm.getFieldProps("full_name")}
                />
              </label>
            </div>
            {formikForm.touched.full_name && formikForm.errors.full_name && (
              <div className="form-control-error">
                {t<string>(formikForm.errors.full_name)}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.email,
                    "about-box__field-inp-error":
                      formikForm.touched.email && formikForm.errors.email,
                  })}
                >
                  {t<string>("CONTACT_FORM.EMAIL")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.email,
                    "about-box__field-inp-touched":
                      formikForm.touched.email && formikForm.errors.email,
                  })}
                  placeholder={t<string>("CONTACT_FORM.EMAIL")}
                  {...formikForm.getFieldProps("email")}
                />
              </label>
            </div>
            {formikForm.touched.email && formikForm.errors.email && (
              <div className="form-control-error">
                {t<string>(formikForm.errors.email)}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.subject,
                    "about-box__field-inp-error":
                      formikForm.touched.subject && formikForm.errors.subject,
                  })}
                >
                  {t<string>("CONTACT_FORM.SUBJECT")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.subject,
                    "about-box__field-inp-touched":
                      formikForm.touched.subject && formikForm.errors.subject,
                  })}
                  placeholder={t<string>("CONTACT_FORM.SUBJECT_PH")}
                  {...formikForm.getFieldProps("subject")}
                />
              </label>
            </div>
            {formikForm.touched.subject && formikForm.errors.subject && (
              <div className="form-control-error">
                {t<string>(formikForm.errors.subject)}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.message,
                    "about-box__field-inp-error":
                      formikForm.touched.message && formikForm.errors.message,
                  })}
                >
                  {t<string>("CONTACT_FORM.MESSAGE")}
                </span>
                <textarea
                  className={classNames({
                    "about-box__field-textarea": true,
                    "about-box__field-inp-filled": formikForm.values.message,
                    "about-box__field-inp-touched":
                      formikForm.touched.message && formikForm.errors.message,
                  })}
                  placeholder= {t<string>("CONTACT_FORM.MESSAGE_PH")}
                  {...formikForm.getFieldProps("message")}
                ></textarea>
              </label>
            </div>
            {formikForm.touched.message && formikForm.errors.message && (
              <div className="form-control-error">
                {t<string>(formikForm.errors.message)}
              </div>
            )}
            <button
              className="about__submit about__submit-active"
              type="submit"
            >
              {t<string>("CONTACT_FORM.SEND_BUTTON")}
            </button>
          </form>
        </div>
        <div className="contact-us-container__cards-list cards-list">
          <div className="cards-list__item">
            <img src={contact_us_first} alt="subject number" />
            <h4 className="cards-list__item-title">{t<string>("CONTACT_FORM.BANK_PHONE_TXT")}</h4>
            <p className="cards-list__item-content">
            {t<string>("CONTACT_FORM.BANK_PHONE_NUMBER")}
            </p>
          </div>
          <div className="cards-list__item">
            <img src={contact_us_second} alt="Location" />
            <h4 className="cards-list__item-title">{t<string>("CONTACT_FORM.OFFICE")}</h4>
            <p className="cards-list__item-content">
              {t<string>("CONTACT_FORM.LOCATION")}
            </p>
          </div>
          <div className="cards-list__item">
            <img src={contact_us_third} alt="E-mail" />
            <h4 className="cards-list__item-title">{t<string>("CONTACT_FORM.EMAIL")}</h4>
            <p className="cards-list__item-content">info@asistent.bg</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
