import React, { FC } from "react";
import BankFeedbackForm from "./BankFeedbackForm";

const BankFeedbackContainer: FC = () => {
  return (
    <div className="public">
      <BankFeedbackForm />
    </div>
  );
};

export default BankFeedbackContainer;
