import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import PrivateService from "../../../../core/services/private.service";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { PrivateUIRoutes, UIRoutes } from "../../../../core/router";
import { Private } from "../../../../core/models";

const CreditsForm: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { id } = useParams();

  const navigate = useNavigate();

  useHttpGet<any>(`${APIRoutes.USER_CREDITS}/${id}`, {
    condition: !!id,
    resolve: (response: any) => {
      formikForm.setValues(response.payload);
    },
  });

  const errorMessage = !!error ? error : null;

  const valueFormValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    last_name: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    phone: Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .min(10, "Min 10 characters")
      .max(15, "Max 15 characters"),
    ucn: Yup.string()
      .matches(/^[0-9]{10}$/, "Must be exactly 10 digits")
      .required(`${t<string>("COMMON.IS_REQUIRED")}`),
    income: Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(10, "Max 10 characters"),
    default: Yup.number(),
    position: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    position_other: Yup.string(),
    company: Yup.string(),
    company_id: Yup.string(),
    work_experience: Yup.string().required(
      `${t<string>("COMMON.IS_REQUIRED")}`
    ),
    official_employment: Yup.number().required(
      `${t<string>("COMMON.IS_REQUIRED")}`
    ),
    update: Yup.boolean(),
  });

  const formikForm = useFormik<Private.Credit>({
    initialValues: {
      id: 0,
      first_name: "",
      last_name: "",
      phone: "",
      ucn: 0,
      income: 0,
      default: 0,
      position: "",
      position_other: "",
      company: "",
      company_id: "",
      work_experience: 0,
      official_employment: 0,
      update: 0,
    },

    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      id ? update(values) : create(values);
    },
  });

  const create = async (values: any) => {
    setLoading(true);

    try {
      const response = await PrivateService.addCredit({
        ...values,
      });
      toast.success(response?.data?.message);
      setLoading(false);
      setError("");
      navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.CREDITS}`);
    } catch (errors: any) {
      setLoading(false);
      setError(errors?.response?.data?.errorMessages[0] || "");
      toast.error(errors?.response?.data?.message);
    }
  };

  const update = async (values: any) => {
    setLoading(true);
    try {
      const response = await PrivateService.updateCredit({
        ...values,
      });
      toast.success(response?.data?.message);
      setLoading(false);
      setError("");
      navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.CREDITS}`);
    } catch (errors: any) {
      setLoading(false);
      setError(errors?.response?.data?.errorMessages[0] || "");
      toast.error(errors?.response?.data?.message);
    }
  };

  const setDefaultValue = (value: any) => {
    formikForm.setFieldValue("default", parseInt(value));
  };

  const setUpdateValue = (value: any) => {
    formikForm.setFieldValue("update", parseInt(value));
  };

  const setOfficialEmploymentValue = (value: any) => {
    formikForm.setFieldValue("official_employment", parseInt(value));
  };

  return (
    <>
      {errorMessage}
      <form onSubmit={formikForm.handleSubmit} className="form w-100">
        <div className="form-control">
          <label className="form-label">First name</label>
          <input
            type="text"
            className="form-control"
            {...formikForm.getFieldProps("first_name")}
          />
          {formikForm.touched.first_name && formikForm.errors.first_name && (
            <div className="form-control-error">
              {formikForm.errors.first_name}
            </div>
          )}
        </div>

        <div className="form-control">
          <label className="form-label">Last name</label>
          <input
            type="text"
            className="form-control"
            {...formikForm.getFieldProps("last_name")}
          />
          {formikForm.touched.last_name && formikForm.errors.last_name && (
            <div className="form-control-error">
              {formikForm.errors.last_name}
            </div>
          )}
        </div>

        <div className="form-control">
          <label className="form-label">Phone</label>
          <input
            type="tel"
            className="form-control"
            {...formikForm.getFieldProps("phone")}
          />
          {formikForm.touched.phone && formikForm.errors.phone && (
            <div className="form-control-error">{formikForm.errors.phone}</div>
          )}
        </div>

        <div className="form-control">
          <label className="form-label">UCN</label>
          <input
            type="number"
            className="form-control"
            {...formikForm.getFieldProps("ucn")}
          />
          {formikForm.touched.ucn && formikForm.errors.ucn && (
            <div className="form-control-error">{formikForm.errors.ucn}</div>
          )}
        </div>

        <div className="form-control">
          <label className="form-label">Income</label>
          <input
            type="number"
            className="form-control"
            {...formikForm.getFieldProps("income")}
          />
          {formikForm.touched.income && formikForm.errors.income && (
            <div className="form-control-error">{formikForm.errors.income}</div>
          )}
        </div>

        <div className="form-control">
          <label className="form-label">Default</label>
          <div role="group" aria-labelledby="my-radio-group">
            <label>
              <input
                type="radio"
                name="default"
                value={1}
                checked={formikForm.values.default === 1}
                onChange={(e) => setDefaultValue(e.target.value)}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="default"
                value={0}
                checked={formikForm.values.default === 0}
                onChange={(e) => setDefaultValue(e.target.value)}
              />
              No
            </label>
          </div>
          {formikForm.touched.default && formikForm.errors.default && (
            <div className="form-control-error">
              {formikForm.errors.default}
            </div>
          )}
        </div>

        <div className="form-control">
          <label className="form-label">Position</label>
          <select
            className="form-control"
            {...formikForm.getFieldProps("position")}>
            <option disabled hidden value="">
              {t<string>("PROFILE.PLEASE_SELECT")}
            </option>
            <option value="worker">Worker</option>
            <option value="owner">Owner</option>
            <option value="other">Other</option>
          </select>
          {formikForm.touched.position && formikForm.errors.position && (
            <div className="form-control-error">
              {formikForm.errors.position}
            </div>
          )}
        </div>

        {formikForm.values.position && formikForm.values.position === "other" && (
          <div className="form-control">
            <label className="form-label">Position other</label>
            <input
              type="text"
              className="form-control"
              {...formikForm.getFieldProps("position_other")}
            />
            {formikForm.touched.position_other &&
              formikForm.errors.position_other && (
                <div className="form-control-error">
                  {formikForm.errors.position_other}
                </div>
              )}
          </div>
        )}

        {formikForm.values.position && formikForm.values.position !== "other" && (
          <>
            <div className="form-control">
              <label className="form-label">Company</label>
              <input
                type="text"
                className="form-control"
                {...formikForm.getFieldProps("company")}
              />
              {formikForm.touched.company && formikForm.errors.company && (
                <div className="form-control-error">
                  {formikForm.errors.company}
                </div>
              )}
            </div>

            <div className="form-control">
              <label className="form-label">Company ID</label>
              <input
                className="form-control"
                {...formikForm.getFieldProps("company_id")}
              />
              {formikForm.touched.company_id &&
                formikForm.errors.company_id && (
                  <div className="form-control-error">
                    {formikForm.errors.company_id}
                  </div>
                )}
            </div>
          </>
        )}

        <div className="form-control">
          <label className="form-label">Work Experience</label>
          <input
            type="range"
            max={50}
            step={0.5}
            className="form-control"
            {...formikForm.getFieldProps("work_experience")}
          />
          <p>{formikForm.values.work_experience}</p>
          {formikForm.touched.work_experience &&
            formikForm.errors.work_experience && (
              <div className="form-control-error">
                {formikForm.errors.work_experience}
              </div>
            )}
        </div>

        <div className="form-control">
          <label className="form-label">Official employment</label>
          <div role="group" aria-labelledby="my-radio-group">
            <label>
              <input
                type="radio"
                name="official_employment"
                value={1}
                checked={formikForm.values.official_employment === 1}
                onChange={(e) => setOfficialEmploymentValue(e.target.value)}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="official_employment"
                value={0}
                checked={formikForm.values.official_employment === 0}
                onChange={(e) => setOfficialEmploymentValue(e.target.value)}
              />
              No
            </label>
          </div>
          {formikForm.touched.official_employment &&
            formikForm.errors.official_employment && (
              <div className="form-control-error">
                {formikForm.errors.official_employment}
              </div>
            )}
        </div>

        <div className="form-control">
          <label className="form-label">Update</label>
          <div role="group" aria-labelledby="my-radio-group">
            <label>
              <input
                type="radio"
                name="update"
                value={1}
                checked={formikForm.values.update === 1}
                onChange={(e) => setUpdateValue(e.target.value)}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="update"
                value={0}
                checked={formikForm.values.update === 0}
                onChange={(e) => setUpdateValue(e.target.value)}
              />
              No
            </label>
          </div>
          {formikForm.touched.update && formikForm.errors.update && (
            <div className="form-control-error">{formikForm.errors.update}</div>
          )}
        </div>

        <div className="form-actions">
          <button type="submit">Save</button>
        </div>
      </form>
    </>
  );
};

export default CreditsForm;
