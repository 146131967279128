import React, { FC } from "react";
import { VerificationTypes } from "../../core/services/enums";
import AuthBase from "../AuthBase";
import VerificationForm from "./VerificationForm";

interface IProps {
  type: VerificationTypes;
}

const VerificationContainer: FC<IProps> = ({ type }) => {
  return (
    <AuthBase>
      <VerificationForm type={type} />
    </AuthBase>
  );
};

export default VerificationContainer;
