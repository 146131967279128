import React, { useContext, useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Rating } from "react-simple-star-rating";

import { AppContext } from "../../../../App";
import PrivateService from "../../../../core/services/private.service";
import CommonService from "../../../../core/services/common.service";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../core/store";
import { setModal } from "../../../../core/store/reducers/modal/modalSlice";

const NewReviewRequest = () => {
  const [ratingValue, setRatingValue] = useState(0);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const appContext = useContext(AppContext);
  const { setReloadChecker, reloadChecker } = appContext;

  const valueFormValidationSchema = Yup.object().shape({
    full_name: Yup.string()
      .max(120, "Max length 120 characters")
      .required("Full name required"),
    email: Yup.string().email("Email is invalid").required("E-mail required"),
    comment: Yup.string()
      .max(720, "Max length 720 characters")
      .required("E-mail required"),
    bank_name: Yup.string().required("Bank name required"),
  });

  const formikForm = useFormik<{
    type: string;
    full_name: string;
    email: string;
    rating: number;
    bank_name: string;
    comment: string;
  }>({
    initialValues: {
      type: "review",
      full_name: "",
      email: "",
      rating: 0,
      bank_name: "",
      comment: "",
    },

    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleRating = (val: number) => {
    setRatingValue(val);

    switch (val) {
      case 1:
        formikForm.setFieldValue("rating", 1);
        break;
      case 2:
        formikForm.setFieldValue("rating", 2);
        break;
      case 3:
        formikForm.setFieldValue("rating", 3);
        break;
      case 4:
        formikForm.setFieldValue("rating", 4);
        break;
      case 5:
        formikForm.setFieldValue("rating", 5);
        break;
      default:
        throw new Error("error!");
    }
  };

  const handleSubmitForm = async (values: any) => {
    try {
      const response = await PrivateService.addBannerRequest(values);
      formikForm.resetForm();
      setRatingValue(0);
      toast.success(response?.data?.message);
      dispatch(setModal(false));
      setReloadChecker(!reloadChecker);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
      CommonService.showErrors(errors?.response?.data?.payload);
    }
  };

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("BANNERS.CREATE_TITLE")}
        </p>
        <form className="about" onSubmit={formikForm.handleSubmit}>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.full_name,
                  "about-box__field-inp-error":
                    formikForm.touched.full_name && formikForm.errors.full_name,
                })}
              >
                {t<string>("BANNERS.FULL_NAME")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.full_name,
                  "about-box__field-inp-touched":
                    formikForm.touched.full_name && formikForm.errors.full_name,
                })}
                placeholder={t<string>("BANNERS.FULL_NAME")}
                {...formikForm.getFieldProps("full_name")}
              />
            </label>
          </div>
          {formikForm.touched.full_name && formikForm.errors.full_name && (
            <div className="form-control-error">
              {formikForm.errors.full_name}
            </div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.email,
                  "about-box__field-inp-error":
                    formikForm.touched.email && formikForm.errors.email,
                })}
              >
                {t<string>("BANNERS.EMAIL")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": formikForm.values.email,
                  "about-box__field-inp-touched":
                    formikForm.touched.email && formikForm.errors.email,
                })}
                placeholder={t<string>("BANNERS.EMAIL")}
                {...formikForm.getFieldProps("email")}
              />
            </label>
          </div>
          {formikForm.touched.email && formikForm.errors.email && (
            <div className="form-control-error">{formikForm.errors.email}</div>
          )}
          <div
            className={classNames({
              "about__form-box about-box": true,
              "feedback-inp": true,
            })}
          >
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.comment && formikForm.errors.comment,
                  "about-box__field-name-filled": formikForm.values.comment,
                })}
              >
                {t<string>("BANNERS.COMMENT")}
              </span>
              <textarea
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-preauth": true,
                  "about-box__field-preauth-comment": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.comment && formikForm.errors.comment,
                  "about-box__field-inp-filled": formikForm.values.comment,
                })}
                placeholder={t<string>("BANNERS.COMMENT")}
                {...formikForm.getFieldProps("comment")}
              />
              {formikForm.touched.comment && formikForm.errors.comment && (
                <div className="form-control-error">
                  {formikForm.errors.comment}
                </div>
              )}
            </label>
          </div>
          <div
            className={classNames({
              "about__form-box about-box": true,
              "feedback-inp": true,
            })}
          >
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-preauth-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.rating && formikForm.errors.rating,
                  "about-box__field-name-filled": formikForm.values.rating,
                })}
              >
                {t<string>("BANNERS.RATING")}
              </span>
              <Rating
                onClick={handleRating}
                size={40}
                transition
                allowTitleTag={false}
                initialValue={ratingValue}
              />
            </label>
          </div>
          <div
            className={classNames({
              "about__form-box about-box": true,
              "feedback-inp": true,
            })}
          >
            <label className="about-box__field about__form-box about-box">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.bank_name && formikForm.errors.bank_name,
                  "about-box__field-name-filled": formikForm.values.bank_name,
                })}
              >
                {t<string>("BANNERS.BANK_NAME")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "slide-step-form__bank-select": true,
                  "about-box__field-preauth": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.bank_name,
                  "about-box__field-inp-touched":
                    formikForm.touched.bank_name && formikForm.errors.bank_name,
                })}
                value={formikForm.values.bank_name}
                onChange={(e) => {
                  formikForm.setFieldValue("bank_name", e.target.value);
                }}
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="DSK Bank">ДСК Банк</option>
                <option value="UniCredit Bulbank">УниКредит Булбанк</option>
                <option value="Postbank">Пощенска банка</option>
                <option value="UBB">Обединена българска банка</option>
                <option value="KBC Bank">KBC Банк</option>
                <option value="Fibank">Fibank</option>
                <option value="Investbank">Инвестбанк</option>
                <option value="Central Cooperative Bank">
                  Централна кооперативна банка
                </option>
                <option value="Allianz Bank Bulgaria ">
                  Алианц Банк България
                </option>
                <option value="Bulgarian-American Credit Bank">
                  Българо-американска кредитна банка
                </option>
                <option value="tbi bank">tbi bank</option>
                <option value="ProCredit Bank">ПроКредит Банк</option>
                <option value="International Asset Bank">
                  Интернешънъл Асет Банк
                </option>
                <option value="D Commerce Bank">Търговска Банка Д</option>
                <option value="Bulgarian Development Bank">
                  Българската банка за развитие
                </option>
                <option value="Tokuda Bank">Токуда Банк</option>
                <option value="Texim Bank">Тексим Банк</option>
                <option value="Municipal Bank">Общинска банка</option>
              </select>
            </label>
            {formikForm.touched.bank_name && formikForm.errors.bank_name && (
              <div className="form-control-error">
                {formikForm.errors.bank_name}
              </div>
            )}
          </div>
          <button
            className={classNames({
              "about-box__field-preauth": true,
              "about__preauth-submit": true,
              "about__preauth-submit-active": true,
            })}
          >
            {t<string>("BANNERS.SAVE_BUTTON")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewReviewRequest;
