import React, { useEffect, useState } from "react";
import UpdateBlogPost from "../../../components/UpdateBlogPost";
import UpdateNewsPost from "../../../components/UpdateNewsPost";

const UpdatePost = () => {
  const [type, setType] = useState<string | null>("");

  useEffect(() => {
    const tempType = localStorage.getItem("postName");
    setType(tempType);
  }, []);

  if (type === "Blogs") {
    return <UpdateBlogPost />;
  }
  return <UpdateNewsPost />;
};

export default UpdatePost;
