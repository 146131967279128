import React, { useState } from "react";
import { profileReviews } from "../../../core/constants/profileReviews";
import { APIRoutes } from "../../../core/http";

import ReviewCard from "../ReviewsCard";
import useHttpGet from "../../../core/hooks/useHttpGet";

import spot from "../../../assets/photos/profile/reviews-empty.svg";
import { useTranslation } from "react-i18next";

const ReviewsList = () => {
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });

  const { fetchedData: reviews } = useHttpGet<any>(`${APIRoutes.USER_REVIEWS}`);

  const setPaginationPage = () => {
    setPagination(() => {
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 };
      } else {
        return { ...pagination, per_page: 50 };
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };
  const {t} = useTranslation()
  return (
    <div className="review-roster">
      {profileReviews.length <= 0 ? (
        <div className="review-roster__empty">
          <img className="review-roster__empty-image" src={spot} alt="spot" />
          <p className="review-roster__empty-txt review-roster__empty-txt-application">
            You have not yet left feedback to banks on their products
          </p>
        </div>
      ) : (
        <ul className="review-roster__list">
          {reviews?.payload?.collection.map((review: any) => (
            <li key={review.id} className="review-roster__list-item">
              <ReviewCard review={review} />
            </li>
          ))}
        </ul>
      )}
      {reviews?.payload?.cursor.has_more_pages && (
        <button
          className="bank-rating__container-btn"
          onClick={() => {
            setPaginationPage();
          }}
        >
          {t<string>("REVIEWS_TABLE.SHOW_MORE")}
        </button>
      )}
      {!reviews?.payload?.cursor.has_more_pages &&
        reviews?.payload?.collection.length > 10 && (
          <button
            onClick={setDefaultPagination}
            className="bank-rating__container-btn"
          >
            {t<string>("REVIWES_TABLE.HIDE_ALL")}
          </button>
        )}
    </div>
  );
};

export default ReviewsList;
