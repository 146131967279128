import React from "react";

import CreditsQuickLoansBanner from "./CreditsQuickLoansBanner";
import CreditsQuickLoansInfo from "./CreditsQuickLoansInfo";

const CreditsQuickLoans = () => {
  return (
    <div id="Quick loans" className="credits-quick-loans">
      <CreditsQuickLoansBanner />
      <div className="credits-container-main-content-consumer">
        <CreditsQuickLoansInfo />
      </div>
    </div>
  );
};

export default CreditsQuickLoans;
