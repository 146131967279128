import React, { FC } from "react";
import { Link } from "react-router-dom";
import QuestionnaireSteps from "./AllSteps";

const QuestionnaireContainer: FC = () => {
  return (
    <div>
      <QuestionnaireSteps />
    </div>
  );
};

export default QuestionnaireContainer;
