import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { Private } from "../../../../core/models";
import { PrivateUIRoutes, UIRoutes } from "../../../../core/router";
import PrivateService from "../../../../core/services/private.service";

const CreditsList: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastUpdatedCreditId, setLastUpdatedCreditId] = useState(0);

  const { fetchedData, loading: creditsLoading } = useHttpGet<any>(
    APIRoutes.USER_CREDITS,
    { dependencies: [lastUpdatedCreditId] }
  );

  const errorMessage = !!error ? error : null;

  const deleteCredit = async (id: number) => {
    try {
      const response = await PrivateService.deleteCredit(id);
      toast.success(response?.data?.message);
      setLastUpdatedCreditId(id);
    } catch (errors: any) {
      setLoading(false);
      toast.error(errors?.response?.data?.message);
    }
  };

  return (
    <>
      {errorMessage}
      {(creditsLoading || loading) && <span>Loading...</span>}
      <table>
        <thead>
          <tr>
            <th>UCN</th>
            <th>Income</th>
            <th>Position</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {fetchedData?.payload?.map((el: Private.Credit) => (
            <tr key={el.id}>
              <td>{el.ucn}</td>
              <td>{el.income}</td>
              <td>{el.position}</td>
              <td>
                <Link
                  to={`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.CREDITS}/${el.id}`}
                >
                  Edit
                </Link>
                <button onClick={() => deleteCredit(el.id)}>Delete</button>
              </td>
            </tr>
          ))}
          {!fetchedData?.payload && (
            <tr>
              <td>No data</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default CreditsList;
