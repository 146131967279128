import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../../App";
import CarLeasingForm from "./CarLeasingForm";
import ConsumerLoansForm from "./ConsumerLoansForm";
import MergingLoansForm from "./MergingLoansForm";
import MortgageLoansForm from "./MortgageLoansForm";
import QuickLoansForm from "./QuickLoansForm";
import RefinancingForm from "./RefinancingForm";

import arrow from "../../assets/photos/all/tabs-arrow.svg";
import {
  results_calc,
  results_calc_mob,
} from "../../core/constants/results-calc";
import useQuery from "../../core/hooks/useQuery";
import CommonService from "../../core/services/common.service";
import { CalculatorTypes } from "../../core/services/enums";
import { UIRoutes } from "../../core/router";
import CreditCardsForm from "./CreditCardsForm";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../core/store";
import classNames from "classnames";

const ResultsCalculator = () => {
  const appContext = useContext(AppContext);
  const [step, setStep] = useState(1);
  const [opener, setOpener] = useState(false);
  const appLanguage = useAppSelector((state) => state.app.appLanguage);
  const navigate = useNavigate();
  const selector = (tab: any) => {
    const elementIndex = tabs.indexOf(appLanguage === "en" ? tab.en : tab.bg);

    setStep(elementIndex);
    setOpener(false);
  };

  const {t} = useTranslation()
  const tabs = [
    {
      id: 1,
      en: t<string>("LOANS.CONSUMER_LOANS"),
      bg: t<string>("LOANS.CONSUMER_LOANS"),
    },
    {
      id: 2,
      en: t<string>("LOANS.CREDIT_CARDS"),
      bg: t<string>("LOANS.CREDIT_CARDS"),
    },
    {
      id: 3,
      en: t<string>("LOANS.QUICK_LOAN"),
      bg: t<string>("LOANS.QUICK_LOAN"),
    },
    {
      id: 4,
      en: t<string>("LOANS.MORTGAGE_LOANS"),
      bg: t<string>("LOANS.MORTGAGE_LOANS"),
    },
    {
      id: 5,
      en: t<string>("LOANS.CAR_LEASING"),
      bg: t<string>("LOANS.CAR_LEASING"),
    },
    {
      id: 6,
      en: t<string>("LOANS.REFINANCING"),
      bg: t<string>("LOANS.REFINANCING"),
    },
    {
      id: 7,
      en: t<string>("LOANS.MERGING_LOANS"),
      bg: t<string>("LOANS.MERGING_LOANS"),
    },
    
  ]

  const query = useQuery();
  const { setSelectedLoan, selectedLoan } = appContext;

  useEffect(() => {
    if (query.type) {
      const calcTypes = CommonService.getNameValues(CalculatorTypes);
      const type = calcTypes.filter(
        (el: any) => el.name.toLowerCase() === query.type
      )[0];

      setSelectedLoan(type.value);
    }
  }, [query]);

  
  const currMob = results_calc_mob.filter((calc) => calc.id === selectedLoan)[0]
    .name;

    return (
      <div className="results-calculator">
        <div className="results-calculator-type">
          <div className="calculator__tabs">
            <ul className="calculator__tabs-list tabs-list">
              {tabs.map((tab) => {
                return(

                  <li
                  className={classNames({
                    "tabs-list__item": true,
                    "tabs-list__item-active": step === tab.id,
                    "tabs-list__item-language-BG": appLanguage === "bg", 
                  })}
                  onClick={() => {
                    setStep(tab.id)
                    navigate(
                      `/${UIRoutes.CALCULATOR}?type=${CalculatorTypes[
                        tab.id
                      ].toLowerCase()}`
                      );
                    }}
                    key={tab.id}>
                  {appLanguage === "en" ? tab.en : tab.bg}
                </li>
                )
              })}
            </ul>
            <div className="tabs-mobile">
              <div className="tabs-mobile__box">
                <div
                  className="tabs-mobile__box-selected"
                  onClick={() => setOpener(!opener)}>
                  <p>{appLanguage === "en" ? tabs[step - 1].en : tabs[step - 1].bg}</p>
                  <img src={arrow} alt="arrow" />
                </div>
                {opener && (
                  <ul className="tabs-mobile__box-selector">
                    {tabs
                      // .map((tab) => tab !== tabs[step])
                      .map((tab, index) => (
                        <li
                          className="tabs-mobile__box-selector-item"
                          key={index}
                          onClick={() => {
                            selector(appLanguage === "en" ? tab.en : tab.bg);
                            if (step < index) {
                              setStep(index);
                            } else {
                              setStep(index);
                            }
                          }}>
                          {appLanguage === "en" ? tab.en : tab.bg}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="calculator__tabs-type">
              {step === 1 && <ConsumerLoansForm />}
              {step === 2 && <CreditCardsForm />}
              {step === 3 && <QuickLoansForm />}
              {step === 4 && <MortgageLoansForm />}
              {step === 5 && <CarLeasingForm />}
              {step === 6 && <RefinancingForm />}
              {step === 7 && <MergingLoansForm />}
            </div>
          </div>
        </div>
      </div>
    );
};

export default ResultsCalculator;
