import React, { FC } from "react";
import CalculatorContainer from "../../../components/Calculator";

const CalculatorPage: FC = () => {
  return (
    <>
      <CalculatorContainer />
      {/* <CarouselMobile /> */}
    </>
  );
};

export default CalculatorPage;
