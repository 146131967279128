import React from "react";

import RefinancingBanner from "./RefinancingBanner";
import RefinancingInfo from "./RefinancingInfo";

const CreditsRefinancing = () => {
  return (
    <div id="Refinancing" className="credits-refinacing">
      <RefinancingBanner />
      <div className="credits-container-main-content-consumer">
        <RefinancingInfo />
      </div>
    </div>
  );
};

export default CreditsRefinancing;
