import { useContext, FC, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { AppContext } from "../../../App";
import PersonalInfo from "./PersonalInfo";
import FamilyInfo from "./FamilyInfo";
import Occupation from "./Occupation";
import OtherInfo from "./OtherInfo";

import { UIRoutes } from "../../../core/router";
import { APIRoutes } from "../../../core/http";
import useHttpGet from "../../../core/hooks/useHttpGet";
import { infoType } from "../../../core/constants/infoType";
import { useAppDispatch } from "../../../core/store";
import { useTranslation } from "react-i18next";
import { setQuestionnaire } from "../../../core/store/reducers/app/appDataSlice";

const ProfileForm: FC = () => {
  const [selectedInfoType, setSelectedInfoType] = useState(0);
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();

  const { reloadChecker } = appContext;

  useHttpGet<any>(APIRoutes.USER_QUESTIONNAIRE, {
    dependencies: [reloadChecker],

    resolve: (response) => {
      dispatch(setQuestionnaire(response.payload));
    },
  });

  return (
    <div className="profile-form">
      <div className="profile-form__editor">
        <h2 className="profile-form__editor-title">
          {t<string>("PROFILE.PROFILE_EDITING")}
        </h2>
        <div className="profile-form__content">
          <div className="profile-form__content-selection">
            {infoType.map((type, index) => (
              <button
                key={type}
                className={classNames({
                  "profile-form__content-selection-btn": true,
                  "profile-form__content-selection-active":
                    index === selectedInfoType,
                })}
                onClick={() => setSelectedInfoType(index)}>
                {t<string>(`PROFILE.${type}`)}
              </button>
            ))}
          </div>
          <div className="profile-form__content-type">
            {selectedInfoType === 0 && <PersonalInfo />}
            {selectedInfoType === 1 && <FamilyInfo />}
            {selectedInfoType === 2 && <OtherInfo />}
            {selectedInfoType === 3 && <Occupation />}
            {/* {selectedInfoType === 4 && <ChangePassword />} */}
          </div>
        </div>
        <Link
          className="profile-form__back"
          to={`/${UIRoutes.ACCOUNT}/${UIRoutes.PROFILE}`}>
          {t<string>("PROFILE.BACK")}
        </Link>
      </div>
    </div>
  );
};

export default ProfileForm;
