import { CalculatorTab } from "../../components/PrivateResultsCalculator/PrivateResultsCalculator";

export const results_calc = [
  {
    id: CalculatorTab.ConsumerLoan,
    
    
  },
  {
    id: CalculatorTab.CreditCard,
    
  },
  {
    id: CalculatorTab.QuickLoans,
    
  },
  {
    id: CalculatorTab.MortgageLoans,
    
  },
  {
    id: CalculatorTab.CarLeasing,
    
  },
  {
    id: CalculatorTab.Refinancing,
    
  },
  {
    id: CalculatorTab.MergingLoans,
    
  },

];
export const results_calc_mob = [
  {
    id: 1,
    name: "Consumer loans",
  },
  {
    id: 7,
    name: "Credit cards",
  },
  {
    id: 2,
    name: "Car leasing",
  },
  {
    id: 3,
    name: "Refinancing",
  },
  {
    id: 4,
    name: "Merging loans",
  },
  {
    id: 5,
    name: "Mortgage loans",
  },
  {
    id: 6,
    name: "Quick loans",
  },
];
export const filterCategory = [
  "Without guarantors",
  "With any credit history",
  "Without income certificates",
  "In cash",
  "Loan decision in 1 day",
];
