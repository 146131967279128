import React from "react";
import { useTranslation } from "react-i18next";

const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <div className="terms-conditions">
      <div className="terms-conditions__box">
        <div>
          <p className="terms-conditions__box-date">
            {t<string>("TERMS.UPDATED")} 2023-10-31
          </p>
        </div>
        <h1 className="terms-conditions__box-title">
          {t<string>("TERMS.TERMS_CONDITIONS")}
        </h1>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-title">
          {t<string>("TERMS.GENERAL_TERMS")}
        </h2>
        <div className="terms-conditions__box-desc1">
          <p className="terms-conditions__box-desc1-subtitle">
            {t<string>("TERMS.GENERAL_TEXT_1")}
            <br />
            <br />
            {t<string>("TERMS.GENERAL_TEXT_2")}
            <br />
            <br />
            {t<string>("TERMS.GENERAL_TEXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.LICENSE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.LICENSE_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.LICENSE_TXT_2")}
            <br /> <br />
            {t<string>("TERMS.LICENSE_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.DEFINITIONS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.DEFINITIONS_SUBTITLE")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>{t<string>("TERMS.DEFINITIONS_LI_1")}</li>
            <li>{t<string>("TERMS.DEFINITIONS_LI_2")}</li>
            <li>{t<string>("TERMS.DEFINITIONS_LI_3")}</li>
            <li>{t<string>("TERMS.DEFINITIONS_LI_4")}</li>
            <li>{t<string>("TERMS.DEFINITIONS_LI_5")}</li>
            <li>{t<string>("TERMS.DEFINITIONS_LI_6")}</li>
            <li>
              {t<string>("TERMS.DEFINITIONS_LI_7")}
            </li>
            <li>{t<string>("TERMS.DEFINITIONS_LI_8")}</li>
          </ul>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.RESTRICTIONS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.RESTRICTIONS_SUBTITLE")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>{t<string>("TERMS.RESTRICTIONS_LI_1")}</li>
            <li>{t<string>("TERMS.RESTRICTIONS_LI_2")}</li>
            <li>{t<string>("TERMS.RESTRICTIONS_LI_3")}</li>
          </ul>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.RETURN_REFUND")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.RETURN_REFUND_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.SUGGESTIONS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.SUGGESTIONS_TXT_1")}
            <br />
            {t<string>("TERMS.SUGGESTIONS_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.CONSENT")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.CONSENT_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.LINKS_WEBSITES")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.LINKS_WEBSITES_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.COOKIES")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.COOKIES_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.CHANGE_TERMS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.CHANGE_TERMS_TXT_1")}
            <br />
            <br />
            {t<string>("TERMS.CHANGE_TERMS_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.MODIFICATIONS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.MODIFICATIONS_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.UPDATE_WEBSITE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.UPDATE_WEBSITE_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.UPDATE_WEBSITE_TXT_2")}
            <br /> <br />
            {t<string>("TERMS.UPDATE_WEBSITE_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.THIRD_PART_SERVICE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.THIRD_PART_SERVICE_TXT_1")}
            <br />
            <br />
            {t<string>("TERMS.THIRD_PART_SERVICE_TXT_2")}
            <br />
            <br />
            {t<string>("TERMS.THIRD_PART_SERVICE_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.TERM_TERMINATION")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.TERM_TERMINATION_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.TERM_TERMINATION_TXT_2")}
            <br /> <br />
            {t<string>("TERMS.TERM_TERMINATION_TXT_3")}
            <br /> <br />
            {t<string>("TERMS.TERM_TERMINATION_TXT_4")}
            <br /> <br />
            {t<string>("TERMS.TERM_TERMINATION_TXT_5")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.COPYRIGHT_NOTICE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.COPYRIGHT_NOTICE_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.INDEMNIFICATION")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.INDEMNIFICATION_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.WARRANTIES")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.WARRANTIES_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.WARRANTIES_TXT_2")}
            <br /> <br />
            {t<string>("TERMS.WARRANTIES_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.LIMITATION_LIABILITY")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.LIMITATION_LIABILITY_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.LIMITATION_LIABILITY_TXT_2")}
            <br /> <br />
            {t<string>("TERMS.LIMITATION_LIABILITY_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.SEVERABILITY")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.SEVERABILITY_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.SEVERABILITY_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.WAIVER")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.WAIVER_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.WAIVER_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.AMENDMENT_AGREE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.AMENDMENT_AGREE_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.AMENDMENT_AGREE_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.ENTIRE_AGREE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.ENTIRE_AGREE_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.ENTIRE_AGREE_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.UPDATES_TERMS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.UPDATES_TERMS_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.INTELLECTUAL")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.INTELLECTUAL_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.AGREE_ARBITRATE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.AGREE_ARBITRATE_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.NOTICE_DISPUTE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.NOTICE_DISPUTE_TXT_1")}
            <span className="terms-conditions__box-email"> info@asistent.bg</span>
            {" "}
            {t<string>("TERMS.NOTICE_DISPUTE_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.BINDIND_ARBITRATION")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.BINDIND_ARBITRATION_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.SUBMISSIONS_PRAVICY")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.SUBMISSIONS_PRAVICY_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.PROMOTIONS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.PROMOTIONS_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.PROMOTIONS_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.TYPOGRAPHICAL_ERRORS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.TYPOGRAPHICAL_ERRORS_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.MISCELLANEOUS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.MISCELLANEOUS_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.DISCLAIMER")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.DISCLAIMER_TXT_1")}
            <br /> <br />
            {t<string>("TERMS.DISCLAIMER_TXT_2")}
            <br /> <br />
            {t<string>("TERMS.DISCLAIMER_TXT_3")}
            <br /> <br />
            {t<string>("TERMS.DISCLAIMER_TXT_4")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("TERMS.CONTACT_US")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("TERMS.CONTACT_US_TXT")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>
              {t<string>("TERMS.CONTACT_US_LI")}
              <span className="terms-conditions__box-email"> info@asistent.bg</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
