import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { APIRoutes } from "../../../../core/http";
import classNames from "classnames";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import addedFile from "../../../../assets/photos/all/file-added.svg";
import fileIcon from "../../../../assets/photos/all/add-file.svg";
import { useAppDispatch, useAppSelector } from "../../../../core/store";
import { setModalType } from "../../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../../core/services/enums";
import { setSelectedId } from "../../../../core/store/reducers/app/appDataSlice";
import PrivateService from "../../../../core/services/private.service";

const ShowQuestionnaireModal = () => {
  const [appId, setAppId] = useState<any>({});
  const [docUpdate, setDocUpdate] = useState(false);
  const appLanguage = useAppSelector((state) => state.app.appLanguage);
  const { selectedId, choosenData } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (choosenData) {
      const questionnaire: any = Object.assign({}, choosenData);
      if (questionnaire.hasOwnProperty("application_id")) {
        setAppId(choosenData);
      }
    }
  }, []);

  const { fetchedData: application } = useHttpGet<any>(
    `${APIRoutes.ADMIN_USER_QUESTIONNAIRE}/questionnaires/${selectedId}`,
    {
      dependencies: [selectedId],
      resolve: () => {
        console.log(selectedId)
      }
    }
  );
  const { fetchedData: documents } = useHttpGet<any>(
    `${APIRoutes.USER_DOCUMENTS}`,
    {
      dependencies: [docUpdate],
    }
  );

  const getMaritalStatus = (status:string) => {
    switch(status) {
      case "0":
        return t<string>("PROFILE.MARRIED")
      case "1":
        return t<string>("PROFILE.SINGLE")
      case "2":
        return t<string>("PROFILE.CIVIL_MARRIAGE")
      case "3":
        return t<string>("PROFILE.DIVORCED")
      case "4":
        return t<string>("PROFILE.WIDOWED")
    }
  }
  const getChildrenStatus = (status:string) => {
    switch(status) {
      case "4":
        return t<string>("PROFILE.MORE_THAN_3")
      default :
        return status
    }
  }
  const getOwnershipStatus = (status:string) => {
    switch(status) {
      case "0":
        return t<string>("PROFILE.RENTING")
      case "1":
        return t<string>("PROFILE.PARENTAL")
      case "2":
        return t<string>("PROFILE.OWN_HOME")
      case "3":
        return t<string>("PROFILE.OWN_WITH_MORTGAGE")
      case "4":
        return t<string>("PROFILE.PUBLIC_HOUSING")
      case "5":
        return t<string>("PROFILE.OTHER")
      default :
        return status
    }
  }
  const transformDateString = (date: string) => {
    const year = date.slice(0,4)
    const month = date.slice(5,7)
    const day = date.slice(-2)
    return `${day}-${month}-${year}`
  }
  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {window.location.pathname
            .split("/")
            .includes("submitted-applications") && (
            <button
              className="modal-logout__box-title-back"
              onClick={() => {
                if (appId) {
                  dispatch(setSelectedId(appId?.application_id));
                  dispatch(
                    setModalType(AuthSelectionType.updateSubmittedApplication)
                  );
                }
              }}>
              Back
            </button>
          )}
          {t<string>("PROFILE.PERSONAL_INFO")}
        </p>
        <div className="about">
          <div className="about__form">
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("PROFILE.FIRST_NAME")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  {application?.payload?.first_name}
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("PROFILE.LAST_NAME")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  {application?.payload?.last_name}
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("PROFILE.PHONE_NUMBER")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  {application?.payload?.phone}
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SHOW_QUESTIONNAIRE.EMAIL")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  {application?.payload?.email}
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SHOW_QUESTIONNAIRE.GENDER")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>
                    {application?.payload?.gender === "0" &&
                      t<string>("SHOW_QUESTIONNAIRE.MALE")}
                  </span>
                  <span>
                    {application?.payload?.gender === "1" &&
                      t<string>("SHOW_QUESTIONNAIRE.FEMALE")}
                  </span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SHOW_QUESTIONNAIRE.DATE_OF_BIRTH")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                    {application?.payload?.date_of_birth && (<span>{transformDateString(application?.payload?.date_of_birth)}</span>)}
                  
                </div>
              </label>
            </div>
            <h2 className="about__title">
              {t<string>("PROFILE.PASSPORT_INFO")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                  })}>
                  {t<string>("SHOW_QUESTIONNAIRE.CITIZENSHIP")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>
                    {application?.payload?.citizenship === "0" &&
                      t<string>("SHOW_QUESTIONNAIRE.BULGARIA")}
                  </span>
                  <span>
                    {application?.payload?.citizenship === "1" &&
                      t<string>("SHOW_QUESTIONNAIRE.OTHER_COUNTRY")}
                  </span>
                </div>
              </label>
            </div>
            {application?.payload?.personal_code && (
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                    })}>
                    {t<string>(
                      "PROFILE.PERSONAL_IDENTIFICATION_CODE"
                    )}
                  </span>
                  <div
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": true,
                    })}>
                    <span>{application?.payload?.personal_code}</span>
                  </div>
                </label>
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                  })}>
                  {t<string>("SHOW_QUESTIONNAIRE.PASSPORT_NUMBER")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{application?.payload?.passport}</span>
                </div>
              </label>
            </div>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.AUTHORITY")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.authority}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.DATE_OF_ISSUE")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                  {application?.payload?.date_of_issue && (<span>{transformDateString(application?.payload?.date_of_issue)}</span>)}
              </div>
            </label>
          </div>

          <div className="about__form">
            <h2 className="about__title">
              {t<string>("PROFILE.FAMILY_STATUS")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("PROFILE.MARITAL_STATUS")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{getMaritalStatus(application?.payload?.marital_status)}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("PROFILE.UNDER_18")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{getChildrenStatus(application?.payload?.children)}</span>
                </div>
              </label>
            </div>
            <h2 className="about__title">
              {t<string>("PROFILE.RESIDENCE_ADDRESS")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("SHOW_QUESTIONNAIRE.COUNTRY_OF_RESIDENCE")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{application?.payload?.country_of_residence}</span>
                </div>
              </label>
            </div>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("PROFILE.CITY_OF_RESIDENCE")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{application?.payload?.city_of_residence}</span>
                </div>
              </label>
            </div>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.OWNERSHIP_TYPE")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{getOwnershipStatus(application?.payload?.housing_type)}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.TYPE_OF_HOUSING")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.accomodation_type === 0 ? t<string>("PROFILE.FLAT") : t<string>("PROFILE.HOUSE")} </span>
              </div>
            </label>
          </div>
          {application?.payload?.housing_type === "0" && (
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("PROFILE.MOUTHLY_RENTING")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{getOwnershipStatus(application?.payload?.renting)}</span>
                </div>
              </label>
            </div>
          )}
          {application?.payload?.housing_type === "3" && (
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": true,
                  })}>
                  {t<string>("PROFILE.MORTGAGE_PEYMENT")}
                </span>
                <div
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": true,
                  })}>
                  <span>{getOwnershipStatus(application?.payload?.hypothec)}</span>
                </div>
              </label>
            </div>
          )}
          
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("SHOW_QUESTIONNAIRE.STREET")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.street_house}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("SHOW_QUESTIONNAIRE.HOUSE_NUMBER")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.house_number}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.APARTMENT_NUMBER")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.apartment_number}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.POSTAL_CODE")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.postal_code}</span>
              </div>
            </label>
          </div>
          <h2 className="about__title">
            {t<string>("PROFILE.CURRENT_LOANS")}
          </h2>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.ANY_LOANS")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.debts === "0" ? t<string>("COMMON.NO") : t<string>("COMMON.YES")}</span>
              </div>
            </label>
          </div>
          {application?.payload?.debts === '1' && (
            <>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": true,
                    })}>
                    {t<string>("PROFILE.CURRENT_LOANS")}
                  </span>
                  <div
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": true,
                    })}>
                    <span>{application?.payload?.loan_sum}</span>
                  </div>
                </label>
              </div>
            </>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.INSOLVENCY")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.insolvency === '0' ? t<string>("COMMON.NO") : t<string>("COMMON.YES")}</span>
              </div>
            </label>
          </div>
          <h2 className="about__title">
            {t<string>("PROFILE.EDUCATION")}
          </h2>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.DEGREE")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>
                  {application?.payload?.education === "0" &&
                    t<string>("SHOW_QUESTIONNAIRE.UNIVERSITY")}
                </span>
                <span>
                  {application?.payload?.education === "1" &&
                    t<string>("SHOW_QUESTIONNAIRE.HIGH_SCHOOL")}
                </span>
                <span>
                  {application?.payload?.education === "2" &&
                    t<string>("SHOW_QUESTIONNAIRE.SECONDARY")}
                </span>
                <span>
                  {application?.payload?.education === "3" &&
                    t<string>("SHOW_QUESTIONNAIRE.PRIMARY")}
                </span>
                <span>
                  {application?.payload?.education === "4" &&
                    t<string>("SHOW_QUESTIONNAIRE.NO_COMPLETED")}
                </span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.SIGNIFICANT_PERSON")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.politic === 0 ? t<string>("COMMON.NO") : t<string>("COMMON.YES")}</span>
              </div>
            </label>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": true,
                })}>
                {t<string>("PROFILE.JOB_EXIST")}
              </span>
              <div
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled": true,
                })}>
                <span>{application?.payload?.official_income === 0 ? t<string>("COMMON.NO") : t<string>("COMMON.YES")}</span>
              </div>
            </label>
          </div>
          {
            application?.payload?.official_income && application?.payload?.official_income === 0 ? (
            <>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": true,
                    })}>
                    {t<string>("SHOW_QUESTIONNAIRE.JOB_EXIST")}
                  </span>
                  <div
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": true,
                    })}>
                    <span>
                      {application?.payload?.job === 1 &&
                        t<string>("SHOW_QUESTIONNAIRE.YES")}
                    </span>
                    <span>
                      {application?.payload?.job === 0 &&
                        t<string>("SHOW_QUESTIONNAIRE.NO")}
                    </span>
                  </div>
                </label>
              </div>
              {application?.payload?.job_status && (
                <div className="about__form-box about-box">
                  <label className="about-box__field">
                    <span
                      className={classNames({
                        "about-box__field-name": true,
                        "about-box__field-name-filled": true,
                      })}>
                      {t<string>("PROFILE.STATUS")}
                    </span>
                    <div
                      className={classNames({
                        "about-box__field-inp": true,
                        "about-box__field-inp-filled": true,
                      })}>
                      <span>{application?.payload?.job_status === 0 ? t<string>("PROFILE.STUDENT") : application?.payload?.job_status === 1 ? t<string>("PROFILE.UNEMPLOYED") : t<string>("PROFILE.PENSIONER")}</span>
                    </div>
                  </label>
                </div>
              )}
              {application?.payload?.income_from && (
                <div className="about__form-box about-box">
                  <label className="about-box__field">
                    <span
                      className={classNames({
                        "about-box__field-name": true,
                        "about-box__field-name-filled": true,
                      })}>
                      {t<string>("PROFILE.JOB_EXIST")}
                    </span>
                    <div
                      className={classNames({
                        "about-box__field-inp": true,
                        "about-box__field-inp-filled": true,
                      })}>
                      <span>{application?.payload?.income_from}</span>
                    </div>
                  </label>
                </div>
              )}
              {application?.payload?.monthly_income && (
                <div className="about__form-box about-box">
                  <label className="about-box__field">
                    <span
                      className={classNames({
                        "about-box__field-name": true,
                        "about-box__field-name-filled": true,
                      })}>
                      {t<string>("PROFILE.INCOME")}
                    </span>
                    <div
                      className={classNames({
                        "about-box__field-inp": true,
                        "about-box__field-inp-filled": true,
                      })}>
                      <span>{application?.payload?.monthly_income}</span>
                    </div>
                  </label>
                </div>
              )}
            </>
          ) : (
            <>
              {application?.payload?.working_place && (
                <div className="about__form-box about-box">
                  <label className="about-box__field">
                    <span
                      className={classNames({
                        "about-box__field-name": true,
                        "about-box__field-name-filled": true,
                      })}>
                      {t<string>("PROFILE.WORK_PLACE")}
                    </span>
                    <div
                      className={classNames({
                        "about-box__field-inp": true,
                        "about-box__field-inp-filled": true,
                      })}>
                      <span>{application?.payload?.working_place}</span>
                    </div>
                  </label>
                </div>
              )}
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": true,
                    })}>
                    {t<string>("PROFILE.POSITION")}
                  </span>
                  <div
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": true,
                    })}>
                    <span>
                      {application?.payload?.position === "0" &&
                        t<string>("PROFILE.EMPLOYED")}
                    </span>
                    <span>
                      {application?.payload?.position === "1" &&
                        t<string>("PROFILE.CONTRACT")}
                    </span>
                    <span>
                      {application?.payload?.position === "2" &&
                        t<string>("PROFILE.SELF_EMPLOYED")}
                    </span>
                    <span>
                      {application?.payload?.position === "3" &&
                        t<string>("PROFILE.FREELANCE")}
                    </span>
                    <span>
                      {application?.payload?.position === "4" &&
                        t<string>("PROFILE.CIVIL")}
                    </span>
                  </div>
                </label>
              </div>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": true,
                    })}>
                    {t<string>("PROFILE.WORK_EXPERIENCE")}
                  </span>
                  <div
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": true,
                    })}>
                    <span>{application?.payload?.experience}</span>
                  </div>
                </label>
              </div>
              {application?.payload?.monthly_income && (
                <div className="about__form-box about-box">
                  <label className="about-box__field">
                    <span
                      className={classNames({
                        "about-box__field-name": true,
                        "about-box__field-name-filled": true,
                      })}>
                      {t<string>("PROFILE.INCOME")}
                    </span>
                    <div
                      className={classNames({
                        "about-box__field-inp": true,
                        "about-box__field-inp-filled": true,
                      })}>
                      <span>{application?.payload?.monthly_income}</span>
                    </div>
                  </label>
                </div>
              )}
            </>
          )}          
          <div className="about__form-box about-box">
            <h2 className="about__title about__title-adm">
              {t<string>("SHOW_QUESTIONNAIRE.ADDED_DOCS")}
            </h2>
            {documents?.payload?.collection.length !== 0 ? (
              <div className="about__form-box about-box">
              <div className="about-box__documents">
                {documents?.payload?.collection.map(
                  (document: any, index: number) => (
                    <div
                      className="about-box__file-block file-block file-block-item"
                      key={index}>
                      <div className="file-block-select__box">
                        <img
                          className="file-block__icon"
                          src={addedFile}
                          alt="file"
                        />
                        <span className="file-block__txt">
                          <span
                            className="file-block__txt-underline"
                            onClick={async () => {
                              PrivateService.downloadDocument(
                                document.id,
                                document.name
                              );
                            }}>
                            {document.name}
                          </span>{" "}
                          {t<string>("PROFILE.ADDED")}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            ) : (
              <div className="about-box__file-block file-block">
                <span className="file-block__txt">
                  {t<string>("SHOW_QUESTIONNAIRE.NO_DOCUMENTS")}
                </span>
              </div>
            )}
            
            {application?.payload?.certificates?.length > 0 ? (
              <div className="about-box__adm-docs">
                {application?.payload?.certificates?.map(
                  (document: any) => (
                    <div
                      className="about-box__file-block file-block"
                      key={document.id}>
                      <img
                        className="file-block__icon"
                        src={addedFile}
                        alt="file"
                      />
                      <span className="file-block__txt">
                        <span
                          className="file-block__txt-underline"
                          onClick={async () => {
                            PrivateService.downloadDocument(
                              document.id,
                              document.name
                            );
                          }}>
                          {document.name.length > 30
                            ? `${document.name.slice(0, 30)}...`
                            : `${document.name}`}
                        </span>{" "}
                        {t<string>("PROFILE.ADDED")}
                      </span>
                    </div>
                  )
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShowQuestionnaireModal;
