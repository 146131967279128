export enum UserRoleTypes {
  SuperAdmin = "super_admin",
  Admin = "admin",
  Partner = "partner",
  User = "user",
}

export enum VerificationTypes {
  Phone,
  Email,
}

export enum CalculatorTypes {
  ConsumerLoan = 1,
  CreditCards = 2,
  Quick = 3,
  Mortgage = 4,
  CarLeasing = 5,
  Refinancing = 6,
  Merging = 7,
}

export enum SubmittedApplicationStatus {
  open = "open",
  inProgress = "in_progress",
  sendToPartner = "send_to_partner",
  approved = "partner_response_approved",
  declined = "partner_response_declined",
  counterOffer = "partner_response_counter_offer",
  acceptedByClient = "accepted_by_client",
  rejectedByClient = "rejected_by_client",
  noResponseFromClient = "no_response_from_client",
}

export enum AuthSelectionType {
  adminSelectUserCertificates = "admin_select_user_certificates",
  AddUser = "add_user",
  addComment = "add_comment",
  AddQuestionnaire = "add_questionnaire",
  addNewCreditOffer = "add_new_credit_offer",
  applyApplication = "apply_application",
  Auth = "auth",
  addNewBank = "add_new_bank",
  Confirm = "confirm",
  createStudy = "create_study",
  createService = "create_service",
  createReview = "create_review",
  certificates = "certificates",
  calcExample = "calc-example",
  downloadCertificates = "download_certificates",
  deleteOffer = "delete_offer",
  DeleteUser = "delete_user",
  Login = "login",
  Logout = "logout",
  newBlogCategory = "new_blog_category",
  newBlogPost = "new_blog_post",
  NewNewsCategory = "new_news_category",
  new_NewsPost = "new_news_post",
  questionnairesList = "questionnaires_list",
  notifications = "notifications",
  Register = "register",
  resultsAlert = "results_alert",
  showUser = "show_user",
  showQuestionnaire = "show_questionnaire",
  showApplication = "show_application",
  showSubmittedApplication = "show_submitted_application",
  showAdminBankOffer = "show_admin_bank_offer",
  showComment = "show_comment",
  showBankComments = "show_bank_comments",
  showBlogCategoryContent = "show_blog_category_content",
  showBlogPostContent = "show_blog_post_content",
  showBankDescription = "show_bank_description",
  showCreditOfferContent = "show_credit_offer_content",
  showBankName = "show_bank_name",
  selectFromMyCertificates = "select_from_my_certificates",
  UpdateUser = "update_user",
  UpdateQuestionnaire = "update_questionnaire",
  updateSubmittedApplication = "update_submitted_application",
  updateBlogCategory = "update_blog_category",
  updatePost = "update-post",
  updateNewsCategory = "update_news_category",
  updateNewsPost = "update_news_post",
  updateBank = "update_bank",
  updateCreditOffer = "update_credit_offer",
  updateStudy = "update_study",
  updateService = "update_service",
  updateReview = "update_review",
}
