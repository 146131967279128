import fiBank_icon from "../../assets/photos/all/fiBank_icon.svg";
import uniCreditBank_icon from "../../assets/photos/all/uniCreditBank.svg";
import alianzBank_icon from "../../assets/photos/all/alianzBank.svg";

export const credits_carousel = [
  {
    id: 1,
    title: "FINANCE.CAROUSEL_TITLE_1",
    icon: fiBank_icon,
    bankTitle: "Fi Bank",
  },
  {
    id: 2,
    title: "FINANCE.CAROUSEL_TITLE_2",
    icon: uniCreditBank_icon,
    bankTitle: "UniCredit",
  },
  {
    id: 3,
    title: "FINANCE.CAROUSEL_TITLE_3",
    icon: alianzBank_icon,
    bankTitle: "Alianz Bank",
  },
  {
    id: 1,
    title: "FINANCE.CAROUSEL_TITLE_4",
    icon: fiBank_icon,
    bankTitle: "Fi Bank",
  },
];
