import React from "react";
import BannersData from "../../core/store/banners.json";
import { useAppSelector } from "../../core/store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { Link } from "react-router-dom";

export default function Banners() {
  const appLanguage = useAppSelector((state) => state.app.appLanguage);

  const handleRedirect = (type: number) => {
    if (type === 2) {
      localStorage.setItem(
        "calcResult",
        JSON.stringify({
          "amount_1": "10000", "term_1": "12", "type": "2"
        })
      );
    } else {
      localStorage.setItem(
        "calcResult",
        JSON.stringify({
          "amount_1": "80000", "term_1": "36", "type": "1"
        })
      );
    }
  }
  return (
    <div className="banners__wrapper">
      <Swiper
        slidesPerView={2}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
        }}
        className="banners"
        modules={[Navigation]}
        loop={true}
        navigation={{
          prevEl: ".banners__button--prev",
          nextEl: ".banners__button--next",
        }}
      >
        {BannersData.map((slide) => (
          <SwiperSlide className="banners__slide" key={slide.id}>
            <Link onClick={()=> handleRedirect(slide.type)} to="/result-list"> 
              <img
                src={require(`../../assets/photos/banners/${
                  appLanguage === "bg" ? slide.img_bg : slide.img_en
                }`)}
                alt={slide.img_en}
              />
            </Link>
          </SwiperSlide>
        ))}
        <button
          type="button"
          className="banners__button banners__button--prev"
        ></button>
        <button
          type="button"
          className="banners__button banners__button--next"
        ></button>
      </Swiper>
    </div>
  );
}
