import { FC, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { AppContext, ProfileListCategory } from "../../../App";
import Offers from "../../Offers";
import ReviewsList from "../../Reviews/ReviewsList";

import QuestionnairesList from "../../Questionnaires/List/QuestionnaireList";

import rate from "../../../assets/photos/profile/rate.svg";
import applicationsIcon from "../../../assets/photos/profile/application-icon.svg";
import reviewsIcon from "../../../assets/photos/profile/reviews.svg";
import selectedApplications from "../../../assets/photos/profile/application-icon-selected.svg";
import selectedReviews from "../../../assets/photos/profile/reviews-selected.svg";
import defaultAvatar from "../../../assets/photos/profile/default-avatar.svg";

import useRole from "../../../core/hooks/useRole";
import { Private } from "../../../core/models";
import PrivateService from "../../../core/services/private.service";
import { useAppDispatch, useAppSelector } from "../../../core/store";
import { PrivateUIRoutes, UIRoutes } from "../../../core/router";
import useHttpGet from "../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../core/http";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../core/services/enums";
import { chatOpener } from "../../../core/functions/funtions";

const ProfileGeneral: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [pagination, setPagination] = useState({
    cursor: undefined,
    per_page: 50,
  });

  const navigate = useNavigate();

  const { isAdmin, isSuperAdmin } = useRole();
  const { t } = useTranslation();
  const { currentUser } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const appContext = useContext(AppContext);

  const { profileCategory, setProfileCategory } = appContext;

  const { fetchedData } = useHttpGet<any>(APIRoutes.USER_SUBMITTED_APPLICATIONS, {
    dependencies: [pagination],
    query: { ...pagination },
  });
  const { fetchedData: reviews } = useHttpGet<any>(APIRoutes.USER_REVIEWS);

  useEffect(() => {
    if (currentUser) {
      const newUser = JSON.parse(JSON.stringify(currentUser));

      for (var property in newUser) {
        if (!newUser[property] && newUser[property] !== 0) {
          newUser[property] = "";
        }
      }
      formikForm.setValues({ ...formikForm.values, ...newUser }, false);
    }
  }, [currentUser]);

  const valueFormValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(121, "Too many characters"),
    last_name: Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .max(121, "Too many characters"),
    passport_id: Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .min(9, "This field must contain 9 characters")
      .max(9, "This field must contain 9 characters"),
    taxpayer_id: Yup.string()
      .required(`${t<string>("COMMON.IS_REQUIRED")}`)
      .min(9, "This field must contain 9 characters")
      .max(9, "This field must contain 9 characters"),
    phone: Yup.string(),
    email: Yup.string().email("Invalid email"),
    country: Yup.string(),
    settlement: Yup.string().max(55, "Too many characters"),
    street: Yup.string().max(55, "Too many characters"),
    house_number: Yup.string().max(10, "Too many characters"),
    apartment_number: Yup.string().max(
      10,
      t<string>("NEW_QUESTIONNAIRE.TOO_MANY_CHARS")
    ),
    gender: Yup.string(),
    zip: Yup.string().matches(/^[0-9]{4}$/, "Must be exactly 4 digits"),
    date_of_birth: Yup.string(),
    income: Yup.number(),
    position: Yup.string(),
    position_other: Yup.string().max(125, "Too many characters"),
    work_experience: Yup.string(),
    company: Yup.string().max(55, "Too many characters"),
    company_id: Yup.string().max(55, "Too many characters"),
  });

  const formikForm = useFormik<Private.Profile>({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      passport_id: "",
      taxpayer_id: "",
      country: "",
      settlement: "",
      street: "",
      house_number: "",
      apartment_number: "",
      zip: 0,
      gender: "",
      date_of_birth: moment().format("YYYY-MM-DD"),
      income: 0,
      position: "",
      work_experience: 0,
      position_other: "",
      company: "",
      company_id: "",
      email: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    try {
      delete values.phone;
      delete values.email;
      const response = await PrivateService.updateProfile({
        ...values,
      });
      toast.success(response?.data?.message);
      setLoading(false);
      setError("");
      setSuccess(response?.data?.message);
    } catch (errors: any) {
      setLoading(false);
      toast.error(errors?.response?.data?.message);
      setError(errors?.response?.data?.errorMessages[0] || "");
    }
  };

  return (
    <div className="profile">
      <div className="profile__container">
        <div className="profile__container-person profile-person">
          <div className="profile-person__block">
            <div className="profile-person__block-details person-details">
              <div className="person-details__box">
                <div className="person-details__box-photo">
                  <img
                    src={
                      currentUser?.avatar ? currentUser?.avatar : defaultAvatar
                    }
                    alt="avatar"
                  />
                </div>
                <p className="person-details__box-name">
                  {t<string>("PROFILE.HI")},&nbsp;
                  {currentUser?.first_name
                    ? `${currentUser?.first_name}`
                    : "User"}
                  !
                </p>
                <Link
                  to={`/${UIRoutes.ACCOUNT}/${UIRoutes.PROFILE}/edit`}
                  className="person-details__box-edit">
                  {t<string>("PROFILE.EDIT")}
                </Link>
              </div>
            </div>
            <div className="profile-person__block-selectors chapter-selector">
              <button
                className="chapter-selector__block"
                onClick={() =>
                  setProfileCategory(ProfileListCategory.Questionnaires)
                }>
                {profileCategory === ProfileListCategory.Questionnaires && (
                  <img
                    className="chapter-selector__block-photo"
                    src={selectedApplications}
                    alt="application"
                  />
                )}
                {profileCategory !== ProfileListCategory.Questionnaires && (
                  <img
                    className="chapter-selector__block-photo"
                    src={applicationsIcon}
                    alt="application"
                  />
                )}
                <span
                  className={classNames({
                    "chapter-selector__block-txt": true,
                    "chapter-selector__block-txt-active":
                      profileCategory === ProfileListCategory.Questionnaires,
                  })}>
                  {t<string>("PROFILE.MY_QUESTIONNAIRES")} (
                  {fetchedData?.payload?.cursor?.count || 0})
                </span>
              </button>
              <div className="chapter-selector__line"></div>
              <button
                className="chapter-selector__block"
                onClick={() => setProfileCategory(ProfileListCategory.Reviews)}>
                {profileCategory === ProfileListCategory.Reviews && (
                  <img
                    className="chapter-selector__block-photo"
                    src={selectedReviews}
                    alt="application"
                  />
                )}
                {profileCategory !== ProfileListCategory.Reviews && (
                  <img
                    className="chapter-selector__block-photo"
                    src={reviewsIcon}
                    alt="application"
                  />
                )}
                <span
                  className={classNames({
                    "chapter-selector__block-txt": true,
                    "chapter-selector__block-txt-active":
                      profileCategory === ProfileListCategory.Reviews,
                  })}>
                  {t<string>("PROFILE.MY_REVIEWS")} (
                  {reviews?.payload?.collection.length || 0})
                </span>
              </button>
              {(isAdmin || isSuperAdmin) && (
                <>
                  <div className="chapter-selector__line"></div>
                  <button
                    className="chapter-selector__block"
                    onClick={() => {
                      setProfileCategory(ProfileListCategory.AdminPanel);
                      navigate(
                        `/${UIRoutes.ADMIN}/${PrivateUIRoutes.ADMIN_PANEL}`
                      );
                    }}>
                    <span
                      className={classNames({
                        "chapter-selector__block-txt": true,
                        "chapter-selector__block-txt-active":
                          profileCategory === ProfileListCategory.AdminPanel,
                      })}>
                      {t<string>("PROFILE.ADMIN_PANEL")}
                    </span>
                  </button>
                </>
              )}
            </div>
          </div>
          <button
            className="profile-person__log-out"
            onClick={() => {
              dispatch(setModalType(AuthSelectionType.Logout));
              dispatch(setModal(true));
              document.body.style.overflow = "hidden";
            }}>
            {t<string>("PROFILE.LOG_OUT")}
          </button>
        </div>
        <div className="profile__container-applicate profile-applicate">
          {profileCategory === ProfileListCategory.Questionnaires && (
            <QuestionnairesList />
          )}
          {profileCategory === ProfileListCategory.Reviews && <ReviewsList />}
        </div>
        <div className="profile__container-rate profile-rate">
          <div className="profile-rate__block">
            <div className="profile-rate__block-photo">
              <img src={rate} alt="rate" />
            </div>
          </div>
          <div className="profile-rate__line"></div>
          <div className="profile-rate__block">
            <h4 className="profile-rate__block-txt">
              {t<string>("PROFILE.HELP_QUESTION")}
            </h4>
            <button
              className="profile-rate__block-btn"
              onClick={() => chatOpener()}>
              {t<string>("PROFILE.HELP_BTN")}
            </button>
          </div>
          <span className="profile-rate__advertise">
            {t<string>("PROFILE.COMMING_SOON")}
          </span>
        </div>
      </div>
      <div className="profile__offers">
        <Offers />
      </div>
    </div>
  );
};

export default ProfileGeneral;
