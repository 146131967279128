import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { toast } from "react-toastify";

import fileIcon from "../../../../assets/photos/all/add-file.svg";
import addedFile from "../../../../assets/photos/all/file-added.svg";

import { useAppDispatch, useAppSelector } from "../../../../core/store";
import PrivateService from "../../../../core/services/private.service";
import CommonService from "../../../../core/services/common.service";
import { APIRoutes } from "../../../../core/http";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import { useTranslation } from "react-i18next";
import { usePrevious } from "../../../../core/hooks/usePrevious";
import { setCerts, setCertsIds } from "../../../../core/store/reducers/documents/documentsSlice";
import Checkbox from "../../../CustomCheckbox/CustomCheckbox";

const Occupation = () => {
  const [docUpdate, setDocUpdate] = useState(false);
  const [addLater, setAddLater] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false)

  
  const { t } = useTranslation();
  const { questionnaire } = useAppSelector((state) => state.app);

  const { certs, certsIds } = useAppSelector((state) => state.document);
  const dispatch = useAppDispatch();

  const { fetchedData: documents } = useHttpGet<any>(
    `${APIRoutes.USER_DOCUMENTS}`,
    {
      dependencies: [docUpdate],
      resolve: (response) => {
        formikForm.setFieldValue('docs', response.payload.collection.length)
      }
    }
  );
  const appLanguage = useAppSelector((state) => state.app.appLanguage);

  useEffect(() => {
    if (questionnaire) {
      const newUser = JSON.parse(JSON.stringify(questionnaire));

      for (const property in newUser) {
        if (!newUser[property] && newUser[property] !== 0) {
          newUser[property] = "";
        } else {
          formikForm.setFieldValue(property, String(newUser[property]));
        }
      }
    }
  }, [questionnaire]);



  useEffect(() => {
    const handleScroll = () => {
      formikForm.validateForm();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const valueFormValidationSchema = Yup.object().shape({
    job: Yup.string(),
    education: Yup.string(),
    politic: Yup.string(),
    monthly_income: Yup.string().when("job", {
      is: (val: string) => val,
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    position: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    income_from: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(36, t<string>("PROFILE.MAX_36_CHARS")),
    }),
    job_status: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    working_place: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(55, t<string>("PROFILE.MAX_55_CHARS")),
    }),
    experience: Yup.number().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.number().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
  });

  const formikForm = useFormik<{
    job: string;
    working_place: string;
    position: string;
    experience: string;
    job_status: string;
    income_from: string;
    monthly_income: string;
    docs: number;
    education: string;
    politic: string;
  }>({
    initialValues: {
      job: "1",
      working_place: "",
      position: "",
      experience: "",
      job_status: "",
      docs: 0,
      income_from: "",
      monthly_income: "",
      education: "",
      politic: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const deleteCert = (index: number, doc: any) => {
    const newCertificates = certs.filter(
      (cert: any) => certs.indexOf(cert) !== index
    );
    const uniqueCert = certsIds.filter((certId: number) => certId !== doc.id);

    dispatch(setCertsIds(uniqueCert));

    dispatch(setCerts(newCertificates));
  };

  const prevIncome = usePrevious(formikForm.values.income_from);

  const updateCertificates = () => {
    for (let i = 0; i < 5; i++) {
      if (certs[i]) {
        formikForm.setFieldValue(`certificates_${i + 1}`, certs[i]);
      } else {
        formikForm.setFieldValue(`certificates_${i + 1}`, "");
      }
    }
  };

  const addDocument = async (document: File) => {
    try {
      const response = await PrivateService.addProfileDocument(document);
      setDocUpdate(!docUpdate);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.errorMessages[0] || "");
    }
  };

  const deleteDocument = async (id: number) => {
    try {
      const response = await PrivateService.deleteDocument(id);

      setDocUpdate(!docUpdate);
      toast.success(response?.data?.message);
    } catch (errors: any) {
      CommonService.showErrors(errors?.response?.data?.payload);
      toast.error(errors?.response?.data?.message);
    }
  };

  const handleSubmitForm = async (values: any) => {

    try {
      delete values.docs;
      delete values.avatar;
      delete values.agree;
      delete values.informed;
      const response = await PrivateService.updateUserQuestionnaire({
        ...values,
      }, questionnaire.id);

      toast.success(response?.data?.message);
    } catch (errors: any) {
      CommonService.showErrors(errors?.response?.data?.payload);
      toast.error(errors?.response?.data?.message);
    }
  };

  const jobInfoClear = (value: string) => {
    formikForm.setFieldValue("working_place", "");
    formikForm.setFieldValue("position", "");
    formikForm.setFieldValue("experience", "");
    formikForm.setFieldValue("monthly_income", "");
    formikForm.setFieldValue("job_status", "");
    formikForm.setFieldValue("income_from", "");
    formikForm.setFieldValue("job", value);
    formikForm.validateForm();
  };

  return (
    <div className="edit-info">
      <form className="about" onSubmit={formikForm.handleSubmit}>
      <div className="about__form">
          <h2 className="about__title">{t<string>("PROFILE.EDUCATION")}</h2>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.education,
                  "about-box__field-inp-error":
                    formikForm.touched.education && formikForm.errors.education,
                })}>
                {t<string>("PROFILE.DEGREE")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.education,
                  "about-box__field-inp-touched":
                    formikForm.touched.education && formikForm.errors.education,
                })}
                {...formikForm.getFieldProps("education")}>
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="0">{t<string>("PROFILE.UNIVERSITY")}</option>
                <option value="1">{t<string>("PROFILE.HIGH_SCHOOL")}</option>
                <option value="2">{t<string>("PROFILE.SECONDARY")}</option>
                <option value="3">{t<string>("PROFILE.PRIMARY")}</option>
                <option value="4">{t<string>("PROFILE.NO_COMPLETED")}</option>
              </select>
            </label>
          </div>
          {formikForm.touched.education && formikForm.errors.education && (
            <div className="form-control-error">
              {formikForm.errors.education}
            </div>
          )}
          <div className="about__form-box about-ask about-ask-bottom">
            <h4 className="about-ask__question">
              {t<string>("PROFILE.SIGNIFICANT_PERSON")}
            </h4>
            <div className="about-ask__variants">
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.politic === "1",
                })}
                type="button"
                onClick={() => formikForm.setFieldValue("politic", "1")}>
                {t<string>("COMMON.YES")}
              </button>
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.politic === "0",
                })}
                type="button"
                onClick={() => formikForm.setFieldValue("politic", "0")}>
                {t<string>("COMMON.NO")}
              </button>
            </div>
          </div>
        </div>
        <div className="about__form">
          <div className="about__form-box about-ask">
            <h4 className="about-ask__question">
              {t<string>("PROFILE.JOB_EXIST")}
            </h4>
            <div className="about-ask__variants">
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.job === "1",
                })}
                type="button"
                onClick={() => jobInfoClear("1")}>
                {t<string>("COMMON.YES")}
              </button>
              <button
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.job === "0",
                })}
                type="button"
                onClick={() => jobInfoClear("0")}>
                {t<string>("COMMON.NO")}
              </button>
            </div>
          </div>
        </div>
        {formikForm.values.job === "0" && (
          <div className="about__form">
            <h2 className="about__title">{t<string>("PROFILE.LIFESTYLE")}</h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.job_status,
                    "about-box__field-inp-error":
                      formikForm.touched.job_status &&
                      formikForm.errors.job_status,
                  })}>
                  {t<string>("PROFILE.STATUS")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.job_status,
                    "about-box__field-inp-touched":
                      formikForm.touched.job_status &&
                      formikForm.errors.job_status,
                  })}
                  {...formikForm.getFieldProps("job_status")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("PROFILE.STUDENT")}</option>
                  <option value="1"> {t<string>("PROFILE.UNEMPLOYED")}</option>
                  <option value="2">{t<string>("PROFILE.PENSIONER")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched.job_status && formikForm.errors.job_status && (
              <div className="form-control-error">
                {formikForm.errors.job_status}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.income_from,
                    "about-box__field-inp-error":
                      formikForm.touched.income_from &&
                      formikForm.errors.income_from,
                  })}>
                  {t<string>("PROFILE.INCOME_WAY")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.income_from,
                    "about-box__field-inp-touched":
                      formikForm.touched.income_from &&
                      formikForm.errors.income_from,
                  })}
                  type="text"
                  value={formikForm.values.income_from}
                  onChange={(event) => {
                    if (event.target.value.length > 36) {
                      formikForm.setFieldValue("income_from", prevIncome);
                    } else {
                      formikForm.setFieldValue(
                        "income_from",
                        event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.income_from &&
              formikForm.errors.income_from && (
                <div className="form-control-error">
                  {formikForm.errors.income_from}
                </div>
              )}
          </div>
        )}

        {formikForm.values.job === "1" && (
          <div className="about__form">
            <h2 className="about__title">{t<string>("PROFILE.LIFESTYLE")}</h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.working_place,
                    "about-box__field-inp-error":
                      formikForm.touched.working_place &&
                      formikForm.errors.working_place,
                  })}>
                  {t<string>("PROFILE.WORK_PLACE")}
                </span>
                <input
                  type="text"
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.working_place,
                    "about-box__field-inp-touched":
                      formikForm.touched.working_place &&
                      formikForm.errors.working_place,
                  })}
                  {...formikForm.getFieldProps("working_place")}
                />
              </label>
            </div>
            {formikForm.touched.working_place &&
              formikForm.errors.working_place && (
                <div className="form-control-error">
                  {formikForm.errors.working_place}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.position,
                    "about-box__field-inp-error":
                      formikForm.touched.position && formikForm.errors.position,
                  })}>
                  {t<string>("PROFILE.POSITION")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.position,
                    "about-box__field-inp-touched":
                      formikForm.touched.position && formikForm.errors.position,
                  })}
                  {...formikForm.getFieldProps("position")}>
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("PROFILE.EMPLOYED")}</option>
                  <option value="1">{t<string>("PROFILE.CONTRACT")}</option>
                  <option value="2">{t<string>("PROFILE.SELF_EMPLOYED")}</option>
                  <option value="3">{t<string>("PROFILE.FREELANCE")}</option>
                  <option value="4">{t<string>("PROFILE.CIVIL")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched.position && formikForm.errors.position && (
              <div className="form-control-error">
                {formikForm.errors.position}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.experience,
                    "about-box__field-inp-error":
                      formikForm.touched.experience &&
                      formikForm.errors.experience,
                  })}>
                  {t<string>("PROFILE.WORK_EXPERIENCE")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.experience,
                    "about-box__field-inp-touched":
                      formikForm.touched.experience &&
                      formikForm.errors.experience,
                  })}
                  type="text"
                  value={formikForm.values.experience}
                  onChange={(event) => {
                    if (
                      +event.target.value <= 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("experience", "");
                    } else {
                      formikForm.setFieldValue(
                        "experience",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.experience && formikForm.errors.experience && (
              <div className="form-control-error">
                {formikForm.errors.experience}
              </div>
            )}
          </div>
        )}

        <div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.monthly_income,
                  "about-box__field-inp-error":
                    formikForm.touched.monthly_income &&
                    formikForm.errors.monthly_income,
                })}>
                {t<string>("PROFILE.INCOME")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled":
                    formikForm.values.monthly_income,
                  "about-box__field-inp-touched":
                    formikForm.touched.monthly_income &&
                    formikForm.errors.monthly_income,
                })}
                placeholder="1000"
                type="text"
                value={formikForm.values.monthly_income}
                onChange={(event) => {
                  if (
                    +event.target.value <= 0 ||
                    Number.isNaN(+event.target.value)
                  ) {
                    formikForm.setFieldValue("monthly_income", "");
                  } else {
                    formikForm.setFieldValue(
                      "monthly_income",
                      +event.target.value
                    );
                  }
                }}
              />
            </label>
            {formikForm.touched.monthly_income &&
              formikForm.errors.monthly_income && (
                <div className="form-control-error">
                  {formikForm.errors.monthly_income}
                </div>
              )}
          </div>

          <div className="about__form-box about-box">
            <div className="about-box__documents">
              {documents?.payload?.collection.map(
                (document: any, index: number) => (
                  <div
                    className="about-box__file-block file-block file-block-item"
                    key={index}>
                    <div className="file-block-select__box">
                      <img
                        className="file-block__icon"
                        src={addedFile}
                        alt="file"
                      />
                      <span className="file-block__txt">
                        <span
                          className="file-block__txt-underline"
                          onClick={async () => {
                            PrivateService.downloadDocument(
                              document.id,
                              document.name
                            );
                          }}>
                          {document.name}
                        </span>{" "}
                        {t<string>("PROFILE.ADDED")}
                      </span>
                    </div>
                    <span
                      className="file-block__txt"
                      onClick={() => {
                        deleteDocument(document.id);
                      }}>
                      x
                    </span>
                  </div>
                )
              )}
            </div>
            <label className="about-box__file">
              {documents?.payload?.collection.length < 10 && (
                <>
                  <div className="about-box__file-block file-block">
                    <img
                      className="file-block__icon"
                      src={fileIcon}
                      alt="file"
                    />
                    <p className={
                  appLanguage === 'en' 
                  ? "file-block__txt-en"
                  : "file-block__txt-bg"
                  }>
                  {t<string>("PROFILE.ADD_CERT.LINE_1")}
                  <br />
                  <b>
                  {t<string>("PROFILE.ADD_CERT.LINE_2")}
                  <br />
                  {t<string>("PROFILE.ADD_CERT.LINE_3")}
                  </b>
                </p>
                  </div>
                  <input
                    type="file"
                    accept=".doc, .docx, .pdf, .xlx, .csv, .zip, .png, .jpg, .jpeg, .gif, .heic, .heif"
                    className="file-block__inp"
                    onChange={(event: any) => {
                      const sameDoc = documents?.payload?.collection.some(
                        (doc: any) => doc.name === event.target.files[0].name
                      );

                      if (event.target.files && !sameDoc) {
                        addDocument(event.target.files[0]);
                      } else {
                        toast.error("File already added");
                      }
                    }}
                  />
                </>
              )}
            </label>
            {btnClicked && formikForm.values.docs === 0 && !addLater && (
          <div className="form-control-error">
            {t<string>("PROFILE.LEAST_CERT")}
          </div>
        )}
        {certs.length === 0 && (
          <div className="about-box__egn_s3">
          <div className="about-box__egn_s3-checkbox-wrapper">
            <Checkbox
              checked={addLater && certs.length === 0}
              onChange={(e: any) => {
                setAddLater(e.target.checked);
                formikForm.validateForm();
              }}
              id="checkboxLabel"
            />
            <label htmlFor="checkboxLabel">
              <p className="line1">
                {t<string>("PROFILE.LATER_CERT.MAIN_TEXT")}
              </p>
            </label>
          </div>
          <span className="line2">
            *{t<string>("PROFILE.LATER_CERT.ADDITIONAL_TEXT")}
          </span>
        </div>
        )}
          </div>
        </div>

        <button
          className={classNames({
            about__submit: true,
            "about__submit-active": formikForm.values.docs !== 0 || addLater && formikForm.isValid && formikForm.dirty,
          })}
          
          onClick= {()=>{
            setBtnClicked(true)}}
          type="submit">
          {t<string>("PROFILE.SAVE_CHANGES")}
        </button>
      </form>
    </div>
  );
};

export default Occupation;
