import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIRoutes } from "../../../core/http";
import { useFormik } from "formik";
import { VerificationTypes } from "../../../core/services/enums";

import AuthService from "../../../core/services/auth.service";
import * as Yup from "yup";

import useHttpGet from "../../../core/hooks/useHttpGet";
import { toast } from "react-toastify";

interface IProps {
  type: VerificationTypes;
}

const VerificationForm: FC<IProps> = ({ type }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { id, code } = useParams();

  const navigate = useNavigate();

  const { loading: emailLoading } = useHttpGet(
    `${APIRoutes.AUTH_VERIFY_EMAIL}/${id}/${code}`,
    {
      condition: type === VerificationTypes.Email,
      resolve: () => {
        navigate("/");
      },
    }
  );

  const valueFormValidationSchema = Yup.object().shape({
    code: Yup.string().required("Code is required"),
  });

  const formikForm = useFormik<{
    code: string;
  }>({
    initialValues: {
      code: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    try {
      const response = await AuthService.verificationPhone({
        ...values,
        id,
      });
      setLoading(false);
      setError("");
      toast.success(response?.data?.message);
      navigate(`/`);
    } catch (errors: any) {
      setLoading(false);
      setError(errors?.response?.data?.errorMessages[0] || "");
    }
  };

  const errorMessage = !!error ? error : null;

  return (
    <>
      {errorMessage}
      {type === VerificationTypes.Phone && (
        <form onSubmit={formikForm.handleSubmit} className="form w-100">
          <div className="form-control">
            <label className="form-label">Received code</label>
            <input
              className="form-control"
              placeholder="Enter code"
              {...formikForm.getFieldProps("code")}
            />
            {formikForm.touched.code && formikForm.errors.code && (
              <div className="form-control-error">{formikForm.errors.code}</div>
            )}
          </div>
          <div className="form-actions">
            <button type="submit">Save</button>
          </div>
          <div>
            <button>Resend code</button>
          </div>
        </form>
      )}
      {emailLoading && <span>Verification...</span>}
    </>
  );
};

export default VerificationForm;
