import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../../App";
import credits_merging_main from "../../../../../assets/photos/credits/credits_merging_main.svg";
import credits_merging_bg from "../../../../../assets/photos/credits/credits_merging_bg.svg";
import { useTranslation } from "react-i18next";
import { setTypeForResult } from "../../../../../core/functions/funtions";

const CreditsMergingBanner = () => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();

  const { setSelectedLoan } = appContext;

  return (
    <section className="credits-block__consumer-credits consumer-credits">
      <div className="consumer-credits-banner credits-merging-banner">
        <div className="consumer-credits-banner__main-content main-content">
          <div className="main-content-info">
            <h1 className="main-content-info-title">
              {t<string>("LOANS.MERGING_LOANS")}
            </h1>
            <p className="main-content-info-subtitle">
              {t<string>("FINANCE.MERGING_TEXT_1")}
              <br />
              <br />
              {t<string>("FINANCE.MERGING_TEXT_2")}
            </p>
            <img
              className="credits-car_leasing-banner__background"
              src={credits_merging_bg}
              alt="credits_merging_bg"
            />
            <img
              className="credits_merging_main-bg"
              src={credits_merging_main}
              alt="credits_merging_main"
            />
            <Link
              to={`/result-list`}
              onClick={() => {
                setTypeForResult(7)
              }}
              className="main-content-btn"
            >
              {t<string>("FINANCE.MERGING_BUTTON")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditsMergingBanner;
