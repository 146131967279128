import React, { useState } from "react";

import arrow_right_gray from "../../../assets/photos/all/arrow_right_gray.svg";
import consumer_main from "../../../assets/photos/all/consumer_main.svg";
import consumer_secondary from "../../../assets/photos/all/consumer_secondary.svg";
import bottom_consumer from "../../../assets/photos/all/bottom_consumer.svg";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ConsumerBanner = () => {
  const [showMore, setShowMore] = useState<boolean | undefined>(false);
  const {t} = useTranslation()
  const text = `Compare the conditions in different banks and decide where it is best to get a loan in 2022. Apply for a loan online for any purpose, without collateral and guarantors, for up to several years`;

  return (
    <div className="results-banner consumer-banner">
      <div className="results-banner-content">
        <div className="results-banner-content__nav-top nav-top">
          <span className="nav-top__first">Home</span>
          <img
            className="nav-top__arrow"
            src={arrow_right_gray}
            alt="arrow_right_gray"
            width="12px"
            height="12px"
          />
          <span className="nav-top__second">{t<string>("LOANS.CONSUMER_LOANS")}</span>
        </div>
        <div className="results-banner-content__main-content main-content">
          <h1 className="main-content__header">{t<string>("LOANS.CONSUMER_LOANS")}</h1>
          <p className="main-content__banner-desc banner-desc">
              {t<string>("RESULTS.CONSUMER_TITLE.LINE_1")}
            <br />
              {t<string>("RESULTS.CONSUMER_TITLE.LINE_2")}
            <br />
              {t<string>("RESULTS.CONSUMER_TITLE.LINE_3")}
          </p>
          <p className="main-content__banner-desc-hide banner-desc-hide">
            {showMore ? text : `${text.substring(0, 58)}...`}
            <button
              className="banner-desc-hide__btn"
              onClick={() => setShowMore((prev) => !prev)}
            >
              {showMore ? "Less" : "More"}
            </button>
          </p>
        </div>
      </div>
      <div className="results-banner-logo">
        <img
          className="results-banner-logo-bottom"
          src={bottom_consumer}
          alt="bottom_consumer"
        />
        <img
          className="results-banner-logo-main"
          src={consumer_main}
          alt="consumer_banner"
        />
        <img
          className="results-banner-logo-secondary"
          src={consumer_secondary}
          alt="consumer_secondary"
        />
      </div>
    </div>
  );
};

export default ConsumerBanner;
