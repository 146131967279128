// import { useContext } from "react";
import { Link } from "react-router-dom";
// import { AppContext } from "../../../../../App";
import credits_mortgage_bg_main from "../../../../../assets/photos/credits/credits_mortgage_bg-main.svg";
import credits_mortgage_bg from "../../../../../assets/photos/credits/credits_mortgage_bg.svg";
import { useTranslation } from "react-i18next";
import { setTypeForResult } from "../../../../../core/functions/funtions";

const CreditsMortgageBanner = () => {
  // const appContext = useContext(AppContext);
  const { t } = useTranslation();

  // const { setSelectedLoan } = appContext;

  return (
    <section className="credits-block__consumer-credits consumer-credits">
      <div className="consumer-credits-banner credits-mortgage-banner">
        <div className="consumer-credits-banner__main-content main-content">
          <div className="main-content-info">
            <h1 className="main-content-info-title">
              {t<string>("LOANS.MORTGAGE_LOANS")}
            </h1>
            <p className="main-content-info-subtitle">
              {t<string>("FINANCE.MORTGAGE_TEXT_1")} <br />
              <br /> {t<string>("FINANCE.MORTGAGE_TEXT_2")}
            </p>
            <img
              className="credits_mortgage_bg"
              src={credits_mortgage_bg}
              alt="credits_mortgage_bg"
            />
            <img
              className="credits_merging_main-bg"
              src={credits_mortgage_bg_main}
              alt="credits_mortgage_bg_main"
            />
            <Link
              to={`/result-list`}
              onClick={() => {
                setTypeForResult(4)
              }}
              className="main-content-btn"
            >
              {t<string>("FINANCE.MORTGAGE_BUTTON")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditsMortgageBanner;
