import { useTranslation } from "react-i18next";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AppContext } from "../../../../App";

import { Private } from "../../../../core/models";
import PrivateService from "../../../../core/services/private.service";
import { useAppDispatch, useAppSelector } from "../../../../core/store";
import { setModal } from "../../../../core/store/reducers/modal/modalSlice";
import { clearCache } from "../../../../core/functions/funtions";

const DeleteModal = () => {
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedUser, setSelectedUser] = useState<Private.User>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const { selectedId, choosenData } = useAppSelector((state) => state.app);
  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();

  const { reloadChecker, setReloadChecker } = appContext;

  const navigate = useNavigate();

  const adminId = JSON.parse(localStorage.getItem("savedUser") || "{}");

  const accessToken = JSON.parse(sessionStorage.getItem("accessToken") || "{}");

  useEffect(() => {
    if (choosenData) {
      setSelectedUser((prev: any) => {
        return {
          ...prev,
          ...choosenData,
        };
      });
    }
  }, [choosenData]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUserId(selectedUser.id);
    }
  }, [selectedUser]);

  const logout = () => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
    localStorage.removeItem("savedUser");
    sessionStorage.removeItem("questionnaire");
    localStorage.removeItem("calcResult");
    sessionStorage.removeItem("currentStep");
    sessionStorage.removeItem("stepper");
    window.location.reload();
    navigate(`/`);
    window.FB.logout(() => {}, { access_token: accessToken });
    clearCache();
  };

  const deleteUser = async (id: number) => {
    try {
      const response = await PrivateService.deleteUser(id);

      if (adminId.id === selectedUser?.id) {
        logout();
      }

      toast.success(response?.data?.message);
      setReloadChecker(!reloadChecker);
      dispatch(setModal(false));
    } catch (errors: any) {
      dispatch(setModal(false));
      setLoading(false);
      setError(errors?.response?.data?.message);
    }
  };

  return (
    <div className="modal__container modal-logout">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("DELETE_USER.CONFIRM")} {selectedUser?.first_name}
          {selectedUser?.last_name}
        </p>
        <div className="modal-logout__box-buttons">
          <button
            className="modal-logout__box-buttons-btn modal-logout__box-buttons-yes"
            onClick={() => {
              dispatch(setModal(false));
              deleteUser(selectedUserId);
              document.body.style.overflowY = "scroll";
            }}>
            {t<string>("DELETE_USER.YES")}
          </button>
          <button
            className="modal-logout__box-buttons-btn modal-logout__box-buttons-no"
            onClick={() => {
              dispatch(setModal(false));
              document.body.style.overflowY = "scroll";
            }}>
            {t<string>("DELETE_USER.NO")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
