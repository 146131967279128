import React, { useContext } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";
import { PrivateUIRoutes, UIRoutes } from "../../../core/router";

import { PMT, currencyFormat } from "../../../core/functions/funtions";

import * as Yup from "yup";
import useAuth from "../../../core/hooks/useAuth";
import ReactGA from "react-ga4";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { style } from "../../../core/constants/style";
import { useAppDispatch } from "../../../core/store";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../core/services/enums";
import {
  setCalculator,
  setLoginRoute,
} from "../../../core/store/reducers/app/appDataSlice";
import { useTranslation } from "react-i18next";
import classNames from "classnames"

const RefinancingForm = () => {
  const { isAuth } = useAuth();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const appContext = useContext(AppContext);

  const { setSelectionType, setCalculationExample } = appContext;

  const valueFormValidationSchema = Yup.object().shape({
    amount_1: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .matches(/[^0]+/, "Sum can't be equal 0")
      .max(30, "Max 30 symbols"),
    current_amount: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .matches(/[^0]+/, "Sum can't be equal 0")
      .max(30, "Max 30 symbols"),
    term_1: Yup.string().matches(/[^0]+/, "Term can't be equal 0"),
  });

  const formikForm = useFormik<{
    amount_1: number;
    current_amount: number;
    term_1: number;
    type: string;
  }>({
    initialValues: {
      amount_1: 10000,
      current_amount: 1000,
      term_1: 36,
      type: "6",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  // const handleSubmitForm = (values: any) => {
  //   sessionStorage.setItem("calcResult", JSON.stringify(values));
  //   dispatch(setCalculator(values));
  //   dispatch(setLoginRoute(true));

  //   ReactGA.event({
  //     category: "calc_public",
  //     action:
  //       process.env.REACT_APP_ANALYTICS_NAME === ""
  //         ? "calc_public"
  //         : `calc_public_${process.env.REACT_APP_ANALYTICS_NAME}`,
  //   });

  //   if (isAuth) {
  //     navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
  //   } else {
  //     dispatch(setModal(true));
  //     setSelectionType(AuthSelectionType.Login);
  //     dispatch(setModalType(AuthSelectionType.Auth));
  //     window.scrollTo(0, 0);
  //     document.body.style.overflow = "hidden";
  //     localStorage.setItem(
  //       "prevPage",
  //       `/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`
  //     );
  //   }
  // };
  const handleSubmitForm = (values: any) => {
    const checkedValues = {
      ...values,
      amount_1:
        formikForm.values.amount_1 < 500
          ? 500
          : Math.ceil(formikForm.values.amount_1 / 100) * 100,
    };
    localStorage.setItem("calcResult", JSON.stringify(checkedValues));
    dispatch(setCalculator(checkedValues));
    dispatch(setLoginRoute(true));

    ReactGA.event({
      category: "calc_public",
      action:
        process.env.REACT_APP_ANALYTICS_NAME === ""
          ? "calc_public"
          : `calc_public_${process.env.REACT_APP_ANALYTICS_NAME}`,
    });
    navigate(`/${UIRoutes.RESULTS_LIST}`);
    // if (isAuth) {
    //   navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.RESULTS_LIST}`);
    // } else {
    //   dispatch(setModalType(AuthSelectionType.Auth));
    //   setSelectionType(AuthSelectionType.Register);
    //   dispatch(setModal(true));
    //   window.scrollTo(0, 0);
    //   // document.body.style.overflow = "hidden";
    //   localStorage.setItem(
    //     "prevPage",
    //     `/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.RESULTS_LIST}`
    //   );
    // }
  };

  const perMonth = () => {
    let rate = 7 / 100 / 12;
    let nper = formikForm.values.term_1;
    let pv = -formikForm.values.amount_1;
    return PMT(rate, nper, pv, 0, 0).toFixed(2);
  };

  const handleExample = () => {
    dispatch(setModal(true));
    dispatch(setModalType(AuthSelectionType.calcExample));
    setCalculationExample(3);
  };

  const addAmount = () => {
    if (formikForm.values.amount_1 < 50000) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 + 100);
    }
    if (50000 - formikForm.values.amount_1 < 200) {
      formikForm.setFieldValue("amount_1", 50000);
    }
  };

  const removeAmount = () => {
    if (formikForm.values.amount_1 > 500) {
      formikForm.setFieldValue("amount_1", formikForm.values.amount_1 - 100);
    }
    if (formikForm.values.amount_1 - 200 < 500) {
      formikForm.setFieldValue("amount_1", 500);
    }
  };

  const addCurrent = () => {
    if (formikForm.values.current_amount < 50000) {
      formikForm.setFieldValue(
        "current_amount",
        formikForm.values.current_amount + 100
      );
    }
    if (50000 - formikForm.values.current_amount < 200) {
      formikForm.setFieldValue("current_amount", 50000);
    }
  };

  const removeCurrent = () => {
    if (formikForm.values.current_amount > 1000) {
      formikForm.setFieldValue(
        "current_amount",
        formikForm.values.current_amount - 100
      );
    }
    if (formikForm.values.current_amount - 200 < 1000) {
      formikForm.setFieldValue("current_amount", 1000);
    }
  };

  const addTerm = () => {
    if (formikForm.values.term_1 < 120) {
      formikForm.setFieldValue("term_1", +formikForm.values.term_1 + 1);
    }
  };
  const removeTerm = () => {
    if (formikForm.values.term_1 > 3) {
      formikForm.setFieldValue("term_1", +formikForm.values.term_1 - 1);
    }
  };

  const coercion = (e: any, maxValue: number, field: string) => {
    let valueNum =
      Number(e.target.value) < 1000
        ? Number(e.target.value)
        : Number(e.target.value.split(" ").join(""));

    if (valueNum > maxValue || valueNum < 0 || Number.isNaN(valueNum)) {
      formikForm.setFieldValue(field, maxValue);
    } else {
      formikForm.setFieldValue(field, valueNum);
    }
  };

  return (
    <div className="calc">
      <form 
      className="calc__form"
      onSubmit={formikForm.handleSubmit}>
        <div className="calc__form-container calc-container">
          <div
            className="calc-container__ranges ranges"
            onBlur={() => {
              if (formikForm.values.amount_1 < 500) {
                formikForm.setFieldValue("amount_1", 500);
              }
            }}
          >
            <div className="ranges__controls">
              <label className="ranges__controls-desc">
                {t<string>("CALCULATOR.LOAN_AMOUNT")}
              </label>
              <label className="ranges__controls-field">
                <input
                  type="text"
                  className={classNames({
                    "ranges__controls-field-put": true,
                    "ranges__controls-field-put__l": true,
                  })}
                  value={currencyFormat(formikForm.values.amount_1)}
                  onChange={(event) => {
                    coercion(event, 50000, "amount_1");
                  }}
                />
                {t<string>("CALCULATOR.BGN")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeAmount}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  type="range"
                  min={500}
                  max={50000}
                  step={100}
                  className="range__controls-input range-wrapper__input"
                  {...formikForm.getFieldProps("amount_1")}
                />
                <button
                  onClick={addAmount}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="ranges__inform">
              <span className="ranges__inform-text">500 {t<string>("CALCULATOR.BGN")}</span>
              <span className="ranges__inform-text">50 000 {t<string>("CALCULATOR.BGN")}</span>
            </div>
          </div>
          <div
            className="calc-container__ranges ranges"
            onBlur={() => {
              if (formikForm.values.current_amount < 1000) {
                formikForm.setFieldValue("current_amount", 1000);
              }
            }}
          >
            <div className="ranges__controls">
              <label className="ranges__controls-desc">
                {t<string>("CALCULATOR.CURRENT_AMOUNT")}
              </label>
              <label className="ranges__controls-field">
                <input
                  type="text"
                  className={classNames({
                    "ranges__controls-field-put": true,
                    "ranges__controls-field-put__l": true,
                  })}
                  value={currencyFormat(formikForm.values.current_amount)}
                  onChange={(event) => {
                    coercion(event, 50000, "current_amount");
                  }}
                />
                {t<string>("CALCULATOR.BGN")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeCurrent}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  type="range"
                  min={1000}
                  max={50000}
                  step={100}
                  className="range__controls-input range-wrapper__input"
                  {...formikForm.getFieldProps("current_amount")}
                />
                <button
                  onClick={addCurrent}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="ranges__inform">
              <span className="ranges__inform-text">1 000 {t<string>("CALCULATOR.BGN")}</span>
              <span className="ranges__inform-text">50 000 {t<string>("CALCULATOR.BGN")}</span>
            </div>
          </div>

          <div
            className="calc__form-ranges ranges"
            onBlur={() => {
              if (formikForm.values.term_1 < 3) {
                formikForm.setFieldValue("term_1", 3);
              }
            }}
          >
            <div className="ranges__controls">
              <label className="ranges__controls-desc">
                {t<string>("CALCULATOR.LOAN_TERM")}
              </label>
              <label className="ranges__controls-field">
                <input
                  className="ranges__controls-field-put ranges__controls-field-term"
                  type="text"
                  value={formikForm.values.term_1}
                  onChange={(e) => {
                    let valueEx = e.target.value.replace(/[^0-9]/g, "");

                    e.target.value = valueEx;

                    let value = Number(e.target.value);
                    if (e.target.value[0] !== "0") {
                      value > 120 || value < 0 || e.target.value === "0"
                        ? formikForm.setFieldValue("term_1", "120")
                        : formikForm.setFieldValue("term_1", e.target.value);
                      e.target.value === "" &&
                        formikForm.setFieldValue("term_1", "3");
                    }
                  }}
                />
                {t<string>("CALCULATOR.MONTHS")}
              </label>
              <div className="range-wrapper">
                <button
                  onClick={removeTerm}
                  type="button"
                  className="range-wrapper__step-add"
                >
                  <AiOutlineMinusCircle style={style} size={32} />
                </button>
                <input
                  type="range"
                  min={3}
                  max={120}
                  step={1}
                  className="range__controls-input range-wrapper__input"
                  {...formikForm.getFieldProps("term_1")}
                />
                <button
                  onClick={addTerm}
                  type="button"
                  className="range-wrapper__step-minus"
                >
                  <AiOutlinePlusCircle style={style} size={32} />
                </button>
              </div>
            </div>
            <div className="ranges__inform">
              <span className="ranges__inform-text">
                3 {t<string>("CALCULATOR.MONTHS")}
              </span>
              <span className="ranges__inform-text">
                120 {t<string>("CALCULATOR.MONTHS")}
              </span>
            </div>
          </div>
        </div>

        <div className="calc__form-actions info-actions">
          <p className="info-actions__text">
            {t<string>("CALCULATOR.MONTHLY_PAYMANENT")}
            <span className="info-actions__text-star">*</span>:
            <span className="info-actions__text-bolder">
              {perMonth()}
              <span> {t<string>("CALCULATOR.BGN")}/{t<string>("CALCULATOR.MONTHS")}</span>
            </span>
          </p>
          <span className="info-actions__small">
            {t<string>("CALCULATOR.INFORM")}
            <span onClick={handleExample} className="calc-hint">
              {t<string>("CALCULATOR.INFORM_LINK")}
            </span>
          </span>
          <span className="info-actions__small">
            {t<string>("CALCULATOR.INFORM_TEXT")} 7%
          </span>
          <button
            onMouseEnter={() => {
              if (formikForm.values.amount_1 < 500) {
                formikForm.setFieldValue("amount_1", 500);
              }
              if (formikForm.values.current_amount < 1000) {
                formikForm.setFieldValue("current_amount", 1000);
              }
            }}
            type="submit"
            className="info-actions__button"
          >
            {/* {isAuth ? (
              <span>{t<string>("CALCULATOR.BUTTON_LOGGED")}</span>
            ) : (
              <span>{t<string>("CALCULATOR.BUTTON")}</span>
            )} */}
            <span>{t<string>("CALCULATOR.BUTTON_LOGGED")}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default RefinancingForm;
