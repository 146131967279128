import React, { useState } from "react";

import archive from "../../assets/photos/partner/archive.svg";
import ClientsTable from "./ClientsTable";
import TableNavigation from "./TableNavigation";

const Requests = () => {
  const [status, setStatus] = useState<string>("New");
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <main className="requests-transparent">
      <div className="requests-main">
        <div className="requests-main-header">
          <h1 className="requests-main-header__title">Requests</h1>
          <button className="requests-main-header__archive">
            <span>Archive</span>
            <img src={archive} alt="archive" />
          </button>
        </div>
        <TableNavigation
          setCurrentPage={setCurrentPage}
          status={status}
          setStatus={setStatus}
        />
        <ClientsTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          status={status}
        />
      </div>
    </main>
  );
};

export default Requests;
