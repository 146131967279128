import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="terms-conditions">
      <div className="terms-conditions__box">
        <div>
          <p className="terms-conditions__box-date">
            {t<string>("PRIVACY.UPDATED")} 2023-10-31
          </p>
        </div>
        <h1 className="terms-conditions__box-title">
          {t<string>("PRIVACY.TITLE")}
        </h1>
        <div className="terms-conditions__box-desc1">
          <p className="terms-conditions__box-desc1-subtitle">
            {t<string>("PRIVACY.PRIVACY_TXT_1")}
          </p>
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.PRIVACY_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.DEFINITIONS_TERMS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.DEFINITIONS_TERMS_TXT")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_1")}</li>
            <li>{t<string>("PRIVACY.COMPANY_IS_MISSING")}</li>
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_2")}</li>
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_3")}</li>
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_4")}</li>
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_5")}</li>
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_6")}</li>
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_7")}</li>
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_8")}</li>
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_9")}</li>
            <li>
              {t<string>("PRIVACY.DEFINITIONS_TERMS_LI_10")}
              <a href="https://asistent.bg/"> www.asistent.bg</a>
            </li>
            <li>{t<string>("PRIVACY.DEFINITIONS_TERMS_LI_11")}</li>
          </ul>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.COLLECT_INFO")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.COLLECT_INFO_TXT_1")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_1")}</li>
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_2")}</li>
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_3")}</li>
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_4")}</li>
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_5")}</li>
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_6")}</li>
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_7")}</li>
          </ul>
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.COLLECT_INFO_TXT_2")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_8")}</li>
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_9")}</li>
            <li>{t<string>("PRIVACY.COLLECT_INFO_LI_10")}</li>
          </ul>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.INFO_USAGE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.INFO_USAGE_TXT")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>{t<string>("PRIVACY.INFO_USAGE_LI_1")}</li>
            <li>{t<string>("PRIVACY.INFO_USAGE_LI_2")}</li>
            <li>{t<string>("PRIVACY.INFO_USAGE_LI_3")}</li>
            <li>{t<string>("PRIVACY.INFO_USAGE_LI_4")}</li>
            <li>{t<string>("PRIVACY.INFO_USAGE_LI_5")}</li>
            <li>{t<string>("PRIVACY.INFO_USAGE_LI_6")}</li>
          </ul>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.FINTECH_END")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.FINTECH_END_TXT_1")}
            <br />
            <br />
            {t<string>("PRIVACY.FINTECH_END_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.FINTECH_CUSTOMER")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.FINTECH_CUSTOMER_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.SHARE_INFO")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.SHARE_INFO_TXT_1")}
            <br />
            <br />
            {t<string>("PRIVACY.SHARE_INFO_TXT_2")}
            <br />
            <br />
            {t<string>("PRIVACY.SHARE_INFO_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.INFO_COLLECT")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.INFO_COLLECT_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.EMAIL_USAGE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.EMAIL_USAGE_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.KEEP_TIME")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.KEEP_TIME_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.PROTECT_INFO")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.PROTECT_INFO_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.TRANSFER_INFO")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.TRANSFER_INFO_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.SECURE_INFO")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.SECURE_INFO_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.CORRECT_INFO")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.CORRECT_INFO_TXT_1")}
            <br />
            <br />
            {t<string>("PRIVACY.CORRECT_INFO_TXT_2")}
            <br />
            <br />
            {t<string>("PRIVACY.CORRECT_INFO_TXT_3")}
            <br />
            <br />
            {t<string>("PRIVACY.CORRECT_INFO_TXT_4")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.PERSONNEL")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.PERSONNEL_TXT_1")}
            <br /> <br />
            {t<string>("PRIVACY.PERSONNEL_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.SALE_BUSINESS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.SALE_BUSINESS_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.AFFILIATES")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.AFFILIATES_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.GOVERNING_LAW")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.GOVERNING_LAW_TXT_1")}
            <br /> <br />
            {t<string>("PRIVACY.GOVERNING_LAW_TXT_2")}
            <br /> <br />
            {t<string>("PRIVACY.GOVERNING_LAW_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.CONSENT")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.CONSENT_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.LINKS_WEBSITES_OTHER")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.LINKS_WEBSITES_OTHER_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.ADS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.ADS_TXT_1")}
            <br /> <br />
            {t<string>("PRIVACY.ADS_TXT_2")}
            <br /> <br />
            {t<string>("PRIVACY.ADS_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.COOK_ADS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.COOK_ADS_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.COOKS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.COOKS_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.BLOCK_DISABLE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.BLOCK_DISABLE_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.REMARKET")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.REMARKET_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.KIDS_PRIVACY")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.KIDS_PRIVACY_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.OUR_PRIVACY")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.OUR_PRIVACY_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.THIRD_PART")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.THIRD_PART_TXT_1")}
            <br />
            <br />
            {t<string>("PRIVACY.THIRD_PART_TXT_2")}
            <br />
            <br />
            {t<string>("PRIVACY.THIRD_PART_TXT_3")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.THIRD_PART_2")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.THIRD_PART_2_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.TRACKING_TECH")}
        </h2>
        <div className="terms-conditions__box-desc">
          <ul className="terms-conditions__box-desc-table">
            <li>
              {t<string>("PRIVACY.TRACKING_TECH_LI_1")}
              <br /> <br />
              {t<string>("PRIVACY.TRACKING_TECH_TXT_1")}
              <br /> <br />
              {t<string>("PRIVACY.TRACKING_TECH_TXT_2")}
              <br /> <br />
              {t<string>("PRIVACY.TRACKING_TECH_TXT_3")}
            </li>
            <li
              style={{
                marginTop: "20px",
              }}>
              {t<string>("PRIVACY.TRACKING_TECH_LI_2")}
              <br />
              <br />
              {t<string>("PRIVACY.TRACKING_TECH_TXT_4")}
            </li>
          </ul>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.INFO_GENERAL")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.INFO_GENERAL_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.GDPR")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.GDPR_TXT_1")}
            <br />
            <br />
            {t<string>("PRIVACY.GDPR_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.PERSONAL_DATA")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.PERSONAL_DATA_TXT_1")}
            <br />
            <br />
            {t<string>("PRIVACY.PERSONAL_DATA_TXT_2")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>{t<string>("PRIVACY.PERSONAL_DATA_LI_1")}</li>
            <li>{t<string>("PRIVACY.PERSONAL_DATA_LI_2")}</li>
            <li>{t<string>("PRIVACY.PERSONAL_DATA_LI_3")}</li>
            <li>{t<string>("PRIVACY.PERSONAL_DATA_LI_4")}</li>
          </ul>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.WHY_GDPR")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.WHY_GDPR_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.INDIVIDUAL_DATA")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.INDIVIDUAL_DATA_TXT_1")}
            <br /> <br />
            {t<string>("PRIVACY.INDIVIDUAL_DATA_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("PRIVACY.CONTACT_US")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("PRIVACY.CONTACT_US_TXT")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>
              {t<string>("PRIVACY.CONTACT_US_LI_1")}
              {" "}
              <span className="terms-conditions__box-email"> info@asistent.bg</span>
              {" "}
            </li>
            <li>{t<string>("PRIVACY.CONTACT_US_LI_2")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
