import { toast } from "react-toastify";

export default class CommonService {
  static toLocalNum(number: number, options: any = {}): string {
    try {
      return number?.toLocaleString("en", {
        maximumFractionDigits: 3,
        ...options,
      });
    } catch (err) {
      return "0";
    }
  }

  static getProperty(data: any, property: string) {
    if (data.hasOwnProperty(property)) {
      return data[property];
    }
    return "N/A";
  }

  static sortByDate(array: any[] = [], parram = "date", sortAsc = true) {
    try {
      if (sortAsc) {
        return array.sort(
          (a, b) =>
            new Date(a[parram]).getTime() - new Date(b[parram]).getTime()
        );
      } else {
        return array.sort(
          (a, b) =>
            new Date(b[parram]).getTime() - new Date(a[parram]).getTime()
        );
      }
    } catch (err) {
      return array;
    }
  }

  static replaceSymbol(name: string): string {
    try {
      return name
        .trim()
        .replaceAll(" ", "-")
        .replace(/[&\/\\#,+()$~%.'":*?<>{}‘’]/g, "")
        .toLocaleLowerCase();
    } catch (err) {
      return name;
    }
  }

  static getNameValues(enumType: {
    [s: number]: string;
  }): Array<{ value: number; name: string }> {
    return Object.keys(enumType)
      .filter((key) => !isNaN(parseInt(key)))
      .map((key) => {
        const val = parseInt(key);
        const name = enumType[val];
        return {
          value: val,
          name: name,
        };
      });
  }

  static prepareFormData = (file: File): any => {
    const formData = new FormData();
    return formData.append("file", file);
  };

  static showErrors(errors: any) {
    for (var error in errors) {
      toast.error(errors[error][0]);
    }
  }

  static showSuccess(success: any) {
    for (var item in success) {
      toast.error(success[item][0]);
    }
  }

  static getQueryByPropType(propName: string, query: any) {
    const keys = Object.keys(query);
    const obj: any = {};

    keys.forEach((key) => {
      if (key.includes(propName)) {
        obj[key] = query[key] || undefined;
      }
    });

    return obj;
  }
}
