import React, { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UIRoutes } from ".";
import useRole from "../hooks/useRole";

interface IProtectedRouteProps {
  path?: string;
  element?: React.ReactElement;
}

const PartnerGuard: FC<IProtectedRouteProps> = (props) => {
  const location = useLocation();

  //   const { isPartner } = useRole();
  const user = JSON.parse(localStorage.getItem("savedUser") || "");

  if (user?.role === "partner") {
    return <Outlet />;
  }
  return (
    <Navigate to={`/${UIRoutes.HOME}`} replace state={{ from: location }} />
  );
};
export default PartnerGuard;
