import axios from "axios";

import { UIRoutes } from "../router";
import { clearCache } from "../functions/funtions";

export const APIRoutesBase = {
  AUTH: `/auth`,
  PUBLIC: `/public`,
  USER: `/user`,
  ADMIN: `/admin`,
};

export const APIRoutes = {
  ...APIRoutesBase,
  ADMIN_USERS: `${APIRoutesBase.ADMIN}/users`,
  ADMIN_USERS_DOCUMENTS: `${APIRoutesBase.ADMIN}/users/documents`,
  ADMIN_USERS_SUBMITTED_APPLICATIONS: `${APIRoutesBase.ADMIN}/users/submitted_applications`,
  ADMIN_USER_QUESTIONNAIRE: `${APIRoutesBase.ADMIN}/users`,
  ADMIN_PUBLIC: `${APIRoutesBase.ADMIN}/public`,
  ADMIN_ENTITIES_BANK: `${APIRoutesBase.ADMIN}/entities/banks`,
  ADMIN_ENTITIES_CREDIT_OFFERS: `${APIRoutesBase.ADMIN}/entities/credit_offers`,
  ADMIN_ENTITIES_BANK_REVIEWS: `${APIRoutesBase.ADMIN}/entities/bank_reviews`,
  ADMIN_ENTITIES_REVIEWS: `${APIRoutesBase.ADMIN}/entities/reviews`,
  ADMIN_ENTITIES_COMMENTS: `${APIRoutesBase.ADMIN}/entities/comments`,
  ADMIN_LEADS_FROM_RATING: `${APIRoutesBase.ADMIN}/entities/leads_from_rating`,
  ADMIN_USERS_QUESTIONNAIRES: `${APIRoutesBase.ADMIN}/users/questionnaires`,
  // AUTH_LOGIN: `${APIRoutesBase.AUTH}/login`,
  AUTH_LOGIN: `${APIRoutesBase.AUTH}/regist-login`,
  // AUTH_REGISTER: `${APIRoutesBase.AUTH}/register`,
  AUTH_REGISTER: `${APIRoutesBase.AUTH}/regist-login`,
  AUTH_FORGOT_PASSWORD: `${APIRoutesBase.AUTH}/password-recovery/send-code`,
  AUTH_RESTORE_PASSWORD: `${APIRoutesBase.AUTH}/password-recovery/check-code`,
  AUTH_RESET_PASSWORD: `${APIRoutesBase.AUTH}/password-recovery/check-code`,
  AUTH_VERIFY_EMAIL: `${APIRoutesBase.AUTH}/verify-email`,
  AUTH_VERIFY_PHONE: `${APIRoutesBase.AUTH}/verify-phone`,
  AUTH_GET_IP: `https://geolocation-db.com/json/`,
  AUTH_VERIFY_EMAIL_RESEND: `${APIRoutesBase.AUTH}/verify-email/resend`,
  AUTH_VERIFY_PHONE_RESEND: `${APIRoutesBase.AUTH}/verify-phone/resend`,
  AUTH_SET_PASSWORD: `${APIRoutesBase.AUTH}/set_password`,
  AUTH_GOOGLE: `${APIRoutesBase.AUTH}/google`,
  AUTH_FACEBOOK: `${APIRoutesBase.AUTH}/facebook`,
  PUBLIC_LAUNCH_BANNERS: `${APIRoutesBase.PUBLIC}/launch_banners`,
  PUBLIC_CONTACT_US: `${APIRoutesBase.PUBLIC}/contact_us`,
  PUBLIC_BANKS: `${APIRoutesBase.PUBLIC}/banks`,
  PUBLIC_BANKS_OFFERS: `${APIRoutesBase.PUBLIC}/banks_offers`,
  PUBLIC_CREDIT_CARDS: `${APIRoutesBase.PUBLIC}/credit_cards`,
  PUBLIC_USER_BANKS_OFFERS: `${APIRoutesBase.PUBLIC}/user_banks_offers`,
  PUBLIC_BLOG_CATEGORIES: `${APIRoutesBase.PUBLIC}/blog/categories`,
  PUBLIC_NEWS_CATEGORIES: `${APIRoutesBase.PUBLIC}/news/categories`,
  PUBLIC_BLOG_POSTS: `${APIRoutesBase.PUBLIC}/blog/posts`,
  PUBLIC_NEWS_POSTS: `${APIRoutesBase.PUBLIC}/news/posts`,
  PUBLIC_REVIEWS: `${APIRoutesBase.PUBLIC}/reviews`,
  USER_CREDITS: `${APIRoutesBase.USER}/credits`,
  USER_DOCUMENTS: `${APIRoutesBase.USER}/documents`,
  USER_QUESTIONNAIRES: `${APIRoutesBase.USER}/questionnaires`,
  USER_PROFILE_DOCUMENTS: `${APIRoutesBase.USER}/documents`,
  USER_SUBMITTED_APPLICATIONS: `${APIRoutesBase.USER}/submitted_applications`,
  USER_CREDIT_OFFERS: `${APIRoutesBase.USER}/credit_offers`,
  USER_REVIEWS: `${APIRoutesBase.USER}/reviews`,
  USER_NOTIFICATIONS: `${APIRoutesBase.USER}/notifications`,
  USER_QUESTIONNAIRE: `${APIRoutesBase.USER}/questionnaire`,
  USER_LOCALE:  `${APIRoutesBase.USER}/change-locale`
};

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

$api.interceptors.request.use((config: any) => {
  if (config?.url.indexOf("login") === -1) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_TOKEN_KEY
    )}`;
  }
  else{
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_TOKEN_KEY
    )}`;
  }

  if (sessionStorage.getItem("lng")) {
    config.headers["Accept-Language"] = sessionStorage.getItem("lng");
  }

  return config;
});

$api.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (
      error.response.status === 401 &&
      window.location.href.indexOf(`/${UIRoutes.HOME}`) === -1
    ) {
      localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
      localStorage.removeItem("savedUser");
      sessionStorage.removeItem("questionnaire");
      localStorage.removeItem("calcResult");
      sessionStorage.removeItem("currentStep");
      sessionStorage.removeItem("stepper");
      window.location.href = "/";
      window.location.reload();
      clearCache();
    } else if (
      error.response.status === 403 &&
      window.location.href.indexOf(`/${UIRoutes.HOME}`) === -1
    ) {
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);
export default $api;
