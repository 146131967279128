import { FC } from "react";

import CreditsForm from "./CreditsForm/CreditsForm";

const CreditsEditContainer: FC = () => {
  return (
    <div>
      <CreditsForm />
    </div>
  );
};

export default CreditsEditContainer;
