import { useContext, useState } from "react";
import { BiCommentDetail } from "react-icons/bi";

import { AppContext } from "../../App";

import useHttpGet from "../../core/hooks/useHttpGet";
import { APIRoutes } from "../../core/http";
import { Private } from "../../core/models";

import search from "../../assets/photos/profile/search.svg";
import sortingArrow from "../../assets/photos/all/sorting-arrow.svg";
import { useAppDispatch } from "../../core/store";
import {
  setModal,
  setModalType,
} from "../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../core/services/enums";
import { useTranslation } from "react-i18next";
import { reviewsStatusArr } from "../../core/constants/reviewsStatusArr";
import PrivateService from "../../core/services/private.service";
import { toast } from "react-toastify";
import ButtonExcel from "../ButtonExcel/ButtonExcel";

const AdminLeads = () => {
  const [statusReload, setStatusReload] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterType, setFilterType] = useState("filter[search]");
  const [filter, setFilter] = useState<any>({});
  const [sorter, setSorter] = useState<any>({ "order[created_at]": "desc" });
  const [pagination, setPagination] = useState<any>({
    cursor: undefined,
    has_more_pages: false,
    per_page: 10,
  });

  const dispatch = useAppDispatch();
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  const { reloadChecker, setComments, setReviewId } = appContext;

  const { fetchedData } = useHttpGet<any>(
    `${APIRoutes.ADMIN_LEADS_FROM_RATING}`,

    {
      dependencies: [
        reloadChecker,
        pagination,
        sorter,
        filter,
        statusReload,
        reloadChecker,
      ],

      query: { ...sorter, ...filter, ...pagination },
    }
  );

  const approve = async (id: number) => {
    const searchedReview = fetchedData.payload?.collection.find(
      (review: Private.AdminReview) => review.id === id
    );

    try {
      const response = await PrivateService.updateLead(
        {
          ...searchedReview,
          status: "approved",
        },
        id
      );

      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
    setStatusReload(!statusReload);
  };

  const decline = async (id: number) => {
    const searchedReview = fetchedData.payload?.collection.find(
      (review: Private.AdminReview) => review.id === id
    );

    try {
      const response = await PrivateService.updateLead(
        {
          ...searchedReview,
          status: "declined",
        },
        id
      );
      toast.success(response?.data?.message);
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
    }
    setStatusReload(!statusReload);
  };

  const setSorterProperty = (propName: string) => {
    const newSorter: any = {};

    newSorter[propName] = sorter[propName] === "asc" ? "desc" : "asc";

    setSorter(newSorter);
  };

  const setFilterProperty = (propName: string, value: string) => {
    setFilter({ [propName]: value || undefined });
  };

  const setPaginationPage = () => {
    setPagination(()=>{
      if (pagination.per_page < 50) {
        return { ...pagination, per_page: pagination.per_page + 10 }
      }else{
        return {...pagination, per_page: 50}
      }
    });
  };

  const setDefaultPagination = () => {
    setPagination({ ...pagination, per_page: 10 });
  };

  const showComment = (comment: string, id: number | string) => {
    setComments(comment);
    setReviewId(String(id))
    dispatch(setModalType(AuthSelectionType.showComment));
    dispatch(setModal(true));
  };

  return (
    <div className="admin">
      <h2 className="admin-table__name">{t<string>("LEADS_TABLE.LEADS")}</h2>
      <ButtonExcel url='admin/leads_from_ratings_export'/>
      <div className="admin-container">
        <div className="profile-table">
          <div className="admin-search">
            <input
              disabled={!filterType}
              className="admin-search__inp"
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
              onKeyPress={(e: any) => {
                if (e.key === "Enter" && filterType) {
                  setFilterProperty(filterType, filterValue);
                }
              }}
            />
            <select
              onChange={(e) => {
                e.target.value === "search"
                  ? setFilterType(`filter[${e.target.value}]`)
                  : setFilterType(`filter[${e.target.value}][like]`);
              }}
              className="admin-search__dropdown"
            >
              <option value="search">{t<string>("LEADS_TABLE.ALL")}</option>
              <option value={"bank_id"}>
                {t<string>("LEADS_TABLE.FI_ID")}
              </option>
              <option value={"name"}>{t<string>("LEADS_TABLE.NAME")}</option>
              <option value={"user_id"}>
                {t<string>("LEADS_TABLE.REVIEW_ID")}
              </option>
              <option value={"id"}>{t<string>("LEADS_TABLE.ID")}</option>
              <option value={"rating"}>
                {t<string>("LEADS_TABLE.RATING")}
              </option>
              <option value={"comment"}>
                {t<string>("LEADS_TABLE.COMMENT")}
              </option>
            </select>
            <button
              className="admin-search__btn"
              onClick={() => {
                if (filterType) {
                  setFilterProperty(filterType, filterValue);
                }
              }}
            >
              <img src={search} alt="search" />
            </button>
          </div>
          <div className="users-container">
            <table className="users-table" cellSpacing="0" cellPadding="0">
              <thead className="users-table-head">
                <tr className="users-table-head-row">
                  <td className="users-table-head__item">
                    {t<string>("LEADS_TABLE.REVIEW_ID")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[bank_id]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("LEADS_TABLE.NAME")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[name]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("LEADS_TABLE.EMAIL")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("LEADS_TABLE.FI_ID")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("LEADS_TABLE.RATING")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[rating]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("LEADS_TABLE.COMMENT")}
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("LEADS_TABLE.CREATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[created_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  <td className="users-table-head__item">
                    {t<string>("LEADS_TABLE.UPDATED")}
                    <button
                      onClick={() => {
                        setSorterProperty("order[updated_at]");
                      }}
                    >
                      <img src={sortingArrow} alt="sortingArrow" />
                    </button>
                  </td>
                  {/* <td className="users-table-head__item">
                    {t<string>("LEADS_TABLE.STATUS")}
                  </td> */}
                  <td className="users-table-head__item"></td>
                  <td className="users-table-head__item"></td>
                </tr>
              </thead>
              <tbody className="users-table-content">
                {fetchedData?.payload?.collection.map(
                  (review: Private.Leads) => (
                    <tr className="users-table-row" key={review.id}>
                      <td className="users-table-row__item">{review.id}</td>
                      <td className="users-table-row__item">{review.name}</td>
                      <td className="users-table-row__item">{review.email}</td>
                      <td className="users-table-row__item">
                        {review.bank_id}
                      </td>
                      <td className="users-table-row__item">{review.rating}</td>
                      <td className="users-table-row__item">
                        <button
                          className="users-table-action__btn"
                          onClick={() => showComment(review.comment, review.id)}
                          title={t<string>("LEADS_TABLE.SHOW_COMMENT")}
                        >
                          <BiCommentDetail />
                        </button>
                      </td>
                      <td className="users-table-row__item">
                        <span>{`${new Date(
                          review.created_at
                        ).toLocaleTimeString()}`}</span>
                        <br></br>
                        {`${review?.created_at.slice(
                          8,
                          10
                        )}.${review?.created_at.slice(
                          5,
                          7
                        )}.${review?.created_at.slice(0, 4)}`}
                      </td>
                      <td className="users-table-row__item">
                        <span>{`${new Date(
                          review.updated_at
                        ).toLocaleTimeString()}`}</span>
                        <br></br>
                        {`${review?.updated_at.slice(
                          8,
                          10
                        )}.${review?.updated_at.slice(
                          5,
                          7
                        )}.${review?.updated_at.slice(0, 4)}`}
                      </td>
                      <td className="users-table-row__item"></td>
                      {/* <td className="users-table-row__item">
                        <select
                          onChange={(e) => {
                            if (e.target.value === "approved") {
                              approve(review.id);
                            }
                            if (e.target.value === "declined") {
                              decline(review.id);
                            }
                          }}
                          className="admin-search__dropdown-status">
                          <option value={review.status}>
                            {t<string>(
                              `REVIEWS_TABLE.${review.status.toUpperCase()}`
                            )}
                          </option>
                          {reviewsStatusArr
                            .filter(
                              (status: string) => review.status !== status
                            )
                            .map((status: string, index: number) => (
                              <option key={index} value={status}>
                                {t<string>(
                                  `REVIEWS_TABLE.${status.toUpperCase()}`
                                )}
                              </option>
                            ))}
                        </select>
                      </td> */}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          {fetchedData?.payload?.cursor.has_more_pages ? (
            <button
              className="profile-table__btn"
              onClick={() => {
                setPaginationPage();
              }}
            >
              {t<string>("LEADS_TABLE.SHOW_MORE")}
            </button>
          ) : (
            <button
              onClick={setDefaultPagination}
              className="profile-table__btn"
            >
              {t<string>("LEADS_TABLE.HIDE_ALL")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLeads;
