import benefits_first from "../../assets/photos/all/benefits_first.svg";

import benefits_second from "../../assets/photos/all/benefits_second.svg";
import benefits_third from "../../assets/photos/all/benefits_third.svg";
import benefits_fourth from "../../assets/photos/all/benefits_fourth.svg";

export const benefits = [
  {
    id: 1,
    photo: benefits_first,
  },
  {
    id: 2,
    photo: benefits_second,
  },
  {
    id: 3,
    photo: benefits_third,
  },
  {
    id: 4,
    photo: benefits_fourth,
  },
];
