import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";
// import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import DatePicker from "react-date-picker";

import { AppContext } from "../../../../App";
import addedFile from "../../../../assets/photos/all/file-added.svg";
import CalendarIcon from "../../../../assets/photos/Calendar";
import fileIcon from "../../../../assets/photos/all/add-file.svg";
import defaultAvatar from "../../../../assets/photos/profile/default-avatar.svg";

import PrivateService from "../../../../core/services/private.service";
import { filterPhoneNum, getPreviousDay } from "../../../../core/functions/funtions";
import { useAppDispatch, useAppSelector } from "../../../../core/store";
import { usePrevious } from "../../../../core/hooks/usePrevious";
import { useTranslation } from "react-i18next";
import { setModal } from "../../../../core/store/reducers/modal/modalSlice";
import { City, Country } from "country-state-city";
import { Countries } from "../../../../core/constants/countries";
import useRole from "../../../../core/hooks/useRole";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { Public } from "../../../../core/models";

const AddNewUserModal = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState<any>();
  const [cities, setCities] = useState<any>([]);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const appLanguage = useAppSelector((state) => state.app.appLanguage);
  const { isSuperAdmin } = useRole();
  const appContext = useContext(AppContext);
  const { reloadChecker, setReloadChecker } = appContext;

  const { fetchedData: banks } = useHttpGet<any>(`${APIRoutes.PUBLIC_BANKS}`, {
    dependencies: [],
  });

  const valueFormValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(121, t<string>("NEW_USER.TOO_MANY_CHARS")),
    last_name: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(121, t<string>("NEW_USER.TOO_MANY_CHARS")),
    phone: Yup.string().required().min(10),
    email: Yup.string()
      .email(t<string>("NEW_USER.INVALID_EMAIL"))
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    gender: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    verified: Yup.string(),
    role: Yup.string().required(t<string>("NEW_USER.REQUIRED_FIELD")),

    date_of_birth: Yup.string().required(
      t<string>("NEW_USER.REQUIRED_PROPERTY")
    ),
    marital_status: Yup.string().required(
      t<string>("NEW_USER.REQUIRED_PROPERTY")
    ),
    postal_code: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    housing_type: Yup.string().required(
      t<string>("NEW_USER.REQUIRED_PROPERTY")
    ),
    renting: Yup.string().when("housing_type", {
      is: (val: string) => val && val === "1",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    hypothec: Yup.string().when("housing_type", {
      is: (val: string) => val && val === "3",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    house_number: Yup.string().required(
      t<string>("NEW_USER.REQUIRED_PROPERTY")
    ),
    country_of_residence: Yup.string().required(
      t<string>("NEW_USER.REQUIRED_PROPERTY")
    ),
    citizenship: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    password: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PASS"))
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[#?!@$%^&*-])(?!.* ).{8,}$/,
        t<string>("NEW_USER.PASS_RULE")
      ),
    password_confirmation: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_FIELD"))
      .oneOf([Yup.ref("password"), null], t<string>("NEW_USER.NOT_MATCH_PASS")),
    loan_sum: Yup.number().when("debts", {
      is: (val: string) => val && val === "1",
      then: Yup.number()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .min(250, t<string>("PROFILE.MIN_LOAN_SUM")),
    }),
    insolvency: Yup.string().required(),
    city_of_residence: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_FIELD"))
      .max(55, t<string>("NEW_USER.MAX_55_CHARS")),
    street_house: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_FIELD"))
      .max(55, t<string>("NEW_USER.MAX_55_CHARS")),
    debts: Yup.string().required(t<string>("NEW_USER.REQUIRED_FIELD")),
    avatar: Yup.string(),
    job: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    monthly_income: Yup.number().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    education: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    politic: Yup.string().required(`${t<string>("COMMON.IS_REQUIRED")}`),
    position: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    income_from: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(36, t<string>("PROFILE.MAX_36_CHARS")),
    }),
    job_status: Yup.string().when("job", {
      is: (val: string) => val && val === "0",
      then: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    working_place: Yup.string().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.string()
        .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
        .max(55, t<string>("PROFILE.MAX_55_CHARS")),
    }),
    experience: Yup.number().when("job", {
      is: (val: string) => val && val === "1",
      then: Yup.number().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
    bank: Yup.number().when("role", {
      is: (val: string) => val && val === "partner",
      then: Yup.number().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    }),
  });

  const formikForm = useFormik<{
    id: number;
    status: string;
    verified: string | number;
    bank: string | number;
    role: string | number;
    email: string;
    phone: string;
    first_name: string;
    last_name: string;
    gender: string;
    date_of_birth: string;
    citizenship: string;
    personal_code: string;
    passport: string;
    marital_status: string;
    children: string;
    country_of_residence: string;
    city_of_residence: string;
    housing_type: string;
    street_house: string;
    postal_code: string;
    house_number: string;
    apartment_number: string;
    job: string;
    working_place: string;
    position: string;
    experience: string;
    job_status: string;
    income_from: string;
    monthly_income: string;
    certificate: File | null;
    education: string;
    politic: string;
    debts: string;
    loan_sum: number | string;
    insolvency: string | number;
    avatar: any;
    egn: boolean | string;
    password: string;
    password_confirmation: string;
    renting: string;
    hypothec: string;
  }>({
    initialValues: {
      id: 0,
      status: "",
      verified: "",
      role: "",
      email: "",
      phone: "",
      bank: "",
      first_name: "",
      last_name: "",
      gender: "",
      date_of_birth: "",
      citizenship: "",
      personal_code: "",
      passport: "",
      marital_status: "",
      children: "",
      country_of_residence: "",
      city_of_residence: "",
      housing_type: "",
      street_house: "",
      postal_code: "",
      house_number: "",
      apartment_number: "",
      job: "1", // TODO: check for 1
      working_place: "",
      position: "",
      experience: "",
      job_status: "",
      income_from: "",
      monthly_income: "",
      certificate: null,
      education: "",
      politic: "",
      debts: "",
      loan_sum: "",
      insolvency: "",
      avatar: "",
      egn: false,
      password: "",
      password_confirmation: "",
      renting: "",
      hypothec: "",
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: async (values) => {
      handleSubmitForm(values);
    },
  });

  const prevEgn = usePrevious(formikForm.values.personal_code);
  const prevIncome = usePrevious(formikForm.values.income_from);
  const prevPassport = usePrevious(formikForm.values.passport);

  const pictureLoader = (e: any) => {
    setCropper(undefined);

    e.preventDefault();

    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };

    reader.readAsDataURL(files[0]);
  };

  const jobInfoClear = (value: string) => {
    formikForm.setFieldValue("working_place", "");
    formikForm.setFieldValue("position", "");
    formikForm.setFieldValue("experience", "");
    formikForm.setFieldValue("monthly_income", "");
    formikForm.setFieldValue("income_from", "");
    formikForm.setFieldValue("job_status", "");
    formikForm.setFieldValue("job", value);
    formikForm.validateForm();
  };

  const urltoFile = (url: string, filename: string, mimeType: string) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      formikForm.setFieldValue(
        "avatar",
        cropper.getCroppedCanvas().toDataURL()
      );
      setImage(undefined);
    }
  };

  const handleSubmitForm = async (values: any) => {
    setLoading(true);

    const avatar = values.avatar
      ? await urltoFile(
          values.avatar,
          `photo${Math.random() * 10000}.png`,
          "image/png"
        )
      : "";

    if (
      !personalCodeValidation() ||
      (formikForm.values.personal_code.length === 0 && !formikForm.values.egn)
    ) {
      toast.error(`${t<string>("PROFILE.CHECK_FIELDS")}`);
      return;
    }

    delete values.egn;
    try {
      const response = await PrivateService.addNewUser({
        ...values,
        avatar,
      });

      setLoading(false);
      setReloadChecker(!reloadChecker);
      toast.success(response?.data?.message);
      dispatch(setModal(false));
    } catch (errors: any) {
      toast.error(errors?.response?.data?.message);
      setLoading(false);
      dispatch(setModal(true));
    }
  };

  const personalCodeValidation = () => {
    if (
      formikForm.values.personal_code.length === 0 ||
      formikForm.values.personal_code.length === 10
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    Country.getAllCountries().filter((country) => {
      if (country.name === formikForm.values.country_of_residence) {
        setCities(City.getCitiesOfCountry(country.isoCode));
      }
    });
  }, [formikForm.values.country_of_residence]);

  return (
    <div className="modal__container modal-addUser">
      <div className="modal-logout__box">
        <p className="modal-logout__box-title">
          {t<string>("NEW_USER.ADD_USER")}
        </p>
        <form className="about" onSubmit={formikForm.handleSubmit}>
          <h2 className="about__title">
            {t<string>("NEW_USER.PROFILE_PHOTO")}
          </h2>
          <div className="about__form-box about-box">
            {image ? (
              <>
                {image && (
                  <div className="about-box__cropper">
                    <Cropper
                      style={{
                        height: 250,
                        width: 250,
                      }}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={image}
                      viewMode={1}
                      minCropBoxHeight={200}
                      minCropBoxWidth={200}
                      cropBoxResizable={false}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                      guides={true}
                    />
                    <button
                      className="about-box__cropper-btn"
                      onClick={getCropData}
                    >
                      {t<string>("NEW_USER.CROP")}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="about-box__avatar">
                  <div className="about-box__avatar-image">
                    <img
                      className="about-box__avatar-image-own"
                      src={
                        formikForm.values.avatar
                          ? formikForm.values.avatar
                          : defaultAvatar
                      }
                      alt="avatar"
                    />
                  </div>
                  <label className="about-box__file">
                    <div className="about-box__file-block file-block">
                      <img
                        className="file-block__icon"
                        src={fileIcon}
                        alt="file"
                      />
                      <span className="file-block__txt">
                        {formikForm.values.avatar
                          ? t<string>("NEW_USER.CHANGE_AVATAR")
                          : t<string>("NEW_USER.ADD_AVATAR")}
                      </span>
                    </div>
                    <input
                      className="file-block__inp"
                      type="file"
                      onChange={(e) => {
                        if (e.target.files) {
                          pictureLoader(e);
                        }
                      }}
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                    />
                  </label>
                </div>
              </>
            )}
          </div>
          <div className="about__form">
            <div className="about__form-avatar">
              {/* <img src={avatar} alt="avatar" /> */}
            </div>
            <h2 className="about__title">
              {t<string>("NEW_USER.PERSONAL_INFO")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.first_name,
                    "about-box__field-inp-error":
                      formikForm.touched.first_name &&
                      formikForm.errors.first_name,
                  })}
                >
                  {t<string>("NEW_USER.FIRST_NAME")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched.first_name &&
                      formikForm.errors.first_name,
                    "about-box__field-inp-filled": formikForm.values.first_name,
                  })}
                  placeholder={t<string>("NEW_USER.FIRST_NAME_PLACEHOLDER")}
                  {...formikForm.getFieldProps("first_name")}
                />
              </label>
            </div>
            {formikForm.touched.first_name && formikForm.errors.first_name && (
              <div className="form-control-error">
                {formikForm.errors.first_name}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.last_name &&
                      formikForm.errors.last_name,
                    "about-box__field-name-filled": formikForm.values.last_name,
                  })}
                >
                  {t<string>("NEW_USER.LAST_NAME")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched.last_name &&
                      formikForm.errors.last_name,
                    "about-box__field-inp-filled": formikForm.values.last_name,
                  })}
                  placeholder={t<string>("NEW_USER.LAST_NAME_PLACEHOLDER")}
                  {...formikForm.getFieldProps("last_name")}
                />
              </label>
            </div>
            {formikForm.touched.last_name && formikForm.errors.last_name && (
              <div className="form-control-error">
                {formikForm.errors.last_name}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.phone,
                    "about-box__field-inp-error":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}
                >
                  {t<string>("NEW_USER.PHONE_NUMBER")}
                </span>
                <InputMask
                  name="phone"
                  className={classNames("data-input__input-field", {
                    "form-control": true,
                    "form-control-touched":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}
                  maskChar=" "
                  mask="+35\9 (999) 999-9999"
                  placeholder="+359 (___) ___-___ "
                  value={formikForm.values.phone}
                  onChange={(event) => {
                    formikForm.setFieldValue("phone", formikForm.setFieldValue(
                      "phone",
                      filterPhoneNum(event.target.value)
                    ));
                  }}
                />
                {/* <PhoneInput
                  enableSearch={true}
                  dropdownClass="phone-dropdown"
                  countryCodeEditable={false}
                  inputClass={classNames({
                    "form-control": true,
                    "form-control-touched":
                      formikForm.touched.phone && formikForm.errors.phone,
                  })}
                  country={"bg"}
                  value={formikForm.values.phone}
                  onChange={(phone) => {
                    formikForm.setFieldValue("phone", phone);
                  }}
                /> */}
              </label>
            </div>
            {formikForm.touched.phone && formikForm.errors.phone && (
              <div className="form-control-error">
                {formikForm.errors.phone}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.email && formikForm.errors.email,
                    "about-box__field-name-filled": formikForm.values.email,
                  })}
                >
                  {t<string>("NEW_USER.EMAIL")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-touched":
                      formikForm.touched.email && formikForm.errors.email,
                    "about-box__field-inp-filled": formikForm.values.email,
                  })}
                  placeholder="admin@gmail.com"
                  {...formikForm.getFieldProps("email")}
                />
              </label>
            </div>
            {formikForm.touched.email && formikForm.errors.email && (
              <div className="form-control-error">
                {formikForm.errors.email}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.gender,
                    "about-box__field-inp-error":
                      formikForm.touched.gender && formikForm.errors.gender,
                  })}
                >
                  {t<string>("NEW_USER.GENDER")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.gender,
                    "about-box__field-inp-touched":
                      formikForm.touched.gender && formikForm.errors.gender,
                  })}
                  {...formikForm.getFieldProps("gender")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("NEW_USER.MALE")}</option>
                  <option value="1">{t<string>("NEW_USER.FEMALE")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched.gender && formikForm.errors.gender && (
              <div className="form-control-error">
                {formikForm.errors.gender}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.date_of_birth,
                    "about-box__field-inp-error":
                      formikForm.touched.date_of_birth &&
                      formikForm.errors.date_of_birth,
                  })}
                >
                  {t<string>("NEW_USER.DATE_OF_BIRTH")}
                </span>
                <DatePicker
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.date_of_birth,
                    "about-box__field-inp-touched":
                      formikForm.touched.date_of_birth &&
                      formikForm.errors.date_of_birth,
                  })}
                  format="dd/MM/y"
                  dayPlaceholder={t<string>("COMMON.CALENDAR_DAY")}
                  monthPlaceholder={t<string>("COMMON.CALENDAR_MONTH")}
                  yearPlaceholder={t<string>("COMMON.CALENDAR_YEAR")}
                  locale={appLanguage}
                  maxDate={getPreviousDay()}
                  minDate={new Date(new Date("1900-01-01").getTime())}
                  onChange={(e: any) => {
                    if (e) {
                      formikForm.setFieldValue(
                        "date_of_birth",
                        `${e.getFullYear()}-${
                          Number(e.getMonth() + 1) < 10
                            ? "0" + `${e.getMonth() + 1}`
                            : e.getMonth() + 1
                        }-${
                          Number(e.getDate()) < 10
                            ? "0" + `${e.getDate()}`
                            : e.getDate()
                        }`
                      );
                    }
                  }}
                  value={
                    formikForm.values.date_of_birth
                      ? new Date(
                          new Date(formikForm.values.date_of_birth).getTime()
                        )
                      : undefined
                  }
                  calendarIcon={<CalendarIcon />}
                />
                {formikForm.touched.date_of_birth &&
                  formikForm.errors.date_of_birth && (
                    <div className="form-control-error">
                      {formikForm.errors.date_of_birth}
                    </div>
                  )}
              </label>
            </div>
          </div>
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("NEW_USER.PASSPORT_INFO")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.citizenship &&
                      formikForm.errors.citizenship,
                  })}
                >
                  {t<string>("NEW_USER.CITIZENSHIP")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.citizenship,
                    "about-box__field-inp-touched":
                      formikForm.touched.citizenship &&
                      formikForm.errors.citizenship,
                  })}
                  value={formikForm.values.citizenship}
                  onChange={(e) =>
                    formikForm.setFieldValue("citizenship", e.target.value)
                  }
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("NEW_USER.BULGARIA")}</option>
                  <option value="1">{t<string>("NEW_USER.OTHER")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched.citizenship &&
              formikForm.errors.citizenship && (
                <div className="form-control-error">
                  {formikForm.errors.citizenship}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      (!personalCodeValidation() ||
                        (formikForm.values.personal_code.length === 0 &&
                          !formikForm.values.egn)) &&
                      formikForm.touched.personal_code,
                  })}
                >
                  {t<string>("UPDATE_USER.PERSONAL_IDENTIFICATION_CODE")}
                </span>
                <input
                  disabled={formikForm.values.egn === "checked"}
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.personal_code,
                    "about-box__field-inp-touched":
                      (!personalCodeValidation() ||
                        (formikForm.values.personal_code.length === 0 &&
                          !formikForm.values.egn)) &&
                      formikForm.touched.personal_code,
                  })}
                  placeholder="1002498384"
                  value={formikForm.values.personal_code}
                  type="text"
                  onChange={(event) => {
                    if (
                      +event.target.value === 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("personal_code", "");
                    } else {
                      if (event.target.value.length > 10) {
                        formikForm.setFieldValue("personal_code", prevEgn);
                      } else {
                        formikForm.setFieldValue(
                          "personal_code",
                          event.target.value
                        );
                        event.target.value.length > 0
                          ? formikForm.setFieldValue("egn", true)
                          : formikForm.setFieldValue("egn", false);
                      }
                    }
                    personalCodeValidation();
                  }}
                />
              </label>
              <label className="about-box__egn">
                <input
                  checked={formikForm.values.egn === "checked"}
                  type="checkbox"
                  onChange={() => {
                    if (formikForm.values.egn !== "checked") {
                      formikForm.setFieldValue("egn", "checked");
                      formikForm.setFieldValue("personal_code", "");
                    } else {
                      formikForm.setFieldValue("egn", false);
                    }
                  }}
                />
                {t<string>("UPDATE_USER.NO_EGN")}
              </label>
            </div>
            {!personalCodeValidation() && (
              <div className="form-control-error">
                {t<string>("UPDATE_QUESTIONNAIRE.EGN_RULE")}
              </div>
            )}
            {!formikForm.values.egn &&
              formikForm.values.personal_code.length !== 10 &&
              formikForm.touched.personal_code && (
                <div className="form-control-error">
                  {t<string>("PROFILE.ADD_EGN")}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-inp-error":
                      formikForm.touched.passport && formikForm.errors.passport,
                  })}
                >
                  {t<string>("NEW_USER.PASSPORT_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled": formikForm.values.passport,
                    "about-box__field-inp-touched":
                      formikForm.touched.passport && formikForm.errors.passport,
                  })}
                  placeholder="1002498384"
                  type="text"
                  value={formikForm.values.passport}
                  onChange={(event) => {
                    if (
                      +event.target.value < 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("passport", "");
                    } else {
                      if (event.target.value.length > 9) {
                        formikForm.setFieldValue("passport", prevPassport);
                      } else {
                        formikForm.setFieldValue(
                          "passport",
                          event.target.value
                        );
                      }
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.passport && formikForm.errors.passport && (
              <div className="form-control-error">
                {formikForm.errors.passport}
              </div>
            )}
          </div>
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("NEW_USER.FAMILY_STATUS")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.marital_status,
                    "about-box__field-inp-error":
                      formikForm.touched.marital_status &&
                      formikForm.errors.marital_status,
                  })}
                >
                  {t<string>("NEW_USER.MARITAL_STATUS")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.marital_status,
                    "about-box__field-inp-touched":
                      formikForm.touched.marital_status &&
                      formikForm.errors.marital_status,
                  })}
                  {...formikForm.getFieldProps("marital_status")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("NEW_USER.MARRIED")}</option>
                  <option value="1">{t<string>("NEW_USER.SINGLE")}</option>
                  <option value="2">
                    {t<string>("NEW_USER.CIVIL_MARRIAGE")}
                  </option>
                  <option value="3">{t<string>("NEW_USER.DIVORCED")}</option>
                  <option value="4">{t<string>("NEW_USER.WIDOWED")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched.marital_status &&
              formikForm.errors.marital_status && (
                <div className="form-control-error">
                  {formikForm.errors.marital_status}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.children,
                    "about-box__field-inp-error":
                      formikForm.touched.children && formikForm.errors.children,
                  })}
                >
                  {t<string>("NEW_USER.UNDER_18")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.children,
                    "about-box__field-inp-touched":
                      formikForm.touched.children && formikForm.errors.children,
                  })}
                  {...formikForm.getFieldProps("children")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">{t<string>("NEW_USER.MORE_THAN_3")}</option>
                </select>
              </label>
            </div>
          </div>
          <div className="about__form">
            <h2 className="about__title">
              {t<string>("NEW_USER.RESIDENCE_ADDRESS")}
            </h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.country_of_residence,
                    "about-box__field-inp-error":
                      formikForm.touched.country_of_residence &&
                      formikForm.errors.country_of_residence,
                  })}
                >
                  {t<string>("NEW_USER.COUNTRY_OF_RESIDENCE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.country_of_residence,
                    "about-box__field-inp-touched":
                      formikForm.touched.country_of_residence &&
                      formikForm.errors.country_of_residence,
                  })}
                  {...formikForm.getFieldProps("country_of_residence")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  {[
                    ...Country.getAllCountries().filter(
                      (country) => country.name === "Bulgaria"
                    ),
                    ...Country.getAllCountries().filter(
                      (country) => country.name !== "Bulgaria"
                    ),
                  ].map((country: any, index: number) => (
                    <option
                      className={classNames({
                        "bul-selected":
                          country.name === "Bulgaria" ||
                          country.name === "България",
                      })}
                      value={country.name}
                      key={index}
                    >
                      {t<string>(`COUNTRY.${country.name.toUpperCase()}`)
                        ?.split(" ")
                        .map(
                          (word) =>
                            word[0]?.toUpperCase() + word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            {formikForm.touched.country_of_residence &&
              formikForm.errors.country_of_residence && (
                <div className="form-control-error">
                  {formikForm.errors.country_of_residence}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.city_of_residence,
                    "about-box__field-inp-error":
                      formikForm.touched.city_of_residence &&
                      formikForm.errors.city_of_residence,
                  })}
                >
                  {t<string>("NEW_USER.CITY_OF_RESIDENCE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.city_of_residence,
                    "about-box__field-inp-touched":
                      formikForm.touched.city_of_residence &&
                      formikForm.errors.city_of_residence,
                  })}
                  {...formikForm.getFieldProps("city_of_residence")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  {formikForm.values.country_of_residence === "Bulgaria"
                    ? cities.map((city: any, index: number) => (
                        <option value={city.name} key={index}>
                          {t<string>(`CITIES.${city.name.toUpperCase()}`)}
                        </option>
                      ))
                    : cities.map((city: any, index: number) => (
                        <option value={city.name} key={index}>
                          {city.name}
                        </option>
                      ))}
                </select>
              </label>
            </div>
            {formikForm.touched.city_of_residence &&
              formikForm.errors.city_of_residence && (
                <div className="form-control-error">
                  {formikForm.errors.city_of_residence}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.housing_type,
                    "about-box__field-inp-error":
                      formikForm.touched.housing_type &&
                      formikForm.errors.housing_type,
                  })}
                >
                  {t<string>("NEW_USER.TYPE_OF_HOUSING")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled":
                      formikForm.values.housing_type,
                    "about-box__field-inp-touched":
                      formikForm.touched.housing_type &&
                      formikForm.errors.housing_type,
                  })}
                  value={formikForm.values.housing_type}
                  onChange={(event: any) => {
                    formikForm.setFieldValue(
                      "housing_type",
                      event.target.value
                    );
                    formikForm.setFieldValue("renting", "");
                    formikForm.setFieldValue("hypothec", "");
                  }}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("NEW_USER.OWN_HOME")}</option>
                  <option value="1">{t<string>("NEW_USER.RENTING")}</option>
                  <option value="2">{t<string>("NEW_USER.PARENTAL")}</option>
                  <option value="3">
                    {t<string>("NEW_USER.OWN_WITH_MORTGAGE")}
                  </option>
                  <option value="4">{t<string>("NEW_USER.OTHER")}</option>
                </select>
              </label>
            </div>
            {formikForm.touched.housing_type &&
              formikForm.errors.housing_type && (
                <div className="form-control-error">
                  {formikForm.errors.housing_type}
                </div>
              )}
            {formikForm.values.housing_type === "1" && (
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled": formikForm.values.renting,
                      "about-box__field-inp-error":
                        formikForm.touched.renting && formikForm.errors.renting,
                    })}
                  >
                    {t<string>("PROFILE.RENT_EXPENSE")}
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.renting,
                      "about-box__field-inp-touched":
                        formikForm.touched.renting && formikForm.errors.renting,
                    })}
                    type="text"
                    value={formikForm.values.renting}
                    onChange={(event: any) => {
                      if (
                        +event.target.value === 0 ||
                        Number.isNaN(+event.target.value)
                      ) {
                        formikForm.setFieldValue("renting", "");
                      } else {
                        formikForm.setFieldValue("renting", event.target.value);
                      }
                    }}
                  />
                </label>
              </div>
            )}
            {formikForm.values.housing_type === "3" && (
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.hypothec,
                      "about-box__field-inp-error":
                        formikForm.touched.hypothec &&
                        formikForm.errors.hypothec,
                    })}
                  >
                    {t<string>("PROFILE.OWN_EXPENSE_MORTGAGE")}
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled": formikForm.values.hypothec,
                      "about-box__field-inp-touched":
                        formikForm.touched.hypothec &&
                        formikForm.errors.hypothec,
                    })}
                    type="text"
                    value={formikForm.values.hypothec}
                    onChange={(event: any) => {
                      if (
                        +event.target.value === 0 ||
                        Number.isNaN(+event.target.value)
                      ) {
                        formikForm.setFieldValue("hypothec", "");
                      } else {
                        formikForm.setFieldValue(
                          "hypothec",
                          event.target.value
                        );
                      }
                    }}
                  />
                </label>
                {formikForm.touched.hypothec && formikForm.errors.hypothec && (
                  <div className="form-control-error">
                    {formikForm.errors.hypothec}
                  </div>
                )}
              </div>
            )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.street_house,
                    "about-box__field-inp-error":
                      formikForm.touched.street_house &&
                      formikForm.errors.street_house,
                  })}
                >
                  {t<string>("NEW_USER.STREET")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.street_house,
                    "about-box__field-inp-touched":
                      formikForm.touched.street_house &&
                      formikForm.errors.street_house,
                  })}
                  placeholder={t<string>("NEW_USER.STREET_HOUSE_PLACEHOLDER")}
                  {...formikForm.getFieldProps("street_house")}
                />
              </label>
            </div>
            {formikForm.touched.street_house &&
              formikForm.errors.street_house && (
                <div className="form-control-error">
                  {formikForm.errors.street_house}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.house_number,
                    "about-box__field-inp-error":
                      formikForm.touched.house_number &&
                      formikForm.errors.house_number,
                  })}
                >
                  {t<string>("NEW_USER.HOUSE_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.house_number,
                    "about-box__field-inp-touched":
                      formikForm.touched.house_number &&
                      formikForm.errors.house_number,
                  })}
                  placeholder="7"
                  type="text"
                  value={formikForm.values.house_number}
                  onChange={(event) => {
                    if (
                      +event.target.value === 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("house_number", "");
                    } else {
                      formikForm.setFieldValue(
                        "house_number",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.house_number &&
              formikForm.errors.house_number && (
                <div className="form-control-error">
                  {formikForm.errors.house_number}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.apartment_number,
                    "about-box__field-inp-error":
                      formikForm.touched.apartment_number &&
                      formikForm.errors.apartment_number,
                  })}
                >
                  {t<string>("NEW_USER.APARTMENT_NUMBER")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.apartment_number,
                    "about-box__field-inp-touched":
                      formikForm.touched.apartment_number &&
                      formikForm.errors.apartment_number,
                  })}
                  placeholder="24"
                  type="text"
                  value={formikForm.values.apartment_number}
                  onChange={(event) => {
                    if (
                      +event.target.value === 0 ||
                      Number.isNaN(+event.target.value)
                    ) {
                      formikForm.setFieldValue("apartment_number", "");
                    } else {
                      formikForm.setFieldValue(
                        "apartment_number",
                        +event.target.value
                      );
                    }
                  }}
                />
              </label>
            </div>
            {formikForm.touched.apartment_number &&
              formikForm.errors.country_of_residence && (
                <div className="form-control-error">
                  {formikForm.errors.apartment_number}
                </div>
              )}
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled":
                      formikForm.values.postal_code,
                    "about-box__field-inp-error":
                      formikForm.touched.postal_code &&
                      formikForm.errors.postal_code,
                  })}
                >
                  {t<string>("NEW_USER.POSTAL_CODE")}
                </span>
                <input
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-inp-filled":
                      formikForm.values.postal_code,
                    "about-box__field-inp-touched":
                      formikForm.touched.postal_code &&
                      formikForm.errors.postal_code,
                  })}
                  placeholder="203-320"
                  {...formikForm.getFieldProps("postal_code")}
                />
              </label>
            </div>
            {formikForm.touched.postal_code &&
              formikForm.errors.postal_code && (
                <div className="form-control-error">
                  {formikForm.errors.postal_code}
                </div>
              )}
          </div>
          <h4 className="about-ask__question">
            {t<string>("NEW_USER.ANY_LOANS")}
          </h4>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.debts,
                  "about-box__field-inp-touched":
                    formikForm.touched.debts && formikForm.errors.debts,
                })}
                value={formikForm.values.debts}
                onChange={(e) => {
                  if (e.target.value === "0") {
                    formikForm.setFieldValue("loan_sum", "");
                  }
                  formikForm.setFieldValue("debts", e.target.value);
                }}
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="0">{t<string>("NEW_USER.NO")}</option>
                <option value="1">{t<string>("NEW_USER.YES")}</option>
              </select>
            </label>
          </div>
          {formikForm.touched.debts && formikForm.errors.debts && (
            <div className="form-control-error">{formikForm.errors.debts}</div>
          )}
          {formikForm.values.debts !== "0" &&
            formikForm.values.debts !== "" && (
              <div className="about__form">
                <label
                  className="about__form-range about-range"
                  htmlFor="family-no"
                >
                  <span
                    className={classNames({
                      "about-range__name": true,
                      "about-box__field-inp-error":
                        formikForm.touched.loan_sum &&
                        formikForm.errors.loan_sum,
                    })}
                  >
                    {t<string>("NEW_USER.ALL_LOANS")}
                  </span>
                  <div className="about-range__gen">
                    <label className="about-range__gen-field">
                      <input
                        type="text"
                        className="about-range__gen-field-put"
                        placeholder="1000"
                        value={formikForm.values.loan_sum}
                        onChange={(event) => {
                          let min = 500;
                          let max = 100000;

                          if (
                            +event.target.value <= 0 ||
                            Number.isNaN(+event.target.value)
                          ) {
                            formikForm.setFieldValue("loan_sum", "");
                          } else {
                            if (
                              (event.target.value.length === 3 ||
                                event.target.value.length < 3) &&
                              +event.target.value < min
                            ) {
                              formikForm.setFieldValue("loan_sum", min);
                            } else if (
                              (event.target.value.length === 6 ||
                                event.target.value.length > 6) &&
                              +event.target.value > max
                            ) {
                              formikForm.setFieldValue("loan_sum", max);
                            } else {
                              formikForm.setFieldValue(
                                "loan_sum",
                                +event.target.value
                              );
                            }
                          }
                        }}
                      />
                      {t<string>("NEW_USER.BGN")}
                    </label>
                    <input
                      className="about-range__gen-inp"
                      type="range"
                      step={10000}
                      min={0}
                      max={1000000}
                      {...formikForm.getFieldProps("loan_sum")}
                    />
                  </div>
                </label>
              </div>
            )}
          <div className="about__form">
            <div className="about__form-box about-ask">
              <h4 className="about-ask__question">
                {t<string>("NEW_USER.INSOLVENCY")}
              </h4>
              <div className="about-ask__variants">
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.insolvency === "1",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("insolvency", "1")}
                >
                  {t<string>("UPDATE_QUESTIONNAIRE.YES")}
                </button>
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.insolvency === "0",
                  })}
                  type="button"
                  onClick={() => formikForm.setFieldValue("insolvency", "0")}
                >
                  {t<string>("UPDATE_QUESTIONNAIRE.NO")}
                </button>
              </div>
            </div>
          </div>
          <div className="about__form">
            <div className="about__form-box about-ask">
              <h4 className="about-ask__question">
                {t<string>("NEW_USER.JOB_EXIST")}
              </h4>
              <div className="about-ask__variants">
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.job === "1",
                  })}
                  type="button"
                  onClick={() => jobInfoClear("1")}
                >
                  {t<string>("UPDATE_QUESTIONNAIRE.YES")}
                </button>
                <button
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.job === "0",
                  })}
                  type="button"
                  onClick={() => jobInfoClear("0")}
                >
                  {t<string>("UPDATE_QUESTIONNAIRE.NO")}
                </button>
              </div>
            </div>
          </div>
          {formikForm.values.job === "0" && (
            <div className="about__form">
              <h2 className="about__title">
                {t<string>("NEW_USER.LIFESTYLE")}
              </h2>
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.job_status,
                      "about-box__field-inp-error":
                        formikForm.touched?.job_status &&
                        formikForm.errors?.job_status,
                    })}
                  >
                    {t<string>("PROFILE.STATUS")}
                  </span>
                  <select
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-select": true,
                      "about-box__field-inp-filled":
                        formikForm.values.job_status,
                      "about-box__field-inp-touched":
                        formikForm.touched?.job_status &&
                        formikForm.errors?.job_status,
                    })}
                    {...formikForm.getFieldProps("job_status")}
                  >
                    <option disabled hidden value="">
                      {t<string>("PROFILE.PLEASE_SELECT")}
                    </option>
                    <option value="0">{t<string>("PROFILE.STUDENT")}</option>
                    <option value="1">{t<string>("PROFILE.UNEMPLOYED")}</option>
                    <option value="2">{t<string>("PROFILE.PENSIONER")}</option>
                  </select>
                </label>
              </div>
              {formikForm.touched?.job_status &&
                formikForm.errors?.job_status && (
                  <div className="form-control-error">
                    {formikForm.errors?.job_status}
                  </div>
                )}
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.income_from,
                      "about-box__field-inp-error":
                        formikForm.touched.income_from &&
                        formikForm.errors.income_from,
                    })}
                  >
                    {t<string>("NEW_USER.INCOME_WAY")}
                  </span>
                  <input
                    type="text"
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled":
                        formikForm.values.income_from,
                      "about-box__field-inp-touched":
                        formikForm.touched?.income_from &&
                        formikForm.errors?.income_from,
                    })}
                    value={formikForm.values.income_from}
                    onChange={(event) => {
                      if (event.target.value.length > 36) {
                        formikForm.setFieldValue("income_from", prevIncome);
                      } else {
                        formikForm.setFieldValue(
                          "income_from",
                          event.target.value
                        );
                      }
                    }}
                  />
                </label>
              </div>
              {formikForm.touched?.income_from &&
                formikForm.errors?.income_from && (
                  <div className="form-control-error">
                    {formikForm.errors?.income_from}
                  </div>
                )}
            </div>
          )}
          {formikForm.values.job === "1" && (
            <div className="about__form">
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.working_place,
                      "about-box__field-inp-error":
                        formikForm.touched?.working_place &&
                        formikForm.errors?.working_place,
                    })}
                  >
                    {t<string>("NEW_USER.WORK_PLACE")}
                  </span>
                  <input
                    type="text"
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled":
                        formikForm.values.working_place,
                      "about-box__field-inp-touched":
                        formikForm.touched?.working_place &&
                        formikForm.errors?.working_place,
                    })}
                    {...formikForm.getFieldProps("working_place")}
                  />
                </label>
              </div>
              {formikForm.touched?.working_place &&
                formikForm.errors?.working_place && (
                  <div className="form-control-error">
                    {formikForm.errors?.working_place}
                  </div>
                )}
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.position,
                      "about-box__field-inp-error":
                        formikForm.touched?.position &&
                        formikForm.errors?.position,
                    })}
                  >
                    {t<string>("NEW_USER.POSITION")}
                  </span>
                  <select
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-select": true,
                      "about-box__field-inp-filled": formikForm.values.position,
                      "about-box__field-inp-touched":
                        formikForm.touched?.position &&
                        formikForm.errors?.position,
                    })}
                    {...formikForm.getFieldProps("position")}
                  >
                    <option disabled hidden value="">
                      {t<string>("PROFILE.PLEASE_SELECT")}
                    </option>
                    <option value="0">{t<string>("NEW_USER.EMPLOYED")}</option>
                    <option value="1">{t<string>("NEW_USER.CONTRACT")}</option>
                    <option value="2">
                      {t<string>("NEW_USER.SELF_EMPLOYED")}
                    </option>
                    <option value="3">{t<string>("NEW_USER.FREELANCE")}</option>
                    <option value="4">{t<string>("NEW_USER.CIVIL")}</option>
                  </select>
                </label>
              </div>
              {formikForm.touched?.position && formikForm.errors?.position && (
                <div className="form-control-error">
                  {formikForm.errors?.position}
                </div>
              )}
              <div className="about__form-box about-box">
                <label className="about-box__field">
                  <span
                    className={classNames({
                      "about-box__field-name": true,
                      "about-box__field-name-filled":
                        formikForm.values.experience,
                      "about-box__field-inp-error":
                        formikForm.touched?.experience &&
                        formikForm.errors?.experience,
                    })}
                  >
                    {t<string>("NEW_USER.WORK_EXPERIENCE")}
                  </span>
                  <input
                    className={classNames({
                      "about-box__field-inp": true,
                      "about-box__field-inp-filled":
                        formikForm.values.experience,
                      "about-box__field-inp-touched":
                        formikForm.touched?.experience &&
                        formikForm.errors?.experience,
                    })}
                    type="text"
                    value={formikForm.values.experience}
                    onChange={(event) => {
                      if (
                        +event.target.value === 0 ||
                        Number.isNaN(+event.target.value)
                      ) {
                        formikForm.setFieldValue("experience", "");
                      } else {
                        formikForm.setFieldValue(
                          "experience",
                          +event.target.value
                        );
                      }
                    }}
                  />
                </label>
              </div>
              {formikForm.touched?.experience &&
                formikForm.errors?.experience && (
                  <div className="form-control-error">
                    {formikForm.errors?.experience}
                  </div>
                )}
            </div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled":
                    formikForm.values.monthly_income,
                  "about-box__field-inp-error":
                    formikForm.touched.monthly_income &&
                    formikForm.errors.monthly_income,
                })}
              >
                {t<string>("NEW_USER.INCOME")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-filled":
                    formikForm.values.monthly_income,
                  "about-box__field-inp-touched":
                    formikForm.touched.monthly_income &&
                    formikForm.errors.monthly_income,
                })}
                placeholder="1000"
                type="text"
                value={formikForm.values.monthly_income}
                onChange={(event) => {
                  if (
                    +event.target.value === 0 ||
                    Number.isNaN(+event.target.value)
                  ) {
                    formikForm.setFieldValue("monthly_income", "");
                  } else {
                    formikForm.setFieldValue(
                      "monthly_income",
                      +event.target.value
                    );
                  }
                }}
              />
            </label>
          </div>
          {formikForm.touched.monthly_income &&
            formikForm.errors.monthly_income && (
              <div className="form-control-error">
                {formikForm.errors.monthly_income}
              </div>
            )}

          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.role,
                  "about-box__field-inp-error":
                    formikForm.touched.role && formikForm.errors.role,
                })}
              >
                {t<string>("NEW_USER.ROLE")}
              </span>
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.role,
                  "about-box__field-inp-touched":
                    formikForm.touched.role && formikForm.errors.role,
                })}
                value={formikForm.values.role}
                onChange={(event: any) => {
                  formikForm.setFieldValue("role", event.target.value);
                  formikForm.setFieldValue("bank", "");
                }}
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                {isSuperAdmin && (
                  <option value="super_admin">
                    {t<string>("NEW_USER.SUPER_ADMIN")}
                  </option>
                )}
                <option value="admin">{t<string>("NEW_USER.ADMIN")}</option>
                <option value="partner">{t<string>("NEW_USER.PARTNER")}</option>
                <option value="user">{t<string>("NEW_USER.USER")}</option>
              </select>
            </label>
            {formikForm.touched.role && formikForm.errors.role && (
              <div className="form-control-error">{formikForm.errors.role}</div>
            )}
          </div>
          {formikForm.values.role === "partner" && (
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.bank,
                    "about-box__field-inp-error":
                      formikForm.touched.bank && formikForm.errors.bank,
                  })}
                >
                  {t<string>("NEW_USER.BANK")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.bank,
                    "about-box__field-inp-touched":
                      formikForm.touched.bank && formikForm.errors.bank,
                  })}
                  {...formikForm.getFieldProps("bank")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  {banks?.payload?.collection?.map((bank: Public.Bank) => (
                    <option key={bank.id} value={bank.id}>
                      {appLanguage === "en"
                        ? bank?.translations?.en?.name
                        : bank?.translations?.bg?.name}
                    </option>
                  ))}
                </select>
              </label>
              {formikForm.touched.bank && formikForm.errors.bank && (
                <div className="form-control-error">
                  {formikForm.errors.bank}
                </div>
              )}
            </div>
          )}
          <div className="about__form-box about-box">
            <div className="about-box__documents">
              {formikForm.values.certificate !== null && (
                <div className="about-box__file-block file-block">
                  <img
                    className="file-block__icon"
                    src={addedFile}
                    alt="file"
                  />
                  <span className="file-block__txt">
                    {formikForm.values.certificate.name &&
                      formikForm.values.certificate.name}{" "}
                    {t<string>("NEW_USER.ADDED")}
                  </span>
                  <span
                    className="file-block__txt"
                    onClick={() => {
                      formikForm.setFieldValue("certificate", null);
                    }}
                  >
                    x
                  </span>
                </div>
              )}
            </div>
            <label className="about-box__file">
              {formikForm.values.certificate === null && (
                <div className="about-box__file-block file-block">
                  <img className="file-block__icon" src={fileIcon} alt="file" />
                  <span className="file-block__txt">
                    {t<string>("NEW_USER.ADD_CERT")}
                  </span>
                </div>
              )}
              <input
                type="file"
                accept=".doc, .docx, .pdf, .xlx, .csv, .zip, .png, .jpg, .jpeg, .gif, .heic, .heif"
                className="file-block__inp"
                onChange={(event) => {
                  if (event.target.files) {
                    formikForm.setFieldValue(
                      "certificate",
                      event.target.files[0]
                    );
                  }
                }}
              />
            </label>
          </div>
          {formikForm.touched.role && formikForm.errors.role && (
            <div className="form-control-error">{formikForm.errors.role}</div>
          )}
          <div className="about__form">
            <h2 className="about__title">{t<string>("NEW_USER.EDUCATION")}</h2>
            <div className="about__form-box about-box">
              <label className="about-box__field">
                <span
                  className={classNames({
                    "about-box__field-name": true,
                    "about-box__field-name-filled": formikForm.values.education,
                    "about-box__field-inp-error":
                      formikForm.touched.education &&
                      formikForm.errors.education,
                  })}
                >
                  {t<string>("NEW_USER.DEGREE")}
                </span>
                <select
                  className={classNames({
                    "about-box__field-inp": true,
                    "about-box__field-select": true,
                    "about-box__field-inp-filled": formikForm.values.education,
                    "about-box__field-inp-touched":
                      formikForm.touched.education &&
                      formikForm.errors.education,
                  })}
                  {...formikForm.getFieldProps("education")}
                >
                  <option disabled hidden value="">
                    {t<string>("PROFILE.PLEASE_SELECT")}
                  </option>
                  <option value="0">{t<string>("NEW_USER.UNIVERSITY")}</option>
                  <option value="1">{t<string>("NEW_USER.HIGH_SCHOOL")}</option>
                  <option value="2">{t<string>("NEW_USER.SECONDARY")}</option>
                  <option value="3">{t<string>("NEW_USER.PRIMARY")}</option>
                  <option value="4">
                    {t<string>("NEW_USER.NO_COMPLETED")}
                  </option>
                </select>
              </label>
            </div>
            {formikForm.touched.education && formikForm.errors.education && (
              <div className="form-control-error">
                {formikForm.errors.education}
              </div>
            )}

            <div className="about__form-box about-ask about-ask-bottom">
              <h4 className="about-ask__question">
                {t<string>("NEW_USER.SIGNIFICANT_PERSON")}
              </h4>
              <div className="about-ask__variants">
                <label
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.politic === "1",
                  })}
                  htmlFor="politic-yes"
                >
                  {t<string>("NEW_USER.YES")}
                  <input
                    className="var-block__inp"
                    type="radio"
                    name="politic"
                    id="politic-yes"
                    value="1"
                    onChange={(e) =>
                      formikForm.setFieldValue("politic", e.target.value)
                    }
                  />
                </label>
                <label
                  className={classNames({
                    "about-ask__variants-block": true,
                    "var-block": true,
                    "var-block-selected": formikForm.values.politic === "0",
                  })}
                  htmlFor="politic-no"
                >
                  {t<string>("NEW_USER.NO")}
                  <input
                    className="var-block__inp"
                    type="radio"
                    name="politic"
                    id="politic-no"
                    value="0"
                    onChange={(e) =>
                      formikForm.setFieldValue("politic", e.target.value)
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-name-filled": formikForm.values.password,
                  "about-box__field-inp-error":
                    formikForm.touched.password && formikForm.errors.password,
                })}
              >
                {t<string>("NEW_USER.PASSWORD")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.password && formikForm.errors.password,
                  "about-box__field-inp-filled": formikForm.values.password,
                })}
                placeholder={t<string>("NEW_USER.PASSWORD")}
                {...formikForm.getFieldProps("password")}
              />
            </label>
          </div>
          {formikForm.touched.password && formikForm.errors.password && (
            <div className="form-control-error">
              {formikForm.errors.password}
            </div>
          )}
          <div className="about__form-box about-box">
            <label className="about-box__field">
              <span
                className={classNames({
                  "about-box__field-name": true,
                  "about-box__field-inp-error":
                    formikForm.touched.password_confirmation &&
                    formikForm.errors.password_confirmation,
                  "about-box__field-name-filled":
                    formikForm.values.password_confirmation,
                })}
              >
                {t<string>("NEW_USER.CONFIRM_PASSWORD")}
              </span>
              <input
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-inp-touched":
                    formikForm.touched.last_name && formikForm.errors.last_name,
                  "about-box__field-inp-filled":
                    formikForm.values.password_confirmation,
                })}
                placeholder={t<string>("NEW_USER.CONFIRM_PASSWORD_PLACE")}
                {...formikForm.getFieldProps("password_confirmation")}
              />
            </label>
          </div>
          {formikForm.touched.password_confirmation &&
            formikForm.errors.password_confirmation && (
              <div className="form-control-error">
                {formikForm.errors.password_confirmation}
              </div>
            )}
          <div className="about__form-box about-ask">
            <h4 className="about-ask__question">
              {t<string>("NEW_USER.VERIFIED")}
            </h4>
            <div className="about-ask__variants">
              <label
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.verified === "1",
                })}
                htmlFor="verified-yes"
              >
                {t<string>("NEW_USER.YES")}
                <input
                  className="var-block__inp"
                  type="radio"
                  name="verified"
                  id="verified-yes"
                  value={"1"}
                  onChange={(e) =>
                    formikForm.setFieldValue("verified", e.target.value)
                  }
                />
              </label>
              <label
                className={classNames({
                  "about-ask__variants-block": true,
                  "var-block": true,
                  "var-block-selected": formikForm.values.verified === "0",
                })}
                htmlFor="verified-no"
              >
                {t<string>("NEW_USER.NO")}
                <input
                  className="var-block__inp"
                  type="radio"
                  name="verified"
                  id="verified-no"
                  value={"0"}
                  onChange={(e) =>
                    formikForm.setFieldValue("verified", e.target.value)
                  }
                />
              </label>
            </div>
          </div>
          <div className="about__form-box about-ask">
            <h4 className="about-ask__question">
              {t<string>("UPDATE_USER.SELECT_STATUS")}
            </h4>
            <label className="about-box__field about__form-box about-box">
              <select
                className={classNames({
                  "about-box__field-inp": true,
                  "about-box__field-select": true,
                  "about-box__field-inp-filled": formikForm.values.status,
                  "about-box__field-inp-touched":
                    formikForm.touched.status && formikForm.errors.status,
                })}
                value={formikForm.values.status}
                onChange={(e) => {
                  formikForm.setFieldValue("status", e.target.value);
                }}
              >
                <option disabled hidden value="">
                  {t<string>("PROFILE.PLEASE_SELECT")}
                </option>
                <option value="active">
                  {t<string>("UPDATE_USER.ACTIVE")}
                </option>
                <option value="pending">
                  {t<string>("UPDATE_USER.PENDING")}
                </option>
                <option value="disabled">
                  {t<string>("UPDATE_USER.DISABLED")}
                </option>
              </select>
            </label>
          </div>
          <button
            className={classNames({
              about__submit: true,
              "about__submit-active": formikForm.isValid && formikForm.touched,
            })}
            type="submit"
          >
            {t<string>("NEW_USER.ADD_NEW_USER")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddNewUserModal;
