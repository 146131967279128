import fiBankPartner from "../../assets/photos/all/banks-colors/fibank-logos.svg";
import allianz from "../../assets/photos/all/banks-colors/Allianz.svg";
import BNPPPF from "../../assets/photos/all/banks-colors/BNPPPF.svg";
import Bulgarian from "../../assets/photos/all/banks-colors/Bulgarian-Ameriican-bank.svg";
import ccb from "../../assets/photos/all/banks-colors/ccb-logo.svg";
import dbank from "../../assets/photos/all/banks-colors/dbank-logo.svg";
import ibank from "../../assets/photos/all/banks-colors/International asset.svg";
import International from "../../assets/photos/all/banks-colors/International asset.svg";
import TeximBank from "../../assets/photos/all/banks-colors/Logo Texim Bank.svg";
import poshtenska from "../../assets/photos/all/banks-colors/poshtenska-banka.svg";
import tbi from "../../assets/photos/all/banks-colors/tbi-bank.svg";
import UniCredit from "../../assets/photos/all/banks-colors/UniCreditGroup.svg";
import OBB from "../../assets/photos/all/banks-colors/ОББ.svg";
import Tokuda from "../../assets/photos/all/banks-colors/Токуда банк.svg";

export const partners_main = [
  { id: 1, photo: fiBankPartner },
  { id: 2, photo: allianz },
  { id: 4, photo: Bulgarian },
  { id: 5, photo: ccb },
  { id: 6, photo: dbank },
  { id: 7, photo: ibank },
  { id: 8, photo: TeximBank },
  { id: 9, photo: poshtenska },
  { id: 10, photo: tbi },
  { id: 11, photo: UniCredit },
  { id: 12, photo: OBB },
  { id: 13, photo: Tokuda },
 
];

export const partners_main_mobile = [
  { id: 1, photo: fiBankPartner },
  { id: 2, photo: allianz },
  { id: 4, photo: Bulgarian },
  { id: 5, photo: ccb },
  { id: 6, photo: dbank },
  { id: 7, photo: ibank },
  { id: 8, photo: TeximBank },
  { id: 9, photo: poshtenska },
  { id: 10, photo: tbi },
  { id: 11, photo: UniCredit },
  { id: 12, photo: OBB },
  { id: 13, photo: Tokuda },
  
];

