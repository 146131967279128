import { Rating } from "react-simple-star-rating";

import { Public } from "../../../../core/models";
import { useAppSelector } from "../../../../core/store";

import bankIcon from "../../../../assets/photos/profile/bank-icon.svg";
import approved from "../../../../assets/photos/ratings/approved.svg";
import openArrow from "../../../../assets/photos/ratings/open-arrow.svg";
import arrrow_up from "../../../../assets/photos/all/arrrow_up.svg";
import logo from "../../../../assets/photos/all/logo-small.svg";

import { useTranslation } from "react-i18next";
import { useState } from 'react';

type FeedbackCardProps = {
  isResponseOpen: boolean;
  handleResponse: () => void;
  review: Public.BankReview;
  bank: Public.Bank;
};

const FeedbackCard = ({
  review,
  bank,
  isResponseOpen,
  handleResponse,
}: FeedbackCardProps) => {
  const appLanguage = useAppSelector((state) => state.app.appLanguage);

  const { t } = useTranslation();

  return (
    <li className="reviews-list__content-item">
      <div className="reviews-list-poster">
        <div className="reviews-list-poster__box">
          <div className="reviews-list-poster__box-about poster-box-about">
            <div className="poster-box-about__person">
              <p className="poster-box-about__person-info">{review.name}</p>
              <span className="poster-box-about__person-date poster-box-about__person-date-desktop">
                {`${review.created_at.slice(8, 10)}.${review?.created_at.slice(
                  5,
                  7
                )}.${review?.created_at.slice(0, 4)}`}
              </span>
            </div>
            <div className="poster-box-about__bank poster-box-about__bank-desktop">
              <img
                className="poster-box-about__bank-icon"
                src={bank?.logo_name ? bank.logo_path : bankIcon}
                alt="icon"
              />
              <span className="poster-box-about__bank-txt">
                {/* {appLanguage === "en"
                  ? bank?.translations.en.name
                  : bank?.translations.bg.name} */}
              </span>
            </div>
          </div>
          <div className="reviews-list-poster__box-rate poster-rate">
            <div className="poster-rate__items">
              <Rating
                initialValue={review.rating}
                readonly={true}
                size={28}
                allowTitleTag={false}
              />
              <span className="poster-rate__items-rating">{review.rating}</span>
            </div>
            <div className="poster-rate__status">
              <img src={approved} alt="approved" />
              <span>{t<string>("PROFILE.APPROVED")}</span>
            </div>
          </div>
          <p className="reviews-list-poster__box-desc">{review.comment}</p>
          <div className="reviews-list-poster__mobile">
            <span className="reviews-list-poster__mobile-date poster-box-about__person-date">
              {`${review.created_at.slice(8, 10)}.${review?.created_at.slice(
                5,
                7
              )}.${review?.created_at.slice(0, 4)}`}
            </span>
            <div className="reviews-list-poster__mobile-line"></div>
            <div className="reviews-list-poster__mobile-bank poster-box-about__bank">
              <img
                className="poster-box-about__bank-icon"
                src={bank?.logo_name ? bank.logo_path : bankIcon}
                alt="icon"
              />
              <span className="poster-box-about__bank-txt">
                {appLanguage === "en"
                  ? bank?.translations.en.name
                  : bank?.translations.bg.name}
              </span>
            </div>
          </div>
        </div>
      </div>
      {review.comments.collection.length > 0 && (
        review.comments.collection.map(el => <FeedbackCardReview key={crypto.randomUUID()} review={el} />)
      )}
    </li>
  );
};

type FeedbackCardReviewProps = {
  review: any;
};
const FeedbackCardReview = ({review}: FeedbackCardReviewProps) => {
  const [isResponseOpen, setIsResponseOpen] = useState(false)
  const { t } = useTranslation();
  const handleResponse = () => {
    setIsResponseOpen(prev=>!prev)
  }
  return (
    <>
    {review && <div className="reviews-list-response" onClick={handleResponse}>
    <div className="bank-response">
      <div className="bank-response__switcher">
        <div className="bank-response__switcher-wrap">
            <img src={logo} />
            {!isResponseOpen
              ? <span className="bank-response__switcher-txt">
                  {t<string>("RATING.RESPONSE")}
                </span>
              : <span className="bank-response__switcher-txt">
                {/* {t<string>("RATING.RESPONSE")} */}
                Asistent <span className="bank-response__information-details-date">
                {`${review.created_at.slice(
                  8,
                  10
                )}.${review?.created_at.slice(
                  5,
                  7
                )}.${review?.created_at.slice(0, 4)}`}
              </span>
                </span>
            }
        </div>

        {isResponseOpen ? (
          // <button
          //   onClick={handleResponse}
          //   className="bank-response__switcher-btn">
          // </button>
            <img src={arrrow_up} alt="arrow opend" />
        ) : (
          // <button
          //   onClick={handleResponse}
          //   className="bank-response__switcher-btn">
          // </button>
            <img src={openArrow} alt="arrow opend" />
        )}
      </div>
      {isResponseOpen &&
          <div key={review.id} className="bank-response__information">
            <div className="bank-response__information-details">
              {/* <img
                className="poster-box-about__bank-icon"
                src={bank?.logo_name ? bank.logo_path : bankIcon}
                alt="icon"
              />
              <p className="bank-response__information-details-bank">
                {appLanguage === "en"
                  ? bank?.translations.en.name
                  : bank?.translations.bg.name}
              </p> */}
              {/* <span className="bank-response__information-details-date">
                {`${comment.created_at.slice(
                  8,
                  10
                )}.${review?.created_at.slice(
                  5,
                  7
                )}.${review?.created_at.slice(0, 4)}`}
              </span> */}
            </div>
            <p className="bank-response__information-desc">
              {review.comment}
            </p>
            <span className="bank-response__information-details-date bank-response__information-details-mobile">
              {`${review.created_at.slice(
                8,
                10
              )}.${review?.created_at.slice(
                5,
                7
              )}.${review?.created_at.slice(0, 4)}`}
            </span>
          </div>
        }
    </div>
    </div>}
    </>
  )
}
export default FeedbackCard;


