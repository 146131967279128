import { InputHTMLAttributes } from 'react'

import cn from 'classnames'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  disabled?: boolean
}
 const Checkbox = ({
  className,
  disabled,
  ...rest
}: CheckboxProps) => {
  return (
    <label className={cn('root', className, { 'disabled': disabled })}>
      <input
        type="checkbox"
        disabled={disabled}
        className="input"
        {...rest}
      />
      <div className="checkmark" />
    </label>
  )
}
export default Checkbox