import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Cropper from "react-cropper";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "cropperjs/dist/cropper.css";

import PrivateService from "../../../../core/services/private.service";
import useHttpGet from "../../../../core/hooks/useHttpGet";
import { APIRoutes } from "../../../../core/http";
import { PrivateUIRoutes, UIRoutes } from "../../../../core/router";
import { Private } from "../../../../core/models";
import { useTranslation } from "react-i18next";

const BanksForm: FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState<any>();
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState<any>();

  const { t } = useTranslation();

  const { id } = useParams();

  const navigate = useNavigate();

  const pictureLoader = (e: any) => {
    setCropper(undefined);

    e.preventDefault();

    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result as any);
    };

    reader.readAsDataURL(files[0]);
  };

  const urltoFile = (url: string, filename: string, mimeType: string) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      urltoFile(cropper.url, "photo.png", "image/png").then(function (file) {
        setLogo(file);
      });

      setImage(undefined);
    }
  };

  const { loading: bankLoading } = useHttpGet<any>(
    `${APIRoutes.ADMIN_ENTITIES_BANK}/${id}`,

    {
      condition: !!id,
      resolve: (response: any) => {
        formikForm.setValues(response.payload);
        formikForm.setFieldValue("logo", response.payload.logo_path);
      },
    }
  );

  const valueFormValidationSchema = Yup.object().shape({
    name: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    description: Yup.string()
      .required(t<string>("NEW_USER.REQUIRED_PROPERTY"))
      .max(720, "Max 720 characters"),
    sort_order: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
    status: Yup.string().required(t<string>("NEW_USER.REQUIRED_PROPERTY")),
  });

  const formikForm = useFormik<Private.Bank>({
    initialValues: {
      id: 0,
      name: "",
      description: "",
      sort_order: "",
      status: "",
      logo: undefined,
    },

    validationSchema: valueFormValidationSchema,

    onSubmit: async (values) => {
      id ? update(values) : create(values);
    },
  });

  const create = async (values: any) => {
    setLoading(true);

    try {
      const response = await PrivateService.addBank({
        ...values,
        logo: logo,
      });

      setLoading(false);
      setError("");
      toast.success(response?.data?.message);
      navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.BANKS}`);
    } catch (errors: any) {
      setLoading(false);
      toast.error(errors?.response?.data?.message);
    }
  };

  const update = async (values: any) => {
    setLoading(true);

    try {
      const response = await PrivateService.updateBank({
        ...values,
        logo: logo,
      });
      toast.success(response?.data?.message);
      setLoading(false);
      setError("");
      navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.BANKS}`);
    } catch (errors: any) {
      setLoading(false);
      toast.error(errors?.response?.data?.message);
    }
  };

  return (
    <>
      {(bankLoading || loading) && <span>Loading...</span>}
      <form
        onSubmit={formikForm.handleSubmit}
        className="form w-100"
        style={{ display: "flex", flexDirection: "column", gap: "7px" }}
      >
        <div className="form-control">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            {...formikForm.getFieldProps("name")}
          />
          {formikForm.touched.name && formikForm.errors.name && (
            <div className="form-control-error">{formikForm.errors.name}</div>
          )}
        </div>

        <div className="form-control">
          <label className="form-label">Description</label>
          <textarea
            className="form-control"
            {...formikForm.getFieldProps("description")}
          ></textarea>
          {formikForm.touched.description && formikForm.errors.description && (
            <div className="form-control-error">
              {formikForm.errors.description}
            </div>
          )}
        </div>

        <div className="form-control">
          <label className="form-label">Sort order</label>
          <input
            type="number"
            className="form-control"
            {...formikForm.getFieldProps("sort_order")}
          />
          {formikForm.touched.sort_order && formikForm.errors.sort_order && (
            <div className="form-control-error">
              {formikForm.errors.sort_order}
            </div>
          )}
        </div>

        <div>
          <div>
            <input
              type="file"
              onChange={pictureLoader}
              accept="image/jpg, image/jpeg, image/png, image/gif"
            />
            <br />
            {image && (
              <div>
                <Cropper
                  style={{ height: 250, width: 250 }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={200}
                  minCropBoxWidth={200}
                  cropBoxResizable={false}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
                <button onClick={getCropData}>Crop</button>
              </div>
            )}
            {id && !cropper && (
              <img
                src={`${formikForm.values.logo}`}
                alt="logo"
                style={{ width: "100px" }}
              />
            )}
            {cropper && !image && <p>Picture successfully saved!</p>}
          </div>
        </div>

        <div className="form-control">
          <label className="form-label">Status</label>
          <select
            className="form-control"
            {...formikForm.getFieldProps("status")}
          >
            <option disabled hidden value="">
              {t<string>("PROFILE.PLEASE_SELECT")}
            </option>
            <option value="enabled">Enabled</option>
            <option value="disabled">Disabled</option>
          </select>
          {formikForm.touched.status && formikForm.errors.status && (
            <div className="form-control-error">{formikForm.errors.status}</div>
          )}
        </div>

        <div className="form-actions">
          <button type="submit">Save</button>
        </div>
      </form>
    </>
  );
};

export default BanksForm;
