import React, { FC, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
// import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { PrivateUIRoutes, UIRoutes } from "../../../core/router";
import { toast } from "react-toastify";
import { Auth } from "../../../core/models";
import InputMask from "react-input-mask";
import { authentiticator } from "../../../core/services/firebase-config.service";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import * as Yup from "yup";
import AuthService from "../../../core/services/auth.service";
import CommonService from "../../../core/services/common.service";

// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import jwtDecode from "jwt-decode";
import classNames from "classnames";
import ReactGA from "react-ga4";
// import facebook from "../../../assets/photos/all/login-facebook.svg";
// import google from "../../../assets/photos/all/login-google.svg";
// import secured from "../../../assets/photos/all/secured.svg";
import { useAppDispatch, useAppSelector } from "../../../core/store";
// import { setModal } from "../../../core/store/reducers/modal/modalSlice";
import { AuthSelectionType } from "../../../core/services/enums";
import { setIsAuth } from "../../../core/store/reducers/auth/authSlice";
import {
  filterPhoneNum,
  spacesRemover,
} from "../../../core/functions/funtions";
import { useTranslation } from "react-i18next";
import {
  setModal,
  setModalType,
} from "../../../core/store/reducers/modal/modalSlice";

type Props = {
  setSelectionType: (str: AuthSelectionType) => void;
  setCurrentEmail: (str: string) => void;
};

const RegisterForm: FC<Props> = ({ setSelectionType, setCurrentEmail }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [savedEmail, setSavedEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [resend, setResend] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(59);
  const [successPhone, setSuccessPhone] = useState(false);
  const [successOTP, setSuccessOTP] = useState(false);
  const [otpButton, setOtpButton] = useState(true);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loginRoute, appLanguage } = useAppSelector((state) => state.app);

  const prevPage = localStorage.getItem("prevPage");

  useEffect(() => {
    if (timeRemaining === 50) {
      setResend(true);
      return;
    }

    const timeSwitcher = setInterval(() => {
      setTimeRemaining(timeRemaining - 1);
    }, 1000);

    return () => {
      clearInterval(timeSwitcher);
    };
  }, [timeRemaining]);

  const valueFormValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required(`${t<string>("AUTH_ERR.EMAIL_ERR")}`)
      .email(`${t<string>("AUTH_ERR.INVALID_EMAIL")}`),
    phone: Yup.string()
      .required(`${t<string>("AUTH_ERR.PHONE_ERR")}`)
      .min(12, `${t<string>("AUTH_ERR.MIN12")}`),
    otp: Yup.string()
      .required("Fill your OTP code")
      .min(6, `${t<string>("AUTH_ERR.MIN6")}`),
    informed: Yup.boolean().isTrue().required(),
    agree: Yup.boolean().isTrue().required(),
  });

  const formikForm = useFormik<{
    email: string;
    phone: string;
    otp: string;
    informed: boolean;
    agree: boolean;
  }>({
    initialValues: {
      email: "",
      phone: "",
      otp: "",
      informed: false,
      agree: false,
    },
    validationSchema: valueFormValidationSchema,
    onSubmit: (values: Auth.Registration) => {
      handleSubmitForm(values);
      dispatch(setModalType(""));
      dispatch(setModal(false));
    },
  });



  // const handleFacebookSignIn = async (values: any) => {
  //   try {
  //     const response = await AuthService.loginFacebook(
  //       values.accessToken,
  //       values.data_access_expiration_time,
  //       values.email,
  //       values.expiresIn,
  //       values.grantedScopes,
  //       values.graphDomain,
  //       values.id,
  //       values.name,
  //       JSON.stringify(values.picture),
  //       values.signedRequest,
  //       values.userID
  //     );
  //     localStorage.setItem(
  //       process.env.REACT_APP_TOKEN_KEY,
  //       response.data.payload.token
  //     );

  //     dispatch(setIsAuth(true));
  //     setLoading(false);
  //     setError("");
  //     if (!loginRoute) {
  //       response?.data?.payload.role === "admin" ||
  //       response?.data?.payload.role === "super_admin"
  //         ? navigate(`/${UIRoutes.ADMIN}/${PrivateUIRoutes.ADMIN_PANEL}`)
  //         : navigate(`/${UIRoutes.ACCOUNT}/${UIRoutes.PROFILE}`);
  //     } else {
  //       navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
  //     }

  //     ReactGA.event({
  //       category: "registration",
  //       action:
  //         process.env.REACT_APP_ANALYTICS_NAME === ""
  //           ? "registration"
  //           : `registration_${process.env.REACT_APP_ANALYTICS_NAME}`,
  //     });
  //     toast.success(response?.data?.message);
  //     localStorage.removeItem("prevPage");
  //     dispatch(setModal(false));
  //   } catch (errors: any) {
  //     setLoading(false);
  //     CommonService.showErrors(errors?.response?.data?.payload);
  //   }
  // };

  // const handleGoogleSignIn = async (values: any) => {
  //   setLoading(true);
  //   try {
  //     const encoded_values: Auth.GoogleLogin = jwtDecode(values.credential);
  //     const response = await AuthService.loginGoogle(encoded_values);
  //     localStorage.setItem(
  //       process.env.REACT_APP_TOKEN_KEY,
  //       response.data.payload.token
  //     );
  //     dispatch(setIsAuth(true));
  //     setLoading(false);
  //     setError("");

  //     if (!loginRoute) {
  //       response?.data?.payload.role === "admin" ||
  //       response?.data?.payload.role === "super_admin"
  //         ? navigate(`/${UIRoutes.ADMIN}/${PrivateUIRoutes.ADMIN_PANEL}`)
  //         : navigate(`/${UIRoutes.ACCOUNT}/${UIRoutes.PROFILE}`);
  //     } else {
  //       navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
  //     }

  //     ReactGA.event({
  //       category: "registration",
  //       action:
  //         process.env.REACT_APP_ANALYTICS_NAME === ""
  //           ? "registration"
  //           : `registration_${process.env.REACT_APP_ANALYTICS_NAME}`,
  //     });
  //     toast.success(response?.data?.message);
  //     localStorage.removeItem("prevPage");
  //     dispatch(setModal(false));
  //   } catch (errors: any) {
  //     setLoading(false);
  //     CommonService.showErrors(errors?.response?.data?.payload);
  //   }
  // };
  // const getUserGeolocation = async () => {
  //   try {
  //     const response = await AuthService.getIpGeolocation();
  //     console.log(response);
  //   } catch (e) {}
  // };
  const handleSubmitForm = async (values: Auth.Registration) => {
    setLoading(true);
    setSavedEmail(values.email);
    try {
      const response = await AuthService.register(values);
      setLoading(false);
      setSent(true);
      setSelectionType(AuthSelectionType.Confirm);
      // setCurrentEmail(formikForm.values.email);
      formikForm.resetForm();
      localStorage.setItem(
        process.env.REACT_APP_TOKEN_KEY,
        response.data.payload.token
      );

      if (!loginRoute) {
        response?.data?.payload.role === "admin" ||
        response?.data?.payload.role === "super_admin"
          ? navigate(`/${UIRoutes.ADMIN}/${PrivateUIRoutes.ADMIN_PANEL}`)
          : navigate(`/${UIRoutes.ACCOUNT}/${UIRoutes.PROFILE}`);
      } else {
        navigate(`/${UIRoutes.ACCOUNT}/${PrivateUIRoutes.QUESTIONNAIRE}`);
      }
      ReactGA.event({
        category: "registration",
        action:
          process.env.REACT_APP_ANALYTICS_NAME === ""
            ? "registration"
            : `registration_${process.env.REACT_APP_ANALYTICS_NAME}`,
      });
      document.body.style.overflow = "auto";
      toast.success(response?.data?.message);
      formikForm.resetForm();
    } catch (errors: any) {
      setLoading(false);
      CommonService.showErrors(errors?.response?.data?.payload);
      toast.error(errors?.response?.data?.payload);
    }
  };

  const dataValidation = (string: string) => {
    let filteredPhone = string.split(",").join().replace(/ /g, ""); //разобраться почему не проверяет правильное количество знаков
    return filteredPhone;
  };

  useEffect(() => {
    dataValidation(formikForm.values.phone).length >= 13
      ? setOtpButton(false)
      : setOtpButton(true);
  }, [formikForm.values.phone]);

  // useEffect(() => {
  //   getUserGeolocation();
  // }, []);

  useEffect(() => {
    dataValidation(formikForm.values.otp).length === 6 && verifyOTP();
  }, [formikForm.values.otp]);

  const verifyOTP = () => {
    window.confirmationResult
      .confirm(dataValidation(formikForm.values.otp))
      .then((result: any) => {
        // User signed in successfully.
        const user = result.user;
        // ...
        localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, user.accessToken);

        // console.log(user);
        setSuccessOTP(true);
      })
      .catch((error: any) => {
        // User couldn't sign in (bad verification code?)
        // ...
        setSuccessOTP(false);
        toast.error(t<string>(error.message));
        console.log(error);
      });
  };

  const getRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      authentiticator,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      }
    );
  };

  const requestOTP = () => {
    setLoading(true);
    getRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(authentiticator, formikForm.values.phone, appVerifier)
      .then((confirmationResult) => {
        setLoading(false);
        setSuccessPhone(true);
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // console.log(confirmationResult);
      })
      .catch((error) => {
        setSuccessPhone(false);
        setLoading(false);
        // Error; SMS not sent
        // ...
        toast.error(t<string>(error.message));
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault(); 
  };

  const handlePrivacyPolicyRedirect = () => {
    dispatch(setModalType(""));
    dispatch(setModal(false));
    navigate("/privacy-policy");
  };
  
  const handleTermsRedirect = () => {
    dispatch(setModalType(""));
    dispatch(setModal(false));
    navigate("/terms-conditions");
  };

  const handleDownloadConsentForm = () => {
    const fileName = t<string>("AUTH.CONSENTFORM")
    return (
      <a
        className="auth-agreement-btn"
        href={process.env.PUBLIC_URL + '/Consent for processing and storage of personal data.zip'}
        download
      >
        {t<string>("AUTH.DOWNLOAD_CONSENTFORM")}
      </a>
    );
  };

  return (
    <div className="registration">
      <form
        className="registration__entry entry"
        onSubmit={(e) => {
          handleSubmit(e);
          formikForm.handleSubmit(e);
        }}
      >
        <div className="entry__auth">
          <p className="auth__title">{t<string>("AUTH.LOGIN_TITLE")}</p>
          <div className="auth__fields">
            <div className="auth__fields-input data-input">
              <label
                className={classNames({
                  "data-input__details": true,
                  // "data-input__details-touched":
                  //   formikForm.touched.email && formikForm.errors.email,
                })}
              >
                {t<string>("AUTH.EMAIL")}
              </label>
              <input
                type="text"
                className={classNames({
                  "data-input__field": true,
                  // "data-input__field-touched":
                  //   formikForm.touched.email && formikForm.errors.email,
                  // "data-input__field-filled": formikForm.values.email,
                })}
                placeholder="Alexmatinsov98@gmail.com"
                value={formikForm.values.email}
                onChange={(event) => {
                  formikForm.setFieldValue(
                    "email",
                    spacesRemover(event.target.value)
                  );
                }}
              />
              {formikForm.touched.email && formikForm.errors.email && (
                <div className="form-control-error">
                  {formikForm.errors.email}
                </div>
              )}
            </div>
            <div className="auth__fields-input data-input open">
              <label
                className={classNames({
                  "data-input__details": true,
                  // "data-input__details-touched":
                  // formikForm.touched.phone && formikForm.errors.phone,
                })}
              >
                {t<string>("AUTH.PHONE")}
              </label>
              <div
                className={classNames(
                  "data-input__number-field",
                  successPhone && "success",
                  loading && "active"
                )}
              >
                <InputMask
                  name="phone"
                  className="data-input__input-field"
                  maskChar=" "
                  mask="+35\9 (999) 999-9999"
                  placeholder="+359 (___) ___-____"
                  value={formikForm.values.phone}
                  onChange={(event) => {
                    formikForm.handleChange(event);
                    formikForm.setFieldValue(
                      "phone",
                      filterPhoneNum(event.target.value)
                    );
                  }}
                />
                <button
                  className={classNames(
                    "data-input__number-submit",
                    loading && "active"
                  )}
                  type="button"
                  onClick={requestOTP}
                  disabled={otpButton}
                >
                  <span>{t<string>("COMMON.SEND_CODE")}</span>
                  <div className="lds-facebook">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </button>
              </div>
              {/* вызов ошибок */}
              {formikForm.touched.phone && formikForm.errors.phone && (
                <div className="form-control-error-phone">
                  {t<string>(formikForm.errors.phone)}
                </div>
              )}
            </div>
            <div className="auth__fields-input data-input">
              <label
                className={classNames({
                  "data-input__details": true,
                  // "data-input__details-touched":
                  // formikForm.touched.phone && formikForm.errors.phone,
                })}
              >
                {t<string>("AUTH.OTP")}
              </label>
              <div
                className={classNames(
                  "data-input__number-field",
                  successOTP && "success"
                )}
              >
                <InputMask
                  className="data-input__input-field"
                  name="otp"
                  mask="9 9 9 9 9 9"
                  maskChar=" "
                  placeholder="_ _ _  _ _ _"
                  disabled={successPhone ? false : true}
                  value={formikForm.values.otp}
                  onChange={(event) => {
                    formikForm.handleChange(event);
                    formikForm.setFieldValue(
                      "otp",
                      spacesRemover(event.target.value)
                    );
                  }}
                />
              </div>
              {/* вызов ошибок */}
              {formikForm.touched.otp && formikForm.errors.otp && (
                <div className="form-control-error-phone">
                  {t<string>(formikForm.errors.otp)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="auth-agreement">
          <div
            className="auth-agreement-box"
            onClick={() => {
              formikForm.setFieldValue("informed", !formikForm.values.informed);
            }}
          >
            <input
              type="checkbox"
              className={classNames({
                "auth-agreement-box-inp": true,
                // "auth-agreement-box-error":
                // formikForm.touched.informed && formikForm.errors.informed,
              })}
              checked={formikForm.values.informed}
              onChange={(event: any) => {
                formikForm.setFieldValue("informed", event.target.checked);
              }}
            />
            <p className="auth-agreement-box-txt">
              {t<string>("AUTH.REGISTRATION_TERMS.PART1")}{" "}
              <button
                type="button"
                className="auth-agreement-btn"
                onClick={(e) => {
                  handleButtonClick(e);
                  handlePrivacyPolicyRedirect()
                }}
              >
                {t<string>("AUTH.POLICY")}
              </button>{" "}
              {t<string>("AUTH.REGISTRATION_TERMS.PART2")}{" "}
              <button
                type="button"
                className="auth-agreement-btn"
                onClick={(e) => {
                  handleButtonClick(e);
                  handleTermsRedirect()
                }}
              >
                {t<string>("COMMON.TERMS_CONDITIONS")}
              </button>
              {" "}
              {t<string>("AUTH.REGISTRATION_TERMS.PART2")}
              {" "}
              <button
              className="auth-agreement-btn">
                {handleDownloadConsentForm()}
              </button>
            </p>
          </div>
          {formikForm.touched.informed && formikForm.errors.informed && (
            <div className="form-control-error">
              {t<string>("AUTH.CHECK_BOXES_ERROR")}
            </div>
          )}
          <div
            className="auth-agreement-box"
            onClick={() => {
              formikForm.setFieldValue("agree", !formikForm.values.agree);
            }}
          >
            <input
              type="checkbox"
              className={classNames({
                "auth-agreement-box-inp": true,
                // "auth-agreement-box-error":
                // formikForm.touched.informed && formikForm.errors.informed,
              })}
              checked={formikForm.values.agree}
              onChange={(event: any) => {
                formikForm.setFieldValue("agree", event.target.checked);
              }}
            />
            <p className="auth-agreement-box-txt">
              {t<string>("AUTH.REGISTRATION_POLICY")}{" "}
              {t<string>("AUTH.ASSISTANT.BG")}.{" "}
              <button
              type="button"
                className="auth-agreement-btn"
                onClick={(e) => {
                  handleButtonClick(e);
                  handlePrivacyPolicyRedirect()
                }}
              >
                {t<string>("AUTH.POLICY")}.
              </button>
            </p>
          </div>
          {formikForm.touched.agree && formikForm.errors.agree && (
            <div className="form-control-error">
              {t<string>("AUTH.CHECK_BOXES_ERROR")}
            </div>
          )}

          <div className="auth__submit">
            <button className="auth__submit-btn" type="submit">
              {t<string>("AUTH.REGISTRATION_BUTTON")}
            </button>
          </div>
        </div>
        {/* <div className="entry__social social-login">
          <div className="social-login__decor">
            <div className="social-login__decor-line"></div>
            <span className="social-login__decor-txt">
              {t<string>("AUTH.OR")}
            </span>
            <span className="social-login__decor-txt social-login__decor-txt-mobile">
              {t<string>("AUTH.OR_SIGN_IN_WITH")}
            </span>
            <div className="social-login__decor-line"></div>
          </div>
          <div className="social-login__networks">
            <label className="social-login__networks-btn networks-btn">
              <div className="networks-btn__visible">
                <img
                  className="networks-btn__visible-icon"
                  src={facebook}
                  alt="facebook"
                />
                <span className="networks-btn__visible-text">
                  {t<string>("AUTH.LOGIN_FACEBOOK")}
                </span>
                <span className="networks-btn__visible-text-mobile">
                  {t<string>("AUTH.FACEBOOK")}
                </span>
              </div>
              <div className="networks-btn__block">
                <button
                  onClick={() => {
                    window.FB.login((res: any) => {
                      window.FB.api(
                        "/me",
                        {
                          fields: "name, email, picture, id",
                        },
                        function (response: any) {
                          if (response && !response.error) {
                            handleFacebookSignIn({
                              ...res.authResponse,
                              ...response,
                              picture: response.picture,
                            });
                          }
                        }
                      );
                    });
                  }}
                ></button>
              </div>
            </label>
            <label className="social-login__networks-btn networks-btn">
              <div className="networks-btn__visible">
                <img
                  className="networks-btn__visible-icon"
                  src={google}
                  alt="google"
                />
                <span className="networks-btn__visible-text">
                  {t<string>("AUTH.LOGIN_GOOGLE")}
                </span>
                <span className="networks-btn__visible-text-mobile">
                  {t<string>("AUTH.GOOGLE")}
                </span>
              </div>
              <div className="networks-btn__block">
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_KEY}
                >
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      handleGoogleSignIn(credentialResponse);
                    }}
                    onError={() => {
                      toast.error("Some errors. User wasn't registered.");
                    }}
                  />
                </GoogleOAuthProvider>
              </div>
            </label>
          </div>
        </div> */}
        {/* <div className="entry__secured">
          <img className="entry__secured-icon" src={secured} alt="secured" />
          <span className="entry__secured-txt">
            {t<string>("AUTH.SAFE_SECURE")}
          </span>
        </div> */}
      </form>
    </div>
  );
};

export default RegisterForm;
