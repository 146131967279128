import { useTranslation } from "react-i18next";

const Cookies = () => {
  const {t} = useTranslation()
  
  return (
    <div className="terms-conditions">
      <div className="terms-conditions__box">
        <div>
          <p className="terms-conditions__box-date">
          {t<string>("COOKIE_POLICY.UPDATED")} 2023-10-31
          </p>
        </div>
        <h1 className="terms-conditions__box-title">{t<string>("COOKIE_POLICY.TITLE")}</h1>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
        {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
          {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS_TXT")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>
            {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS_LI_1")}
            </li>
            <li>
            {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS_LI_2")}
            </li>
            <li>
            {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS_LI_3")}
            </li>
            <li>
            {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS_LI_4")}
            </li>
            <li>
            {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS_LI_5")}
            </li>
            <li>
            {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS_LI_6")}
            </li>
            <li>
            {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS_LI_7")}
              <a href="https://asistent.bg/">{` www.asistent.bg`} </a>
            </li>
          </ul>
          {/* <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.DEFINITIONS_TERMS_LI_8")}
            <a href="https://termify.io/"> {`Termify`}</a> .
          </p> */}
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.INTRODUCTION")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
          {t<string>("COOKIE_POLICY.INTRODUCTION_TXT_1")}{" "}
            <a href="https://asistent.bg/"> {` www.asistent.bg`} 
            </a> {t<string>("COOKIE_POLICY.INTRODUCTION_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.COOKIES_TITLE")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
          {t<string>("COOKIE_POLICY.COOKIES_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.COOKIE_USE")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.COOKIE_DEF_TXT")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>
              {t<string>("COOKIE_POLICY.COOKIE_DEF_LI_1")}
            </li>
            <li>
              {t<string>("COOKIE_POLICY.COOKIE_DEF_LI_2")}
            </li>
            <li>
              {t<string>("COOKIE_POLICY.COOKIE_DEF_LI_3")}
            </li>
            <li>
              {t<string>("COOKIE_POLICY.COOKIE_DEF_LI_4")}
            </li>
            <li>
              {t<string>("COOKIE_POLICY.COOKIE_DEF_LI_5")}
            </li>
            <li>
              {t<string>("COOKIE_POLICY.COOKIE_DEF_LI_6")}
            </li>
            <li>
              {t<string>("COOKIE_POLICY.COOKIE_DEF_LI_7")}
            </li>
          </ul>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
        {t<string>("COOKIE_POLICY.TYPE_OF_COOKIES")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
          {t<string>("COOKIE_POLICY.TYPE_OF_COOKIES_TXT_1")}
            <br /> <br />
            {t<string>("COOKIE_POLICY.TYPE_OF_COOKIES_TXT_2")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.ESSENTIAL")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
          {t<string>("COOKIE_POLICY.ESSENTIAL_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("COOKIE_POLICY.PERFORMANCE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
          {t<string>("COOKIE_POLICY.PERFORMANCE_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.MARKETING_COOK")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
          {t<string>("COOKIE_POLICY.MARKETING_COOK_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("COOKIE_POLICY.ANALYT_COOK")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.ANALYT_COOK_TXT_1")}
            <br /> <br />
            {t<string>("COOKIE_POLICY.ANALYT_COOK_TXT_2")}
            <a href="https://www.google.com/policies/privacy/partners/">
              {` https://www.google.com/policies/privacy/partners/ `}
            </a>
            . {t<string>("COOKIE_POLICY.ANALYT_COOK_TXT_3")}
            <a href="https://tools.google.com/dlpage/gaoptout">
              {` https://tools.google.com/dlpage/gaoptout `}
            </a>
            .
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.ADVERTISING")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.ADVERTISING_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.SOCIAL_MEDIA")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.SOCIAL_MEDIA_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.THIRD_PARTY")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.THIRD_PARTY_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("COOKIE_POLICY.COOKIE_MANAGE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.COOKIE_BROWES")}
            <br /> <br />
            {t<string>("COOKIE_POLICY.COOKIE_BROWES_TXT")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>{t<string>("COOKIE_POLICY.BROWSER_LI_1")}</li>
            <li>{t<string>("COOKIE_POLICY.BROWSER_LI_2")}</li>
            <li>{t<string>("COOKIE_POLICY.BROWSER_LI_3")}</li>
            <li>{t<string>("COOKIE_POLICY.BROWSER_LI_4")}</li>
            <li>{t<string>("COOKIE_POLICY.BROWSER_LI_5")}</li>
            <li>{t<string>("COOKIE_POLICY.BROWSER_LI_6")}</li>
            <li>{t<string>("COOKIE_POLICY.BROWSER_LI_7")}</li>
            <li>{t<string>("COOKIE_POLICY.BROWSER_LI_8")}</li>
          </ul>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("COOKIE_POLICY.BLOCK_DISABLE")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.BLOCK_DISABLE_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">
          {t<string>("COOKIE_POLICY.CHANGE_TO")}
        </h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.CHANGE_TO_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.CONSENT")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
            {t<string>("COOKIE_POLICY.CONSENT_TXT")}
          </p>
        </div>
      </div>
      <div className="terms-conditions__box">
        <h2 className="terms-conditions__box-subtitle">{t<string>("COOKIE_POLICY.CONTACT_US")}</h2>
        <div className="terms-conditions__box-desc">
          <p className="terms-conditions__box-desc-subtitle">
          {t<string>("COOKIE_POLICY.CONTACT_US_TXT")}
          </p>
          <ul className="terms-conditions__box-desc-table">
            <li>
            {t<string>("COOKIE_POLICY.CONTACT_US_LI")}
              <span className="terms-conditions__box-email"> info@asistent.bg</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
